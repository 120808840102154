import React, {FC, useEffect, useState} from 'react';
import {StripeCardNumberElement} from '@stripe/stripe-js';
import {useElements} from '@stripe/react-stripe-js';
import {Field, FieldRenderProps} from 'react-final-form';

export type UseRouteStripeData = {
  ready: false;
} | {
  ready: true;
  element: StripeCardNumberElement;
}

// Right now this is only used for the credit card portion because you can create a token from a data object
export function useRouteStripe(): UseRouteStripeData {
  const elements = useElements();

  if (!elements) {
    return {
      ready: false,
    };
  }

  const element = elements.getElement('cardNumber');
  if (!element) {
    return {
      ready: false,
    };
  }

  return {
    ready: true,
    element,
  }
}

export type StripeFormSetterProps = UseRouteStripeData & FieldRenderProps<any>
export type StripeFormSetterData = {
  element: StripeCardNumberElement;
}

function StripeFormSetter(props: StripeFormSetterProps) {
  useEffect(() => {
    if (!props.ready) {
      return;
    }

    if (props.input.value?.element === props.element) {
      return;
    }

    const data: StripeFormSetterData = {
      element: props.element,
    };

    props.input.onChange(data);
  });
  
  return (null);
}

export type StripeFieldProps = {
  field: string;
} & UseRouteStripeData;

export const StripeField: FC<StripeFieldProps> = (props) => {
  return (
    <Field name={props.field}>
      {(renderProps: FieldRenderProps<any>) => {
        return <StripeFormSetter {...renderProps} {...props}/>;
      }}
    </Field>
  )
}