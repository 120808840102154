import { AppState, initialState } from "../store";
import {
  AccountCreationStartedAction,
  AccountCreationSucceededAction,
  AccountCreationFailedAction,
  ACCOUNT_CREATION_STARTED,
  ACCOUNT_CREATION_SUCCEEDED,
  ACCOUNT_CREATION_FAILED
} from "../actions/account-creation/account-creation";
import {
  RoutePlusConfigurationSubmitStartedAction,
  RoutePlusConfigurationSubmitSucceededAction,
  RoutePlusConfigurationSubmitFailedAction,
  ROUTE_PLUS_CONFIGURATION_SUBMIT_STARTED,
  ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED,
  ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED
} from "../actions/configure-route-plus";
import {
  PremiumsTransferSucceededAction,
  PremiumsTransferFailedAction,
  PremiumsTransferStartedAction,
  PREMIUMS_TRANSFER_STARTED,
  PREMIUMS_TRANSFER_SUCCEEDED,
  PREMIUMS_TRANSFER_FAILED
} from "../actions/premiums-transfer";
import {
  SkipPremiumsStartedAction,
  SkipPremiumsSucceededAction,
  SkipPremiumsFailedAction,
  SKIP_PREMIUMS_STARTED,
  SKIP_PREMIUMS_SUCCEEDED,
  SKIP_PREMIUMS_FAILED
} from "../actions/premiums-transfer/complete-later";
import {
  SETUP_ACCOUNT_STARTED,
  SETUP_ACCOUNT_FAILED,
  SETUP_ACCOUNT_SUCCEEDED,
  SetupAccountStartedAction,
  SetupAccountFailedAction,
  SetupAccountSucceededAction,
} from "../../Brand/actions/setup-account"
import {
  VERIFY_DOMAIN_STARTED,
  VERIFY_DOMAIN_FAILED,
  VERIFY_DOMAIN_SUCCEEDED,
  VerifyDomainStartedAction,
  VerifyDomainFailedAction,
  VerifyDomainSucceededAction,
} from "../../Brand/actions/verify-domain"

type Actions =
  AccountCreationStartedAction | AccountCreationSucceededAction | AccountCreationFailedAction |
  RoutePlusConfigurationSubmitStartedAction | RoutePlusConfigurationSubmitSucceededAction | RoutePlusConfigurationSubmitFailedAction |
  PremiumsTransferStartedAction | PremiumsTransferSucceededAction | PremiumsTransferFailedAction |
  SkipPremiumsStartedAction | SkipPremiumsSucceededAction | SkipPremiumsFailedAction |
  SetupAccountStartedAction | SetupAccountFailedAction | SetupAccountSucceededAction |
  VerifyDomainStartedAction | VerifyDomainFailedAction | VerifyDomainSucceededAction;


export function loadingReducer(state: AppState['loading'] = initialState['loading'], action: Actions): AppState['loading'] {
  switch (action.type) {
    case ACCOUNT_CREATION_STARTED:
    case ROUTE_PLUS_CONFIGURATION_SUBMIT_STARTED:
    case PREMIUMS_TRANSFER_STARTED:
    case SKIP_PREMIUMS_STARTED:
    case SETUP_ACCOUNT_STARTED:
    case VERIFY_DOMAIN_STARTED: {
      return true;
    }
    case ACCOUNT_CREATION_SUCCEEDED:
    case ACCOUNT_CREATION_FAILED:
    case ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED:
    case ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED:
    case PREMIUMS_TRANSFER_SUCCEEDED:
    case PREMIUMS_TRANSFER_FAILED:
    case SKIP_PREMIUMS_SUCCEEDED:
    case SKIP_PREMIUMS_FAILED:
    case SETUP_ACCOUNT_FAILED:
    case SETUP_ACCOUNT_SUCCEEDED:
    case VERIFY_DOMAIN_FAILED:
    case VERIFY_DOMAIN_SUCCEEDED: {
      return false;
    }
    default: {
      return state;
    }
  }
}