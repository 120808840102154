import React, { FC, useState } from 'react';
import {Container, Button} from '../../../../Onboarding-2/components';
import {Grid, Box, makeStyles} from '@material-ui/core';
import HeadingImage from '../../../../../assets/images/branded-order-card-success.svg';
import {
  ContainerContent,
  SuccessDescription,
  Image,
  BoldText
} from "./styles";

const useStyles = makeStyles({
  button: {
    marginTop: 20,
    fontFamily: 'Titillium Web'
  },
  mainContainer: {
    position: `absolute`,
    top: 0,
    width: `100%`,
    height: '100%',
    background: `linear-gradient(180deg, #FFFFFF 35.79%, rgba(255, 255, 255, 0.822917) 100%)`,
    'z-index': `999999`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export const Success: FC<any> = (props) => {
  const [opened, setOpened] = useState(props.open || false);
  const classes = useStyles();

  const subText = ` {new/updated} branded content in `;

  const setKeepDrawerOpen = () => {
    setOpened(false);

    if (props.setKeepDrawerOpen) {
      props.setKeepDrawerOpen(false);
    }
  };

  return (
    <React.Fragment>
      { opened && (
        <Container className={classes.mainContainer}>
          <ContainerContent>
            <Box width={340}>
              <Grid container alignItems={'center'} justify="flex-start">
                <Grid item xs={12}>
                  <Image src={HeadingImage} />
                </Grid>
                <Grid item xs={12} className={classes.button}>
                  <BoldText>CONGRATULATIONS!<br /> YOUR ORDER CARD HAS BEEN SAVED</BoldText>
                </Grid>
                <Grid item xs={12}>
                  <SuccessDescription>
                    Your customers will now see your
                      {subText} <br/>
                    the Route mobile app.
                  </SuccessDescription>
                </Grid>
                <Grid item xs={12}>
                    <Button width={240} hoverBgColor={'14869F'} onClick={props.closeDrawer} className={classes.button}>Confirm</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button width={240} bgColor = {'#f7f7f7'} textColor={'#333333'} hoverBgColor={'14869F'} onClick={setKeepDrawerOpen} className={classes.button}>Continue Editing</Button>
                </Grid>
              </Grid>
            </Box>
          </ContainerContent>
        </Container>
      ) }
    </React.Fragment>
  )
};
