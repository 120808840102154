export type ErrorValue =  string|React.ReactFragment|undefined;
export type ValidatorWithOtherValues<T> = (value: unknown, allValues: Partial<T>) => ErrorValue;
export type ValidatorWithoutOtherValues<T> = (value: unknown) => ErrorValue;
export type Validator<T> = ValidatorWithOtherValues<T> | ValidatorWithoutOtherValues<T>;

export function composeValidators<T>(...validators: Validator<T>[]): Validator<T> {
  return (value: unknown, allValues: Partial<T>): ErrorValue => 
    validators.reduce((error: ErrorValue, validator) => error || validator(value, allValues), undefined);
}

export * from './required';
export * from './phone';
export * from './email';
