import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { createStripeCardAccount } from "../../../../services/billing";

class StripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      addressLine1: "",
      addressLine2: "",
      addressCity: "",
      addressState: "",
      addressZip: "",
      addressCountry: "",
      openCardModal: true,
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleSubmit = async ev => {
    ev.preventDefault();

    const { token } = await this.props.stripe.createToken({
      name: this.state.name,
      address_line1: this.state.addressLine1,
      address_line2: this.state.addressLine2,
      address_city: this.state.addressCity,
      address_state: this.state.addressState,
      address_zip: this.state.addressZip,
      address_country: this.state.addressCountry,
    });

    let res = await createStripeCardAccount(token);

    if (res) {
      this.props.onSuccess();
    } else {
      alert(
        "An error occurred while trying to verify your payment card with Stripe. \nPlease make sure your information is entered correctly. \nPlease reach out to Route Customer Service with any questions, or if this error persists."
      );
    }
  };

  render() {
    return (
      <form>
        <div className="creditcard-form">
          <div className="creditcard-form-container">
            <div className="title">Enter Card Details</div>
            <div className="row-1">
              <div className="field">
                <label id="label-name" htmlFor="name">
                  <div style={{ color: "red" }}>*</div>Name
                </label>
                <input
                  id="name"
                  className="billing-input"
                  type="text"
                  placeholder="Jane Doe"
                  autoComplete="name"
                  value={this.state.name}
                  name="name"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row-2">
              <div className="field">
                <label htmlFor="address">Address</label>
                <input
                  id="address"
                  className="billing-input"
                  type="text"
                  placeholder="185 Berry St"
                  required=""
                  autoComplete="address-line1"
                  value={this.state.addressLine1}
                  name="addressLine1"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row-3">
              <div className="field">
                <label htmlFor="address2">Address 2</label>
                <input
                  id="address2"
                  className="billing-input"
                  type="text"
                  placeholder="apt 3, suite#"
                  required=""
                  autoComplete="address-line2"
                  value={this.state.addressLine2}
                  name="addressLine2"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row-4" data-locale-reversible>
              <div className="field">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  className="billing-input"
                  type="text"
                  placeholder="San Francisco"
                  required=""
                  autoComplete="city"
                  value={this.state.addressCity}
                  name="addressCity"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row-5" data-locale-reversible>
              <div className="field">
                <label htmlFor="state">State</label>
                <input
                  id="state"
                  className="billing-input"
                  type="text"
                  placeholder="CA"
                  required=""
                  autoComplete="state"
                  value={this.state.addressState}
                  name="addressState"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row-6" data-locale-reversible>
              <div className="field">
                <label htmlFor="zip">ZIP</label>
                <input
                  id="zip"
                  className="billing-input"
                  type="text"
                  placeholder="94107"
                  required=""
                  autoComplete="postal-code"
                  value={this.state.addressZip}
                  name="addressZip"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row-7" data-locale-reversible>
              <div className="field">
                <label htmlFor="country">Country</label>
                <input
                  id="country"
                  className="billing-input"
                  type="text"
                  placeholder="US"
                  required=""
                  autoComplete="country"
                  value={this.state.addressCountry}
                  name="addressCountry"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="row__creditcard">
              <CardElement />
            </div>
            <button
              className="submit-CC-button"
              type="submit"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }
}
export default injectStripe(StripeForm);