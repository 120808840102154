import React, {useState, useEffect, useRef, MutableRefObject} from 'react';
import PhoneInput from 'react-phone-input-2';
import {makeStyles} from '@material-ui/core/styles';
import {
  TextFieldProps as MuiTextFieldProps,
  InputBase as MuiInputBase,
  InputLabel as MuiInputLabel,
} from '@material-ui/core';
import {Field, FieldProps, FieldRenderProps} from 'react-final-form';

import {colors, text} from '../constants/styles';
import {ErrorPopover} from './error-popover';
import {defaultInputBorderRadius} from '../constants/theme';
import 'react-phone-input-2/lib/style.css';

export type RouteFormTextInputProps<T = string> = Omit<MuiTextFieldProps, 'onChange'> & {
  name: string;
  maxWidth?: string;
  center?: boolean;
  required?: boolean;
  fieldProps?: FieldProps<T, FieldRenderProps<T>>;
  // If true, the input will have the "error" border active
  // WITHOUT any error popover
  overrideError?: boolean;
}

export function RouteFormTextInput<T>(props: RouteFormTextInputProps<T>, ref: MutableRefObject<any>) {
  const {name, label, type, fieldProps, required = false, maxWidth, center, disabled, ...rest} = props;

  return (
    <Field
      name={name}
      type={type}
      {...fieldProps}
      render={
        ({input, meta, ...restRenderProps}: FieldRenderProps<T>) => {
          return (<RouteTextInput
            disabled={disabled}
            maxWidth={maxWidth}
            center={center}
            input={input}
            meta={meta}
            label={label}
            required={required}
            {...restRenderProps}
            {...(rest as any)}
          />);
        }
      }
    >
    </Field>
  );
}

/////////////////////////////////////////////////////////////////////////////////////

const useTextFieldStyles = makeStyles({
  label: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
    color: text.inputLabelColor,
  },
  requiredLabel: {
    color: text.requiredLabelColor,
  },
  inputRoot: {
    display: 'block',
    // The design calls for 0.5px but that doesn't display correctly
    // when the field is being autocompleted
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: (props: any) => props.error ? colors.error : colors.gray,
    borderRadius: defaultInputBorderRadius,
    marginTop: '7px',
  },
  inputElement: {
    // Border box makes height and padding calculations very easy
    boxSizing: 'border-box',
    height: '30px',
    fontSize: '11.5px',
    lineHeight: '14px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:-webkit-autofill': {
      borderRadius: defaultInputBorderRadius,
      borderWidth: '0px',
    }
  },
  disabledInputRoot: {
    marginTop: '7px',
    width: 'auto',
    fontSize: '12px',
    lineHeight: '18px',
  },
  root: {
    width: 'auto',
    textAlign: 'left',
    maxWidth: (props: any) => props.maxWidth || '240px',
    marginLeft: (props: any) => props.center ? 'auto' : undefined,
    marginRight: (props: any) => props.center ? 'auto' : undefined,
  }
}, {name: "RouteTextInput"});

type RouteTextFieldProps<T> = FieldRenderProps<T, HTMLElement> & Omit<MuiTextFieldProps, 'onChange'> & {
  maxWidth?: 'string';
  center?: boolean;
  overrideError?: boolean;
};

function RouteTextInput<T>(props: RouteTextFieldProps<T>) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const open = !!props.meta.touched && !!props.meta.error && !props.meta.active;
    setTooltipOpen(open);
  }, [props.meta.touched, props.meta.error, props.meta.active]);

  const {
    input: { name, value, type, onChange, ...restInput },
    disabled,
    meta,
    required,
    className,
    maxWidth,
    center,
    overrideError,
		...rest
  } = props;
  const classes = useTextFieldStyles({error: overrideError || (!!props.meta.touched && !!props.meta.error), maxWidth, center});

  return (
    <div>
      <div ref={inputRef} className={`${classes.root} ${className}`} {...(rest as any)}>
        <MuiInputLabel className={classes.label} htmlFor={name}>
          <span>
            {props.label}
            {required && !disabled && <span className={classes.requiredLabel}>{' (*Required)'}</span>}
          </span>
        </MuiInputLabel>
        {
          disabled &&
          <div className={classes.disabledInputRoot}>{value}</div>
        }
        {
          !disabled && type !== 'tel' &&
          <MuiInputBase
            classes={{root: classes.inputRoot, input: classes.inputElement}}
            onChange={onChange}
            name={name}
            value={value}
            type={type}
            required={required}
            inputProps={{
              'data-testid': name,
              ...restInput
            }}
            {...rest as any}
          />
        }
        {
          !disabled && type === 'tel' &&
          <PhoneInput
            inputClass={classes.inputElement}
            inputStyle={{root: classes.inputRoot, input: classes.inputElement}}
            preferredCountries={['us']}
            onChange={onChange}
            placeholder=''
            inputProps={{
              'data-testid': name,
              ...restInput,
            }}
            {...rest as any}
          />
        }

      </div>
      <ErrorPopover open={tooltipOpen} content={meta.error} onClose={() => setTooltipOpen(false)} anchorEl={inputRef.current}/>
    </div>
  );
}


