import styled from 'styled-components';
import Colors from '../../../../constants/colors';

export const TooltipWrapper = styled.div`
  cursor: pointer;
`;

export const TooltipOverlay = styled.div<any>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  position: absolute;
  z-index: 999;
  /* top: calc(100% + 10px); */
  right: -25px;
  margin: 15px 0 0 0;
  padding: 35px 35px;
  min-width: 250px;
  /* overflow: hidden; */
  border-width: 0;
  outline: none;
  border-radius: 16px;
  background-color: ${Colors.primaryCardColor};
  filter: drop-shadow(0px 12px 12px rgba(0,0,0,0.25));
`;

export const TooltipCard = styled.div<any>`
  position: relative;
`;

export const TooltipArrow = styled.div<any>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1000;
  right: 28px;
  top: -2px;
  background-color: ${Colors.primaryCardColor};
  margin-top: 0em;

  transform: rotate(-180deg) skewX(-30deg) scale(1,.866);

  &::before, &::after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &, &::before, &::after {
    width:  1em;
    height: 1em;
    border-top-right-radius: 40%;
  }

  &::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  }

  &::after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  }
`;
