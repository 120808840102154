import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  TooltipCard, TooltipWrapper, TooltipOverlay, TooltipArrow,
} from './Tooltip.styles';
import { ITooltipProps } from './interface';
import IconClose from '../IconClose';


const Tooltip: React.FC<ITooltipProps> = ({ wrapper, overlay }: ITooltipProps) => {
  const [show, setShow] = useState(false);

  const handleClickAway = () => {
    setShow(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TooltipCard>
        <TooltipWrapper onClick={() => setShow(!show)}>
          {wrapper}
        </TooltipWrapper>
        <TooltipOverlay show={show}>
          <TooltipArrow show={show} />
          <div style={{
            position: 'absolute',
            right: '15px',
            top: '10px',
            cursor: 'pointer',
          }} onClick={() => setShow(!show)}>
            <IconClose />
          </div>
          {overlay}
        </TooltipOverlay>
      </TooltipCard>
    </ClickAwayListener>
  );
};

export default Tooltip;
