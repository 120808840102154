import axios from 'axios';
import { ROUTE_API_V2 } from '../constants';

const openApi = {
  get: async (path: string, params = {}) => axios.get(ROUTE_API_V2 + path, Object.assign(params, {
    timeout: 120000,
  })),
  post: async (path: string, data: object, params = {}) => axios.post(ROUTE_API_V2 + path, data, Object.assign(params, {
    timeout: 120000,
  })),
};

export default openApi;
