import React, {FC} from 'react';
import {Button as MuiButton, ButtonProps as MuiButtonProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const buttonWidth = '16px';
const useRouteButtonStyles = makeStyles({
  root: {
    height: buttonWidth,
    width: buttonWidth,
    minWidth: buttonWidth,
    borderRadius: buttonWidth,
    padding: '0px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    }
  },
}, {name: 'CloseButton'});

export type CloseButtonProps = {
  onClick?: () => void;
} & MuiButtonProps

export const CloseButton: FC<CloseButtonProps> = (props) => {
  const classes = useRouteButtonStyles(props);
  const {onClick, ...rest} = props;

  return (
    <MuiButton {...rest} classes={{root: classes.root}} onClick={props.onClick}>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49561 3.14404L2.64429 7.99536" stroke="white" strokeLinecap="round"/>
        <path d="M7.49561 7.99536L2.64429 3.14404" stroke="white" strokeLinecap="round"/>
      </svg>
    </MuiButton>
  );
}
