import React from "react"
import { makeStyles, Theme } from "@material-ui/core"

import { colors } from "./../../../constants/styles"
import { ReactComponent as Image } from "./../../../media/help-center.svg"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: "123%",
    maxWidth: 521,
    height: 60,
    margin: '45px -35px', // negative to ignore container padding
    background: colors.offWhite,
    borderRadius: 24,
    fontSize: 12,
    fontWeight: 'bold',
    [theme.breakpoints.up("sm")]: {
      height: 85,
      fontSize: 15,
      margin: '45px auto'
    }
  },
  link: {
    color: colors.blue,
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    textDecoration: "underline",
    flex: 1,
    textAlign: "left",
    paddingLeft: 3
  },
  image: {
    width: "100%",
    height: "120%",
    flex: 1,
    alignSelf: "flex-end"
  }
}))

const HelpCenter = (props: any) => {
  const classes = useStyles(props)

  const openNewPage = () => {
    window.open("https://help.route.com/hc/en-us", "_blank")
  }

  return (
    <div className={classes.root}>
      <Image className={classes.image} />
      <span>Got questions? Check out our</span>
      <button className={classes.link} onClick={openNewPage}>Help Center.</button>
    </div>
  )
}

export default HelpCenter
