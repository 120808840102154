import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {DispatchProps, PasswordReset, FormData} from './reset';
import {resetPassword} from '../../../actions/sign-in/password-reset';

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (data) => {
      dispatch(resetPassword({password: data.password!}));
      return undefined;
    }
  };
}

export const ConnectedPasswordReset = connect(null, mapDispatchToProps)(PasswordReset);
