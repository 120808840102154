import styled from 'styled-components';

export const Label = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-size: 8px;
  color: #FFFFFF;
  font-weight: bold;
  display:inline-flex;
  background: linear-gradient(168.34deg, #73F1F0 15.55%, #62CEDD 126.31%);
  border-radius: 14px;
  margin: 0px 5px;
  padding: 5px 8px;
  height: 9px;
  justify-content: center;
  align-items: center;
`;
