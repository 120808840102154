import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '../../../../assets/icons/close';
import './SideDrawer.scss';

export default function SideDrawer({
  children, side = 'right', onSelected = '', onSelect,
}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [title, setTitle] = useState();
  const [classe, setClass] = useState('');


  const toggleDrawer = (sided, opened) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [sided]: opened });

    if (!opened) {
      onSelect({});
    }
  };

  useEffect(() => {
    setState({ ...state, [side]: Object.keys(onSelected).length > 0 });
  }, [onSelected]);

  useEffect(() => {
    if (document.location.pathname.includes('orders') || document.location.pathname.includes('billing')) {
      setTitle('Order Details');
      setClass('extended-plus');
    } else if (document.location.pathname.includes('issues')) {
      setTitle('Issue Details');
      setClass('extended');
    }
  }, []);

  return (
    <div>
      <ClickAwayListener onClickAway={toggleDrawer('right', false)}>
        <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
          className='sidedrawer'
        >
          <div className={`sidedrawer-header ${classe}`} onClick={toggleDrawer('right', false)}>
            <h4 className="title">
              {title}
            </h4>
            <CloseIcon className="icon" fill="#666666" height='10px' width='10px' />
          </div>
          <div className={`sidedrawer-content ${classe}`} role="presentation">
            {children}
          </div>
        </SwipeableDrawer>
      </ClickAwayListener>
    </div>
  );
}
