import React from 'react';
import {Bold} from '../components';
import {ValidatorWithoutOtherValues} from './index';

const errorFragment = <div>This does not appear to be a valid phone #. <Bold>Please check formatting and try again.</Bold></div>;
const phoneValidate = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

export const phonePatternValidator: ValidatorWithoutOtherValues<string> = (value) => {
  // We only want to check that the value is valid if we have a value at all.
  // We will interpret an undefined/null value and the empty string as "no value"
  if (value === undefined || value === null || value === '') {
    return undefined;
  }

  if (typeof value !== 'string') {
    return errorFragment;
  }

  return value.match(phoneValidate) ? undefined : errorFragment;
}
