import axios from 'axios';
import { addBreadcrumb, Severity } from '@sentry/browser';
import { getMerchantToken } from './api';
import { ROUTE_API } from '../constants';
import dictionary from '../constants/dictionary';

export type IssueResponse = {
  'merchantId': String,
  'merchantRules': {
    'id': String,
    'createdOn': Date,
    'merchantId': String,
    'userId': String,
    'fileMaxHours': BigInt,
    'noPoliceMaxUsd': BigInt,
    'noDeliveryMinHours': BigInt,
    'noDeliveryMaxHours': BigInt,
    'autoApproveLostMaxUsd': BigInt,
    'deliveredMinHours': BigInt,
    'deliveredMaxHours': BigInt,
    'autoApproveMaxStolenUsd': BigInt,
    'autoApproveDamagedMaxUsd': BigInt,
    'replaceOverMaxPercent': BigInt
  },
  'routeRules': {
    'id': String,
    'createdOn': Date,
    'merchantId': String,
    'userId': String,
    'fileMaxHours': BigInt,
    'noPoliceMaxUsd': BigInt,
    'noDeliveryMinHours': BigInt,
    'noDeliveryMaxHours': BigInt,
    'autoApproveLostMaxUsd': BigInt,
    'deliveredMinHours': BigInt,
    'deliveredMaxHours': BigInt,
    'autoApproveMaxStolenUsd': BigInt,
    'autoApproveDamagedMaxUsd': BigInt,
    'replaceOverMaxPercent': BigInt
  }
};
const getRequestOptions = (token: string) => ({
  headers: {
    'Content-Type': 'application/json',
    token: token || getMerchantToken(),
  },
});

export const getMerchantIssueRules: any = async (
  merchantId: string,
  token: string,
) => {
  try {
    const { data: ruleSet } = await axios.get<IssueResponse>(
      `${ROUTE_API}/merchants/${merchantId}/rules`,
      getRequestOptions(token),
    );

    const reformatRules = (keys: any, data: any) => {
      const formatted = { ...data };
      keys.forEach((key: string) => {
        if (key.includes('Usd')) {
          formatted[key] = data[key] / 100;
        } else if (key.includes('Hours')) {
          formatted[key] = data[key] / 24;
        }
      });
      return formatted;
    };

    let formattedRules = { ...ruleSet };
    if (ruleSet.merchantRules) {
      const reformattedMerchRules = reformatRules(
        Object.keys(ruleSet.merchantRules),
        ruleSet.merchantRules,
      );
      formattedRules = {
        ...formattedRules,
        merchantRules: reformattedMerchRules,
      };
    }

    const reformattedRouteRules = reformatRules(
      Object.keys(ruleSet.routeRules),
      ruleSet.routeRules,
    );

    const allFormattedRules = { ...formattedRules, routeRules: reformattedRouteRules };
    return allFormattedRules;
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_MERCHANT_ISSUE_RULES,
      category: 'merchant_issue_rules',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export const createMerchantIssue = async (
  rules: any,
  merchantId: string,
  token: string,
) => {
  try {
    const keys = Object.keys(rules);
    const reformattedRules = { ...rules };
    delete reformattedRules.merchantId;
    delete reformattedRules.createdOn;
    delete reformattedRules.id;

    keys.forEach((key) => {
      const value = reformattedRules[key];
      if (key.includes('Usd')) {
        reformattedRules[key] = value * 100;
      } else if (key.includes('Hours')) {
        reformattedRules[key] = value * 24;
      }
    });

    const { data: newRules } = await axios.post(
      `${ROUTE_API}/merchants/${merchantId}/rules?platform=portal`,
      reformattedRules,
      getRequestOptions(token),
    );

    return newRules;
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_MERCHANT_ISSUE_RULES,
      category: 'merchant_issue_rules',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export default {
  getMerchantIssueRules,
};
