import React, { useState, useEffect } from 'react';
import {
  Box, List, ListItem, ListItemIcon, Checkbox, ListItemText,
} from '@material-ui/core';
import Button from '../../../Button/Button';
import TitleAndNav from './TitleAndNav';
import bulletSquare from '../../../../assets/images/bullet-square-icon.jpg';
import circularCheckbox from '../../../../assets/images/circular-checkbox.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected.svg';
import './ShopifyImplementation.scss';
import { upsertMerchant, hasDealSizeLimitAmount } from '../../../../services/merchant';
import ReactGA from 'react-ga';

const ShopifyImplementation = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentChecked, setCurrentChecked] = useState(0);
  let manualCheckboxLabel = "You'd like to do it yourself";
  const singleCheckboxLabel = 'I understand';
  const [checkboxes, setCheckboxes] = useState([
    'Expert implementation!',
    manualCheckboxLabel,
  ]);

  useEffect(() => {
    const { install_method } = props.userSession.activeBrand;
    const activeCheckbox = install_method === 'expert' ? 0 : 1;
    setCurrentChecked(activeCheckbox);

    if (!hasDealSizeLimitAmount(props.userSession.activeBrand)) {
      setCheckboxes([
         singleCheckboxLabel,
      ]);
      setCurrentChecked(0);
    }
  }, []);

  const handleSubmitButton = async (e) => {
    const { id, prod_api_secret } = props.userSession.activeBrand;
    const method = currentChecked === 0 && hasDealSizeLimitAmount(props.userSession.activeBrand) ? 'expert' : 'manual';
    setLoading(true);

    // Google analytics
    ReactGA.event({ category: 'Onboarding', action: 'Implementation type selected', label: `${method}` });

    const resp = await upsertMerchant('install_method', method, id, prod_api_secret);
    props.closeDrawer();

    if (resp.data) {
      resp.data = Object.assign(resp.data, { has_route_plus: true });
      props.updateActiveBrand(resp.data);
      setLoading(false);
    }

    e.preventDefault();
  };

  return (
      <div className='shopify-implementation'>
          <TitleAndNav title="Implementation" close={props.closeDrawer} goBack={props.goBack} />

          <div className='update-payment-method-upper-text'>
            { hasDealSizeLimitAmount(props.userSession.activeBrand) &&
               <React.Fragment>
                  <p>As a Shopify store, you have two options to place Route Plus Package Protection on your site:</p>

                  <h3 className="shopify-implementation-title">Expert Implementation</h3>

                  <ul className="nested-bullet-list">
                     <li>
                        <img
                           srcSet={bulletSquare}
                           className="bullet"
                        />
                        <span>
                           Our team will be in contact
                           within 3 business days to
                           request any necessary
                           permissions and finalize
                           the implementation
                        </span>
                     </li>
                     <li>
                        <img
                           srcSet={bulletSquare}
                           className="bullet"
                        />
                        <span>
                           No additional cost to you
                        </span>
                     </li>
                  </ul>
               </React.Fragment>
            }

            <h3 className="shopify-implementation-title">Self Implementation</h3>
            <ul className="nested-bullet-list">
               <li>
                  <img
                     srcSet={bulletSquare}
                     className="bullet"
                  />
                  <span>
                    Instructions for self implementation can be found <a href="https://help.route.com/hc/en-us/articles/360038579473-How-to-self-install-Route-on-your-store" target="_blank" rel="noopener norefferer" className="shopify-implementation-link">here</a>.
                  </span>
               </li>
            </ul>
        </div>

         <Box mt="-7px">
            <List
               component="nav"
               aria-labelledby="nested-list-subheader"
               className="nested-list"
            >
               {checkboxes.map((item, i) => (
                  <ListItem key={i} onClick={() => setCurrentChecked(i)} alignItems="flex-start" className="item-list">
                     <ListItemIcon className='checkbox'>
                        <Checkbox
                           onChange={() => setCurrentChecked(i)}
                           checked={currentChecked === i}
                           icon={
                              <img
                                 alt="checkbox icon image"
                                 srcSet={circularCheckbox}
                                 width={20}
                              />
                           }
                           checkedIcon={
                              <img
                                 alt="checkbox icon image"
                                 srcSet={circularCheckboxChecked}
                                 width={20}
                              />
                           }
                           value="checked"
                           color="primary"
                        />
                     </ListItemIcon>
                     <ListItemText primary={item}/>
                  </ListItem>
               ))}
            </List>
         </Box>

         <Button className='update-company-details-save-button' type='submit'
        onClick={handleSubmitButton}
        isLoading={loading}
        text="Save" />
      </div>
  );
};

export default ShopifyImplementation;
