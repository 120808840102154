import React from 'react';
import MobileDraw from './Notification-ReadyToShip-Push-Preview.png';

import {
  Container,
  Draw,
} from './MobilePreview.styles';

const MobilePreview: any = (props: any) => {
  return (
    <Container>
      <Draw srcSet={props.draw ? props.draw : MobileDraw}></Draw>
    </Container>
  );
};

export default MobilePreview;
