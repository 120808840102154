import React, {FC} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Form, FormRenderProps} from 'react-final-form';

import {HeaderContainer, ContainerContent, Button, RouteFormTextInput} from '../../../components';
import {createRequiredValidator} from '../../../validation/required';

export type OwnProps = {};

export type StateProps = {};

export type DispatchProps = {
  onSubmit: (data: Partial<FormData>) => void;
}

export type FormData = {
  password: string;
  confirmPassword: string;
};

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  },
  title: {
    textAlign: 'left',
    marginTop: '75px',
  },
  form: {
    marginTop: '45px',
  }
}), {name: 'RequestPasswordPage'});



export const PasswordReset: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <HeaderContainer>
      <ContainerContent width={390}>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>Please Change Your Password</Typography>
            </Grid>
            <Grid className={classes.form} item xs={12}>
              <Form onSubmit={props.onSubmit}>
                {(formProps: FormRenderProps<FormData>) => {
                  return <InternalForm {...props} {...formProps}/>;
                }}
              </Form>
            </Grid>
          </Grid>
        </div>
      </ContainerContent>
    </HeaderContainer>
  );
};

const passwordRequiredValidator = createRequiredValidator('This field is required. Please enter a new password.');
const confirmPasswordSameValidator = (value: unknown, allValues: Partial<FormData>) => {
  return value === allValues.password ? undefined : "Uh-oh, looks like your passwords don't match. Please try again.";
}

const useFormStyles = makeStyles({
  password: {
    marginTop: '25px',
  },
  confirmPassword: {
    marginTop: '25px',
  },
  button: {
    marginTop: '45px',
    marginBottom: '55px',
  },
}, {name: 'PasswordResetPage-internal'});

const InternalForm: FC<Props & FormRenderProps<FormData>> = (props) => {
  const classes = useFormStyles();
  const {invalid: formInvalid} = props;

  return (
    <Grid container>
      <form onSubmit={props.handleSubmit}>
        <Grid item xs={12}>
          <RouteFormTextInput
            type="password"
            id="password"
            name="password"
            label="New Password"
            fieldProps={{
              name: 'password',
              validate: passwordRequiredValidator,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.confirmPassword}>
          <RouteFormTextInput
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm New Password"
            fieldProps={{
              name: 'confirmPassword',
              validate: confirmPasswordSameValidator,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.button}>
          <Button width={240} type="submit" disabled={formInvalid}>
            {'Change Password'}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
