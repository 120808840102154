import React from 'react';
import {
  Switch, Redirect, Route, BrowserRouter,
} from 'react-router-dom';
import AccountCreation from './AccountCreation/AccountCreation';
import InstallationInstructions from './InstallationInstructions/InstallationInstructions';
import OnBoardComplete from './OnBoardComplete/OnBoardComplete';
import Billing from './Billing/Billing';
import UpdateInAppBrand from './UpdateInAppBrand/UpdateInAppBrand';
import NotFound from '../_shared/ErrorPages/NotFound';
import RoutePackageProtection from './RoutePackageProtection/RoutePackageProtection';
import UpdateRouteEmail from './UpdateRouteEmail/UpdateRouteEmail';
import SetUpPassword from './SetUpPassword/SetUpPassword';
import LinkMerchant from './LinkMerchant/LinkMerchant';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (localStorage.getItem('merchant') ? (
        <Component {...props} />
    ) : (
        <Redirect
          to={{
            pathname: '/onboarding',
            state: { from: props.location },
          }}
        ></Redirect>
    ))}
  />
);

const OnboardingRoutes = ({ userSession, setUserSession }) => (
    <BrowserRouter>
    <Switch>
      <Redirect exact from='/onboarding' to='/onboarding/account-creation' />
      <Route exact path='/onboarding/account-creation' render={(props) => <AccountCreation setUserSession={setUserSession} userSession={userSession} {...props}/>} />
      <Route exact path='/onboarding/link-merchant' render={(props) => <LinkMerchant {...props}/>} />
      <Route exact path='/onboarding/set-password' render={(props) => <SetUpPassword setUserSession={setUserSession} userSession={userSession} {...props} />} />
      <PrivateRoute exact path='/onboarding/route-package-protection' component={RoutePackageProtection} />
      <PrivateRoute exact path='/onboarding/installation-instructions' component={InstallationInstructions} />
      <PrivateRoute exact path='/onboarding/billing' component={Billing} />
      <PrivateRoute exact path='/onboarding/personalize-your-in-app-brand' component={UpdateInAppBrand} />
      <PrivateRoute exact path='/onboarding/update-your-email' component={UpdateRouteEmail} />
      <PrivateRoute exact path='/onboarding/successfully' component={OnBoardComplete} />
      <PrivateRoute path='*' component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default OnboardingRoutes;
