import React from 'react';
import { FormControlLabel, Radio, RadioProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { dataUri as radioCheckDataUri } from '../media/radio-check';
import { colors } from './../constants/styles'

type RouteRadioProps<T> = {
  label: string;
  value: T;
  description?: string;
  className?: string;
}

type RouteLabelProps = {
  label: string;
  description?: string;
}

const useStyles = makeStyles({
  root: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  rootLabel: {
    textAlign: 'left',
    display: 'flex !important',
    flexDirection: 'column'
  },
  label: {
    fontWeight: 'bold',
    color: colors.black,
  },
  description: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 1.2,
  }
}, { name: 'RouteRadio' });

const RouteLabel = (props: RouteLabelProps) => {
  const classes = useStyles();

  const { label, description } = props
  return (
    <>
      <span className={classes.label}>{label}</span>
      <span className={classes.description}>{description}</span>
    </>
  )
}

export function RouteRadioButton<T>(props: RouteRadioProps<T>) {
  const baseClasses = useStyles();
  const labelClasses = {
    root: `${baseClasses.root}`,
    label: baseClasses.rootLabel
  }

  const { label, value, description } = props;

  return (
    <FormControlLabel
      className={props.className}
      labelPlacement="end"
      label={<RouteLabel label={label} description={description} />}
      classes={labelClasses}
      control={<StyledRadio />}
      value={value}
    />
  );
}

const useStyledRadioStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: '15px'
  },
  radioRoot: {
    marginRight: '15px',
  },
  icon: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    border: '1px solid #CCCCCC',
  },
  checkedIcon: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      margin: 3,
      backgroundImage: `url(${radioCheckDataUri})`,
      content: '""',
    }
  },
}, { name: 'StyledRadio' });

function StyledRadio(props: RadioProps & { dataId?: string }) {
  const classes = useStyledRadioStyles();
  const { classes: propClasses, dataId, ...restProps } = props;

  return (
    <Radio
      data-testid={dataId}
      className={`${classes.root} ${propClasses && propClasses.root}`}
      classes={props.classes}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...restProps}
    />
  );
}