import {Dispatch} from 'react';
import {connect} from 'react-redux';

import {submitForm} from '../../actions/premiums-transfer';
import {FormData} from './types';
import {TransferPremiums, DispatchProps} from './transfer';
import {completeLater} from '../../actions/premiums-transfer/complete-later';


export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (data: FormData) => {
      dispatch(submitForm(data));
      return undefined;
    },
    onFinishLater: () => dispatch(completeLater()),
  };
}

export const ConnectedTransferPremiums = connect(null, mapDispatchToProps)(TransferPremiums);
