import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Popper as MuiPopper,
  ClickAwayListener,
} from '@material-ui/core';

import { colors } from '../constants/styles';
import { CloseButton } from './close-button';

const useTooltipStyles = makeStyles({
  root: {
    // This will end up being 240px with 10px left and right border
    width: '220px',
    backgroundColor: colors.info,
    padding: '5px',
    borderRadius: '8px',

    '[x-placement*="bottom"] &': {
      marginTop: '10px',
    },
    '[x-placement*="top"] &': {
      marginBottom: '10px',
    },
  },
  content: {
    marginRight: 15,
    fontFamily: 'Titillium Web',
    color: colors.white,
    fontSize: '10px',
    lineHeight: '14px',
    width: '100%'
  },
  arrow: {
    // Makes anti-aliasing better in webkit browsers
    '-webkit-transform': 'rotate(360deg)',
    fill: colors.info,
    position: 'absolute',
    left: '50%',
    '[x-placement*="bottom"] &': {
      top: 0,
      transform: 'scaleY(-1)'
    },
    '[x-placement*="top"] &': {
      bottom: `0px`,
    },
  },
  roundedArrowPath: {
    strokeWidth: 20,
    stroke: colors.info,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  },
  closeButton: {
    position: 'fixed',
    right: '10px',
    top: '10px',
  }
}, { name: 'TooltipContent' });

type PopoverContentProps = {
  content: React.ReactNode;
  onClose: () => void;
}

const PopoverContent = React.forwardRef((props: PopoverContentProps, ref: any) => {
  const classes = useTooltipStyles();

  return (
    <div>
      <div ref={ref} className={classes.root}>
        <div className={classes.content}>{props.content}</div>
        <CloseButton className={classes.closeButton} types='secondary' onClick={props.onClose} />
        <svg className={classes.arrow} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.64701 10.2656C7.84102 11.3985 6.15843 11.3985 5.35243 10.2656L0.487696 3.42748C-0.464488 2.08905 0.492414 0.233944 2.13499 0.233945L11.8645 0.233945C13.507 0.233946 14.4639 2.08905 13.5118 3.42748L8.64701 10.2656Z" fill="#333333" />
        </svg>
      </div>
    </div>
  );
});


export type PopoverProps = {
  content: React.ReactNode;
  anchorEl: any;
  open: boolean;
  onClose: () => void;
}

export function InfoPopover(props: PopoverProps) {
  const { content, open, onClose, anchorEl } = props;

  return (
    <MuiPopper
      open={open}
      anchorEl={anchorEl}
      placement="top"
    >
      <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
        <PopoverContent content={content} onClose={onClose} />
      </ClickAwayListener>
    </MuiPopper>
  );
}
