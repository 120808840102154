import React, { Fragment } from 'react';
import ShipmentMap from '../ShipmentMap';
import DeliveryHistory from '../DeliveryHistory';
import ShipmentHeader from '../ShipmentHeader';
import { Container } from './style';
import TrackingNumber from '../TrackingNumber';
// import Feature from '../Feature';
// const DownloadiOS = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/images/download-ios.svg';

const Shipment = (props) => {
  console.log('internal', props.item, props);
  return (
    <Fragment>
      {props.item && props.item.checkpoints ? (
        <Container hasShipped={props.item.checkpoints}>
          <ShipmentHeader estDeliveryDate={props.item.estimatedDeliveryDate} status={props.item.status} />
          <ShipmentMap {...props}></ShipmentMap>
          <div>
            <TrackingNumber number={props.item.trackingNumber} />
            <DeliveryHistory checkpoints={props.item.checkpoints}></DeliveryHistory>
          </div>
        </Container>
      ) : (
        <Container hasShipped={false}>
          <ShipmentHeader />
          <ShipmentMap {...props}></ShipmentMap>
          {props.children}
        </Container>
      )}
    </Fragment>
  );
};

export default Shipment;
