import React from 'react';

const SVG = ({
  style = {},
  circleColor = '#66C1CB',
  lineColor = '#FFF',
  width = '50px',
  className = '',
  height = '50px',
  viewBox = '0 0 50 50',
  transform = '',
  isActive = false,
  doOnClick = () => { },
}) => <svg
  width={width}
  cursor={'pointer'}
  style={style}
  height={height}
  viewBox={viewBox}
  className={className}
  transform={transform}
  onClick={doOnClick}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
    <g filter="url(#filter0_d)">
      <rect x="10" y="10" width="30" height="30" rx="15" fill={isActive ? circleColor : lineColor} />
    </g>
    <path fill={isActive ? lineColor : circleColor} d="M23.0003 29.9991C22.6883 29.9991 22.3903 29.8531 22.2003 29.5991L20.0483 26.7291L18.8323 28.5541C18.5253 29.0131 17.9043 29.1361 17.4453 28.8311C16.9863 28.5241 16.8613 27.9041 17.1683 27.4441L19.1683 24.4441C19.3483 24.1731 19.6483 24.0081 19.9733 23.9991C20.3003 24.0041 20.6053 24.1391 20.8003 24.3991L22.8293 27.1041L25.1053 22.5511C25.2603 22.2411 25.5643 22.0331 25.9103 22.0021C26.2583 21.9681 26.5923 22.1211 26.8003 22.3991L28.8293 25.1041L31.1053 20.5511C31.3523 20.0581 31.9523 19.8591 32.4473 20.1041C32.9413 20.3511 33.1413 20.9521 32.8943 21.4461L29.8943 27.4461C29.7393 27.7561 29.4353 27.9641 29.0893 27.9951C28.7413 28.0251 28.4073 27.8771 28.1993 27.5991L26.1713 24.8941L23.8953 29.4461C23.7393 29.7561 23.4363 29.9641 23.0903 29.9951C23.0603 29.9981 23.0293 29.9991 23.0003 29.9991Z" />
    <defs>
      <filter id="filter0_d" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>;

export default SVG;
