import React from 'react';

const SVG = ({
  style = {},
  fill = '#313222',
  width = '16px',
  className = '',
  height = '11px',
  viewBox = '0 0 20 20',
  transform = '',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M16 7.12839C13.8297 4.94012 11.8125 2.7905 9.65954 0.783679C8.53139 -0.268873 7.47355 -0.231071 6.25607 0.716477C5.17259 1.55902 4.20738 2.55277 3.05772 3.60112C4.94596 4.04046 6.05178 5.23245 7.18489 6.41773C8.26093 7.54252 9.39156 8.61944 10.5445 9.66779C11.3807 10.428 12.3724 10.4112 13.2144 9.73331C14.2036 8.93445 15.0803 7.99866 16 7.12839ZM1.0297 9.95676C2.29018 8.71688 3.48781 7.53832 4.69949 6.34633C3.96256 5.06361 1.86093 5.03169 0.827065 6.05736C-0.124913 7.00155 -0.491313 8.82944 1.0297 9.95676Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
