import {captureException, addBreadcrumb, Severity, captureMessage} from '@sentry/browser';
import {push} from 'connected-react-router';
import {FormData} from '../../pages/account-creation/account-creation-form';
import {CreateUserErrorReason} from '../../api'
import {loginUser} from '../../actions/sign-in';
import {OnboardingStatus} from '../../api/types';
import {State, ThunkDispatch} from '../../store';
import {createNecessaryUserAndMerchant} from './account-creation-helpers';
import { updateActiveMerchant } from '../user-data/merchants';

export const ACCOUNT_CREATION_STARTED = 'ACCOUNT_CREATION_STARTED' as const;
export interface AccountCreationStartedAction {
  type: typeof ACCOUNT_CREATION_STARTED;
}

export function accountCreationStarted(): AccountCreationStartedAction {
  return {
    type: ACCOUNT_CREATION_STARTED,
  };
}

export const ACCOUNT_CREATION_SUCCEEDED = 'ACCOUNT_CREATION_SUCCEEDED' as const;
export interface AccountCreationSucceededAction {
  type: typeof ACCOUNT_CREATION_SUCCEEDED;
}

export function accountCreationSucceeded(): AccountCreationSucceededAction {
  return {
    type: ACCOUNT_CREATION_SUCCEEDED,
  };
}

export const ACCOUNT_CREATION_FAILED = 'ACCOUNT_CREATION_FAILED' as const;
export interface AccountCreationFailedAction {
  type: typeof ACCOUNT_CREATION_FAILED;
  errorReason?: CreateUserErrorReason;
  errorMessage?: string;
}

export function accountCreationFailed(data: {message?: string; reason?: CreateUserErrorReason} = {}): AccountCreationFailedAction {
  return {
    type: ACCOUNT_CREATION_FAILED,
    errorMessage: data.message,
    errorReason: data.reason,
  };
}

export const HIDE_DOMAIN_EXISTS_ERROR = 'HIDE_DOMAIN_EXISTS_ERROR' as const;
export interface HideDomainExistsErrorAction {
  type: typeof HIDE_DOMAIN_EXISTS_ERROR;
}

export function hideDomainExistsErrorActionCreator() {
  return {
    type: HIDE_DOMAIN_EXISTS_ERROR,
  };
}

export type AppActionTypes =
  AccountCreationStartedAction |
  AccountCreationSucceededAction |
  AccountCreationFailedAction;

export const createAccount = (data: FormData) => {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Starting account creation process',
        category: 'Account Creation',
        level: Severity.Info,
      });
      dispatch(accountCreationStarted());

      const possibleUserResponse = await createNecessaryUserAndMerchant({
        formData: data,
        accountCreationData: getState().app.accountCreation,
      });

      if (possibleUserResponse?.successful === false) {
        switch(possibleUserResponse.reason) {
          case 'PasswordResetGuidExpired': {
            addBreadcrumb({
              message: 'Password reset guid expired',
              category: 'Account Creation',
              level: Severity.Info,
            });
            // We dispatch a success because we don't want to show an error message
            dispatch(accountCreationSucceeded());
            return dispatch(push('/onboarding/account-creation/password-expired'));
          }
          case 'MerchantExists': {
            addBreadcrumb({
              message: 'Merchant already exists',
              category: 'Account Creation',
              level: Severity.Info,
            });
            return dispatch(accountCreationFailed({
              reason: 'MerchantExists',
            }));
          }
          case 'User Exists': {
            addBreadcrumb({
              message: 'User account exists',
              category: 'Account Creation',
              level: Severity.Info,
            });
            // We dispatch a success because we don't want to show an error message
            dispatch(accountCreationSucceeded());
            return dispatch(push('/onboarding/account-creation/error'));
          }
          default: {
            captureMessage(`Account creation failed: "${possibleUserResponse.message}"`);
            return dispatch(accountCreationFailed({
              message: possibleUserResponse.message,
              reason: possibleUserResponse.reason,
            }));
          }
        }
      }

      await dispatch(loginUser({
        username: data.email,
        password: data.password,
      }));

      const userState = getState().app.userData;
      if (!userState.loggedIn || !userState.activeBrand) {
        throw new Error('Unknown error occurred while logging in after account creation');
      }

      await dispatch(updateActiveMerchant({
        id: userState.activeBrand.id,
        prod_api_secret: userState.activeBrand.prod_api_secret,
        onboarding_status: OnboardingStatus.AccountCreated,
        merchant_preferences: {
          ...userState.activeBrand.merchant_preferences,
          opt_in: false,
          opt_out: true,
        },
      }));

      addBreadcrumb({
        message: 'User account creation successful',
        category: 'Account Creation',
        level: Severity.Info,
      });
      dispatch(accountCreationSucceeded());
      return dispatch(push('/onboarding/configure-route-plus'));
    } catch (err) {
      captureException(err);
      return dispatch(accountCreationFailed({
        message: err?.message,
      }));
    }
  }
}
