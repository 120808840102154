import React from 'react';
import colors from '../../constants/colors';

const SVG = ({
  style = {},
  fill = colors.primaryColor,
  width = '8',
  className = '',
  height = '6',
  viewBox = '0 0 8 6',
  transform = 'rotate(0)',
  hoverState = false,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    hoverstate={hoverState}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.436389 0H7.56439C7.96039 0 8.11239 0.5 7.91239 0.777L4.36739 5.754C4.16539 6.029 3.83539 6.099 3.63339 5.822L0.0883893 0.742C-0.113611 0.466 0.0423893 0 0.436389 0Z"
      fill={fill}
      style={style}
    />
  </svg>
);

export default SVG;
