import React from "react";
const SVG = ({
  style = {},
  className = '',
  width= '17px',
  height = '18px',
  viewBox = '0 0 17 18',
  transform = '',
}) =>
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill='none'
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<g clipPath="url(#clip0)">
<path d="M16.9982 8.99951C16.9982 13.6945 13.1932 17.4995 8.50324 17.4995C3.81324 17.4995 -0.206754 13.6895 0.00824551 8.99951C0.243245 3.71951 3.88324 0.329515 8.50324 0.504515C13.1932 0.684515 16.9982 4.30951 16.9982 8.99951Z" fill="#F1373C"/>
<path d="M8.06979 4.11453L3.67979 11.7195C3.48979 12.0495 3.72979 12.4645 4.10979 12.4645H12.8898C13.2748 12.4645 13.5148 12.0495 13.3198 11.7195L8.93479 4.11453C8.74479 3.77953 8.26479 3.77953 8.06979 4.11453Z" fill="white"/>
<path d="M8.50399 9.87023C8.24399 9.87023 8.02899 9.66523 8.01399 9.40523L7.86399 6.76023C7.84899 6.48023 8.07399 6.24023 8.35399 6.24023H8.65399C8.93399 6.24023 9.15899 6.47523 9.14399 6.76023L8.99399 9.40523C8.97899 9.66523 8.76399 9.87023 8.50399 9.87023Z" fill="#F1373C"/>
<path d="M8.50359 11.6361C8.8184 11.6361 9.07359 11.3809 9.07359 11.0661C9.07359 10.7513 8.8184 10.4961 8.50359 10.4961C8.18879 10.4961 7.93359 10.7513 7.93359 11.0661C7.93359 11.3809 8.18879 11.6361 8.50359 11.6361Z" fill="#F1373C"/>
</g>
<defs>
<clipPath id="clip0">
<rect y="0.5" width="17" height="17" fill="white"/>
</clipPath>
</defs>  </svg>;

export default SVG;

