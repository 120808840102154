import styled, { css } from 'styled-components';
import Colors from '../../../../constants/colors';

export const Container = styled.div<any>`
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 20px;
  /* overflow: hidden; */
  border-width: 0;
  outline: none;
  border-radius: 24px;
  background-color: ${Colors.primaryCardColor};
  margin-top: 10px;

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    `};
`;

export const Arrow = styled.div<any>`
  content: '';
  position: absolute;

  left: calc(50% - 25px);

  &.triangle {
    line-height: 1;
    width: 20px;
    height: 20px;
    border-top-right-radius: 40%;
    background: ${(props) => (props.background ? props.background : Colors.primaryCardColor)};
    text-align: left;
    display: inline-block;
    margin: 1rem;

    transition: background-color 0.45s ease;

    &:hover {
      background: ${(props) => (props.background ? props.background : Colors.primaryCardColor)};
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background: ${(props) => (props.background ? props.background : Colors.primaryCardColor)};
      width: 100%;
      height: 100%;
      border-top-right-radius: 40%;
    }

    &.up {
      transform: rotate(60deg) skewX(-30deg) scale(1, 0.866);
      transform-origin: 30% 90%;

      top: -30px;
    }
    &.down {
      transform: rotate(-120deg) skewX(-30deg) scale(1, 0.866);
      transform-origin: 30% 45%;

      bottom: -25px;
    }
    &.left {
      transform: rotate(90deg) skewX(-30deg) scale(1, 0.866);
      transform-origin: 45% 75%;
      top: calc(50% - 31px);
      left: 10px;
    }
    &.right {
      transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
      transform-origin: 45% 75%;
    }

    &:before {
      transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
    }
    &:after {
      transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    }
  }
`;
