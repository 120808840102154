import { addBreadcrumb, Severity } from '@sentry/browser';
import openApi from './open-api';
import { getHeadersWithToken } from './utils';
import { getUserSession } from '../stores/SessionManager';

export const getMerchantServiceTier = async (merchantId: string, token: string) => {
  try {
    const { data } = await openApi.get(`/merchants/${merchantId}/service-tier`, getHeadersWithToken(token));
    return data;
  } catch (err) {
    addBreadcrumb({
      message: '',
      category: 'merchant_service_tier',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
  }
  return {
    service_tier: 'ROUTE',
  };
};

export const getActiveMerchantServiceTier = () => {
  try {
    const userSession = getUserSession();
    return userSession?.tier;
  } catch (error) {

  }
  return 'ROUTE';
};

export const userLoggedAsRoutePro = () => {
  const services = getActiveMerchantServiceTier();
  return services?.service_tier === 'ROUTE_PRO';
};

export default {
  getMerchantServiceTier,
};
