export const testIds = {
  EMAIL_ADDRESS: 'email_address',
  FILE_A_CLAIM: 'file_a_claim',
  ORDER_DATE: 'order_date',
  ROUTE_ORDER_ID: 'route_order_id',
};

export default {
  testIds,
};
