import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Label = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: #333;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerMap = styled.div`
  position: relative;
`;

export const ContentMapInfo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const ContainerZoom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const ContentZoom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 14px;
  border: 1px solid ${colors.primaryColor};
`;

export const ButtonZoomIn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: ${colors.primaryColor};
  padding-right: 10px;
  text-align: center;
`;

export const ButtonZoomOut = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: ${colors.primaryColor};
  padding-left: 10px;
  text-align: center;
`;

export const NavigationPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  height: 20px;
  color: ${colors.primaryColor};
  text-align: center;
  border-right: 1px solid ${colors.primaryColor};
  border-left: 1px solid ${colors.primaryColor};
  padding-right: 5px;
  padding-left: 5px;
`;

export const Cluster = styled.div<any>``;

export const Card = styled.div`
  width: 160px;
  padding: 25px;
`;

export const CardTitle = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #999999;
`;

export const CardSubTitle = styled.p<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.color ? props.color : '#333333')};
`;

export const CardSubTitleHighlight = styled.p<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  color: ${(props) => (props.color ? props.color : '#333333')};
`;
