const colors = {
  routeBlue: '#31C8E0',
  routeMidnight: '#222222',
  routeDark: '#023646',
  routeDusk: '#51868E',
  gray1: '#666666',
  gray2: '#999999',
  gray3: '#CCCCCC',
  gray4: '#DDDDDD',
  gray5: '#EEEEEE',
  carbon: '#333333',
  squant: '#666666',
  black: '#000000',
  white: '#ffffff',
  coldMorning: '#E5E5E5',
  alabaster: '#F7F7F7',
  darkBlue: '#14869F',
  darkGray: '#999999',
  fordSilver: '#CCCCCC',
  kellyGreen: '#249D0E',
  lightGray: '#CCCCCC',
  red: '#F06350',
  darkYellow: '#FFBB23',
};

export default {
  primaryColor: colors.routeBlue,
  secondaryColor: colors.carbon,
  primaryBackground: colors.routeBlue,
  secondaryBackground: colors.carbon,
  primaryText: colors.squant,
  secondaryText: colors.white,
  primaryButtonBackground: colors.routeBlue,
  primaryButtonText: colors.white,
  secondaryButtonBackground: colors.darkBlue,
  secondaryTextBackground: colors.black,
  hoverLinkColor: colors.darkBlue,
  successColor: colors.kellyGreen,
  redColor: colors.red,
  darkGray: colors.darkGray,
  gray: colors.fordSilver,
  primaryCardColor: colors.white,
  gray3: colors.lightGray,
  white: colors.white,
  black: colors.black,
  darkYellow: colors.darkYellow,
  primaryBoxBackground: colors.alabaster,
  buttonDark: colors.routeDark,
  buttonLigh: colors.alabaster,
};
