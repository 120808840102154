import React, { FC } from 'react';
import { Button } from '../../../../components/Brand/components/button';
import IconClose from '../../components/IconClose';
import { Container, Header, Description, LineBreak } from './ErrorBox.styles';

export type ErrorBoxProps = {
  bgColor?: string;
  label?: string;
  description?: string;
  icon?: any;
  close: () => void;
  marginTop?: string;
};

export const ErrorBox: FC<ErrorBoxProps> = (props) => {
  const { label, description, bgColor, icon, close, marginTop } = props;
  return (
    <Container
      style={{
        marginTop: marginTop || '0px',
      }}
      color={bgColor}
    >
      <div
        style={{
          position: 'absolute',
          top: '6px',
          right: '10px',
          cursor: 'pointer',
        }}
        onClick={close}
      >
        <IconClose />
      </div>
      <Header>
        <img alt="smiley" srcSet={icon} style={{ marginRight: '8px' }} />
        {label}
      </Header>
      <Description>{description}</Description>

      <LineBreak />
      <Button
        onClick={close}
        width={48}
        height={20}
        fontSize="10px"
        lineHeight="14px"
        style={{ fontFamily: 'Titillium Web, sans-serif', alignSelf: 'flex-end' }}
      >
        Close
      </Button>
    </Container>
  );
};

export default ErrorBox;
