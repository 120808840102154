import React, { FC } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { Container, ContainerContent, Button } from '../../components'
import { defaultPaperBorderRadius } from '../../constants/theme';
import { State } from "./../../../Onboarding-2/store"
import { Logo } from "../../media/logo"
import { EmailType } from '../../api/merchants'
import { sendEmailInstructions as sendEmailInstructionsAction } from "../../actions/verify-domain"
import { goBack as goBackAction } from "../../actions/thank-you"
import { colors } from "../../constants/styles"
import { ReactComponent as Confetti } from "../../media/confetti.svg"
import { ReactComponent as BaseLogoStore } from "../../media/base-logo-store.svg"

export type OwnProps = {};

export type StateProps = {
  isShopify?: boolean;
};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
}

export type FormData = {
  checkTrace: boolean;
};

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '0 auto',
    width: '100%',
    maxWidth: 390,
    padding: '0 40px',
    borderRadius: '0px',
    boxSizing: 'border-box',
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      margin: '65px auto',
      borderRadius: defaultPaperBorderRadius,
      height: '100%'
    },
  },
  logo: {
    margin: '15px auto 22px auto',
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  advice: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  imageContent: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'center'
  },
  confetti: {
    position: 'absolute'
  },
  storeLogo: {
    marginTop: 36,
    width: 300,
    height: 200,
    objectFit: 'cover',
    margin: "0px auto",
    borderRadius: 16
  },
  baseLogo: {
    position: 'absolute',
    alignSelf: 'flex-end',
    marginTop: 10
  },
  description: {
    margin: '45px 0',
    fontSize: '13px',
    padding: '0px 45px'
  },
  secondButton: {
    background: 'transparent',
    border: 0,
    fontSize: 16,
    fontWeight: 600,
    color: colors.black,
    marginTop: 16
  }
}))

const spanBasedOnMethod: Record<EmailType, string> = {
  "email": "Please follow the instructions in the email to verify your domain.",
  "file-upload": "Please follow the instructions in the email. Once completed and validated we will reach out.",
  "text-record": "Please follow the instructions in the email. Once completed and validated we will reach out."
}

const ThankYou: FC<Props> = (props: any) => {
  const classes = useStyles();
  const email = useSelector((state: State) => state.app.brand.verifyDomain?.email || state.app.brand.form?.email)
  const method = useSelector((state: State) => state.app.brand.verifyDomain?.method || "email")
  const storeLogo = useSelector((state: State) => state.app.brand.form?.logoImage)
  const loading = useSelector((state: State) => state.app.loading)
  const dispatch = useDispatch()

  const resendEmail = () => {
    dispatch(sendEmailInstructionsAction(email as string, method))
  }

  const goBack = () => {
    dispatch(goBackAction())
  }

  return (
    <Container>
      <ContainerContent className={classes.content}>
        <Logo className={classes.logo} width={60} height={43} />
        <h1 className={classes.title}>Thank you</h1>
        <span className={classes.advice}>We sent an email to {email}</span>
        {storeLogo &&
          <div className={classes.imageContent}>
            <Confetti className={classes.confetti} />
            <img className={classes.storeLogo} src={storeLogo}></img>
            <BaseLogoStore className={classes.baseLogo} />

          </div>
        }
        <span className={classes.description}>{spanBasedOnMethod[method]}</span>
        <Button fontSize="16px" onClick={resendEmail} loading={loading} >Resend Email</Button>
        <button className={classes.secondButton} onClick={goBack}>Change Verification Method</button>
      </ContainerContent>
    </Container>
  )
}

export default ThankYou
