import React, { Component } from 'react';
import ChevronIcon from '../../../../assets/icons/chevron';
import CloseIcon from '../../../../assets/icons/close-with-circle';
import './TitleAndNav.scss';

class TitleAndNav extends Component {
  render() {
    return (
      <div className="drawer-title">
        {this.props.goBack ? (
          <button onClick={this.props.goBack} className="drawer-title-chevron">
            <ChevronIcon
              height="12px"
              width="12px"
              fill="#666666"
              transform=""
            />{' '}
          </button>
        ) : (
          ''
        )}
        <span className="drawer-title-header">{this.props.title}</span>
        {this.props.close ? (
          <button
            onClick={() => (!this.props.isEdited || this.props.forceClose
              ? this.props.close(true)
              : this.props.updateSaveState({ active: true, location: '' }))
            }
            className="drawer-title-close"
          >
            <CloseIcon stroke="#FFFFFF" fill="#333333" />{' '}
          </button>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TitleAndNav;
