import { connect } from 'react-redux';
import { State, ThunkDispatch } from '../../../Onboarding-2/store';
import { StateProps, DispatchProps, Banner } from '../../components/banner';
import { clearUserErrorActionCreator } from '../../actions/user-error';

export const mapStateToProps = (state: State): StateProps => {
  return {
    errorMessage: state.app.userError,
  };
}

export const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => {
  return {
    onClose: () => dispatch(clearUserErrorActionCreator()),
  }
}

export const ConnectedErrorBanner = connect(mapStateToProps, mapDispatchToProps)(Banner);
