import styled, { css } from 'styled-components';
import Colors from '../../../../constants/colors';

export const Container = styled.li<any>`
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #F7F7F7;
  margin-bottom: 10px;
  padding-bottom: 16px;


  ${(props) => props.hasGraph === true && css`
    margin-bottom: 10px;
    padding-bottom: 10px;
  `};

  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border: none;
  }
`;

export const MetricTitle = styled.span<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #999999;
  ${(props) => props.hasGraph === true && css`
    font-size: 12px;
    line-height: 18px;
  `};
`;

export const MetricValue = styled.span<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;

  ${(props) => props.hasGraph === true && css`
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
  `};
  color: ${(props) => (props.color ? props.color : Colors.darkYellow)};
`;
