import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import circularCheckbox from '../../../../assets/images/circular-checkbox.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected.svg';
import Button from '../../../Button/Button';
import './UpdateRoutePlus.scss';
import TitleAndNav from './TitleAndNav';
import {
  List, ListItem, ListItemIcon, ListItemText, Checkbox,
} from '@material-ui/core';
import {
  updateMerchantRequest, getMerchant, upsertMerchant, shopifyUpdateScripts,
} from '../../../../services/merchant';
import bulletSquare from '../../../../assets/images/bullet-square-icon.jpg';
import bullet from '../../../../assets/images/bullet-icon.svg';
import routePlusSampleEmail from '../../../../assets/images/route-plus-sample-email.jpg';

const UpdateRoutePlus = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentChecked, setCurrentChecked] = useState(0);
  const checkboxes = [
    'Enable Route Plus',
    'Disable Route Plus',
  ];

  useEffect(() => {
    const { has_route_plus } = props.userSession.activeBrand;

    const activeCheckbox = has_route_plus === true ? 0 : 1;
    setCurrentChecked(activeCheckbox);
  }, []);

  const handleSubmitButton = async (e) => {
    const { id, prod_api_secret, platform_id } = props.userSession.activeBrand;
    const isRoutePlusActive = currentChecked === 0;

    ReactGA.event({ category: 'Admin - Preferences', action: 'Route Plus change', label: `isRoutePlusActive:${isRoutePlusActive}` });
    const resp = await upsertMerchant('has_route_plus', isRoutePlusActive, id, prod_api_secret);

    if (resp.data) {
      let merchant = resp.data;

      merchant = Object.assign(merchant, { has_route_plus: isRoutePlusActive });

      if (platform_id === 'shopify') {
        const {
          shopify_domain, store_domain,
        } = props.userSession.activeBrand;

        if (isRoutePlusActive) {
          props.openShopifyImplementationDrawer();
        } else {
          props.closeDrawer();
          props.updateActiveBrand(merchant);
        }

        const shop = shopify_domain || store_domain;

        if (shop) {
         await shopifyUpdateScripts(shop);
        }
      } else {
        props.closeDrawer();
        props.updateActiveBrand(merchant);
      }
    }

    e.preventDefault();
  };

  const renderRoutePlusForm = () => {

  };

  return (
      <div className='update-payment-method update-route-plus'>
        <TitleAndNav close={props.closeDrawer} title='Route+ Package Protection' />
        <div className='update-payment-method-upper-text'>
          <p>With Route Plus:</p>
        </div>

        <ul className="nested-bullet-list">
            <li>
               <img srcSet={bulletSquare} className="bullet" />
               <span>Your customers can purchase Route Plus Package Protection at checkout</span>
            </li>
            <li>
               <img srcSet={bulletSquare} className="bullet" />
               <span>Customers that purchase protection receive:</span>
            </li>
            <li>
               <ul className="subnested-list">
                  <li>
                     <img srcSet={bullet} className="bullet-trace" />
                     <span>Easy access to file claims with Route when insured packages
                     are lost, damaged, or stolen</span>
                  </li>
                  <li>
                     <img srcSet={bullet} className="bullet-trace" />
                     <span>Free web and in-app tracking of their packages during shipment, sent via email</span>
                  </li>
               </ul>
            </li>
         </ul>

         <img srcSet={routePlusSampleEmail} className="route-plus-image"></img>

        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="nested-list"
         >
            {checkboxes.map((item, i) => (
               <ListItem key={i} onClick={() => setCurrentChecked(i)} alignItems="flex-start" className="item-list">
                  <ListItemIcon className='checkbox'>
                     <Checkbox
                        onChange={() => setCurrentChecked(i)}
                        checked={currentChecked === i}
                        icon={
                           <img
                              alt="checkbox icon image"
                              srcSet={circularCheckbox}
                              width={20}
                           />
                        }
                        checkedIcon={
                           <img
                              alt="checkbox icon image"
                              srcSet={circularCheckboxChecked}
                              width={20}
                           />
                        }
                        value="checked"
                        color="primary"
                     />
                  </ListItemIcon>
                  <ListItemText primary={item}/>
               </ListItem>
            ))}
         </List>
        <div className='update-payment-method-container'>

        </div>
        <Button className='update-company-details-save-button' type='submit'
        onClick={handleSubmitButton}
        // disabled={!this.validateForm()}
        isLoading={loading}
        text="Save" />
      </div>
  );
};

export default UpdateRoutePlus;
