import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import LeftArrowIcon from '../../../../../../assets/icons/scroll-arrow-left';
import RightArrowIcon from '../../../../../../assets/icons/scroll-arrow-right';
import CloseIcon from '../../../../../../assets/icons/close-with-circle';
import MobilePreview from '../MobilePreview';
import {
  Wrapper,
  Container,
  Header,
  Title,
  ArrowIcon,
  Middle,
  Footer,
  Thumbnail,
  Thumbnails,
  CloseIconContainer,
} from './styles';
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';
import ImagePlaceholder from './media/1.jpg';
import ImagePlaceholder2 from './media/3.jpg';
import ImagePlaceholder3 from './media/4.jpg';
import ImagePlaceholder4 from './media/5.jpg';
import ImagePlaceholder5 from './media/6.jpg';
import ImagePlaceholder6 from './media/7.jpg';
import ImagePlaceholder7 from './media/8.jpg';
import ImagePlaceholder8 from './media/10.jpg';

interface BrandedOrderSlideshowProps {
  onClose: any;
}

const BrandedOrderSlideshow: React.FC<BrandedOrderSlideshowProps> = (props: any) => {
  const [images, setImages] = useState([
    ImagePlaceholder,
    ImagePlaceholder2,
    ImagePlaceholder3,
    ImagePlaceholder4,
    ImagePlaceholder5,
    ImagePlaceholder6,
    ImagePlaceholder7,
    ImagePlaceholder8,
  ]);
  const [position, setPosition] = useState(0);
  const [selectedImage, setSelectedImage] = useState('');
  const [opened, setOpened] = useState(true);
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef();

  useEffect(() => {
    if (images && images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, []);

  const onClose = () => {
    setPosition(0);
    setOpened(false);

    if (props && props.onClose) {
      props.onClose();
    }
  };

  const setCurrentImage = (thumbnail: string, index: number) => {
    setSelectedImage(thumbnail);
    setPosition(index);
  };

  const setNextImage = () => {
    const index = images.length - 1 === position ? 0 : position + 1;
    const thumbnail = images[index];
    setCurrentImage(thumbnail, index);
  };

  const setPrevImage = () => {
    const index = position <= 0 ? images.length - 1 : position - 1;
    const thumbnail = images[index];
    setCurrentImage(thumbnail, index);
  };

  const renderThumbails = () => {
    const thumbnails = images.map((thumbnail, i) => {
      return <Thumbnail onClick={() => setCurrentImage(thumbnail, i)} key={i} bordered={i === position} src={thumbnail}></Thumbnail>;
    });

    return (
      <Thumbnails>
        {thumbnails}
      </Thumbnails>
    );
  };

  useOnClickOutside(ref, () => onClose());

  return (
    <Wrapper disabled={!opened}>
      <Container ref={ref}>
        <Header>
          <Title>
            Examples
          </Title>
          <CloseIconContainer onClick={onClose} >
            <CloseIcon/>
          </CloseIconContainer>
        </Header>
        <Middle>
          <ArrowIcon onClick={setPrevImage}>
            <LeftArrowIcon/>
          </ArrowIcon>
          <MobilePreview
            brandedOrderFeaturedImage={selectedImage}
          />
          <ArrowIcon onClick={setNextImage}>
            <RightArrowIcon/>
          </ArrowIcon>
        </Middle>
        <Footer>
          {renderThumbails()}
        </Footer>
      </Container>
    </Wrapper>
  );
};

export default BrandedOrderSlideshow;
