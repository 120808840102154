import React from 'react';
import './Form.scss';
import OrderForm from '../../Orders/OrderForm/OrderForm';
import ClaimForm from '../../Claims/AllClaims/ClaimForm/ClaimForm';

const Form = (props) => (
  <div className="portal-form-container">
    {Object.keys(props.formContent).length === 0 ? (
      <div></div>
    ) : document.location.pathname.includes('orders') || document.location.pathname.includes('billing') ? (
      <OrderForm data={props.formContent} userSession={props.userSession}/>
    ) : (
          <ClaimForm data={props.formContent} userSession={props.userSession} />
        )}
  </div>
);

export default Form;
