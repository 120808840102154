import styled, { css } from 'styled-components';
import colors from '../../../../constants/colors';

export const Container = styled.a`
  background: #f7f7f7;
  border: 1px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 24px;
  display: block;
  margin-top: 27px;
  margin-bottom: 15px;
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 17px;
  top: 17px;
  cursor: pointer;
  z-index: 9999;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 240px;
  height: 240px;
  border-radius: 27px;
`;
export const ImageContainer = styled.div`
  position: relative;
`;

export const InstructionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

export const Instructions = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  width: 240px;
  height: 240px;
  box-sizing: border-box;
  border-radius: 24px;
  left: 0;
  top: 0;

  ${(props: any) =>
    props.overlay &&
    css`
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        z-index: 11;
        background: #f7f7f7;
        opacity: 0.85;
        border-radius: 7.22321px;
        width: 240px;
        height: 240px;
        z-index: -1;
        background: #f7f7f7;
        border: 1px dashed #cccccc;
        box-sizing: border-box;
        border-radius: 24px;
        left: -1px;
        top: -1px;
      }
    `}

  ${(props: any) =>
    props.hide &&
    css`
      svg {
        display: none;
      }

      div {
        color: transparent !important;
      }
    `}
`;

export const Instruction: any = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-family: Titillium Web;
  color: #333333;

  ${(props: any) =>
    props.primary &&
    css`
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: #333333;
      text-decoration: none;
      margin: 5px 0;
    `}

  ${(props: any) =>
    props.secondary &&
    css`
      font-family: Titillium Web;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      text-decoration-line: underline;
      color: ${colors.primaryColor};
      margin: 5px 0;
    `}

  ${(props: any) =>
    props.danger &&
    css`
      text-align: center;
      text-decoration-line: underline;
      color: #f06350;
      position: absolute;
      top: 165px;
    `}
`;

export const Note = styled.p`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
`;

export const Wrapper = styled.div`
  margin-bottom: 45px;
`;

export const Zone = styled.button`
  background: #f7f7f7;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
  padding: 0;
  cursor: auto;
`;
