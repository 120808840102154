export type ConstantEntry = {
  GTM_ID: string;
  GA_ID: string;
  ROUTE_SHOPIFY_URL: string;
  ROUTE_API: string;
  ROUTE_API_V2: string;
  ROUTE_API_LEGACY: string;
  PLAID_URL: string;
  PLAID_ENV: string;
  PLAID_PUBLIC_KEY: string;
  SENTRY_DSN: string;
  SITE_URL: string;
  STRIPE_PUBLIC_API_KEY: string;
  BILLING_VIDEO_CDN: string;
  ROUTE_TERM_OF_SERVICE_URL: string;
  ROUTE_PRIVACY_POLICY_URL: string;
  PENDO_API_KEY: string;
  MAPBOX_API_KEY: string;
  HOTJAR_ID: number;
  HOTJAR_VERSION: number;
  ROUTE_PRICING_URL: string;
};

const constants: Record<'dev' | 'stage' | 'prod', ConstantEntry> = {
  dev: {
    GTM_ID: 'GTM-',
    GA_ID: 'UA-',
    ROUTE_SHOPIFY_URL: 'http://localhost:3000',
    ROUTE_API: 'https://api-stage.route.com/v1',
    ROUTE_API_V2: 'https://api-stage.route.com/v2',
    ROUTE_API_LEGACY: 'http://localhost:3001/api/v1',
    PLAID_URL: 'https://sandbox.plaid.com',
    PLAID_ENV: 'sandbox',
    PLAID_PUBLIC_KEY: '35e7455ec033851a2c8860a267f78d',
    SENTRY_DSN: '',
    SITE_URL: 'http://localhost:3000',
    STRIPE_PUBLIC_API_KEY: 'pk_test_qbPlDUFI4l2D5IX5cMqHGjZB',
    BILLING_VIDEO_CDN: 'https://route-static-content.s3.amazonaws.com/Route_Billing_v007.mp4',
    ROUTE_TERM_OF_SERVICE_URL: 'https://route.com/terms-and-conditions/',
    ROUTE_PRIVACY_POLICY_URL: 'https://route.com/privacy/',
    PENDO_API_KEY: '4440a0c2-29a8-47fa-4ee6-c982699153e6',
    HOTJAR_ID: 0,
    HOTJAR_VERSION: 0,
    MAPBOX_API_KEY: 'pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg',
    ROUTE_PRICING_URL: 'https://go.route.com/routepro/features',
  },
  stage: {
    GTM_ID: 'GTM-',
    GA_ID: 'UA-',
    ROUTE_SHOPIFY_URL: 'https://shopify-stage.route.com',
    ROUTE_API: 'https://api-stage.route.com/v1',
    ROUTE_API_LEGACY: 'https://api-stage.route.com/api/v1',
    ROUTE_API_V2: 'https://api-stage.route.com/v2',
    PLAID_URL: 'https://sandbox.plaid.com',
    PLAID_ENV: 'sandbox',
    PLAID_PUBLIC_KEY: '35e7455ec033851a2c8860a267f78d',
    SENTRY_DSN: '',
    SITE_URL: 'https://dashboard-stage.route.com',
    STRIPE_PUBLIC_API_KEY: 'pk_test_qbPlDUFI4l2D5IX5cMqHGjZB',
    BILLING_VIDEO_CDN: 'https://route-static-content.s3.amazonaws.com/Route_Billing_v007.mp4',
    ROUTE_TERM_OF_SERVICE_URL: 'https://route.com/terms-and-conditions/',
    ROUTE_PRIVACY_POLICY_URL: 'https://route.com/privacy/',
    PENDO_API_KEY: '4530beb5-7f37-4f1d-5f46-85e64fee87a9',
    HOTJAR_ID: 0,
    HOTJAR_VERSION: 0,
    MAPBOX_API_KEY: 'pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg',
    ROUTE_PRICING_URL: 'https://go.route.com/routepro/features',
  },
  prod: {
    GTM_ID: 'GTM-K2K2GM5',
    GA_ID: 'UA-84872712-5',
    PLAID_URL: 'https://production.plaid.com',
    ROUTE_API: 'https://api.route.com/v1',
    ROUTE_API_LEGACY: 'https://api.route.com/api/v1',
    ROUTE_API_V2: 'https://api.route.com/v2',
    PLAID_ENV: 'production',
    PLAID_PUBLIC_KEY: '35e7455ec033851a2c8860a267f78d',
    SENTRY_DSN: 'https://79889b1addbe469fb75ca8cf5c09c58b@o239602.ingest.sentry.io/5220829',
    SITE_URL: 'https://dashboard.route.com',
    STRIPE_PUBLIC_API_KEY: 'pk_live_GXBYnJGicFSWgAZQS1EharED',
    BILLING_VIDEO_CDN: 'https://route-static-content.s3.amazonaws.com/Route_Billing_v007.mp4',
    ROUTE_SHOPIFY_URL: 'https://shopify.route.com',
    ROUTE_TERM_OF_SERVICE_URL: 'https://route.com/terms-and-conditions/',
    ROUTE_PRIVACY_POLICY_URL: 'https://route.com/privacy/',
    PENDO_API_KEY: '4530beb5-7f37-4f1d-5f46-85e64fee87a9',
    HOTJAR_ID: 1965974,
    HOTJAR_VERSION: 6,
    MAPBOX_API_KEY: 'pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg',
    ROUTE_PRICING_URL: 'https://go.route.com/routepro/features',
  },
};

if (!Object.keys(constants).includes(process.env.REACT_APP_STAGE as string)) {
  throw new Error(`REACT_APP_STAGE not one of ['dev', 'stage', 'prod']: "${process.env.REACT_APP_STAGE}"`);
}

const defaultExport = constants[process.env.REACT_APP_STAGE as keyof typeof constants];
export default defaultExport;

// This file was previously a commonjs module with `module.exports = defaultExport`.
// This file is used similar to `import {GTM_ID} from './constants'`.
// If we want to keep doing that while having the dynamic nature of dev/stage/prod
// while also being an ES6 module we need to export every config value.
export const {
  GTM_ID,
  GA_ID,
  PLAID_URL,
  PLAID_ENV,
  PLAID_PUBLIC_KEY,
  ROUTE_API,
  ROUTE_API_LEGACY,
  ROUTE_API_V2,
  SENTRY_DSN,
  SITE_URL,
  STRIPE_PUBLIC_API_KEY,
  BILLING_VIDEO_CDN,
  ROUTE_SHOPIFY_URL,
  ROUTE_TERM_OF_SERVICE_URL,
  ROUTE_PRIVACY_POLICY_URL,
  PENDO_API_KEY,
  HOTJAR_ID,
  HOTJAR_VERSION,
  MAPBOX_API_KEY,
  ROUTE_PRICING_URL,
} = defaultExport;
