import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Popper as MuiPopper,
  ClickAwayListener,
} from '@material-ui/core';

import {colors} from '../constants/styles';
import {CloseButton} from './close-button';
import {WarningIcon} from '../media/warning';

const arrowHeight = '10px';
const useTooltipStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // This will end up being 240px with 10px left and right border
    width: '220px',
    backgroundColor: colors.error,
    padding: '10px',
    marginTop: '10px',
    borderRadius: '8px',
    // This overrides the default border radius so there isn't any radius where we are connecting with the arrow
    '[x-placement*="bottom"] &': {
      borderTopLeftRadius: 0,
    },
    '[x-placement*="top"] &': {
      borderBottomLeftRadius: 0,
    }
  },
  warningIcon: {
    flexShrink: 0,
  },
  content: {
    marginLeft: 10,
    marginRight: 15,
    fontFamily: 'Titillium Web',
    color: colors.white,
    fontSize: '10px',
    lineHeight: '14px',
  },
  arrow: {
    // Makes anti-aliasing better in webkit browsers
    '-webkit-transform':'rotate(360deg)',
    fill: colors.error,
    position: 'absolute',
    width: arrowHeight,
    height: arrowHeight,
    left: 0,
    '[x-placement*="bottom"] &': {
      top: 0,
    },
    '[x-placement*="top"] &': {
      bottom: `-${arrowHeight}`,
      transform: 'scaleY(-1)'
    },
  },
  roundedArrowPath: {
    strokeWidth: 20,
    stroke: colors.error,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  }
}, {name: 'TooltipContent'});

type PopoverContentProps = {
  content: React.ReactNode;
  onClose: () => void;
}

const PopoverContent = React.forwardRef((props: PopoverContentProps, ref: any) => {
  const classes = useTooltipStyles();

  return (
    <div>
      <div ref={ref} className={classes.root}>
        <WarningIcon className={classes.warningIcon}/>
        <div className={classes.content}>{props.content}</div>
        <CloseButton onClick={props.onClose}/>
        <svg className={classes.arrow} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          {/* The first path is a rounded triangle, and the next two overlap on two of the corners so one one corner is rounded */}
          <path className={classes.roundedArrowPath} d="M 10,10 84,96 10,90 z"/>
          <polygon points="0,100 0,90 10,90 10,100"/>
          <path d="M 84,100 100,100 83,80 z"/>
        </svg>
      </div>
    </div>
  );
});


export type PopoverProps = {
  content: React.ReactNode;
  anchorEl: any;
  open: boolean;
  onClose: () => void;
}

export function ErrorPopover(props: PopoverProps) {
  const {content, open, onClose, anchorEl} = props;

  return (
    <MuiPopper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
        <PopoverContent content={content} onClose={onClose}/>
      </ClickAwayListener>
    </MuiPopper>
  );
}
