import React, { FC, useState } from 'react';
import SmallNavBar from '../../../components/SmallNavBar/SmallNavBar';

import { PreviewHeader, ExampleLink } from './styles';
import MobilePreview from './MobilePreview';
import { WebPreview } from './WebPreview';
import BrandedOrderSlideshow from './BrandedOrderSlideshow/index';

const BrandedOrderPreview: FC<any> = (props: any) => {
  const [selected, setSelected] = useState('Route App');
  const [openExample, setOpenExample] = useState(false);

  const onOpenExampleLink = () => {
    setOpenExample(!openExample);
  };

  return (
    <div>
      <PreviewHeader>
        <span>PREVIEW</span>
        <br />
        <div
          style={{
            marginTop: 10,
            marginBottom: 14,
          }}
        >
          <SmallNavBar items={['Route App', 'Web Tracking']} selected={selected} setSelected={setSelected} />
        </div>
        <div>
          <ExampleLink onClick={onOpenExampleLink}>View Examples</ExampleLink>
        </div>
        {selected === 'Route App' ? (
          <MobilePreview
            brandedOrderFeaturedImage={props.brandedOrderFeaturedImage || ''}
            storeName={props.storeName || ''}
            storeLogo={props.storeLogo || ''}
            storeHero={props.storeHero || ''}
          />
        ) : (
          ''
        )}
        {selected === 'Web Tracking' ? (
          <WebPreview
            brandedOrderFeaturedImage={props.brandedOrderFeaturedImage || ''}
            storeName={props.storeName || ''}
            storeLogo={props.storeLogo || ''}
            storeHero={props.storeHero || ''}
          />
        ) : (
          ''
        )}
      </PreviewHeader>
      {openExample && <BrandedOrderSlideshow onClose={() => setOpenExample(false)} />}
    </div>
  );
};

export default BrandedOrderPreview;
