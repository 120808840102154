import { addBreadcrumb, Severity } from '@sentry/browser';
import { MerchantBrand } from '../models/merchant_brand';
import openApi from './open-api';
import { getHeadersWithToken, hasCsmRequestedByMerchant, setCsmRequestedByMerchant } from './utils';
import dictionary from '../constants/dictionary';

export const createCsmInteraction = async (payload: {}, merchantId: string, token: string) => {
  try {
    // if CSM has not been requested yet
    if (!hasCsmRequestedByMerchant(merchantId)) {
      const body = payload;
      const { data } = await openApi.post(
        `/merchants/${merchantId}/request_csm_interaction`,
        body,
        getHeadersWithToken(token),
      );

      setCsmRequestedByMerchant(merchantId);
      return data[0];
    }
  } catch (err) {
    // report to sentry
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_CREATE_REQUEST_CSM_INTERACTION,
      category: 'request_csm_interaction',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });

    return false;
  }
};

export const getRequestCsmInteraction = async (interaction_type: string, merchantId: string, token: string) => {
  try {
    const { data } = await openApi.get(
      `/merchants/${merchantId}/request_csm_interaction/${interaction_type}`,
      getHeadersWithToken(token),
    );

    return data[0];
  } catch (err) {
    // report to sentry
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_GET_REQUEST_CSM_INTERACTION,
      category: 'request_csm_interaction',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });

    return false;
  }
};

export default {
  createCsmInteraction,
  getRequestCsmInteraction,
};
