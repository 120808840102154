import {createMuiTheme} from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: 'Titillium Web, sans-serif',
    h1: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '33px'
    },
    // Regular subtitle
    subtitle1: {
      fontSize: '13px',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
    // All uppercase subtitle
    subtitle2: {
      fontSize: '15px',
      fontWeight: 'bold',
      lineHeight: '17px',
    },
    caption: {
      fontSize: '13px',
      fontWeight: 'normal',
      lineHeight: '20px',
    }
  },
});

export const defaultButtonBorderRadius = '20px';
export const defaultPaperBorderRadius = '24px';
export const defaultInputBorderRadius = '8px';
