import {Dispatch} from 'react';
import {connect} from 'react-redux';

import {State} from '../../store';
import {FormData, ConfigureForm, StateProps, DispatchProps} from './configure';
import {continueToThankYouPage} from '../../actions/configure-route-plus';
import {qualifiesForExpertRouteImplementation} from '../../model/merchant';

export const mapStateToProps = (state: State): StateProps => {
  if (!state.app.userData.loggedIn) {
    throw new Error('Expected user to be logged in');
  }

  return {
    qualifiesForExpertImplementation: qualifiesForExpertRouteImplementation(state.app.userData.activeBrand),
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (values: FormData) => {
      dispatch(continueToThankYouPage(values));
      return undefined;
    },
  };
}

export const ConnectedConfigureForm = connect(mapStateToProps, mapDispatchToProps)(ConfigureForm);
