import React from 'react';
import colors from '../../constants/colors';

const SVG = ({
  style = {},
  fill = colors.primaryColor,
  width = '8px',
  height = '12px',
  viewBox = '0 0 8 12',
  className = '',
  transform = '',
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M1.06667 5.2L6.4 1.2C7.05924 0.705573 8 1.17595 8 2L8 10C8 10.824 7.05924 11.2944 6.4 10.8L1.06667 6.8C0.533333 6.4 0.533333 5.6 1.06667 5.2Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
