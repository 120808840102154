import React, {FC} from 'react';
import {Grid, Theme, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {RouteFormTextInput, Bold} from '../../../components';
import {text} from '../../../constants/styles';
import {formFieldNames} from '../types';
import {RightArrow} from '../../../media/right-arrow';
import {createRequiredValidator, emailPatternValidator, composeValidators} from '../../../validation';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    marginTop: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrow: {
    marginRight: '14px',
  },
  mainContent: {
    // This is a hack to make this specific enough to override other rules
    // It just uses the class name twice which is specific enough
    '&&': {
      paddingLeft: '26px !important',
    }
  },
  textDescription: {
    color: text.detailsParagraphColor,
    maxWidth: '240px',
  },
  smallBreakGridItem: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '35px',
    }
  }
}), {name: 'CollectionMethod'});

const billingEmailRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your email.</Bold></div>);

export const BillingContact: FC<any> = (props: any) => {
  const classes = useStyles();

  return (
    <Grid container item>
      <Grid item xs={12} className={classes.sectionHeader}>
        <RightArrow className={classes.arrow}/>
        <Typography variant="subtitle2">B. BILLING CONTACT</Typography>
      </Grid>
      <Grid container item xs={12} className={classes.mainContent} style={{marginTop: '45px'}}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" className={classes.textDescription}>
            Provide a primary contact that Route can use to send you notices for upcoming Route+ collections.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{marginBottom: '66px'}} className={classes.smallBreakGridItem}>
          <RouteFormTextInput
            required
            id={formFieldNames.billingEmail}
            name={formFieldNames.billingEmail}
            label="Billing Email"
            fieldProps={{
              name: formFieldNames.billingEmail,
              validate: composeValidators(
                billingEmailRequiredValidator,
                emailPatternValidator,
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}