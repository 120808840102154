import React, {FC} from 'react';
import {Stepper, Step, StepLabel, StepConnector, StepIconProps} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import styleMap from '../constants/styles';

const iconHeight = 14;
const iconWidth = 24;

const RouteConnector = withStyles({
  root: {
    // Subtract one because the line is 2px wide, and this makes it look centered
    top: (iconHeight / 2) - 1,
    width: `calc(100% - ${iconWidth}px)`,
    left: `calc(-50% + ${iconWidth / 2}px)`,
    right: `calc(50% - ${iconWidth / 2}px)`
  },
  line: {
    borderTopWidth: '2px',
    borderStyle: 'dotted',
    borderColor: styleMap.progressStepperConnectorIncompleteColor,
  },
  active: {
    '& $line': {
      borderColor: styleMap.progressStepperConnectorCompleteColor,
      borderStyle: 'solid',
    },
    '.override-connector & $line': {
      borderStyle: 'dotted',
      borderColor: styleMap.progressStepperConnectorIncompleteColor
    }
  },
  completed: {
    '& $line': {
      borderColor: styleMap.progressStepperConnectorCompleteColor,
      borderStyle: 'solid',
    },
    '.override-connector & $line': {
      borderStyle: 'dotted',
      borderColor: styleMap.progressStepperConnectorIncompleteColor
    }
  },
})(StepConnector);


const useRouteStepIconStyles = makeStyles({
  root: {
    color: styleMap.progressStepperIncompleteIconColor,
    display: 'flex',
    height: iconHeight,
    width: iconWidth,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  active: {
    color: styleMap.progressStepperCompleteIconColor,

  },
  completed: {
    color: styleMap.progressStepperCompleteIconColor,
  }
});

function RouteStepIcon(props: StepIconProps) {
  const classes = useRouteStepIconStyles();
  const {active, completed} = props;

  return (
    <div className={clsx(classes.root, {[classes.active]: active, [classes.completed]: completed})}>
      <div className={classes.circle}></div>
    </div>
  );
}

export enum StepEnum {
  AccountDetails = 'account-details',
  ConfigureRoutePlus = 'configure-route-plus',
  CartThankYouPage = 'cart-thank-you-page',
  PremiumsTransfer = 'premiums-transfer',
};

const stepToNumberMap: {[S in StepEnum]: number} = {
  [StepEnum.AccountDetails]: 0,
  [StepEnum.ConfigureRoutePlus]: 1,
  [StepEnum.CartThankYouPage] : 2,
  [StepEnum.PremiumsTransfer]: 3,
};

const stepToLabelMap: {[S in StepEnum]: React.ReactNode} = {
  [StepEnum.AccountDetails]: <div><div>Account</div><div>Details</div></div>,
  [StepEnum.ConfigureRoutePlus]: <div><div>Configure</div><div>Route+</div></div>,
  [StepEnum.CartThankYouPage]: <div><div>Thank You</div><div>Page</div></div>,
  [StepEnum.PremiumsTransfer]: <div><div>Premiums</div><div>Transfer</div></div>,
}

type StylesProps = {
  stepperWidth?: number;
}

const useStepperStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    userSelect: 'none',
    borderRadius: 'inherit',
  },
  stepper: {
    width: (props: StylesProps) => props.stepperWidth,
  },
  label: {
    marginTop: '5px !important',
    fontWeight: '600 !important' as any,
    fontSize: '8px',
    lineHeight: '8px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'black !important',
  },
  text: {
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 600,
  },
  grayText: {
    color: styleMap.progressStepperIncompleteIconColor,
  }
}, {name: 'Stepper'});

export type Props = {
  step: StepEnum;
  overrideConnector?: boolean;
  className?: string;
  stepperWidth?: number;
};

export const OnboardingStepper: FC<Props> = (props) => {
  const classes = useStepperStyles(props);

  const stepEnumKeys = Object.values(StepEnum) as StepEnum[];
  const steps = stepEnumKeys.map((step) => {
    const textClassname = clsx({
      [classes.grayText]: !props.overrideConnector && step !== props.step,
      [classes.text]: true,
    });
    return (
      <Step key={step}>
        <StepLabel classes={{label: classes.label}} StepIconComponent={RouteStepIcon}>
          <div className={textClassname}>{stepToNumberMap[step] + 1}</div>
          <div className={textClassname}>{stepToLabelMap[step]}</div>
        </StepLabel>
      </Step>
    );
  });

  return (
    <div className={clsx(classes.root, props.className)}>
      <Stepper
        className={clsx(classes.stepper, {'override-connector': props.overrideConnector})}
        alternativeLabel
        activeStep={stepToNumberMap[props.step]} 
        connector={<RouteConnector/>}
      >
        {steps}
      </Stepper>
    </div>
  );
};