import styled from 'styled-components'
import { Breakpoints } from '../../utils/responsive-breakpoints'

export const Container = styled.div`
    background: white;
    border-radius: 24px;
    margin: 0 24px 24px;
    padding: 24px;
    overflow: hidden;

    ${ props => !props.hasShipped && `
    height: 830px;
    margin-bottom: 140px;

    @media (max-width: ${Breakpoints.xsMax}px) {
      height: auto;
    }

  `}

    @media (max-width: ${Breakpoints.xsMax}px) {
      margin: 24px 10px;
    }
`;