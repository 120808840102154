import React, { Component } from 'react';
import PlaidLink from 'react-plaid-link';
import { injectStripe } from 'react-stripe-elements';

import Button from '../../../Button/Button';
import { PLAID_ENV, PLAID_PUBLIC_KEY } from '../../../../constants';

import { createStripeBankAccount, createStripeBankAccountViaPlaid } from '../../../../services/billing';
import TitleAndNav from './TitleAndNav';
import './ConnectBank.scss';
import { getMerchant } from '../../../../services/merchant';
import { isOnboardingFlow } from '../../../../utils/url';
import { Box } from '@material-ui/core';
import AlertTip from '../../../../assets/icons/alert-tip';
import AlertTriangle from '../../../../assets/icons/alert-triangle';
import CloseValidation from '../../../../assets/icons/close-validation';

class ConnectBank extends Component {
  constructor(props) {
    super(props);

    // create a ref to plaidLink to execute a click manually.
    //  this.dashboardActions = this.props.dashboardActions;
    this.plaidLinkRef = React.createRef();
    this.state = {
      openBankModal: true,
      width: this.props.width || '683px',
      height: this.props.height || '534px',
      accountName: '',
      routingNumber: '',
      accountNumber: '',
      accountHolder: 'individual',
      disableAddBankBtn: false,
      accountNumberError: false,
      routingNumberError: false,
    };
  }

  handleAddPlaidBankClick = (e) => {
    e.preventDefault();
    this.props.closeDrawer();
    this.plaidLinkRef.current.handleOnClick();
    this.handleCloseBankModal();
  };

  handleCloseBankModal = () => {
    this.setState({ openBankModal: false });
    this.props.onRequestClose();
  };

  handleOnSuccessPlaid = async (public_token_value, metadata) => {
    let prodApiSecret = '';

    if (isOnboardingFlow()) {
      const merchant = getMerchant() || '';
      prodApiSecret = merchant.prod_api_secret;
    } else {
      prodApiSecret = this.props.userSession.activeBrand.prod_api_secret;
    }

    const res = await createStripeBankAccountViaPlaid({ source_token: public_token_value, metadata }, prodApiSecret);
    if (res.status === 200) {
      this.props.getBillingDetails();
      this.props.setSnackbarMessage('Payment Method Updated Successfully');
      this.props.closeDrawer();
    } else {
      this.props.setSnackbarMessage(
        'An error occurred while trying to verify your payment card with Stripe. \nPlease make sure your information is entered correctly. \nPlease reach out to Route Customer Service with any questions, or if this error persists.',
      );
    }
  };

  handleOnExitPlaid = () => {
    /* handle the case when your user exits Link*/
  };

  handleAddBankRouting = async (e) => {
    this.setState({ disableAddBankBtn: true });
    e.preventDefault();

    const { token, error } = await this.props.stripe.createToken('bank_account', {
      country: 'US',
      currency: 'USD',
      routing_number: this.state.routingNumber,
      account_number: this.state.accountNumber,
      account_holder_name: this.state.accountName,
      account_holder_type: this.state.accountHolder,
    });

    if (error) {
      console.error('Create Bank Error: ', error);
      if (error.param == 'bank_account[routing_number]') {
        this.setState({ routingNumberError: true });
      } else if (error.param == 'bank_account[account_number]') {
        this.setState({ accountNumberError: true });
      }
      this.setState({ disableAddBankBtn: false });
      this.setState({
        alertBillingFailure: true,
        // billingSuccess: false
      });
      return;
    }

    let prodApiSecret = '';

    if (isOnboardingFlow()) {
      const merchant = getMerchant() || '';
      prodApiSecret = merchant.prod_api_secret;
    } else {
      prodApiSecret = this.props.userSession.activeBrand.prod_api_secret;
    }

    let res = await createStripeBankAccount(token, prodApiSecret);
    if (res) {
      this.props.setSnackbarMessage({ message: 'Payment Method Updated Successfully', variant: 'success' });
      this.props.closeDrawer();
      this.props.getBillingDetails();

      if (this.props.onBillingSuccess) {
        this.props.onBillingSuccess();
      }
    } else {
      this.props.setSnackbarMessage({
        message:
          'An error occurred while trying to verify your payment source with Stripe. \nPlease make sure your information is entered correctly. \nPlease reach out to Route Customer Service with any questions, or if this error persists.',
        variant: 'error',
      });
    }
    this.setState({ disableAddBankBtn: false });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  accountErrorMessage = (
    <div className="input-validation-middle-div">
      Sorry, looks like there was an issue with your account number. Please try entering your Account Number again.
    </div>
  );

  routingErrorMessage = (
    <div className="input-validation-middle-div">
      Sorry, looks like there was an issue with your Routing number. Please try entering your Routing Number again.
    </div>
  );

  render() {
    return (
      <div className="connect-bank-container">
        <form className="connect-bank-bank-routing-form">
          <TitleAndNav title="Connect a Bank Account" close={this.props.closeDrawer} goBack={this.props.goBack} />

          <div className="connect-bank-mid-text">
            ACH through Stripe is currently only supported for businesses based in the U.S.
          </div>

          <Box mt="18px">
            <div className="connect-bank-routing-row">
              <div className="connect-bank-connect-bank-field">
                <label htmlFor="account-name">
                  <span>
                    Account Name <span className="red-emphasis">*</span>
                  </span>
                </label>
                <input
                  className="connect-bank-routing-input"
                  id="account-name"
                  placeholder="Jane Doe"
                  name="accountName"
                  value={this.state.accountName}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="connect-bank-routing-row">
              <div className="connect-bank-connect-bank-field">
                <label htmlFor="routing-number">
                  <span>
                    Routing Number <span className="red-emphasis">*</span>
                  </span>
                </label>
                <input
                  className="connect-bank-routing-input"
                  id="routing-number"
                  placeholder="#####1234"
                  name="routingNumber"
                  maxLength="9"
                  value={this.state.routingNumber}
                  onChange={this.handleInputChange}
                />
                {this.state.routingNumberError ? (
                  <div className="input-validation">
                    <AlertTip />
                    <div className="input-validation-box">
                      <div className="input-validation-left-div">
                        <AlertTriangle />
                      </div>
                      {this.routingErrorMessage}
                      <div
                        className="input-validation-right-div"
                        onClick={() => this.setState({ routingNumberError: false })}
                      >
                        <CloseValidation />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="connect-bank-routing-row">
              <div className="connect-bank-connect-bank-field">
                <label htmlFor="account-number">
                  <span>
                    Account Number <span className="red-emphasis">*</span>
                  </span>
                </label>
                <input
                  className="connect-bank-routing-input"
                  id="account-number"
                  placeholder="#####1234"
                  maxLength="13"
                  name="accountNumber"
                  value={this.state.accountNumber}
                  onChange={this.handleInputChange}
                />

                {this.state.accountNumberError ? (
                  <div className="input-validation">
                    <AlertTip />
                    <div className="input-validation-box">
                      <div className="input-validation-left-div">
                        <AlertTriangle />
                      </div>
                      {this.accountErrorMessage}
                      <div
                        className="input-validation-right-div"
                        onClick={() => this.setState({ accountNumberError: false })}
                      >
                        <CloseValidation />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="connect-bank-routing-row">
              <div className="connect-bank-connect-bank-field">
                <label htmlFor="connect-bank-account-holder">
                  <span>
                    Account Holder <span className="red-emphasis">*</span>
                  </span>
                </label>
                <select
                  className="connect-bank-routing-input"
                  id="connect-bank-account-holder"
                  name="connect-bank-accountHolder"
                  defaultValue={this.state.accountHolder}
                  onChange={this.handleAccountHolderSelect}
                >
                  <option value="individual">Individual</option>
                  <option value="company">Company</option>
                </select>
              </div>
            </div>

            <Button
              className="connect-bank-form-btn"
              type="submit"
              onClick={this.handleAddBankRouting}
              disabled={this.state.disableAddBankBtn}
              text="Save"
            />
          </Box>
        </form>

        <div className="connect-bank-bank-options-routing-disclaimer first">
          Using the bank routing method will take up to 1-2 days.
        </div>
        <div className="connect-bank-bank-options-divider">
          <div className="connect-bank-bank-options-divider-left" />
          <div className="connect-bank-bank-options-divider-label">Or</div>
          <div className="connect-bank-bank-options-divider-right" />
        </div>
        <div className="connect-bank-bank-options-routing-disclaimer">
          Connect securely and instantly with our trusted partner{' '}
          <span className="plaid">
            Plaid<sup>TM</sup>
          </span>
        </div>
        <Button
          className="connect-bank-form-btn"
          type="submit"
          onClick={this.handleAddPlaidBankClick}
          // disabled={this.disableVerifyBtn()}
          text="Connect Instantly"
        />

        {/* Start non-visible content */}
        <PlaidLink
          selectAccount={true}
          env={PLAID_ENV}
          clientName="Route"
          publicKey={PLAID_PUBLIC_KEY}
          product={['auth']}
          style={{ display: 'none' }}
          ref={this.plaidLinkRef}
          onExit={this.handleOnExitPlaid}
          onSuccess={this.handleOnSuccessPlaid}
        />
        {/* End non-visible content */}
      </div>
    );
  }
}

export default injectStripe(ConnectBank);
