import React from 'react';
import phoneOutline from '../../../../assets/simulator/phone-template.svg';
import phoneStats from '../../../../assets/simulator/phone-stats.svg';
import cardContent from '../../../../assets/simulator/card-content.svg';
import ImageIcon from '../../../../assets/icons/image';
import routePlusIcon from '../../../../assets/simulator/route-plus-bubble.svg';
import webIcon from '../../../../assets/icons/website-icon.svg';
import { truncateStr } from '../../../../helpers';
import './MobileDevice.scss';

const MobileDevice = (props) => {
  const {
    companyName, coverImage, logo,
  } = props;

  return (
    <div className="mobile-device-container">
      <div className="mobile-device-container-header">
        MOBILE APP PREVIEW <br />
        (WHAT YOUR CUSTOMER SEES)
      </div>
      <div className="mobile-container">
        <div className="mobile-background">
          <img
            src={phoneOutline}
            alt="mobile outline"
            className="mobile-outline"
          />
          <img src={phoneStats} alt="phone stats" className="phone-stats" />
          {coverImage ? (
            <img src={coverImage} className="phone-cover-image" />
          ) : (
            <div className="phone-cover-image-placeholder">
              <div className="phone-cover-image-placeholder-content">
                <ImageIcon stroke={'white'} />
                <span>Cover Image</span>
              </div>
            </div>
          )}
          {logo ? (
            <img src={logo} className="phone-logo-image-large" />
          ) : (
            <div className="phone-logo-image-large-placeholder">Logo</div>
          )}
          <div className="phone-container">
            <span className="phone-company-name">
              {truncateStr(companyName, 38) || '@COMPANY NAME'}
            </span>
            <div className="phone-orders-headers">
              ORDERS{' '}
              <span>
                <img
                  src={webIcon}
                  alt="web icon"
                  className="phone-orders-web-icon"
                />
                WEBSITE
              </span>
            </div>
          </div>
          <div>
            {logo ? (
              <img src={logo} className="phone-logo-image-small" />
            ) : (
              <div className="phone-logo-image-small-placeholder">Logo</div>
            )}
            <img src={routePlusIcon} className="phone-route-plus-icon" />
            <span className="card-content-custom">
              {truncateStr(companyName, 20) || 'Company Name'}
            </span>
          </div>
          <img src={cardContent} alt="card content" className="card-content" />
        </div>
      </div>
    </div>
  );
};

export default MobileDevice;
