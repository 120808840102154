import axios from 'axios';
import { ROUTE_API } from '../constants';
import { updateMerchantRequest } from './merchant';

const headers = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getOptions = (token, cancelToken) => ({
  cancelToken,
  headers: {
    token,
    'Content-Type': 'application/json',
  },
});

const getUser = async (token, id) => {
  try {
    const userResp = await axios.get(`${ROUTE_API}/users/${id}`, getOptions(token));
    return userResp.data;
  } catch (err) {
    console.error('Error getting user : ', err.response, { token, id });
  }
};

const getBrands = async (token, id) => {
  try {
    const brandsResp = await axios.get(`${ROUTE_API}/users/${id}/merchants`, getOptions(token));
    return brandsResp.data;
  } catch (err) {
    console.error('Error getting brands : ', err.response, { token, id });
  }
};
export const getMerchantToken = () => {
  const merchant = JSON.parse(localStorage.getItem('merchant'));
  return merchant.prod_api_secret;
};

export const getUserAndBrand = async (token, id) => {
  let user;
  let brands;

  try {
    user = await getUser(token, id);
    brands = await getBrands(token, id);

    return { user, brands };
  } catch (err) {
    console.error('Error getting user and brand : ', err.response, {
      user,
      brands,
    });
  }
};

export const getOrders = async (cancelToken, token, params) => {
  const endpoint = `${ROUTE_API}/orders`;
  const options = getOptions(token, cancelToken);
  options.params = params;

  return axios.get(endpoint, options);
};

export const getTrackings = async (token, params) => [
  {
    id: 'order_xmygKTknmKlScqawpdKkD9eBNGIUrP3PhQ',
    orderNumber: '5994650546',
    orderDate: '2019-11-14T02:06:22.160226Z',
    routePlus: false,
    brand: {
      name: 't.kohls.com',
      routePlus: false,
      url: 't.kohls.com',
      logo: null,
      hero: null,
    },
    shipments: [
      {
        id: 'ship_p59mREv0kaOimc595OtL2UodHkTXxgVjTFg',
        trackingNumber: '1ZA674W3YW19947077',
        carrier: null,
        status: 'Exception',
        estimatedDeliveryDate: '2019-11-16T02:06:22.160226Z',
        checkpoints: [
          {
            name: 'SLC',
            date: '11/11/2019 00:00:00',
            lat: -22.906847,
            lon: -43.172897,
            status: 'Out for Delivery',
            isDestination: false,
          },
          {
            name: 'SLC',
            date: '11/11/2019 00:00:00',
            lat: -23.54867,
            lon: -46.63825,
            status: 'Out for Delivery',
            isDestination: true,
          },
        ],
      },
    ],
  },
  {
    id: 'order_xmygKTknmKlScqa123319eBNGIUrP3PhQ',
    orderNumber: '1231541',
    orderDate: '2019-11-14T02:06:22.160226Z',
    routePlus: false,
    brand: {
      name: 't.kohls.com',
      routePlus: false,
      url: 't.kohls.com',
      logo: null,
      hero: null,
    },
    shipments: [
      {
        id: 'ship_p59mREv0kaOimc595OtL2UodHkTXxgVjTFg',
        trackingNumber: '154923947077',
        carrier: null,
        status: 'Exception',
        estimatedDeliveryDate: '2019-11-16T02:06:22.160226Z',
        checkpoints: [
          {
            name: 'RJ',
            date: '11/11/2019 00:00:00',
            lat: -22.906847,
            lon: -43.172897,
            status: 'Out for Delivery',
            isDestination: false,
          },
          {
            name: 'SP',
            date: '11/11/2019 00:00:00',
            lat: -23.54867,
            lon: -46.63825,
            status: 'Out for Delivery',
            isDestination: true,
          },
        ],
      },
    ],
  },
];
/* const endpoint = params !== undefined ? `${ROUTE_API}/orders?${params}` : `${ROUTE_API}/orders`;

  try {
    const ordersResp = await axios.get(endpoint, getOptions(token));
    return {
      orders: ordersResp.data.results.filter((order) => Number(order.paid_to_insure) !== 0).sort((a, b) => b.created_on.localeCompare(a.created_on)),
      cursor: ordersResp.data.cursor,
    };
  } catch (err) {
    console.error(
      'Error getting orders : ',
      err.response,
      { token },
    );
    return [];
  } */

export const getOrder = async (orderId, token, includeEmail = false) => {
  try {
    const ordersResp = await axios.get(
      `${ROUTE_API}/orders/${orderId}?include_email=${includeEmail}`,
      getOptions(token),
    );
    return ordersResp.data;
  } catch (err) {
    console.error('Error getting orders : ', err.response, { token });
    return [];
  }
};

export const getClaims = async (token, params) => {
  const url = `${ROUTE_API}/claims`;
  const options = getOptions(token);
  options.params = params;
  try {
    const claimsResp = await axios.get(url, options);
    return claimsResp.data;
  } catch (err) {
    console.log('Error getting orders : ', err.response, { token });
  }
};

export const getClaimsInsights = async (token, params, merchantId) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const existing = sessionStorage.getItem(`${merchantId}-claimsInsights-${params.number_of_days}-${today.toString()}`);
  if (existing) {
    return JSON.parse(existing);
  }
  const url = `${ROUTE_API}/claims/insights`;
  const options = getOptions(token);
  options.params = params;
  try {
    const claimsResp = await axios.get(url, options);
    sessionStorage.setItem(
      `${merchantId}-claimsInsights-${params.number_of_days}-${today.toString()}`,
      JSON.stringify(claimsResp.data),
    );
    return claimsResp.data;
  } catch (err) {
    console.log('Error getting orders : ', err.response, { token });
    // If an error occurs return an empty object
    return {
      claimResolutionStatusResults: [],
      claimStatusOfFiledResults: [],
      claimStatusOfInProgressResults: [],
      highestClaimRateByCourierResults: [],
      highestClaimRateByLocationResults: [],
      highestClaimRateByTypeResults: [],
    };
  }
};

export const searchBrands = async (query, token) => {
  const ordersResp = await axios.get(`${ROUTE_API}/merchants?search=${query}`, getOptions(token));
  return ordersResp.data;
};

export const loginUser = async (username, password) => {
  const body = {
    username,
    password,
  };

  let user;
  let brands;

  try {
    const userResponse = await axios.post(`${ROUTE_API}/login`, body, headers);

    user = userResponse.data;
    brands = await getBrands(user.token, user.id);
    setHeaderToken(user.token);

    return { user, brands };
  } catch (err) {
    console.error('Error getting user and brand : ', err.response, { user }, { brands });
  }
};

export const loginUserWithouBrands = async (username, password) => {
  const body = {
    username,
    password,
  };

  let user;

  try {
    const userResponse = await axios.post(`${ROUTE_API}/login`, body, headers);

    user = userResponse.data;
    setHeaderToken(user.token);

    return { user };
  } catch (err) {
    console.error('Error getting user : ', err.response, { user });
  }
};

export const getMerchant = async (token) => {
  try {
    const merchantResponse = await axios.get(`${ROUTE_API}/merchants`, getOptions(token));

    if (merchantResponse && merchantResponse.data && merchantResponse.data.length > 0) {
      return merchantResponse.data[0];
    }
  } catch (err) {
    console.log('Error geting merchant', err);
  }

  return [];
};

export const uploadStoreImages = async (token, logoImage, heroImage, id) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    };

    const formData = {};
    formData.store_logo = logoImage;
    formData.store_hero = heroImage;

    await axios.post(`${ROUTE_API}/merchants/${id}/media`, formData, options);
  } catch (err) {
    console.error('Error updating store images ', err, { token });
  }
};

export const requestPasswordChange = (email) =>
  axios.post(`${ROUTE_API}/forgot_password`, {
    email,
  });

export const requestForgotEmail = async (name, email, merchantName) => {
  try {
    return axios.post(`${ROUTE_API}/forgot_email`, {
      name,
      email,
      merchant_name: merchantName,
    });
  } catch (err) {
    console.log('Error requesting forgot email', err);
  }
};

export const requestPasswordResetLink = async (email, publicToken) => {
  try {
    return axios.post(`${ROUTE_API}/forgot_password`, {
      email,
      token: publicToken,
    });
  } catch (err) {
    console.log('Error requesting reset link password to onbboarding', err);
  }
};

export const resetPassword = async (password, resetPasswordGUID) =>
  axios.post(`${ROUTE_API}/reset_password`, {
    password,
    guid: resetPasswordGUID,
  });

export const setHeaderToken = (token) => localStorage.setItem('token', token);

export const setPlatform = (platform) => {
  localStorage.setItem('platform', platform);
};

export const getPlatform = () => localStorage.getItem('platform');

export const isExpertInstallOption = () => localStorage.getItem('installOption') === 'expert';

export const setExpertInstallOption = () => {
  localStorage.setItem('installOption', 'expert');
};

export const setManualInstallOption = () => {
  localStorage.setItem('installOption', 'self');
};

export const getInstallOption = () => localStorage.getItem('installOption');

export const clearInstallOption = () => {
  localStorage.removeItem('installOption');
};

export const isRoutePlusInstallation = () => localStorage.getItem('productVersion') === 'plus';

export const setRoutePlusProductVersion = () => {
  updateMerchantRequest('has_route_plus', true);
  localStorage.setItem('productVersion', 'plus');
};

export const setRouteFreeProductVersion = () => {
  updateMerchantRequest('has_route_plus', false);
  localStorage.setItem('productVersion', 'free');
};

export const clearRouteProductVersion = () => {
  localStorage.removeItem('productVersion');
};

export const clearAllStorage = () => {
  localStorage.clear();
};
