import React, {
  useState,
  createRef,
  useEffect,
  useRef,
  FC,
} from 'react';
import Dropzone from 'react-dropzone';

import DoubleImageIcon from '../../../../assets/icons/double-image';
import { ReactComponent as TrashIcon } from '../../media/delete-icon.svg';
import { InfoPopover } from './../info-popover';
import { Button } from '../button'
import { ReactComponent as Landscape } from './../../media/landscape.svg';
import { ReactComponent as Arrow } from './../../media/arrow.svg';
import ImageReq from '../ImageReq';
import {
  Container,
  Icon,
  Image,
  ImageContainer,
  Instructions,
  Instruction,
  InstructionsContainer,
  Wrapper,
  Zone,
  Title,
  Recommendation,
  Link,
  TextArea,
  ShowDragDropIcon,
  ContentPopoverImages,
  ContentPopoverText,
  ContentPopoverh3,
  ContentPopoverSpan,
} from './styles';

interface IDropzoneProps {
  maxSize?: number;
  accept?: string;
  onUpdateImage?: (binaryStr: string) => void;
  title?: string;
  recommendation?: {
    w: number;
    h: number;
  };
}

interface ContentPopoverProps {
  close: () => void;
  width: number;
  height: number;
}

const ContentPopover: FC<ContentPopoverProps> = ({ width, height, close }) => (
  <>
    <ContentPopoverImages>
      <Arrow width={100} height={15} />
      <Landscape width={100} height={25} />
    </ContentPopoverImages>
    <ContentPopoverText>
      <ContentPopoverh3>File Formats Accepted:</ContentPopoverh3>
      <ContentPopoverSpan>JPG or PNG</ContentPopoverSpan>
      <ContentPopoverh3>File Size Limit:</ContentPopoverh3>
      <ContentPopoverSpan>20MB</ContentPopoverSpan>
      <ContentPopoverh3>Canvas Size</ContentPopoverh3>
      <ContentPopoverSpan>w: {width}px  h: {height}px</ContentPopoverSpan>
      <Button
        onClick={close}
        width={60}
        height={20}
        style={{ marginTop: '20px' }}
      >Close</Button>
    </ContentPopoverText>
  </>
)

const ImageDropzone: FC<IDropzoneProps> = (props: any) => {
  const [image, setImage] = useState<string>('');
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false)
  const popoverButton = useRef(null);
  const imageRef = useRef<HTMLDivElement>(null)
  const dropzoneRef: any = createRef();

  useEffect(() => {
    if (props && props.onUpdateImage) {
      props.onUpdateImage(image);
    }
  }, [image]);

  const onDropImage = ((acceptedFiles: any) => {
    const reader = new FileReader();

    if (reader.readyState === 0 && acceptedFiles.length === 0) {
      setError(true)
      return;
    }

    reader.onload = () => {
      const binaryStr = reader.result;
      setImage(binaryStr as string)
    };

    reader.onerror = () => {
      setError(true)
    }

    acceptedFiles.forEach((file: any) => reader.readAsDataURL(file));
  })

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const hasPopulatedImage = image && image.length > 0;

  const removeImage = () => {
    setImage('')
  }

  return (
    <Wrapper ref={imageRef}>
      <TextArea>
        {props.title && <Title>{props.title}</Title>}
        {props.recommendation &&
          <Recommendation>
            Recommended w: {props.recommendation.w}px x h: {props.recommendation.h}px
            <Link ref={popoverButton} type="button" onClick={() => setOpenInfo(true)}>IMAGE REQs</Link>
          </Recommendation>
        }
        <InfoPopover
          open={openInfo}
          content={<ContentPopover
            width={props.recommendation.w}
            height={props.recommendation.h}
            close={() => setOpenInfo(false)}
          />}
          onClose={() => setOpenInfo(false)}
          anchorEl={popoverButton.current} />
      </TextArea>
      <Dropzone
        onDrop={onDropImage}
        maxSize={2e+7} // 20 MB
        accept=".png,.jpg"
      >
        {(dropzone: any) => (
          <Container>
            <input {...dropzone.getInputProps()} />
            <Zone
              type="button"
              onClick={openDialog}
            >
              <Instructions hide={hasPopulatedImage}>
                <InstructionsContainer {...dropzone.getRootProps()}>
                  <ShowDragDropIcon>
                    <DoubleImageIcon />
                    <Instruction primary>
                      Drag & Drop Image
                    </Instruction>
                    <Instruction>
                      OR
                    </Instruction>
                  </ShowDragDropIcon>
                  {!hasPopulatedImage && <Instruction secondary>
                    Select Image
                  </Instruction>}
                </InstructionsContainer>
              </Instructions>
              {hasPopulatedImage && (
                <ImageContainer>
                  <Image src={image} />
                  <Icon onClick={removeImage}>
                    <TrashIcon />
                  </Icon>
                </ImageContainer>
              )}
            </Zone>
          </Container>
        )}
      </Dropzone>
      {error &&
        <ImageReq close={() => { setError(false) }} />
      }
    </Wrapper >
  );
};

export default ImageDropzone;