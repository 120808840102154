import styled from 'styled-components';

export const MainContainer = styled.div`
  padding: 120px 0 170px;
`;

export const ContainerHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #666666;
`;

export const ContainerContent = styled.div`
  margin-left: 113px;
  max-height: 300px;
  max-width: 790px;
  background-size: cover;
`;

export const BackgroundPaper = styled.div`
  display: grid;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 93px;
  background: #f7f7f7;
  border-radius: 24px;
  height: 710px;
`;

export const CropContainer = styled.div`
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.05);
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 10;
  padding-left: 280px;
`;

export const CropButton = styled.div`
  width: 350px;
  display: flex;
`;

export const InnerCrop = styled.div`
  border: 2px dashed #31C8E0;
  height: 400px;
`;

export const CropMenu = styled.div`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: #999999;

`;

export const OuterContainerMenu = styled.div`
  margin-left: 112px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 12px;
`;

export const FirstMenuItem = styled.div`
  border: 1px dashed #31C8E0;
  width: 13px;
  height: 8px;
`;

export const SecondMenuItem = styled.div`
  border: 1px dashed #023646;
  width: 13px;
  height: 8px;
`;
