import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Container, Label, Copy, CopiedLabel } from './style';

function TrackingNumber(props) {
  const [showCopied, setShowCopied] = useState(false);

  if (!props.number) {
    return null;
  }

  function displayCopiedLabel() {
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  }

  return (
    <Container>
      <Label>Tracking # {props.number}</Label>
      <CopyToClipboard text={props.number} onCopy={displayCopiedLabel}>
        <Copy />
      </CopyToClipboard>
      {showCopied && <CopiedLabel>Copied</CopiedLabel>}
    </Container>
  );
}

export default TrackingNumber;
