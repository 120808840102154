import React, { FC } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const buttonWidth = '16px';
const useRouteButtonStyles = makeStyles({
  root: {
    height: buttonWidth,
    width: buttonWidth,
    minWidth: buttonWidth,
    borderRadius: buttonWidth,
    padding: '0px',
  },
  primary: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    }
  },
  secondary: {
    backgroundColor: '#F7F7F7',
  }
}, { name: 'CloseButton' });

export type CloseButtonProps = {
  onClick?: () => void;
  types?: 'primary' | 'secondary';
} & MuiButtonProps

let strokes = new Map()
strokes.set("primary", "white");
strokes.set("secondary", "black");

export const CloseButton: FC<CloseButtonProps> = ({ onClick, types = 'primary', ...props }) => {
  const classes = useRouteButtonStyles(props);
  const stroke = strokes.get(types);

  return (
    <MuiButton {...props} className={`${classes[types]} ${props.className}`} classes={{ root: classes.root }} onClick={onClick}>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49561 3.14404L2.64429 7.99536" stroke={stroke} strokeLinecap="round" />
        <path d="M7.49561 7.99536L2.64429 3.14404" stroke={stroke} strokeLinecap="round" />
      </svg>
    </MuiButton>
  );
}
