import React from 'react';

const SVG = ({
  style = {},
  fill = '#313942',
  width = '20px',
  className = '',
  height = '20px',
  viewBox = '0 0 20 20',
  transform = '',
}) => (
  // <svg
  //   width={width}
  //   style={style}
  //   height={height}
  //   viewBox={viewBox}
  //   className={className}
  //   transform={transform}
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  // >
  //   <path fill={fill} d="M16.469 11.25C17.169 11.25 17.874 10.7062 18.0428 10.0375L19.9628 1.2125C20.1328 0.54375 19.7041 0 19.0028 0H1.26752C0.566259 0 0 0.55875 0 1.25C0 1.94125 0.566259 2.5 1.26752 2.5H2.50004V11.25V15C2.50004 15.6912 3.10005 16.25 3.80131 16.25H17.5003C18.2003 16.25 18.7503 15.6912 18.7503 15C18.7503 14.3088 18.2003 13.75 17.5003 13.75H5.00008V11.25H16.469Z" />
  //   <path fill={fill} d="M5.62503 20C6.66058 20 7.50006 19.1605 7.50006 18.125C7.50006 17.0895 6.66058 16.25 5.62503 16.25C4.58948 16.25 3.75 17.0895 3.75 18.125C3.75 19.1605 4.58948 20 5.62503 20Z" />
  //   <path fill={fill} d="M15.625 20C16.6606 20 17.5001 19.1605 17.5001 18.125C17.5001 17.0895 16.6606 16.25 15.625 16.25C14.5895 16.25 13.75 17.0895 13.75 18.125C13.75 19.1605 14.5895 20 15.625 20Z" />
  // </svg>;

  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H16.79C18.5649 0 19.9517 1.53245 19.7751 3.29851L19.1751 9.29851C19.0218 10.8321 17.7313 12 16.19 12H6.69425C6.47734 12 6.26513 11.9768 6.0602 11.9326L6.22904 13.1386C6.29819 13.6325 6.72067 14 7.21938 14H16.9999C17.5522 14 17.9999 14.4477 17.9999 15C17.9999 15.5523 17.5522 16 16.9999 16H7.21938C5.72324 16 4.4558 14.8976 4.24836 13.4159L3.51071 8.14703L3.01361 5.1644C2.92281 4.61963 3.29083 4.1044 3.8356 4.01361C4.38037 3.92281 4.8956 4.29083 4.98639 4.8356L5.70786 9.1644C5.78823 9.64659 6.20541 10 6.69425 10H16.19C16.7038 10 17.1339 9.61071 17.1851 9.0995L17.7851 3.0995C17.8439 2.51082 17.3816 2 16.79 2H1C0.447715 2 0 1.55228 0 1ZM13.9801 3.92385C14.2983 4.18903 14.3413 4.66195 14.0762 4.98016L11.5762 7.98016C11.4413 8.14204 11.2445 8.2397 11.034 8.24925C10.8235 8.2588 10.6187 8.17935 10.4697 8.03035L8.96967 6.53035C8.67678 6.23746 8.67678 5.76258 8.96967 5.46969C9.26256 5.1768 9.73744 5.1768 10.0303 5.46969L10.9496 6.38896L12.9238 4.01988C13.189 3.70167 13.6619 3.65868 13.9801 3.92385ZM9 18.5C9 19.3284 8.32843 20 7.5 20C6.67157 20 6 19.3284 6 18.5C6 17.6716 6.67157 17 7.5 17C8.32843 17 9 17.6716 9 18.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z"
    />
  </svg>
);

export default SVG;
