/* eslint-disable import/first */
import styled from 'styled-components';
const PinImage = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/pin.svg';
const RoutePlusIcon = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/route-plus-icon-small.svg';
import { Breakpoints } from '../../utils/responsive-breakpoints';
const blurredImage = "https://route-cdn.s3.amazonaws.com/route-order-tracking/blurredMap_squared.png";

export const Map = styled.div`
    height: 256px;
    pointer-events: none;
    width: 100%;
    border-radius: 24px 24px 0 0;
    overflow: hidden;
    position: relative;

    @media (max-width: ${Breakpoints.xsMax}px) {
      height: 256px;
    }
`;

export const Pin = styled.div`
  background-image: url(${PinImage});
  height: 10px;
  transform: translate(-50%, -50%);
  width: 10px;
`;

export const StatusTooltip = styled.div`
  background: ${ props => props.routePlus ? `linear-gradient(180deg, #4FF8F7 0%, #62CEDD 100%)` : '#A0A0A2'};
  border-radius: 8px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  padding: 6px 9px;
  position: absolute;
  right: 15px;
  top: -15px;
  transform: translateX(100%);
  white-space:nowrap;

  ${ props => props.routePlus && `
    &:after {
      content: url(${RoutePlusIcon});
      margin-left: 2px;
      vertical-align: middle;
    }
  `}
`;

export const NavigationContainer = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const LoaderContainer = styled.div`
    background-color: white;
    padding: 16px;
    border-radius: 12px;
`;

export const ErrorMessage = styled.div`
    position: absolute;
    background-color: white;
    color: #a0a0a0;
    max-width: 325px;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
    border-radius: 16px;
    padding: 16px 24px 16px 24px;
    -webkit-box-shadow: 7px 10px 31px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: 7px 10px 31px -12px rgba(0,0,0,0.75);
    box-shadow: 7px 10px 31px -12px rgba(0,0,0,0.75);
`;

export const CloseButton = styled.button`
    font-family: 'Titillium Web', sans-serif;
    color: black;
    font-weight: 900;
    background-color: #F7F7F7;
    border-radius: 50px;
    width: 100%;
    border: none;
    font-size: 14px;
    padding: 8px;
    outline: none;
    margin-top: 16px;
    pointer-events: auto;

    &:hover {
        cursor: pointer;
        background-color: #E7E7E7;
    }
`;

export const BlurredMap = styled.div`
    background-image: url(${blurredImage});
    background-size: cover;
    border-radius: 24px 24px 0 0;
    height: 256px;
    position: relative;

    @media (max-width: ${Breakpoints.xsMax}px) {
      height: 256px;
    }
`;

export const BlurredMapStatusLabel = styled.div`
  background: rgba(51, 51, 51, 0.9);
  border-radius: 24px;
  color: white;
  font-family: 'Titillium Web', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  padding: 12px 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

