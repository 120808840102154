import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Subnav,
  Items,
  Link,
} from "./SmallNavBar.styles";

const useStyles = makeStyles(theme => ({
  preview: {
    margin: 0,
    fontWeight: 600,
    background: '#333333',
    borderRadius: '20px',
    zIndex: 100,
    color: '#FFFFFF !important',
  }
}));

export type Props = {};

const SmallNavBar: FC<any> = (props) => {
  const classes = useStyles();

  const viewClicked = (view: string) => {
    props.setSelected(view);
  };

  return (
    <Subnav>
      <Items className = {`${ props.selected === props.items[0] ? classes.preview : ''}`}
        onClick={() => viewClicked(props.items[0])}><Link active={props.selected === props.items[0]} >{props.items[0]}</Link>
      </Items>
      <Items className = {`${ props.selected === props.items[1] ? classes.preview : ''}`}
onClick={() => viewClicked(props.items[1])}><Link active={props.selected === props.items[1]} >{props.items[1]}</Link>
      </Items>
    </Subnav>
  );
};

export default SmallNavBar;
