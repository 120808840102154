import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Marker = styled.div`
  margin-bottom: ${(props) => (props.trackingLine ? '48px' : '')};
  margin-right: 15px;
  margin-top: 1px;
  position: relative;

  &:before {
    border-right: ${(props) => (props.trackingLine ? '2px' : '0px')} ${(props) => (props.dashed ? 'dashed' : 'solid')}
      ${(props) => (props.active ? colors.primaryColor : '#ddd')};
    content: '';
    height: 58px;
    left: calc(50% - 2px);
    position: absolute;
    top: 50%;
    width: 1px;
  }
`;

export const MarkerIcon = styled.div`
  background-color: ${(props) =>
    props.active || (props.info && props.info.status === 'Delivered') ? colors.primaryColor : '#ddd'};
  border: 4px solid white;
  border-radius: 50%;
  height: 8px;
  position: relative;
  width: 8px;
`;

export const ShipmentType = styled.div`
  color: 'black';
  font-family: 'Titillium Web', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
`;

export const Metadata = styled(ShipmentType)`
  font-weight: 300;
  color: #999;
`;
