import React from 'react';
import LoadingModal from '../LoadingModal/LoadingModal';
import { Container, Arrow } from './Card.styles';

import { ICardProps } from './interface';

const Card: React.FC<ICardProps> = ({
  children,
  style,
  image,
  isLoading,
  arrow,
  arrowDirection,
  arrowBackground,
}: ICardProps) => (
  <>
    <Container style={style} image={image}>
      {children}
      {isLoading === true && <LoadingModal isLoading></LoadingModal>}
    </Container>
    {arrow && <Arrow className={`triangle ${arrowDirection}`} background={arrowBackground} />}
  </>
);

export default Card;
