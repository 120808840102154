import {OnboardingStatus} from "../../api/types";
import {Action} from "redux";
import {ThunkDispatch} from "../../store";
import {setMerchantOnboardingStatus} from "../../api";
import {exportStateToBrowser} from "../sign-in/util";

export const ONBOARDING_STATUS_UPDATE = 'ONBOARDING_STATUS_UPDATE' as const;
export interface OnboardingStatusUpdateAction {
  type: typeof ONBOARDING_STATUS_UPDATE;
  data: {
    status: OnboardingStatus;
    merchantId: string;
  };
}

export function isOnboardingStatusUpdateAction(action: Action|OnboardingStatusUpdateAction): action is OnboardingStatusUpdateAction {
  return action.type === ONBOARDING_STATUS_UPDATE;
}

export function getUpdateOnboardingStatusForActiveMerchantAction(data: OnboardingStatusUpdateAction['data']): OnboardingStatusUpdateAction {
  return {
    type: ONBOARDING_STATUS_UPDATE,
    data,
  };
}

export const ONBOARDING_STATUS_UPDATE_FAILED = 'ONBOARDING_STATUS_UPDATE_FAILED' as const;
export interface OnboardingStatusUpdateFailedAction {
  type: typeof ONBOARDING_STATUS_UPDATE_FAILED;
}

export function onboardingStatusUpdateFailedActionCreator(): OnboardingStatusUpdateFailedAction {
  return {
    type: ONBOARDING_STATUS_UPDATE_FAILED,
  };
}

export type UpdateOboardingStatusOptions = {
  merchantId: string;
  merchantToken: string;
  onboardingStatus: OnboardingStatus;
};

export function updateOnboardingStatusForMerchant(options: UpdateOboardingStatusOptions) {
  return async (dispatch: ThunkDispatch) => {
    try {
      const newOnboardingStatus = await setMerchantOnboardingStatus(options);
      const actionResult = dispatch(getUpdateOnboardingStatusForActiveMerchantAction({
        status: newOnboardingStatus,
        merchantId: options.merchantId,
      }));
      dispatch(exportStateToBrowser());
      return actionResult;
    } catch {
      return dispatch(onboardingStatusUpdateFailedActionCreator());
    }
  }
}
