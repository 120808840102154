import React from "react";
const SVG = ({
  style = {},
  fill = 'none',
  width = '24px',
  className = '',
  height = '21px',
  viewBox = '0 0 24 21',
  transform = '',
  stroke = "#F1373C"
}) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill='none'
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path d="M22.84 1.5957H1V19.5425H22.84V1.5957Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1 16.3205L6.82 10.5629L11.92 14.2871L20.12 5.24414L22.84 8.09081" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.21 9.73018C12.4305 9.73018 13.42 8.6713 13.42 7.36509C13.42 6.05889 12.4305 5 11.21 5C9.98945 5 9 6.05889 9 7.36509C9 8.6713 9.98945 9.73018 11.21 9.73018Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;

export default SVG;
