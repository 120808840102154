import React, {FC} from 'react';
import {Dialog, Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Logo} from '../../media/logo';
import {Text, TextVariant, Button} from '../../components';
import customerRoute from '../../media/customer-track-order.png';
import merchantRoute from '../../media/merchant-track-order.png';
import routePlusImg from '../../media/route-plus.png';
import { defaultPaperBorderRadius } from '../../constants/theme';

export type Props = {
  open: boolean;
  routePlusEnabled: boolean;
  onClose?: () => void;
  onExplore?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    // The left hand pane has a z-index of 9000 and we want to
    // show above that. This needs to be important because MaterialUI
    // adds a z-index of 1300 to the element style directly
    zIndex: '10000 !important' as any,
  },
  dialogPaper: {
    maxWidth: (props: {routePlusEnabled: boolean}) => props.routePlusEnabled ? '582px' : '800px',
    overflowX: 'hidden',
    overflowY: 'auto',
    textAlign: 'center',
    borderRadius: defaultPaperBorderRadius,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '54px',
    marginBottom: '57px',
  },
  titleText: {
    maxWidth: (props: {routePlusEnabled: boolean}) => props.routePlusEnabled ? '278px' : 'initial',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bodyText: {
    marginTop: '25px',
    maxWidth: '374px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  routePlusImage: {
    maxWidth: 'min(372px, 100%)',
    height: 'auto',
    marginTop: '60px',
    imageRendering: '-webkit-optimize-contrast',
  },
  leftImage: {
    width: '296px',
    marginLeft: '30px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
    marginTop: (props: {routePlusEnabled: boolean}) => props.routePlusEnabled ? '30px' : '0px',
  },
  rightImage: {
    width: '301px',
    marginRight: '30px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
    }
  },
  trackingOrderCustomer: {
    marginTop: '37px',
  },
  trackingOrderMerchant: {
    marginTop: '65px',
  },
  leftButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '55px',
    marginBottom: '55px',
    paddingRight: '12.5px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingRight: '0px',
      marginBottom: '0px',
    },
  },
  rightButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '55px',
    marginBottom: '55px',
    paddingLeft: '12.5px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingLeft: '0px',
      marginTop: '35px',
    },
  },
}), {name: 'PortalCongratsModal'});

export const PortalCongratsModal: FC<Props> = (props) => {
  const {open, onClose, onExplore, routePlusEnabled} = props;
  const classes = useStyles({routePlusEnabled});

  return (
    <Dialog open={open} onClose={onClose} classes={{root: classes.dialog, paper: classes.dialogPaper}}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.logoWrapper}>
            <Logo width={59.12} height={43.48}/>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Text variant={TextVariant.Title} className={classes.titleText}>
            Congratulations! You're all done.
          </Text>
          <Text variant={TextVariant.Title} className={classes.titleText}>
            Welcome to your merchant portal <span role="img" aria-label="clap">👏</span>
          </Text>
        </Grid>
        {
          routePlusEnabled &&
          <Grid item xs={12}>
            <img data-testid="route-plus-img" className={classes.routePlusImage} alt="Route Plus - Dashboard" src={routePlusImg}/>
          </Grid>
        }
        <Grid item xs={12}>
          <Text variant={TextVariant.Body1} className={classes.bodyText}>
            <Text variant={TextVariant.Body1} fontWeight={600} inline>The merchant portal is your claims, tracking, settings, and metrics control center.</Text>
            There won't be a lot of information for about a week, but feel free to look around &amp; explore.
          </Text>
        </Grid>
        {
          !routePlusEnabled && 
          <React.Fragment>
            <Grid className={classes.trackingOrderCustomer} item xs={12} sm={6}>
              <img data-testid="customer-img" className={classes.leftImage} alt="Track Order - Customers" src={customerRoute}/>
            </Grid>
            <Grid className={classes.trackingOrderMerchant} item xs={12} sm={6}>
              <img data-testid="merchant-img" className={classes.rightImage} alt="Track Order - Merchant" src={merchantRoute}/>
            </Grid>
          </React.Fragment>
        }
        <Grid item xs={12} sm={6} className={classes.leftButtonContainer}>
          <Button width={206} onClick={onClose}>
            <Text variant={TextVariant.Button} color="white">Take a look around</Text>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightButtonContainer}>
          <div>
            <Button width={206} onClick={onExplore} style={{marginBottom: '5px'}}>
              <Text variant={TextVariant.Button} color="white">
                Check Out Admin Controls
              </Text>
            </Button>
            <Text variant={TextVariant.Body4} color="#999999">(Recommended)</Text>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}
