import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {usePlaidLink} from 'react-plaid-link-2';
import {Field, FieldRenderProps} from 'react-final-form';

//@ts-ignore
import {PLAID_ENV, PLAID_PUBLIC_KEY} from '../../../../../constants'

import plaidLogo from '../../../media/plaid-logo.svg';
import plaidBackground from '../../../media/plaid-background.png';
import {colors} from '../../../constants/styles';
import {Button} from '../../../components';
import {formFieldNames} from '../types';
import {PlaidMetadata} from '../../../api/types';

const height = 159;
const width = 530;
const aspectRatio = height / width;

type FirstArg<T> = T extends (arg: infer U) => any ? U : never;
type PlaidOptions = FirstArg<typeof usePlaidLink>;

function getVerticalMarginInPercent(pixelMargin: number): string {
  const verticalMarginPercent = pixelMargin / height;
  return `${(verticalMarginPercent * aspectRatio) * 100}%`;
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    maxWidth: `${width}px`,
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    marginTop: getVerticalMarginInPercent(25),
    fontSize: '13px',
    lineHeight: '20px',
    textAlign: 'center',
    fontWeight: 600,
    color: colors.darkGray,
  },
  logo: {
    marginTop: getVerticalMarginInPercent(23),
  },
  button: {
    marginTop: getVerticalMarginInPercent(15),
  }
}, {name: 'Plaid'})

// This needs to be a separate function component because Plaid will
// error if rendered in callback vs as its own component
const InnerForm: FC<FieldRenderProps<PlaidMetadata|undefined>> = (renderProps) => {
  const classes = useStyles();
  const {value, onChange} = renderProps.input;
  const plaidConfig: PlaidOptions = {
    //@ts-ignore
    selectAccount: true,
    clientName: 'Route',
    env: PLAID_ENV,
    publicKey: PLAID_PUBLIC_KEY,
    product: ['auth'],
    token: value?.public_token,
    onSuccess: (token: string, metadata: PlaidMetadata) => {
      // Plaid will error if we synchronously change the token
      setTimeout(() => onChange(metadata));
    },
  };

  const {open, ready, error} = usePlaidLink(plaidConfig);
  const description = value ?
    'Great! Your payments have been set up with Plaid! 👍' :
    'Connect securely and instantly with our trusted partner Plaid';
  const buttonText = value ?
    'Edit' :
    'Connect Instantly with Plaid™';

  return (
    <div className={classes.overlay}>
      <img className={classes.logo} src={plaidLogo}/>
      <span className={classes.description}>{description}</span>
      <Button
        width={175}
        height={22}
        bgColor="black"
        fontSize="10px"
        lineHeight="10px"
        className={classes.button}
        onClick={() => open()}
        disabled={!ready}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export const Plaid: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.image} src={plaidBackground}/>
      <Field name={formFieldNames.plaidMetadata} component={InnerForm}/>
    </div>
  );
};
