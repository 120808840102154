import React, {FC, useState} from 'react';
import {InputLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Field, FieldRenderProps} from 'react-final-form';
import {
  CardNumberElement as StripeCardNumberElement,
  CardNumberElementProps as StripeCardNumberElementProps,
  CardExpiryElement as StripeCardExpiryElement,
  CardExpiryElementProps as StripeCardExpiryElementProps,
  CardCvcElement as StripeCardCvcElement,
  CardCvcElementProps as StripeCardCvcElementProps,
} from '@stripe/react-stripe-js';
import {StripeElementStyle, StripeElementChangeEvent} from '@stripe/stripe-js';

import {text, colors} from '../constants/styles';
import {defaultInputBorderRadius} from '../constants/theme';

const typeToDefaultWidth = {
  number: '240px',
  cvc: '60px',
  expiry: '60px',
}

type StyleProps = {
  type: 'number' | 'expiry' | 'cvc';
  width?: string;
}

const useStyles = makeStyles({
  stripeInput: {
    borderWidth: '0.5px',
    borderColor: colors.gray,
    borderRadius: defaultInputBorderRadius,
    boxShadow: 'none',
    height: '32px',
    width: (props: StyleProps) => props.width ? props.width : typeToDefaultWidth[props.type],
    padding: '8px 10px 7px 10px !important',
  },
  label: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: text.inputLabelColor,
  },
}, {name: 'StripeInput'})

const stripeStyle: StripeElementStyle = {
  base: {
    fontFamily: 'Titillium Web, sans-serif',
    fontStyle: 'normal',
    fontSize: '11.5px',
    lineHeight: '14px',
    fontWeight: 'normal',
    color: text.textBodyColor,
  }
}

type CommonProps = {
  name: string;
  label: string;
  width?: string;
}

export type CardNumberElementProps = StripeCardNumberElementProps & CommonProps & {
  type: 'number'
}

export type CardExpiryElementProps = StripeCardExpiryElementProps & CommonProps & {
  type: 'expiry'
}

export type CardCvcElementProps = StripeCardCvcElementProps & CommonProps & {
  type: 'cvc'
}

export type StripeElementProps = 
  CardNumberElementProps |
  CardExpiryElementProps |
  CardCvcElementProps;

export const StripeElement: FC<StripeElementProps> = (props) => {
  const {name, label, type, width, options, ...restProps} = props;
  const classes = useStyles({type, width});

  // Initialize to false because as a stripe element, this will be
  // required to have a value and it does not start with one
  const [valid, setValid] = useState(false);

  // We need the react-final-form to re-evaluate the validator sometimes.
  // We don't have an actual value for this field (because Stripe is the
  // only one that has that) so we just use a "fake" value that we
  // increment when needed
  const [fakeValue, setFakeValue] = useState(0);

  const Element = props.type === 'number' ? StripeCardNumberElement :
    props.type === 'expiry' ? StripeCardExpiryElement :
    StripeCardCvcElement;

  const derivedOptions = {
    style: {
      base: {
        ...(stripeStyle.base),
        ...(options && options.style && options.style.base),
      },
      ...(options && options.style),
    },
    placeholder: '',
    ...options
  }

  const fieldValidator = () => valid ? undefined : 'Field Required';

  return (
    <div>
      <InputLabel className={classes.label} htmlFor={name}>
        <span>
          {label}
        </span>
      </InputLabel>
      <Field name={name} validate={fieldValidator} render={(renderProps: FieldRenderProps<any>) => {
        // This setup allows us to at least know when the Stripe element has a valid value
        // for validation purposes. We don't have access to the value for more granular error messages,
        // we only know if the value is "complete"
        const handleStripeChange = (event: StripeElementChangeEvent) => {
          if (event.complete === valid) {
            return;
          }

          setValid(event.complete);
          setFakeValue(fakeValue + 1);
          renderProps.input.onChange(fakeValue + 1);
        }

        return (
          <Element id={name} onChange={handleStripeChange} className={classes.stripeInput} options={derivedOptions} {...restProps as any}/>
        );
      }}/>
      
    </div>
  );
}
