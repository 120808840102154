import React from 'react';

const SVG = ({
  style = {},
  fill = '#313222',
  width = '21px',
  className = '',
  height = '18px',
  viewBox = '0 0 21 18',
  transform = '',
  selected,
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <>
      <circle cx="18.5139" cy="2.23611" r="2.23611" fill={fill} />
      <circle cx="2.86111" cy="15.6528" r="2.23611" fill={fill} />
      <circle cx="14.0417" cy="12.2986" r="2.23611" fill={fill} />
      <circle cx="7.33334" cy="6.70834" r="2.23611" fill={fill} />
      <path
        d="M2.86108 15.6527L7.33331 6.70831L14.0416 12.2986L18.5139 2.23608"
        stroke={fill}
        strokeWidth="2.23611"
        strokeLinejoin="round"
      />
    </>
    {/* {selected === 'insights' ? (
      <>
        <circle cx="18.5139" cy="2.23611" r="2.23611" fill={fill} />
        <circle cx="2.86111" cy="15.6528" r="2.23611" fill={fill} />
        <circle cx="14.0417" cy="12.2986" r="2.23611" fill={fill} />
        <circle cx="7.33334" cy="6.70834" r="2.23611" fill={fill} />
        <path
          d="M2.86108 15.6527L7.33331 6.70831L14.0416 12.2986L18.5139 2.23608"
          stroke={fill}
          strokeWidth="2.23611"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.126 4.9961C19.1719 4.9311 20 4.06226 20 3C20 1.89543 19.1046 1 18 1C16.8954 1 16 1.89543 16 3C16 3.40952 16.1231 3.79029 16.3343 4.10734L12.849 11.0681C12.6799 11.0807 12.5166 11.1144 12.3619 11.1665L8.97108 8.3408C8.99009 8.23005 9 8.11618 9 8C9 6.89543 8.10457 6 7 6C5.89543 6 5 6.89543 5 8C5 8.37895 5.10539 8.73329 5.28847 9.03529L2.0045 15C2.003 15 2.0015 15 2 15C0.895431 15 0 15.8954 0 17C0 18.1046 0.895431 19 2 19C3.10457 19 4 18.1046 4 17C4 16.6374 3.9035 16.2973 3.73478 16.0041L7.04064 9.9996C7.26883 9.99505 7.48764 9.95228 7.69095 9.87744L11.039 12.6675C11.0134 12.7952 11 12.9273 11 13.0625C11 14.1671 11.8954 15.0625 13 15.0625C14.1046 15.0625 15 14.1671 15 13.0625C15 12.6441 14.8715 12.2557 14.6518 11.9346L18.126 4.9961Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient id="paint0_linear" x1="20" y1="19" x2="1.86241" y2="1.15275" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2BFFFF" />
            <stop offset="0.592551" stopColor="#28B8E3" />
            <stop offset="1" stopColor="#255FC0" />
          </linearGradient>
        </defs>
      </>
    )} */}
  </svg>
);

export default SVG;
