import React, { useRef } from 'react';
import {
  FormControlLabelProps,
  InputBase,
  InputBaseProps,
  MenuItem as MuiMenuItem,
  Select,
  InputLabel,
  withStyles,
  SvgIcon
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FieldRenderProps } from 'react-final-form';

import { colors, text } from '../constants/styles';
import { defaultInputBorderRadius } from '../constants/theme';

const useArrowStyles = makeStyles({
  root: {
    width: '11px',
    height: '7px',
    position: 'absolute',
    top: 'calc(50% - 3.5px)',
    right: '9.5px',
    pointerEvents: 'none',
  }
}, { name: 'SelectArrow' });

function SelectArrow(props: any) {
  const classes = useArrowStyles();

  return (
    <SvgIcon {...props} viewBox="0 0 11 7" className={classes.root}>
      <path d="M4.68627 5.86077L1.62946 1.58124C1.15669 0.91937 1.62982 0 2.44319 0L8.55681 0C9.37018 0 9.84331 0.91937 9.37054 1.58124L6.31373 5.86077C5.91491 6.41912 5.08509 6.41912 4.68627 5.86077Z" fill="#5DC8DB" />
    </SvgIcon>
  );
}

export const MenuItem = withStyles({
  root: {
    paddingLeft: '10px',
    fontSize: '12px',
    '& ~ &': {
      borderTop: '0.5px solid #CCCCCC',
    },
    '&:not(.Mui-selected):hover': {
      backgroundColor: colors.darkBlue,
      color: colors.white,
    }
  }
}, { name: 'RouteMenuItem' })(MuiMenuItem);

type RouteSelectProps<T> = {
  name: string;
  label: React.ReactNode;
  children: React.ReactNode;
  validator?: any;
  parse?: (value: any, name: string) => any;
  format?: (value: any, name: string) => any;
  labelClasses?: FormControlLabelProps['classes'];
  selectClasses?: InputBaseProps['classes'];
  className?: string;
}

const useStyles = makeStyles({
  labelRoot: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  select: {
    padding: '8px 0px 7px 10px !important',
    boxSizing: 'border-box',
    height: '30px',
    textAlign: 'left',
  },
  // This is applied to the "floating" element that shows up when
  // selecting an item. For some reason the anchor position of "bottom left"
  // shows up in an incorrect position so we need to fix it this way
  menu: {
    borderRadius: defaultInputBorderRadius,
    maxHeight: '220px',
  },
  menuList: {
    borderRadius: defaultInputBorderRadius,
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: colors.gray,
    // The material UI component will set the width to 100% + 15px with a right padding
    // of 15px. This will cause the item separator borders to stop 15px short of
    // the right side. We get rid of the padding so the separator borders reach
    // any scrollbar
    padding: '0 !important',
  }
}, { name: 'RouteSelect' });

export function RouteSelect<T>(props: RouteSelectProps<T>) {
  const baseClasses = useStyles();

  const { name, children, label, parse, format, validator } = props;

  return (
    <Field
      name={name}
      parse={parse}
      format={format}
      validate={validator}
      render={(renderProps: FieldRenderProps<T>) => {
        return (
          <Select
            value={renderProps.input.value}
            onChange={renderProps.input.onChange}
            name="route-select"
            classes={{
              select: baseClasses.select
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null,
              classes: {
                paper: baseClasses.menu,
              },
              MenuListProps: {
                classes: {
                  root: baseClasses.menuList,
                },
              }
            }}
            IconComponent={SelectArrow}
            input={<StyledSelectBase className={props.className} label={label} dataId={name} classes={props.selectClasses} />}
          >
            {children}
          </Select>
        );
      }}
    />
  );
}

const useStyledSelectStyles = makeStyles({
  root: {
    width: 'auto',
    maxWidth: '240px',
    fontSize: '12px'
  },
  inputRoot: {
    display: 'block',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: (props: any) => props.error ? colors.error : colors.gray,
    borderRadius: defaultInputBorderRadius,
    marginTop: '7px',
  },
  label: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
    color: text.inputLabelColor,
  },
  arrow: {

  }
}, { name: 'StyledSelectBase' });

function StyledSelectBase(props: InputBaseProps & { label: React.ReactNode, dataId?: string }) {
  const classes = useStyledSelectStyles();
  const { classes: propClasses, dataId, label, className, ...restProps } = props;

  return (
    <div className={classes.root + ' ' + className}>
      <InputLabel className={classes.label} htmlFor={dataId}>
        <span>
          {label}
        </span>
      </InputLabel>
      <InputBase
        id={dataId}
        data-testid={dataId}
        className={`${classes.root} ${propClasses && propClasses.root}`}
        classes={{ root: classes.inputRoot }}
        {...restProps}
      />
    </div>

  );
}
