export const permissions = {
  dashboard: {
    view: 'view_dashboard',
  },
  orders: {
    view: 'view_orders',
  },
  issues: {
    view: 'view_issues',
    list: 'list_issues',
    edit: 'edit_issues',
    tabs: 'view_issues_tabs',
  },
  issue_rules: {
    view: 'view_issue_rules',
    edit: 'edit_issue_rules',
  },
  file_issues: {
    view: 'view_file_issues',
    edit: 'edit_file_issues',
  },
  engage: {
    view: 'view_engage',
  },
  insights: {
    view: 'view_insights',
  },
  shipping_notifications: {
    view: 'view_shipping_notifications',
    edit: 'edit_shipping_notifications',
  },
  admin: {
    view: 'view_admin',
    edit: 'edit_admin',
  },
};

export const merchantRoles: {[key: string]: any} = {
  merchant_admin: {
    static: [],
  },
  route_admin: {
    static: [],
  },
  merchant_full_user: {
    static: [
      permissions.dashboard.view,
      permissions.orders.view,
      permissions.issues.view,
      permissions.insights.view,
      permissions.issues.tabs,
      permissions.file_issues.view,
      permissions.file_issues.edit,
      permissions.engage.view,
      permissions.admin.view,
    ],
  },
  merchant_limited_user: {
    static: [
      permissions.orders.view,
      permissions.issues.view,
      permissions.file_issues.view,
      permissions.file_issues.edit,
    ],
  },
};

export default {
  permissions,
  merchantRoles,
};
