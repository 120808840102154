import {Stripe, CreateTokenBankAccountData, StripeError} from '@stripe/stripe-js';
import {
  createStripeBankAccount,
  createStripeCardAccount,
  createStripeBankAccountFromPlaid,
} from '../../api';
import {FormData} from '../../pages/premiums-transfer/types';
import { addBreadcrumb, Severity } from '@sentry/browser';

export type BillingConfig = {
  merchantToken: string;
  stripe: Stripe;
  formData: FormData;
};

function isHumanReadableStripeError(error: StripeError): boolean {
  // Look at https://stripe.com/docs/api/errors or the Typescript definitions to see
  // more details on these errors. The tl;dr is that the `message` field should be
  // suitable to show when the error type is `card_error`, `invalid_request_error`, or `validation_error`.
  return error.type === 'card_error' ||
    error.type === 'invalid_request_error' ||
    error.type === 'validation_error';
}

export type BillingResponse = {
  successful: true;
} | {
  successful: false;
  error: Error|StripeError;
  isHumanReadableError: boolean;
}

export async function submitCreditCardForm(config: BillingConfig): Promise<BillingResponse> {
  try {
    const data = config.formData;
    const element = data.stripe?.element;
    if (!element) {
      throw new Error('Expected Stripe DOM Element to be defined');
    }

    const {token, error} = await config.stripe.createToken(element, {
      name: data.ccCardholder,
      address_country: data.ccCountry,
      address_line1: data.ccAddressOne,
      address_line2: data.ccAddressTwo,
      address_city: data.ccCity,
      address_state: data.ccState,
      address_zip: data.ccZip,
    });

    if (error) {
      if (!isHumanReadableStripeError(error)) {
        throw error;
      }

      return {
        successful: false,
        error,
        isHumanReadableError: true,
      }
    }

    if (!token) {
      throw new Error('Stripe token not returned');
    }

    addBreadcrumb({
      message: 'Stripe token creation successful',
      category: 'stripe/plaid',
      level: Severity.Info,
    });

    return await createStripeCardAccount(config.merchantToken, token);
  } catch (err) {
    addBreadcrumb({
      message: err.message || 'Unknown error while creating stripe card account',
      category: 'stripe/plaid',
      level: Severity.Error,
    });

    return {
      successful: false,
      error: new Error('An unknown error occurred while processing the credit card'),
      isHumanReadableError: true,
    }
  }
}

export async function submitBankAccountForm(config: BillingConfig): Promise<BillingResponse> {
  try {
    const data = config.formData;

    if (!data.bankAccountName || !data.bankAccountNumber || !data.bankAccountRoutingNumber || !data.bankAccountType) {
      throw new Error('Missing Bank Account Information');
    }

    const tokenConfig: CreateTokenBankAccountData = {
      country: 'US',
      currency: 'USD',
      account_holder_name: data.bankAccountName,
      account_number: data.bankAccountNumber,
      routing_number: data.bankAccountRoutingNumber,
      account_holder_type: data.bankAccountType,
    }
    const {token, error} = await config.stripe.createToken('bank_account', tokenConfig);

    if (error) {
      if (!isHumanReadableStripeError(error)) {
        throw error;
      }

      return {
        successful: false,
        error,
        isHumanReadableError: true,
      }
    }

    if (!token) {
      throw new Error('Stripe Token Missing From Stripe Response');
    }

    addBreadcrumb({
      message: 'Stripe token creation successful',
      category: 'stripe/plaid',
      level: Severity.Info,
    });

    return await createStripeBankAccount(config.merchantToken, token);
  } catch (err) {
    addBreadcrumb({
      message: err.message || 'Unknown error while creating stripe bank account',
      category: 'stripe/plaid',
      level: Severity.Error,
    });
    return {
      successful: false,
      error: new Error('An unknown error occurred while processing the bank account'),
      isHumanReadableError: true,
    }
  }
}

export async function submitPlaidBankAccount(config: BillingConfig): Promise<BillingResponse> {
  try {
    if (!config.formData.plaidMetadata) {
      throw new Error('Expecting Plaid metadata object');
    }
  
    return await createStripeBankAccountFromPlaid(config.merchantToken, config.formData.plaidMetadata);
  } catch (err) {
    addBreadcrumb({
      message: err.message || 'Unknown error while creating plaid bank account',
      category: 'stripe/plaid',
      level: Severity.Error,
    });
    return {
      successful: false,
      error: new Error('An unknown error occurred while processing the Plaid bank account'),
      isHumanReadableError: true,
    }
  }
}
