import React, { Component } from 'react';
import './ListItem.scss';

class ListItem extends Component {
  render() {
    const { value, styleName } = this.props;

    return (
      <li className={`list-item ${styleName}`}>{value}</li>
    );
  }
}

export default ListItem;
