import styled from 'styled-components';
import colors from '../../../../constants/colors';

const questionIcon = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/question.svg';

export const ShipmentHeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const StatusContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
`;

export const Status = styled.span`
  font-family: Titillium Web;
  font-weight: 600;
  font-size: 17px;
  color: #999;

  ${(props) =>
    props.inTransit &&
    `
        color: #249D0E;
    `}

  ${(props) =>
    props.delivered &&
    `
        color: ${colors.primaryColor};
    `}
`;

export const Tooltip = styled.div`
  background-color: white;
  box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  position: absolute;
  z-index: 1;
  left: 140%;
  padding: 32px 30px 40px 30px;
  visibility: hidden;
  text-align: center;

  width: 280px;
`;

export const TooltipIcon = styled.div`
  margin-left: 8px;
  color: #ccc;
  position: relative;
  display: inline-block;
  background-image: url(${questionIcon});
  width: 16px;
  height: 16px;

  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

export const Icon = styled.img`
  height: 32px;
  width: 32px;
  margin: 0 auto 16px;
`;

export const Title = styled.div`
  font-family: Titillium Web;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #333;
  margin-bottom: 16px;
`;

export const Message = styled.div`
  font-family: Titillium Web;
  font-size: 17px;
  line-height: 24px;
  color: #999;
`;

export const DeliveryDateContainer = styled.div`
  font-family: Titillium Web;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 54px;
`;

export const Day = styled.div`
  line-height: 48px;
  color: #ccc;
`;

export const Date = styled.div`
  line-height: 48px;
  color: #333;
`;
