import React, { Component } from 'react';
import './MultiStep.scss';

class MultiStep extends Component {
  render() {
    const {
      currentStep, step, title, description,
    } = this.props;

    return (
      <div className="multistep">
        <h3 onClick={this.props.onClick} className={`title ${currentStep !== step ? 'inactive' : ''} ${currentStep > step ? 'active' : ''}`}>{title}</h3>

        { currentStep === step
          ? (
            <div>
              {description ? (<h4 className="description">{description}</h4>) : ''}
              {this.props.children}
            </div>
          ) : ''
        }
      </div>
    );
  }
}

export default MultiStep;
