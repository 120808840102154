import type { ReactFragment } from 'react';
import { CombinedState, Action } from 'redux';
import { ThunkDispatch as OriginalThunkDispatch, ThunkAction as OriginalThunkAction, ThunkAction } from 'redux-thunk';
import { RouterState } from "connected-react-router";

import { RouteApi } from "../api/types";
import { BrowserUserSessionData } from "../../../stores/SessionManager";
import { BrandState } from "../../Brand/store"

export type ThunkDispatch = OriginalThunkDispatch<State, never, Action<any>>;

export type UserData = {
  loggedIn: false;
} | {
  loggedIn: true;
  user: RouteApi.User.UserResponse;
  brands: RouteApi.Merchant.MerchantResponse[];
  activeBrand: RouteApi.Merchant.MerchantResponse;
  tier?: any;//tier?: RouteApi.Merchant.MerchantResponse;
  merchantRole?: any;
}

export type AccountCreationState = {
  merchantCreationData?: {
    platformId?: string;
    currency?: string;
    country?: string;
    domain?: string;
    name?: string;
  }
  email?: string;
  passwordGuid?: string;
  merchant?: RouteApi.Merchant.MerchantResponse;
  page: {
    showDomainExistsError: boolean;
  }
}

export type SignInState = {
  passwordResetEmail: string;
  showIncorrectCredentialsError: boolean;
  showInvalidPasswordResetRequestError: boolean;
}

export type OnboardingState = {
  showPortalModal: boolean;
}

export type AppState = {
  setUserSessionOutsideStoreFunction: (userData: BrowserUserSessionData) => void;
  redirecting: boolean;
  accountCreation: AccountCreationState;
  signIn: SignInState;
  userData: UserData;
  userError: string | ReactFragment | null;
  loading: boolean;
  onboarding: OnboardingState;
  brand: BrandState;
};

export type State = CombinedState<{
  router: RouterState<any>,
  app: CombinedState<AppState>
}>;

export const initialState: AppState = {
  setUserSessionOutsideStoreFunction: () => { },
  redirecting: true,
  accountCreation: {
    page: {
      showDomainExistsError: false,
    }
  },
  signIn: {
    passwordResetEmail: '',
    showIncorrectCredentialsError: false,
    showInvalidPasswordResetRequestError: false,
  },
  userData: {
    loggedIn: false,
  },
  userError: null,
  loading: false,
  onboarding: {
    showPortalModal: false,
  },
  brand: {
    page: {
      error: false,
    }
  }
};
