import React from 'react';
import IconNew from '../IconNew';
import { Container, Label } from './HintText.styles';

import { IHintTextProps } from './interface';

const HintText: React.FC<IHintTextProps> = ({ children }: IHintTextProps) => (
  <>
    <Container>
      <Label>
        {children}
      </Label>
    </Container>
  </>
);

export default HintText;
