export const ruleSet = {
  FILE_MAX_HOURS: 'fileMaxHours',
  NO_POLICE_MAX_USD: 'noPoliceMaxUsd',
  NO_DELIVERY_MIN_HOURS: 'noDeliveryMinHours',
  NO_DELIVERY_MAX_HOURS: 'noDeliveryMaxHours',
  AUTO_APPROVE_LOST_MAX_USD: 'autoApproveLostMaxUsd',
  DELIVERED_MIN_HOURS: 'deliveredMinHours',
  DELIVERED_MAX_HOURS: 'deliveredMaxHours',
  AUTO_APPROVE_MAX_STOLEN_USD: 'autoApproveMaxStolenUsd',
  AUTO_APPROVE_DAMAGE_MAX_USD: 'autoApproveDamagedMaxUsd',
  REPLACE_OVER_MAX_PERCENT: 'replaceOverMaxPercent',
};

export const issueRulesPlaceholder = {
  id: '',
  createdOn: '',
  merchantId: '',
  userId: '',
  fileMaxHours: 0,
  noPoliceMaxUsd: 0,
  deliveredMinHours: 0,
  deliveredMaxHours: 0,
  noDeliveryMinHours: 0,
  noDeliveryMaxHours: 0,
  autoApproveLostMaxUsd: 0,
  autoApproveMaxStolenUsd: 0,
  autoApproveDamagedMaxUsd: 0,
  replaceOverMaxPercent: 0,
  preferredResolution: '',
};

export const issueFilingRules = [
  {
    title: 'Days since order to report an issue', description: 'What is the maximum number of days after an order that you want to allow your customers to file an issue resolution? ', type: ruleSet.FILE_MAX_HOURS, displayType: 'days',
  },
  {
    title: 'Order total to require police report (Stolen)', description: 'What is the maximum amount (in USD) on order items with an issue before a police report is required?', type: ruleSet.NO_POLICE_MAX_USD, displayType: 'dollars',
  },
];

export const autoApprovalRules = [
  {
    title: 'Order total to auto approve (Lost)', description: 'What is the maximum amount (in USD) on order items with a "Lost" issue to automatically approve the issue resolution (without manual review)?', type: ruleSet.AUTO_APPROVE_LOST_MAX_USD, displayType: 'dollars',
  },
  {
    title: 'Order total to auto approved (Stolen)', description: 'What is the maximum amount (in USD) on order items with a "Lost" issue to automatically approve the issue resolution (without manual review)?', type: ruleSet.AUTO_APPROVE_MAX_STOLEN_USD, displayType: 'dollars',
  },
  {
    title: 'Order total to auto approve (Damaged)', description: 'What is the maximum amount (in USD) on order items with a "Damaged" issue to automatically approve the issue resolution?', type: ruleSet.AUTO_APPROVE_DAMAGE_MAX_USD, displayType: 'dollars',
  },
];

export const issueAssessmentRules = [
  {
    title: 'Days since last checkpoint pre delivery to wait before assessment', description: 'When a package has not yet been delivered, what is the minimum number of days after the last shipment checkpoint to wait before Route assesses the issue resolution?', type: ruleSet.NO_DELIVERY_MIN_HOURS, displayType: 'days',
  },
  {
    title: 'Days since last checkpoint pre delivery before issue denial', description: 'When a package has not yet been delivered, what is the maximum number of days after the last shipment checkpoint before an issue resolution is automatically denied?', type: ruleSet.NO_DELIVERY_MAX_HOURS, displayType: 'days',
  },
  {
    title: 'Max days after delivery before denial', description: 'When a package has been delivered, what is the maximum number of days after delivery before the issue resolution is automatically denied?', type: ruleSet.DELIVERED_MAX_HOURS, displayType: 'days',
  },
  {
    title: 'Minimum days after delivery to wait before assessment (Stolen)', description: 'When a package has been delivered, what is the minimum number of days after delivery to wait before Route assesses a stolen issue?', type: ruleSet.DELIVERED_MIN_HOURS, displayType: 'days',
  },
];
