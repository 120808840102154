import React from 'react';
import PropTypes from 'prop-types';
import './PageTitle.scss';
import IconNew from '../../components/IconNew';

const PageTitle = ({ title, isNew }) => (
  <div className="portal-page-header">
    <h1 className="portal-page-title">{title}</h1>
    {isNew && (
      <span className="portal-page-icon-new">
        <IconNew>NEW</IconNew>
      </span>
    )}
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
