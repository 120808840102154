import React from 'react';

const SVG = ({
  style = {},
  fill = '#EA5541',
  width = '20px',
  className = '',
  height = '20px',
  viewBox = '0 0 20 20',
  transform = '',
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 8C0 3.58172 3.58172 0 8 0H12C16.4183 0 20 3.58172 20 8V12C20 16.4183 16.4183 20 12 20H8C3.58172 20 0 16.4183 0 12V8Z" fill={fill}/>
    <rect x="9" y="5" width="2" height="6" rx="1" fill="white"/>
    <circle cx="10" cy="14" r="1" fill="white"/>
  </svg>
);

export default SVG;
