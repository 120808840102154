import React from "react";
const SVG = ({
  style = {},
  fill = 'none',
  width = '30',
  className = '',
  height = '22',
  viewBox = '0 0 30 22',
  transform = '',
  stroke = "#333333"
}) =>
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5 7.5H29.5V21.5H11.5V16.5" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.5 0.5H0.5V14.5H18.5V0.5Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 11.9999L5.3 7.4999L9.5 10.3999L16.3 3.3999L18.5 5.5999" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.5 6.5C10.6046 6.5 11.5 5.60457 11.5 4.5C11.5 3.39543 10.6046 2.5 9.5 2.5C8.39543 2.5 7.5 3.39543 7.5 4.5C7.5 5.60457 8.39543 6.5 9.5 6.5Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.6 19.3C18.5 19.3 18.5 19.3 18.4 19.3C18.3 19.3 18.2 19.2 18.1 19L17 16.6L15.8 17.5C15.7 17.6 15.6 17.6 15.5 17.6C15.4 17.6 15.4 17.6 15.3 17.6C15.1 17.5 15 17.3 15 17.2V10.5C15 10.3 15.1 10.1 15.3 10C15.4 10 15.4 10 15.5 10C15.6 10 15.7 10 15.8 10.1L20.6 14.6C20.7 14.7 20.8 14.9 20.7 15.1C20.6 15.3 20.5 15.4 20.3 15.5L19 15.7L20.1 18.1C20.2 18.4 20.1 18.6 19.8 18.8L18.8 19.2C18.8 19.3 18.7 19.3 18.6 19.3Z" fill={stroke} />
    <path d="M15.5 10.5L20.3 15L18.2 15.4L19.5 18.4L18.5 18.8L17.2 15.8L15.5 17.1V10.5ZM15.5 9.5C15.4 9.5 15.2 9.5 15.1 9.6C14.7 9.8 14.5 10.1 14.5 10.5L14.6 17.1C14.6 17.5 14.8 17.8 15.2 18C15.3 18.1 15.5 18.1 15.6 18.1C15.8 18.1 16 18 16.2 17.9L16.9 17.4L17.7 19.3C17.9 19.7 18.2 19.9 18.6 19.9C18.7 19.9 18.9 19.9 19 19.8L20 19.4C20.2 19.3 20.4 19.1 20.5 18.8C20.6 18.6 20.6 18.3 20.5 18L19.7 16.1L20.5 16C20.9 15.9 21.2 15.7 21.3 15.3C21.4 14.9 21.3 14.5 21 14.3L16.2 9.8C16 9.6 15.7 9.5 15.5 9.5Z" fill="white" />
  </svg>;

export default SVG;
