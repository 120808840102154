import React, { useState } from 'react';

const SVG = ({
  style = {},
  fill = '#666666',
  width = '16px',
  className = '',
  height = '16px',
  viewBox = '0 0 16 16',
  transform = '',
  selected= false,
}) => {
  const [active, setActive] = useState(selected);

  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      transform={transform}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill={active ? fill : '#F7F7F7'}/>
      <rect x="7" y="7" width="2" height="5" rx="1" fill={active ? '#ffffff' : '#333333'}/>
      <rect x="7" y="4" width="2" height="2" rx="1" fill={active ? '#ffffff' : '#333333'}/>
    </svg>
  );
};

export default SVG;
