import React from "react";
const SVG = ({
               style = {},
               fill = '',
               width = '47px',
               className = '',
               height = '23px',
               viewBox = '0 0 47 23',
               transform = '',
             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill='none'
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="0.5" width="47" height="22" rx="11" fill="#F06350"/>
    <circle cx="11.5" cy="11.5" r="8" fill="white"/>
    <path d="M26.608 14.554C25.145 14.554 24.804 13.652 24.804 11.771C24.804 9.901 25.156 8.911 26.608 8.911C28.06 8.911 28.401 9.901 28.401 11.771C28.401 13.685 28.071 14.554 26.608 14.554ZM26.608 15.621C28.962 15.621 29.666 14.334 29.666 11.771C29.666 9.241 28.962 7.844 26.608 7.844C24.254 7.844 23.539 9.252 23.539 11.771C23.539 14.312 24.243 15.621 26.608 15.621ZM31.0878 15.5H32.3088V12.64H35.1798V11.573H32.3088V9.032H35.7628V7.965H31.0878V15.5ZM36.91 15.5H38.131V12.64H41.002V11.573H38.131V9.032H41.585V7.965H36.91V15.5Z" fill="white"/>
  </svg>
export default SVG;
