import styled, { css } from 'styled-components';

export const Subnav = styled.ul`
  display: inline-flex;
  justify-content: center;
  background: #F7F7F7;
  border-radius: 20px;
  align-items: center;
  height: 20px;
  margin: 0;
  color: #333;
  cursor: pointer;
`;

export const Items = styled.li<any>`
  height: 100%;
  display: flex;
  width: 110px;
  font-size: 10px;
  justify-content: center;
  align-items: center;
`;

export const Link = styled.span<any>`
  font-weight: 600;
  padding: 3px;
  text-decoration: none !important;
  color: #333333;

  ${(props) => props.active && css`
    color: #FFFFFF;
  `}
`;
