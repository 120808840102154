import React from 'react';
import {
  makeStyles, createStyles, Grid, Container,
} from '@material-ui/core';
import './OnboardingContainer.scss';

const useStyles = makeStyles(() => createStyles({
  root: {
    marginTop: '',
  },
}));

const OnboardingContainer = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={[classes.root, props.className]}
    >
      <Container maxWidth="lg" className="onboarding-container">
        {props.children}
      </Container>
    </Grid>
  );
};

export default OnboardingContainer;
