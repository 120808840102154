
import { addBreadcrumb, Severity } from '@sentry/browser';
import { FormData } from '../../store'
import {
  createUser,
  CreateUserResponse,
  createMerchant,
  uploadMerchantMedia,
  CreateMerchantResult,
  merchantDetails

} from '../../api'

async function createForMerchantThatDoesNotExist(formData: FormData): Promise<CreateMerchantResult> {
  try {
    const domain = formData.domain;
    if (!domain) {
      throw new Error('Expected domain to be populated for setup account');
    }

    addBreadcrumb({
      message: '"createForMerchantThatDoesNotExist - checking for domain existence',
      category: 'Setup Account',
      level: Severity.Info,
    });

    // We are checking to see if the  merchant already exists before we try to create the user account
    // because if we create the user account and then find out that the merchant domain is already used
    // then the user will run into an issue when re-submitting because then the user account already exists
    const publicSettings = await merchantDetails(domain);
    if (publicSettings && publicSettings.platform_id !== "email") {
      return {
        successful: false,
        reason: 'MerchantExists',
      };
    }

    addBreadcrumb({
      message: '"createForMerchantThatDoesNotExist" - starting user creation',
      category: 'Setup Account',
      level: Severity.Info,
    });

    const platformId = 'brand';
    const userResponse = await createUser({
      email: formData.email,
      password: formData.password,
      phone: formData.phoneNumber,
      name: formData.fullName,
      platformId,
    });

    if (!userResponse.successful) {
      return {
        successful: userResponse.successful,
        reason: userResponse.reason,
        message: userResponse.message
      };
    }

    addBreadcrumb({
      message: '"createForMerchantThatDoesNotExist" - starting merchant creation',
      category: 'Setup Account',
      level: Severity.Info,
    });

    // Prefer form data and then url data
    const merchantData = {
      platform_id: platformId,
      store_domain: domain,
      store_name: formData.storeName,
      country: formData.country,
      currency: 'USD',
      source: platformId,
    };

    // The user and merchant should be linked after this because this is a user with "merchant" as its role
    const result = await createMerchant({
      userToken: userResponse.user.token,
      userId: userResponse.user.id,
      data: merchantData,
    });

    if (!result.successful) {
      return result;
    }

    if (!(formData.coverImage !== "" || formData.logoImage !== "")) return result;

    const uploadMediaBody = {
      store_logo: formData.logoImage,
      store_hero: formData.coverImage,
    }

    await uploadMerchantMedia(result.merchant.prod_api_secret, result.merchant.id, uploadMediaBody) // ignore upload image response, it's not a blocker to proceed

    return result;
  } catch (err) {
    addBreadcrumb({
      message: `"createForMerchantThatDoesNotExist" failure: "${err.message}"`,
      category: 'Account Creation',
      level: Severity.Error,
    });
    throw err;
  }
}

export async function createNecessaryUserAndMerchant(formData: FormData): Promise<CreateMerchantResult> {
  const response = await createForMerchantThatDoesNotExist(formData)

  return response
}
