import React from 'react';
import './PageHeader.scss';

const PageHeader = ({ children }) => (
      <div className="page-header">
         {children}
      </div>
);

export default PageHeader;
