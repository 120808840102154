import { Action } from "redux";
import { AppState, initialState } from "../../store";
import { RedirectAction, REDIRECTING_DONE, PreloadDataAction, PRE_LOAD_DATA } from "../../actions/redirect/redirect";


function isReducerAction(action: Action<any>): action is RedirectAction {
  return action.type === REDIRECTING_DONE;
}

export function redirectingReducer(state: AppState['redirecting'] = initialState.redirecting, action: RedirectAction | Action<any>): AppState['redirecting'] {
  if (!isReducerAction(action)) {
    return state;
  }

  switch(action.type) {
    case REDIRECTING_DONE:
      return false;
    default:
      throw new Error('Unexpected action');
  }
}

function isPreloadAction(action: Action|PreloadDataAction): action is PreloadDataAction {
  return action.type === PRE_LOAD_DATA;
}

export function setUserSessionOutsideStateStoreFnReducer(
  state: AppState['setUserSessionOutsideStoreFunction'] = initialState.setUserSessionOutsideStoreFunction,
  action: Action<any> | PreloadDataAction
) {
  if (!isPreloadAction(action)) {
    return state;
  }

  return action.setUserSessionFn;
}
