import axios, { AxiosError } from 'axios';
import {Token} from '@stripe/stripe-js';
import {ROUTE_API} from '../../../constants';
import {RouteApi, PlaidMetadata, OnboardingStatus} from './types';
import { addBreadcrumb } from '@sentry/browser';
import { getCurrentUserId } from '../../../services/user';

function isAxiosError<T>(err: unknown): err is AxiosError<T> {
  return (typeof err === 'object' && err && (err as any).config);
}

const headers = {
  'Content-Type': 'application/json',
};

function headersWithToken(token: string) {
  return {
    ...headers,
    token,
  };
}

export type CreateUserOptions = {
  email: string;
  password: string;
  platformId: string;
  phone?: string;
  name?: string;
  merchantToken?: string;
}

export type CreateUserErrorReason = 'User Exists' | 'MerchantExists' | 'PasswordResetGuidExpired' | 'Unknown';
export type CreateUserResponse = {
  successful: true;
  user: RouteApi.User;
} | {
  successful: false;
  reason: CreateUserErrorReason;
  message?: string;
}

export async function createUserAndLinkToMerchant(options: CreateUserOptions & {merchantToken: string}) {
  return createUser(options);
}

export async function createUser(options: CreateUserOptions): Promise<CreateUserResponse> {
  const body: RouteApi.User.CreateUserRequestBody = {
    name: options.name,
    password: options.password,
    phone: options.phone,
    primary_email: options.email,
    platform_id: options.platformId,
  };

  if (
      options.merchantToken &&
      (process.env.REACT_APP_STAGE === 'dev' ||
      process.env.REACT_APP_STAGE === 'stage')) {
    //TODO change testing script to get users associated with merchant instead of doing this
    const merchant = await getMerchantFromMerchantToken(options.merchantToken);
    if (merchant) {
      body.notes = merchant.store_logo;
    }
  }

  try {
    // If a merchant token is given when creating a user the user <-> merchant link is created for us
    const {data} = await axios.post<RouteApi.User.CreateUserResponse>(`${ROUTE_API}/users`, body, {
      headers: options.merchantToken ?
        headersWithToken(options.merchantToken) :
        headers,
    });

    return {
      successful: true,
      user: data,
    };
  } catch(err) {
    if (!isAxiosError<RouteApi.User.CreateUserResponse>(err)) {
      return {
        successful: false,
        reason: 'Unknown',
        message: err?.message,
      };
    }

    const responseCode = err.response?.status;
    if (responseCode === 409) {
      return {
        successful: false,
        reason: 'User Exists',
      };
    }

    return {
      successful: false,
      reason: 'Unknown',
      message: err.message,
    };
  }
}

export async function merchantExists(domain: string): Promise<boolean> {
  try {
    await axios.get(`${ROUTE_API}/public-settings/${domain}`);
    return true;
  } catch(err) {
    if (err.response) {
      const {response} = err as AxiosError;
      if (response?.status === 404 || response?.status === 500) {
        return false;
      }
    }

    throw err;
  }
}

export type CreateMerchantResult = {
  successful: true;
  merchant: RouteApi.Merchant;
} | {
  successful: false;
  reason: 'MerchantExists' | 'Unknown',
};

export async function createMerchant(options: {
  userToken: string,
  userId: string,
  data: RouteApi.Merchant.CreateMerchantRequestBody,
}): Promise<CreateMerchantResult> {
  try {
    const response = await axios.post<RouteApi.Merchant.CreateMerchantResponse>(
      `${ROUTE_API}/merchants?user_id=${options.userId}`,
      options.data,
      {headers: headersWithToken(options.userToken)},
    );
  
    return {
      successful: true,
      merchant: response.data,
    };
  } catch (err) {
    if (err.response) {
      const {response} = err as AxiosError;
      if (response?.status === 409) {
        return {
          successful: false,
          reason: 'MerchantExists',
        };
      }
    }
    throw err;
  }
}

export async function updateMerchant(options: {
  id: string;
  token: string;
  data: RouteApi.Merchant.UpdateMerchantRequestBody
}) {
  const userId = getCurrentUserId();
  const response = await axios.post<RouteApi.Merchant.UpdateMerchantResponse>(
    `${ROUTE_API}/merchants/${options.id}?user_id=${userId}`,
    options.data,
    {headers: headersWithToken(options.token)}
  );

  return response.data;
}

export async function upsertMerchantContact(options: {
  merchantId: string;
  merchantToken: string;
  data: RouteApi.MerchantContact.UpsertMerchantContactRequestBody;
}) {
  const response = await axios.post<RouteApi.MerchantContact.UpsertMerchantContactResponse>(
    `${ROUTE_API}/merchants/${options.merchantId}/merchant_contact`,
    options.data,
    {headers: headersWithToken(options.merchantToken)}
  );

  return response.data;
}

export async function getMerchantFromMerchantToken(token: string): Promise<RouteApi.Merchant.MerchantResponse | undefined> {
  try {
    const response = await axios.get<RouteApi.Merchant.GetAllMerchantsResponse>(`${ROUTE_API}/merchants`, {headers: headersWithToken(token)});
    
    // The API should only ever return a single merchant if you use a merchant token to make this fetch
    // We can be even safer here in case that changes and just look for a merchant that matches the token
    const merchant = response.data.find((merchant) => merchant.prod_api_secret === token);

    if (!merchant || !merchant.prod_api_secret) {
      throw new Error('Expected Merchant to have token');
    }

    return merchant;  
  } catch {
    return;
  }
}

export async function setMerchantOnboardingStatus(data: {merchantToken: string, merchantId: string, onboardingStatus: OnboardingStatus} ): Promise<OnboardingStatus> {
  const body: RouteApi.Merchant.UpdateMerchantOnboardingStatusRequestBody = {};

  return axios.post<RouteApi.Merchant.UpdateMerchantOnboardingStatusResponse>(
    `${ROUTE_API}/merchants/${data.merchantId}/onboarding_status?onboarding_status=${encodeURI(data.onboardingStatus)}`, body, {
        headers: headersWithToken(data.merchantToken),
      }
    )
    .then((response) => response.data);
}

export async function loginUser(data: {username: string; password: string;}): Promise<RouteApi.User.UserResponse> {
  const body: RouteApi.User.LoginUserRequestBody = data;

  const result = await axios.post<RouteApi.User.LoginUserResponse>(`${ROUTE_API}/login`, body, {headers});

  // Let's validate to make sure the user always has a token
  // It _should_ always be set, but it is technically a nullable field
  // in the database so we should validate our assumptions.
  if (!result.data.token) {
    throw new Error('Expected a user token on the user object');
  }

  return result.data;
}

export async function getMerchantsForUser(data: {userId: string; userToken: string}): Promise<RouteApi.User.GetAllMerchantsResponse> {
  try {
    const result = await axios.get<RouteApi.User.GetAllMerchantsResponse>(
      `${ROUTE_API}/users/${data.userId}/merchants`,
      {headers: headersWithToken(data.userToken)}
    );
  
    // Let's validate to make sure that all merchants contain a token so we can simplify checks
    // in the rest of the app.
    if (result.data.some((merchant) => !merchant.prod_api_secret)) {
      throw new Error('Encountered merchant without token');
    }
  
    return result.data;
  } catch(err) {
    if (err.response) {
      const {response} = err as AxiosError;
      if (response?.status === 404) {
        return [];
      }
    }

    throw err;
  }
}

export async function linkMerchantToUser(data: {userId: string; merchantToken: string}): Promise<boolean> {
  try {
    await axios.post<RouteApi.UserMerchantRecord>(
      `${ROUTE_API}/users/${data.userId}/link_merchant`,
      {},
      {headers: headersWithToken(data.merchantToken)},
    );

    return true;
  } catch (err) {
    addBreadcrumb({
      message: `Error encountered while trying to link user and merchant: "${err.message}"`,
    });

    return false;
  }
}

export async function requestPasswordReset(data: RouteApi.User.RequestPasswordResetRequestBody) {
  try {
    const body: RouteApi.User.RequestPasswordResetRequestBody = data;
    await axios.post(`${ROUTE_API}/forgot_password`, body, {headers});
    return true;
  } catch (err) {
    if (err.response) {
      const {response} = err as AxiosError;
      if (response?.status === 404) {
        return false;
      }
    }
    
    throw new Error('Password Reset Request Failed');
  }
}

export async function resetPassword(data: {password: string; guid: string;}) {
  try {
    const body: RouteApi.User.ResetPasswordRequestBody = data;
    await axios.post(`${ROUTE_API}/reset_password`, body, {headers});

    return true;
  } catch(err) {
    if (err.response) {
      const {response} = err as AxiosError;
      if (response?.status === 400 && response.data.message === 'Password reset token is expired') {
        return false;
      }
    }

    throw err;
  }
} 

export const resendPasswordResetLink = () => new Promise((resolve) => setTimeout(() => resolve(true), 1000));

export type BillingApiResponse = {
  successful: true;
} | {
  successful: false;
  error: Error;
  isHumanReadableError: boolean;
}

export const createStripeCardAccount = async (merchantToken: string, stripeToken: Token): Promise<BillingApiResponse> => {
  try {
    if (stripeToken.type !== 'card') {
      throw new Error('Stripe token "type" required to be "card"');
    }
  
    if (!stripeToken.card) {
      throw new Error('Stripe token "card" is undefined')
    }
  
    const body: RouteApi.Billing.CreateAccountRequestBody = {
      type: 'card',
      source_token: stripeToken.id,
      card: stripeToken.card,
    };
  
    return createStripeBillingAccount(merchantToken, body);
  } catch(err) {
    return {
      successful: false,
      error: err,
      isHumanReadableError: false,
    };
  }
}

export const createStripeBankAccount = async (merchantToken: string, stripeToken: Token): Promise<BillingApiResponse> => {
  try {
    if (stripeToken.type !== 'bank_account') {
      throw new Error('Stripe token "type" required to be "bank_account"');
    }
  
    if (!stripeToken.bank_account) {
      throw new Error('Stripe token "bank_account" is undefined')
    }
  
    const body: RouteApi.Billing.CreateAccountRequestBody = {
      type: 'bank',
      source_token: stripeToken.id,
      bank_account: stripeToken.bank_account,
    };
  
    return createStripeBillingAccount(merchantToken, body);
  } catch(err) {
    return {
      successful: false,
      error: err,
      isHumanReadableError: false,
    };
  }
}

export const createStripeBankAccountFromPlaid = async (merchantToken: string, data: PlaidMetadata): Promise<BillingApiResponse> => {
  try {
    const body: RouteApi.Billing.CreateAccountRequestBody = {
      type: 'plaid',
      source_token: data.public_token,
      metadata: data,
    };
  
    return createStripeBillingAccount(merchantToken, body);
  } catch(err) {
    return {
      successful: false,
      error: err,
      isHumanReadableError: false,
    };
  }
}

const humanReadableErrorMessages = [
  'Your card was declined',
  'Your card has expired',
  "Your card's security code is incorrect",
];

const createStripeBillingAccount = async (merchantToken: string, data: RouteApi.Billing.CreateAccountRequestBody): Promise<BillingApiResponse> => {
  try {
    await axios.post(`${ROUTE_API}/billing`, data, {
      headers: {
        'Content-Type': 'application/json',
        token: merchantToken,
      },
    });

    return {
      successful: true,
    };
  } catch (err) {
    if (err.response) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        const dataIsString =  typeof error.response?.data === 'string';
        return {
          successful: false,
          error: dataIsString ? new Error(error.response?.data) : error,
          isHumanReadableError: dataIsString &&
            humanReadableErrorMessages.some((err) => error.response?.data.includes(err)),
        };
      }
    }

    return {
      successful: false,
      error: err,
      isHumanReadableError: false,
    };
  }
}
