import React from 'react';
import Moment from 'react-moment';
import {
  ShipmentHeaderContainer,
  StatusContainer,
  Status,
  TooltipIcon,
  Tooltip,
  Title,
  Icon,
  Message,
  DeliveryDateContainer,
  Day,
  Date,
} from './style';
import { StatusLabel, Statuses } from '../../utils/shipment';

const delivered = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/tooltip_icons/delivered.png';
const inTransit = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/tooltip_icons/inTransit.png';
const processing = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/tooltip_icons/processing.png';
const warning = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/tooltip_icons/warning.png';

const tooltipText = (status) => {
  switch (status) {
    case Statuses.NOT_AVAILABLE:
      return {
        title: 'Waiting For Tracking',
        message: 'No tracking number was found for the shipment. The merchant may still be working to generate it.',
        icon: processing,
      };
    case Statuses.ATTEMPT_FAIL:
      return {
        title: 'Delivery Attempted',
        message: 'The carrier attempted to deliver the package but was unable to. The carrier will usually try again.',
        icon: warning,
      };
    case Statuses.ACTIVE:
      return null;
    case Statuses.NOT_ACTIVE:
      return null;
    case Statuses.DELIVERED:
      return { title: 'Delivered', message: 'The package has been delivered.', icon: delivered };
    case Statuses.REGISTERED:
      return null;
    case Statuses.PENDING:
      return null;
    case Statuses.INFO_RECEIVED:
      return {
        title: 'Awaiting Shipment',
        message: 'Information for the package has been provided to the carrier.',
        icon: processing,
      };
    case Statuses.IN_TRANSIT:
      return {
        title: 'In Transit',
        message: "The package has been received by the carrier and is on it's way to it's destination.",
        icon: inTransit,
      };
    case Statuses.OUT_FOR_DELIVERY:
      return { title: 'Out for Delivery', message: 'The package is out for delivery by the carrier.', icon: inTransit };
    case Statuses.UNKNOWN_CARRIER:
      return {
        title: 'Carrier Not Supported',
        message: 'Unfortunately this carrier is unsupported or invalid.',
        icon: warning,
      };
    case Statuses.EXPIRED:
      return {
        title: 'Tracking Expired',
        message: 'The tracking number assigned to the package has expired',
        icon: processing,
      };
    case Statuses.AVAILABLE_FOR_PICKUP:
      return {
        title: 'Available for Pickup',
        message: "The carrier has delivered the package to it's pickup destination.",
        icon: delivered,
      };
    case Statuses.EXCEPTION:
      return {
        title: 'Carrier Problem',
        message: 'The carrier experienced a problem with the package.',
        icon: warning,
      };
    default:
      return null;
  }
};

function ShipmentHeader(props) {
  const { estDeliveryDate, status } = props;
  const statusLabel = StatusLabel(status);

  const inTransit = status === Statuses.IN_TRANSIT || status === Statuses.OUT_FOR_DELIVERY;
  const delivered = status === Statuses.DELIVERED || status === Statuses.AVAILABLE_FOR_PICKUP;

  const statusInfo = tooltipText(status);

  return (
    <ShipmentHeaderContainer className="ShipmentHeader">
      <StatusContainer>
        <Status inTransit={inTransit} delivered={delivered}>
          {statusLabel}
        </Status>
        {statusInfo && (
          <TooltipIcon>
            <Tooltip>
              <Icon src={statusInfo.icon} />
              <Title>{statusInfo.title}</Title>
              <Message>{statusInfo.message}</Message>
            </Tooltip>
          </TooltipIcon>
        )}
      </StatusContainer>

      {estDeliveryDate && (
        <DeliveryDateContainer>
          <Day>
            <Moment format="dddd" parse="YYYY-MM-DDTHH:mm:ss.SSSZ">
              '{estDeliveryDate}'
            </Moment>
          </Day>
          <Date>
            <Moment format="MMMM D" parse="YYYY-MM-DDTHH:mm:ss.SSSZ">
              '{estDeliveryDate}'
            </Moment>
          </Date>
        </DeliveryDateContainer>
      )}
    </ShipmentHeaderContainer>
  );
}

export default ShipmentHeader;
