import React from 'react';
import { Route } from 'react-router';
import { Box } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { defaultTheme } from './constants/theme';
import { ConnectedWelcome as Welcome } from './pages/welcome/welcome-container';
import { ConnectedAccountCreation } from './pages/account-creation/account-creation-container';
import { ConnectedSignInForm as SignInForm } from './pages/sign-in/sign-in-container';
import { ConnectedPasswordResetRequest } from './pages/sign-in/password-reset-request/request-container';
import { ConnectedPasswordResetConfirmation } from './pages/sign-in/password-reset-request/confirmation-container';
import { ConnectedPasswordReset } from './pages/sign-in/password-reset/reset-container';
import { ConnectedPasswordResetSuccess } from './pages/sign-in/password-reset/success-container';
import { ConnectedPasswordResetExpired } from './pages/sign-in/password-reset/expired-container';
import { ConnectedConfigureForm } from './pages/configure-route-plus/configure-container';
import { ConnectedAccountCreationError } from "./pages/account-creation/account-creation-error-container";
import { ConnectedForgotEmail } from "./pages/sign-in/forgot-email-container";
import { ConnectedTransferPremiums } from './pages/premiums-transfer/transfer-container';
import { ConnectedPasswordGuidExpiredPage } from './pages/account-creation/expired-password-container';
import { ConnectedThankYouPage } from './pages/Thank-you-page/thank-you-page-container';

import svg from './media/background.svg';
import './index.scss';
import { ConnectedCongrats } from "./pages/congrats/congrats-container";
import { ConnectedErrorBanner } from './pages/misc/error-banner-container';
import { ConnectedLoadingToast } from './pages/misc/loading-toast';
import Pendo from '../Portal/components/Pendo/Pendo';


const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${svg})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    height: '100%',
    width: '100%',
    fontFamily: 'Titillium Web, sans-serif'
  },
  container: {
    overflowX: 'hidden',
    width: '390px',
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
})

export const OnboardingApp = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={defaultTheme}>
      <ConnectedLoadingToast />
      <Box className={`${classes.root} onboarding-2`} m={0}>
        <Route exact path="/onboarding/welcome" component={Welcome} />
        <Route exact path="/onboarding/account-creation" component={ConnectedAccountCreation} />
        <Route exact path="/onboarding/account-creation/password-expired" component={ConnectedPasswordGuidExpiredPage} />
        <Route exact path="/onboarding/account-creation/error" component={ConnectedAccountCreationError} />
        <Route exact path="/onboarding/sign-in" component={SignInForm} />
        <Route exact path="/onboarding/congrats" component={ConnectedCongrats} />
        <Route exact path="/onboarding/sign-in/request-password-reset" component={ConnectedPasswordResetRequest} />
        <Route exact path="/onboarding/sign-in/password-reset-confirmation" component={ConnectedPasswordResetConfirmation} />
        <Route exact path="/onboarding/sign-in/reset-password" component={ConnectedPasswordReset} />
        <Route exact path="/onboarding/sign-in/password-reset-success" component={ConnectedPasswordResetSuccess} />
        <Route exact path="/onboarding/sign-in/password-reset-expired" component={ConnectedPasswordResetExpired} />
        <Route exact path="/onboarding/configure-route-plus" component={ConnectedConfigureForm} />
        <Route exact path="/onboarding/forgot-email" component={ConnectedForgotEmail} />
        <Route exact path="/onboarding/transfer-premiums" component={ConnectedTransferPremiums} />
        <Route exact path="/onboarding/thank-you-page" component={ConnectedThankYouPage} />
      </Box>
      <ConnectedErrorBanner />
      <Pendo
        anonymous={true}
      />
    </ThemeProvider>
  );
}
