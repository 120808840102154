export const labels = {
  ORDERS: 'Orders',
  TOTAL_ORDERS: 'Total Orders',
  PROTECTED_ORDERS: 'Protected Orders',
  REORDERS: 'Reorders',
  CLAIMS_REORDERED :'Claims Reordered',
  REORDERED_REVENUE: 'Reorder Revenue',
  REFUNDS: 'Refunds',
  CLAIMS_REFUNDED: 'Claims Refunded',
  REFUNDED_SAVINGS: 'Refund Savings',
};

export const graphTitles = {
  TOTAL_ORERS_BY_DAY: 'Total Orders by Day',
  PROTECTED_ORDERS_BY_DAY: 'Protected Orders by Day',
  CLAIMS_REORDERED_BY_DAY: 'Claims Reordered by Day',
  REORDER_REVENUE_BY_DAY: 'Reorder Revenue by Day',
  CLAIMS_REFUNDED_BY_DAY: 'Claims Refunded by Day',
  REFUNDED_SAVING_BY_DAY: 'Refund Savings by Day'
}

export const sortColumnTitles = {
  'Order Date' : 'source_created_on',
  'Store Order Number' : 'source_order_number',
  'Route Order ID': 'order_number',
  'Customer Name' : 'first_name',
  'Order Subtotal' : 'subtotal',
  'Protection Cost' : 'paid_to_insure_usd',
  'Issue Date' : 'created_at',
  'Amount Paid' : 'revenues',
  'Issue Status' : 'issue_status',
}
