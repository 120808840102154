import React from 'react';
import AlertTriangle from '../../../../assets/icons/alert-triangle';
import CloseValidation from '../../../../assets/icons/close-validation';
import AlertTip from '../../../../assets/icons/alert-tip';

export function validateEmail(email) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (email === '' || emailRegex.test(email));
}

export function validatePhone(phone) {
  const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return (phone === '' || phoneRegex.test(phone));
}

const DrawerValidation = (props) => {
  // Currently the only required email field is billing,
  // so the blank case will only show for billing. If we want to make others required, we can start passing a message
  const emailErrorMessage = (props.isBlank
    ? <div className="alert-middle-div">
            You must enter a valid Billing Contact
            email before you proceed.
    </div>
    : <div className="alert-middle-div">
            Looks like this email is invalid.<br />
        Please check formatting and try again.
    </div>);

  if (props.isEmail) {
    return <div className="validation">
            <AlertTip />
            <div className="validation-box">
                <div className="alert-left-div"><AlertTriangle /></div>
                {emailErrorMessage}
                <div className="alert-right-div" onClick={props.closeEmailError}><CloseValidation /></div>
            </div>
        </div>;
  }
  if (props.isPhone) {
    return <div className="validation">
            <AlertTip />
            <div className="validation-box">
                <div className="alert-left-div"><AlertTriangle /></div>
                <div className="alert-middle-div">Looks like this phone is invalid.<br />
                    Please check formatting and try again.</div>
                <div className="alert-right-div" onClick={props.closePhoneError}><CloseValidation /></div>
            </div>
        </div>;
  }
  if (props.isDomainAlreadyInUse) {
    return <div className="validation">
            <AlertTip />
            <div className="validation-box">
                <div className="alert-left-div"><AlertTriangle /></div>
                <div className="alert-middle-div">Looks like this domain is already in use.</div>
                <div className="alert-right-div" onClick={props.closeDomainInUseError}><CloseValidation /></div>
            </div>
        </div>;
  }
  return null;
};

export default DrawerValidation;
