import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import '../../_shared/_shared.scss';
import { Box, Grid } from '@material-ui/core';
import TableAndFormContainer from '../../_shared/TableAndFormContainer/TableAndFormContainer';
import { getClaims } from '../../../../services/api';
import Typography from '../../components/Typography';
import { formatClaimStatus } from '../../../../utils/claims';

function compareClaimsDate(a, b) {
  if (a.created_at < b.created_at) return 1;
  if (b.created_at < a.created_at) return -1;
  return 0;
}

const Claims = (props) => {
  const [searchValue, updateSearchValue] = useState('');
  const [searchKey, updateSearchKey] = useState('');
  const [selected, updateSelected] = useState({});
  const [claims, setClaims] = useState({ isLoading: true, results: [], count: 0 });
  const [isLoading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    searchClaims();
  }, []);

  const searchClaims = async (useSearch = true) => {
    setLoading(true);
    setIsSearching(true);

    if (useSearch && searchKey && searchValue) {
      ReactGA.event({ category: 'Claims - All Claims', action: 'Search Executed', label: searchKey });
    }

    const claimsResp = await getClaims(
      props.userSession.activeBrand.prod_api_secret,
      { search_key: useSearch ? searchKey : '', search_value: useSearch ? searchValue : '' }, // LIMIT and CURSOR ARE omitted here. Currently ALL claims are fetched. This can be paginated in the future
    );
    setClaims({
      ...claimsResp,
      results: claimsResp && claimsResp.results ? claimsResp.results.sort(compareClaimsDate) : [],
    });
    setIsSearching(false);
    setLoading(false);
  };

  const claimSelected = (claim) => {
    ReactGA.event({
      category: 'Claims - All Claims',
      action: 'Row Click / Drawer View',
      label: String((claim && claim.id) || ''),
    });
    updateSelected(claim);
  };

  claims.results.forEach((items) => {
    items.fields.forEach((item, idx) => {
      if (item.id === 360015990133) {
        items.revenues = item.value;
      }
      if (item.id === 360020728733) {
        items.issue_status = formatClaimStatus(item.value);
      }
    });
  });

  return (
    <div>
      <div style={{ marginTop: '-70px' }}>
        <TableAndFormContainer
          headerList={[
            'Issue Date',
            'Issue ID',
            'Route Order Number',
            'Store Order ID',
            'Store Order Number',
            'Issue Status',
            'Amount Paid',
          ]}
          dataProps={[
            'created_at',
            'id',
            ['fields', 360015989293],
            ['fields', 360016141754],
            ['fields', 360050956473],
            ['fields', 360020728733],
            ['fields', 360015990133],
          ]}
          isLoading={isLoading}
          data={(claims && claims.results) || []}
          count={String(claims && claims.count)}
          updateSearchKey={updateSearchKey}
          updateSearchValue={updateSearchValue}
          updateLimit={() => {}}
          search={searchClaims}
          onSelect={claimSelected}
          selected={selected}
          searchPlaceholder="Select Category"
          searchDropdownItems={{
            claim_id: 'Issue ID',
            customer_email: 'Customer Email',
            customer_name: 'Customer Name',
            route_order_number: 'Route Order Number',
            source_order_id: 'Store Order ID',
            source_order_number: 'Store Order Number',
            tracking_number: 'Tracking Number',
          }}
          userSession={props.userSession}
          searchAsDropdown={true}
          origin="claims"
          isSearching={isSearching}
        />
        <Grid container direction="row" justify={'flex-end'} alignItems={'center'}>
          <Box mb={4} pr="80px">
            <Typography variant="h8" component="h4" color="gray">
              *Data could be delayed up to 24 hours.
            </Typography>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

export default Claims;
