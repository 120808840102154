import React from 'react';

const SVG = ({
  style = {},
  fill = '#66C1CB',
  width = '11px',
  className = '',
  height = '11px',
  viewBox = '0 0 11 11',
  transform = 'scale(.81 .81)',
}) => <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path fill={fill} d="M3.64625 7.857L0.14625 4.44C-0.04875 4.252 -0.04875 3.946 0.14625 3.756L0.85325 3.072C1.04825 2.884 1.36525 2.884 1.56025 3.072L4.00025 5.466L9.43925 0.141C9.63425 -0.047 9.95125 -0.047 10.1462 0.141L10.8532 0.825C11.0482 1.013 11.0482 1.32 10.8532 1.508L4.35325 7.859C4.15825 8.046 3.84225 8.046 3.64625 7.857Z" />
  </svg>;

export default SVG;
