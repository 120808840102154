import React from "react";
const SVG = ({
  fill = '#5DC8DB',
  width = '19',
  className = '',
  height = '19',
  viewBox = '0 0 19 19',
  stroke = '#FFFFFF',
}) =>
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.5" cy="9.5" r="9.5" fill={fill} />
    <path d="M8.34258 12.626L6.38086 10.6625L11.0322 6.00697C11.4411 5.59761 12.0997 5.59761 12.5086 6.00697L12.9939 6.49264C13.4029 6.902 13.4029 7.56113 12.9939 7.97048L8.34258 12.626Z" stroke={stroke} strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M6.37946 10.6626L5.72786 12.6122C5.58922 13.0216 5.97741 13.4101 6.38639 13.2714L8.33425 12.6192" stroke="white" strokeMiterlimit="10" />
    <path d="M10.1992 6.84644L12.1609 8.80301" stroke="white" strokeMiterlimit="10" />
  </svg>;

export default SVG;
