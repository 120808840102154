import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  getMerchantIssueRules,
  createMerchantIssue,
} from '../../../../services/issues';
import { getUserSession } from '../../../../stores/SessionManager';
import { permissions } from '../../components/Authentication/merchant_permissions';

import {
  Button,
  HeaderContainer,
  SubsetRulesContainer,
  RulesContentContainer,
  RadioContainer,
  Wrapper,
} from './IssueRules.styles';

import { Container, Title } from './IssueRule/IssueRule.styles';

// eslint-disable-next-line import/extensions
import { IIssueRulesProps } from './interface';
import IssueRule from './IssueRule';
import {
  issueFilingRules,
  autoApprovalRules,
  issueAssessmentRules,
  issueRulesPlaceholder,
} from './IssueRules.constants';

import RadioWithCheck from '../../../../assets/icons/radio-with-check';
import RadioWithoutCheck from '../../../../assets/icons/radio-without-check';
import SaveState from './SaveState';
import { check } from '../../components/Authentication/Can/Can';
import Can from '../../components/Authentication/Can/Can';

export type Props = RouteComponentProps & IIssueRulesProps;

const IssueRules: React.FC<Props> = (props, rulesI) => {
  const [preferredResolution, setPreferredResolution] = useState<string>('replacement');
  const [isEdited, setIsEdited] = useState<Boolean>(false);
  const [userSession, setUserSession] = useState<any>(false);
  const [isReverted, setIsReverted] = useState<boolean>(false);
  const [useSaveState, setUseSaveState] = useState<boolean>(false);

  const [origRulesUsed, setOrigRulesUsed] = useState<typeof rulesI>(
    issueRulesPlaceholder,
  );
  const [rulesUsed, setRulesUsed] = useState<typeof rulesI>(
    issueRulesPlaceholder,
  );
  const [routeRules, setRouteRules] = useState<typeof rulesI>(
    issueRulesPlaceholder,
  );
  const [newRules, setNewRules] = useState<typeof rulesI>(
    issueRulesPlaceholder,
  );

  const onCloseModal = () => setUseSaveState(false);

  const containerRef = useRef<any>(null);
  useEffect(() => {
    const handleClick = (e: any) => {
      if (containerRef.current.contains(e.target)) {
        return;
      }
      if (isEdited) { setUseSaveState(true); }
    };

    document.addEventListener('mousedown', handleClick, false);

    return function cleanupListener() {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  useEffect(() => {
    const userResp = getUserSession();
    if (userResp && userResp.loggedIn) {
      setUserSession(userResp);
    }
  }, []);

  const getAllRulesData = async () => {
    if (userSession) {
      const issueRulesResp = await getMerchantIssueRules(
        userSession.activeBrand.id,
        userSession.activeBrand.prod_api_secret,
      );
      if (issueRulesResp) {
        const useVersion = issueRulesResp.merchantRules
          ? issueRulesResp.merchantRules
          : issueRulesResp.routeRules;
        setRulesUsed(useVersion);
        setNewRules(useVersion);
        setOrigRulesUsed(useVersion);
        setRouteRules(issueRulesResp.routeRules);
        setPreferredResolution(useVersion.preferredResolution);
      }
    }
  };

  useEffect(() => {
    getAllRulesData();
  }, [userSession]);

  useEffect(() => {
    if (isReverted) {
      setIsReverted(false);
    }
  }, [isReverted]);

  const onChangeRule = (info: any) => {
    if (isEdited !== true) {
      setIsEdited(true);
    }

    let formattedVal = info.newValue;
    if (info.newValue.includes('$')) {
      formattedVal = info.newValue.replace('$', '')
    }

    setRulesUsed({ ...rulesUsed, [info.type]: formattedVal });
    setNewRules({ ...newRules, [info.type]: formattedVal });
  };

  const revertRules = () => {
    if (isEdited) {
      setRulesUsed(origRulesUsed);
      setPreferredResolution(origRulesUsed.preferredResolution);
      setIsEdited(false);
      setIsReverted(true);
    }
  };

  const onSave = async () => {
    if (isEdited) {
      await createMerchantIssue(
        { ...newRules, userId: userSession.user.id },
        userSession.activeBrand.id,
        userSession.activeBrand.prod_api_secret,
      );
      window.location.reload();
    }
  };

  const handleSetPreferred = (newValue: string) => {
    if (newValue !== preferredResolution) {
      setIsReverted(true);
      setPreferredResolution(newValue);
      setNewRules(isEdited ? { ...newRules, preferredResolution: newValue } : { ...rulesUsed, preferredResolution: newValue });
      if (!isEdited) {
        setIsEdited(true);
      }
    }
  };

  return (
    <Wrapper ref={containerRef}>
      {/* TODO: block routes while in edit mode -- will probably have to be with props to the routing pages */}
      {/* {useSaveState ? <SaveState onSave={onSave} onCloseModal={onCloseModal} onExit={() => { revertRules(); setUseSaveState(false); }} /> : null} */}
      <HeaderContainer>
        <h1>Issue Rules</h1>
        <div>
          Route will use the rules outlined below to process each issue filed
          against a Route protected order. Please update each rule to ensure
          issues are processed in line with your customer support policies.
        </div>

        <Button disabled={!isEdited} reverseColor onClick={() => revertRules()}>
          Revert
        </Button>
        <Can
          role={userSession.merchantRole}
          perform={permissions.issue_rules.edit}
          yes={() => (
            <Button disabled={!isEdited} onClick={() => onSave()}>
              Save
            </Button>
          )}
        />
      </HeaderContainer>
      <RulesContentContainer>
        <div>
          <SubsetRulesContainer>
            <h1>Issue Filing Rules</h1>
            {issueFilingRules.map((setting, index) => (
              <IssueRule
                key={setting.type}
                description={setting.description}
                title={setting.title}
                value={rulesUsed[setting.type]}
                type={setting.type}
                index={index}
                recommendedValue={routeRules[setting.type]}
                onChangeRule={onChangeRule}
                displayType={setting.displayType}
                isReverted={isReverted}
              />
            ))}
          </SubsetRulesContainer>
          <SubsetRulesContainer>
            <h1>Auto Approval Rules</h1>
            <div>
              {autoApprovalRules.map((setting, index) => (
                <IssueRule
                  key={setting.type}
                  description={setting.description}
                  title={setting.title}
                  value={rulesUsed[setting.type]}
                  type={setting.type}
                  index={index}
                  recommendedValue={routeRules[setting.type]}
                  onChangeRule={onChangeRule}
                  displayType={setting.displayType}
                  isReverted={isReverted}
                />
              ))}
            </div>
          </SubsetRulesContainer>
        </div>
        <div>
          <SubsetRulesContainer>
            <h1>Issue Assessment Rules</h1>
            <div>
              {issueAssessmentRules.map((setting, index) => (
                <IssueRule
                  key={setting.type}
                  description={setting.description}
                  title={setting.title}
                  value={rulesUsed[setting.type]}
                  type={setting.type}
                  index={index}
                  recommendedValue={routeRules[setting.type]}
                  onChangeRule={onChangeRule}
                  displayType={setting.displayType}
                  isReverted={isReverted}
                />
              ))}
            </div>
          </SubsetRulesContainer>
          {/* removed on 10/28 -- may be added back in later */}
          {/* <SubsetRulesContainer>
            <h1>Issue Resolution Rules</h1>
            <Container>
              <Title>Preferred method to resolve issues</Title>
              <RadioContainer onClick={() => handleSetPreferred('replacement')}>
                {preferredResolution === 'replacement' ? (
                  <RadioWithCheck />
                ) : (
                    <RadioWithoutCheck />
                )}
                <span>Replace</span>
              </RadioContainer>
              <RadioContainer onClick={() => handleSetPreferred('refund')}>
                {preferredResolution === 'refund' ? (
                  <RadioWithCheck />
                ) : (
                    <RadioWithoutCheck />
                )}
                <span>Refund</span>
              </RadioContainer>
            </Container>
          </SubsetRulesContainer> */}
        </div>
      </RulesContentContainer>
    </Wrapper>
  );
};

export default IssueRules;
