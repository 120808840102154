import styled, { css } from 'styled-components';
import Colors from '../../../../../../constants/colors';

export const Container = styled.div`
  padding: 24px 72px;
`;

export const Line = styled.hr`
  background: ${Colors.black};
  height: 1px;
  opacity: 0.12;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Descriptive = styled.p`
  color: ${Colors.primaryText};
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  margin-top: 8px;
`;
