import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Container = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  z-index: 99999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  background: linear-gradient(-10deg, #2bffff 0%, #28b8e3 47.33%, #114057 94.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SubTitle = styled.h3`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;

  text-align: center;

  color: #333333;
`;

export const Description = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  text-align: center;

  color: #666666;

  b {
    font-weight: 600;
  }

  span {
    color: #5dc8db;
  }
`;

export const Button = styled.button`
  height: 40px;
  padding: 0px 32px 0px 32px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;

  text-align: center;

  color: #ffffff;

  background: ${colors.primaryButtonBackground};
  border: none;
  border-radius: 40px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const ButtonLinear = styled.button`
  height: 40px;
  padding: 0px 32px 0px 32px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;

  text-align: center;

  color: #ffffff;

  background: linear-gradient(-10deg, #2bffff 0%, #28b8e3 47.33%, #114057 94.66%);
  border: none;
  border-radius: 40px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const Button2 = styled.button`
  height: 30px;
  padding: 0px 16px 0px 16px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;

  color: #5dc8db;

  background: #ffffff;
  border: none;
  border-radius: 16px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const ButtonClose = styled.button`
  height: 30px;
  padding: 0px 16px 0px 16px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;

  color: #ffffff;

  background: #14869f;
  border: none;
  border-radius: 16px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const TitleSuccess = styled.h3`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;

  text-align: center;

  color: #ffffff;
`;

export const SubTitleSuccess = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;

  text-align: center;

  color: #ffffff;
`;

export const CloseButton = styled.img<any>`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;
