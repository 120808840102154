import ruleset from '../permissions';
import { merchantRoles } from '../merchant_permissions';
import { ICheckProps } from './interface.d';

export const check = ({ permissions, rules, services, action, data, role }: ICheckProps): boolean => {
  const internalPermissions = services && services?.service_tier ? permissions[services?.service_tier] : null;
  const internalRules = rules && rules[role] ? rules[role] : merchantRoles[role];

  if (typeof services !== 'object' && (role === 'route_admin' || role === 'merchant_admin')) {
    return true;
  }

  const staticPermissions = internalPermissions?.static || internalRules?.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = internalPermissions?.dynamic || internalRules?.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }

  if (typeof services === 'object' && services !== null) {
    if (services?.service_tier === 'ROUTE_PRO' && staticPermissions.includes(action)) {
      return true;
    }
    return services[action] && services[action].available_and_active === true;
  }

  return false;
};

const Can = (props: any) =>
  check({
    permissions: ruleset,
    rules: merchantRoles,
    services: props.services || props.role,
    role: props.role,
    action: props.perform,
    data: props.data,
  })
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
