import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel as MuiInputLabel } from '@material-ui/core';

import { colors, text } from '../constants/styles';
import { defaultInputBorderRadius } from '../constants/theme';

export type TextAreaProps = {
  label: string;
  value: string;
  className?: string;
  maxWidth?: string | number;
  height?: string | number;
}

const defaultMaxWidth = 240
const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    maxWidth: (props: any) => props.maxWidth || defaultMaxWidth,
  },
  label: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
    color: text.inputLabelColor,
  },
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  textArea: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray,
    borderRadius: defaultInputBorderRadius,
    marginTop: '5px',
    boxSizing: 'border-box',
    minHeight: 30,
    fontSize: '12px',
    padding: '5px 10px',
    wordBreak: "break-all",
    flex: '0 0 93%',
  },
  link: {
    color: colors.blue,
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    fontSize: 14
  }
}, { name: "RouteTextArea" });

export function TextArea(props: TextAreaProps) {
  const { label, value, className, maxWidth, height } = props;
  const classes = useStyles({ maxWidth, height });

  const copy = async () => {
    await navigator.clipboard.writeText(value)
  }

  return (
    <div className={`${classes.root} ${className}`}>
      <MuiInputLabel className={classes.label}>
        <span>{label}</span>
      </MuiInputLabel>
      <div className={classes.content}>
        <div className={classes.textArea}>{value}</div>
        <button className={classes.link} onClick={copy}>copy</button>
      </div>
    </div>
  );
}
