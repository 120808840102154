import { addBreadcrumb, Severity } from '@sentry/browser';
import openApi from './open-api';
import { getHeadersWithToken } from './utils';

const getOptions = (token: string, cancelToken: string) => ({
  cancelToken,
  headers: {
    token,
    'Content-Type': 'application/json',
  },
});

export const getMerchantStats = (merchantId: string, token: string, startDate: Date, endDate: Date, metric: string, cancelToken: string) => {
  return openApi.get(
    `/merchants/${merchantId}/stats?metric=${metric}&end_date=${endDate}&start_date=${startDate}`,
    getOptions(token, cancelToken),
  );
};

export default {
  getMerchantStats,
};
