import React, { useState, useEffect, FC } from 'react';
import '../MobileDevice.scss';
import phoneOutline from '../../../../../assets/simulator/phone-template.svg';
import thinCross from '../../../../../assets/images/branded-order-mobile-view.png';
import {
  StoreName,
  Logo,
  NoLogo,
  MobileOutline,
  CrossSymbol,
  CardImageOnMobileView,
  MobileContainer
} from "./styles";

const MobilePreview: FC<any> = (props) => {
  const [brandedOrderFeaturedImage, setBrandedOrderFeaturedImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeLogo, setStoreLogo] = useState('');

  useEffect(() => {
    if (props.brandedOrderFeaturedImage && props.brandedOrderFeaturedImage !== '') {
      setBrandedOrderFeaturedImage(props.brandedOrderFeaturedImage);
    }
    if (props.storeName && props.storeName !== '') {
      setStoreName(storeName);
    }
    if (props.storeLogo && props.storeLogo !== '') {
      setStoreLogo(props.storeLogo);
    }
  }, [props]);

  return (
    <MobileContainer>
      <div className="mobile-background">
        <MobileOutline src={phoneOutline} alt="mobile View" />
        {brandedOrderFeaturedImage.length > 0 ?
          <CardImageOnMobileView src={brandedOrderFeaturedImage}></CardImageOnMobileView> : ''}
        <StoreName>{storeName || ''}</StoreName>
        { storeLogo.length > 0 ? (
          <Logo src={storeLogo} />
        ) : (
          <NoLogo>Logo</NoLogo>
        )}
        <CrossSymbol src={thinCross} alt="mobile View"/>
      </div>
    </MobileContainer>
  );
};

export default MobilePreview;
