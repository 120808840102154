export const colors = {
  routeBlue: '#31C8E0',
  routeMidnight: '#222222',
  routeDark: '#023646',
  routeDusk: '#51868E',
  gray1: '#666666',
  gray2: '#999999',
  gray3: '#CCCCCC',
  gray4: '#DDDDDD',
  gray5: '#EEEEEE',
  white: '#FFFFFF',
  offWhite: '#F1F1F1',
  blue: '#31C8E0',
  darkBlue: '#51868E',
  green: '#249D0E',
  gray: '#CCCCCC',
  darkGray: '#2C373C',
  lightGray: '#A0A0A2',
  mediumGray: '#666666',
  mediumDarkGray: '#5C676C',
  error: '#F06350',
  teal: '#23D09C',
};

export const text = {
  textBodyColor: colors.darkGray,
  inputLabelColor: colors.lightGray,
  requiredLabelColor: colors.error,
  detailsParagraphColor: colors.mediumGray,
  listItemColor: colors.mediumDarkGray,
};

export const link = {
  linkColor: colors.blue,
  linkHoverColor: colors.darkBlue,
};

export const button = {
  buttonColor: colors.blue,
  disabledButtonColor: colors.gray,
  buttonHoverColor: colors.darkBlue,
  buttonTextColor: colors.white,
  hoverBgColor: colors.teal,
};

export const progressStepper = {
  progressStepperCompleteIconColor: colors.blue,
  progressStepperIncompleteIconColor: colors.gray,
  progressStepperConnectorCompleteColor: colors.blue,
  progressStepperConnectorIncompleteColor: colors.gray,
};

export default {
  ...text,
  ...link,
  ...button,
  ...progressStepper,
};
