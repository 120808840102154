import React, { FC } from "react";
import ImageIcon from "../../../../assets/icons/image";
import CloseIcon from "../../../../assets/icons/close";
import ExclamationMark from "../../../../assets/icons/exclamationMark";
import ErrorStatus from "../../../../assets/icons/error-status";
import {
  Container,
  CloseIconContainer,
  ImageContainer,
  InfoContent,
  InfoContentHeader,
  InfoContentDescription,
  ListItems,
  Item,
  Button
} from "./styles";

type ImageReqProps = {
  close: () => void;
}

const ImageReq: FC<ImageReqProps> = ({ close }) => {
  return (
    <Container>
      <CloseIconContainer onClick={close}>
        <CloseIcon fill="#2c373c" />
      </CloseIconContainer>
      <ImageContainer>
        <ExclamationMark />
        <ImageIcon stroke="#F1373C" />
      </ImageContainer>
      <InfoContent>
        <InfoContentHeader>Image could not load</InfoContentHeader>
        <InfoContentDescription>
          Please check image requirements:
          </InfoContentDescription>
        <ListItems>
          <Item>
            <ErrorStatus /><span>JPG or PNG file types</span>
          </Item>
          <Item>
            <ErrorStatus /><span>20MB size limit</span>
          </Item>
        </ListItems>
      </InfoContent>
      <Button type="button" onClick={close}>
        close
        </Button>
    </Container>
  );
};
export default ImageReq;
