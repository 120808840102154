import { OnboardingState, initialState } from '../store';
import { 
  ShowOnboardingModalAction,
  HideOnboardingModalAction,
  SHOW_ONBOARDING_MODAL,
  HIDE_ONBOARDING_MODAL
} from '../actions/congrats/congrats';

type ActionableActions = ShowOnboardingModalAction | HideOnboardingModalAction;

export function onboardingReducer(state = initialState['onboarding'], action: ActionableActions): OnboardingState {
  switch(action.type) {
    case SHOW_ONBOARDING_MODAL: {
      return {
        ...state,
        showPortalModal: true,
      };
    }
    case HIDE_ONBOARDING_MODAL: {
      return {
        ...state,
        showPortalModal: false,
      };
    }
    default: {
      return state;
    }
  }
}
