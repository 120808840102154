import { Action } from 'redux';
import { initialState } from '../../Onboarding-2/store'
import { BrandState } from './../store'
import {
  SETUP_ACCOUNT_FAILED,
  SetupAccountFailedAction,
  DISMISS_ERROR,
  DismissErrorAction,
  SAVE_SETUP_ACCOUNT_FORM,
  SaveSetupAccountFormAction,
  SAVE_MERCHANT_DATA,
  SaveMerchantDataAction
} from '../actions/setup-account';
import { SET_VERIFY_DOMAIN_METHOD, SetVerifyDomainMethodAction } from '../actions/verify-domain';

type ActionableAction = SetupAccountFailedAction | DismissErrorAction | SaveSetupAccountFormAction |
  SaveMerchantDataAction | SetVerifyDomainMethodAction;

function isActionableAction(action: Action<any>): action is ActionableAction {
  return action.type === SETUP_ACCOUNT_FAILED || action.type === DISMISS_ERROR ||
    action.type === SAVE_SETUP_ACCOUNT_FORM || action.type === SAVE_MERCHANT_DATA ||
    action.type === SET_VERIFY_DOMAIN_METHOD;
}

export const setupAccountReducer = (state: BrandState = initialState['brand'], action: ActionableAction | Action<any>): BrandState => {
  if (!isActionableAction(action)) {
    return state;
  }

  switch (action.type) {
    case SETUP_ACCOUNT_FAILED: return {
      ...state,
      page: {
        ...state.page,
        error: true,
        errorReason: action.errorReason,
      },
    }
    case DISMISS_ERROR: return {
      ...state,
      page: {
        ...state.page,
        error: false,
        errorReason: undefined,
      }
    }
    case SAVE_SETUP_ACCOUNT_FORM: return {
      ...state,
      form: action.data
    }
    case SAVE_MERCHANT_DATA: return {
      ...state,
      merchant: action.merchant
    }
    case SET_VERIFY_DOMAIN_METHOD: return {
      ...state,
      verifyDomain: { ...action.data }
    }
  }
}
