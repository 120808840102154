import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import queryString from 'query-string';
import PageTitle from '../_shared/PageTitle/PageTitle';
import Paragraph from '../_shared/Paragraph/Paragraph';
import PageHeader from '../_shared/PageHeader/PageHeader';
import peopleOnRoute from '../../../assets/images/people-on-route.svg';
import FeaturedImage from '../_shared/FeaturedImage/FeaturedImage';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import './LinkMerchant.scss';
import LoadingIndicator from '../../_shared/LoadingIndicator/LoadingIndicator';
import Form from '../_shared/Form/Form';
import FormInput from '../_shared/Form/FormInput/FormInput';
import FormRow from '../_shared/Form/FormRow/FormRow';
import FormItem from '../_shared/Form/FormItem/FormItem';
import SubmitButton from '../_shared/SubmitButton/SubmitButton';
import SnackBar from '../../_shared/MaterialUi/SnackBar';
import { loginUser } from '../../../services/api';
import {
  linkMerchant,
  updateMerchant,
  updateCollaborator,
} from '../../../services/merchant';

const LinkMerchant = (props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [collaboratorId, setCollaboratorId] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: '',
  });

  useEffect(() => {
    if (login) {
      setLogin(login.toLowerCase());
    }
  }, [login]);

  useEffect(() => {
    const query = queryString.parse(props.location.search);

    if (query.id) {
      setCollaboratorId(query.id);
    } else {
      props.history.push('/onboarding');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser(login, password);

      if (!response) {
		  setLoading(false);
		  alert('Incorrect username or password. For help please contact support@route.com');

        return false;
      }

      if (response) {
        const userSession = response;
        setLoading(true);

        if (userSession.brands) {
          const activeBrand = userSession.brands[0];

          await linkMerchant(
            collaboratorId,
            activeBrand.prod_api_secret,
          );

          setLoading(false);

          sessionStorage.setItem('uToken', userSession.user.token);
          sessionStorage.setItem('uId', userSession.user.id);
          sessionStorage.setItem('bId', userSession.brands[0].id);
          userSession.brands[0].merchantId = userSession.brands[0].id;
          updateMerchant(userSession.brands[0]);
          updateCollaborator(userSession.user);

          props.history.push('/onboarding/route-package-protection');
        }
      }
    } catch (err) {
      if (err && err.data) {
        alert(err.data);
      }
    }
  };

  return (
		<div>
			<OnboardingContainer className="link-merchant">
				<Box mt="10px">
					<PageHeader>
						<PageTitle
							title="It Looks like you might already be in our system..."
							alignment="no-margin left"
						></PageTitle>
					</PageHeader>
				</Box>
				<Box display="flex">
					<Box width={721} mr="60px" mb="70px">
						<Paper className="column">
							<Box mb="10px">
								<Paragraph text="Based on your installation, it looks like you already have a Route user account. This usually happens when you've set up Route on a staging environment."></Paragraph>
								<Paragraph text="Please sign in below with the email and password you used when you set up your staging instance of Route."></Paragraph>
							</Box>

							<Form
								styleName="link-merchant-form"
								method="POST"
								onSubmit={handleSubmit}
							>
								<fieldset>
									<FormRow spacing={0}>
										<FormItem md={7}>
											<label>Email Address</label>
											<FormInput
												type="email"
												name="email"
												id="email"
												placeholder="joe@onlinetoys.com"
												onChange={(e) => setLogin(e.target.value)
												}
												value={login}
												required
											></FormInput>
										</FormItem>
									</FormRow>
									<FormRow spacing={0}>
										<FormItem md={7}>
											<label>Password</label>
											<FormInput
												type="password"
												name="password"
												id="password"
												placeholder="••••••••"
												onChange={(e) => setPassword(e.target.value)
												}
												value={password}
												required
											></FormInput>
										</FormItem>
									</FormRow>
									<SubmitButton
										text="Sign In"
										styleName="light"
									></SubmitButton>
								</fieldset>
							</Form>
						</Paper>
					</Box>
					<Box display="flex" mt={0} alignItems="flex-start">
						<FeaturedImage
							src={peopleOnRoute}
							alt="People on Route"
						></FeaturedImage>
					</Box>
				</Box>
			</OnboardingContainer>
			<LoadingIndicator
				active={loading}
				spinner
				text="Wait a sec... We are linking your merchant account with your existing one"
			/>
			<SnackBar
				ContentProps={{
				  'aria-describedby': 'message-id',
				}}
				message={message.text}
				variant="error"
			/>
		</div>
  );
};

export default LinkMerchant;
