export function prettifyCurrency(amount = 0, currency = 'USD') {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

function isNumeric(val: any) {
  return !isNaN(parseFloat(val)) && 'undefined' !== typeof val ? parseFloat(val) : false;
}

export function percentage(partialValue: any, totalValue: any, precision: number = 0) {
  partialValue = parseFloat( partialValue.replace(/,/g, '') );
  const numeric = Number((100 * partialValue) / totalValue);
  const total = isNumeric(numeric) ? numeric : 0;
  return total.toFixed(precision);
}
