import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import ReactGA from 'react-ga';

import './Table.scss';
import '../../pages/dashboard/Dashboard.scss';
import _ from 'lodash';
import { formatClaimStatus } from '../../../../utils/claims';
import { getOrder } from '../../../../services/api';
import { prettifyCurrency } from '../../../../utils/currency';
import ColumnSort from '../../../../assets/icons/sort-column-icon.js';
import TablePaginationActions from '../TablePaginationActions/TablePaginationActions';
import colors from '../../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    borderRadius: 0,
    width: '100%',
    overflow: 'hidden',
    background: '#FFFFFF',
    position: 'relative',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 350,
  },
  tableHeader: {
    fontFamily: 'Titillium Web, sans-serif',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '10px',
    color: '#999999',
    padding: '15px 0px 15px 0px',
    borderBottom: '1px solid #eeeeee',
  },
  tableBody: {},
  tableCell: {
    minWidth: 100,
    maxWidth: 100,
    fontFamily: 'Titillium Web, sans-serif',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '12px',
    color: '#333333',
    padding: '15px 0px 15px 0px',
    borderBottom: '1px solid #eeeeee',
  },
  tableToolbar: {
    minHeight: '28px',
    paddingTop: '15px',
  },
  tablePagination: {
    fontFamily: 'Titillium Web, sans-serif',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '10px',
    color: '#666666',
  },
  tableSelect: {
    border: '1px solid #CCCCCC',
    boxSizing: 'border-box',
    borderRadius: '8px',
    '&:focus': {
      border: '1px solid #CCCCCC',
      boxSizing: 'border-box',
      borderRadius: '8px',
    },
  },
  tableSelectIcon: {
    color: colors.primaryColor,
  },
}));

export default function DenseTable(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  let dataCount;

  React.useEffect(() => {
    setPage(0);
  }, [props.isSearching]);

  async function handleChangeRowsPerPage(event) {
    // Google Analytics - Track rows per page selected
    if (document.location.pathname.includes('orders')) {
      ReactGA.event({ category: 'Orders', action: '"Rows per page" change', label: `${event.target.value}` });
    } else if (document.location.pathname.includes('admin/billing')) {
      ReactGA.event({ category: 'Admin - Billing', action: '"Rows per page" change', label: `${event.target.value}` });
    } else if (document.location.pathname.includes('claims')) {
      ReactGA.event({
        category: 'Claims - All Claims',
        action: '"Rows per page" change',
        label: `${event.target.value}`,
      });
    }
    props.updateLimit(+event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  async function handleChangePage(event, newPage) {
    // Google Analytics - Track rows per page selected
    if (document.location.pathname.includes('orders')) {
      ReactGA.event({ category: 'Orders', action: 'Next page click' });
    } else if (document.location.pathname.includes('admin/billing')) {
      ReactGA.event({ category: 'Admin - Billing', action: 'Next page click' });
    } else if (document.location.pathname.includes('claims')) {
      ReactGA.event({ category: 'Claims - All Claims', action: 'Next page click' });
    }

    if (
      document.location.pathname.includes('claims') &&
      newPage > page &&
      props.data.length === page * rowsPerPage + rowsPerPage
    ) {
      setPage(newPage);
    } else if (document.location.pathname.includes('orders')) {
      await props.fetchMoreOrders();
      setPage(newPage);
    } else if (document.location.pathname.includes('billing')) {
      await props.fetchMoreOrders();
      setPage(0);
    } else {
      setPage(newPage);
    }
  }

  const handleClick = async (event, item) => {
    setSelected(item.id);

    if (document.location.pathname.includes('orders') || document.location.pathname.includes('billing')) {
      const order_id = item.source_order_id || item.source_order_number;
      const token = props.userSession && props.userSession.activeBrand.prod_api_secret;

      const resp = await getOrder(order_id, token, true);

      if (resp) {
        props.onSelect(resp);
      }
    } else {
      props.onSelect(item);
    }
  };

  const listHeaders = () =>
    props.headerList.map((header, index) => (
      <TableCell id={index} className={classes.tableHeader} key={Math.random()}>
        {header === 'Order Date' ||
        header === 'Customer Name' ||
        header === 'Order Subtotal' ||
        header === 'Protection Cost' ||
        header === 'Issue Date' ||
        header === 'Amount Paid' ||
        header === 'Issue Status' ? (
          <div
            key={index}
            onClick={() => {
              if (props.handleSort) {
                props.handleSort(header, index);
              }
            }}
          >
            {header} &nbsp;&nbsp;
            {props.sortDirection ? (
              props.sortDirection && props.sortDirection[index] ? (
                <ColumnSort transform="rotate(0)" />
              ) : (
                <ColumnSort transform="rotate(180)" />
              )
            ) : null}
          </div>
        ) : (
          <div>{header}</div>
        )}
      </TableCell>
    ));

  const displayZeroState = () =>
    props.isLoading ? (
      Array(props.data.length || 2)
        .fill('')
        .map(() => (
          <TableRow key={Math.random()}>
            {props.headerList.map(() => (
              <TableCell className={classes.tableCell} key={Math.random()} align="left">
                <div className="dash-loading-state">&nbsp;</div>
              </TableCell>
            ))}
          </TableRow>
        ))
    ) : (
      <TableRow key={Math.random()}>
        {props.headerList.map(() => (
          <TableCell className={classes.tableCell} key={Math.random()} align="left">
            <font>-</font>
          </TableCell>
        ))}
      </TableRow>
    );

  const formatDollar = (val) => `$${Number(val).toFixed(2)}`;

  const displayData = () => {
    let dataSet;
    if (!document.location.pathname.includes('billing') && !props.isDateRangeCleared) {
      if (props.headerList.includes('Issue Date')) {
        dataSet = props.data.filter(
          (item) => item.created_at >= props.startDateRange && item.created_at <= props.endDateRange,
        );
      } else {
        dataSet = props.data.filter((item) => {
          if (item.source_created_on) {
            return item.source_created_on >= props.startDateRange && item.source_created_on <= props.endDateRange;
          }
          if (item.created_on) {
            return item.created_on >= props.startDateRange && item.created_on <= props.endDateRange;
          }
        });
      }
    } else {
      dataSet = props.data;
    }
    dataCount = dataSet.length;
    dataSet = _.uniqBy(dataSet, (e) => {
      return e.id;
    });
    return dataSet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
      <TableRow hover selected={item.id === selected} onClick={(event) => handleClick(event, item)} key={Math.random()}>
        {props.dataProps.map((type) => {
          let val;
          if (typeof type === 'string') {
            if (type.includes('paid_to_insure_usd')) {
              val = formatDollar(item[type]);
            } else if (type.includes('subtotal')) {
              if (!isNaN(item[type])) {
                val = prettifyCurrency(item[type], item.currency);
              } else {
                val = item[type];
              }
            } else if (!type.includes('created')) {
              val = item[type];
            } else if (type.includes('source_created_on')) {
              val =
                item[type] || item.created_on
                  ? moment(item[type] || item.created_on)
                      .utc()
                      .format('MM/DD/YYYY')
                  : '';
            } else {
              val = moment(item[type]).format('MM/DD/YYYY');
            }
          } else if (type[0] === 'first_name') {
            val = `${item[type[0]]} ${item[type[1]]}`;
          } else if (type[0] === 'source_order_number') {
            if (item.platform_id === 'shopify') {
              val = item[type[0]] || '';
            } else if (item.platform_id === 'magento' || item.platform_id === 'woocommerce') {
              val = item[type[1]] || '';
            } else {
              val = item[type[0]] || item[type[1]] || '';
            }
          } else if (type[1] === 360020728733) {
            val = item[type[0]].find((field) => field.id === type[1]).value;

            val = formatClaimStatus(val);
          } else {
            val = item[type[0]].find((field) => field.id === type[1]).value;
            if (type[1] === 360015990133 && val) {
              val = formatDollar(val);
            }
          }

          if (item.status === 'cancelled') {
            if (
              (type.includes('subtotal') ||
                type.includes('display_paid_to_insure') ||
                type.includes('paid_to_insure_usd')) &&
              !isNaN(item[type])
            ) {
              val = `(${formatDollar(item[type])})`;
            }
          }

          return (
            <TableCell className={classes.tableCell} key={Math.random()} align="left">
              <font className={item.status === 'cancelled' ? 'cancelled-order-row' : ''}>{val || '-'}</font>
            </TableCell>
          );
        })}
      </TableRow>
    ));
  };

  return (
    <div className={classes.root}>
      <Paper className={`${[classes.paper, 'dense-table']}`} elevation={0}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>{listHeaders()}</TableRow>
          </TableHead>
          <TableBody classes={classes.tableBody}>{props.isLoading ? displayZeroState() : displayData()}</TableBody>
        </Table>
        <TablePagination
          classes={{
            root: classes.tablePagination,
            toolbar: classes.tableToolbar,
            caption: classes.tablePagination,
            select: classes.tableSelect,
            selectIcon: classes.tableSelectIcon,
          }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={document.location.pathname.includes('billing') ? Number(props.count) : Number(dataCount)}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonText="Prev"
          backIconButtonProps={{
            'aria-label': 'Prev',
            className: document.location.pathname.includes('billing') ? 'hidden' : '',
          }}
          nextIconButtonText="Next"
          nextIconButtonProps={{
            'aria-label': 'Next',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={'Show:'}
          labelDisplayedRows={({ from, to, count }) => {
            if (document.location.pathname.includes('orders') || document.location.pathname.includes('billing')) {
              return;
            }
            return `${from}-${to === -1 ? count : to} of ${count}`;
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}
