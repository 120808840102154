import React, { FC, useState, useEffect } from 'react';
import { addBreadcrumb, Severity } from '@sentry/browser';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';
import Subnav from '../../../_shared/Subnav/Subnav';
import '../../../_shared/_shared.scss';
import '../../../Preferences/Preferences.scss';
import CloseIcon from '../../../../../assets/icons/close-rounded';
import Checkmark from '../../../../../assets/icons/checkmark';
import PreferencesDrawer from '../../../Preferences/Drawer/PreferencesDrawer';
import { getBrand } from '../../../../../services/merchant_brand';
import { MerchantBrand } from '../../../../../models/merchant_brand';
import { getMerchantContacts, shouldShowOrderBrandedContent } from '../../../../../services/merchant';
import Colors from '../../../../../constants/colors';
import { userLoggedAsRoutePro, getActiveMerchantServiceTier } from '../../../../../services/merchant_service_tier';
import Can from '../../../components/Authentication/Can/Can';
import {
  BCRoot,
  BCTable,
  BCTableRow,
  BCTableCell,
  BCContactTableCell,
  BCContactTableHeaderCell,
  BCContentTableRowHeader,
  BCTableRightCell,
  BCHeader,
  BCStoreLogo,
  BCEdit,
  BCRedDash,
  BCOrderImage,
  BCStoreHero,
  WidgetHeader,
  WidgetTitle,
} from './BrandedContentStyles';
import IconNew from '../../../components/IconNew';
import RouteProSmIcon from '../../../../../assets/images/route-pro-badge-sm.svg';

export const BrandedContent: FC<any> = (props) => {
  const [drawer, setDrawer] = useState(
    (props.location && props.location.state && props.location.state.drawer) ||
      props.drawer ||
      'update-payment' ||
      'set-up-payment',
  );
  const [open, setOpen] = useState(
    (props.location && props.location.state && props.location.state.isDrawerOpen) ||
      (props.billing && !props.billing.verified) ||
      false,
  );
  const [merchantContacts, setMerchantContacts] = useState<any[]>([]);
  const [companyEmail, setCompanyEmail] = useState<string | undefined>();
  const [companyPhone, setCompanyPhone] = useState<string | undefined>();
  const [companyLink, setCompanyLink] = useState<string | undefined>();
  const [supportEmail, setSupportEmail] = useState<string | undefined>();
  const [supportPhone, setSupportPhone] = useState<string | undefined>();
  const [supportLink, setSupportLink] = useState<string | undefined>();
  const [returnsEmail, setReturnsEmail] = useState<string | undefined>();
  const [returnsPhone, setReturnsPhone] = useState<string | undefined>();
  const [returnsLink, setReturnsLink] = useState<string | undefined>();
  const [merchantBrand, setMerchantBrand] = useState<MerchantBrand>();

  const refreshMerchantContact = async () => {
    try {
      let contacts = [];
      if (props.userSession.activeBrand.prod_api_secret && props.userSession.activeBrand.id) {
        contacts = await getMerchantContacts(
          props.userSession.activeBrand.prod_api_secret,
          props.userSession.activeBrand.id,
        );
      }
      if (contacts) {
        setMerchantContacts(contacts);
      }
    } catch (error) {
      addBreadcrumb({
        message: `"getMerchantContacts" failure: "${error}"`,
        category: 'GET Merchant Contacts',
        level: Severity.Error,
      });
      throw error;
    }
  };

  const refreshMerchantBrand = async () => {
    if (props.userSession && props.userSession.activeBrand) {
      const { id, prod_api_secret: token } = props.userSession.activeBrand;
      const brand = await getBrand(id, token);
      setMerchantBrand(brand);
    }
  };

  useEffect(() => {
    refreshMerchantContact();
    refreshMerchantBrand();
  }, []);

  useEffect(() => {
    setDrawer(props.location && props.location.state && props.location.state.drawer);
    setOpen(props.location && props.location.state && props.location.state.isDrawerOpen);
  }, [props.location]);

  useEffect(() => {
    const contactCompany = merchantContacts && merchantContacts.find((x) => x && x.slug === 'company');
    setCompanyEmail((contactCompany && contactCompany.email) || '');
    setCompanyPhone((contactCompany && contactCompany.phone) || '');
    setCompanyLink((contactCompany && contactCompany.link) || '');

    const contactSupport = merchantContacts && merchantContacts.find((x) => x.slug && x.slug === 'support');
    setSupportEmail((contactSupport && contactSupport.email) || '');
    setSupportPhone((contactSupport && contactSupport.phone) || '');
    setSupportLink((contactSupport && contactSupport.link) || '');

    const contactReturns = merchantContacts && merchantContacts.find((x) => x.slug && x.slug === 'returns');
    setReturnsEmail((contactReturns && contactReturns.email) || '');
    setReturnsPhone((contactReturns && contactReturns.phone) || '');
    setReturnsLink((contactReturns && contactReturns.link) || '');
  }, [merchantContacts]);

  const openUpdateBrandDetails = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Merchant Profile "Edit" click / Drawer view' });
    setDrawer('update-details');
    setOpen(true);
  };

  const openUpdateCompanyContact = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Company Contact "Edit" click / Drawer view' });
    setDrawer('update-company-contact');
    setOpen(true);
  };
  const openUpdateSupportContact = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Support Contact "Edit" click / Drawer view' });
    setDrawer('update-support-contact');
    setOpen(true);
  };
  const openUpdateReturnsContact = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Returns Contact "Edit" click / Drawer view' });
    setDrawer('update-returns-contact');
    setOpen(true);
  };

  const openBrandedOrderCard = () => {
    setDrawer('branded-order-card');
    setOpen(true);
  };

  const subnavRoutes = [
    { pathname: 'branded-content', pageName: 'Branded Content' },
    { pathname: 'shipping-notification', pageName: 'Shipping Notifications' },
  ];

  return (
    <div className="admin-preferences-main-container">
      <Subnav {...props} header="Engage" pages={subnavRoutes} />
      <Grid container spacing={2} style={{ marginLeft: '60px' }}>
        <Grid item xs={6}>
          <BCRoot>
            <BCHeader>
              <span>In-App Merchant Profile</span>
              <BCEdit onClick={openUpdateBrandDetails}>Edit</BCEdit>
            </BCHeader>
            <BCTable>
              <BCTableRow>
                <BCTableCell>Store Name:</BCTableCell>
                <BCTableRightCell id="store_name">
                  {props.userSession.activeBrand.store_name || <CloseIcon width="10" fill="#DC5960" />}
                </BCTableRightCell>
              </BCTableRow>
              <BCTableRow>
                <BCTableCell>Website:</BCTableCell>
                <BCTableRightCell id="store_domain">
                  {props.userSession.activeBrand.store_domain || <CloseIcon width="10" fill="#DC5960" />}
                </BCTableRightCell>
              </BCTableRow>
              <BCTableRow>
                <BCTableCell>Bio:</BCTableCell>
                <BCTableRightCell id="merchant-bio">
                  {merchantBrand && merchantBrand.bio && merchantBrand.bio.length > 0 ? (
                    <Checkmark width="10" fill={Colors.successColor} />
                  ) : (
                    <BCRedDash>--</BCRedDash>
                  )}
                </BCTableRightCell>
              </BCTableRow>
              <BCTableRow>
                <BCTableCell>Logo:</BCTableCell>
                <BCTableRightCell>
                  {props.userSession.activeBrand.store_logo ? (
                    <div>
                      <BCStoreLogo id="store-logo" src={props.userSession.activeBrand.store_logo} />
                    </div>
                  ) : (
                    <BCRedDash>--</BCRedDash>
                  )}
                </BCTableRightCell>
              </BCTableRow>
              <BCTableRow>
                <BCTableCell>Cover Image:</BCTableCell>
                <BCTableRightCell>
                  {props.userSession.activeBrand.store_hero ? (
                    <div>
                      <BCStoreHero id="store-hero" src={props.userSession.activeBrand.store_hero} />
                    </div>
                  ) : (
                    <BCRedDash>--</BCRedDash>
                  )}
                </BCTableRightCell>
              </BCTableRow>
            </BCTable>
          </BCRoot>
          <BCRoot>
            <WidgetHeader>
              <WidgetTitle>
                <div style={{ marginRight: '5px' }}>
                  <span>Promo Content</span>
                </div>
                <img src={RouteProSmIcon} alt="route pro" />
              </WidgetTitle>
              <BCEdit onClick={openBrandedOrderCard}>Edit</BCEdit>
            </WidgetHeader>
            <BCTable>
              <BCTableRow>
                <BCTableCell>Image:</BCTableCell>
                <BCTableRightCell>
                  {props.userSession.activeBrand.branded_order_featured_image ? (
                    <div>
                      <BCOrderImage src={props.userSession.activeBrand.branded_order_featured_image} />
                    </div>
                  ) : (
                    <BCRedDash>--</BCRedDash>
                  )}
                </BCTableRightCell>
              </BCTableRow>
              <BCTableRow>
                <BCTableCell>Image Link:</BCTableCell>
                <BCTableRightCell>
                  {props.userSession.activeBrand.branded_order_image_link || <BCRedDash>--</BCRedDash>}
                </BCTableRightCell>
              </BCTableRow>
            </BCTable>
          </BCRoot>
          {/* )}
          /> */}
        </Grid>
        <Grid item xs={6}>
          <BCRoot>
            <BCHeader>Contact Info: Customer-Facing</BCHeader>
            <BCTable>
              <BCContentTableRowHeader>
                <BCContactTableHeaderCell>Company Contact</BCContactTableHeaderCell>
                <BCTableRightCell>
                  <BCEdit onClick={openUpdateCompanyContact}>Edit</BCEdit>
                </BCTableRightCell>
              </BCContentTableRowHeader>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Email</span>
                </BCContactTableCell>
                <BCTableRightCell>{companyEmail || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Phone</span>
                </BCContactTableCell>
                <BCTableRightCell>{companyPhone || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Link</span>
                </BCContactTableCell>
                <BCTableRightCell>{companyLink || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <BCContentTableRowHeader>
                <BCContactTableHeaderCell>Support Contact</BCContactTableHeaderCell>
                <BCTableRightCell>
                  <BCEdit onClick={openUpdateSupportContact}>Edit</BCEdit>
                </BCTableRightCell>
              </BCContentTableRowHeader>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Email</span>
                </BCContactTableCell>
                <BCTableRightCell>{supportEmail || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Phone</span>{' '}
                </BCContactTableCell>
                <BCTableRightCell>{supportPhone || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Link</span>
                </BCContactTableCell>
                <BCTableRightCell>{supportLink || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <BCContentTableRowHeader>
                <BCContactTableHeaderCell>Returns Contact</BCContactTableHeaderCell>
                <BCTableRightCell>
                  <BCEdit onClick={openUpdateReturnsContact}>Edit</BCEdit>
                </BCTableRightCell>
              </BCContentTableRowHeader>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Email</span>
                </BCContactTableCell>
                <BCTableRightCell>{returnsEmail || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Phone</span>
                </BCContactTableCell>
                <BCTableRightCell>{returnsPhone || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
              <div className="div-table-row">
                <BCContactTableCell>
                  <span>Link</span>
                </BCContactTableCell>
                <BCTableRightCell>{returnsLink || <BCRedDash>--</BCRedDash>}</BCTableRightCell>
              </div>
            </BCTable>
          </BCRoot>
        </Grid>
      </Grid>
      <PreferencesDrawer
        {...props}
        isOpen={open}
        setOpen={setOpen}
        drawerName={drawer}
        setDrawerName={setDrawer}
        refreshMerchantContact={refreshMerchantContact}
        companyEmail={companyEmail}
        companyPhone={companyPhone}
        companyLink={companyLink}
        supportEmail={supportEmail}
        supportPhone={supportPhone}
        supportLink={supportLink}
        returnsEmail={returnsEmail}
        returnsPhone={returnsPhone}
        returnsLink={returnsLink}
        getBillingDetails={props.getBillingDetails}
        user={props.user}
      />
    </div>
  );
};
