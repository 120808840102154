import { Action } from 'redux';

import {
  USER_DATA_LOADED,
  USER_LOGIN_SUCCEEDED,
  UserDataLoadedAction,
  UserLoginSucceededAction,
} from '../actions/sign-in';
import {AppState, initialState} from '../store';
import {OnboardingStatusUpdateAction, ONBOARDING_STATUS_UPDATE} from '../actions/onboarding/status';
import {UpdateActiveMerchantSucceededAction, UPDATE_ACTIVE_MERCHANT_SUCCEEDED} from '../actions/user-data/merchants';
import { captureException } from '@sentry/browser';

type ActionableAction =
  UserDataLoadedAction |
  UserLoginSucceededAction |
  OnboardingStatusUpdateAction |
  UpdateActiveMerchantSucceededAction;

function isActionableAction(action: Action|ActionableAction): action is ActionableAction {
  return action.type === USER_DATA_LOADED ||
         action.type === USER_LOGIN_SUCCEEDED ||
         action.type === ONBOARDING_STATUS_UPDATE ||
         action.type === UPDATE_ACTIVE_MERCHANT_SUCCEEDED;
}

export function userDataReducer(state: AppState['userData'] = initialState.userData, action: Action<any>|ActionableAction): AppState['userData'] {
  if (!isActionableAction(action)) {
    return state;
  }

  switch (action.type) {
    case USER_DATA_LOADED: {
      return {
        ...state,
        ...action.data,
      };
    }
    case USER_LOGIN_SUCCEEDED: {
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        brands: action.brands,
        activeBrand: action.activeBrand,
        tier: action.tier,
        merchantRole: action.merchantRole,
      };
    }
    case ONBOARDING_STATUS_UPDATE: {
      if (!state.loggedIn) {
        return state;
      }

      const newBrands = state.brands.map((merchant) => {
        if (merchant.id !== action.data.merchantId) {
          return merchant;
        }

        const newMerchant = {
          ...merchant,
        };
        newMerchant.onboarding_status = action.data.status;
        return newMerchant;
      });

      const activeBrand = newBrands.find((merchant) => merchant.id === state.activeBrand!.id);
      if (!activeBrand) {
        captureException(new Error('Invariant violated: active merchant not found in list of merchants'));
        return state;
      }

      return {
        ...state,
        brands: newBrands,
        activeBrand,
      };
    }
    case UPDATE_ACTIVE_MERCHANT_SUCCEEDED: {
      if (!state.loggedIn) {
        return state;
      }

      const newBrands = state.brands.map((merchant) => {
        if (merchant.id !== action.data.id) {
          return merchant;
        }

        return action.data;
      });
      const activeBrand = newBrands.find((merchant) => merchant.id === state.activeBrand!.id);
      if (!activeBrand) {
        captureException(new Error('Invariant violated: active merchant not found in list of merchants'));
        return state;
      }

      return {
        ...state,
        brands: newBrands,
        activeBrand,
      };
    }
    default: {
      // @ts-ignore
      throw new Error(`Unexpected action: ${action.type}`);
    }
  }

}
