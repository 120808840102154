import React, { useState } from 'react';
import './UpdatePaymentMethod.scss';
import TitleAndNav from './TitleAndNav';
import Button from '../../../Button/Button';
import { updateMerchantContact } from '../../../../services/merchant';
import DrawerValidation, {validateEmail, validatePhone} from "./DrawerValidation";
import ReactGA from 'react-ga';

const UpdateCSupportContact = (props) => {
  const { id: merchantId, prod_api_secret: token } = props.userSession.activeBrand;
  const [supportEmail, setSupportEmail] = useState(props.supportEmail);
  const [supportPhone, setSupportPhone] = useState(props.supportPhone);
  const [supportLink, setSupportLink] = useState(props.supportLink);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [isPhoneInvalid, setPhoneInvalid] = useState(false);

  const handleSubmitButton = async (e) => {
    if (validateEmail(supportEmail) && validatePhone(supportPhone)) {

      // Google analytics, track merchant support contact change - phone and email are PII and cannot be captured
      ReactGA.event({ category: 'Admin - Preferences', action: 'Support Contact change', label: `emailIsSet:${!!supportEmail} phoneIsSet:${!!supportPhone} link:${supportLink}` });

      await updateMerchantContact({
        email: supportEmail,
        phone: supportPhone,
        link: supportLink,
        slug: 'support',
        merchantId,
        token
      });
      props.closeDrawer();
    } else {
      validateSupportEmail();
      validateSupportPhone();
    }
    props.refreshMerchantContact();
  };

  const closeEmailError = () => {
    setEmailInvalid(false);
  };

  const closePhoneError = () => {
    setPhoneInvalid(false);
  };

  const validateSupportEmail = () => {
    setEmailInvalid(!validateEmail(supportEmail))
  };

  const validateSupportPhone = () => {
    setPhoneInvalid(!validatePhone(supportPhone))
  };

  const handleEmailChange =(e) => {
    setSupportEmail(e.target.value);
    closeEmailError();
  };

  const handlePhoneChange =(e) => {
    setSupportPhone(e.target.value);
    closePhoneError();
  };

  return (
    <div>
      <TitleAndNav close={props.closeDrawer} title='Support Contact' />
      <div className='update-payment-method-upper-text'>
        <p>Provide contact information that your customers can use for support-related questions or
          concerns. This will be listed on your merchant profile in the Route mobile app.</p><br />
        <p className='bold-paragraph'>Any fields below that are left blank will not appear on your
          profile in the Route mobile app.</p><br />
      </div>
      <div className='update-payment-method-lower-text'>
        <div className='update-billing-contact-input-header'>
          Email
        </div>
        <input className='input-ss' value={supportEmail} name="email"
           onChange={handleEmailChange}
           onBlur={validateSupportEmail}
        />
        {isEmailInvalid ? <DrawerValidation isEmail = {isEmailInvalid} closeEmailError={closeEmailError} /> : ''}
        <div className='update-billing-contact-input-header'>
          Phone
        </div>
        <input className='input-ss' value={supportPhone} name="phone"
          onChange={handlePhoneChange}
          onBlur={validateSupportPhone}
        />
        {isPhoneInvalid ? <DrawerValidation isPhone = {isPhoneInvalid} closePhoneError={closePhoneError} /> : ''}
        <div className='update-billing-contact-input-header'>
          Link
        </div>
        <input className='input-ss' value={supportLink} name="link"
          onChange={(e) => setSupportLink(e.target.value)}
        />
      </div>
      <Button className='update-company-details-save-button' type='submit'
        text="Save"
        onClick={handleSubmitButton}
      />
    </div>
  );
};

export default UpdateCSupportContact;
