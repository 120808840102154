import React from 'react';

const SVG = ({
                 style = {},
                 fill = '',
                 width = '',
                 className = '',
                 height = '',
                 viewBox = '',
                 transform = '',
             }) => <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.9002 0.618506L9.39923 0.103126C9.33247 0.0343032 9.25558 0 9.16864 0C9.08192 0 9.00506 0.0343032 8.9383 0.103126L5.00011 4.15463L1.0621 0.103235C0.995313 0.0344116 0.918451 0.000108267 0.831621 0.000108267C0.744756 0.000108267 0.667894 0.0344116 0.60114 0.103235L0.100237 0.61865C0.033342 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334473 1.02415 0.100237 1.09283L4.76963 5.89695C4.83639 5.96566 4.91328 6 5.00011 6C5.08694 6 5.1637 5.96566 5.23042 5.89695L9.9002 1.09283C9.96696 1.02411 10.0001 0.945037 10.0001 0.855776C10.0001 0.766407 9.96696 0.687329 9.9002 0.618506Z" fill="#666666"/>
</svg>;
export default SVG;
