import React, {FC, useRef} from 'react';
import {Grid, Link, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Form, FormRenderProps} from 'react-final-form';

import {HeaderContainer, ContainerContent, Button, RouteFormTextInput, ErrorPopover} from '../../../components';
import {createRequiredValidator} from '../../../validation/required';
import styleMap from '../../../constants/styles';
import {RightArrow} from '../../../media/right-arrow';

export type OwnProps = {};

export type StateProps = {
  showInvalidEmail: boolean;
};

export type DispatchProps = {
  onSubmit: (data: Partial<FormData>) => void;
  onBack: () => void;
  onHideInvalidEmail: () => void;
  onForgotEmail: () => void;
}

export type FormData = {
  email: string;
};

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  },
  backLink: {
    marginLeft: '4px',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: styleMap.linkColor,
    '&:hover': {
      color: styleMap.linkHoverColor,
    }
  },
  arrow: {
    transform: 'scaleX(-1)',
  },
  backLinkContainer: {
    alignSelf: 'flex-start',
    marginLeft: '16px',
    marginTop: '16px',
  },
  title: {
    textAlign: 'left',
    marginTop: '45px',
  },
  subtitle: {
    textAlign: 'left',
    marginTop: '15px',
  },
  formHeader: {
    textAlign: 'left',
    textTransform: 'uppercase',
    marginTop: '45px',
  },
  spacer: {
    marginBottom: '55px',
  }
}), {name: 'RequestPasswordPage'});



export const PasswordResetRequest: FC<Props> = (props) => {
  const classes = useStyles();
  const onBack = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onBack();
  };

  return (
    <HeaderContainer>
      <ContainerContent width={390}>
        <div className={classes.backLinkContainer}>
          <RightArrow className={classes.arrow}/>
          <Link href="" onClick={onBack} className={classes.backLink}>Back To Sign In</Link>
        </div>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>Password<br></br>Reset</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>Please provide the email address associated with your user account.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.formHeader}>Enter Email Address</Typography>
            </Grid>
            <Grid item xs={12}>
              <Form onSubmit={props.onSubmit}>
                {(formProps: FormRenderProps<FormData>) => {
                  return <InternalForm {...props} {...formProps}/>;
                }}
              </Form>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.spacer}/>
            </Grid>
          </Grid>
        </div>
      </ContainerContent>
    </HeaderContainer>
  );
};

const emailRequiredValidator = createRequiredValidator('This field is required. Please enter your email.');

const useFormStyles = makeStyles({
  email: {
    marginTop: '25px',
  },
  button: {
    marginTop: '45px',
  },
  errorText: {
    color: 'white',
  },
}, {name: 'PasswordResetPage-internal'});

const InternalForm: FC<Props & FormRenderProps<FormData>> = (props) => {
  const classes = useFormStyles();
  const emailGridRef = useRef(null);
  const {invalid: formInvalid, showInvalidEmail, onHideInvalidEmail} = props;
  const onForgotEmail = (event: React.MouseEvent) => {
    event.preventDefault();
    onHideInvalidEmail();
    props.onForgotEmail();
  };
  const invalidEmailError = (
    <span>This does not appear to be a valid email. Please try again. <Link href="" onClick={onForgotEmail} className={classes.errorText}>Forgot your email? CLICK HERE.</Link></span>
  );

  return (
    <Grid container>
      <form onSubmit={props.handleSubmit}>
        <Grid item xs={12} className={classes.email} ref={emailGridRef}>
          <RouteFormTextInput
            overrideError={showInvalidEmail}
            id="email"
            name="email"
            label="Email Address"
            fieldProps={{
              name: 'email',
              validate: emailRequiredValidator,
            }}
          />
        </Grid>
        <ErrorPopover
          open={showInvalidEmail}
          onClose={onHideInvalidEmail}
          anchorEl={emailGridRef.current}
          content={invalidEmailError}
        />
        <Grid item xs={12} className={classes.button}>
          <Button width={240} type="submit" disabled={formInvalid}>
            {'Submit'}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
