import React from 'react';
import IconNew from '../IconNew';
import { Container, Label } from './CardLabel.styles';

import { ICardLabelProps } from './interface';

const CardLabel: React.FC<ICardLabelProps> = ({ children, isNew }: ICardLabelProps) => (
  <>
    <Container>
      <Label>
        {children}
      </Label>
      {isNew && (
        <IconNew>
          NEW
        </IconNew>
      )}
    </Container>
  </>
);

export default CardLabel;
