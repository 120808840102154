import React from "react";
const SVG = ({
  style = {},
  fill = '#7e7e7e',
  width = '16',
  className = '',
  height = '16',
  viewBox = '0 0 16 16',
  transform = '',
  stroke = "#FFFFFF"
}) =>
<svg width={width} height={height} viewBox={viewBox} stroke={stroke} fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill={fill}/>
<path d="M10.5 5.5L5.5 10.5" stroke={stroke} strokeLinecap="round"/>
<path d="M10.5 10.5L5.5 5.5" stroke={stroke} strokeLinecap="round"/>
</svg>;

export default SVG;

