import React, { Component } from 'react';
import './Header.scss';
import RouteLogo from '../../assets/images/route-logo.svg';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <img
          src={RouteLogo}
          alt="route logo"
          className="l-route-logo route-logo"
        />
      </div>
    );
  }
}

export default Header;
