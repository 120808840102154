import React from 'react';
import colors from '../../constants/colors';

const SVG = ({
  style = {},
  fill = colors.primaryColor,
  width = '16px',
  className = '',
  height = '11px',
  viewBox = '0 0 20 20',
  transform = '',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M11.829 9.61499C10.789 10.479 9.455 11 8 11C6.545 11 5.211 10.479 4.171 9.61499C1.687 10.463 0 12.106 0 14V15C0 15.553 0.448 16 1 16H15C15.552 16 16 15.553 16 15V14C16 12.106 14.313 10.463 11.829 9.61499Z"
      fill={fill}
    />
    <path
      d="M8 9C10.209 9 12 7.209 12 5V4C12 1.791 10.209 0 8 0C5.791 0 4 1.791 4 4V5C4 7.209 5.791 9 8 9Z"
      fill={fill}
    />
  </svg>
);

export default SVG;
