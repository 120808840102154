import { Dispatch } from 'react';
import { connect } from 'react-redux';

import { State } from '../../../Onboarding-2/store';
import { SetupAccountForm, StateProps, DispatchProps } from './setup-account-form';
import { createAccount } from '../../actions/setup-account';
import { FormData } from '../../store'

export const mapStateToProps = (state: State): StateProps => {
  const bState = state.app.brand;
  const lockEmail = !bState.merchant || bState.merchant.platform_id !== 'shopify';

  return {
    // We could just use the existence/absence of the email, but for now we want to ensure
    // that a locked field is unique to non-shopify merchants
    initialLockedEmail: lockEmail && bState.email ? bState.email : undefined
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (values: FormData) => {
      dispatch(createAccount(values));
      return;
    },
  };
}

export const ConnectedSetupAccount = connect(mapStateToProps, mapDispatchToProps)(SetupAccountForm);
