import styled, { css } from 'styled-components';
import Colors from '../../../../constants/colors';

const colorset = new Map();
colorset.set('primary', `color: ${Colors.primaryColor};`);
colorset.set('darkGray', `color: ${Colors.darkGray};`);
colorset.set('black', `color: ${Colors.black};`);
colorset.set('white', `color: ${Colors.white};`);
colorset.set('gray', `color: ${Colors.gray};`);
colorset.set('gray3', `color: ${Colors.gray};`);

export const Component = styled.span<any>`
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  color: ${Colors.primaryText};

  ${(props: any) => props.color !== '' && css`
    ${colorset.get(props.color)};
  `}


  ${(props: any) => props.variant === 'h1' && css`
    font-size: 32px;
  `}

  ${(props: any) => props.variant === 'h2' && css`
    font-size: 28px;
  `}

  ${(props: any) => props.variant === 'h4' && css`
    font-size: 20px;
    line-height: 25px;
  `}

  ${(props: any) => props.variant === 'h3' && css`
    font-size: 24px;
  `}

  ${(props: any) => props.variant === 'h5' && css`
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
  `}

  ${(props: any) => props.variant === 'h6' && css`
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
  `}

  ${(props: any) => props.variant === 'h7' && css`
    font-style: normal;
    font-size: 12px;
    line-height: 17px;
  `}

  ${(props: any) => props.variant === 'h8' && css`
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  `}

  ${(props: any) => (props.variant === 'p' || props.variant === 'span') && css`
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  `}

  ${(props: any) => (props.align === 'right') && css`
    text-align: right;
  `}

  ${(props: any) => (props.uppercase === true) && css`
    text-transform: uppercase;
  `}

  ${(props: any) => (props.semibold === true) && css`
    font-weight: 600;
  `}
`;

export default {
  Component,
};
