import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const ViewAll = styled.a`
  color: ${colors.primaryColor};
  cursor: pointer;
  display: inline-block;
  font-family: 'Titillium Web', sans-serif;
  margin-left: 31px;
  margin-top: 14px;
`;
