import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-top: 5px;
  font-weight: bold;
  transition: 0.25s;

  &:hover {
    background: #333333;
    path {
      stroke: #fff;
    }
  }
`;

export const Icon = styled.svg<any>`
  width: ${(props) => props.width || '10px'};
  height: ${(props) => props.height || '10px'};
  fill: ${(props) => props.fill || 'none'};
  stroke: ${(props) => props.stroke || '#fff'};
  stroke-linecap: ${(props) => props.strokeLineCap || 'round'};
  stroke-linejoin: ${(props) => props.strokeLineJoin || 'round'};
  stroke-width: ${(props) => props.strokeWidth || 'default'};
`;

export default {
  Container,
  Icon,
};
