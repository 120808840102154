import { addBreadcrumb, Severity } from '@sentry/browser';
import { MerchantBrand } from '../models/merchant_brand';
import openApi from './open-api';
import { getHeadersWithToken } from './utils';
import dictionary from '../constants/dictionary';

export const getNotificationsSettings = async (notificationType: string, merchantId: string, token: string) => {
  try {
    const { data } = await openApi.get(`/merchants/${merchantId}/notifications-settings`, getHeadersWithToken(token));
    return data;
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_RETRIEVE_NOTIFICATION_SETTINGS,
      category: 'notification_settings',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};
export const updateNotificationsSettings = async (notificationType: string, notificationPayload: {}, merchantId: string, token: string) => {
  try {
    const body = notificationPayload;
    const { data } = await openApi.post(`/merchants/${merchantId}/notifications-settings/${notificationType}`, body, getHeadersWithToken(token));
    return data[0];
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_SAVING_NOTIFICATION_SETTINGS,
      category: 'notification_settings',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export default {
  getNotificationsSettings,
  updateNotificationsSettings,
};
