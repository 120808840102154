import React, { useState, useEffect } from 'react';
import {
  Container, Title, DescText, SubText,
} from './IssueRule.styles';

// eslint-disable-next-line import/extensions
import { IIssueRuleProps } from './interface';

const IssueRule: React.FC<IIssueRuleProps> = ({
  description,
  title,
  value,
  recommendedValue,
  onChangeRule,
  type,
  displayType,
  isReverted,
}: IIssueRuleProps) => {
  const [valueDisplayed, setValueDisplayed] = useState<string>('');
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const formatValue = (formattedValue: any): string => {
    if (displayType === 'dollars') {
      const withCents = Number.parseFloat(formattedValue).toFixed(2);
      return `${String(withCents).includes('$') ? '' : '$'}${withCents || '0'}`;
    }
    return formattedValue || '0';
  };

  useEffect(() => {
    if (!isEdited) {
      setValueDisplayed(formatValue(value));
    }
  }, [value]);

  useEffect(() => {
    if (isReverted) {
      setValueDisplayed(formatValue(value));
    }
  }, [isReverted]);

  const handleChange = (newValue: string) => {
    if (value !== newValue && !isEdited) {
      setIsEdited(true);
    }
    setValueDisplayed(newValue);
    onChangeRule({ newValue, type });
  };

  const handleOnBlur = () => {
    if (displayType === 'dollars') {
      valueDisplayed.replace('$', '');
    }
    setValueDisplayed(formatValue(value));
    onChangeRule({ newValue: valueDisplayed, type });
  };

  const formatRecommendedValue = (): string => (displayType === 'dollars'
    ? `$${recommendedValue}.00`
    : `${recommendedValue} Days`);

  return (
    <Container>
      <Title>{title}</Title>
      <DescText>{description}</DescText>
      <input
        onChange={(e) => handleChange(e.target.value)}
        value={valueDisplayed}
        onBlur={() => handleOnBlur()}
      />
      <SubText>Recommend: {formatRecommendedValue()}</SubText>
      <br />
    </Container>
  );
};

export default IssueRule;
