import React from 'react';
import propTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import './FormRow.scss';

const FormRow = ({
  xs, md, spacing, children,
}) => (
    <Grid container item xs={xs} md={md} spacing={spacing} className='form-row'>
      {children}
    </Grid>
);

FormRow.defaultProps = {
  xs: 12,
  md: 12,
  spacing: 1,
  children: propTypes.object.isRequired,
};

FormRow.propTypes = {
  xs: propTypes.number,
  md: propTypes.number,
  spacing: propTypes.number,
  children: propTypes.element.isRequired,
};

export default FormRow;
