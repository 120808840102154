import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { addBreadcrumb, Severity } from '@sentry/browser';
import Card from '../Card';

import { createCsmInteraction } from '../../../../services/merchant_interaction';

import {
  Container,
  Title,
  SubTitle,
  Description,
  Button,
  ButtonLinear,
  TitleSuccess,
  SubTitleSuccess,
  Button2,
  ButtonClose,
  CloseButton,
} from './GetRouteProWidget.styles';

import CloseIconHover from '../../../../assets/images/close-icon-hover.svg';
import RouteProMdIcon from '../../../../assets/images/route-pro-badge-md.svg';
import RouteProLgIcon from '../../../../assets/images/route-pro-badge-lg.svg';
import WidgetBgDark from '../../../../assets/images/route-pro-widget-bg-lg-dark.png';

import { IProps } from './interface';
import dictionary from '../../../../constants/dictionary';

const GetRouteProWidget: React.FC<IProps> = ({
  userSession,
  children,
  title,
  subtitle,
  description,
  titleSuccess,
  messageSuccess,
  tryProButton,
  tryOutButton,
  closeButton,
  onTryOutPress,
  onClosePress,
}: IProps) => {
  const [state, setState] = useState({ page: 1 });

  const onGetRouteProPress = async () => {
    if (userSession) {
      try {
        const { user, activeBrand: merchant } = userSession;
        const payload = {
          interaction_type: 'get_route_pro_trial',
        };
        await createCsmInteraction(payload, merchant.id, user.token);
        setState({ page: 2 });
      } catch (err) {
        addBreadcrumb({
          message: dictionary.MESSAGE_ERROR_DEATTACH_USER_AND_MERCHANT_TRIAL_REQUEST,
          category: 'Get Route Pro 30 days trial',
          level: Severity.Error,
          data: {
            error: err,
          },
        });
      }
    }
  };

  return (
    <>
      <Container>
        {state.page === 1 && (
          <Card
            style={{
              backgroundColor: '#eee',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 450,
              padding: 60,
            }}
          >
            <Grid container direction="column" alignItems="center" justify="center">
              <Grid item lg={10}>
                <Box mb={3}>
                  <Title>{title}</Title>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={8}>
                  <img src={RouteProMdIcon} alt="route pro" />
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={3}>
                  <SubTitle>{subtitle}</SubTitle>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={6}>
                  <Description dangerouslySetInnerHTML={{ __html: description }} />
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={2}>
                  <Button onClick={onTryOutPress}>{tryOutButton}</Button>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={2}>
                  <ButtonLinear onClick={onGetRouteProPress}>{tryProButton}</ButtonLinear>
                </Box>
              </Grid>
            </Grid>
            <CloseButton
              srcSet={CloseIconHover}
              onClick={() => {
                onClosePress();
              }}
            />
          </Card>
        )}

        {state.page === 2 && (
          <Card
            image={WidgetBgDark}
            style={{
              backgroundColor: '#eee',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 450,
              padding: 60,
            }}
          >
            <Grid container direction="column" alignItems="center" justify="center">
              <Grid item lg={10}>
                <Box mb={8} mt={4}>
                  <img src={RouteProLgIcon} alt="route pro" />
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={4}>
                  <TitleSuccess>{titleSuccess}</TitleSuccess>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={8}>
                  <SubTitleSuccess>{messageSuccess}</SubTitleSuccess>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={2}>
                  <Button2 onClick={onTryOutPress}>{tryOutButton}</Button2>
                </Box>
              </Grid>
              <Grid item lg={10}>
                <Box mb={2}>
                  <ButtonClose onClick={onClosePress}>{closeButton}</ButtonClose>
                </Box>
              </Grid>
            </Grid>
            <CloseButton
              srcSet={CloseIconHover}
              onClick={() => {
                onClosePress();
              }}
            />
          </Card>
        )}
      </Container>
    </>
  );
};

export default GetRouteProWidget;
