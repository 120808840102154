import styled from 'styled-components';
import { Breakpoints } from '../../utils/responsive-breakpoints';

const copyIcon = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/copy.svg';

export const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: black;
  font-size: 17px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 30px);
`;

export const Copy = styled.div`
  background-image: url(${copyIcon});
  color: #333;
  cursor: pointer;
  margin-left: 12px;
  height: 18px;
  width: 18px;
`;

export const CopiedLabel = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-size: 17px;
  line-height: 24px;
  margin-left: 12px;
  color: #999;

  @media (max-width: ${Breakpoints.xsMax}px) {
    display: none;
  }
`;
