import React, {FC, useEffect, Dispatch} from 'react';
import {connect} from 'react-redux';

import {State} from '../../store';
import {redirectAfterInitialLoad, preloadData} from '../../actions/redirect/redirect';
import {loadStateFromBrowser} from '../../actions/sign-in/util';
import {BrowserUserSessionData} from '../../../../stores/SessionManager';

export type DispatchProps = {
  onInitialLoad: (setUserSessionOutsideStateStoreFunction: OwnProps['setUserSessionOutsideStateStore']) => void;
}

export type StateProps = {
  redirectComplete: boolean;
}

export type OwnProps = {
  children: React.ReactElement;
  setUserSessionOutsideStateStore: (data: BrowserUserSessionData) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

// When this component is used it should be the top level componentof `App.js`

export const Redirect: FC<Props> = (props) => {
  const {onInitialLoad, setUserSessionOutsideStateStore, redirectComplete, children} = props;

  useEffect(() => {
    onInitialLoad(setUserSessionOutsideStateStore);
  }, []);

  return (
    redirectComplete ? children : null
  );
}

export const mapStateToProps = (state: State): StateProps => {
  return {
    redirectComplete: !state.app.redirecting,
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onInitialLoad: (setUserSessionOutsideStateStoreFunction: OwnProps['setUserSessionOutsideStateStore']) => {
      dispatch(preloadData({
        setUserSessionFn: setUserSessionOutsideStateStoreFunction,
      }));
      dispatch(loadStateFromBrowser());
      dispatch(redirectAfterInitialLoad());
    },
  };
}

export const ConnectedRedirect = connect(mapStateToProps, mapDispatchToProps)(Redirect);
