import {Dispatch} from 'react';
import {connect} from 'react-redux';

import {State} from '../../store';
import {StateProps, PasswordGuidExpiredPage} from './expired-password';

export const mapStateToProps = (state: State): StateProps => {
  const acState = state.app.accountCreation;
  if (!acState.email) {
    throw new Error('Expected email from account creation.')
  }

  return {
    email: acState.email,
  };
}

export const ConnectedPasswordGuidExpiredPage = connect(mapStateToProps, null)(PasswordGuidExpiredPage);
