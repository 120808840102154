import React from 'react';
import Button from '../../../Button/Button';

import UhOhDog from '../../../../assets/images/uh-oh-dog.svg';
import ConfettiBox from '../../../../assets/images/confetti-box.svg';

import './SaveState.scss';

const SaveState = (props) => {
  const {
    isEdited,
    onPrimaryAction,
    onSecondaryAction,
    isSaving,
    location,
    setKeepDrawerOpen,
  } = props;

  const changesNotSaved = {
    header: 'uh-oh! your changes',
    header2: 'have not been saved',
    text:
      "Your profile changes will not update and won't be seen by your customers if you close without saving.",
    primaryButton: 'Go Back to Editing',
    secondaryButton: 'Close Without Saving',
  };

  const profileSavedText = {
    header: 'congratulations!',
    header2: 'your profile has been saved',
    text:
      'Changes may take up to 30 minutes to be reflected here and in the Route mobile app.',
    primaryButton: 'Continue',
  };

  return (
    <div className="save-state-container">
      <div className="save-state-container-content">
        <img src={location !== 'saving' ? UhOhDog : ConfettiBox} />
        <div className="save-state-header">
          <span>
            {location !== 'saving'
              ? changesNotSaved.header
              : profileSavedText.header}
          </span>
          <span>
            {location !== 'saving'
              ? changesNotSaved.header2
              : profileSavedText.header2}
          </span>
        </div>
        <div className="save-state-text">
          {location !== 'saving' ? changesNotSaved.text : profileSavedText.text}
        </div>
        {location !== 'saving' ? (
          <Button
            type="submit"
            onClick={onPrimaryAction}
            text={
              location !== 'saving'
                ? changesNotSaved.primaryButton
                : profileSavedText.primaryButton
            }
          />
        ) : (
          <Button
            type="submit"
            onClick={() => onPrimaryAction({ active: false, location: '' })}
            isLoading={isSaving}
            loadingText={'Saving'}
            text={profileSavedText.primaryButton}
          />
        )}
        {location !== 'saving' ? (
          <button
            onClick={() => (location === 'saving'
              ? onSecondaryAction({ active: false, location: '' })
              : onSecondaryAction(true))
            }
            className="save-state-go-back-btn"
          >
            {location !== 'saving'
              ? changesNotSaved.secondaryButton
              : profileSavedText.secondaryButton}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default SaveState;
