import React from 'react';
import {Grid, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {
  RouteFormTextInput,
  RouteSelect,
  MenuItem,
} from '../../../components';
import {colors} from '../../../constants/styles';
import {FormData, formFieldNames, testIds} from '../types';
import {Plaid} from './plaid';

const useStyles = makeStyles((theme: Theme) => ({
  constantMarginGridItem: {
    marginTop: '25px',
  },
  conditionalMarginGridItem: {
    [theme.breakpoints.down('xs')]: {
      '&&': {
        marginTop: '25px',
      }
    }
  },
  separatorContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '45px',
    marginBottom: '25px',
    pointerEvents: 'none',
    userSelect: 'none',

  },
  separator: {
    flexGrow: 1,
    border: '0.5px solid #E3E3E3',
  },
  separatorText: {
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.blue,
    marginLeft: '14px',
    marginRight: '14px',
  },
}), {name: 'BankAccountMethod'})

const requiredValidator = (value: unknown, allValues: FormData) => {
  if (allValues.collectionMethod !== 'bank' || allValues.plaidMetadata) {
    return;
  }

  return value ? undefined : 'This field is required when selecting the "Bank Account" option. Consider connecting with Plaid.';
}

export const BankAccountMethod = () => {
  const classes = useStyles();

  return (
    <Grid container item data-testid={testIds.bankAccountCollectionMethodSection}>
      <Grid item xs={12} sm={6}>
        <RouteFormTextInput
          id={formFieldNames.bankAccountName}
          name={formFieldNames.bankAccountName}
          label="Account Name"
          fieldProps={{
            name: formFieldNames.bankAccountName,
            validate: requiredValidator as any,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.conditionalMarginGridItem}>
        <RouteFormTextInput
          id={formFieldNames.bankAccountRoutingNumber}
          name={formFieldNames.bankAccountRoutingNumber}
          label="Routing Number"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.constantMarginGridItem}>
        <RouteFormTextInput
          id={formFieldNames.bankAccountNumber}
          name={formFieldNames.bankAccountNumber}
          label="Account Number"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.constantMarginGridItem}>
        <RouteSelect
          name={formFieldNames.bankAccountType}
          label="Account Type"
        >
          <MenuItem value="company">Company</MenuItem>
          <MenuItem value="individual">Individual</MenuItem>
        </RouteSelect>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.separatorContainer}>
          <div className={classes.separator}/>
          <div className={classes.separatorText}>OR</div>
          <div className={classes.separator}/>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Plaid/>
      </Grid>
    </Grid>
  );
}
