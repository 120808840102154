import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {sendToForgotPassword, sendToForgotEmail, loginUser, hideCredentialsError} from '../../actions/sign-in';
import {OwnProps, DispatchProps, StateProps, SignInForm} from './sign-in';
import {State} from '../../store';

export const mapStateToProps = (state: State): StateProps => {
  return {
    showCredentialsError: state.app.signIn.showIncorrectCredentialsError,
  }
}

export const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps?: OwnProps): DispatchProps => {
  return {
    onSubmit: (data) => {
      if (!data.email || !data.password) {
        throw new Error('Invalid Form Data');
      }

      dispatch(loginUser({
        username: data.email,
        password: data.password,
        userInitiatedLogIn: true,
      }));
    },
    onHideCredentialsError: () => {
      dispatch(hideCredentialsError())
    },
    onForgotPassword: () => {
      dispatch(sendToForgotPassword());
    },
    onForgotEmail: () => {
      dispatch(sendToForgotEmail());
    },
  };
}

export const ConnectedSignInForm = connect(mapStateToProps, mapDispatchToProps)(SignInForm);
