import {replace} from 'connected-react-router';
import {getStripePromise} from '../../config/stripe';
import {upsertMerchantContact} from '../../api';
import {
  BillingConfig,
  BillingResponse,
  submitCreditCardForm,
  submitPlaidBankAccount,
  submitBankAccountForm
} from './billing';

import {State, ThunkDispatch} from '../../store';
import {FormData} from '../../pages/premiums-transfer/types';
import {OnboardingStatus} from '../../api/types';
import {captureException, addBreadcrumb, Severity} from '@sentry/browser';
import {updateActiveMerchant, UPDATE_ACTIVE_MERCHANT_FAILED} from '../user-data/merchants';
import {finishOnboarding} from '../congrats/congrats';

export const PREMIUMS_TRANSFER_STARTED = 'PREMIUMS_TRANSFER_STARTED' as const;
export interface PremiumsTransferStartedAction {
  type: typeof PREMIUMS_TRANSFER_STARTED;
}

export function premiumsTransferStarted(): PremiumsTransferStartedAction {
  return {
    type: PREMIUMS_TRANSFER_STARTED,
  };
}

export const PREMIUMS_TRANSFER_SUCCEEDED = 'PREMIUMS_TRANSFER_SUCCEEDED' as const;
export interface PremiumsTransferSucceededAction {
  type: typeof PREMIUMS_TRANSFER_SUCCEEDED;
}

export function premiumsTransferSucceeded(): PremiumsTransferSucceededAction {
  return {
    type: PREMIUMS_TRANSFER_SUCCEEDED,
  };
}

export const PREMIUMS_TRANSFER_FAILED = 'PREMIUMS_TRANSFER_FAILED' as const;
export interface PremiumsTransferFailedAction {
  type: typeof PREMIUMS_TRANSFER_FAILED;
  reason?: string;
  isHumanReadableError: boolean;
}

export function premiumsTransferFailed(reason?: string, humanReadableError = false): PremiumsTransferFailedAction {
  return {
    type: PREMIUMS_TRANSFER_FAILED,
    reason,
    isHumanReadableError: humanReadableError,
  };
}

export type PremiumsTransferRequestActions =
  PremiumsTransferStartedAction |
  PremiumsTransferSucceededAction | 
  PremiumsTransferFailedAction;

export function submitForm(data: FormData) {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Premiums Transfer Started',
        level: Severity.Info,
      });
      dispatch(premiumsTransferStarted());

      const {userData} = getState().app;
      if (!userData.loggedIn) {
        throw new Error('Expected user to be logged in');
      }

      const stripe = await getStripePromise();
      if (!stripe) {
        throw new Error('Stripe not initialized correctly in the app');
      }

      // Step 1: Write billing contact to merchant_contact table
      //         This is important because the merchant billing API will use the
      //         billing contact email from the merchant contact table
      await upsertMerchantContact({
        merchantId: userData.activeBrand.id,
        merchantToken: userData.activeBrand.prod_api_secret,
        data: {
          merchant_id: userData.activeBrand.id,
          slug: 'billing_contact',
          email: data.billingEmail,
        },
      });

      addBreadcrumb({
        message: 'Premiums Transfer - Merchant Contact Updated',
        level: Severity.Info,
      });
      const config: BillingConfig = {
        merchantToken: userData.activeBrand.prod_api_secret,
        stripe,
        formData: data,
      };
    
      // Step 2: Write billing information to the merchant billing table
      let billingResponse: BillingResponse;

      if (data.collectionMethod === 'cc') {
        billingResponse = await submitCreditCardForm(config);
      } else if (data.plaidMetadata) {
        billingResponse = await submitPlaidBankAccount(config);
      } else {
        billingResponse = await submitBankAccountForm(config);
      }

      if (!billingResponse.successful) {
        return dispatch(premiumsTransferFailed(billingResponse.error.message, billingResponse.isHumanReadableError));
      }

      // Step 3: Update onboarding status
      const actionResult = await dispatch(updateActiveMerchant({
        id: userData.activeBrand.id,
        prod_api_secret: userData.activeBrand.prod_api_secret,
        onboarding_status: OnboardingStatus.Complete,
        onboarding_complete: true,
        status: 'Active',
      }));

      if (actionResult.type === UPDATE_ACTIVE_MERCHANT_FAILED) {
        throw new Error('Onboarding Status Update Failed');
      }
  
      addBreadcrumb({
        message: 'Premiums Transfer Succeeded',
        level: Severity.Info,
      })
      dispatch(premiumsTransferSucceeded());
      return dispatch(finishOnboarding());
    } catch (error) {
      captureException(error);

      const errorMessage = `Premiums Transfer Error: ${error?.message || 'Unknown Error'}`;
      dispatch(premiumsTransferFailed(errorMessage));
    }
  };
}
