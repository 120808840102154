import { addBreadcrumb, Severity } from '@sentry/browser';
import { MerchantBrand } from '../models/merchant_brand';
import openApi from './open-api';
import { getHeadersWithToken } from './utils';
import dictionary from '../constants/dictionary';

export const getBrand = async (merchantId: string, token: string) => {
  try {
    const { data } = await openApi.get(`/merchants/${merchantId}/brand`, getHeadersWithToken(token));
    return data;
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_BRAND_INFORMATION,
      category: 'merchant_brand',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export const getPublicBrand = async (domain: string, token: string) => {
  try {
    const { data } = await openApi.get(`/public_brand/${domain}`, getHeadersWithToken(token));
    return data;
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_PUBLIC_BRAND_INFORMATION,
      category: 'merchant_brand',
      data: {
        domain,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export const updateBioText = async (bio: string, merchantId: string, token: string) => {
  try {
    const body: MerchantBrand = {
      bio,
    };
    const { data } = await openApi.post(`/merchants/${merchantId}/brand/bio`, body, getHeadersWithToken(token));
    return data[0];
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_MERCHANT_BRAND_BIO,
      category: 'merchant_brand',
      data: {
        merchantId,
        bio,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export default {
  getBrand,
  getPublicBrand,
  updateBioText,
};
