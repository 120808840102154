import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core/styles';

const baseBodyStyle = {
  fontSize: 16,
  lineHeight: '23px',
};

const useStyles = makeStyles({
  root: {
    marginTop: 90,
    marginBottom: 90,
    maxWidth: 420,
    textAlign: 'left',
  },
  heading: {
    fontSize: 32,
    lineHeight: '33px',
    fontWeight: 'bold',
  },
  body: {
    marginTop: 45,
    fontWeight: 600,
    ...baseBodyStyle
  },
  body2: {
    ...baseBodyStyle
  }
});

export const DetailsText: FC = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <h1 className={classes.heading}>1: Account Details</h1>
      <div className={classes.body}>
        {'Use this email & password to login to the Route Portal –'} 
      </div>
      <div className={classes.body2}>
        {'where you can customize Route+, track shipments, track insurance claims, gain valuable insights into your customers and more.'}
      </div>
    </div>
  );
}
