import React from 'react';
import {
  Container,
  Text
} from './Button.styles';

interface IButtonProps {
  isLoading?: boolean;
  text?: string;
  loadingText?: string;
  className?: string;
  disabled?: boolean;
  hoverState?: boolean;
  type?: string,
  onClick?: any,
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  return (
    <Container
      disabled={props.disabled || props.isLoading}
      {...props}
    >
      <Text {...props}>{props.text}</Text>
    </Container>
  );
};

export default Button;
