import React, {FC, useRef} from 'react';
import {Grid, Divider, Theme, Container, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Form, FormRenderProps} from 'react-final-form';

import {DetailsText} from './details-text';
import {
  HeaderContainer,
  ProgressContainerContent,
  StepEnum,
  Button,
  RouteFormTextInput,
  Bold,
  RouteSelect,
  MenuItem,
  ErrorPopover,
} from '../../components';
import {createRequiredValidator} from '../../validation/required';
import {RightArrow} from '../../media/right-arrow';
import {Validator, composeValidators, emailPatternValidator, phonePatternValidator} from '../../validation';
import countryObjectJson from '../../../../providers/countries.json';
import currencyObjectJson from '../../../../providers/currencies.json';

const typedCountryObject = countryObjectJson as Record<string, string>;
const typedCurrencyObject = currencyObjectJson as Record<string, string>;

export type OwnProps = {};

export type StateProps = {
  initialLockedEmail?: string;
  showExtendedFields: boolean;
  showDomainExistsError: boolean;
};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
  onMerchantExistsErrorDismissed: () => void;
}

export type FormData = {
  email: string;
  fullName: string;
  phoneNumber?: string;
  password: string;
  confirmPassword: string;
  domain?: string;
  storeName?: string;
  country?: string;
  currency?: string;
};

export type Props = OwnProps & StateProps & DispatchProps;

const emailRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your email.</Bold></div>);
const confirmPasswordRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please confirm your password.</Bold></div>);
const passwordRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your password.</Bold></div>);
const domainRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your store domain.</Bold></div>);

const confirmPasswordSameValidator: Validator<Partial<FormData>> = (value: unknown, allValues: Partial<FormData>) => {
  return value === allValues.password ? undefined : <div>This password does not match. <Bold>Please enter a matching password.</Bold></div>;
}

const countries = Object.keys(typedCountryObject).map((key) =>
  <MenuItem value={typedCountryObject[key]} key={typedCountryObject[key]} style={{width: '238px'}}>
    {key}
  </MenuItem>
);

const currencies = Object.keys(typedCurrencyObject).map((key) =>
  <MenuItem value={typedCurrencyObject[key]} key={typedCurrencyObject[key]} style={{width: '238px'}}>
    {key}
  </MenuItem>
);

const useInternalFormStyles = makeStyles({
  root: {},
  footer: {
    height: 245,
  },
  field: {
    marginTop: 25,
  },
  arrow: {
    marginTop: '5px',
  },
  formContentsArrowWrapper: {
    marginTop: 47,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  formContentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  formContents: {
    marginLeft: 15,
  },
  formHeading: {
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: '17px',
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: 25,
  },
  buttonDivider: {
    marginTop: 66,
  },
  button: {
    marginTop: 65,
    marginBottom: 75,
  },
  errorText: {
    color: 'white',
  },
}, {name: 'InternalForm'});

function InternalForm(props: Props & FormRenderProps<FormData>) {
  const classes = useInternalFormStyles();
  const {handleSubmit, initialLockedEmail, showExtendedFields, showDomainExistsError, onMerchantExistsErrorDismissed, invalid} = props;
  const domainInputGridRef = useRef(null);

  const domainExistsError = (
    <span>A store with this domain already exists. <Bold>Please enter a valid domain.</Bold></span>
  );

  const onChangePhoneNumber = (phoneNumber: string) => {
    props.form.change('phoneNumber', phoneNumber);
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <div className={classes.formContentsArrowWrapper}>
        <RightArrow className={classes.arrow}/>
        <div className={classes.formContentsContainer}>
          <Grid container spacing={0} className={classes.formContents}>
            <span className={classes.formHeading}>Create User Account</span>
            <Grid item xs={12}>
              <RouteFormTextInput
                required
                disabled={!!initialLockedEmail}
                id="email"
                name="email"
                label="Email Address"
                fieldProps={{
                  validate: composeValidators<FormData>(
                    emailRequiredValidator,
                    emailPatternValidator,
                  ),
                  name: 'email',
                }}>
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                id="fullName"
                name="fullName"
                label="Full Name">
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                label="Phone"

                fieldProps={{
                  validate: phonePatternValidator,
                  name: 'phoneNumber',
                }}
                >
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                required
                id="password"
                type="password"
                name="password"
                label="Password"
                fieldProps={{
                  validate: passwordRequiredValidator,
                  name: 'password'
                }}
                >
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                required
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                fieldProps={{
                  name: 'confirmPassword',
                  validate: composeValidators<FormData>(
                    confirmPasswordRequiredValidator,
                    confirmPasswordSameValidator,
                  )
                }}>
              </RouteFormTextInput>
            </Grid>
          </Grid>
          {
            showExtendedFields &&
            <Grid container spacing={0} className={classes.formContents} style={{marginTop: '45px'}}>
              <Grid item xs={12} className={classes.formHeading}>
                <span className={classes.formHeading}>Store Details</span>
              </Grid>
              <Grid item xs={12} sm={6} ref={domainInputGridRef}>
                <RouteFormTextInput
                  required
                  overrideError={showDomainExistsError}
                  id="domain"
                  name="domain"
                  label="Store Domain"
                  fieldProps={{
                    validate: domainRequiredValidator,
                    name: 'domain'
                  }}
                />
              </Grid>
              <ErrorPopover
                open={showDomainExistsError}
                onClose={onMerchantExistsErrorDismissed}
                anchorEl={domainInputGridRef.current}
                content={domainExistsError}
              />
              <Grid item xs={12} sm={6}>
                <RouteFormTextInput
                  id="storeName"
                  name="storeName"
                  label="Store Name"
                  fieldProps={{name: 'storeName'}}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RouteSelect
                  className={classes.field}
                  name="country"
                  label="Country"
                >
                  {countries}
                </RouteSelect>
              </Grid>
              <Grid item xs={12} sm={6}>
              <RouteSelect
                className={classes.field}
                name="currency"
                label="Currency"
              >
                {currencies}
              </RouteSelect>
              </Grid>
            </Grid>
          }
        </div>

      </div>
      <Divider className={classes.buttonDivider}/>
      <Container>
        <Button
          className={classes.button}
          width={240}
          id="submit-button"
          disabled={invalid}
          type="submit"
        >
          {'Save & Continue to STEP 2'}
        </Button>
      </Container>
    </form>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    height: 136,
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 90,
    paddingRight: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  }
}), {name: "AccountCreationForm"});

export const AccountCreationForm: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <HeaderContainer>
      <ProgressContainerContent width={800} progressStep={StepEnum.AccountDetails}>
        <div className={classes.innerContent}>
          <DetailsText/>
          <Divider/>
          <Form
            onSubmit={props.onSubmit}
            initialValues={{
              email: props.initialLockedEmail ?? '',
              fullName: '',
              password: '',
              confirmPassword: '',
            }}
          >
            {(formProps: FormRenderProps<FormData>) => {
              return <InternalForm {...props} {...formProps}/>;
            }}
          </Form>
        </div>
      </ProgressContainerContent>
    </HeaderContainer>
  );
}
