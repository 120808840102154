import styled, { css } from 'styled-components';
import Colors from '../../../../../../constants/colors';

export const Container = styled.div`
  padding: 24px 72px;
`;

export const Line = styled.hr`
  background: ${Colors.black};
  height: 1px;
  opacity: 0.12;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const InputText = styled.input`
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  width: 40px;
  height: 30px;
  padding: 10px 7px;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11.5px;
  line-height: 17px;
  color: #333;
`;

export const InputSelect = styled.div<any>`
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11.5px;
  line-height: 17px;
  color: #333333;
  width: 70px;
  height: 30px;
  margin-left: 8px;
  position: relative;
  padding: 7px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputOptions = styled.div<any>`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 8px 24px;
  width: 70px;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  position: absolute;
  margin-top: 30px;
  top: 0;
  display: none;

  left: 10px;
  top: 0;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11.5px;
  line-height: 17px;
  margin-left: 0;

  ${(props) =>
    props.open &&
    css`
      display: block;
    `};
`;
export const InputOption = styled.div`
  background: #ffffff;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  width: 70px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  border-bottom: 1px solid #eeeeee;

  &::last-child {
    border-bottom: none;
  }

  &:hover {
    background: #5dc8db;
    color: #ffffff;
  }
`;

export const Form = styled.div`
  margin: 8px 0;
  display: flex;
`;

export const Description = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  color: #333333;

  b {
    font-weight: 600;
  }
`;

export const Button1 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  padding: 0 12px 0 12px;
  background: #f7f7f7;
  border-radius: 10px;
  margin-right: 8px;

  border: none;

  transition: 0.25s;
  &:hover {
    opacity: 0.6;
  }
`;

export const Label1 = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  text-align: center;

  color: #ffffff;
`;

export const Button2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 12px 0 12px;
  background: #5dc8db;
  border-radius: 10px;
  border: none;

  transition: 0.25s;
  color: #ffffff;
  &:hover {
    opacity: 0.6;
  }
`;

export const Label2 = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  text-align: center;
  color: #333333;
`;

export const CloseButton = styled.img<any>`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Footer = styled.div`
  background: #eeeeee;
  min-height: 62px;
  width: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const FooterButton = styled.button`
  width: 57px;
  height: 30px;
  left: 0px;
  top: 0px;
  background: #5dc8db;
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;
  margin: 0px 0px;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  transition: all 0.5s;
  color: #ffffff;
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  border: 0;

  &:hover {
    background-color: #333333;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${Colors.gray} !important;
      cursor: not-allowed;
    `};
`;

export const Descriptive = styled.p`
  color: #666666;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  margin-top: 8px;

  b {
    font-weight: 600;
  }
`;

export const Note = styled.p`
  color: #666666;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  margin-top: 24px;
  width: 260px;
`;

export const InputSelectCaret = styled.img<any>`
  margin-top: 3px;
  transition: all 0.3s;

  ${(props) =>
    props.rotated &&
    css`
      transform: rotate(180deg);
    `};
`;

export default {
  Container,
  Line,
  InputText,
  Form,
  FooterButton,
  Note,
};
