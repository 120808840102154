import React, { useState } from 'react';
import { Box, ClickAwayListener, Divider, Grid } from '@material-ui/core';
import { addBreadcrumb, Severity } from '@sentry/browser';
import Card from '../Card';

import { Container, Description, Button1, Button2, Label1, Label2, CloseButton } from './GetRouteProPopoverDown.styles';

import RouteIcon from '../../../../assets/icons/route-icon';

import CloseIconHover from '../../../../assets/images/close-icon-hover.svg';
import RouteProMdIcon from '../../../../assets/images/route-pro-badge-md.svg';
import RouteProLgIcon from '../../../../assets/images/route-pro-badge-lg.svg';
import routeprowidgetbg2 from '../../../../assets/images/route-pro-widget-bg2.png';

import { IProps } from './interface';
import { createCsmInteraction } from '../../../../services/merchant_interaction';
import dictionary from '../../../../constants/dictionary';
import { ROUTE_PRICING_URL } from '../../../../constants';
import { colors } from '../../constants/styles';

const GetRouteProPopoverDown: React.FC<IProps> = ({ userSession, children, onClose }: IProps) => {
  const [state, setState] = useState({ page: 1 });

  const onGetRouteProPress = async () => {
    if (userSession) {
      try {
        const { user, activeBrand: merchant } = userSession;
        const payload = {
          interaction_type: 'get_route_pro_trial',
        };
        await createCsmInteraction(payload, merchant.id, user.token);
        setState({ page: 2 });
      } catch (err) {
        addBreadcrumb({
          message: dictionary.MESSAGE_ERROR_DEATTACH_USER_AND_MERCHANT_TRIAL_REQUEST,
          category: 'Get Route Pro 30 days trial',
          level: Severity.Error,
          data: {
            error: err,
          },
        });
      }
    }
  };

  const onClosePress = async () => {
    onClose();
  };

  return (
    <>
      <Container>
        {state.page === 1 && (
          <>
            <Card
              arrow
              arrowDirection={'down'}
              arrowBackground={colors.routeBlue}
              style={{
                backgroundColor: colors.routeBlue,
                // boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
                width: '300px',
                height: '160px',
                marginTop: '0px',
                marginBottom: '20px',
                padding: '32px 32px 24px 32px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <RouteIcon
                  fill={'#ffffff'}
                  width={'25px'}
                  height={'25px'}
                  viewBox={'0 0 20 10'}
                  style={{ marginBottom: '14px' }}
                />
                <Description style={{ marginBottom: '24px' }}>
                  Sorry, to save promo content you’ve uploaded, <b>you must have Route Pro</b>.
                </Description>
                <Divider style={{ marginBottom: '16px', background: '#ffffff', opacity: '0.12' }} />
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly">
                  <Button1
                    onClick={() => {
                      window.open(ROUTE_PRICING_URL, '_blank');
                    }}
                  >
                    <Label1>Learn More</Label1>
                  </Button1>
                  <Button2
                    onClick={() => {
                      onGetRouteProPress();
                    }}
                  >
                    <Label2>Try Route Pro for Free</Label2>
                  </Button2>
                </Box>
              </Box>
              <CloseButton
                srcSet={CloseIconHover}
                onClick={() => {
                  onClosePress();
                }}
              />
            </Card>
          </>
        )}

        {state.page === 2 && (
          <>
            <Card
              arrow
              arrowDirection={'down'}
              arrowBackground={'#29CAEB'}
              style={{
                background: 'linear-gradient(311.53deg, #2BFFFF 0%, #28B8E3 47.33%, #114057 94.66%)',
                // boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
                width: '300px',
                height: '160px',
                marginTop: '0px',
                marginBottom: '20px',
                padding: '32px 32px 24px 32px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginBottom: '14px' }}
                >
                  <RouteIcon fill={'#ffffff'} width={'25px'} height={'25px'} viewBox={'0 0 20 10'} />
                  <Description style={{ marginLeft: '8px' }}>Route Pro</Description>
                </Box>
                <Description style={{ marginBottom: '24px' }}>
                  Thank you for your interest in Route Pro.{' '}
                  <b>One of our Customer Success Representatives will be reaching out to you shortly via your email</b>.
                </Description>
                <Divider style={{ marginBottom: '16px', background: '#ffffff', opacity: '0.12' }} />
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                  <Button2
                    onClick={() => {
                      onClosePress();
                    }}
                  >
                    <Label2>Close</Label2>
                  </Button2>
                </Box>
              </Box>
              <CloseButton
                srcSet={CloseIconHover}
                onClick={() => {
                  onClosePress();
                }}
              />
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default GetRouteProPopoverDown;
