import React, { FC, useState, useEffect, useRef } from 'react';
import emoji from 'react-easy-emoji';
import { Box, Popover } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '../../../../components/Typography';
import Card from '../../../../components/Card';

import IconInfoWhite from '../../../../../../assets/icons/icon-info-circle-2';
import IconInfoWarning from '../../../../../../assets/icons/icon-warning';
import CloseIconHover from '../../../../../../assets/images/close-icon-2.svg';
import ArrowDownIcon from '../../../../../../assets/images/arrow-down.svg';
import Button from '../../../../components/Button';
import { LoadingToast } from '../../../../components/LoadingToast';
import {
  getNotificationsSettings,
  updateNotificationsSettings,
} from '../../../../../../services/notification_settings';
import ShipmentDelayMobilePreviewDraw from '../MobilePreview/Notification-ShipmentDelay-Push-Preview.png';
import ShipmentDelayDesktopPreviewDraw from '../DesktopPreview/Notification-ShipmentDelay-Push-Preview.png';
import HolidayDelayMobilePreviewDraw from '../MobilePreview/Notification-HolidayShipmentDelay-Push-Preview.png';
import HolidayDelayDesktopPreviewDraw from '../DesktopPreview/Notification-HolidaySHipmentDelay-Email-Preview.png';

import useOnClickOutside from '../../../../../../hooks/useOnClickOutside';

import {
  Container,
  Line,
  InputText,
  Footer,
  Form,
  Description,
  Button1,
  Button2,
  Label1,
  Label2,
  CloseButton,
  FooterButton,
  Descriptive,
  Note,
  InputSelect,
  InputOptions,
  InputOption,
  InputSelectCaret,
} from './ShipmentDelay.styles';
import ShippingNotification from '../ShippingNotification/ShippingNotification';
import Divider from '../../../../components/Divider/Divider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
    paper: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
  }),
);

type ShipmentDelayProps = {
  active: boolean;
  history: {
    push(url: string): void;
  };
  preview: {
    desktop: boolean;
    mobile: boolean;
    configure: boolean;
  };
  notificationSettings: any;
  userSession: any;
  location: any;
};

const ShipmentDelay: FC<ShipmentDelayProps> = (props: ShipmentDelayProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState(
    props.notificationSettings || {
      settings: {
        shipping_delayed: {
          active: false,
          delay_hours: 0,
        },
        shipping_ready: {
          active: false,
        },
      },
    },
  );
  const [notification, setNotification] = useState({
    active: false,
    error: false,
    message: 'You set up Send Time very well 👏',
  });
  const [preview, setPreview] = useState({
    desktop: false,
    mobile: true,
    configure: false,
  });
  const [delayHours, setdelayHours] = useState(-1);
  const [shouldConvertToHour, setShouldConvertToHour] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef();

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.notificationSettings) {
      const { notificationSettings: settings } = props.location.state;
      setNotificationSettings(settings);
    }
  }, [props.location]);

  useEffect(() => {
    if (notification.active) {
      setTimeout(() => {
        setNotification({
          ...notification,
          active: false,
          error: false,
        });
      }, 1500);
    }
  }, [notification]);

  const fetchNotifications = async () => {
    if (props?.userSession) {
      const merchant = props?.userSession.activeBrand;
      setLoading(true);
      const response = await getNotificationsSettings('shipping_delayed', merchant.id, merchant.prod_api_secret);
      setLoading(false);
      setNotificationSettings(response);

      const newDelayHours = Number(response.settings.shipping_delayed.delay_hours);
      const convertedToHour = newDelayHours / 24;

      if (convertedToHour < 1 && convertedToHour !== 0) {
        setdelayHours(newDelayHours);
        setShouldConvertToHour(true);
      } else {
        setdelayHours(Math.floor(convertedToHour));
        setShouldConvertToHour(false);
      }
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const onHandleDelayedHoursChange = (e: any): void => {
    const field = e.target?.value.replace(/^0+/, '');

    if (field === '' || /^[0-9\b]+$/.test(field)) {
      setdelayHours(field);
    }
  };

  const sendNotificationSettings = async (field: string, subfield: string, checked: boolean) => {
    if (props?.userSession) {
      const merchant = props?.userSession.activeBrand;
      const payload: any = {};
      payload[subfield] = checked;

      if (subfield === 'active' && checked === false) {
        payload.holiday_message_enabled = false;
      }

      await updateNotificationsSettings(field, payload, merchant.id, merchant.prod_api_secret);
      await fetchNotifications();
    }
  };

  const sendToastNotification = (message: string): void => {
    setNotification({
      active: true,
      error: false,
      message,
    });
  };

  const sendDelayedHoursSettings = async () => {
    if (props?.userSession) {
      try {
        const merchant = props?.userSession.activeBrand;
        const payload: any = {
          active: notificationSettings.settings.shipping_delayed.active,
          delay_hours: shouldConvertToHour ? Number(delayHours) : Number(delayHours) * 24,
        };
        setLoading(true);
        await updateNotificationsSettings('shipping_delayed', payload, merchant.id, merchant.prod_api_secret);
        await fetchNotifications();
        sendToastNotification('You set up Send Time very well 👏');
        setLoading(false);
      } catch (err) {
        console.log('Error set manual time', err);
        setLoading(false);
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
  const [anchorEl3, setAnchorEl3] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? 'simple-popover-2' : undefined;

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? 'simple-popover-3' : undefined;

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopover2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const openPopover3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };

  const openConfigureTab = () => {
    setPreview({
      desktop: false,
      mobile: false,
      configure: true,
    });
    closePopover();
  };

  const renderRequirePopover = () => {
    return (
      <Popover
        id={id3}
        open={open3}
        anchorEl={anchorEl3}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
      >
        <Card
          arrow
          arrowDirection={'left'}
          arrowBackground={'white'}
          style={{
            backgroundColor: '#ffffff',
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
            width: '212px',
            marginTop: 0,
            marginBottom: 0,
            marginLeft: '30px',
            padding: '32px 32px 24px 32px',
          }}
        >
          <Box display="flex" flexDirection="column">
            <IconInfoWarning />
            <Description style={{ marginBottom: '24px', marginTop: '17px' }}>
              {notificationSettings.settings?.shipping_delayed?.active || delayHours === 0
                ? 'Sorry, you can’t send this notification until you have set a manual Send Time.'
                : ' Sorry, you can’t enable this messaging until you active the Sending setting.'}
            </Description>
            <Divider style={{ marginBottom: '16px', background: '#eeeeee', opacity: '1', height: '1px' }} />
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              {(notificationSettings.settings?.shipping_delayed?.active || delayHours === 0) && (
                <Button2 onClick={openConfigureTab}>
                  <Label1>Set Send Time</Label1>
                </Button2>
              )}
              <Button1 onClick={closePopover}>
                <Label2>Close</Label2>
              </Button1>
            </Box>
          </Box>
          <CloseButton srcSet={CloseIconHover} onClick={closePopover} />
        </Card>
      </Popover>
    );
  };

  useOnClickOutside(ref, () => setOpenSelect(false) );

  return (
    <>
      <ShippingNotification
        title={'Shipment Delay'}
        description={
          'Sometimes packages get delayed. This notification lets you configure a notification to alert customers about delays that may affect the shipment or delivery date of their order.'
        }
        settings={[
          {
            field: 'shipping_delayed',
            subfield: 'active',
            label: 'Sending',
            requiredPopover: {
              render: () => {
                return renderRequirePopover();
              },
              open: openPopover3,
            },
            required: true,
            conditionMatch: delayHours > 0,
            onSwitch: (field: any, subfield: any, checked: any) => {
              const toastMessage = checked
                ? 'Shipment Delay Notifications have been turned ON 👏'
                : 'All Shipment Delay Notifications have been turned OFF 👍';
              sendNotificationSettings(field, subfield, checked);
              sendToastNotification(toastMessage);
            },
          },
          {
            field: 'shipping_delayed',
            subfield: 'holiday_message_enabled',
            label: 'Use Holiday Delay Message',
            onSwitch: (field: any, subfield: any, checked: any) => {
              const toastMessage = checked
                ? 'Holiday Order Volume Messaging has been turned ON 👏'
                : 'Holiday Order Volume Messaging has been turned OFF 👍';
              sendNotificationSettings(field, subfield, checked);
              sendToastNotification(toastMessage);
            },
            popover: {
              open: openPopover,
              active: open,
              render: () => (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                >
                  <Card
                    arrow
                    arrowDirection={'left'}
                    arrowBackground={'white'}
                    style={{
                      backgroundColor: '#ffffff',
                      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
                      width: '212px',
                      marginTop: 0,
                      marginBottom: 0,
                      marginLeft: '30px',
                      padding: '32px 32px 24px 32px',
                    }}
                  >
                    <Box display="flex" flexDirection="column">
                      <IconInfoWhite />
                      <Description style={{ marginBottom: '24px', marginTop: '17px' }}>
                        This updates the messages sent to the custom er letting them know that due to the holiday season
                        their package may be delayed.
                      </Description>
                      <Divider style={{ marginBottom: '16px', background: '#eeeeee', opacity: '1', height: '1px' }} />
                      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                        <Button2 onClick={closePopover}>
                          <Label1>Close</Label1>
                        </Button2>
                      </Box>
                    </Box>
                    <CloseButton srcSet={CloseIconHover} onClick={closePopover} />
                  </Card>
                </Popover>
              ),
            },
            requiredPopover: {
              open: openPopover3,
              render: () => {
                return renderRequirePopover();
              },
            },
            required: true,
            conditionMatch: delayHours > 0 && notificationSettings.settings?.shipping_delayed.active,
          },
        ]}
        notificationSettings={notificationSettings}
        setNotificationSettings={setNotificationSettings}
        configure={{
          heading: 'Trigger',
          label: 'Manually-Set Send Time',
          buttonLabel: 'Settings',
          conditionMatch: delayHours > 0,
          popover: {
            open: openPopover2,
            render: () => (
              <Popover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
              >
                <Card
                  arrow
                  arrowDirection={'left'}
                  arrowBackground={'white'}
                  style={{
                    backgroundColor: '#ffffff',
                    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
                    width: '212px',
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: '30px',
                    padding: '32px 32px 24px 32px',
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <IconInfoWarning />
                    <Description style={{ marginBottom: '24px', marginTop: '17px' }}>
                      In order to send this notification, <b>you must set a send time</b>.
                    </Description>
                    <Divider style={{ marginBottom: '16px', background: '#eeeeee', opacity: '1', height: '1px' }} />
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                      <Button1 onClick={closePopover}>
                        <Label2>Close</Label2>
                      </Button1>

                      <Button2 onClick={openConfigureTab}>
                        <Label1>Set Send Time</Label1>
                      </Button2>
                    </Box>
                  </Box>
                  <CloseButton srcSet={CloseIconHover} onClick={closePopover} />
                </Card>
              </Popover>
            ),
          },
          render: () => (
            <>
              <div style={{ height: '100%', padding: '40px 48px', marginTop: '-20px', borderRadius: 20 }}>
                <Typography variant="h4" component="h4">
                  Manually-Set Send Time
                </Typography>
                <Descriptive>
                  {' '}
                  This notification will send based on a manually set number of hours after a tracking number has been
                  generated.
                </Descriptive>
                <Line />
                <div>
                  <Descriptive>
                    <b>This will send</b>
                  </Descriptive>
                  <Form>
                    <InputText onChange={onHandleDelayedHoursChange} value={delayHours} />
                    <div style={{ position: 'relative' }}>
                      <InputSelect
                        onClick={() => {
                          setOpenSelect(true);
                        }}
                      >
                        <span>{shouldConvertToHour ? 'Hour/s' : 'Day/s'}</span>
                        <InputSelectCaret srcSet={ArrowDownIcon} rotated={openSelect} />
                      </InputSelect>
                      <InputOptions open={openSelect} ref={ref}>
                        <InputOption
                          onClick={() => {
                            setShouldConvertToHour(true);
                            setOpenSelect(false);
                          }}
                        >
                          Hour/s
                        </InputOption>
                        <InputOption
                          onClick={() => {
                            setShouldConvertToHour(false);
                            setOpenSelect(false);
                          }}
                        >
                          Day/s
                        </InputOption>
                      </InputOptions>
                    </div>
                  </Form>

                  <Descriptive>
                    <b>after tracking number has been generated.</b>
                  </Descriptive>
                  <Note>
                    NOTE: This will NOT send if the carrier scans the item for pickup before the notification send time
                    you specify.
                  </Note>
                </div>
              </div>
              <Footer>
                <FooterButton
                  disabled={delayHours <= 0 || loading}
                  onClick={() => {
                    sendDelayedHoursSettings();
                  }}
                >
                  {loading ? 'Saving' : 'Save'}
                </FooterButton>
              </Footer>
            </>
          ),
        }}
        userSession={props.userSession}
        history={props.history}
        preview={preview}
        previews={{
          mobile: notificationSettings.settings?.shipping_delayed.holiday_message_enabled
            ? HolidayDelayMobilePreviewDraw
            : ShipmentDelayMobilePreviewDraw,
          desktop: notificationSettings.settings?.shipping_delayed.holiday_message_enabled
            ? HolidayDelayDesktopPreviewDraw
            : ShipmentDelayDesktopPreviewDraw,
        }}
        isLoading={loading}
      ></ShippingNotification>
      <LoadingToast active={notification.active} message={emoji(notification.message)} />
    </>
  );
};

export default ShipmentDelay;
