export const Statuses = {
    NOT_AVAILABLE: 'NotAvailable',
    ATTEMPT_FAIL: 'AttemptFail',
    NOT_ACTIVE: 'NotActive',
    ACTIVE: 'Active',
    PENDING: 'Pending',
    INFO_RECEIVED: 'InfoReceived',
    IN_TRANSIT: 'InTransit',
    AVAILABLE_FOR_PICKUP: 'AvailableForPickup',
    OUT_FOR_DELIVERY: 'OutForDelivery',
    UNKNOWN_CARRIER: 'UnknownCarrier',
    REGISTERED: 'Registered',
    DELIVERED: 'Delivered',
    EXPIRED: 'Expired',
    UNKNOWN: 'Unknown',
    EXCEPTION: 'Exception'
};

export const LoadingStatuses = [
    Statuses.NOT_ACTIVE,
    Statuses.ACTIVE,
    Statuses.REGISTERED,
    Statuses.PENDING
];

export const StatusLabel = (status) => {
    switch (status) {
        case Statuses.NOT_AVAILABLE:
            return 'Waiting for Tracking';
        case Statuses.ATTEMPT_FAIL:
            return 'Delivery Attempted';
        case Statuses.ACTIVE:
            return 'Getting Info...';
        case Statuses.NOT_ACTIVE:
            return 'Finding Package...';
        case Statuses.DELIVERED:
            return 'Delivered';
        case Statuses.REGISTERED:
            return 'Getting Info...';
        case Statuses.PENDING:
            return 'Getting Info...';
        case Statuses.INFO_RECEIVED:
            return 'Awaiting Shipment';
        case Statuses.IN_TRANSIT:
            return 'In Transit';
        case Statuses.OUT_FOR_DELIVERY:
            return 'Out for Delivery';
        case Statuses.UNKNOWN_CARRIER:
            return 'Carrier Not Supported';
        case Statuses.EXPIRED:
            return 'Tracking Expired';
        case Statuses.AVAILABLE_FOR_PICKUP:
            return 'Available for Pickup';
        case Statuses.EXCEPTION:
            return 'Carrier Problem';
        default:
            return status;
    }
}

export const ParseShipmentStatuses = (shipments) => {
    if (!shipments || shipments.length === 0) return Statuses.NOT_ACTIVE;

    const priority = [
        Statuses.NOT_AVAILABLE,
        Statuses.EXCEPTION, // lowest priority
        Statuses.NOT_ACTIVE,
        Statuses.ACTIVE,
        Statuses.REGISTERED,
        Statuses.PENDING,
        Statuses.UNKNOWN,
        Statuses.ATTEMPT_FAIL,
        Statuses.DELIVERED,
        Statuses.AVAILABLE_FOR_PICKUP,
        Statuses.OUT_FOR_DELIVERY,
        Statuses.IN_TRANSIT,
        Statuses.INFO_RECEIVED // top priority
    ];

    let highestIndex = -1;
    shipments.forEach((element, index) => {
        const currentIndex = priority.indexOf(element.status);

        if (currentIndex > highestIndex) highestIndex = currentIndex;
    });

    return highestIndex === -1 ? priority[0] : priority[highestIndex];
}