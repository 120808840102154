import React, { Component } from 'react';
import './VerifyPayment.scss';
import Button from '../../../Button/Button';
import TitleAndNav from './TitleAndNav';

import { verifyBankDeposit } from '../../../../services/billing';
import { getMerchant } from '../../../../services/merchant';
import { isOnboardingFlow } from '../../../../utils/url';

class VerifyPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deposit1: '',
      deposit2: '',
      disableButton: true,
    };
  }

  handleVerifyPaymentInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    this.setState({ [name]: value });

    this.setState({ disableButton: !this.validated() });
  };

  validated = () => {
    const {deposit1, deposit2} = this.state;
    return deposit1 !== '' && deposit2 !== '';
  };

  handleVerifyPayment = async (e) => {
    e.preventDefault();

    this.setState({ disableButton: true });
    let prodApiSecret = ''

    if (isOnboardingFlow()) {
      const merchant = getMerchant() || "";
      prodApiSecret = merchant.prod_api_secret;
    } else {
      prodApiSecret = this.props.userSession.activeBrand.prod_api_secret;
    }
    let res = await verifyBankDeposit({
      deposit1: Math.round(Number(this.state.deposit1) * 100),
      deposit2: Math.round(Number(this.state.deposit2) * 100),
      bank_id: this.props.billing.bank_id,
      stripe_customer_token: this.props.billing.stripe_customer_token,
    }, prodApiSecret);
    if (res) {
      this.props.setSnackbarMessage({ message: 'Payment Method Updated Successfully', variant: 'success' });
      this.props.closeDrawer();
      this.props.getBillingDetails();
    } else {
      this.props.setSnackbarMessage({ message: 'An error occurred while trying to verify your payment method with Stripe. \nPlease make sure your information is entered correctly. \nPlease reach out to Route Customer Service with any questions, or if this error persists.', variant: 'error' });
    }
    this.setState({ disableButton: true });
  };


  render() {
    return (
      <div className='verify-payment-container'>
        <TitleAndNav close={this.props.closeDrawer} title='Verify Payment Method' />
        <div className='verify-payment-inner-container'>
          <div className='verify-payment-upper-text'>
            <p>Please check your bank transactions for two<br/> micro-deposits made from AMTS, and enter the amounts below. </p>
            <p>NOTE: Deposits will typically take 1-2 days to show on your bank statement.</p>
          </div>
          <div className='verify-payment-form-container'>
            <form className='verify-payment-form'>
              <div className='verify-payment-form-deposit-row verify-payment-form-deposit-row-one'>
                <div className='field'>
                  <label htmlFor='deposit1'>
                    <span>Deposit 1</span>
                  </label>
                </div>
                <div class="form-input-text">
                  <input className='verify-payment-form-input' id='deposit1' name='deposit1' placeholder='Enter deposit 1 amount (e.g. 0.24)'
                    value={this.state.deposit1}
                    onChange={this.handleVerifyPaymentInput}
                  />
                  <label className="static-value">$</label>
                </div>
              </div>
              <div className='verify-payment-form-deposit-row'>
                <div className='field'>
                  <label htmlFor='deposit2'>
                    <span>Deposit 2</span>
                  </label>
                </div>
                <div className="form-input-text">
                  <input className='verify-payment-form-input' id='deposit2' name='deposit2' placeholder='Enter deposit 2 amount (e.g. 0.81)'
                    value={this.state.deposit2}
                    onChange={this.handleVerifyPaymentInput}
                  />
                  <label className="static-value">$</label>
                </div>
              </div>
            </form>
            <Button className='verify-payment-form-btn' type='submit'
              onClick={this.handleVerifyPayment}
              disabled={this.state.disableButton}
              text='Verify Deposits'/>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyPayment;
