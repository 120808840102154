import React, { useState, useEffect } from 'react';
import './RoutePackageProtection.scss';
import {
  Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Box,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import PageHeader from '../_shared/PageHeader/PageHeader';
import PageTitle from '../_shared/PageTitle/PageTitle';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import Paragraph from '../_shared/Paragraph/Paragraph';
import circularCheckbox from '../../../assets/images/circular-checkbox.svg';
import circularCheckboxChecked from '../../../assets/images/circular-checkbox-selected.svg';
import squareCheckbox from '../../../assets/images/square-checkbox.svg';
import squareCheckboxChecked from '../../../assets/images/square-checkbox-checked.svg';
import CTAButton from '../_shared/CTAButton/CTAButton';
import bulletSquare from '../../../assets/images/bullet-square-icon.jpg';
import Subtitle from '../_shared/Subtitle/Subtitle';
import {
  getPlatform, setRouteFreeProductVersion, setRoutePlusProductVersion, setManualInstallOption, setExpertInstallOption,
} from '../../../services/api';
import routePackageProtection from '../../../assets/images/route-package-protection.svg';
import FeaturedImage from '../_shared/FeaturedImage/FeaturedImage';
import {
  setOnboardingComplete, getMerchant, updateMerchantRequest, requestFullCoverage, shopifyUpdateScripts,
} from '../../../services/merchant';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#666666',
  },
}));

const RoutePackageProtection = (props) => {
  const classes = useStyles();
  const [currentChecked, setCurrentChecked] = useState(0);
  const [fullCoverage, setFullCoverage] = useState(false);
  const checkboxes = [
    'The Route checkbox is SELECTED by default, so your customers must "opt out" to avoid the purchase of Route package protection',
    'The Route checkbox is UNSELECTED by default, so your customers must "opt in" to purchase Route package protection',
    // 'You want to pay for Route Plus package protection for all of your customers',
  ];

  const updateRouteProtection = async (e) => {
    const merchant = getMerchant();

    if (!merchant) {
      return;
    }

    const merchantPreferences = Object.assign(merchant.merchant_preferences || {}, {
      opt_in: currentChecked === 1,
      opt_out: currentChecked === 0,
    });

    if (merchant && merchant.platform_id === 'shopify') {
      const { shopify_domain, shopify_access_token, store_domain } = merchant;
      const shop = shopify_domain || store_domain;

      if (shop) {
        await shopifyUpdateScripts(shop);
      }
    }

    if (fullCoverage) {
      const coverageType = currentChecked !== 0 ? 'opt-in' : 'opt-out';
      const merchantId = merchant.id || merchant.merchantId;
      requestFullCoverage(merchantId, coverageType, merchant.prod_api_secret);
    }

    await updateMerchantRequest('merchant_preferences', JSON.stringify(merchantPreferences));
  };

  const nextStep = () => {
    props.history.push('/onboarding/billing');
  };

  const nextSkipStep = () => {
    props.history.push('/onboarding/personalize-your-in-app-brand');
  };

  const handleSubmitButton = async () => {
    // platform
    await setRoutePlusProductVersion();
    await updateRouteProtection();
    if (getPlatform() === 'shopify') {
      props.history.push('/onboarding/installation-instructions');
    } else {
      nextStep();
    }
  };

  const handleSkipButton = () => {
    setRouteFreeProductVersion();
    nextSkipStep();
  };

  return (
      <div>
         <OnboardingContainer className="route-package-protection">
            <Box display="flex">
               <Box width={721} mr="60px">
                  <PageHeader>
                     <PageTitle title="Route Plus Package Protection"></PageTitle>
                  </PageHeader>
                  <Paper className="column">
                     <div>
                        <Paragraph text={'One of the benefits of Route Plus is the ability for your customers to instantly add Route package protection to their online orders at the point of checkout.'}></Paragraph>
                        <Paragraph text={'Please select from the options below to configure the experience for your customers.'}></Paragraph>
                        <List
                           component="nav"
                           aria-labelledby="nested-list-subheader"
                           className="nested-list"
                        >
                           {checkboxes.map((item, i) => (
                              <ListItem key={i} onClick={() => setCurrentChecked(i)} alignItems="flex-start" className="item-list">
                                 <ListItemIcon className='checkbox'>
                                    <Checkbox
                                       onChange={() => setCurrentChecked(i)}
                                       checked={currentChecked === i}
                                       icon={
                                          <img
                                             alt="checkbox icon image"
                                             srcSet={circularCheckbox}
                                             width={20}
                                          />
                                       }
                                       checkedIcon={
                                          <img
                                             alt="checkbox icon image"
                                             srcSet={circularCheckboxChecked}
                                             width={20}
                                          />
                                       }
                                       value="checked"
                                       color="primary"
                                    />
                                 </ListItemIcon>
                                 <ListItemText primary={item} secondary={ i === 0 && '(Recommended)'}/>
                              </ListItem>
                           ))}

                        </List>
                     </div>
                     <div className='coverage-type-coverage' onClick={() => setFullCoverage(!fullCoverage)}>
                        <Checkbox
                        className="checkbox-square"
                        icon={
                           <img
                              alt="checkbox icon image"
                              srcSet={squareCheckbox}
                              width={15}
                              />
                        }
                        checkedIcon={
                              <img
                              alt="checkbox icon image"
                              srcSet={squareCheckboxChecked}
                              width={15}
                              />
                        }
                        checked={fullCoverage}
                        value="checked"
                        color="primary"
                        />

                        <p>Check here if you want to pay for Route Plus package protection for all of your customers <b>(Requires approval)</b></p>
                     </div>
                     <div className="button-container">
                        <CTAButton text="Continue" href="#" onClick={handleSubmitButton}></CTAButton>
                        <CTAButton text="Add Package Protection Later" href="#" styleName="basic underline" onClick={handleSkipButton}></CTAButton>
                     </div>
                  </Paper>
               </Box>
               <Box display="flex" mt="140px" alignItems="flex-start">
                  <FeaturedImage src={routePackageProtection} alt="Route Plus Package Protection sample"></FeaturedImage>
               </Box>
            </Box>
         </OnboardingContainer>
      </div>
  );
};

export default RoutePackageProtection;
