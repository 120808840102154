import React, {FC, useState} from 'react';
import {Grid, Divider, Theme, Typography, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Form, FormRenderProps} from 'react-final-form';
import {Elements} from '@stripe/react-stripe-js';

import {
  HeaderContainer,
  StepEnum,
  ProgressContainerContent,
  Button,
} from '../../components';
import {colors} from '../../constants/styles';
import {FormData, formFieldNames} from './types';
import {CollectionMethod} from './method';
import {BillingContact} from './method/billing-contact';
import {getStripePromise} from '../../config/stripe';

import VideoModal from '../../../Onboarding/Billing/BillingVideoModal/BillingVideoModal';
import PlayVideoButton from '../../../../assets/images/play-video-button.svg';

export type OwnProps = {};

export type StateProps = {};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
  onFinishLater: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const BluePlus = () => (<span style={{color: colors.blue}}>+</span>);

const Bold = (props: {children: React.ReactNode, color?: string}) => {
  const style: any = {
    fontWeight: 'bold',
  };

  if (props.color) {
    style.color = props.color;
  }

  return (
    <span style={style}>{props.children}</span>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    height: 136,
  },
  content: {
    textAlign: 'left',
    paddingLeft: 90,
    paddingRight: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
      paddingRight: 5,
      width: 'calc(100% - 10px)'
    }
  },
  explanationText: {
    display: 'block !important',
    fontSize: '16px !important',
    lineHeight: '23px',
    color: colors.mediumDarkGray,
  },
  explanationCaption: {
    display: 'block !important',
    fontSize: '12px !important',
    fontWeight: 600,
    lineHeight: '17px',
    color: colors.darkGray,
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'begin',
    marginTop: '36px',
    marginBottom: '75px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '65px',
    marginBottom: '75px',
  },
  link: {
    marginTop: '25px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textDecoration: 'underline',
    color: colors.lightGray,
  }
}), {name: 'TransferPremiums'});

export const TransferPremiums: FC<Props> = (props) => {
  const classes = useStyles();
  const [videoOpen, setVideoOpen] = useState(false);

  const GridDivider = () => (<Grid item xs={12}><Divider style={{width: '100%'}}/></Grid>)
  const finishLater = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onFinishLater();
  };

  return (
    <HeaderContainer>
      <ProgressContainerContent width={800} progressStep={StepEnum.PremiumsTransfer}>
        <Form
          onSubmit={props.onSubmit}
          initialValues={{
            [formFieldNames.collectionMethod]: 'bank',
            [formFieldNames.bankAccountType]: 'company',
            [formFieldNames.billingEmail]: '',
          }}
        >
          {(formProps: FormRenderProps<FormData>) => (
            <form onSubmit={formProps.handleSubmit}>
              <Grid container className={classes.content}>
                <Grid item xs={12}>
                  <Typography variant="h1" style={{marginTop: '65px'}}>
                    4: Premiums Transfer
                  </Typography>
                  <Typography style={{marginTop: '55px', maxWidth: '420px'}} className={classes.explanationText}>
                    <Bold color="black">Route and Route<BluePlus/> are 100% FREE for you. </Bold>
                    However, your customers pay a small (about 1% of purchase) premiums fee for Route<BluePlus/>.
                    That fee is initially paid to you at checkout, so this is a way for us to collect that fee.
                  </Typography>
                  <div className={classes.videoContainer}>
                    <span className={classes.explanationCaption}>
                      How does collection of premiums work? 
                    </span>
                    <img style={{marginLeft: '10px', cursor: 'pointer'}} src={PlayVideoButton} onClick={() => setVideoOpen(true)}/>
                  </div>
                </Grid>
                <GridDivider/>
                <Grid item xs={12}>
                  <Elements stripe={getStripePromise()} options={{fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Titillium+Web'}]}}>
                    <CollectionMethod/>
                  </Elements>
                </Grid>
                <GridDivider/>
                <Grid item xs={12}>
                  <BillingContact/>
                </Grid>
                <GridDivider/>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button width={240} disabled={formProps.invalid} onClick={formProps.handleSubmit}>{'Save & Finish'}</Button>
                  <Link className={classes.link} href="" onClick={finishLater}>Do This Later</Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </ProgressContainerContent>
      {
        videoOpen ? (
          <VideoModal
            open={videoOpen}
            onRequestClose={() => setVideoOpen(false)}
          />
        ) : null
      }
    </HeaderContainer>
  );
}
