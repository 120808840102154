import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {BrandedContent} from './BrandedContent/BrandedContent';
import ShippingNotifications from './ShippingNotifications';
import NotFound from '../../../_shared/ErrorPages/NotFound';
import ReadyToShip from './ShippingNotifications/ReadyToShip';
import ShipmentDelay from './ShippingNotifications/ShipmentDelay';

const Engage = (props: any) => {
  return (
    <Switch>
      <Redirect exact from='/engage' to='/engage/branded-content' />
      <Route exact path='/engage/branded-content' render={() => <BrandedContent {...props}/>} />
      <Route exact path='/engage/shipping-notification' render={() => <ShippingNotifications {...props}/>} />
      <Route exact path='/engage/shipping-notification/ready-to-ship' render={() => <ReadyToShip {...props}/>} />
      <Route exact path='/engage/shipping-notification/shipment-delay' render={() => <ShipmentDelay {...props}/>} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
}
export default Engage;
