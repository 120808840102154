import React, {FC} from 'react';
import {Grid, Divider, Theme, Typography, RadioGroup} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Form, FormRenderProps, Field} from 'react-final-form';

import {
  HeaderContainer,
  ProgressContainerContent,
  StepEnum,
  Button,
  RouteFormTextInput,
  RouteRadio,
  Bold,
} from '../../components';
import {colors, text} from '../../constants/styles';
import {RightArrow} from '../../media/right-arrow';
import shipmentProtectionImage from '../../media/shipment-protection.png';

export type OwnProps = {};

export type StateProps = {
  qualifiesForExpertImplementation: boolean;
};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
}

const claimsContactEmailFieldName = 'claimsContactEmail';
const returnsContactEmailFieldName = 'returnsContactEmail';
const returnsProcessLinkFieldName = 'returnsProcessLink';

export type FormData = {
  routePlusEnabled: boolean;
  expertImplementationEnabled: boolean;
  [claimsContactEmailFieldName]?: string;
  [returnsContactEmailFieldName]?: string;
  [returnsProcessLinkFieldName]?: string;
};

export type Props = OwnProps & StateProps & DispatchProps;

const BluePlus = () => (<span style={{color: colors.blue}}>+</span>);

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    height: 136,
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginTop: '65px',
    paddingLeft: 90,
    paddingRight: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  innerContentItem: {
    marginTop: '63px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerContentSmallText: {
    fontSize: '10px !important',
    fontWeight: 600,
    lineHeight: '14px',
  },
}), {name: "ConfigureRoutePlus"});

export const ConfigureForm: FC<Props> = (props) => {
  const classes = useStyles();

  const initialValues: FormData = {
    routePlusEnabled: true,
    expertImplementationEnabled: props.qualifiesForExpertImplementation,
  }

  return (
    <HeaderContainer>
      <ProgressContainerContent width={800} progressStep={StepEnum.ConfigureRoutePlus}>
        <div className={classes.innerContent}>
          <Grid container style={{flexShrink: 0}}>
            <Grid item xs={12}>
              <Typography variant="h1">2: Configure Route<BluePlus/></Typography>
            </Grid>
            <Grid className={classes.innerContentItem} item xs={12} sm={6}>
                <Typography><Bold>Route<BluePlus/> Package Protection</Bold> is available for your customers to add at checkout. Please use the settings below to configure this experience for your customers.</Typography>
                <div style={{marginTop: '15px', width: '100%'}}>
                  <Typography classes={{root: classes.innerContentSmallText}}>All settings can be changed and managed in the Route Portal.</Typography>
                </div>
            </Grid> 
            <Grid className={classes.innerContentItem} item xs={12} sm={6}>
              <img src={shipmentProtectionImage} width={228}/>
            </Grid>
          </Grid>
          <Divider style={{marginTop: '75px'}}/>
          <div>
            <Form 
              onSubmit={props.onSubmit}
              initialValues={initialValues}
            >
              {(formProps: FormRenderProps<FormData>) => {
                return <InternalForm {...props} {...formProps}/>;
              }}
            </Form>
          </div>
        </div>
      </ProgressContainerContent>
    </HeaderContainer>
  );
}

// *********************************************************************** //

type ConditionProps<T> = {
  when: string;
  is: T;
  children: React.ReactElement;
};
function Condition<T>({ when, is, children }: ConditionProps<T>) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );
}

const emailValidator = (value?: string) => {
  const message = 'This does not appear to be a valid email. Please check formatting and try again.'
  return value === undefined || !value.includes('@') ? message : undefined;
}

const useInternalFormStyles = makeStyles((theme) => ({
  arrow: {
    marginRight: '14px',
  },
  radioGroup: {
    display: 'contents',
    width: '100%',
  },
  field: {
    marginTop: 25,
  },
  formContentsContainer: {
    marginTop: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstHorizontalGridItem: {
    marginTop: '35px',
    '.onboarding-2 &': {
      paddingLeft: '26px !important',
    }
  },
  secondHorizontalGridItem: {
    marginTop: '35px',
    // Normally we don't want any padding for the second horizontal items
    // but when the second horizontal item is on its own line we need
    // padding to match the first item
    [theme.breakpoints.down('xs')]: {
      '.onboarding-2 &': {
        paddingLeft: '26px !important'
      }
    }
  },
  list: {
    listStyle: 'none',
    marginLeft: 0,
    paddingLeft: 0,
  },
  listItem: {
    fontSize: '12px',
    lineHeight: '18px',
    color: text.listItemColor,
    paddingLeft: '1em',
    textIndent: '-1em',
    '&:before': {
      content: '"■ "',
    }
  },
  radioLabel: {
    color: 'black',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  },
  paragraphText: {
    display: 'block !important',
    fontSize: '12px !important',
    lineHeight: '18px',
    color: text.detailsParagraphColor,
    maxWidth: '242px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '26px !important',
      marginTop: '10px',
      marginBottom: '35px',
    }
  },
  expertImplementationExplanationText: {
    marginLeft: '40px',
    marginTop: '10px',
    maxWidth: '200px',
    fontSize: '12px !important',
    lineHeight: '18px',
    color: text.listItemColor
  },
  divider: {
    width: '100%',
    marginTop: '55px',
    marginBottom: '45px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '75px',
  },
}), {name: 'InternalForm'});

export const testIds = {
  routePlusSection: 'routePlusSection',
  contactInfoSection: 'contactInfoSection',
  expertImplementationSection: 'expertImplementationSection',
  routePlusEnabledRadio: 'routePlusEnabledRadio',
  routePlusDisabledRadio: 'routePlusDisabledRadio',
  expertImplementationEnabledRadio: 'expertImplementationEnabledRadio',
  expertImplementationDisabledRadio: 'expertImplementationDisabledRadio',
  claimsContactEmail: claimsContactEmailFieldName,
  returnsContactEmail: returnsContactEmailFieldName,
  returnsProcessLink: returnsProcessLinkFieldName,
};

function InternalForm(props: Props & FormRenderProps<FormData>) {
  const classes = useInternalFormStyles();
  const {handleSubmit, invalid, qualifiesForExpertImplementation} = props;

  const parseFormElementValueToBoolean = (value: any) => value === 'true';
 
  const routePlusEnabledList = (
    <div style={{marginLeft: '40px', marginTop: '10px', maxWidth: '200px'}}>
      <ul className={classes.list}>
        <li className={classes.listItem}>Premium Package Protection</li>
        <li className={classes.listItem}>Visual Tracking</li>
        <li className={classes.listItem}>Branded Profile in Route App</li>
        <li className={classes.listItem}><Bold>FREE for Merchants</Bold></li>
        <li className={classes.listItem}>A small coverage premium (about 1% of order) is charged to shoppers</li>
      </ul>
    </div>
  );

  const routeOnlyList = (
    <div style={{marginLeft: '45px', marginTop: '10px'}}>
      <ul className={classes.list}>
        <li className={classes.listItem}>Visual Tracking ONLY</li>
        <li className={classes.listItem}><Bold>{'FREE for Merchants & Shoppers'}</Bold></li>
      </ul>
    </div>
  );

  const expertImplementationContent = (
    <div className={classes.expertImplementationExplanationText}>
      Our team will be in contact within 3 business days to request any necessary permissions and finalize the implementation for FREE.
    </div>
  );

  const contactInfoSection = (
    <React.Fragment>
      <Grid item xs={12}><Divider className={classes.divider}/></Grid>
      <Grid container item xs={12} data-testid={testIds.contactInfoSection}>
        <Grid item xs={12} className={classes.sectionHeader}>
          <RightArrow className={classes.arrow}/>
          <Typography variant="subtitle2">B. CONTACT INFO</Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{marginTop: '25px'}} className={classes.firstHorizontalGridItem}>
          <RouteFormTextInput
            id={claimsContactEmailFieldName}
            name={claimsContactEmailFieldName}
            label="Claims Contact Email"
            fieldProps={{
              name: claimsContactEmailFieldName,
              validate: emailValidator,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{marginTop: '45px'}}>
          <Typography classes={{root: classes.paragraphText}}>
            There are a few instances where Route may not cover a customer insurance claim.
            We need a contact email to let you know if the claim has been denied, order info and customer info.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{marginTop: '20px'}} className={classes.firstHorizontalGridItem}>
          <RouteFormTextInput
            id={returnsContactEmailFieldName}
            name={returnsContactEmailFieldName}
            label="Returns Contact Email"
            fieldProps={{
              name: returnsContactEmailFieldName,
              validate: emailValidator,
            }}
          />
          <RouteFormTextInput
            className={classes.field}
            id={returnsProcessLinkFieldName}
            name={returnsProcessLinkFieldName}
            label="Link To your Returns Process/Form/Policy"
            fieldProps={{
              name: returnsProcessLinkFieldName
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{marginTop: '40px'}}>
          <Typography classes={{root: classes.paragraphText}}>
            Provide contact information that your customers can use for return-related questions or concerns.
            This will be listed on your merchant profile in the Route mobile app.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const expertImplementationSection = (
    <React.Fragment>
      <Grid item xs={12}><Divider className={classes.divider}/></Grid>
      <Grid container item xs={12} data-testid={testIds.expertImplementationSection}>
        <Grid item xs={12} className={classes.sectionHeader}>
          <RightArrow className={classes.arrow}/>
          <Typography variant="subtitle2">C. IMPLEMENTATION METHOD</Typography>
        </Grid>
        <RadioGroup className={classes.radioGroup}>
          <Grid item xs={12} sm={6} className={classes.firstHorizontalGridItem}>
            <RouteRadio
              name="expertImplementationEnabled"
              dataId={testIds.expertImplementationEnabledRadio}
              value={true}
              parse={parseFormElementValueToBoolean}
              label={<span className={classes.radioLabel}>FREE Expert Implementation<div style={{marginTop: '-3px'}}>(RECOMMENDED)</div></span>}
            />
            {expertImplementationContent}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.secondHorizontalGridItem}>
            <RouteRadio
              name="expertImplementationEnabled"
              dataId={testIds.expertImplementationDisabledRadio}
              value={false}
              parse={parseFormElementValueToBoolean}
              label={<span className={classes.radioLabel}>Self Implementation</span>}
            />
          </Grid>
        </RadioGroup>
      </Grid>
    </React.Fragment>
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formContentsContainer}>
        <Grid container>
          <Grid container item xs={12} data-testid={testIds.routePlusSection}>
            <Grid item xs={12} className={classes.sectionHeader}>
            <RightArrow className={classes.arrow}/>
              <Typography variant="subtitle2">A. SET ROUTE<BluePlus/> PACKAGE PROTECTION</Typography>
            </Grid>
            <RadioGroup className={classes.radioGroup}>
              <Grid item xs={12} sm={6} className={classes.firstHorizontalGridItem}>
                <RouteRadio
                  name="routePlusEnabled"
                  dataId={testIds.routePlusEnabledRadio}
                  value={true}
                  parse={parseFormElementValueToBoolean}
                  label={<span className={classes.radioLabel}>ROUTE<BluePlus/> (Recommended)</span>}
                />
                {routePlusEnabledList}
              </Grid>
              <Grid item xs={12} sm={6} className={classes.secondHorizontalGridItem}>
                <RouteRadio
                  name="routePlusEnabled"
                  dataId={testIds.routePlusDisabledRadio}
                  value={false}
                  parse={parseFormElementValueToBoolean}
                  label={<span className={classes.radioLabel}>ROUTE ONLY</span>}
                />
                {routeOnlyList}
              </Grid>
            </RadioGroup>
          </Grid>
          <Condition when="routePlusEnabled" is={true}>
            {contactInfoSection}
          </Condition>
          { qualifiesForExpertImplementation &&
            <Condition when="routePlusEnabled" is={true}>
              {expertImplementationSection}
            </Condition>
          }
          <Grid item xs={12}><Divider className={classes.divider}/></Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button width={240} onClick={handleSubmit} disabled={invalid}>{'Save & Continue to STEP 3'}</Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

