import React from 'react';

export const SmallLogo = (props: {className?: string}) => (
  <svg className={props.className} width="100" height="16" viewBox="0 0 100 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44.7304 13.6868H42.8087L38.2885 7.29967H40.0088C40.4133 7.30547 40.8176 7.27481 41.2166 7.20808C41.4654 7.16794 41.7103 7.10674 41.9488 7.02514C42.3208 6.86993 42.6367 6.60515 42.8546 6.26602C43.053 5.97711 43.164 5.63719 43.1744 5.28687C43.1733 4.94204 43.0645 4.60617 42.8633 4.32614C42.6329 4.00846 42.3239 3.75616 41.9665 3.59402C41.5426 3.39776 41.0794 3.30077 40.6123 3.3104H37.8488L37.8854 13.6872H36.3298V1.77272H40.6123C41.1611 1.76848 41.7064 1.86143 42.2228 2.04725C42.7088 2.22181 43.1563 2.48905 43.5404 2.83413C43.8973 3.15144 44.1867 3.53734 44.3914 3.96884C44.5892 4.38001 44.6924 4.83023 44.6936 5.28649C44.6995 5.98904 44.4672 6.67287 44.0346 7.2265C43.8424 7.48011 43.618 7.70768 43.3671 7.90349C43.1156 8.10416 42.8424 8.27605 42.5527 8.41596C42.1507 8.57693 41.7228 8.66362 41.2898 8.67182L44.7304 13.6868Z" fill="black"/>
    <path d="M54.4852 13.7969C53.4203 13.8059 52.3731 13.5244 51.4563 12.9825C50.5416 12.443 49.7841 11.6735 49.2591 10.7504C48.7341 9.82727 48.46 8.7828 48.4641 7.72088C48.4556 6.65438 48.7336 5.60517 49.2692 4.68287C49.793 3.76909 50.5473 3.00868 51.4568 2.47754C52.3738 1.94414 53.4157 1.66315 54.4765 1.66315C55.5373 1.66315 56.5792 1.94414 57.4962 2.47754C58.4067 3.01025 59.1634 3.77013 59.6923 4.68287C60.2258 5.60813 60.5067 6.65742 60.5067 7.72548C60.5067 8.79355 60.2258 9.84283 59.6923 10.7681C59.1657 11.685 58.4087 12.4483 57.4962 12.9825C56.5853 13.5227 55.5442 13.8043 54.4852 13.7969V13.7969ZM54.4852 3.18216C53.6899 3.17612 52.9082 3.38818 52.225 3.79531C51.5442 4.19552 50.9795 4.76641 50.5869 5.45158C50.1912 6.14576 49.9831 6.93104 49.9831 7.73009C49.9831 8.52914 50.1912 9.31441 50.5869 10.0086C50.9794 10.6939 51.544 11.2648 52.225 11.6649C52.9105 12.0664 53.6907 12.278 54.4852 12.278C55.2797 12.278 56.0598 12.0664 56.7454 11.6649C57.4261 11.2647 57.9905 10.6939 58.3829 10.0087C58.7786 9.31454 58.9867 8.52926 58.9867 7.73021C58.9867 6.93117 58.7786 6.14589 58.3829 5.45171C57.9903 4.76666 57.4259 4.1958 56.7454 3.79544C56.0622 3.38835 55.2805 3.17624 54.4852 3.18216V3.18216Z" fill="black"/>
    <path d="M69.4735 13.7969C68.8608 13.7988 68.254 13.6775 67.6892 13.4399C67.1247 13.2043 66.6164 12.8519 66.1977 12.4059C65.7641 11.9755 65.4217 11.4621 65.1909 10.8964C64.9582 10.324 64.8401 9.71141 64.8433 9.0935V1.77301H66.3623V9.0935C66.361 9.51381 66.4417 9.93035 66.6001 10.3197C66.7543 10.7035 66.9847 11.0522 67.2774 11.3445C67.9239 11.9671 68.656 12.2784 69.4735 12.2784C69.8829 12.2795 70.2881 12.1954 70.6632 12.0313C71.0391 11.8682 71.3806 11.6354 71.6698 11.345C71.9623 11.0526 72.1927 10.704 72.3469 10.3202C72.5052 9.93083 72.586 9.51432 72.5848 9.09401V1.77301H74.1033V9.0935C74.1104 10.3303 73.6305 11.5201 72.7674 12.4059C72.3418 12.8497 71.8302 13.202 71.2637 13.4412C70.6973 13.6804 70.088 13.8014 69.4731 13.7969H69.4735Z" fill="black"/>
    <path d="M88.0676 1.77301V3.31031H84.023V13.6871H82.4674V3.31031H78.4229V1.77301H88.0676Z" fill="black"/>
    <path d="M92.3867 13.6871V1.77301H100V3.31031H93.9057V6.95226H98.5726V8.48955H93.9057V12.1681H100V13.6871H92.3867Z" fill="black"/>
    <path d="M24.7469 10.8555C21.3905 7.52368 18.2698 4.24952 14.9405 1.19284C13.1955 -0.409321 11.5597 -0.351625 9.67636 1.09049C8.00013 2.37437 6.50774 3.88774 4.72943 5.48324C7.64987 6.15269 9.36038 7.96797 11.113 9.77225C12.7776 11.4854 14.5262 13.1257 16.3094 14.7215C17.603 15.8791 19.1365 15.8543 20.4386 14.8209C21.9694 13.6066 23.3246 12.1811 24.7469 10.8555ZM1.59215 15.1639C3.5415 13.2754 5.39426 11.4807 7.26801 9.66569C6.12844 7.71135 2.87833 7.66261 1.27924 9.22434C-0.193708 10.6629 -0.759273 13.4462 1.59215 15.1639V15.1639Z" fill="#5DC8DB"/>
  </svg>
);
