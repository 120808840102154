import React, { FC, useState, useEffect } from 'react';
import {Container, Button, Bold} from '../../../../Onboarding-2/components';
import {Grid, Box, makeStyles} from '@material-ui/core';
import HeadingImage from '../../../../../assets/images/branded-order-card-not-saved-logo.svg';
import {
  ContainerContent,
  SuccessDescription,
  Image,
  BoldText
} from './styles';

const useStyles = makeStyles({
  button: {
    marginTop: 20,
  },
  buttonDescription: {
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '16px',
      textTransform: 'uppercase'
  },
  mainContainer: {
    position: `absolute`,
    top: 0,
    width: `100%`,
    height: '100%',
    background: `linear-gradient(180deg, #FFFFFF 35.79%, rgba(255, 255, 255, 0.822917) 100%)`,
    'z-index': `999999`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export const NotSaved: FC<any> = (props) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(props.open || false);

  const boldSubText = `Your Order Card changes will not update `;
  const subText = `and won’t be seen by your customers if you close without saving.`;

  return (
    <React.Fragment>
      {opened && (
        <Container className={classes.mainContainer} >
          <ContainerContent>
            <Box width={290}>
              <Grid container alignItems={'center'} justify="flex-start">
                <Grid item xs={12}>
                  <Image src={HeadingImage} />
                </Grid>
                <Grid item xs={12} className={classes.button}>
                  <BoldText>UH-OH! YOUR CHANGES<br />HAVE NOT BEEN SAVED</BoldText>
                </Grid>
                <Grid item xs={12}>
                  <SuccessDescription>
                    <Bold>{boldSubText}</Bold>
                    {subText}
                  </SuccessDescription>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.buttonDescription}>Would you like to:</span>
                </Grid>
                <Grid item xs={12}>
                  <Button width={240} hoverBgColor={'14869F'} onClick={() => {
                    setOpened(false);
                    props.setKeepDrawerOpen(false);
                  }} className={classes.button}>Go Back to Editing</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button width={240} bgColor = {'#f7f7f7'} textColor={'#333333'} hoverBgColor={'14869F'} onClick={props.closeDrawer} className={classes.button}>Close Without Saving</Button>
                </Grid>
              </Grid>
            </Box>
          </ContainerContent>
        </Container>
      )}
    </React.Fragment>
  )
};
