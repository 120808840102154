import React, { Component } from "react";
import AddIcon from '@material-ui/icons/Add';
import "./MultiStepsCompanySetting.scss";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import CTAButton from "../CTAButton/CTAButton";
import MultiStep from "./MultiStep/MultiStep";
import merchantCreation from '../../../../assets/images/merchant-creation.png';
import { uploadStoreLogo, uploadStoreHero, getMerchant, updateMerchantRequest } from "../../../../services/merchant";

class MultiStepsCompanySetting extends Component {

    state = {
      currentStep: 1,
      linkToWebsite: '',
      files: [],
    };

    componentDidMount() {
      const merchant = getMerchant();
      const steps = [];

      if (steps.length > 0) {
        this.setState({
          files: steps,
          currentStep: steps.length + 1
        });
      }

      /*this.setState({
        linkToWebsite: merchant.store_domain
      }); */
    }
    
	handleDrop = async (files, currentStep) => {
	  let fileList = this.state.files;
	  let file = files[0];

	  if (!file.name) return;

	  if (!file.type.match("image.*")) {
	    return;
	  }

	  let reader = new FileReader();

	  reader.onload = (theFile => {
	    return (e) => {
	      fileList[currentStep] = {
	        name: theFile.name,
	        size: theFile.size,
	        type: theFile.type,
	        src: e.target.result,
	      };

	      this.setState({ files: fileList });
	      this.handleUpload(fileList[currentStep], currentStep);
	    };
	  })(file);

	  reader.readAsDataURL(file);
	};

    handleNextStep = () => {
      const { currentStep } = this.state;

      if (currentStep >= 0 && currentStep <= 3) {
        this.setState({ currentStep: currentStep + 1 });

        if (currentStep === 2) {
          this.onMultistepSuccess(true);
        }
      }
    };

    handleCurrentStep = (step) => {
      this.setState({ currentStep: step });
    };

    handleChange = e => {
      const { value, id } = e.target;
      this.setState({
        [id]: value,
      });
    };
    
    handleUpload = (media, currentStep) => {
      const file = media.src;
      try {
        if (currentStep === 0) {
          uploadStoreLogo(file);
        } else if (currentStep === 1) {
          uploadStoreHero(file);
        }
      } catch (err) {
        alert(err);
      }
    };

    handleWebsiteLink = (e) => {
      updateMerchantRequest("store_domain", e.target.value);
    };

    bytesToSize = bytes => {
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };
    
    renderFileInfo = step => {
      const { currentStep, files } = this.state;

      if ((step +1 ) !== currentStep ) {
        return;
      }

      return (
        <div>
          <div className="file-info">
            <div className={`file-info-image-container  ${(step + 1) === 2 ? 'cover' : ''}`}>
              { files[step] ? (
                <img src={files[step].src} alt="Logo" className={`file-info-image`}/>
              ) : ''}
            </div>
            <div className="file-info-container">
              <h4 className="file-info-title">
                        Add Above
              </h4>
              <h4 className="file-info-filesize">{ !files[step] ? '0 MB' : this.bytesToSize(files[step].size) }</h4>
            </div>
                    
          </div>
          <CTAButton href="#" text={`${!files[step] ? 'Continue' : 'Confirm'}`} styleName={`${!files[step] ? 'light' : 'primary'}`} onClick={this.handleNextStep}></CTAButton>
        </div>
      );
    };

    getStepStyleName = (step) => {
      const { currentStep, files, linkToWebsite } = this.state;
      let className = '';

      if (step === currentStep) {
        className = 'current';
      }

      if ((currentStep > step && files[step-1]) || (currentStep === 3 && linkToWebsite.length > 0) ) {
        className = 'filled';
      }

      return className;
    };

    onMultistepSuccess = () => {
      this.props.onMultistepSuccess(true);
    };

    render() {
      const { currentStep, files, linkToWebsite } = this.state;

      return (
        <div className="multisteps-company-settings-wrapper">
          <div className="multisteps-placeholder">
            <img src={merchantCreation} alt="Merchant Creation step" className="picture" width="248" />
            {files[0] && (<img src={files[0].src} className="logo" alt="Logo Multistep"/>)}
            {!files[0] && (
              <div>
                <div className="add-placeholder">
                  <AddIcon/>
                  <span>Add</span>
                </div>
                <span className="text text-logo">Logo</span>
              </div>
            )}
            {files[1] && (<img src={files[1].src} className="cover" alt="Cover Multistep"/>)}
            {!files[1] && files[0] && (<span className="text">Landing Page</span>)}
                   
          </div>
          <div className="multisteps-company-settings">
            <div className="steps">
              <div className={`step ${this.getStepStyleName(1)}`} onClick={() => { this.handleCurrentStep(1);}}></div>
              <div className={`step ${this.getStepStyleName(2)}`} onClick={() => { this.handleCurrentStep(2);}}></div>
              <div className={`step ${this.getStepStyleName(3)}`} onClick={() => { this.handleCurrentStep(3);}}></div>
            </div>
            <div className="multisteps">
              <MultiStep title="Add Company Logo" description="Recommended 500 x 500" step={1} currentStep={currentStep} currentLastStep={false} onClick={() => { this.handleCurrentStep(1);}}>
                <DragAndDrop handleDrop={this.handleDrop} currentStep={0}>
                  { this.renderFileInfo(0) }
                </DragAndDrop>
              </MultiStep>
              <MultiStep title="Add Cover Image" description="Recommended 1000 x 640" step={2} currentStep={currentStep} currentLastStep={false} onClick={() => { this.handleCurrentStep(2);}}>
                <DragAndDrop handleDrop={this.handleDrop} currentStep={1}>
                  { this.renderFileInfo(1) }
                </DragAndDrop>
              </MultiStep>
              <MultiStep title="Add Link to Website" step={3} currentStep={currentStep} currentLastStep={true} onClick={() => { this.handleCurrentStep(3);}}>
                <input type="url" placeholder="URL" id="linkToWebsite" className="input-field" value={linkToWebsite} onChange={this.handleChange} onBlur={this.handleWebsiteLink}></input>
              </MultiStep>
            </div>
          </div>
        </div>
      );
    }
}

export default MultiStepsCompanySetting;
