import styled from 'styled-components';

export const Container = styled.div<any>`
  font-family: Titillium Web;
  display: "block";
  width: "100%";
  background-color: "#F7F7F7";
  border-left: 0.5px solid #dddddd;
  margin: 30px 0 20px 20px;
  padding-left: 15px;
  height: 107px;
  input {
    margin: 10px 0 5px 0;;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding-left: 10px;
    color: #666666;
    width: 115px;
    height: 30px;
  }
`;

export const Title = styled.div<any>`
  margin-top: 18px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #333333;
`;

export const DescText = styled.div<any>`
  margin-bottom: 5px;
  width: 340px;
  font-weight: normal;
  font-size: 9px;
  line-height: 13px;
  color: #666666;
`;

export const SubText = styled.div<any>`
  font-size: 9px;
  line-height: 13px;
  color: #666666;
`;
