import React from 'react';
import CsvDownloader from 'react-csv-downloader';

import { Container, Button } from './ExportButton.styles';

type IExportButtonProps = {
  origin: 'claims' | 'orders';
  data: any;
};

const columnsClaims = [
  { displayName: 'Issue Date', id: 'created_at' },
  { displayName: 'Issue Id', id: 'id' },
  { displayName: 'Email', id: 'email' },
  { displayName: 'Route Order Number', id: 'route_order_number' },
  { displayName: 'Store Order ID', id: 'store_order_id' },
  { displayName: 'Store Order Number', id: 'store_order_number' },
  { displayName: 'Issue Status', id: 'issue_status' },
  { displayName: 'Amount Paid', id: 'revenues' },
];

const ExportButton: React.FC<IExportButtonProps> = ({ origin, data }: IExportButtonProps) => {
  const [csvData, setCsvData] = React.useState<any>();
  const [csvColumns, setCsvColumns] = React.useState<any>();

  const handleClaims = (data: any) => {
    const newData = data.map((item: any) => {
      item.route_order_number = item.fields[0].value; // Route Order Number
      item.store_order_id = item.fields[1].value; // Store Order ID
      item.store_order_number = item.fields[11].value; // Store Order Number
      return item;
    });
    return newData;
  };

  React.useEffect(() => {
    if (Object.entries(data).length > 0) {
      if (origin === 'claims') {
        const dataClaims = handleClaims(data);
        setCsvData(dataClaims);
        setCsvColumns(columnsClaims);
      }
    }
  }, [data]);

  return (
    <>
      {origin ? (
        <Container>
          <CsvDownloader datas={csvData} columns={csvColumns} separator="," filename={`${origin}.csv`}>
            <Button disabled={!csvData}>Download CSV</Button>
          </CsvDownloader>
        </Container>
      ) : null}
    </>
  );
};

export default ExportButton;
