import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Divider, Theme, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormRenderProps, Field } from 'react-final-form';

import {
  Button,
  RouteFormTextInput,
  Bold,
  RouteSelect,
  MenuItem,
  ErrorPopover,
} from '../../components';
import DetailsText from './details-text';
import { createRequiredValidator } from '../../validation/required';
import { RightArrow } from '../../media/right-arrow';
import { Validator, composeValidators, emailPatternValidator, phonePatternValidator } from '../../validation';
import ImageDropZone from '../../components/ImageDropzone'
import { FormData } from './../../store'

import countryObjectJson from '../../../../providers/countries.json';
import { State } from '../../../Onboarding-2/store';
import { dismissError } from '../../actions/setup-account';

const useInternalFormStyles = makeStyles((theme: Theme) => ({
  root: {},
  footer: {
    height: 245,
  },
  field: {
    marginTop: 25,
  },
  arrow: {
    transform: 'translateX(-50%)',
  },
  formContentsArrowWrapper: {
    marginTop: 47,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
    }
  },
  formContentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  formContents: {
    marginLeft: 15,
  },
  formHeading: {
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: '17px',
    textAlign: 'left',
    marginBottom: 25,
  },
  buttonDivider: {
    marginTop: 66,
  },
  button: {
    marginTop: 65,
    marginBottom: 75,
  },
  errorText: {
    color: 'white',
  },
  uploadImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  spanHeaderImages: {
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: '17px',
    textAlign: 'left',
    marginTop: 15,
    marginBottom: 0,
    flex: '0 0 100%',
    [theme.breakpoints.up("sm")]: {
      marginTop: 30,
      marginBottom: 5
    }
  },
}), { name: 'InternalForm' });

const typedCountryObject = countryObjectJson as Record<string, string>;

export type StateProps = {
  initialLockedEmail?: string;
};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
}

export type Props = StateProps & DispatchProps;

const emailRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your email.</Bold></div>);
const confirmPasswordRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please confirm your password.</Bold></div>);
const passwordRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your password.</Bold></div>);
const domainRequiredValidator = createRequiredValidator(<div>This field is required. <Bold>Please enter your store domain.</Bold></div>);
const requiredValidator = createRequiredValidator(<div></div>);

const confirmPasswordSameValidator: Validator<Partial<FormData>> = (value: unknown, allValues: Partial<FormData>) => {
  return value === allValues.password ? undefined : <div>This password does not match. <Bold>Please enter a matching password.</Bold></div>;
}

const countries = Object.keys(typedCountryObject).map((key) =>
  <MenuItem value={typedCountryObject[key]} key={typedCountryObject[key]} style={{ width: '238px' }}>
    {key}
  </MenuItem>
);

const domainExistsError = (
  <span>A store with this domain already exists. <Bold>Please enter a valid domain.</Bold></span>
);

function InternalForm(props: Props & FormRenderProps<FormData>) {
  const classes = useInternalFormStyles();
  const { handleSubmit, initialLockedEmail, invalid } = props;
  const loading = useSelector((state: State) => state.app.loading)
  const errorReason = useSelector((state: State) => state.app.brand.page.errorReason)
  const domainInputGridRef = useRef(null);
  const dispatch = useDispatch();

  const onLogoImageChange = (binaryStr: string) => {
    props.form.change('logoImage', binaryStr)
  }

  const onCoverImageChange = (binaryStr: string) => {
    props.form.change('coverImage', binaryStr)
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <DetailsText head="1: Setup Account" description="Complete the information below to claim your brand profile" />
      <Divider />
      <div className={classes.formContentsArrowWrapper}>
        <RightArrow className={classes.arrow} />
        <div className={classes.formContentsContainer}>
          <Grid container spacing={0} className={classes.formContents}>
            <span className={classes.formHeading}>Create User Account</span>
            <Grid item xs={12}>
              <RouteFormTextInput
                required
                disabled={!!initialLockedEmail}
                id="email"
                name="email"
                label="Email Address"
                fieldProps={{
                  validate: composeValidators<FormData>(
                    emailRequiredValidator,
                    emailPatternValidator,
                  ),
                  name: 'email',
                }}>
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                id="fullName"
                name="fullName"
                label="Full Name">
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                fieldProps={{
                  validate: composeValidators<FormData>(
                    phonePatternValidator
                  ),
                  name: 'phoneNumber'
                }}>
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                required
                id="password"
                type="password"
                name="password"
                label="Password"
                tip="Must have a minium of 8 characters"
                fieldProps={{
                  validate: composeValidators<FormData>(
                    requiredValidator,
                    passwordRequiredValidator
                  ),
                  name: 'password'
                }}
              >
              </RouteFormTextInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <RouteFormTextInput
                className={classes.field}
                required
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                tip="Must have a minium of 8 characters"
                fieldProps={{
                  name: 'confirmPassword',
                  validate: composeValidators<FormData>(
                    confirmPasswordRequiredValidator,
                    confirmPasswordSameValidator,
                  )
                }}>
              </RouteFormTextInput>
            </Grid>
          </Grid>
        </div>
      </div>

      <DetailsText head="2: Store Details" marginTop={32} />
      <Divider />
      <div className={classes.formContentsContainer} >
        <Grid container spacing={0} className={classes.formContents}>
          <Grid item xs={12} sm={6} ref={domainInputGridRef}>
            <RouteFormTextInput
              required
              overrideError={errorReason === "MerchantExists"}
              className={classes.field}
              id="domain"
              name="domain"
              label="Store Domain"
              fieldProps={{
                validate: domainRequiredValidator,
                name: 'domain'
              }}
            />
          </Grid>
          <ErrorPopover
            open={errorReason === "MerchantExists"}
            onClose={() => dispatch(dismissError())}
            anchorEl={domainInputGridRef.current}
            content={domainExistsError}
          />
          <Grid item xs={12} sm={6}>
            <RouteFormTextInput
              className={classes.field}
              id="storeName"
              name="storeName"
              label="Store Name"
              fieldProps={{
                validate: requiredValidator,
                name: 'storeName'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RouteSelect
              className={classes.field}
              validator={requiredValidator}
              name="country"
              label="Country"
            >
              {countries}
            </RouteSelect>
          </Grid>

          <div className={classes.uploadImages}>
            <span className={classes.spanHeaderImages}>Upload Images</span>
            <Field
              name="coverImage"
              validate={requiredValidator}
              render={() => (
                <ImageDropZone
                  onUpdateImage={onCoverImageChange}
                  title="Cover Image"
                  recommendation={{ w: 1000, h: 640 }}
                />
              )}
            />

            <Field
              name="logoImage"
              validate={requiredValidator}
              render={() => (
                <ImageDropZone
                  onUpdateImage={onLogoImageChange}
                  title="Logo Image"
                  recommendation={{ w: 500, h: 500 }}
                />
              )}
            />
          </div>
        </Grid>
      </div>

      <Divider className={classes.buttonDivider} />
      <Container>
        <Button
          className={classes.button}
          width={240}
          id="submit-button"
          disabled={invalid}
          loading={loading}
          type="submit"
        >
          {'Save & Continue to STEP 3'}
        </Button>
      </Container>
    </form>
  );
}

export default InternalForm