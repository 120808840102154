import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FieldRenderProps } from 'react-final-form';

import { colors } from '../constants/styles';

const useButtonStyles = makeStyles({
  root: {
    color: 'black',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    textTransform: 'none',
    minWidth: '175px',
    '&:not(.Mui-selected):hover': {
      backgroundColor: `${colors.darkBlue} !important`,
      color: 'white !important'
    }
  },
  selected: {
    backgroundColor: `${colors.blue} !important`,
    color: 'white !important',
  },
}, { name: 'ToggleButtonInput' });


export function ToggleButtonInput(props: any) {
  const { children, value, ...restProps } = props;
  const classes = useButtonStyles();
  return (
    <ToggleButton data-testid={value} value={value} {...restProps} classes={classes} disableRipple>
      {children}
    </ToggleButton>
  );
}

export type ToggleButtonGroupInputProps = {
  name: string;
  children: React.ReactNode;
}

export const ToggleButtonGroupInput: FC<ToggleButtonGroupInputProps> = (props) => {
  const { name, children } = props;

  return (
    <Field
      name={name}
    >
      {
        (renderProps: FieldRenderProps<any>) => (
          <ToggleButtonGroup
            exclusive
            value={renderProps.input.value}
            onChange={(_, value) => {
              if (value === null) {
                // We don't want to commit a null value because in this button group
                // there should always be one button selected at a time (and never zero)
                return;
              }
              renderProps.input.onChange(value);
            }}
          >
            {children}
          </ToggleButtonGroup>
        )
      }
    </Field>
  );
};
