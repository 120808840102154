import { createContext } from 'react';

const authContext = createContext({
  authenticated: false, // to check if authenticated or not
  loggedIn: false,
  user: {},
  brands: [],
  activeBrand: {},
  tier: false,
  merchantRole: false,
  initiateLogin: () => {},
  handleAuthentication: () => {},
  logout: () => {},
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
