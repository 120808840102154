import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  StateProps,
  DispatchProps,
  default as Portal,
} from './Portal';
import { State, ThunkDispatch } from '../Onboarding-2/store';
import { hideOnboardingModalActionCreator } from '../Onboarding-2/actions/congrats/congrats';

export const mapStateToProps = (state: State): StateProps => {
  const userData = state.app.userData;
  if (!userData.loggedIn) {
    return {
      onboardingDialogVersion: 'none',
    };
  }

  return {
    onboardingDialogVersion: !state.app.onboarding.showPortalModal ?
      'none' :
      userData.activeBrand.has_route_plus ?
        'routePlusEnabled' :
        'routePlusDisabled',
  };
}

export const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => {
  return {
    closeOnboardingDialog: () => dispatch(hideOnboardingModalActionCreator()),
    closeOnboardingDialogAndGoToAdminPage: () => {
      dispatch(hideOnboardingModalActionCreator());
      return dispatch(push('/admin'));
    }
  };
}

export const ConnectedPortal = connect(mapStateToProps, mapDispatchToProps)(Portal);
