import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 275px;
  padding: 32px;
  background-color: ${(props) => props.color};
  border-radius: 16px;
  text-align: left;
`;

export const Header = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 13px;
  color: ${colors.primaryColor};
`;

export const Description = styled.span`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.white};
`;

export const LineBreak = styled.hr`
  width: 100%;
  height: 1px;
  background: ${colors.white};
  opacity: 0.12;
`;
