import {Dispatch} from 'react';
import {connect} from 'react-redux';

import {State} from '../../store';
import {StateProps, DispatchProps, Congrats} from './congrats';

import {goToPortal} from '../../actions/congrats/congrats';
import {routePlusEnabled, routePlusSelfImplementationEnabled} from '../../model/merchant';

export const mapStateToProps = (state: State): StateProps => {
  const userData = state.app.userData;
  if (!userData.loggedIn) {
    throw new Error('Expected user to be logged in')
  }

  return {
    routePlusEnabled: routePlusEnabled(userData.activeBrand),
    routePlusShopifySelf: routePlusSelfImplementationEnabled(userData.activeBrand),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: () => {
      return dispatch(goToPortal())
    }
  };
};

export const ConnectedCongrats = connect(mapStateToProps, mapDispatchToProps)(Congrats);
