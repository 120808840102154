import React from 'react';
import Button from '@material-ui/core/Button';
import './Button.scss';

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className = '',
  disabled = false,
  hoverState = false,
  ...props
}) => (
  <Button
    className={`button ${className} ${hoverState ? 'button-hover-change' : ''}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {!isLoading ? text : (loadingText || text)}
  </Button>
);

export default LoaderButton;
