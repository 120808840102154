import React, {FC} from 'react';
import {FormControlLabel, FormControlLabelProps, Radio, RadioProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Field, FieldRenderProps} from 'react-final-form';
import clsx from 'clsx';
import {dataUri as radioCheckDataUri} from '../media/radio-check';

type RouteRadioProps<T> = {
  name: string;
  value: T;
  label: React.ReactElement;
  dataId?: string;
  parse?: (value: any, name: string) => any;
  format?: (value: any, name: string) => any;
  labelClasses?: FormControlLabelProps['classes'];
  radioClasses?: RadioProps['classes'];
  className?: string;
}

const useStyles = makeStyles({
  labelRoot: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  }
}, {name: 'RouteRadio'});

export function RouteRadio<T>(props: RouteRadioProps<T>) {
  const baseClasses = useStyles();
  const labelClasses = {
    ...props.labelClasses,
    root: `${baseClasses.labelRoot} ${props.labelClasses && props.labelClasses.root ? props.labelClasses.root : ''}`
  }

  const {name, value, label, parse, format} = props;

  return (
    <Field
      name={name}
      value={value}
      type="radio"
      parse={parse}
      format={format}
      render={(renderProps: FieldRenderProps<T>) => {
        return (
          <FormControlLabel
            className={props.className}
            checked={renderProps.input.checked}
            labelPlacement="end"
            label={label}
            classes={labelClasses}
            control={<StyledRadio dataId={props.dataId} classes={props.radioClasses}/>}
            value={renderProps.input.value}
            onChange={renderProps.input.onChange}
          />
        );
      }}
    />
  );
}

const useStyledRadioStyles = makeStyles({
  root: {
    padding: 0,
    marginRight: '15px'
  },
  radioRoot: {
    marginRight: '15px',
  },
  icon: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    border: '1px solid #CCCCCC',
  },
  checkedIcon: {
    backgroundColor: 'transparent',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      margin: 3,
      backgroundImage: `url(${radioCheckDataUri})`,
      content: '""',
    }
  },
}, {name: 'StyledRadio'});

function StyledRadio(props: RadioProps & {dataId?: string}) {
  const classes = useStyledRadioStyles();
  const {classes: propClasses, dataId, ...restProps} = props;

  return (
    <Radio
      data-testid={dataId}
      className={`${classes.root} ${propClasses && propClasses.root}`}
      classes={props.classes}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
      icon={<span className={classes.icon}/>}
      {...restProps}
    />
  );
}