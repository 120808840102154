import React from 'react';
import { Container } from './CardHeaderOutside.styles';

import { ICardHeaderOutsideProps } from './interface';

const CardHeaderOutside: React.FC<ICardHeaderOutsideProps> = ({ children }: ICardHeaderOutsideProps) => (
  <Container>
    {children}
  </Container>
);

export default CardHeaderOutside;
