import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {AccountCreationError} from './account-creation-error';
import {DispatchProps} from "../account-creation/account-creation-error";
import {goBack} from "../../actions/sign-in/password-reset-request";
import {startOnboarding} from '../../actions/welcome/welcome';

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onBack: () => dispatch(goBack()),
    tryAgain: () => dispatch(startOnboarding()),
  };
};

export const ConnectedAccountCreationError = connect(null, mapDispatchToProps)(AccountCreationError);
