import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, MetricTitle, MetricValue } from './MetricBox.styles';

import CircularGraphIcon from '../../../../assets/icons/circle-graph-icon.js';
import circularCheckbox from '../../../../assets/images/circular-checkbox-white.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected-white.svg';

import { IMetricBoxProps } from './interface';
import Loading from '../Loading';
import { percentage } from '../../../../utils/currency';

const MetricBox: React.FC<IMetricBoxProps> = ({
  text, value, color, hasGraph, hasCheck, hasPercentage, isChecked, handleCheck, isLoading, handleGraphPress, isActive, total,
}: IMetricBoxProps) => (<Container hasGraph={hasGraph}>
  {hasCheck
    ? (<div style={{ display: 'flex', flexDirection: 'column' }}>
      <Checkbox
        onChange={() => handleCheck()}
        checked={isChecked}
        icon={
          <img
            alt="checkbox icon image"
            srcSet={circularCheckbox}
            width={20}
          />
        }
        checkedIcon={
          <img
            alt="checkbox icon image"
            srcSet={circularCheckboxChecked}
            width={20}
          />
        }
        value="checked"
        color="primary"
      />
    </div>) : null}
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <MetricTitle>
      {text}
    </MetricTitle>
    <MetricValue color={color} hasGraph={hasGraph}>
      {isLoading
        ? (<Loading />)
        : hasPercentage ? `${value} (${percentage(value, total)}%)` : value}
    </MetricValue>
    <CircularGraphIcon
      isActive={isActive}
      circleColor={'#5DC8DB'}
      doOnClick={handleGraphPress} />
  </div>
</Container>);

export default MetricBox;
