import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Label } from './IconInfo.styles';

import { IIconInfoProps } from './interface';

const IconInfo: React.FC<IIconInfoProps> = ({ children, style }: IIconInfoProps) => {
  const [active, setActive] = useState(false);

  const handleClickAway = (): void => {
    setActive(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Label style={style} onClick={() => setActive(!active)} active={active}>
        {children || (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="7" width="2" height="5" rx="1" fill="white" />
            <rect x="7" y="4" width="2" height="2" rx="1" fill="white" />
          </svg>
        )}
      </Label>
    </ClickAwayListener>
  );
};

export default IconInfo;
