import React from 'react';
import './FormInput.scss';
import TextField from '@material-ui/core/TextField';
import propTypes from 'prop-types';

const FormInput = (props) => (
    <TextField
        {...props}
        onChange={props.onChange}
        InputProps={{
          disableUnderline: true,
        }}
      />
);

FormInput.prototypes = {
  onChange: propTypes.func,
};

export default FormInput;
