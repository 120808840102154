import React from "react";
const SVG = ({
  style = {},
  fill = '#313222',
  className = '',
  width= '3px',
  height = '11px',
  viewBox = '0 0 3 11',
  transform = '',
}) =>
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill='none'
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<path d="M1.89054 7.18906C1.44039 7.18906 1.06815 6.83414 1.04218 6.38399L0.782477 1.80459C0.756507 1.31982 1.14606 0.904297 1.63083 0.904297H2.15024C2.63501 0.904297 3.02456 1.31116 2.99859 1.80459L2.73889 6.38399C2.71292 6.83414 2.34068 7.18906 1.89054 7.18906Z" fill="#F1373C"/>
<path d="M1.89019 10.2452C2.43522 10.2452 2.87705 9.80338 2.87705 9.25835C2.87705 8.71332 2.43522 8.27148 1.89019 8.27148C1.34516 8.27148 0.90332 8.71332 0.90332 9.25835C0.90332 9.80338 1.34516 10.2452 1.89019 10.2452Z" fill="#F1373C"/>
  </svg>;

export default SVG;

