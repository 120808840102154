export const DELIVERY_STATUS = {
  NotAvailable: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  NotActive: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Active: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  AttemptFail: {
    fill: 'rgba(202, 17, 106, 0.2)',
    stroke: '#CA116A',
    visible: true,
  },
  Pending: {
    fill: 'rgba(255, 201, 49, 0.2)',
    stroke: '#FFC931',
    visible: true,
  },
  InfoReceived: {
    fill: 'rgba(255, 201, 49, 0.2)',
    stroke: '#FFC931',
    visible: true,
  },
  AvailableForPickup: {
    fill: 'rgba(114, 142, 157, 0.2)',
    stroke: '#728E9D',
    visible: true,
  },
  OutForDelivery: {
    fill: 'rgba(152, 54, 119, 0.2)',
    stroke: '#983677',
    visible: true,
  },
  UnknownCarrier: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Registered: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Expired: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Unknown: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Exception: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Delivered: {
    fill: 'rgba(90, 200, 219, 0.2)',
    stroke: '#5DC8DB',
    visible: true,
  },
  InTransit: {
    fill: 'rgba(20, 134, 159, 0.2)',
    stroke: '#14869F',
    visible: true,
  },
  Cancelled: {
    fill: 'rgba(0, 0, 0, 0.2)',
    stroke: '#000',
    visible: false,
  },
  Lost: { // we have to confirm this status if the API is returning correctly
    fill: 'rgba(114, 142, 157, 0.2)',
    stroke: '#728E9D',
    visible: false,
  },
  Damaged: { // we have to confirm this status if the API is returning correctly
    fill: 'rgba(152, 54, 119, 0.2)',
    stroke: '#983677',
    visible: false,
  },
  Stolen: { // we have to confirm this status if the API is returning correctly
    fill: 'rgba(202, 17, 106, 0.2)',
    stroke: '#CA116A',
    visible: false,
  },
};
