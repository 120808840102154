import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import webOutline from '../../../../../assets/images/branded-order-web-view.png';
import webContent from '../../../../../assets/images/browser-image.svg';
import placeholder from '../../../../../assets/images/branded-order-card-placeholder.svg';
import { WebView, Image, CoverImage, Store } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '520px',
    overflow: 'hidden',
  },
  imagePlaceholder: {
    position: 'absolute',
    top: '269px',
    right: '75px',
    borderRadius: '8px',
    width: '124px',
    height: '124px',
    objectFit: 'cover',
  },
}));

export const WebPreview: FC<any> = (props) => {
  const classes = useStyles(props);
  const [brandedOrderFeaturedImage, setBrandedOrderFeaturedImage] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeLogo, setStoreLogo] = useState('');
  const [storeHero, setStoreHero] = useState('');

  useEffect(() => {
    if (props?.brandedOrderFeaturedImage !== '') {
      setBrandedOrderFeaturedImage(props.brandedOrderFeaturedImage);
    }
    if (props?.storeName !== '') {
      setStoreName(storeName);
    }
    if (props?.storeLogo !== '') {
      setStoreLogo(props.storeLogo);
    }
    if (props?.storeHero !== '') {
      setStoreHero(props.storeHero);
    }
  }, [props]);

  return (
    <div className={classes.root}>
      {storeHero ? <CoverImage src={props.storeHero} /> : <CoverImage id="Cover-image" />}
      <WebView src={webOutline} alt="Web Tracking Page View" />
      <Store />
      {brandedOrderFeaturedImage ? (
        <img src={brandedOrderFeaturedImage} className={classes.imagePlaceholder} />
      ) : (
        <img className={classes.imagePlaceholder} src={placeholder} />
      )}
      <Image src={webContent} alt="Web Tracking Page View" />
    </div>
  );
};
