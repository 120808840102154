import React, {FC} from 'react';
import {Grid, Theme, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Field} from 'react-final-form';

import {ToggleButtonGroupInput, ToggleButtonInput} from '../../../components';

import {CreditCardMethod} from './credit-card';
import {BankAccountMethod} from './bank-account';
import {formFieldNames, testIds} from '../types';
import {RightArrow} from '../../../media/right-arrow';
import stripeLogo from '../../../media/stripe-logo.svg';

type ConditionProps<T> = {
  when: string;
  is: T;
  children: React.ReactElement;
};
function Condition<T>({ when, is, children }: ConditionProps<T>) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    marginTop: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrow: {
    marginRight: '14px',
  },
  mainContent: {
    maxWidth: '554px',
    // This is a hack to make this specific enough to override other rules
    // It just uses the class name twice which is specific enough
    '&&': {
      paddingLeft: '26px !important',
    }
  },
  stripeHeading: {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '14px',
    color: 'black',
  },
  stripeCaption: {
    marginTop: '4px',
    fontSize: '10px',
    lineHeight: '14px',
    color: 'black',
  }

}), {name: 'CollectionMethod'});

export type CollectionMethodProps = {};

export const CollectionMethod: FC<CollectionMethodProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container item data-testid={testIds.collectionMethodSection}>
      <Grid item xs={12} className={classes.sectionHeader}>
        <RightArrow className={classes.arrow}/>
        <Typography variant="subtitle2">A. PREMIUMS COLLECTION METHOD</Typography>
      </Grid>
      <Grid container item xs={12} className={classes.mainContent} style={{marginTop: '45px'}}>
        <Grid item xs={12}>
          <ToggleButtonGroupInput name={formFieldNames.collectionMethod}>
            <ToggleButtonInput value="bank">
              Bank Account
            </ToggleButtonInput>
            <ToggleButtonInput value="cc">
              Credit Card
            </ToggleButtonInput>
          </ToggleButtonGroupInput>
        </Grid>
        <Grid item xs={12} style={{marginTop: '48px'}}>
          <div className={classes.stripeHeading}>PROTECTED WITH <img src={stripeLogo} style={{marginLeft: '2px'}}/></div>
          <div className={classes.stripeCaption}>ACH through Stripe is currently only supported for businesses based in the U.S.</div>
        </Grid>
        <Grid item xs={12} style={{marginTop: '25px', marginBottom: '45px'}}>
          <Condition when={formFieldNames.collectionMethod} is="bank">
            <BankAccountMethod/>
          </Condition>
          <Condition when={formFieldNames.collectionMethod} is="cc">
            <CreditCardMethod/>
          </Condition>
        </Grid>
      </Grid>
    </Grid>
  );
};