import React from 'react';
import {ValidatorWithoutOtherValues} from '.';

export function createRequiredValidator(message: string|React.ReactFragment) {
  const validator: ValidatorWithoutOtherValues<unknown> = (value: unknown) => {
    if (value !== undefined && typeof value === 'string') {
      return value === '' ? message : undefined;
    }

    return value === undefined ? message : undefined;
  };
  return validator;
}
