import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { ProtectedRouteProps } from './interface.d';

export const ProtectedRoute: React.FC<any> = ({
  isAuthenticated,
  isAllowed,
  restrictedPath,
  authenticationPath,
  ...props
}: any) => {
  let redirectPath = '';
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  }
  if (isAuthenticated && !isAllowed) {
    redirectPath = restrictedPath;
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }
  return <Route {...props} />;
};

export default ProtectedRoute;
