import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import colors from '../../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  componentContainer: {},
  pickerContainer: {
    display: 'inline-block',
    justifyContent: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    alignItems: 'center',
    padding: '0 20px 0 20px',
    height: '32px',
    display: 'inline-flex',
  },
  pickerLink: {
    height: '32px',
    margin: '0',
    fontSize: '14px',
    color: '#666666',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    fontWeight: '600',
    cursor: 'pointer',
    display: 'flex',
  },
  activeNav: {
    border: '0',
    background: colors.primaryColor,
    color: '#FFF',
  },
  title: {
    fontSize: '32px',
    color: '#313942',
  },
  pageHeader: {
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#666',
    textDecoration: 'none',
    fontWeight: '600',
    margin: '20px',
  },
}));

const Picker = (props) => {
  const [selected, setSelected] = useState((props && props.entries && props.entries[0]) || {});
  const classes = useStyles(props);

  /** expecting object in props like {entries:[{pathname:"billing", pageName:"Invoices"}]} */
  return (
    <div className={classes.componentContainer}>
      <div className={classes.pageHeader}>{props.header}</div>
      <div className={classes.pickerContainer}>
        {props.entries.map((entry, index) => (
          <div
            key={index}
            className={classNames(classes.pickerLink, selected.value === entry.value ? classes.activeNav : '')}
            onClick={() => {
              // Google Analytics - Track time picker change
              ReactGA.event({ category: 'Claims - All Claims', action: 'Date filter click', label: `${entry.value}` });
              setSelected(entry);
              props.setSelectedValue(entry.value);
            }}
          >
            <span>{entry.displayName}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Picker;
