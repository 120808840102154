import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './UpdatePaymentMethod.scss';
import circularCheckbox from '../../../../assets/images/circular-checkbox.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected.svg';

import BankIcon from '../../../../assets/icons/bank-account-icon';
import CardIcon from '../../../../assets/icons/credit-card-icon';
import CloseIcon from '../../../../assets/icons/close';
import Checkbox from '@material-ui/core/Checkbox';

import AddPaymentCardModal from '../../../_shared/AddBillingMethod/AddCard/AddPaymentCardModal';
import AddBankAccountModal from '../../../_shared/AddBillingMethod/AddBank/AddBankAccountModal';
import { Elements, StripeProvider } from 'react-stripe-elements';
import DrawerValidation, { validateEmail } from "./DrawerValidation";
import { updateMerchantContact } from '../../../../services/merchant';

import {
  STRIPE_PUBLIC_API_KEY,
} from '../../../../constants';
import BillingVideoModal from '../BillingVideoModal/BillingVideoModal';
import TitleAndNav from './TitleAndNav';
import { Box } from '@material-ui/core';
class UpdatePaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingVideoModalOpen: false,
      paymentCardModalOpen: false,
      bankModalOpen: false,
      billingSuccess: false,
      skipClicked: false,

      billingEmail: props.billingEmail,
      isEmailWarningDisplayed: false,
      isEmailInvalid: !validateEmail(props.billingEmail),
      isEmailBlank: !(props.billingEmail && props.billingEmail.length),
    };
  }

  handleOpenBankModal = () => {
    if (this.props.understandChecked) {
      this.setState({ bankModalOpen: true });
    } else {
      this.setState({
        bankModalOpen: false,
        alert: true,
      });
    }
  };
  handleCloseBankModal = () => this.setState({ bankModalOpen: false });
  handleOpenCardModal = () => {
    if (this.props.understandChecked) {
      this.setState({ paymentCardModalOpen: true });
    } else {
      this.setState({
        paymentCardModalOpen: false,
        alert: true,
      });
    }
  };
  handleCloseCardModal = () => this.setState({ paymentCardModalOpen: false });
  handleOpenBillingVideoModal = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Billing video link click' });
    this.setState({ billingVideoModalOpen: true });
  }
  handleCloseBillingVideoModal = () =>
    this.setState({ billingVideoModalOpen: false });

  handleBillingSuccess = () => {
    this.setState({ billingSuccess: true });
  }

  handleEmailChange = (e) => {
    this.setState({
      billingEmail: e.target.value,
      isEmailWarningDisplayed: false,
      isEmailInvalid: !validateEmail(e.target.value),
      isEmailBlank: !e.target.value.length,
    });
  }

  closeEmailError = () => {
    this.setState({
      isEmailWarningDisplayed: false,
    });
  };

  calculateEmailError = () => {
    this.setState({
      isEmailWarningDisplayed: this.state.isEmailBlank || this.state.isEmailInvalid,
    });
  };

  handleConnectBankClicked = async () => {
    await updateMerchantContact({
      email: this.state.billingEmail,
      phone: '',
      link: '',
      slug: 'billing_contact',
      merchantId: this.props.userSession.activeBrand.id,
      token: this.props.userSession.activeBrand.prod_api_secret,
    });
    this.props.openBankAccountDrawer();
  }

  handleConnectCardClicked = async () => {
    await updateMerchantContact({
      email: this.state.billingEmail,
      phone: '',
      link: '',
      slug: 'billing_contact',
      merchantId: this.props.userSession.activeBrand.id,
      token: this.props.userSession.activeBrand.prod_api_secret,
    });
    this.props.openCreditCardDrawer();
  }

  render() {
    return (
      <div className='update-payment-method'>
        <TitleAndNav close={this.props.closeDrawer} title='Payment Method' />
        <div className='update-payment-method-upper-text'>
          <p>
            Connect your bank account in order to remit insurance premiums
            you have collected from your customers, on behalf of Route.
          </p>
        </div>
        <a href="javascript: void(0)" className='billing-video' onClick={this.handleOpenBillingVideoModal}>Watch a short video on how billing works</a>
        <div className='update-payment-method-understand-checkbox'>
          {this.renderAddBillingModal}
          <Checkbox
            className="checkbox"
            checked={this.props.understandChecked}
            onChange={() => this.props.setUnderstandChecked(!this.props.understandChecked)}
            icon={
              <img alt='powered by stripe logo' srcSet={circularCheckbox} width="20" />
            }
            checkedIcon={
              <img
                alt='powered by stripe logo'
                srcSet={circularCheckboxChecked}
                width="20"
              />
            }
            value='checked'
            color='primary'
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
          />
          <p onClick={() => this.props.setUnderstandChecked(!this.props.understandChecked)}>I understand how billing <br />works and wish to proceed.</p>
        </div>
        <hr style={{
          margin: '0 30px',
          border: '0.5px solid #BBBBBB'
        }} />
        <div className='update-payment-method-upper-text'>
          <p>
            Provide a billing email for Route+ premium collections invoices and payment notifications.
          </p>
        </div>
        <div className='update-payment-method-upper-text'>
          <p style={{ color: '#F06350' }}>
            A valid billing email is required before you may continue.
          </p>
        </div>
        <div className='update-payment-method-upper-text' style={{ paddingBottom: '10px' }}>
          <div className='update-billing-contact-input-header' style={{ padding: '10px 0' }} >
            Billing Email
          </div>
          <input className='input-ss'
            id="email"
            name="billingEmail"
            placeholder="example@company.com"
            value={(this.state.billingEmail === null || this.state.billingEmail === undefined) ? this.props.billingEmail : this.state.billingEmail}
            onChange={this.handleEmailChange}
            onBlur={this.calculateEmailError}
          />
          {this.state.isEmailWarningDisplayed ? <DrawerValidation isEmail={true} isBlank={this.state.isEmailBlank} closeEmailError={this.closeEmailError} /> : ''}
        </div>
        <div className='update-payment-method-container'>
          <div className={this.props.understandChecked
            && !this.state.isEmailInvalid
            && !this.state.isEmailBlank
            ? 'update-payment-method-connect-account'
            : 'update-payment-method-connect-account inactive'}
            onClick={this.handleConnectBankClicked}
          >
            <span className='update-payment-method-icon-title-wrapper'>
              Connect a Bank Account
            </span>
          </div>
          <div className={this.props.understandChecked
            && !this.state.isEmailInvalid
            && !this.state.isEmailBlank
            ? 'update-payment-method-connect-card'
            : 'update-payment-method-connect-card inactive'}
            onClick={this.handleConnectCardClicked}
          >
            <span className='update-payment-method-icon-title-wrapper'>
              Connect Debit / Credit Card
            </span>
          </div>
        </div>
        <div className='update-payment-method-lower-text'>
          <p>
            <b>Please note</b> that Route has integrated with Stripe, an online
            payment solution application, to provide a <i>secure environment</i> in which
            you can connect your payment method.<br></br>
          </p>
          <Box mt="20px">
            <p>
              <i>Route does not store any sensitive billing information.</i>
            </p>
          </Box>
        </div>

        {this.state.billingVideoModalOpen ? (
          <BillingVideoModal
            open={this.state.billingVideoModalOpen}
            onRequestClose={this.handleCloseBillingVideoModal}
          />
        ) : (
            ''
          )}
        {this.state.paymentCardModalOpen ? (
          <AddPaymentCardModal
            onBillingSuccess={this.handleBillingSuccess}
            onRequestClose={this.handleCloseCardModal}
          />
        ) : (
            ''
          )}
        {this.state.bankModalOpen ? (
          <StripeProvider apiKey={STRIPE_PUBLIC_API_KEY}>
            <Elements>
              <AddBankAccountModal
                onBillingSuccess={this.handleBillingSuccess}
                onRequestClose={this.handleCloseBankModal}
              />
            </Elements>
          </StripeProvider>
        ) : (
            ''
          )}
      </div>
    );
  }
}

export default UpdatePaymentMethod;
