import React, { Component } from "react";
import "./AccountCreationForm.scss";
import Form from '../../_shared/Form/Form';
import SubmitButton from "../../_shared/SubmitButton/SubmitButton";
import {
   createUser,
   linkMerchant,
   getCollaborator,
   updateCollaborator
} from "../../../../services/merchant";
import platforms from "../../../../providers/platforms.json";
import currencies from "../../../../providers/currencies.json";
import countries from "../../../../providers/countries.json";
import PhoneInput from 'react-phone-input-2';
import FormItem from "../../_shared/Form/FormItem/FormItem";
import FormInput from "../../_shared/Form/FormInput/FormInput";
import FormRow from "../../_shared/Form/FormRow/FormRow";
import { loginUser } from "../../../../services/api";
import PasswordIssueModal from "./PasswordIssueModal/PasswordIssueModal";

class AccountCreationForm extends Component {
	state = {
		merchantName: "",
		email: "",
		merchantDomain: "",
		phoneNumber: "",
		merchantCountry: "",
		merchantStoreCurrency: "",
		platform: "api",
		fullName: "",
		password: "",
		confirmPassword: "",
		token: "",
		save: true,
		saving: false,
		saved: false,
		error: false,
		errorMessage: "",
		linkAccount: false,
    showPlatformSelector: true,
    userExistsModal: false,
    loading: false,
    };

    componentWillReceiveProps(props) {
        if (props.query.platform && Object.values(platforms).find((v) => v === props.query.platform)) {
            this.setState({
                showPlatformSelector: false,
                platform: props.query.platform
            });
        }

        if (props.query.currency && Object.values(currencies).find((v) => v === props.query.currency)) {
            this.setState({
                merchantStoreCurrency: props.query.currency
            });
        }

        if (props.query.country && Object.values(countries).find((v) => v === props.query.country)) {

            this.setState({
                merchantCountry: props.query.country
            });
        }

        const prePopulate = Array.from(Object.keys(props.query), k=>[`${k}`, props.query[k]]);
        let obj = {};

        for (const populate of prePopulate) { // eslint-disable-line no-unused-vars
            if (populate[0] === "merchantDomain"
                || populate[0] === "merchantName"
                || populate[0] === "email"
                || populate[0] === "token") {
                obj[populate[0]] = populate[1];
                this.setState(obj);
            }
        }
    }

	createOptionList = (list, exclude = [], defaultValue = null) => {
        let returning = [];
		for (const prop in list) {
            if (!exclude.includes(prop)) {
                returning.push(
                    <option key={prop} value={list[prop]} selected={defaultValue === prop}>
                        {prop}
                    </option>
                );
            }
		}
		return returning;
  };

  clearPasswordInputs = () => {
    this.setState({
      userExistsModal: false
    });
  };

	handleChange = e => {
		const { value, id } = e.target;
		this.setState({
			[id]: value
		});
    };

    handleChangePhoneInput = value => {
        this.setState({
            phoneNumber: value
        });
    }

	handleSubmit = async e => {
		const {
			merchantName,
			email,
			merchantDomain,
			phoneNumber,
			platform,
			merchantStoreCurrency,
			merchantCountry,
			fullName,
			password,
      confirmPassword,
      token
		} = this.state;

        e.preventDefault();

    this.setState({ loading: true });

		if (password !== confirmPassword) {
      alert("Passwords don't match");
      this.setState({ loading: false });
			return;
		}

		try {
			let response = await createUser(
				fullName,
				password,
				email,
				platform,
				phoneNumber
      );

      if (response.statusCode === 409 && response.status !== 'success') {
        let loginUserResponse = await loginUser(email, password);

        if (loginUserResponse && loginUserResponse.user.id) {
          updateCollaborator({
            ...loginUserResponse.user,
            collaboratorId: loginUserResponse.user.id
          });
        } else {
          this.setState({ userExistsModal: true, loading: false });
          return;
        }
      }

      const collaborator = getCollaborator();
      let merchant = {
          store_name: merchantName,
          store_domain: merchantDomain,
          platform_id: platform,
          country: merchantCountry,
          currency: merchantStoreCurrency,
          source: platform,
      };

      if (token !== "") {
          const linkMerchantResp = await linkMerchant(collaborator.collaboratorId, token);

          if (linkMerchantResp && linkMerchantResp.merchant_id) {
            merchant = { ...merchant, id: linkMerchantResp.merchant_id };
          }
      }

      response = {};

      if (response.status === "success") {
        let resp = await loginUser(email, password);
        this.props.setUserSession({loggedIn: true, user: resp.user, brands: resp.brands, activeBrand: resp.brands[0]});
        sessionStorage.setItem('uToken', resp.user.token);
        sessionStorage.setItem('uId', resp.user.id);
        sessionStorage.setItem('bId', resp.brands[0].id);

        this.props.onRequestCompleted();
      } else {
        alert(response.message);
        this.setState({ loading: false });
        return;
      }
		} catch (err) {
      console.log(err);
      this.setState({ loading: false });
			alert("There was an error creating your account. Please contact support at support@route.com");
		}
    };
    renderForm() {
        const {
            fullName,
			merchantStoreCurrency,
            platform,
            email,
            merchantName,
            merchantDomain,
            merchantCountry,
            userExistsModal,
            token,
            loading
		} = this.state;
		return (
            <Form
                styleName="account-creation-form"
                method="POST"
                onSubmit={this.handleSubmit}>
                <fieldset>
                    <legend>Store Details</legend>
                    <FormRow spacing={4}>
                        <FormItem md={6}>
                            <label>Store Name</label>
                            <FormInput
                                type="text"
                                pattern="(.*)\.(.*)"
                                name="merchantName"
                                id="merchantName"
                                placeholder=""
                                value={merchantName}
                                onChange={this.handleChange}
                                required
                            ></FormInput>
                        </FormItem>
                        <FormItem md={6}>
                            <label>Store Domain</label>
                            <FormInput
                                type="text"
                                name="merchantDomain"
                                id="merchantDomain"
                                placeholder=""
                                onChange={this.handleChange}
                                value={merchantDomain}
                                required
                            ></FormInput>
                        </FormItem>
                    </FormRow>
                    <FormRow spacing={2}>
                        <FormItem md={6}>
                            <label>Country</label>
                            <div className="select-container">
                                <select
                                    name="country"
                                    id="merchantCountry"
                                    onChange={this.handleChange}
                                    value={merchantCountry}
                                    required
                                >
                                    <option value="">Country</option>
                                    {this.createOptionList(countries)}
                                </select>
                            </div>
                        </FormItem>
                        <FormItem md={6}>
                            <label>Store Currency</label>
                            <div className="select-container">
                                <select
                                    name="currency"
                                    onChange={this.handleChange}
                                    id="merchantStoreCurrency"
                                    value={merchantStoreCurrency}
                                    required
                                >
                                    <option value="">Store Currency</option>
                                    {this.createOptionList(currencies)}
                                </select>
                            </div>
                        </FormItem>
                    </FormRow>
                </fieldset>
                <fieldset style={{marginTop: '-20px', marginBottom: 0}}>
                    <legend>Account Details</legend>
                    <FormRow spacing={2}>
                        <FormItem md={4}>
                            <label>Full Name</label>
                            <FormInput
                                type="text"
                                name="fullName"
                                id="fullName"
                                placeholder=""
                                onChange={this.handleChange}
                                value={fullName}
                                required
                            ></FormInput>
                        </FormItem>
                        <FormItem md={4}>
                            <label>Email Address</label>
                            <FormInput
                                type="email"
                                name="email"
                                id="email"
                                placeholder=""
                                onChange={this.handleChange}
                                value={email}
                                required
                            ></FormInput>
                        </FormItem>
                        <FormItem md={4}>
                            <label>Phone</label>
                            <PhoneInput
                                defaultCountry={'us'}
                                inputClass='input-phone'
                                buttonClass='button-input-phone'
                                value={this.state.phoneNumber}
                                onChange={this.handleChangePhoneInput}
                                required
                                inputExtraProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true

                                }}
                            />
                        </FormItem>
                    </FormRow>
                    <FormRow spacing={2}>
                        <FormItem md={6}>
                            <label>Password</label>
                            <FormInput
                                type="password"
                                name="password"
                                id="password"
                                placeholder=""
                                onChange={this.handleChange}
                                required
                            ></FormInput>
                        </FormItem>
                        <FormItem md={6}>
                            <label>Confirm Password</label>
                            <FormInput
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder=""
                                onChange={this.handleChange}
                                required
                            ></FormInput>
                        </FormItem>
                    </FormRow>
                    <SubmitButton
                        text="Create Account"
                        styleName="light"
                        disabled={loading}
                    ></SubmitButton>
                    <PasswordIssueModal openModal={userExistsModal} email={email} publicToken={token} onResetInput={this.clearPasswordInputs} />
                </fieldset>
            </Form>
		);
    }

	render() {
		const {
			merchantStoreCurrency,
            platform,
            email,
            merchantName,
            merchantDomain,
            merchantCountry,
            showPlatformSelector
        } = this.state;

        return this.renderForm();
	}
}

export default AccountCreationForm;


