import styled, { css } from 'styled-components';
import Colors from '../../../../constants/colors';

export const Container = styled.div<any>`
  display: flex;
  position: relative;
`;

export const Button = styled.button<any>`
  display: flex;
  align-self: center;

  width: fit-content;
  height: 30px;

  padding: 6px 16px;
  background: #5dc8db;
  background: ${(props) => (props.disabled ? Colors.gray : '#5dc8db')};
  border: none;
  border-radius: 16px;

  color: #ffffff;
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;
