import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Toggle = styled.div`
  #switch {
    position: relative;
    width: 36px;
    height: 20px;
    width: 36px;
    height: 20px;
    border-radius: 20px;
    -webkit-transition: border-color 0.6s ease-out;
    transition: border-color 0.6s ease-out;
    box-sizing: border-box;

    cursor: pointer;
  }

  .toggle {
    border: 2px solid #dddddd;

    border-radius: 20px;
    -webkit-transition: border-color 0.6s ease-out;
    transition: border-color 0.6s ease-out;
    box-sizing: border-box;
    background: #dddddd;
  }

  .toggle.toggle-on {
    background: ${colors.primaryColor};
    border-radius: 50px;

    border: 2px solid ${colors.primaryColor};
    -webkit-transition: all 0.5s 0.15s ease-out;
    transition: all 0.5s 0.15s ease-out;
  }

  .toggle-button {
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: all 0.3s 0.1s, width 0.1s, top 0.1s, bottom 0.1s;
    transition: all 0.3s 0.1s, width 0.1s, top 0.1s, bottom 0.1s;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    padding: 0;

    position: absolute;
    left: 0;
  }

  .toggle-on .toggle-button {
    right: 0;
    top: 0;
    left: auto;
    border-radius: 23px;
    -webkit-transition: all 0.2s 0.1s, right 0.1s;
    transition: all 0.2s 0.1s;
  }

  .toggle-text-on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 36px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    font-weight: normal;
    cursor: pointer;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    color: rgba(0, 0, 0, 0);
  }

  /* used for streak effect */
  .glow-comp {
    position: absolute;
    opacity: 0;
    border-radius: 6px;
    background-color: rgba(75, 122, 141, 0.1);
    box-shadow: 0 0 12px rgba(75, 122, 141, 0.2);
    -webkit-transition: opacity 4.5s 1s;
    transition: opacity 4.5s 1s;
  }

  .toggle-on .glow-comp {
    opacity: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  }

  .toggle-disabled {
    opacity: 0.6;
  }
`;

export default {
  Toggle,
};
