import {push} from 'connected-react-router';
import {State, ThunkDispatch} from '../../store';
import {updateActiveMerchant, UPDATE_ACTIVE_MERCHANT_SUCCEEDED} from '../user-data/merchants';
import {captureException, addBreadcrumb, Severity} from '@sentry/browser';

export const THANK_YOU_PAGE_SUBMIT_STARTED = 'THANK_YOU_PAGE_SUBMIT_STARTED' as const;
export interface ThankYouPageSubmitStartedAction {
  type: typeof THANK_YOU_PAGE_SUBMIT_STARTED;
}

export function thankYouPageSubmitStarted(): ThankYouPageSubmitStartedAction {
  return {
    type: THANK_YOU_PAGE_SUBMIT_STARTED,
  };
}

export const THANK_YOU_PAGE_SUBMIT_SUCCEEDED = 'THANK_YOU_PAGE_SUBMIT_SUCCEEDED' as const;
export interface ThankYouPageSubmitSucceededAction {
  type: typeof THANK_YOU_PAGE_SUBMIT_SUCCEEDED;
}

export function thankYouPageSubmitSucceeded(): ThankYouPageSubmitSucceededAction {
  return {
    type: THANK_YOU_PAGE_SUBMIT_SUCCEEDED,
  };
}

export const THANK_YOU_PAGE_SUBMIT_FAILED = 'THANK_YOU_PAGE_SUBMIT_FAILED' as const;
export interface ThankYouPageSubmitFailedAction {
  type: typeof THANK_YOU_PAGE_SUBMIT_FAILED;
  reason?: string;
}

export function thankYouPageSubmitFailed(reason?: string): ThankYouPageSubmitFailedAction {
  return {
    type: THANK_YOU_PAGE_SUBMIT_FAILED,
    reason,
  };
}

export type ThankYouPageData = {
  checkTrace: boolean;
}

export const continueToPremiums = (data: ThankYouPageData) => {
  data = {
    ...data,
  };

  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Starting "Thank You Page" action',
        level: Severity.Info,
      });

      dispatch(thankYouPageSubmitStarted());

      const {userData} = getState().app;

      if (!userData.loggedIn) {
        throw new Error('Expected user to be logged in');
      }
      const finalAction = await dispatch(updateActiveMerchant({
        id: userData.activeBrand.id,
        prod_api_secret: userData.activeBrand.prod_api_secret,
        merchant_preferences: {
          ...userData.activeBrand.merchant_preferences,
          asset_live: data.checkTrace,
        }
      }));

      if (finalAction.type !== UPDATE_ACTIVE_MERCHANT_SUCCEEDED) {
        throw new Error('Merchant Update Failed');
      }
      
      addBreadcrumb({
        message: '"Thank You Page" successful',
        data: {
          routePlusEnabled: false,
        },
        level: Severity.Info,
      });
      dispatch(thankYouPageSubmitSucceeded());
      return dispatch(push('/onboarding/transfer-premiums'))
    } catch(err) {
      captureException(err);
      const message: string|undefined = err && typeof err.message === 'string' ? err.message : undefined;
      dispatch(thankYouPageSubmitFailed(message));
    }
  }
}
