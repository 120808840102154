export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR' as const;
export interface ClearUserErrorAction {
  type: typeof CLEAR_USER_ERROR;
}

export function clearUserErrorActionCreator() {
  return {
    type: CLEAR_USER_ERROR,
  }
}

export const SHOW_USER_ERROR = 'SHOW_USER_ERROR' as const;
export interface ShowUserErrorAction {
  type: typeof SHOW_USER_ERROR;
}

export function showUserErrorActionCreator() {
  return {
    type: SHOW_USER_ERROR,
  }
}
