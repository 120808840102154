import {captureException} from '@sentry/browser';

import {ThunkDispatch, State} from '../../store';
import {updateOnboardingStatusForMerchant, ONBOARDING_STATUS_UPDATE_FAILED} from '../onboarding/status';
import {OnboardingStatus } from '../../api/types';
import { push } from 'connected-react-router';
import { updateActiveMerchant, UPDATE_ACTIVE_MERCHANT_FAILED } from '../user-data/merchants';
import { finishOnboarding } from '../congrats/congrats';

export const SKIP_PREMIUMS_STARTED = 'SKIP_PREMIUMS_STARTED' as const;
export interface SkipPremiumsStartedAction {
  type: typeof SKIP_PREMIUMS_STARTED;
}

export function skipPremiumsStartedActionCreator(): SkipPremiumsStartedAction {
  return {
    type: SKIP_PREMIUMS_STARTED,
  };
}

export const SKIP_PREMIUMS_SUCCEEDED = 'SKIP_PREMIUMS_SUCCEEDED' as const;
export interface SkipPremiumsSucceededAction {
  type: typeof SKIP_PREMIUMS_SUCCEEDED;
}

export function skipPremiumsSuccessfulActionCreator(): SkipPremiumsSucceededAction {
  return {
    type: SKIP_PREMIUMS_SUCCEEDED,
  };
}

export const SKIP_PREMIUMS_FAILED = 'SKIP_PREMIUMS_FAILED' as const;
export interface SkipPremiumsFailedAction {
  type: typeof SKIP_PREMIUMS_FAILED;
  reason?: string;
}

export function skipPremiumsFailureActionCreator(reason?: string): SkipPremiumsFailedAction {
  return {
    type: SKIP_PREMIUMS_FAILED,
    reason,
  };
}

export function completeLater() {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      dispatch(skipPremiumsStartedActionCreator());
      const userState = getState().app.userData;
      if (!userState.loggedIn) {
        throw new Error('User must be logged in');
      }

      const actionResult = await dispatch(updateActiveMerchant({
        id: userState.activeBrand.id,
        prod_api_secret: userState.activeBrand.prod_api_secret,
        onboarding_status: OnboardingStatus.Complete,
        onboarding_complete: true,
        status: 'Active',
      }));

      if (actionResult.type === UPDATE_ACTIVE_MERCHANT_FAILED) {
        throw new Error('Onboarding Status Update Failed');
      }

      dispatch(skipPremiumsSuccessfulActionCreator());
      return dispatch(finishOnboarding());
    } catch (err) {
      captureException(err);
      return dispatch(skipPremiumsFailureActionCreator())
    }
  };
}
