import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import StaticMap, { Marker } from 'react-map-gl';
import Loader from 'react-loader-spinner';
import {
  Map,
  Pin,
  StatusTooltip,
  NavigationContainer,
  FlexContainer,
  LoaderContainer,
  ErrorMessage,
  CloseButton,
  BlurredMap,
  BlurredMapStatusLabel,
} from './style';
import PolylineOverlay from './polyline';
import { Logo } from '../MerchantLogoProfile/style';
import DeliveryCompany from '../DeliveryCompany';
import { CalendarStrings } from '../../utils/calendar';
import { StatusLabel, Statuses, LoadingStatuses } from '../../utils/shipment';
import { AddressToGeolocation } from '../../utils/geolocation';
import colors from '../../../../constants/colors';

const MerchantLogo = 'https://route-cdn.s3.amazonaws.com/route-order-tracking/default-merchant-logo.svg';

const ShipmentMap = (props) => {
  const [viewport, setViewport] = useState({
    longitude: -98,
    latitude: 39,
    zoom: 3,
  });
  const [points, setPoints] = useState([]);
  const [brandLogoURL, setBrandLogoURL] = useState('');
  const [isRoutePlus, setIsRoutePlus] = useState(false);
  const [checkpoints, setCheckpoints] = useState([]);
  const [status, setStatus] = useState('');
  const [carrier, setCarrier] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    console.log('data', props);
    if (props.data) {
      const { data } = props;
      if (props.item) {
        const filteredCheckpoints = props.item.checkpoints.filter((cp) => cp.name !== '');

        const mappedCheckpoints = filteredCheckpoints.map(async (item, index) => {
          const geolocation = await AddressToGeolocation(item.name);
          item.lon = geolocation[0];
          item.lat = geolocation[1];
          return item;
        });

        Promise.all(mappedCheckpoints).then((res) => {
          props.item.checkpoints = res;

          const checkpoints = props.item.checkpoints.filter((item, index) => item.lon != -1 && item.lat != -1);

          if (checkpoints[checkpoints.length - 1]) {
            setViewport({
              longitude: checkpoints[checkpoints.length - 1].lon,
              latitude: checkpoints[checkpoints.length - 1].lat,
              zoom: 4,
            });
          }

          setPoints(checkpoints.map((item, index) => [item.lon, item.lat]));

          setCheckpoints(checkpoints);
          setStatus(props.item.status);
          if (props.item.estimatedDeliveryDate) {
            setDeliveryDate(props.item.estimatedDeliveryDate);
          }
          setShowErrorMessage(props.showErrorMessage);
        });

        if (props.item.checkpoints && props.item.checkpoints.length) {
          props.item.checkpoints.map((item, _) => {
            if (item.status == Statuses.DELIVERED) {
              setDeliveryDate(item.date);
            }
          });
        }
      }

      if (data && data.brand) {
        setBrandLogoURL(data.brand.logo);
      }

      setIsRoutePlus(data.routePlus);
    }
  }, []);

  useEffect(() => {
    setShowErrorMessage(props.showErrorMessage);
  }, [props.showErrorMessage]);

  useEffect(() => {
    if (props.item) {
      setStatus(props.item.status);
      setCarrier(props.item.carrier);
    }
  }, [props.item]);

  function closeDialogue() {
    setShowErrorMessage(false);
  }

  if (!checkpoints || !checkpoints.length) {
    return (
      <BlurredMap>
        <BlurredMapStatusLabel>{StatusLabel(status)}</BlurredMapStatusLabel>
      </BlurredMap>
    );
  }

  return (
    <Map>
      <DeliveryCompany carrier={carrier} />
      <StaticMap
        reuseMaps
        {...viewport}
        width="100%"
        height="100%"
        mapboxApiAccessToken="pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg"
        mapStyle="mapbox://styles/routewapp/ck0sfcnuh1cqp1cpybctk21k2"
        scrollZoom={false}
        interactive={false}
      >
        {!LoadingStatuses.includes(status) && (
          <div>
            <PolylineOverlay points={points} color={colors.primaryColor} />
            {checkpoints &&
              checkpoints.length > 0 &&
              checkpoints.map((item, index) => (
                <Marker key={index} longitude={item.lon} latitude={item.lat}>
                  {index === checkpoints.length - 1 ? (
                    <Logo large src={brandLogoURL || MerchantLogo} boxShadow centerAlign>
                      {item.date && deliveryDate && status !== Statuses.DELIVERED ? (
                        <StatusTooltip routePlus={isRoutePlus}>
                          <Moment parse="YYYY-MM-DDTHH:mm:ss.SSSZ" calendar={CalendarStrings}>
                            '{deliveryDate}'
                          </Moment>
                        </StatusTooltip>
                      ) : (
                        <StatusTooltip routePlus={isRoutePlus}>{StatusLabel(status)}</StatusTooltip>
                      )}
                    </Logo>
                  ) : (
                    <Pin />
                  )}
                </Marker>
              ))}
          </div>
        )}
        {LoadingStatuses.includes(status) && !showErrorMessage && (
          <FlexContainer>
            <LoaderContainer>
              <Loader type="TailSpin" color="#5cc8dc" height={40} width={40} />
            </LoaderContainer>
          </FlexContainer>
        )}
        {showErrorMessage && (
          <FlexContainer>
            <ErrorMessage>
              <div>
                Sorry, we are still working on getting your tracking information. Please check back in a few minutes.
              </div>
              <CloseButton onClick={() => closeDialogue()}>Close</CloseButton>
            </ErrorMessage>
          </FlexContainer>
        )}
      </StaticMap>
    </Map>
  );
};

export default ShipmentMap;
