import {Card, BankAccount} from '@stripe/stripe-js';

export type PlaidMetadata = {
  public_token: string;
  account_id: string;
  institution: {
    name: string;
    institution_id: string;
  };
  account: {
    subtype: string;
    mask: string;
    name: string;
  };
};

export enum OnboardingStatus {
  Install = 'Install',
  AccountCreated = 'Account Created',
  Skipped = 'Skipped',
  Complete = 'Complete',
  Uninstall = 'Uninstall',
};

export declare namespace RouteApi {
  export type User = {
    id: string;
    // This is actually nullable, but we check to make sure
    // it exists when fetching/logging in a user so making it
    // non-nullable here allows us to omit checks in our app
    token: string;
    merchant_id: string;
    platform_id: string;
    primary_email: string;
    created_on?: string;
    updated_on?: string;
    phone?: string;
    name?: string;
    role?: string;
    notes?: string;
  }

  export type UserMerchantRecord = {
    user_id: string;
    merchant_id: string;
    created_on?: string;
  }

  export type MerchantPreferences = {
    opt_in?: boolean;
    opt_out?: boolean;
    referral_fees?: boolean;
    automated_billing?: boolean;
    asset_live?: boolean;
    asset_version?: string;
  }

  export type Merchant = {
    id: string;
    onboarding_status: OnboardingStatus;
    prod_api_secret: string;
    store_logo?: string;
    store_name?: string;
    store_domain?: string;
    store_id?: number;
    platform_id?: string;
    created_on?: string;
    updated_on?: string;
    deal_size_order_count?: string;
    has_route_plus?: boolean;
    install_method?: 'expert' | 'self';
    merchant_preferences?: MerchantPreferences;
    onboarding_complete?: boolean;
    status?: 'Active' | 'Uninstalled';
  }

  export type MerchantContact = {
    id: string;
    merchant_id: string;
    name?: string;
    email?: string;
    phone?: string;
    link?: string;
    slug?: 'billing_contact' | 'claim_contact' | 'returns' | 'reimbursement_contact' | 'company' | 'support';
    created_on?: string;
    updated_on?: string;
  }

  export type MerchantService = {
    service_tier?: 'ROUTE' | 'ROUTE_PRO' | 'LEGACY';
  };

  export type BillingRecord = {
    id: string;
    merchant_id: string;
    created_on: string;
    company_id?: string;
    platform_id?: string;
    updated_on?: string;
    stripe_customer_token?: string;
    card_token?: string;
    account_last_four?: string;
    cart_type?: string;
    card_exp?: string;
    funding_type?: string;
    card_id?: string;
    bank_token?: string;
    bank_type?: string;
    bank_name?: string;
    bank_institution_code?: string;
    default_source?: string;
    account_name?: string;
    bank_id?: string;
    verified?: boolean;
  };

  export namespace Billing {
    export type CreateAccountRequestBody = {
      source_token: string;
    } & ({
      type: 'card';
      card: Card;
    } | {
      type: 'bank';
      bank_account: BankAccount;
    } | {
      type: 'plaid';
      metadata: PlaidMetadata;
    });

    export type GetAllBillingRecordsResponse = BillingRecord[];
  }

  export namespace User {
    export type UserResponse = RouteApi.User;

    export type LoginUserRequestBody = {
      username: string;
      password: string;
    }

    export type LoginUserResponse = UserResponse;

    export type CreateUserRequestBody = {
      name?: string;
      password: string;
      primary_email: string;
      platform_id?: string;
      phone?: string;
      notes?: string;
    }

    export type CreateUserResponse = UserResponse;

    // Intentionally left empty - no data needed except user token as header
    export type GetAllMerchantsRequest = {};

    export type GetAllMerchantsResponse = Merchant.MerchantResponse[];

    export type ResetPasswordRequestBody = {
      password: string;
      guid: string;
    }

    export type RequestPasswordResetRequestBody = {
      email: string;
      token?: string;
      source?: 'onboarding' | string;
    }
  }

  export namespace Merchant {
    export type MerchantResponse = RouteApi.Merchant;

    export type CreateMerchantRequestBody = {
      platform_id: string;
      store_domain: string;
      store_name?: string;
      country?: string;
      currency?: string;
      source?: string;
      // Note: There are many more potential fields here
    }

    // This is misleading - if we give a merchant token in the request it will return
    // an array with one element
    export type GetAllMerchantsResponse = MerchantResponse[];

    export type CreateMerchantResponse = MerchantResponse;

    export type UpdateMerchantRequestBody = Partial<RouteApi.Merchant>;

    export type UpdateMerchantResponse = MerchantResponse;

    export type UpdateMerchantOnboardingStatusRequestBody = {};

    export type UpdateMerchantOnboardingStatusResponse = OnboardingStatus;
  }

  export namespace MerchantContact {
    export type UpsertMerchantContactRequestBody = Omit<Partial<RouteApi.MerchantContact>, 'id'> & {
      merchant_id: string;
    }

    export type UpsertMerchantContactResponse = RouteApi.MerchantContact;
  }

  export namespace MerchantService {

    export type MerchantResponse = RouteApi.Merchant;

    export type MerchantService = 'ROUTE' | 'ROUTE_PRO' | 'LEGACY';

    export type MerchantServiceResponse = {
      service_tier?: MerchantService;
    };
  }

}
