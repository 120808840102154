import React, { FC, useEffect, useState } from 'react';
import Subnav from '../../../_shared/Subnav/Subnav';
import Typography from '../../../components/Typography';
import Loading from '../../../components/Loading/Loading';
import Button from '../../../components/Button';
import { getNotificationsSettings } from '../../../../../services/notification_settings';
import {
  Container,
  Description,
  Grid,
  Widget,
  Widgets,
  WidgetContainer,
  WidgetHeader,
  Line,
  Divider,
  WidgetTitle,
} from './ShippingNotifications.styles';
import IconNew from '../../../components/IconNew';
import RouteProSmIcon from '../../../../../assets/images/route-pro-badge-sm.svg';
import { check } from '../../../components/Authentication/Can/Can';
import { permissions, merchantRoles } from '../../../components/Authentication/merchant_permissions';

const ShippingNotifications: FC = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({
    settings: {
      shipping_delayed: {
        active: false,
        delay_hours: 0,
      },
      shipping_ready: {
        active: false,
      },
    },
  });
  useEffect(() => {
    const fetchNotifications = async () => {
      if (props?.userSession) {
        setIsLoading(true);
        const merchant = props?.userSession.activeBrand;
        const response = await getNotificationsSettings('shipping_ready', merchant.id, merchant.prod_api_secret);

        setNotificationSettings(response);
        setIsLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  return (
    <div className="admin-preferences-main-container">
      <Subnav
        {...props}
        header="Engage"
        pages={[
          { pathname: '/engage/branded-content', pageName: 'Branded Content' },
          { pathname: '/engage/shipping-notification', pageName: 'Shipping Notifications' },
        ]}
      />
      <Divider></Divider>
      <Grid>
        <Typography variant="h3" component="h2" color="black">
          Shipping Notifications
        </Typography>
        <Container>
          <Description>
            <Typography variant="h5" component="h3">
              Pre-Shipment
            </Typography>
            <Line style={{ marginTop: '15px' }} />
          </Description>

          <Widgets>
            <Widget>
              <WidgetHeader>
                <WidgetTitle>
                  <div style={{ marginRight: '5px' }}>
                    <Typography variant="h5" component="h4">
                      Ready To Ship
                    </Typography>
                  </div>
                  <img src={RouteProSmIcon} alt="route pro" />
                </WidgetTitle>
                <div
                  style={{
                    display: 'flex',
                    width: 100,
                    justifyContent: 'space-between',
                  }}
                >
                  {isLoading && (
                    <div
                      style={{
                        width: 25,
                        marginRight: 5,
                      }}
                    >
                      <Loading />
                    </div>
                  )}
                  {!isLoading &&
                    (notificationSettings.settings.shipping_ready.active ? (
                      <Typography variant="p" component="h4" color="primary" align="right">
                        ON
                      </Typography>
                    ) : (
                      <Typography variant="p" component="h4" color="gray" align="right">
                        OFF
                      </Typography>
                    ))}
                  <Button
                    text="Settings"
                    onClick={() =>
                      props.history.push({
                        pathname: '/engage/shipping-notification/ready-to-ship',
                        state: { notificationSettings },
                      })
                    }
                  ></Button>
                </div>
              </WidgetHeader>
              <Typography variant="h8" component="h4" color="darkGray" uppercase>
                Notification Types
              </Typography>
              <Line />
              <WidgetContainer>
                <Typography variant="h7" component="h4">
                  App (Push Notification)
                </Typography>
              </WidgetContainer>
              <Line />
              <WidgetContainer>
                <Typography variant="h7" component="h4">
                  Email
                </Typography>
              </WidgetContainer>
            </Widget>
            <Widget>
              <WidgetHeader>
                <WidgetTitle>
                  <div style={{ marginRight: '5px' }}>
                    <Typography variant="h5" component="h4">
                      Shipment Delay
                    </Typography>
                  </div>
                  <img src={RouteProSmIcon} alt="route pro" />
                </WidgetTitle>
                <div
                  style={{
                    display: 'flex',
                    width: 100,
                    justifyContent: 'space-between',
                  }}
                >
                  {isLoading && (
                    <div
                      style={{
                        width: 25,
                        marginRight: 5,
                      }}
                    >
                      <Loading />
                    </div>
                  )}
                  {!isLoading &&
                    (notificationSettings.settings.shipping_delayed.active ? (
                      <Typography variant="p" component="h4" color="primary" align="right">
                        ON
                      </Typography>
                    ) : (
                      <Typography variant="p" component="h4" color="gray" align="right">
                        OFF
                      </Typography>
                    ))}
                  <Button
                    text="Settings"
                    onClick={() =>
                      props.history.push({
                        pathname: '/engage/shipping-notification/shipment-delay',
                        state: { notificationSettings },
                      })
                    }
                  ></Button>
                </div>
              </WidgetHeader>
              <Typography variant="h8" component="h4" color="darkGray" uppercase>
                Notification Types
              </Typography>
              <Line />
              <WidgetContainer>
                <Typography variant="h7" component="h4">
                  App (Push Notification)
                </Typography>
              </WidgetContainer>
              <Line />
              <WidgetContainer>
                <Typography variant="h7" component="h4">
                  Email
                </Typography>
              </WidgetContainer>
            </Widget>
          </Widgets>
        </Container>
      </Grid>
    </div>
  );
};

export default ShippingNotifications;
