import React from "react";
import Colors from '../../constants/colors';
const SVG = ({
  style = {},
  stroke = 'white',
  fill = Colors.primaryColor,
  width = '40',
  className = '',
  height = '40',
  viewBox = '0 0 40 40',
  transform = '',
}) =>
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" r="20" fill="#5DC8DB"/>
  <path d="M18 14L24 20L18 26" stroke="white" stroke-linecap="round"/>
  </svg>;

export default SVG;
