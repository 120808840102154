import React from 'react';
import { Container, Label, Logo } from './style';
import { Carriers } from './carriers';

const DeliveryCompany = (props) => {
  const carrier = props.carrier.toLowerCase();
  const carrierName = Carriers[carrier];

  if (!carrierName) return null;

  return (
    <Container>
      <Logo src={`https://cdn.routeapp.io/route-order-tracking/carriers/${carrier}.png`} />
      <Label>{carrierName}</Label>
    </Container>
  );
};

export default DeliveryCompany;
