import React, { Component } from 'react';
import './SummaryCard.scss';
import Card from '@material-ui/core/Card';
import { withRouter } from 'react-router-dom';
import Button from '../../../Button/Button';

class SummaryCard extends Component {
  render() {
    return (
      <span>
        <Card className='invoice-summary-card' elevation={0}>
          <div className='invoice-summary-card-premiums'>
            {
              this.props.isLoading ? <div className='dash-loading-state'>&nbsp;</div> : (
                <div className="summary-block">
                  <span className='summary-price'>{this.props.premiums}</span>
                  {/*<span className='summary-text'>Paid</span> */}
                </div>
              )
            }

          </div>
          {/*           <div className='invoice-summary-card-subtotal'><span className='invoice-summary-card-left'>Subtotal</span> <span className='invoice-summary-card-right'>{this.props.isLoading ? <div className='dash-loading-state'>&nbsp;</div> : this.props.subtotal}</span> </div> */}
        </Card>
        { /* this.props.billing && !this.props.billing.verified
          ? <Card className='invoice-summary-card' elevation={0}>
            <div className='invoice-summary-card-total'>{this.props.isLoading ? <div className='dash-loading-state'>&nbsp;</div> : this.props.total}</div>
            <div className='invoice-summary-card-alert'>We are unable to process payments until you update your payment method</div>
            <Button
              className='input-button full'
              text='Update Payment Method'
              onClick={() => this.props.history.push({
                pathname: '/admin/preferences',
                state: { drawer: 'update-payment', isDrawerOpen: true },
              })} />
          </Card> : '' */}
      </span>
    );
  }
}

export default withRouter(SummaryCard);
