import React, { Component } from 'react';
import './Onboarding.scss';
import OnboardingRoutes from './routes';

class Onboarding extends Component {
  constructor() {
    super();

    this.state = {
      productVersion: 'free',
      displayBreadcrumbs: false,
      breadcrumbStep: '',
      breadcrumbStepCount: '',
    };


    this.updateNav = this.updateNav.bind(this);
  }

  updateNav(productVersion, displayBreadcrumbs, currentStepTitle, currentStepCount) {
    this.setState((state, props) => ({
      productVersion: productVersion || '',
      displayBreadcrumbs: displayBreadcrumbs || false,
      breadcrumbStep: currentStepTitle || '',
      breadcrumbStepCount: currentStepCount || 0,
    }));
  }

  render() {
    return (
      <div className='onboarding'>
        <OnboardingRoutes {...this.props} />
      </div>
    );
  }
}

export default Onboarding;
