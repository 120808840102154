import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';
import axios from 'axios';
import Subnav from '../../_shared/Subnav/Subnav';
import 'react-dropdown/style.css';
import SearchBar from '../../_shared/SearchBar/SearchBar';
import DenseTable from '../../_shared/Table/Table';
import SummaryCard from '../SummaryCard/SummaryCard';
import '../../../_shared/_shared.scss';
import './Invoices.scss';
import { getOrders } from '../../../../services/api';
import { getInvoice } from '../../../../services/billing';
import Form from '../../_shared/Form/Form';
import SideDrawer from '../../_shared/SideDrawer/SideDrawer';
import Calendar from '../../_shared/PeriodCalendar/Calendar';
import PrevArrow from '../../../../assets/icons/prev-arrow';

const Invoices = (props) => {
  const [orders, setOrders] = useState([]);
  const [subtotal, setSubtotal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isSubtotalLoading, setSubtotalLoading] = useState(true);
  const [selected, updateSelected] = useState({});
  const [cursor, updateCursor] = useState('');
  const [limit, updateLimit] = useState(10);
  const [searchValue, updateSearchValue] = useState('');
  const [searchKey, updateSearchKey] = useState('');
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [startOfWeek, setStartOfWeek] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'));
  const [endOfWeek, setEndOfWeek] = useState(moment().endOf('isoWeek').format('YYYY-MM-DD'));
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let unmounted = false;
    setLoading(true);
    const params = { limit, cursor, start_date: formatDate(endOfWeek) }
    if (searchKey) { params.search_key = searchKey; }
    if (searchValue) { params.search_value = searchValue; }

    getOrders(source.token, props.userSession.activeBrand.prod_api_secret, params)
      .then((res) => {
        if (!unmounted) {
          updateCursor(res.data.cursor || '');
          setOrders(formatOrders(res.data.results) || []);
          setLoading(false);
        }
      }, (err) => {
        if (!axios.isCancel(err) && !unmounted) {
          updateCursor('');
          setOrders([]);
          setLoading(false);
          console.log(err.message);
        }
      });

    // return cleanup function, this is called when the component unmounts, or renders, here we will cancel pending requests
    return function () {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [counter, endOfWeek]);

  useEffect(() => {
    updateCursor('');
    setCounter(counter + 1);
  }, [limit]);

  useEffect(() => {
    setSubtotal(0);
    const source = axios.CancelToken.source();
    setSubtotalLoading(true);
    let unmounted = false;

    getInvoice(props.userSession.activeBrand.id, formatDate(endOfWeek), props.userSession.activeBrand.prod_api_secret, source.token)
      .then((res) => {
        if (!unmounted) {
          if (res && res.data !== null) {
            if (typeof res.data === 'object' && res.data.hasOwnProperty('subtotal')) {
              const subtotal = res.data.subtotal !== null ? (Math.round(res.data.subtotal * 100) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0;
              setSubtotal(subtotal);
            } else {
              setSubtotal(0);
            }
          } else {
            setSubtotal(0);
          }
          setSubtotalLoading(false);
        }
      }, (err) => {
        if (!axios.isCancel(err) && !unmounted) {
          setSubtotalLoading(false);
          setSubtotal(0);
          console.log(err.message);
        }
      });

    // return cleanup function, this is called when the component unmounts, or renders, here we will cancel pending requests
    return function () {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [endOfWeek]);

  useEffect(() => {
    document.addEventListener("mousedown", handleDropdownClick);
    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, []);

  const node = useRef();

  const handleDropdownClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setCalendarOpen(false);
  };

  const handleRange = async (data) => {
    // Google Analytics - Track date change
    ReactGA.event({ category: 'Admin - Billing', action: 'Time Period Change', label: `${moment(data[0]).format('YYYY-MM-DD')} to ${moment(data[6]).format('YYYY-MM-DD')}` });

    setStartOfWeek(data[0]);
    setEndOfWeek(data[6]);
    updateCursor('');
    setCalendarOpen(false);
  };

  const searchOrders = () => {
    if (searchKey && searchValue) {
      ReactGA.event({ category: 'Admin - Billing', action: 'Search Executed', label: searchKey || 'No Search Key' });
    }
    updateCursor('');
    setCounter(counter + 1);
  };

  const fetchMoreOrders = () => {
    setCounter(counter + 1);
  };

  const onSelectRow = (order) => {

    ReactGA.event({ category: 'Admin - Billing', action: 'Row Click / Drawer View', label: String((order && order.id) || '') });
    updateSelected(order);
  };

  const formatOrders = (orders) => {
    if (orders && orders.length > 0) {
      const totalOrders = [];
      orders = orders.filter((x) => x.status !== 'test');
      orders = orders.map((x) => ({ ...x, customer: `${x.first_name || ''} ${x.last_name || ''}` }));
      orders.forEach((element) => {
        element.amount_covered = element.amount_covered > 0 ? formatDollar(element.amount_covered) : 0;
        element.display_paid_to_insure = formatDollar(element.paid_to_insure_usd);
        // endOfWeek and startOfWeek are both truncated to YYYY-MM-DD, so comparisons to created_on need to also truncate time
        const isInDateRange = moment(element.created_on).utc().startOf('day') <= moment(endOfWeek).utc()
          && moment(element.created_on).utc() >= moment(startOfWeek).utc();
        if (isInDateRange) {
          if (element.status === 'cancelled') {
            // duplicate the entry
            const newObj = JSON.parse(JSON.stringify(element));
            // set the created time of the cancelled entry to the cancelled time
            newObj.created_on = newObj.updated_on;
            newObj.display_paid_to_insure = `(${formatDollar(element.paid_to_insure_usd)})`;
            newObj.amount_covered = element.amount_covered > 0 ? `(${formatDollar(element.amount_covered)})` : 0;
            newObj.className = 'cancelled-order-row';
            newObj.display_paid_to_insure = element.paid_to_insure_usd > 0 ? `(${formatDollar(element.paid_to_insure_usd)})` : 0;
            newObj.subtotal = element.subtotal > 0 ? `(${formatDollar(element.subtotal)})` : 0;
            // give some non-cancelled status to the initial creation
            element.status = 'notCancelledYet';
            totalOrders.push(newObj);
          } else {
            totalOrders.push(element);
          }
        }
      });
      totalOrders.sort((b, a) => ((a.created_on > b.created_on) ? 1 : ((b.created_on > a.created_on) ? -1 : 0)));
      return totalOrders;
    }
    return [];
  };

  const formatDollar = (val) => {
    val = isNaN(val) ? 0 : val;
    return `$${Number(val).toFixed(2)}`;
  };

  const formatDate = (date) => {
    if (!date) {
      return moment().utc().format('YYYY-MM-DD');
    }
    return moment(date).utc().format('YYYY-MM-DD');
  };

  const setOpen = () => {
    // Google Analytics - Track calendar click
    ReactGA.event({ category: 'Admin - Billing', action: '"Select Period" click' });

    setCalendarOpen(!isCalendarOpen);
  };

  const subnavRoutes = [
    { pathname: 'preferences', pageName: 'Preferences' },
    { pathname: 'billing', pageName: 'Billing' }
  ];

  return (
    <div>
      <Subnav {...props} header="Admin" 
        pages={subnavRoutes} />
      <div className='portal-content-container'>
        <h1>{props.pageHeader}{props.pageHeader ? 's' : ''}</h1>
        <div className='experimental-flex' container >
          <div className='experimental-flex-left'>
            <div className='portal-content-header-container'>
              <div className="summary-widget">
                <h3 className="summary-title">Select Billing Period</h3>
                <div className="inputBox" ref={node} >
                  <span className='calendar-header'>{moment(startOfWeek).format('MMM DD').toUpperCase()} - {moment(endOfWeek).format('MMM DD').toUpperCase()}</span>
                  <span className="arrow"><PrevArrow /></span>
                  <span className="click-listener" onClick={() => setOpen()}></span>
                  {isCalendarOpen ? <Calendar handleWeekRange={handleRange} /> : ''}
                </div>
              </div>
              <div className="summary-widget">
                <h3 className="summary-title">Period Premiums (Net)</h3>
                <SummaryCard className='invoice-summary-card' {...props} isLoading={isSubtotalLoading} premiums={`$${subtotal}`} subtotal={`$${subtotal}`} total={`$${subtotal}`} />
              </div>
            </div>
            <div className='portal-content-header-container--searchbar' >
              <SearchBar search={searchOrders}
                updateSearchValue={updateSearchValue}
                updateSearchKey={updateSearchKey}
                placeholder="Search Orders" searchDropdownItems={{
                  route_order_number: 'Route Order Number',
                  source_order_number: 'Store Order Number',
                }} />
            </div>

            <DenseTable elevation={0}
              isLoading={isLoading}
              onSelect={onSelectRow}
              headerList={['Order Date', 'Store Order Number', 'Shipping Protection Cost', 'Protected Revenue', 'Order Subtotal', 'Order Currency']}
              dataProps={['created_on', ['source_order_number', 'source_order_id'], 'display_paid_to_insure', 'amount_covered', 'subtotal', 'currency']}
              data={orders}
              rowsPerPage={10}
              fetchMoreOrders={fetchMoreOrders}
              count={String(orders.length + 1)}
              search={searchOrders}
              cursor={cursor}
              userSession={props.userSession || null}
              updateLimit={updateLimit}
            />
          </div>
        </div>

        <SideDrawer title={props.pageHeader} toggleDrawer={true} onSelected={selected} onSelect={onSelectRow}>
          <Form count={10} isLoading={props.isLoading} formContent={selected} userSession={props.userSession} />
        </SideDrawer>
      </div>
    </div>
  );
};

export default Invoices;
