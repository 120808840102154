import React, { useState } from 'react';
import './UpdatePaymentMethod.scss';
import TitleAndNav from './TitleAndNav';
import Button from '../../../Button/Button';
import { updateMerchantContact } from '../../../../services/merchant';
import { makeStyles } from '@material-ui/core/styles';
import DrawerValidation, {validateEmail} from "./DrawerValidation";
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  ul: {
    listStylePosition: 'outside',
    listStyleType: 'square',
    marginLeft: '33px'
  },
  li: {
    fontSize: '12px',
    lineHeight: '19px',
    paddingLeft: '10px'
  },
  span: {
    fontSize: '16px'
  }
}));
const UpdateClaimsContact = (props) => {
  const { id: merchantId, prod_api_secret: token } = props.userSession.activeBrand;
  const classes = useStyles();
  const [claimEmail, setClaimEmail] = useState(props.claimEmail);
  const [isEmailInvalid, setEmailInvalid] = useState(false);

  const handleSubmitButton = async (e) => {
    if (validateEmail(claimEmail)) {
      setEmailInvalid(false);
      // Google analytics, track merchant claims contact change
      ReactGA.event({ category: 'Admin - Preferences', action: 'Claims Contact change' });
      await updateMerchantContact({email: claimEmail, phone: '', link: '', slug: 'claim_contact', merchantId, token});
      props.closeDrawer();
    } else {
      setEmailInvalid(true);
    }
    props.refreshMerchantContact();
  };

  const handleEmailChange = (e) => {
    setClaimEmail(e.target.value);
    setEmailInvalid(false);
  };

  const closeEmailError = () => {
    setEmailInvalid(false);
  };

  const validateClaimEmail = () => {
    setEmailInvalid(!validateEmail(claimEmail))
  };

  return (
    <div>
      <TitleAndNav close={props.closeDrawer} title='Claims Contact' />
      <div className='update-payment-method-upper-text'>
        <p>Route may deny claims filed by your customers that insured their purchase with Route+
          package protection.</p><br />
        <p>We will send you an email each time a claim is denied by Route to provide you the
          opportunity to review the claim and continue the conversation with the customer.</p> <br />
        <p>This email will include:
        <ul className={classes.ul}>
            <li className={classes.li}><span className={classes.span}>Brief summary of why the claim was denied</span></li>
            <li className={classes.li}><span className={classes.span}>Customer’s contact information</span></li>
          </ul>
        </p>
        <br />
        <p className='bold-paragraph'>
          If you leave this field empty, you will not be notified when claims are denied by Route.
        </p>
      </div>
      <div className='update-payment-method-lower-text'>
        <div className='update-billing-contact-input-header'>
          Email
        </div>
        <input className='input-ss' value={claimEmail} name="claimEmail"
          onChange={handleEmailChange}
          onBlur={validateClaimEmail}
        />
        {isEmailInvalid ? <DrawerValidation isEmail = {isEmailInvalid} closeEmailError={closeEmailError} /> : ''}
      </div>
      <Button className='update-company-details-save-button' type='submit'
        text="Save"
        onClick={handleSubmitButton}
      />
    </div>
  );
};

export default UpdateClaimsContact;
