import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {DispatchProps, PasswordResetSuccess} from './success';
import {goBackToSignIn} from '../../../actions/sign-in/password-reset'

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onGoToSignIn: () => dispatch(goBackToSignIn()),
  };
}

export const ConnectedPasswordResetSuccess = connect(null, mapDispatchToProps)(PasswordResetSuccess);
