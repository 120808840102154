import React, { useState, useEffect } from 'react';
import {
  Grid, makeStyles, Box, Paper,
} from '@material-ui/core';
import PageTitle from '../_shared/PageTitle/PageTitle';
import IntroText from '../_shared/IntroText/IntroText';
import CTAButton from '../_shared/CTAButton/CTAButton';

import { isExpertInstallOption } from '../../../services/api';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import MultiStepsCompanySetting from '../_shared/MultiStepsCompanySetting/MultiStepsCompanySetting';
import PageHeader from '../_shared/PageHeader/PageHeader';
import Paragraph from '../_shared/Paragraph/Paragraph';
import InAppBrandPlaceholder from '../../../assets/images/in-app-brand-placeholder.svg';
import PreferencesDrawer from '../../Portal/Preferences/Drawer/PreferencesDrawer';
import './UpdateInAppBrand.scss';

const useStyles = makeStyles({
  multiStepsContainer: {
    position: 'relative',
  },
});

const UpdateInAppBrand = (props) => {
  const [nextPage, setNextPage] = useState('/onboarding/invite-email');
  const [multistepSuccess, setMultistepSuccess] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawer, setDrawer] = useState('update-details');

  const onMultistepSuccess = (state) => {
    setMultistepSuccess(state);
  };

  const toggleDrawer = () => {
    setDrawerOpen(true);
  };

  const nextStep = () => {
    props.history.push('/onboarding/update-your-email');
  };

  return (
    <div className='update-in-app-brand'>
      <OnboardingContainer>
        <Box display="flex">
          <Box width={721} mr="111px">
            <PageHeader>
              <PageTitle title="Personalize Your In-app Merchant Profile"></PageTitle>
            </PageHeader>
            <Paper className="column">
              <Paragraph text="The Route mobile app automatically connects to all of your customer's orders, allowing them to visually track shipments, anytime, anywhere."></Paragraph>
              <Paragraph text="Your personalized brand profile organizes all of your customer's orders in one place, while providing quick links to continue shopping on your website or reach your customer support team."></Paragraph>
              <Paragraph text={'This can be edited at any time in the Route "Brand Portal".'}></Paragraph>

              <div className="button-container">
                <CTAButton text="Continue" href="#" onClick={toggleDrawer}></CTAButton>
                <CTAButton text="Skip (you can set this later)" href="#" styleName="basic underline" onClick={nextStep}></CTAButton>
              </div>
            </Paper>
          </Box>
          <Box display="flex" mt="106px">
            <img src={InAppBrandPlaceholder} alt="Route" className="featured-image"></img>
          </Box>
        </Box>
      </OnboardingContainer>

      <PreferencesDrawer {...props} isOpen={drawerOpen} setOpen={setDrawerOpen} drawerName={drawer} setDrawerName={setDrawer} getBillingDetails='' onUpdateDetails={nextStep} />
    </div>
  );
};

export default UpdateInAppBrand;
