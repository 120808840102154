const dictionary = {
  MESSAGE_ERROR_BRAND_INFORMATION: 'Error while retrieving Merchant Brand information',
  MESSAGE_ERROR_PUBLIC_BRAND_INFORMATION: 'Error while retrieving Public Merchant Brand information',
  MESSAGE_ERROR_MERCHANT_ISSUE_RULES: 'Error while retrieving Merchant Issue Rules',
  MESSAGE_ERROR_MERCHANT_BRAND_BIO: 'Error while updating Merchant Brand Bio',
  MESSAGE_ERROR_MEDIA_UPLOAD: 'Error while uploading Media Upload',
  MESSAGE_ERROR_RETRIEVE_USER_ID: 'Error while retrieving User ID',
  MESSAGE_ERROR_RETRIEVE_ORDER_WEB_TRACKING: 'Error while retrieving Web Order Tracking',
  MESSAGE_ERROR_RETRIEVE_NOTIFICATION_SETTINGS: 'Error while getting Notification Settings from the merchant',
  MESSAGE_ERROR_SAVING_NOTIFICATION_SETTINGS: 'Error while saving Notification Settings from the merchant',
  MESSAGE_ERROR_CREATE_REQUEST_CSM_INTERACTION: 'Error while creating request csm interaction from the merchant',
  MESSAGE_ERROR_GET_REQUEST_CSM_INTERACTION: 'Error while getting request csm interaction from the merchant',
  MESSAGE_ERROR_INITIALIZE_PENDO: "Pendo couldn't be initialized",
  MESSAGE_ERROR_RETRIEVE_MERCHANT_ROLES: 'Error while retrieving Merchant Roles',
  MESSAGE_ERROR_DEATTACH_USER_AND_MERCHANT_TRIAL_REQUEST:
    '"createCsmInteraction - to deattach user and merchant information to request get route pro 30 days',
  TRACKING_STATUS: {
    NOT_AVAILABLE: 'NotAvailable',
    NOT_TRACKING_NUMBER: 'No Tracking Information On This Order',
    WAITING_TRACKING_NUMBER: "We're gathering your tracking info - try again",
    IN_PROGRESS: 'Waiting for Order Items info',
  },
};

export default dictionary;
