import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import '../../_shared/_shared.scss';
import './ClaimsInsights.scss';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Box, Grid } from '@material-ui/core';
import { getClaimsInsights } from '../../../../services/api';
import InfoIcon from '../../../../assets/icons/info-icon';
import CloseIcon from '../../../../assets/icons/close-info-rounded';
import InsightWidget from './InsightWidget/InsightWidget';
import Picker from '../../_shared/DatePicker/Picker';
import Typography from '../../components/Typography';
import colors from '../../../../constants/colors';

const useStyles = makeStyles({
  card: {
    padding: '15px 20px 15px 20px',
    borderRadius: '10px',
    marginBottom: '20px',
    borderRadius: '0px!important',
    background: '#FFFFFF',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)!important',
    minHeight: '80px',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#313942',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    border: 0,
    padding: '5px 0px 5px 0px',
    '&:last-child': { paddingRight: '0px' },
  },
  tableRow: {
    border: 0,
    padding: '0',
  },
  tablePercentCell: {
    textAlign: 'right',
    // The specific line height is arbitrary as long as it is below the content height.
    // mui tables seem to expand row heights, but never shrink them
    lineHeight: '0px',
  },
  percentText: {
    lineHeight: '0px',
    fontSize: '12px',
    fontWeight: '800',
    color: colors.primaryColor,
  },
  icon: {
    marginLeft: '25px',
  },
  cardTitle: {
    zIndex: '2',
  },
  cardTitleSecondRow: {
    marginTop: '40px',
  },
  picker: {
    position: 'absolute',
    top: '106px',
    right: '40px',
  },
  gray: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    opacity: '0.5',
    zIndex: '0',
  },
  subnav: {
    display: 'inline-block',
    width: '350px',
  },
  subnavAndPicker: {
    width: '1200px',
    marginBottom: '10px',
  },
});

const baseClaimsData = {
  filed: {
    title: 'Filed',
    rate: 0,
    count: 0,
    helpText: [
      'An issue has been reported but has not yet been reviewed by the Route support team.',
      'This graph shows the percentage of open issues with a status of “Filed.”',
    ],
  },
  inProgress: {
    title: 'In Progress',
    rate: 0,
    count: 0,
    helpText: [
      'The Route Support team is actively working to resolve these issues.',
      'This graph shows the percentage of open issues with a status of “In Progress.”',
    ],
  },
  reorder: {
    title: 'Resolved - Reorder',
    rate: 0,
    count: 0,
    helpText: [
      'These issues have been approved and the item(s) have been reordered.',
      'This graph shows the percentage of closed issues with a status of “Reordered.”',
    ],
  },
  refund: {
    title: 'Resolved - Refund',
    rate: 0,
    count: 0,
    helpText: [
      'These issues have been approved and the item(s) have been refunded.',
      'This graph shows the percentage of closed issues with a status of “Refunded.”',
    ],
  },
  other: {
    title: 'Resolved - Other',
    rate: 0,
    count: 0,
    helpText: [
      'These issues have been resolved because the shipment was found or the customer failed to respond to outreach.',
      'This graph shows the percentage of closed issues with a status of “Resolved.”',
    ],
  },
  referred: {
    title: 'Referred to Merchant',
    rate: 0,
    count: 0,
    helpText: [
      'These issues are not covered by Route+ package protection, and customers have been referred back to the merchant.',
      'This graph shows the percentage of closed issues with a status of “Referred to Merchant.”',
    ],
  },
};

const datePickerEntries = [
  { displayName: '30d', value: 30 },
  { displayName: '90d', value: 90 },
  { displayName: '1y', value: 365 },
];

const ClaimsInsights = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(datePickerEntries[0].value);
  const [numberOfAllClaimsByType, setNumberOfAllClaimsByType] = useState({});
  const [insightsData, setInsightsData] = useState({
    highestClaimRateByCourierResults: [],
    highestClaimRateByTypeResults: [],
    highestClaimRateByLocationResults: [],
    claimStatusOfFiledResults: [],
    claimStatusOfInProgressResults: [],
    claimResolutionStatusResults: [],
  });
  const [resolutionResults, setResolutionResults] = useState(baseClaimsData);
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles(props);

  useEffect(() => {
    searchClaimsInsights();
  }, []);

  useEffect(() => {
    searchClaimsInsights();
  }, [numberOfDays]);

  const displayLoadingState = () =>
    Array(3)
      .fill('')
      .map(() => (
        <TableRow key={Math.random()}>
          <TableCell className={classes.tableCell} key={Math.random()} align="left">
            <div className="dash-loading-state">&nbsp;</div>
          </TableCell>
        </TableRow>
      ));

  const searchClaimsInsights = async () => {
    setLoading(true);
    setResolutionResults(baseClaimsData);
    const claimsInsightsResp = await getClaimsInsights(
      props.userSession.activeBrand.prod_api_secret,
      { number_of_days: numberOfDays },
      props.userSession.activeBrand.id,
    );

    // For some reasont the claim-rate-by-type array is not sorted from the database
    if (claimsInsightsResp) {
      claimsInsightsResp.highestClaimRateByTypeResults = claimsInsightsResp.highestClaimRateByTypeResults.sort((a, b) =>
        a.NUMBER_OF_CLAIMS < b.NUMBER_OF_CLAIMS ? 1 : -1,
      );
      setInsightsData(claimsInsightsResp);
    }
    setLoading(false);
  };

  const displayInfo = async (event) => {
    // Google Analytics - Track claims info clicked
    ReactGA.event({ category: 'Claims - All Claims', action: '"i" modal click' });
    setShowInfo(true);
    // Since show and hide buttons overlap, stopPropagation() prevents both from triggering every click
    event.stopPropagation();
  };

  const hideInfo = async (event) => {
    setShowInfo(false);
    // Since show and hide buttons overlap, stopPropagation() prevents both from triggering every click
    event.stopPropagation();
  };

  useEffect(() => {
    // do a deep copy everytime we refresh the claims insights data
    const claimsData = JSON.parse(JSON.stringify(baseClaimsData));

    claimsData.filed.count =
      (insightsData.claimStatusOfFiledResults[0] && insightsData.claimStatusOfFiledResults[0].FILED_CLAIMS) || 0;
    claimsData.inProgress.count =
      (insightsData.claimStatusOfInProgressResults[0] &&
        insightsData.claimStatusOfInProgressResults[0].IN_PROGRESS_CLAIMS) ||
      0;
    claimsData.filed.rate =
      Math.round((claimsData.filed.count * 100) / (claimsData.filed.count + claimsData.inProgress.count)) || 0;
    claimsData.inProgress.rate =
      Math.round((claimsData.inProgress.count * 100) / (claimsData.filed.count + claimsData.inProgress.count)) || 0;

    const reorders = insightsData.claimResolutionStatusResults.find(
      (x) => x.CLAIM_RESOLUTION_STATUS === 'Resolved - Reorder',
    );
    const refunds = insightsData.claimResolutionStatusResults.find(
      (x) => x.CLAIM_RESOLUTION_STATUS === 'Resolved - Refund',
    );
    const others = insightsData.claimResolutionStatusResults.find(
      (x) =>
        x.CLAIM_RESOLUTION_STATUS !== 'Resolved - Reorder' &&
        x.CLAIM_RESOLUTION_STATUS !== 'Resolved - Refund' &&
        x.CLAIM_RESOLUTION_STATUS !== 'Referred to Merchant',
    );
    const referred = insightsData.claimResolutionStatusResults.find(
      (x) => x.CLAIM_RESOLUTION_STATUS === 'Referred to Merchant',
    );

    claimsData.reorder.count = (reorders && reorders.CLAIMS) || 0;
    claimsData.refund.count = (refunds && refunds.CLAIMS) || 0;
    claimsData.other.count = (others && others.CLAIMS) || 0;
    claimsData.referred.count = (referred && referred.CLAIMS) || 0;

    const resolvedClaimsCount =
      claimsData.reorder.count + claimsData.refund.count + claimsData.other.count + claimsData.referred.count;

    claimsData.reorder.rate = Math.round((claimsData.reorder.count * 100) / resolvedClaimsCount) || 0;
    claimsData.refund.rate = Math.round((claimsData.refund.count * 100) / resolvedClaimsCount) || 0;
    claimsData.other.rate = Math.round((claimsData.other.count * 100) / resolvedClaimsCount) || 0;
    claimsData.referred.rate = Math.round((claimsData.referred.count * 100) / resolvedClaimsCount) || 0;

    setResolutionResults(claimsData);
    // setFiled(claimsData.filed);

    const numberOfClaims = insightsData.highestClaimRateByTypeResults
      .map((x) => x.NUMBER_OF_CLAIMS)
      .reduce((total, num) => total + Math.round(num), 0);
    setNumberOfAllClaimsByType(numberOfClaims);
  }, [insightsData]);

  return (
    <div>
      <div className={classes.subnavAndPicker}>
        <span className={classes.subnav}></span>
        <div className={classes.picker}>
          <Picker {...props} header="Date Filter" setSelectedValue={setNumberOfDays} entries={datePickerEntries} />
        </div>
      </div>
      <div className="claims-content-container" onClick={hideInfo}>
        <div className={classNames('admin-preferences-card-above-card-title', 'first', classes.cardTitle)}>
          Issues by Status
          <span className={classes.icon} onClick={displayInfo}>
            {showInfo ? <CloseIcon doOnClick={hideInfo} /> : <InfoIcon />}
          </span>
        </div>
        <div className="claims-insights-dial-container">
          <div className="claim-insights-dial">
            <InsightWidget {...resolutionResults.filed} isLoading={isLoading} color="#666666" showInfo={showInfo} />
          </div>
          <div className="claim-insights-dial">
            <InsightWidget
              {...resolutionResults.inProgress}
              color="#666666"
              isLoading={isLoading}
              showInfo={showInfo}
            />
          </div>
          <div className="claim-insights-dial">
            <InsightWidget
              {...resolutionResults.reorder}
              color={colors.primaryColor}
              isLoading={isLoading}
              showInfo={showInfo}
            />
          </div>
          <div className="claim-insights-dial">
            <InsightWidget
              {...resolutionResults.refund}
              color={colors.primaryColor}
              isLoading={isLoading}
              showInfo={showInfo}
            />
          </div>
          <div className="claim-insights-dial">
            <InsightWidget
              {...resolutionResults.other}
              color={colors.primaryColor}
              isLoading={isLoading}
              showInfo={showInfo}
            />
          </div>
          <div className="claim-insights-dial">
            <InsightWidget
              {...resolutionResults.referred}
              color={colors.primaryColor}
              isLoading={isLoading}
              showInfo={showInfo}
            />
          </div>
        </div>
        <div className="claims-rate-table-content-container">
          <div className="claim-insights-table-container">
            <div
              className={classNames(
                'admin-preferences-card-above-card-title',
                'second',
                classes.cardTitleSecondRow,
                classes.cardTitle,
              )}
            >
              Highest Issue Rate by Courier
            </div>
            <Card className={classes.card} elevation={0}>
              <Table>
                <TableBody>
                  {isLoading
                    ? displayLoadingState()
                    : insightsData.highestClaimRateByCourierResults.map((x) => (
                        <TableRow className={classes.tableRow} style={{ height: 10 }}>
                          <TableCell className={classes.tableCell} align="left">
                            {insightsData.highestClaimRateByCourierResults.indexOf(x) + 1}. {x.COURIER}
                          </TableCell>
                          <TableCell className={[classes.tableCell, classes.tablePercentCell]} align="left">
                            <font className={classes.percentText}>{Math.round(x.CLAIM_RATE * 10000) / 100}%</font>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Card>
          </div>
          <div className="claim-insights-table-container">
            <div
              className={classNames(
                'admin-preferences-card-above-card-title',
                'second',
                classes.cardTitleSecondRow,
                classes.cardTitle,
              )}
            >
              Highest Issue Rate by Location
            </div>
            <Card className={classes.card} elevation={0}>
              <Table className={classes.table}>
                <TableBody>
                  {isLoading
                    ? displayLoadingState()
                    : insightsData.highestClaimRateByLocationResults.map((x) => (
                        <TableRow className={classes.tableRow} style={{ height: '10px', padding: '0px' }}>
                          <TableCell className={classes.tableCell} align="left">
                            {insightsData.highestClaimRateByLocationResults.indexOf(x) + 1}. {x.CITY}
                          </TableCell>
                          <TableCell className={[classes.tableCell, classes.tablePercentCell]} align="left">
                            <font className={classes.percentText}>{Math.round(x.CLAIM_RATE * 10000) / 100}%</font>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Card>
            {/* </div> */}
          </div>
          <div className="claim-insights-table-container">
            {/* <div className='claim-insights-card-wrapper' > */}
            <div
              className={classNames(
                'admin-preferences-card-above-card-title',
                'second',
                classes.cardTitleSecondRow,
                classes.cardTitle,
              )}
            >
              Highest Issue Rate by Type
            </div>
            <Card className={classes.card} elevation={0}>
              <Table className={classes.table}>
                <TableBody>
                  {isLoading
                    ? displayLoadingState()
                    : insightsData.highestClaimRateByTypeResults.map((x) => (
                        <TableRow className={classes.tableRow}>
                          <TableCell className={classes.tableCell} align="left">
                            {insightsData.highestClaimRateByTypeResults.indexOf(x) + 1}. {x.CLAIM_TYPE.toUpperCase()}
                          </TableCell>
                          <TableCell className={[classes.tableCell, classes.tablePercentCell]} align="left">
                            <font className={classes.percentText}>
                              {Math.round((x.NUMBER_OF_CLAIMS / numberOfAllClaimsByType) * 10000) / 100}%
                            </font>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </Card>
          </div>
        </div>
        <div className="claims-insights-dial-container">
          <Grid container direction="row" justify={'flex-end'} alignItems={'center'}>
            <Box mb={4} pr="30px">
              <Typography variant="h8" component="h4" color="gray">
                *Data could be delayed up to 24 hours.
              </Typography>
            </Box>
          </Grid>
        </div>
      </div>
      {showInfo ? <div onClick={hideInfo} className={classes.gray}></div> : null}
    </div>
  );
};

export default ClaimsInsights;
