import React, {FC} from 'react';
import {Grid, Typography, Divider} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {HeaderContainer, ContainerContent, Button} from '../../../components';

export type OwnProps = {};

export type StateProps = {
  email: string;
};

export type DispatchProps = {
  onBack: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  },
  titleContainer: {
    marginTop: '75px',
  },
  titleText: {
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '32px',
  },
  divider: {
    width: '100%',
    marginTop: '27px',
  },
  contentText: {
    marginTop: '15px',
    fontSize: '16px',
    lineHeight: '23px',
  },
  contentEmail: {
    fontSize: '16px',
    lineHeight: '23px',
    fontWeight: 'bold',
  },
  contentTextContainer: {
    marginTop: '25px',
  },
  instructionsContainer: {
    marginTop: '24px',
  },
  buttonContainer: {
    marginTop: '36px',
    marginBottom: '56px',
  },
  spacer: {
    marginBottom: '55px',
  }
}), {name: 'RequestPasswordPage'});



export const PasswordResetConfirmation: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <HeaderContainer>
      <ContainerContent width={360}>
        <div className={classes.root}>
          <Grid container>
            <Grid className={classes.titleContainer} item xs={12}>
              <Typography variant="h1" className={classes.titleText}>Thank You!</Typography>
            </Grid>
            <Grid className={classes.divider} item xs={12}>
              <Divider/>
            </Grid>
            <Grid className={classes.contentTextContainer} item xs={12}>
              <Typography className={classes.contentText}>A password reset email has been sent to:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.contentEmail}>{props.email}</Typography>
            </Grid>
            <Grid className={classes.instructionsContainer} item xs={12}>
              <Typography variant="subtitle1">Please click the link in the email to reset your password.</Typography>
            </Grid>
            <Grid className={classes.buttonContainer} item xs={12}>
              <Button width={140} onClick={props.onBack}>Back to Sign In</Button>
            </Grid>
          </Grid>
        </div>
      </ContainerContent>
    </HeaderContainer>
  );
};
