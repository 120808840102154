import styled from 'styled-components';
import { colors } from '../../constants/styles';

export const Container = styled.div`
  position: relative;
`;

export const Description = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  color: #ffffff;

  b {
    font-weight: 600;
  }
`;

export const Button1 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  padding: 0 12px 0 12px;
  background: ${colors.routeDark};
  border-radius: 10px;

  border: none;

  transition: 0.25s;
  &:hover {
    opacity: 0.6;
  }
`;

export const Label1 = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  text-align: center;

  color: #ffffff;
`;

export const Button2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;
  padding: 0 12px 0 12px;
  background: #ffffff;
  border-radius: 10px;

  border: none;

  transition: 0.25s;
  &:hover {
    opacity: 0.6;
  }
`;

export const Label2 = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  text-align: center;

  background: linear-gradient(-10deg, #2bffff 0%, #28b8e3 47.33%, #114057 94.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const CloseButton = styled.img<any>`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;
