import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import ReactGA from 'react-ga';
import Button from '../Button/Button';
import './Login.scss';
import './../Form.scss';
import Header from '../Header/Header';
import { Text, TextVariant} from '../Onboarding-2/components/text.tsx';
import {colors} from '../Onboarding-2/constants/styles';

import {loginUser} from '../../services/api';
import {loginUser as newLoginUser} from '../Onboarding-2/actions/sign-in/index';
import {store} from '../../index';
import {shouldUseNewOnboarding} from '../../App';
import {
  Links
} from "./styles";
import {
  ROUTE_TERM_OF_SERVICE_URL,
  ROUTE_PRIVACY_POLICY_URL
} from '../../../src/constants/index';
import { getMerchantServiceTier } from '../../services/merchant_service_tier';

class Login extends Component {
  state = {
    isLoading: false,
    domain: '',
    email: '',
    password: '',
    newPassword: '',
    tempUser: null,
    errorMessage: '',
    checkEmail: false,
    loginError: false,
    redirect: false,
  };

  componentDidMount() {
    if (this.props.userSession.loggedIn) {
      this.setState({redirect: true});
    }
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    const {value, id} = event.target;
    this.setState({
      [id]: value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({isLoading: true});

    const loginBody = {
      username: this.state.email.toLowerCase(),
      password: this.state.password,
    };

    try {
      await store.dispatch(newLoginUser({username: loginBody.username, password: loginBody.password, userInitiatedLogIn: true}));

      if (!this.props.userSession.loggedIn) {
        throw new Error('Login Failed');
      }
    } catch (err) {

      if (!shouldUseNewOnboarding) {
        ReactGA.event({
          category: 'Sign In',
          action: 'Failed Sign In',
        });

        console.error({err});
      }

      this.setState({
        isLoading: false,
        loginError: true,
        errorMessage: 'Incorrect username or password. For help please contact merchantsupport@route.com',
      });
    }
  };

  renderError() {
    const {loginError} = this.state;
    return loginError ? (
      <div className='login-password-error'>{this.state.errorMessage}</div>
    ) : null;
  }

  renderForm() {
    const viewTermsOfSercice = () => {
      window.open(ROUTE_TERM_OF_SERVICE_URL, '_blank');
    }

    const viewPrivacyPolicy = () => {
      window.open(ROUTE_PRIVACY_POLICY_URL, '_blank');
    }
    return (
      <form className='login-form' onSubmit={this.handleSubmit}>
        <label className='form-label'>Email</label>
        <input
          className='form-input'
          id='email'
          type='email'
          placeholder='Email'
          value={this.state.email}
          onChange={this.handleChange}
        />
        <label className='form-label'>Password</label>
        <input
          className='form-input'
          id='password'
          type='password'
          placeholder='Password'
          value={this.state.password}
          onChange={this.handleChange}
        />
        <Text variant={TextVariant.Terms} color={colors.darkGray}>
          {'By continuing, you are agreeing to our '}
          <Links onClick={viewTermsOfSercice}>Terms of Service</Links>
          &nbsp;and <Links onClick={viewPrivacyPolicy}>Privacy Policy.</Links>
        </Text>
        {this.renderError()}
        <Button
          className='login-button'
          type='submit'
          disabled={!this.validateForm()}
          isLoading={this.state.isLoading}
          text='Submit'
          loadingText='Signing in...'
        />
      </form>
    );
  }

  render() {
    const {from} = this.props.location.state || {from: '/'};
    const {redirect} = this.state;

    if (redirect) {
      return <Redirect to={{
        pathname: from,
      }}/>;
    }
    return (
      <div>
        <Header />
        <div className='login form'>
          <div className='form-wrapper'>
            <div className='form-container'>
              <h1 className='form-header'>Sign In</h1>
              <div className='l-form'>{this.renderForm()}</div>
              <div className='l-other-actions'>
                <Link to='/forgot-password' onClick={() => ReactGA.event({ category: 'Sign In', action: '"Forgot Password" click'})} >Forgot Password</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
