import React from 'react';

const SVG = ({
  style = {},
  fill = 'white',
  width = '20px',
  className = '',
  height = '20px',
  viewBox = '0 0 20 20',
  transform = '',
}) => (
  // <svg
  //   width={width}
  //   style={style}
  //   height={height}
  //   viewBox={viewBox}
  //   className={className}
  //   transform={transform}
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  // >
  //   <path fill={fill} d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM8.75 2.5C8.75 1.80875 9.30875 1.25 10 1.25C10.6912 1.25 11.25 1.80875 11.25 2.5V3.75C11.25 4.44125 10.6912 5 10 5C9.30875 5 8.75 4.44125 8.75 3.75V2.5ZM3.75 11.25H2.5C1.80875 11.25 1.25 10.6912 1.25 10C1.25 9.30875 1.80875 8.75 2.5 8.75H3.75C4.44125 8.75 5 9.30875 5 10C5 10.6912 4.44125 11.25 3.75 11.25ZM6.465 6.46375C6.22125 6.7075 5.90125 6.83 5.58125 6.83C5.26125 6.83 4.94125 6.7075 4.6975 6.46375L3.81375 5.58C3.325 5.09125 3.325 4.3 3.81375 3.8125C4.3025 3.325 5.0925 3.32375 5.58125 3.8125L6.465 4.69625C6.9525 5.18375 6.9525 5.975 6.465 6.46375ZM10 17.5C8.62 17.5 7.5 16.38 7.5 15C7.5 14.0775 8.005 13.28 8.75 12.8463V7.5C8.75 6.80875 9.30875 6.25 10 6.25C10.6912 6.25 11.25 6.80875 11.25 7.5V12.8463C11.995 13.28 12.5 14.0763 12.5 15C12.5 16.38 11.38 17.5 10 17.5ZM13.535 6.46375C13.0463 5.975 13.0463 5.185 13.535 4.69625L14.4187 3.8125C14.9075 3.32375 15.6975 3.32375 16.1863 3.8125C16.675 4.30125 16.675 5.09125 16.1863 5.58L15.3025 6.46375C15.0587 6.7075 14.7387 6.83 14.4187 6.83C14.0988 6.83 13.7788 6.7075 13.535 6.46375ZM17.5 11.25H16.25C15.5588 11.25 15 10.6912 15 10C15 9.30875 15.5588 8.75 16.25 8.75H17.5C18.1912 8.75 18.75 9.30875 18.75 10C18.75 10.6912 18.1912 11.25 17.5 11.25Z" />
  // </svg>;

  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9.99995 5.40005C10.4418 5.40005 10.8 5.04188 10.8 4.60005C10.8 4.15822 10.4418 3.80005 9.99995 3.80005C9.55812 3.80005 9.19995 4.15822 9.19995 4.60005C9.19995 5.04188 9.55812 5.40005 9.99995 5.40005ZM14.6 6.20002C14.6 6.64185 14.2418 7.00002 13.8 7.00002C13.3582 7.00002 13 6.64185 13 6.20002C13 5.7582 13.3582 5.40002 13.8 5.40002C14.2418 5.40002 14.6 5.7582 14.6 6.20002ZM10.5 8.19995C10.5 7.92381 10.2761 7.69995 10 7.69995C9.72386 7.69995 9.5 7.92381 9.5 8.19995V12.7853C8.9174 12.9912 8.5 13.5468 8.5 14.2C8.5 15.0284 9.17157 15.7 10 15.7C10.8284 15.7 11.5 15.0284 11.5 14.2C11.5 13.5468 11.0826 12.9912 10.5 12.7853V8.19995ZM15.4001 10.8C15.8419 10.8 16.2001 10.4418 16.2001 9.99995C16.2001 9.55812 15.8419 9.19995 15.4001 9.19995C14.9583 9.19995 14.6001 9.55812 14.6001 9.99995C14.6001 10.4418 14.9583 10.8 15.4001 10.8ZM6.9999 6.20002C6.9999 6.64185 6.64173 7.00002 6.1999 7.00002C5.75807 7.00002 5.3999 6.64185 5.3999 6.20002C5.3999 5.7582 5.75807 5.40002 6.1999 5.40002C6.64173 5.40002 6.9999 5.7582 6.9999 6.20002ZM4.60005 10.8C5.04188 10.8 5.40005 10.4418 5.40005 9.99995C5.40005 9.55812 5.04188 9.19995 4.60005 9.19995C4.15822 9.19995 3.80005 9.55812 3.80005 9.99995C3.80005 10.4418 4.15822 10.8 4.60005 10.8Z"
    />
  </svg>
);

export default SVG;
