import React, { useState, useEffect } from 'react';
import './OnBoardComplete.scss';
import { Grid, Box, Paper } from '@material-ui/core';
import PageTitle from '../_shared/PageTitle/PageTitle';
import LoyaltyIll from '../../../assets/images/loyalty-ill.svg';
import CTAButton from '../_shared/CTAButton/CTAButton';
import IntroText from '../_shared/IntroText/IntroText';
import {
  getInstallOption, getPlatform,
} from '../../../services/api';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import { setOnboardingComplete, getMerchant, hasDealSizeLimitAmount } from '../../../services/merchant';
import PageHeader from '../_shared/PageHeader/PageHeader';
import Paragraph from '../_shared/Paragraph/Paragraph';

const OnBoardComplete = (props) => {
  const [title, setTitle] = useState("All Done!");
  const [button, setButton] = useState({
      href: '#',
      title: 'Go to Brand Portal',
      external: false,
   });
  const [platform, setPlatform] = useState('');
  const [messages, setMessages] = useState([
   'You\'ve successfully completed your installation and configuration of Route!',
   'You should be automatically redirected to the Route "Brand Portal" momentarily, or click the link below to go there now'
  ]);
  const [extraMessage, setExtraMessage] = useState();

  useEffect(() => {
    const merchant = getMerchant();
    handlePlatform();

    if (merchant) {
      merchant.merchantId = merchant.merchantId || merchant.id;
      
      if (!hasDealSizeLimitAmount(merchant) && merchant.platform_id === 'shopify') {
         setTitle('Install Route+ Asset');
         setMessages([
            'As a Shopify store, implementing the Route+ asset on your cart page is straightforward and simple. Click the link below for installation instructions.'
         ]);
         setButton({
            title: 'View Instructions',
            href: 'https://help.route.com/hc/en-us/articles/360038579473-How-to-self-install-Route-on-your-store',
            external: true,
         });
         setExtraMessage({
            title: 'Once you\'ve installed the Route+ asset on your site, go to <a href="/">the merchant portal</a>.',
            href: '#'
         });
       } else {
         redirect();
       }

      setOnboardingComplete(
        merchant.merchantId,
        getInstallOption(),
        true,
      );
    }
  }, [platform]);

  const handlePlatform = () => {
    const platform = getPlatform();
    let name = '';

    switch (platform) {
      case 'api':
        name = 'API';
        break;
      case 'bold':
        name = 'Bold';
        break;
      case 'magento':
        name = 'Magento';
        break;
      case 'woocommerce':
        name = 'Woocommerce';
        break;
      case 'shopify':
        name = 'Shopify';
        break;
      default:
        name = 'Shopify';
        break;
    }

    setPlatform(name);
  };

  const handleRedirectToLogin = () => {
    window.location.href = '/';
  };

  const redirect = () => {
    setTimeout(() => {
      handleRedirectToLogin();
    }, 4000);
  };

  return (
    <div className="onboard-complete">
      <OnboardingContainer>
        <Box display="flex">
          <Box width={721} mr="60px">
            <PageHeader>
              <PageTitle title={title}></PageTitle>
            </PageHeader>
            <Paper className="column">
              {messages && messages.map((message, i) => <Paragraph key={i} text={message}></Paragraph>)}
              <CTAButton
                text={button.title}
                href={button.href}
                onClick={handleRedirectToLogin}
                external={button.external}
              ></CTAButton>
               {extraMessage && (<span className="paragraph extra-paragraph" dangerouslySetInnerHTML={{__html: extraMessage.title}}></span>)}
            </Paper>
          </Box>
          <Box display="flex" mt="60px">
            <img src={LoyaltyIll} alt="Route" className="featured-image"></img>
          </Box>
        </Box>
      </OnboardingContainer>
    </div>
  );
};

export default OnBoardComplete;
