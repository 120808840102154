import React from 'react';
import { Container, Icon } from './IconClose.styles';

import { IIconCloseProps } from './interface';

const IconClose: React.FC<IIconCloseProps> = ({ style }: IIconCloseProps) => (
  <Container style={style}>
    <Icon>
      <path d="M7.62231 2.66406L2.63898 7.66406" stroke="#333333" strokeLinecap="round" />
      <path d="M7.62231 7.66406L2.639 2.66406" stroke="#333333" strokeLinecap="round" />
    </Icon>
  </Container>
);

export default IconClose;
