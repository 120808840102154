import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Invoices from '../Billing/Invoices/Invoices';
import Users from '../Users/Users';
import Preferences from '../Preferences/Preferences';
import NotFound from '../../_shared/ErrorPages/NotFound';
import './Admin.scss';
import { shouldHideRevenueData } from '../../../services/merchant';

const Admin = (props) => {
  const { loggedIn } = props.userSession;

  useEffect(() => {
    if (shouldHideRevenueData(props.userSession)) {
      props.history.push('/orders');
    }
  }, []);

  return loggedIn ? (
    <div className="admin-container-large">
      <Switch>
        <Redirect exact from='/admin' to='/admin/preferences' />
        <Route exact path='/admin/billing' render={() => <Invoices {...props} />} />
        <Route exact path='/admin/users' render={() => <Users {...props} />} />
        <Route exact path='/admin/preferences' render={() => <Preferences {...props} />} />
        <Route path='*' component={NotFound} />
      </Switch>
    </div>
  ) : null;
};

export default Admin;
