import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, MetricTitle, MetricValue } from './MetricItem.styles';

import CircularGraphIcon from '../../../../assets/icons/circle-graph-icon.js';
import circularCheckbox from '../../../../assets/images/circular-checkbox-white.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected-white.svg';

import { IMetricItemProps } from './interface';
import Loading from '../Loading';

const MetricItem: React.FC<IMetricItemProps> = ({
  text, value, color, hasGraph, hasCheck, isChecked, handleCheck, isLoading, handleGraphPress, isActive,
}: IMetricItemProps) => (<Container hasGraph={hasGraph}>
  {hasCheck
    ? (<div style={{ display: 'flex', flexDirection: 'column' }}>
      <Checkbox
        onChange={() => handleCheck()}
        checked={isChecked}
        icon={
          <img
            alt="checkbox icon image"
            srcSet={circularCheckbox}
            width={20}
          />
        }
        checkedIcon={
          <img
            alt="checkbox icon image"
            srcSet={circularCheckboxChecked}
            width={20}
          />
        }
        value="checked"
        color="primary"
      />
    </div>) : null}
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <MetricTitle>
      {text}
    </MetricTitle>
    <MetricValue color={color} hasGraph={hasGraph}>
      {isLoading
        ? (<Loading />)
        : value}
    </MetricValue>
    {!isLoading && hasGraph
      ? (<CircularGraphIcon
        style={{ position: 'absolute', right: '15px' }}
        isActive={isActive}
        circleColor={color}
        doOnClick={handleGraphPress} />)
      : null}
  </div>
</Container>);

export default MetricItem;
