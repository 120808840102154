import React, { useState, useEffect } from 'react';
import moment from 'moment';
import FolderIcon from '../../../../../assets/icons/folder-icon';
import UserIcon from '../../../../../assets/icons/user-icon';
import RouteIcon from '../../../../../assets/icons/route-icon';
import '../../../_shared/_shared.scss';
import { formatClaimStatus, isClaimResolved, formatClaimType } from '../../../../../utils/claims';
import colors from '../../../../../constants/colors';

const ClaimForm = (props) => {
  const [claimStatus, setClaimStatus] = useState('');
  const [claimType, setClaimType] = useState('');

  useEffect(() => {
    setClaimStatus(props.data.custom_fields.find((field) => field.id === 360020728733).value);
    setClaimType(props.data.custom_fields.find((field) => field.id === 360015935754).value);
  }, []);

  return (
    <div className="form-display-container">
      <div className="form-data-inner-container primary-column">
        <div className="form-block">
          <RouteIcon fill={colors.primaryColor} className="icon icon-small" height="20px" width="20px" />
          <div className="form-group">
            <div className="form-data-container">
              <span className="form-data-label">Issue ID</span>
              <span>{props.data.id || <span className="empty-form-data">Not Provided</span>}</span>
            </div>
            <div className="form-data-container">
              <span className="form-data-label">Issue Type</span>
              <span style={{ textTransform: 'capitalize' }}>
                {formatClaimType(claimType) || <span className="empty-form-data">Not Provided</span>}
              </span>
            </div>
            <div className="form-data-container">
              <span className="form-data-label">Issue Reported</span>
              <span>{moment(props.data.created_at).format('MM/DD/YYYY')}</span>
            </div>
            <div className="form-data-container">
              <span className="form-data-label">Issue Status</span>
              <span>{formatClaimStatus(claimStatus) || <span className="empty-form-data">Not Provided</span>}</span>
            </div>
            <div className="form-data-container">
              <span className="form-data-label">Amount Paid</span>
              <span>
                {props.data.custom_fields.find((field) => field.id === 360015990133).value || (
                  <span className="empty-form-data">Not Provided</span>
                )}
              </span>
            </div>
            {props.data.custom_fields.find((field) => field.id === 360034965813).value && (
              <div className="form-data-container">
                <span className="form-data-label">Replacement Order ID</span>
                <span>{props.data.custom_fields.find((field) => field.id === 360034965813).value}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="form-data-inner-container second-column">
        <div className="form-block">
          <UserIcon fill={colors.primaryColor} className="icon" height="20px" width="20px" />
          <div className="form-group">
            <div className="form-data-container">
              <span className="form-data-label">Customer Email</span>
              <span>{props.data.email || <span className="empty-form-data">Unavailable</span>}</span>
            </div>
          </div>
        </div>
        <div className="form-block bordered">
          <FolderIcon fill={colors.primaryColor} className="icon icon-folder" />
          <div className="form-group">
            <div className="form-data-container">
              <span className="form-data-label">Store Name</span>
              <span>
                {props.userSession.activeBrand.store_name || <span className="empty-form-data">Not Provided</span>}
              </span>
            </div>
            {props.data.custom_fields.find((field) => field.id === 360050956473).value && (
              <div className="form-data-container">
                <span className="form-data-label">Store Order Number</span>
                <span>
                  {props.data.custom_fields.find((field) => field.id === 360050956473).value || (
                    <span className="empty-form-data">Not Provided</span>
                  )}
                </span>
              </div>
            )}
            <div className="form-data-container">
              <span className="form-data-label">Store Order ID</span>
              <span>
                {props.data.custom_fields.find((field) => field.id === 360016141754).value || (
                  <span className="empty-form-data">Not Provided</span>
                )}
              </span>
            </div>
            <div className="form-data-container">
              <span className="form-data-label">Route Order Number</span>
              <span>
                {props.data.custom_fields.find((field) => field.id === 360015989293).value || (
                  <span className="empty-form-data">Not Provided</span>
                )}
              </span>
            </div>
            <div className="form-data-container">
              <span className="form-data-label">Tracking Number</span>
              <span>
                {props.data.custom_fields.find((field) => field.id === 360020877693).value || (
                  <span className="empty-form-data">Not Provided</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimForm;
