declare const pendo: any;

export const pendoTrack = (name: string, data = {}): void => {
  if (pendo && pendo.isReady && pendo.isReady()) {
    return pendo.track(name, data);
  }
  setTimeout(() => {
    pendoTrack(name, data);
  }, 500);
};

export const pendoShowGuideById = (id: string, attempts = 3): void => {
  if (pendo && pendo.isReady && pendo.isReady()) {
    return pendo.showGuideById(id);
  }

  attempts -= 1;
  if (attempts > 0) {
    setTimeout(() => {
      pendoShowGuideById(id, attempts);
    }, 500);
  } else {
    window.open(`/?pendo=${id}`, '_self');
  }
};

export default {
  pendoTrack,
};
