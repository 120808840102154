import React, { Component } from 'react';
import './List.scss';
import ListItem from '../ListItem/ListItem';

class List extends Component {
  renderListItems() {
    const { items } = this.props;

    if (!items) {
      return;
    }

    return (
      items.map((item, i) => <ListItem key={i} value={item} />)
    );
  }

  render() {
    return (
      <ul className="list">
        {this.renderListItems()}
        {this.props.children}
      </ul>
    );
  }
}

export default List;
