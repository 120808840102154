import React from 'react';
import { Container } from './CardHeader.styles';

import { ICardHeaderProps } from './interface';

const CardHeader: React.FC<ICardHeaderProps> = ({ children }: ICardHeaderProps) => (
  <Container>
    {children}
  </Container>
);

export default CardHeader;
