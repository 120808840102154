import { setUser, setTags, init } from "@sentry/browser";
import { BrowserUserSessionData } from "../stores/SessionManager";
import { RouteApi } from "../components/Onboarding-2/api/types";
// @ts-ignore
import { SENTRY_DSN } from '../constants';
import { UserLoggedInData } from "../components/Onboarding-2/actions/sign-in";

export type GlobalSentryData = {
  browserSessionData?: BrowserUserSessionData|null;
  reduxSessionData?: UserLoggedInData;
}

export function setGlobalSentryUserData(data: GlobalSentryData) {
  let userId: string|null = null;
  let merchantId: string|null = null;
  let loggedIn = false;

  if (data.browserSessionData) {
    userId = (data.browserSessionData.user && (data.browserSessionData.user as RouteApi.User).id) ?? null;
    merchantId = (data.browserSessionData.activeBrand && (data.browserSessionData.activeBrand as RouteApi.Merchant).id) ?? null;
    loggedIn = data.browserSessionData && data.browserSessionData.loggedIn;
  } else if (data.reduxSessionData) {
    userId = data.reduxSessionData.user.id;
    merchantId = data.reduxSessionData.activeBrand.id;
    loggedIn = true;
  }

  setUser(userId === null ? null : {id: userId});
  setTags({
    merchant: merchantId ?? 'Unknown',
    // Typescript doesn't have the `toString` method on booleans defined
    loggedIn: String(loggedIn),
  });
}

export function initializeSentry() {
  // Values will be "development", "test", "stage", or "prod"
  // NODE_ENV values can be "development", "test", or "production"
  // NODE_ENV will be "production" while in stage so we need to detect that ourselves
  const environment = process.env.NODE_ENV !== 'production' ?
    process.env.NODE_ENV :
    window.location.hostname.includes('stage') ?
      'stage' :
      'prod';
  const releaseVersion = process.env.REACT_APP_SENTRY_RELEASE_VERSION;
  
  init({
    environment,
    dsn: SENTRY_DSN,
    debug: environment !== 'prod',
    // This will also send stacks when we send messages (vs errors)
    attachStacktrace: true,
    // This is OK to be undefined, we only really care about this
    // during a production build. This variable will be set by our
    // build script during a production build.
    // When not defined, the build will insert the string "undefined"
    release: !releaseVersion || releaseVersion === 'undefined' ? 
      undefined :
      releaseVersion,
  });
  setTags({
    referrer: document.referrer,
  });
}
