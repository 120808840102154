import React from 'react';

const SVG = ({
  style = {},
  fill = '#66C1CB',
  width = '9px',
  className = '',
  height = '9px',
  viewBox = '0 0 9 9',
  transform = 'rotate(90)',
}) => <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

<path d="M9.81665 8.06672L6.75173 5L9.81789 1.93328C10.0607 1.69165 10.0607 1.29887 9.81789 1.05725L8.94078 0.181216C8.69921 -0.0604052 8.30649 -0.0604052 8.06492 0.181216L5 3.24794L1.93385 0.181216C1.69227 -0.0604052 1.29955 -0.0604052 1.05798 0.181216L0.182111 1.05848C-0.0607037 1.30011 -0.0607037 1.69289 0.182111 1.93451L3.24827 5.00124L0.182111 8.06672C-0.0607037 8.30834 -0.0607037 8.70113 0.182111 8.94275L1.05798 9.81878C1.29955 10.0604 1.69227 10.0604 1.93385 9.81878L5 6.75206L8.06492 9.81878C8.30649 10.0604 8.69921 10.0604 8.94078 9.81878L9.81665 8.94275C10.0607 8.69989 10.0607 8.30834 9.81665 8.06672Z" fill={fill}/>


    </svg>;

export default SVG;
