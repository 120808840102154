import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  margin-top: 16px;
  margin-left: 16px;
`;

export const Label = styled.div`
  display: inline-block;
  font-family: 'Titillium Web', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  margin-left: 8px;
`;

export const Logo = styled.img`
  border-radius: 4px;
  vertical-align: middle;
  width: 32px;
`;
