import React, { FC, useRef, useEffect } from 'react';
import { addBreadcrumb, Severity } from '@sentry/browser';
import { PENDO_API_KEY } from '../../../../constants';
import dictionary from '../../../../constants/dictionary';

declare const pendo: any;

type PendoProps = {
  visitorId?: any;
  accountId?: any;
  creationDate?: Date;
  merchantName?: string;
  merchantDomain?: string;
  platform?: string;
  fullName?: string;
  planTier?: any;
  serviceTier?: any;
  anonymous?: boolean;
};

const Pendo: FC<PendoProps> = (props: PendoProps) => {
  const instance = useRef(null);
  const prefix = process.env?.REACT_APP_STAGE === 'prod' ? '' : 'stage_';
  const {
    visitorId,
    accountId,
    creationDate,
    merchantName,
    merchantDomain,
    platform,
    fullName,
    planTier,
    anonymous,
  } = props;

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://cdn.pendo.io/agent/static/${PENDO_API_KEY}/pendo.js`;
    (instance.current! as HTMLElement).appendChild(scriptTag);

    setTimeout(() => {
      try {
        if (pendo) {
          if (anonymous === true) {
            pendo.initialize();
          } else {
            pendo.initialize({
              visitor: {
                id: prefix + visitorId,
                full_name: fullName,
              },
              account: {
                id: prefix + accountId,
                created_on: creationDate,
                platform,
                merchant_name: merchantName,
                merchant_domain: merchantDomain,
                plan_tier: planTier?.service_tier,
                service_tier: planTier,
              },
            });
          }
        }
      } catch (error) {
        addBreadcrumb({
          message: dictionary.MESSAGE_ERROR_INITIALIZE_PENDO,
          category: 'pendo',
          level: Severity.Info,
        });
      }
    }, 1000);
  }, []);

  return <div ref={instance}></div>;
};

export default Pendo;
