import React from 'react';

export const Logo = (props: {width?: number, height?: number}) => (
  <svg width={props.width ?? 109} height={props.height ?? 83} viewBox="0 0 109 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8154 82.4764H11.5406L3.83781 71.455H6.76926C7.45868 71.465 8.14758 71.4121 8.82762 71.2969C9.25157 71.2277 9.66895 71.1221 10.0752 70.9813C10.7092 70.7134 11.2476 70.2565 11.6189 69.6713C11.957 69.1728 12.1462 68.5862 12.1639 67.9818C12.162 67.3867 11.9766 66.8071 11.6337 66.3239C11.2411 65.7758 10.7145 65.3404 10.1055 65.0606C9.38303 64.722 8.59377 64.5546 7.79778 64.5712H3.08854L3.15089 82.4771H0.5V61.9178H7.79778C8.73303 61.9105 9.66217 62.0709 10.5422 62.3916C11.3704 62.6928 12.133 63.1539 12.7876 63.7494C13.3958 64.2969 13.889 64.9628 14.2377 65.7074C14.5748 66.4169 14.7507 67.1938 14.7527 67.9811C14.7627 69.1934 14.3669 70.3734 13.6297 71.3287C13.3021 71.7663 12.9197 72.159 12.4922 72.4969C12.0636 72.8432 11.5981 73.1398 11.1044 73.3812C10.4193 73.659 9.69016 73.8086 8.95232 73.8227L14.8154 82.4764Z" fill="black"/>
    <path d="M31.4393 82.6663C29.6246 82.6818 27.84 82.196 26.2777 81.261C24.719 80.3301 23.4281 79.0022 22.5335 77.4093C21.6389 75.8164 21.1718 74.0141 21.1787 72.1817C21.1642 70.3414 21.6381 68.5309 22.5508 66.9394C23.4433 65.3626 24.7287 64.0505 26.2786 63.1339C27.8412 62.2135 29.6167 61.7286 31.4245 61.7286C33.2322 61.7286 35.0078 62.2135 36.5704 63.1339C38.122 64.0532 39.4115 65.3644 40.3128 66.9394C41.222 68.536 41.7006 70.3466 41.7006 72.1896C41.7006 74.0326 41.222 75.8433 40.3128 77.4399C39.4154 79.022 38.1253 80.3392 36.5704 81.261C35.0181 82.1932 33.244 82.6791 31.4393 82.6663ZM31.4393 64.3498C30.0841 64.3394 28.7519 64.7053 27.5877 65.4078C26.4275 66.0984 25.4653 67.0835 24.7962 68.2658C24.1218 69.4637 23.7672 70.8188 23.7672 72.1976C23.7672 73.5764 24.1218 74.9314 24.7962 76.1293C25.465 77.3119 26.4273 78.297 27.5877 78.9873C28.7559 79.6801 30.0854 80.0454 31.4393 80.0454C32.7932 80.0454 34.1227 79.6801 35.2909 78.9873C36.4508 78.2969 37.4127 77.3118 38.0813 76.1295C38.7557 74.9317 39.1103 73.5766 39.1103 72.1978C39.1103 70.819 38.7557 69.4639 38.0813 68.2661C37.4124 67.084 36.4506 66.0989 35.2909 65.4081C34.1267 64.7056 32.7946 64.3396 31.4393 64.3498Z" fill="black"/>
    <path d="M56.9806 82.6662C55.9365 82.6696 54.9024 82.4602 53.9399 82.0503C52.9779 81.6437 52.1117 81.0356 51.3982 80.266C50.6593 79.5233 50.0758 78.6374 49.6826 77.6612C49.2859 76.6735 49.0847 75.6165 49.0903 74.5502V61.9182H51.6788V74.5502C51.6764 75.2755 51.8141 75.9943 52.0841 76.6661C52.3468 77.3285 52.7394 77.9301 53.2382 78.4345C54.3399 79.5088 55.5874 80.0459 56.9806 80.0459C57.6783 80.0479 58.3687 79.9028 59.008 79.6197C59.6485 79.3382 60.2305 78.9364 60.7232 78.4354C61.2218 77.9308 61.6143 77.3293 61.8771 76.667C62.1469 75.9951 62.2846 75.2764 62.2826 74.5511V61.9182H64.8702V74.5502C64.8823 76.6843 64.0645 78.7375 62.5936 80.266C61.8685 81.0319 60.9966 81.6397 60.0313 82.0525C59.066 82.4652 58.0277 82.674 56.9799 82.6662H56.9806Z" fill="black"/>
    <path d="M88.6663 61.9182V64.5709H81.7738V82.4768H79.1229V64.5709H72.2306V61.9182H88.6663Z" fill="black"/>
    <path d="M96.0261 82.4768V61.9182H109V64.5709H98.6146V70.8554H106.568V73.5081H98.6146V79.8556H109V82.4768H96.0261Z" fill="black"/>
    <path d="M89.7204 30.4395C80.4853 21.0968 71.8988 11.9159 62.7382 3.34477C57.9367 -1.14776 53.4358 -0.985976 48.2538 3.0578C43.6417 6.65785 39.5353 10.9014 34.6423 15.3753C42.6779 17.2524 47.3844 22.3426 52.2067 27.4019C56.7868 32.2058 61.5981 36.8052 66.5045 41.2798C70.0639 44.5258 74.2835 44.4562 77.8661 41.5585C82.078 38.1536 85.807 34.1565 89.7204 30.4395ZM26.0101 42.5202C31.3737 37.2249 36.4716 32.1925 41.6272 27.1031C38.4917 21.623 29.549 21.4863 25.1491 25.8655C21.0963 29.8993 19.5401 37.7038 26.0101 42.5202Z" fill="#5DC8DB"/>
  </svg>
);
