import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { AppState } from '../store'
import { accountCreationReducer } from './pages/account-creation';
import { redirectingReducer, setUserSessionOutsideStateStoreFnReducer } from './pages/redirect';
import { signInStateReducer } from './pages/sign-in';
import { userDataReducer } from './user';
import { userErrorReducer } from './user-error';
import { loadingReducer } from './loading';
import { onboardingReducer } from './onboarding';
import { brandReducer } from './../../Brand/reducers'

const appStateReducer = combineReducers<AppState>({
  setUserSessionOutsideStoreFunction: setUserSessionOutsideStateStoreFnReducer,
  redirecting: redirectingReducer,
  signIn: signInStateReducer,
  accountCreation: accountCreationReducer,
  userData: userDataReducer,
  userError: userErrorReducer,
  loading: loadingReducer,
  onboarding: onboardingReducer,
  brand: brandReducer,
});

export const getRootReducer = (history: History<any>) => {
  return combineReducers({
    router: connectRouter(history),
    app: appStateReducer,
  });
}
