import axios from 'axios';
import { getMerchantToken } from './api';
import { ROUTE_API } from '../constants';
import { RouteApi } from '../components/Onboarding-2/api/types';

const getRequestOptions = (token: string) => ({
  headers: {
    'Content-Type': 'application/json',
    token: token || getMerchantToken(),
  },
});

export const createStripeCardAccount = async (stripeData: any, token: string) => createStripeBillingMethod({ ...stripeData, type: 'card' }, token);

export const createStripeBankAccount = async (stripeData: any, token: string) => createStripeBillingMethod({ ...stripeData, type: 'bank' }, token);

export const createStripeBillingMethod = async (stripeData: any, token: string) => {
  stripeData.source_token = stripeData.id;
  delete stripeData.id;

  try {
    return await axios.post(`${ROUTE_API}/billing`, stripeData, getRequestOptions(token));
  } catch (err) {
    console.log(err);
    console.error('Error creating billing account : ', err.response);
  }
};

export const createStripeBankAccountViaPlaid = async (data: any, token: string) => {
  const reqData = { ...data, type: 'plaid' };

  try {
    return await axios.post(
      `${ROUTE_API}/billing`,
      reqData,
      getRequestOptions(token),
    );
  } catch (err) {
    console.error('Error creating billing account : ', err.response);
  }
};

export const getBilling = async (token: string): Promise<RouteApi.BillingRecord[] | undefined> => {
  try {
    const billingResp = await axios.get<RouteApi.Billing.GetAllBillingRecordsResponse>(`${ROUTE_API}/billing`, getRequestOptions(token));
    return billingResp.data;
  } catch (err) {
    console.error('Error getting billing account : ', err.response);
  }
};
// send 2 deposit values, and verify values match ACH
export const verifyBankDeposit = async (data: any, token: string) => {
  const options = getRequestOptions(token);
  try {
    return await axios.post(
      `${ROUTE_API}/billing/verify_deposit`,
      data,
      options,
    );
  } catch (err) {
    console.error('Error verifying payment method: ', JSON.stringify(err));
  }
};

export const getInvoice = (merchantId: string, date: string, token: string, cancelToken: any) => {
  const options = getRequestOptions(token);
  return axios.get(
    `${ROUTE_API}/merchants/${merchantId}/invoice_weekly/${date}`,
    options,
  );
};
