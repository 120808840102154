import React from 'react';

const SVG = ({
                 style = {},
                 fill = '#F06350',
                 width = '11',
                 height = '11',
                 className = '',
                 viewBox = '0 0 11 11',
                 transform = '',
             }) => <svg
                    style={style}
                    fill="none"
                    width={width}
                    height={height}
                    className={className}
                    viewBox={viewBox}
                    transform={transform}
                    xmlns="http://www.w3.org/2000/svg"
                >
        <path fill={fill} d="M0 1.62132V7C0 7.82843 0.671573 8.5 1.5 8.5H6.87868C8.21504 8.5 8.88429 6.88429 7.93934 5.93934L2.56066 0.56066C1.61571 -0.384287 0 0.284963 0 1.62132Z" />
    </svg>
;

export default SVG;
