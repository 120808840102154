import styled from 'styled-components';
import colors from '../../../../constants/colors';

export const Header1 = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  background: linear-gradient(-10deg, #2bffff 0%, #28b8e3 47.33%, #114057 94.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ButtonPrimary = styled.button`
  height: 30px;
  padding: 0px 16px 0px 16px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;

  color: #ffffff;
  background: ${colors.primaryButtonBackground};

  border: none;
  border-radius: 16px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const Button1 = styled.button`
  /* min-width: 200px; */
  height: 30px;
  padding: 0px 16px 0px 16px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;

  color: #ffffff;

  background: linear-gradient(-10deg, #2bffff 0%, #28b8e3 47.33%, #114057 94.66%);
  border: none;
  border-radius: 16px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const Button2 = styled.button`
  height: 30px;
  padding: 0px 16px 0px 16px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  text-align: center;

  color: #5dc8db;

  background: #ffffff;
  border: none;
  border-radius: 16px;

  transition: 0.25s;

  &:hover {
    opacity: 0.6;
  }
`;

export const Title2 = styled.h3`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;

  text-align: center;

  color: #ffffff;
`;

export const SubTitle2 = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;

  text-align: center;

  color: #ffffff;
`;

export const CloseButton = styled.img<any>`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;
