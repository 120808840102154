import React, { FC } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styleMap from '../constants/styles';
import { defaultButtonBorderRadius } from '../constants/theme';

type StylesProps = {
  width?: number;
  height?: number;
  radius?: number;
  textColor?: string;
  bgColor?: string;
  hoverBgColor?: string;
  disabled?: boolean;
  fontSize?: string;
  lineHeight?: string;
  maxWidth?: number | string;
}

const useRouteButtonStyles = makeStyles({
  root: {
    height: (props: StylesProps) => props.height || '40px',
    width: (props: StylesProps) => props.width ?? 'auto',
    minWidth: (props: StylesProps) => props.width ?? 'auto',
    maxWidth: (props: StylesProps) => props.maxWidth ?? 'none',
    borderRadius: (props: StylesProps) => props.radius ?? defaultButtonBorderRadius,
    backgroundColor: (props: StylesProps) => props.disabled ? styleMap.disabledButtonColor : (props.bgColor || styleMap.buttonColor),
    '&:hover': {
      cursor: 'hand',
      backgroundColor: (props: StylesProps) => props.disabled ? styleMap.disabledButtonColor : (props.hoverBgColor || styleMap.buttonHoverColor),
    },
    padding: 0,
  },
  label: {
    color: (props: StylesProps) => props.textColor ?? styleMap.buttonTextColor,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: (props: StylesProps) => props.fontSize || '14.5px',
    lineHeight: (props: StylesProps) => props.lineHeight || '16px',
    textAlign: 'center',
  },
  text: {
    padding: 0,
  }
}, { name: 'RouteButton' });

export type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  radius?: number;
  width?: number;
  height?: number;
  textColor?: string;
  bgColor?: string;
  hoverBgColor?: string;
  fontSize?: string;
  lineHeight?: string;
  maxWidth?: number | string;
} & MuiButtonProps

export const Button: FC<ButtonProps> = (props) => {
  const {
    width,
    height,
    radius,
    textColor,
    bgColor,
    hoverBgColor,
    fontSize,
    lineHeight,
    disabled,
    loading,
    maxWidth,
    ...nonStyleProps
  } = props;
  const classes = useRouteButtonStyles({
    disabled,
    width,
    height,
    textColor,
    bgColor,
    hoverBgColor,
    fontSize,
    lineHeight,
    maxWidth,
  });
  const { onClick, children, ...rest } = nonStyleProps;

  return (
    <MuiButton
      {...rest}
      disabled={disabled}
      disableRipple
      disableFocusRipple
      classes={{ root: classes.root, label: classes.label, text: classes.text }}
      onClick={props.onClick}
    >
      {loading ? 'Loading...' : props.children}
    </MuiButton>
  );
}
