import React from 'react';

const SVG = ({
  style = {},
  fill = '#66C1CB',
  width = '9px',
  className = '',
  height = '9px',
  viewBox = '0 0 9 9',
  transform = 'rotate(90)',
}) => <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M7.924 6.51025L5.45 4.03525L7.925 1.56025C8.121 1.36525 8.121 1.04825 7.925 0.85325L7.217 0.14625C7.022 -0.04875 6.705 -0.04875 6.51 0.14625L4.036 2.62125L1.561 0.14625C1.366 -0.04875 1.049 -0.04875 0.854 0.14625L0.147 0.85425C-0.049 1.04925 -0.049 1.36625 0.147 1.56125L2.622 4.03625L0.147 6.51025C-0.049 6.70525 -0.049 7.02225 0.147 7.21725L0.854 7.92425C1.049 8.11925 1.366 8.11925 1.561 7.92425L4.036 5.44925L6.51 7.92425C6.705 8.11925 7.022 8.11925 7.217 7.92425L7.924 7.21725C8.121 7.02125 8.121 6.70525 7.924 6.51025Z"/>
  </svg>;

export default SVG;
