import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import circularCheckbox from '../../../../assets/images/circular-checkbox.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected.svg';
import Button from '../../../Button/Button';
import './UpdateRoutePlus.scss';
import TitleAndNav from './TitleAndNav';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import {
  upsertMerchant,
  getMerchantPreference,
} from '../../../../services/merchant';
import bulletSquare from '../../../../assets/images/bullet-square-icon.jpg';
import routePlusSampleEmail from '../../../../assets/images/visually-tracking-orders.jpg';
import './FreeTrackingEmail.scss';

const FreeTrackingEmail = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentChecked, setCurrentChecked] = useState(0);
  const checkboxes = [
    'Send this email on all future orders',
    'You have your own tracking solution',
  ];

  useEffect(() => {
    const activeCheckbox =			getMerchantPreference(
			  props.userSession.activeBrand,
			  'optional_tracking_email',
    ) === true
			  ? 0
			  : 1;
    setCurrentChecked(activeCheckbox);
  }, []);

  const handleSubmitButton = async (e) => {
    const {
      merchant_preferences,
      id,
      prod_api_secret,
      has_route_plus,
    } = props.userSession.activeBrand;

    ReactGA.event({ category: 'Admin - Preferences', action: 'Free Tracking Email change', label: `optional_tracking_email:${(currentChecked === 0)}` });

    const merchantPreferences = Object.assign(merchant_preferences || {}, {
      optional_tracking_email: currentChecked === 0,
	 });

	 setLoading(true);

    const resp = await upsertMerchant(
      'merchant_preferences',
      JSON.stringify(merchantPreferences),
      id,
      prod_api_secret,
	 );

	 setLoading(false);

    if (resp.data) {
      resp.data = Object.assign(resp.data, { has_route_plus });
      props.updateActiveBrand(resp.data);
    }

    props.closeDrawer();
    e.preventDefault();
  };

  return (
		<div className="update-payment-method update-route-plus free-tracking-email">
			<TitleAndNav
				close={props.closeDrawer}
				title="Free Tracking Email"
			/>

			<img
				srcSet={routePlusSampleEmail}
				className="sample-image"
			></img>

			<div className="update-payment-method-upper-text">
				<p>
					When your customers make an online purchase from your site,
					you have the option to send them an email with links to:
				</p>
			</div>

			<ul className="nested-bullet-list">
				<li>
					<img srcSet={bulletSquare} className="bullet" />
					<span>
						A branded web page where your customers can track each
						order
					</span>
				</li>
				<li>
					<img srcSet={bulletSquare} className="bullet" />
					<span>
						Download the Route mobile app where customers can see
						all orders from you over time
					</span>
				</li>
			</ul>

			<div className="update-payment-method-upper-text free-tracking-email-upper-text">
				<p>
					This additional web and in-app tracking is available to all
					of your customers - at no additional cost to you.
				</p>
			</div>

			<List
				component="nav"
				aria-labelledby="nested-list-subheader"
				className="nested-list"
			>
				{checkboxes.map((item, i) => (
					<ListItem
						key={i}
						onClick={() => setCurrentChecked(i)}
						alignItems="flex-start"
						className="item-list"
					>
						<ListItemIcon className="checkbox">
							<Checkbox
								onChange={() => setCurrentChecked(i)}
								checked={currentChecked === i}
								icon={
									<img
										alt="checkbox icon image"
										srcSet={circularCheckbox}
										width={20}
									/>
								}
								checkedIcon={
									<img
										alt="checkbox icon image"
										srcSet={circularCheckboxChecked}
										width={20}
									/>
								}
								value="checked"
								color="primary"
							/>
						</ListItemIcon>
						<ListItemText primary={item} />
					</ListItem>
				))}
			</List>
			<Button
				className="update-company-details-save-button"
				type="submit"
				onClick={handleSubmitButton}
				// disabled={!this.validateForm()}
				isLoading={loading}
				text="Save"
			/>
		</div>
  );
};

export default FreeTrackingEmail;
