import { addBreadcrumb, Severity } from '@sentry/browser';
import dictionary from '../constants/dictionary';
import openApi from './new-open-api';
import { getHeadersWithToken } from './utils';

export const getCurrentUserId = () => {
  try {
    return sessionStorage.getItem('uId');
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_RETRIEVE_USER_ID,
      category: 'user',
      level: Severity.Error,
    });
    return false;
  }
};

export const getMerchantRole = async (userId: string, token: string, merchantId: string) => {
  try {
    const { data } = await openApi.get(
      `/users/${userId}/merchant-roles?merchant_id=${merchantId}`,
      getHeadersWithToken(token),
    );
    return data[0];
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_RETRIEVE_MERCHANT_ROLES,
      category: 'media',
      data: {
        merchantId,
        error: err,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export default {
  getCurrentUserId,
  getMerchantRole,
};
