import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { startOnboarding } from '../../actions/welcome/welcome';
import { OwnProps, StateProps, Welcome } from '../../pages/welcome/welcome';

export const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps?: OwnProps): StateProps => {
  return {
    startOnboarding: () => dispatch(startOnboarding()),
  };
}

export const ConnectedWelcome = connect(null, mapDispatchToProps)(Welcome);
