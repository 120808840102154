import React, { useEffect, useState } from 'react';
import './Subnav.scss';

const Subnav = (props) => {
  const [selected, setSelected] = useState(props && props.pages && props.pages[0] || {});

  useEffect(() => {
    props.pages.forEach((page) => {
      if (props?.location?.pathname.includes(page.pathname)) {
        setSelected(page);
      }
    });
  }, []);

  return (
    <div className="portal-content-container">
      <h1>{props.header}</h1>
      <div className="admin-subnav-container">
        {props.pages.map((page, index) =>
          <span key={index} className="link"
            onClick={() => {
              setSelected(page);
              props.history.push(page.pathname);
            }}>
            <div className={`admin-subnav-label ${selected.pathname === page.pathname ? 'active-admin-nav' : ''}`}>
              <span>{page.pageName}</span>
            </div>
          </span>)
        }
      </div>
    </div>
  );
};

export default Subnav;
