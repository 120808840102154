import styled from 'styled-components';
import Colors from '../../../../constants/colors';

export const Container = styled.li<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.primaryBoxBackground};
  margin-bottom: 10px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #FBFBFB;
  border-radius: 16px;
  flex: none;
  order: 3;
  align-self: center;
  flex-grow: 0;
  margin: 0px 0px;
  min-height: 168px;

  @media screen and (max-width: 1200px) {
    min-height: auto;
  }

  &:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
    border: none;
  }

  @media screen and (max-width: 1200px) {
    margin-top: 40px;
  }
`;

export const MetricTitle = styled.span<any>`
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: ${Colors.darkGray};
  margin-top: 10px;
`;

export const MetricValue = styled.span<any>`
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : Colors.primaryColor)};
  margin: 4px 0;
`;
