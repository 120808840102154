import React, {FC} from 'react';
import {withStyles, StyleRules, ClassNameMap, ClassKeyOfStyles} from '@material-ui/styles';

export enum TextVariant {
  Title = 'Title',
  Subtitle = 'Subtitle',
  Body1 = 'Body1',
  Body2 = 'Body2',
  Body3 = 'Body3',
  Body4 = 'Body4',
  Label = 'Label',
  Input = 'Input',
  Caption = 'Caption',
  Description = 'Description',
  Button = 'Button',
  Terms = 'Terms',
}
const textVariantKeys = Object.keys(TextVariant);

export type TextVariantDefinition = {
  fontWeight: number|'bold'|'normal';
  fontSize: number;
  lineHeight: number;
};
export type TextVariantMap = {
  [K in keyof typeof TextVariant]: TextVariantDefinition;
};

export const textVariantDefinitions: TextVariantMap = {
  [TextVariant.Title]: {
    fontWeight: 'bold',
    fontSize: 32,
    lineHeight: 33,
  },
  [TextVariant.Subtitle]: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 17,
  },
  [TextVariant.Body1]: {
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 20,
  },
  [TextVariant.Body2]: {
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 17,
  },
  [TextVariant.Body3]: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: 23,
  },
  [TextVariant.Body4]: {
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: 14,
  },
  [TextVariant.Label]: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 14,
  },
  [TextVariant.Input]: {
    fontWeight: 'normal',
    fontSize: 11.5,
    lineHeight: 17,
  },
  [TextVariant.Caption]: {
    fontWeight: 'normal',
    fontSize: 8,
    lineHeight: 11,
  },
  [TextVariant.Description]: {
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: 18,
  },
  [TextVariant.Button]: {
    fontWeight: 'bold',
    fontSize: 14.5,
    lineHeight: 16,
  },
  [TextVariant.Terms]: {
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: 16,
  }
}

function activeVariant(variant?: TextVariant | TextVariantDefinition): TextVariantDefinition {
  if (!variant) {
    return textVariantDefinitions[TextVariant.Body1];
  }

  if (textVariantKeys.includes(variant as any)) {
    return textVariantDefinitions[variant as TextVariant];
  }

  return variant as TextVariantDefinition;
}

const styles: StyleRules<TextProps, 'root'> = {
  root: {
    display: (props) => props.inline ? 'inline' : 'block',
    fontFamily: 'Titillium Web, sans-serif',
    color: (props) => props.color ?? 'black',
    fontSize: (props) => props.fontSize ?? activeVariant(props.variant).fontSize,
    lineHeight: (props) => props.lineHeight ?? `${activeVariant(props.variant).lineHeight}px`,
    fontWeight: (props) => props.fontWeight ?? activeVariant(props.variant).fontWeight,
  }
};
export type StyleClassKey = ClassKeyOfStyles<typeof styles>;
export type StyleMap = ClassNameMap<StyleClassKey>;

export type TextProps = {
  children: React.ReactFragment;
  variant?: TextVariant | TextVariantDefinition;
  className?: string;
  classes?: Partial<StyleMap>;
  inline?: boolean;
  color?: string;
  fontSize?: TextVariantDefinition['fontSize'];
  lineHeight?: TextVariantDefinition['lineHeight'];
  fontWeight?: TextVariantDefinition['fontWeight'];
}

const TextComponent: FC<TextProps> = (props) => {
  const {
    children,
    className: consumerClassName,
    classes: wronglyTypedClasses,
  } = props;
  // The "classes" prop is defined as partial and optional because a consumer
  // shouldn't worry about having to define it or fully define it. The `withStyles`
  // HOC will combine our default values defined in `styles` with any values
  // passed in via props resulting in a fully defined object. We typecast to
  // that fully defined object here.
  const classes = wronglyTypedClasses as ClassNameMap<StyleClassKey>;

  return (
    <div className={classes.root + ' ' + consumerClassName}>{children}</div>
  );
};

export const Text = withStyles(styles, {name: 'RouteText'})(TextComponent);
