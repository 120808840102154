import styled from 'styled-components';
import colors from '../../../../../constants/colors';

export const Container = styled.div`
  display: flex;
  position: relative;
  margin: 0 80px;
`;

export const FormContainer = styled.div`
  margin: 10px 0;
  padding-bottom: 200px;
  width: 225px;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 45px;
`;

export const Subtitle = styled.h3`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #333333;
`;

export const Description = styled.p`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 45px;

  a {
    color: #66c1cb;
    text-decoration: underline;
  }
`;

export const LineBreak = styled.hr`
  border: 1px solid #eeeeee;
`;

export const Wrapper = styled.div``;

export const Label = styled.label`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #333333;

  span {
    color: #b3b3b3;
  }
`;

export const Descriptive = styled.h6`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #666;
  display: block;
  margin-top: 5px;

  span {
    font-weight: bold;
    color: #333;
    text-decoration: underline;
  }
`;
export const Form = styled.form`
  position: relative;
`;
export const FormGroup = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
`;

export const PreviewHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #cccccc;
`;

export const Subnav = styled.ul`
  display: inline-block;
  justify-content: center;
  background: #f7f7f7;
  border-radius: 20px;
  align-items: center;
  height: 20px;
  margin: 13px 0;
  color: #333;
  cursor: pointer;
`;

export const Items = styled.li`
  height: 100%;
  display: inline-block;
  width: 110px;
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
`;

export const Link = styled.a`
  font-weight: 600;
  padding: 1px 20px;
  text-decoration: none;
`;

export const WebView = styled.img`
  position: absolute;
  margin: 56px 0 0 40px;
  height: 607px;
`;

export const Image = styled.img``;

export const CoverImage = styled.img`
  position: absolute;
  background: #333333;
  border-radius: 8px 8px 0px 0px;
  width: 360px;
  height: 133px;
  margin: 77px 0 0 100px;
`;

export const StoreName = styled.span`
  position: absolute;
  margin: 93px 0 0 68px;
`;

export const Logo = styled.img`
  background-color: #f7f7f7;
  position: absolute;
  margin: 95px 0 0 30px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export const NoLogo = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #2c373c;
  text-transform: uppercase;
  font-size: 7px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 95px 0 0 30px;
`;

export const MobileOutline = styled.img`
  position: absolute;
  z-index: auto;
  border-radius: initial;
  z-index: -1;
  left: 0;
`;

export const CrossSymbol = styled.img`
  padding: 13px;
  border-radius: 50px;
`;

export const CardImageOnMobileView = styled.img`
  position: absolute;
  border-radius: 18.22px;
  height: 261px;
  width: 261px;
  margin: 160px 0 0 21px;
  object-fit: cover;
`;

export const Input = styled.input`
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: normal;
  font-size: 11.5px;
  line-height: 17px;
  padding: 7px 15px;
  color: #333333;
  margin: 7px 0;
  width: 240px;
`;

export const Store = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background: #333333;
  border-radius: 25px;
  margin: 226px 0px 0px 105px;
`;

export const ExampleLink = styled.a`
  color: ${colors.primaryColor};
  margin: 0 0 17px 0;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 11px;
  text-decoration: none;

  &:hover {
    color: #333333;
  }
`;

export const ContainerContent = styled.div`
  text-align: center;
`;

export const SuccessDescription = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #5c676c;
  margin: 20px;
`;

export const BoldText = styled.span`
  font-weight: bold;
  font-size: 19px;
  line-height: 22px;
`;
export const Message = styled.div``;

export const MobileContainer = styled.div`
  width: 302px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  margin-top: 17px;
  overflow: hidden;
`;

export const PreviewContainer = styled.div`
  width: 650px;
  margin: 0 auto;
`;

export const ButtonHidden = styled.button<any>`
  border: none;
  background: none;
  width: 100%;
`;

export const ButtonDisabled = styled.button<any>`
  padding: 8px 32px 8px 32px;

  background: #cccccc;
  border: none;
  border-radius: 40px;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
`;

export const FooterBar = styled.div`
  background-color: #fff;
  width: 100%;
  height: 72px;
  position: absolute;
  bottom: 0px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
`;
