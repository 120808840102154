export const getHeadersWithToken = (token: string) => ({
  headers: {
    'Content-Type': 'application/json',
    Token: token,
  },
});

export const hasCsmRequestedByMerchant = (merchantId: string) =>
  localStorage.getItem(`csm-requested-by-${merchantId}`) === 'true';

export const setCsmRequestedByMerchant = (merchantId: string) =>
  localStorage.setItem(`csm-requested-by-${merchantId}`, 'true');

export default {
  getHeadersWithToken,
  hasCsmRequestedByMerchant,
  setCsmRequestedByMerchant,
};
