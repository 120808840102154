import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import {
  List, ListItem, ListItemIcon, ListItemText, Checkbox,
} from '@material-ui/core';
import circularCheckbox from '../../../../assets/images/circular-checkbox.svg';
import circularCheckboxChecked from '../../../../assets/images/circular-checkbox-selected.svg';
import squareCheckbox from '../../../../assets/images/square-checkbox.svg';
import squareCheckboxChecked from '../../../../assets/images/square-checkbox-checked.svg';
import Button from '../../../Button/Button';
import './UpdateCoverageType.scss';
import TitleAndNav from './TitleAndNav';
import { camelToSnakeCase } from '../../../../utils';

import {
  upsertMerchant, getMerchantPreference, shopifyUpdateScripts, requestFullCoverage,
} from '../../../../services/merchant';

const UpdateCoverageType = (props) => {
  const [loading, setLoading] = useState(false);
  const [originalPreference, setOriginalPreference] = useState('');
  const [currentSelectedOption, setMerchantPreferenceSelected] = useState('');

  const insuranceListOptions = [
    { optOut: 'The Route checkbox is SELECTED by default, so your customers must "opt out" to avoid the purchase of Route package protection' },
    { optIn: 'The Route checkbox is UNSELECTED by default, so your customers must "opt in" to purchase Route package protection' },
    { merchantSuppliedInsurance: 'You want to pay for Route Plus package protection for all of your customers' },
    /*{ mixedCoverage: 'Mixed Coverage Type (Opt-out and Full Coverage at the same time' },*/
  ];

  useEffect(() => {
    let merchantInsurancePreference;
    const activeBrand = props.userSession.activeBrand;
    const isFullCoverage = getMerchantPreference(activeBrand, 'merchant_supplied_insurance') === true;
    const isOptOut = getMerchantPreference(activeBrand, 'opt_out') === true;
    const isOptIn = getMerchantPreference(activeBrand, 'opt_in') && !isOptOut;
  
    if (isFullCoverage) {
      if (!isOptOut) {
        merchantInsurancePreference = 'merchantSuppliedInsurance';
      } else {
        merchantInsurancePreference = 'mixedCoverage';
      }
    } else if (isOptIn) {
      merchantInsurancePreference = 'optIn';
    } else {
      merchantInsurancePreference = 'optOut';
    }
  
    setOriginalPreference(merchantInsurancePreference);
    setMerchantPreferenceSelected(merchantInsurancePreference);
  }, []);

  const handleSubmitButton = async (e) => {
    if (originalPreference !== currentSelectedOption) {
      const {
        merchant_preferences, id, prod_api_secret, has_route_plus, platform_id,
      } = props.userSession.activeBrand;
      const isOptOut = currentSelectedOption === 'optOut';

      let merchantPreferences = Object.assign(merchant_preferences || {}, {
        opt_in: currentSelectedOption === 'optIn',
        opt_out: isOptOut,
        merchant_supplied_insurance: false,
      });

      if (props.user && props.user.role === 'admin') {
        const mixedCoverage = currentSelectedOption === 'mixedCoverage';

        merchantPreferences = {
          ...merchantPreferences,
          merchant_supplied_insurance: currentSelectedOption === 'merchantSuppliedInsurance' || mixedCoverage,
          opt_out: mixedCoverage || isOptOut,
        };
      }

      ReactGA.event({ category: 'Admin - Preferences', action: 'Coverage Type change', label: `coverageType:${camelToSnakeCase(currentSelectedOption)}` });
      setLoading(true);

      const resp = await upsertMerchant('merchant_preferences', JSON.stringify(merchantPreferences), id, prod_api_secret);

      const optIn = !!(merchantPreferences && merchantPreferences.opt_in === true);
      const coverageType = optIn === true ? 'opt-in' : 'opt-out';

      if (platform_id === 'shopify') {
        const { shopify_domain, store_domain } = props.userSession.activeBrand;
        const shop = shopify_domain || store_domain;

        if (shop) {
          await shopifyUpdateScripts(shop);
        }
      }

      if (currentSelectedOption === 'merchantSuppliedInsurance' && (props.user && props.user.role === 'merchant')) {
        requestFullCoverage(
          id,
          coverageType,
          props.userSession.activeBrand.prod_api_secret,
          props.userSession.user.primary_email,
        );
      }

      setLoading(false);

      if (resp.data) {
        resp.data = Object.assign(resp.data, { has_route_plus });
        props.updateActiveBrand(resp.data);
      }
    }
    props.closeDrawer();
    e.preventDefault();
  };

  return (
      <div className='update-payment-method update-coverage-type'>
        <TitleAndNav close={props.closeDrawer} title="Protection Toggle Default" />
        <div className='update-payment-method-upper-text'>
          <p>
            One of the benefits of Route Plus is the ability for your customers to instantly add Route package
            protection to their online orders at
            the point of checkout.
          </p>
          <p className="attention-text">
            Please select from the options
            below to configure the experience
            for your customers:
          </p>
        </div>

        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="nested-list"
         >
            {insuranceListOptions.map((item, i) => {
              const insuranceOption = Object.keys(item)[0];

              if (props.user && props.user.role === 'merchant' && (insuranceOption === 'merchantSuppliedInsurance' || insuranceOption === 'mixedCoverage')) {
                return false;
              }

              const activeOption = (Object.keys(insuranceListOptions[i])[0]);
              return (
               <ListItem key={Math.random()} onClick={() => setMerchantPreferenceSelected(activeOption)} alignItems="flex-start" className="item-list">
                  <ListItemIcon className='checkbox'>
                     <Checkbox
                        onChange={() => setMerchantPreferenceSelected(activeOption)}
                        checked={currentSelectedOption === activeOption}
                        icon={
                           <img
                              alt="checkbox icon image"
                              srcSet={circularCheckbox}
                              width={20}
                           />
                        }
                        checkedIcon={
                           <img
                              alt="checkbox icon image"
                              srcSet={circularCheckboxChecked}
                              width={20}
                           />
                        }
                        value="checked"
                        color="primary"
                     />
                  </ListItemIcon>
                  <ListItemText primary={item[activeOption]} secondary={ activeOption === 'optOut' && '(Recommended)'}/>
               </ListItem>
              );
            })}

         </List>

         {props.user && props.user.role === 'merchant' && (
          <div className='update-payment-method-container coverage-type-coverage'>
            <Checkbox
              onChange={() => setMerchantPreferenceSelected('merchantSuppliedInsurance')}
              className="checkbox-square"
              icon={
                <img
                    alt="checkbox icon image"
                    srcSet={squareCheckbox}
                    width={20}
                  />
              }
              checkedIcon={
                  <img
                    alt="checkbox icon image"
                    srcSet={squareCheckboxChecked}
                    width={20}
                  />
              }
              checked={currentSelectedOption === 'merchantSuppliedInsurance'}
              value="checked"
              color="primary"
            />

            <p>Check here if you want to pay for Route Plus package protection for
              all of your customers <br/><b>(Requires approval)</b></p>
          </div>
         )}
        <Button className='update-company-details-save-button' type='submit'
        onClick={handleSubmitButton}
        // disabled={!this.validateForm()}
        isLoading={loading}
        text="Save" />
      </div>
  );
};

export default UpdateCoverageType;
