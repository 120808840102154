import styled from 'styled-components';
import Colors from '../../../../../constants/colors';

export const BCRoot = styled.div`
  border-radius: 16px;
  background: ${Colors.primaryButtonText};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.02);
  padding: 25px;
  margin-bottom: 20px;
`;

export const BCTable = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

export const BCTableRow = styled.div`
  display: table-row;
  border-top: 1px solid #EEEEEE;
  height: 32px;
`;

export const BCTableCell = styled.div`
  border: 0px solid #999999;
  display: table-cell;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  vertical-align: middle;
`;

export const BCContactTableCell = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  vertical-align: middle;
`;

export const BCContactTableHeaderCell = styled.div`
  border: 0px solid #999999;
  display: table-cell;
  color: ${Colors.secondaryBackground};
  vertical-align: middle;
  font-size: 12px;
`;

export const BCContentTableRowHeader = styled.div`
  display: table-row;
  border-top: 1px solid #EEEEEE;
  height: 40px;
  color: ${Colors.secondaryBackground};
  font-weight: 600;
`;

export const BCTableRightCell = styled.div`
  display: table-cell;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  vertical-align: middle;
  min-width: 81px;
  color: ${Colors.secondaryBackground};
`;

export const BCHeader = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  padding-bottom: 35px;
`;

export const BCStoreLogo = styled.img`
  width: 50px;
  height: 50px;
  background: ${Colors.secondaryColor};
  border-radius: 25px;
  margin: 7px 0px;
`;

export const BCEdit = styled.span`
  width: 45px;
  height: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  padding-top: 2px;
  float: right;
  color: ${Colors.primaryButtonText};
  background: ${Colors.primaryColor};
  border-radius: 20px;
  transition: all .5s;
  &:hover {
    cursor: pointer;
    background: #333;
  }
`;

export const BCRedDash = styled.span`
  color: ${Colors.redColor};
`;

export const BCOrderImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin: 7px 0px;
`;

export const BCStoreHero = styled.img`
  width: 135px;
  height: 50px;
  background: ${Colors.secondaryColor};
  border-radius: 8px;
  margin: 7px 0px;
`;

export const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
`;
export const WidgetTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
`;
