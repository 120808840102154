import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import './App.scss';
import { Route, Switch, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import { ConnectedPortal as Portal } from './components/Portal/Portal-container';
import Login from './components/Login/Login';
import Onboarding from './components/Onboarding/Onboarding';
import ResetEmail from './components/Login/ResetEmail/ResetEmail';
import PasswordReset from './components/Login/PasswordReset/PasswordReset';
import GenericError from './components/_shared/ErrorPages/GenericError';
import NotFound from './components/_shared/ErrorPages/NotFound';
import { getUserSession, setUserSessionInStorage } from './stores/SessionManager';
import SnackBar from './components/_shared/MaterialUi/SnackBar';
import { GTM_ID, GA_ID } from './constants';
import { OnboardingApp } from './components/Onboarding-2/index';
import { ConnectedRedirect } from './components/Onboarding-2/pages/redirect/redirect';
import { initializeSentry, setGlobalSentryUserData } from './helpers/sentry';
import { Brand } from './components/Brand';
import { getMerchantServiceTier } from './services/merchant_service_tier';
import { AuthProvider } from './components/Portal/hooks/authContext';
import { getMerchantRole } from './services/user';

/**
  * Init Sentry for entire APP
  * --------------------------
  * Docs show initialization in index.js, but I can only get it to work there if the DSN value is hardcoded.
  * Something to do with the app.ENV and constants not getting initialized before the APP component maybe
  */
initializeSentry();

const tagManagerArgs = {
  gtmId: GTM_ID,
};
TagManager.initialize(tagManagerArgs);

ReactGA.initialize(GA_ID);

export const shouldUseNewOnboarding = true;

const App = (props) => {
  const [userSession, setUserSession] = useState({
    loggedIn: false,
    user: {},
    brands: [],
    activeBrand: {},
    tier: false,
    merchantRole: false,
  });

  const [validatingSession, updateValidatingSession] = useState(true);

  const [messageProps, setMessageProps] = useState({ message: '', variant: 'info' });

  useEffect(() => {
    const session = getUserSession();
    if (session && session.loggedIn) {
      setUserSession(session);
    }

    setGlobalSentryUserData({ browserSessionData: session });
    updateValidatingSession(false);
  }, []);

  useEffect(() => {
    if (userSession && userSession.loggedIn) {
      const fetchPlanTier = async () => {
        try {
          const serviceTier = await getMerchantServiceTier(userSession.activeBrand.id, userSession.activeBrand.prod_api_secret);
          Object.assign(userSession, {
            tier: serviceTier,
          });
        } catch (error) {
          console.log(error);
        }
        setUserSessionInStorage(userSession);
      };
      fetchPlanTier();
    }
  }, [userSession]);

  const updateActiveBrand = (brand) => {
    const fetchPlanTier = async () => {
      try {
        const serviceTier = await getMerchantServiceTier(brand.id, brand.prod_api_secret);


        Object.assign(userSession, {
          tier: serviceTier,
        });

        if (userSession.user.role === 'admin') {
          Object.assign(userSession, {
            merchantRole: 'route_admin',
          });
        } else {
          const merchantRole = await getMerchantRole(userSession.user.id, userSession.user.token, brand.id);
          Object.assign(userSession, {
            merchantRole: merchantRole.merchant_role,
          });
        }
      } catch (error) {
        console.log(error);
      }
      setUserSession({ ...userSession, activeBrand: brand });
    };
    fetchPlanTier();
  };

  const updateplan = (merchantTier) => {
    setUserSession({ ...userSession, tier: merchantTier });
  };

  const handleSnackbar = (snackbarMessage) => {
    const variantArray = ['success', 'error', 'warning', 'info'];

    const snackbarData = {
      variant: (snackbarMessage && snackbarMessage.variant && variantArray.includes(snackbarMessage.variant)) ? snackbarMessage.variant : 'info',
      message: snackbarMessage && snackbarMessage.message || snackbarMessage,
      entropy: Math.random(),
    };
    setMessageProps(snackbarData);
  };

  const signOut = () => {
    setUserSession({
      loggedIn: false, user: {}, brands: [], activeBrand: {}, tier: {}, merchantRole: false,
    });
    sessionStorage.removeItem('userSession');
    sessionStorage.removeItem('uToken');
    sessionStorage.removeItem('uId');
    sessionStorage.removeItem('bId');

    setGlobalSentryUserData({ browserSessionData: null });
    return <Redirect to="/login" />;
  };

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => (userSession.loggedIn ? (
        <Component
          {...rest}
          userSession={userSession}
          signOut={signOut}
          updateActiveBrand={updateActiveBrand}
          updateplan={updateplan}
        />
      ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: rest.location.pathname },
            }}
          />
        ))}
    />
  );

  const mainSwitch = (
    <React.Fragment>
      <Switch>
        <PrivateRoute
          exact
          path={['/', '/orders', '/trackings']}
          component={Portal}
          userSession={userSession}
          signOut={signOut}
          updateActiveBrand={updateActiveBrand}
          handleSnackbar={handleSnackbar}
        />
        <PrivateRoute
          path={['/admin', '/issues', '/engage', '/insights']}
          signOut={signOut}
          component={Portal}
          userSession={userSession}
          updateActiveBrand={updateActiveBrand}
          handleSnackbar={handleSnackbar}
        />
        {
          !shouldUseNewOnboarding
          && <Route
            path="/onboarding"
            render={() => <Onboarding {...props} setUserSession={setUserSession} userSession={userSession} />} />
        }
        {
          shouldUseNewOnboarding
          && <Route path="/onboarding" render={() => <OnboardingApp />} />
        }
        <Route
          path="/login"
          render={(props) => <Login {...props} setUserSession={setUserSession} userSession={userSession} />}
        />
        <Route
          exact
          path="/reset-password"
          render={(props) => <PasswordReset {...props} setUserSession={setUserSession} userSession={userSession} />}
        />
        <Route exact path="/forgot-password" component={ResetEmail} />
        <Route path="/brand" component={Brand} />
        <Route exact path="/error" component={GenericError} />
        <Route path="*" component={NotFound} />
      </Switch>
      <SnackBar {...messageProps} />
    </React.Fragment>
  );

  return (
    validatingSession ? null
      : (
        <AuthProvider value={userSession}>
          <div className="App">
            {
              shouldUseNewOnboarding
              && <ConnectedRedirect setUserSessionOutsideStateStore={setUserSession}>
                {mainSwitch}
              </ConnectedRedirect>
            }
            {
              !shouldUseNewOnboarding
              && mainSwitch
            }
          </div>
        </AuthProvider>)
  );
};

export default App;
