import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import axios from 'axios';

import orderBy from 'lodash/orderBy';
import moment from 'moment';
import Form from '../Form/Form';
import SearchBar from '../SearchBar/SearchBar';
import DenseTable from '../Table/Table';
import '../_shared.scss';
import SideDrawer from '../SideDrawer/SideDrawer';
import DateRangePicker from '../../../_shared/DateRangePicker';
import { getOrders } from '../../../../services/api';
import { sortColumnTitles } from '../../constants';
import ExportButton from '../../components/ExportButton';

const TableAndFormContainer = (props) => {
  const [startDateRange, setStartDateRange] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss.SSS'));
  const [endDateRange, setEndDateRange] = useState(moment().add(1, 'day').format('YYYY-MM-DD HH:mm:ss.SSS'));
  const [isDateRangeCleared, setIsDateRangeCleared] = useState(false);
  const [column, setColumn] = useState({ columnToSort: [], sortDirection: '' });
  const [sortDirection, setSortDirection] = useState([true, true, true, true, true, true, true]);

  const formatDate = (date) => {
    if (!date) {
      return moment().utc().format('YYYY-MM-DD');
    }
    return moment(date).utc().format('YYYY-MM-DD');
  };

  const onSelectDate = (startDate, endDate) => {
    setIsDateRangeCleared(false);
    setStartDateRange(moment(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'));
    setEndDateRange(moment(endDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss.SSS'));

    if (document.location.pathname.includes('orders')) {
      props.setStartDate(endDate);
      const source = axios.CancelToken.source();
      let unmounted = false;
      props.setLoading(true);
      const params = { limit: props.limit, cursor: props.cursor, start_date: formatDate(endDate) };

      getOrders(source.token, props.userSession.activeBrand.prod_api_secret, params).then(
        (res) => {
          if (!unmounted) {
            props.checkNextPage({
              prevCursor: '',
              nextCursor: res.data.cursor || '',
              currentResults: [...res.data.results],
            });
            props.setLoading(false);
          }
        },
        (err) => {
          if (!axios.isCancel(err) && !unmounted) {
            props.updateCursor('');
            props.updateOrders({ results: [] });
            props.setLoading(false);
            console.log(err.message);
          }
        },
      );
      // return cleanup function, this is called when the component unmounts, or renders, here we will cancel pending requests
      return function () {
        unmounted = true;
        source.cancel('Cancelling in cleanup');
      };
    }
  };

  const changeClearDateRange = () => {
    setIsDateRangeCleared(!isDateRangeCleared);
  };

  const invertDirection = {
    asc: 'desc',
    desc: 'asc',
  };

  const handleSort = (columnName, index) => {
    setColumn({
      columnToSort: sortColumnTitles[columnName],
      sortDirection:
        column.columnToSort === sortColumnTitles[columnName] ? invertDirection[column.sortDirection] : 'asc',
    });
    const itemsArr = sortDirection;
    itemsArr[index] = !itemsArr[index];
    setSortDirection(itemsArr);
  };

  return (
    <div className="portal-content-container">
      <h1>
        {props.pageHeader}
        {props.pageHeader ? 's' : ''}
      </h1>
      <div className="experimental-flex">
        <div className="experimental-flex-left">
          <div className="portal-content-header">
            {props.dropdown ? (
              <div className="portal-content-header-container">
                <Dropdown
                  options={props.dropdown.options}
                  onChange={props.dropdown._onSelect}
                  value={props.dropdown.defaultOption}
                  placeholder={props.dropdown.placeholder || props.dropdown.options[0]}
                  arrowClosed={props.dropdown.arrowClosed}
                  arrowOpen={props.dropdown.arrowOpen}
                />
              </div>
            ) : (
              ''
            )}
            <div className="portal-content-header-container--searchbar">
              <SearchBar
                search={props.search}
                updateSearchKey={props.updateSearchKey}
                updateSearchValue={props.updateSearchValue}
                placeholder={props.searchPlaceholder}
                searchDropdownItems={props.searchDropdownItems}
              />
            </div>
            <div className="portal-content-header-container--button">
              <ExportButton
                origin={props.origin}
                data={orderBy(props.data, column.columnToSort, column.sortDirection)}
              />
            </div>
          </div>
          <div className="portal-content-header-container--data-range">
            <DateRangePicker
              title="Date Range:"
              placeholder="Last 30 Days"
              onClean={changeClearDateRange}
              startDate={props.userSession.activeBrand.created_on || moment().subtract(1, 'week')}
              onSelectDate={onSelectDate}
              isClaim={!!props.headerList.includes('Issue Date')}
            ></DateRangePicker>
          </div>
          <DenseTable
            handleSort={handleSort}
            sortDirection={sortDirection}
            startDateRange={startDateRange}
            endDateRange={endDateRange}
            isLoading={props.isLoading}
            onSelect={props.onSelect}
            headerList={props.headerList}
            dataProps={props.dataProps}
            data={orderBy(props.data, column.columnToSort, column.sortDirection)}
            count={props.count}
            fetchMoreOrders={props.fetchMoreOrders}
            cursor={props.cursor}
            userSession={props.userSession || null}
            updateLimit={props.updateLimit}
            isDateRangeCleared={isDateRangeCleared}
            changeClearDateRange={changeClearDateRange}
            isSearching={props.isSearching}
          />
        </div>
        <SideDrawer title={props.pageHeader} toggleDrawer={true} onSelected={props.selected} onSelect={props.onSelect}>
          <Form
            count={props.count}
            isLoading={props.isLoading}
            formContent={props.selected}
            userSession={props.userSession}
          />
        </SideDrawer>
      </div>
    </div>
  );
};

export default TableAndFormContainer;
