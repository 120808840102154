import React, {useState, useEffect, useCallback} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {RouteFormTextInput, StripeElement, RouteSelect, MenuItem} from '../../../components';
import {formFieldNames, testIds} from '../types';
import {StripeField, useRouteStripe} from './stripe-helper';
import {colors} from '../../../constants/styles';
import countries from '../../../media/country-list.json';

const useStyles = makeStyles({
  gridItem: {
    marginTop: '25px',
  },
  stripeInput: {
    borderWidth: '0.5px',
    borderColor: colors.gray,
    borderRadius: '4px',
  },
  countryDropdown: {
    width: '173px',
  }
}, {name: 'CreditCardMethod'});

function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

export const CreditCardMethod = () => {
  const classes = useStyles();
  const stripeData = useRouteStripe();

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (stripeData.ready) {
      return;
    }

    forceUpdate();
  });

  const reactCountries = countries.map((countryObj) =>
    <MenuItem value={countryObj.Code} key={countryObj.Code} className={classes.countryDropdown}>
      {countryObj.Name}
    </MenuItem>
  );

  return (
    <Grid container item data-testid={testIds.creditCardCollectionMethodSection}>
      <StripeField field="stripe" {...stripeData}/>
      <Grid item xs={12} className={classes.gridItem}>
        <RouteFormTextInput
          id={formFieldNames.ccCardholder}
          name={formFieldNames.ccCardholder}
          label="Cardholder Name"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <RouteFormTextInput
          id={formFieldNames.ccAddressOne}
          name={formFieldNames.ccAddressOne}
          label="Address 1"
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <RouteFormTextInput
          id={formFieldNames.ccAddressTwo}
          name={formFieldNames.ccAddressTwo}
          label="Address 2"
        />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <RouteFormTextInput
          maxWidth="170px"
          id={formFieldNames.ccCity}
          name={formFieldNames.ccCity}
          label="City"
        />
      </Grid>
      <Grid item xs={4} sm={2} className={classes.gridItem}>
        <RouteFormTextInput
          id={formFieldNames.ccState}
          name={formFieldNames.ccState}
          label="State"
        />
      </Grid>
      <Grid item xs={4} sm={2} className={classes.gridItem}>
        <RouteFormTextInput
          maxWidth="60px"
          center={true}
          id={formFieldNames.ccZip}
          name={formFieldNames.ccZip}
          label="Zip"
        />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.gridItem}>
        <RouteSelect
          name={formFieldNames.ccCountry}
          label="Country"
        >
          {reactCountries}
        </RouteSelect>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <StripeElement
          type="number"
          name={formFieldNames.ccNumber}
          label="Credit Card #"
        />
      </Grid>
      <Grid item xs={4} sm={2} className={classes.gridItem}>
        <StripeElement
          type="expiry"
          name={formFieldNames.ccExpiration}
          label="MM/YY"
        />
      </Grid>
      <Grid item xs={4} sm={2} className={classes.gridItem}>
        <StripeElement
          type="cvc"
          name={formFieldNames.ccCCV}
          label="CVC"
        />
      </Grid>
    </Grid>
  );
}
