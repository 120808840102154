import React, {FC} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {Container} from './container';
import {SmallLogo} from '../media/small-logo';

const imageHeight = 16;
const headerHeight = 60;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  header: {
    height: headerHeight,
    backgroundColor: 'white',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  image: {
    height: imageHeight,
    marginTop: (headerHeight - imageHeight) / 2,
    marginBottom: (headerHeight - imageHeight) / 2,
    marginLeft: 30,
  }
}, {name: "HeaderContainer"});

export type HeaderContainerProps = {
  children: any;
  [x: string]: any;
}

export const HeaderContainer: FC<HeaderContainerProps> = (props) => {
  const classes = useStyles();
  const {children, ...otherProps} = props;

  return (
    <Box className={classes.root} >
      <div className={classes.header}>
        <SmallLogo className={classes.image}/>
      </div>
      <Container {...otherProps}>
        {children}
      </Container>
    </Box>
  );
}
