import React, { useState, useEffect } from 'react';
import { Container } from './Divider.styles';

import { IDividerProps } from './interface';

const Divider: React.FC<IDividerProps> = ({ style }: IDividerProps) => (
  <Container style={style} />
);

export default Divider;
