import React from 'react';
import DesktopDraw from './Notification-ReadyToShip-Email-Preview.png';
import {
  Container,
  Draw,
} from './DesktopPreview.styles';

const DesktopPreview: any = (props: any) => {
  return (
    <Container>
      <Draw srcSet={props.draw ? props.draw : DesktopDraw}></Draw>
    </Container>
  );
};

export default DesktopPreview;
