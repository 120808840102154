import React from 'react';
import colors from '../../constants/colors';

const SVG = ({
  style = {},
  stroke = colors.primaryColor,
  fill = colors.primaryColor,
  width = '20px',
  className = '',
  height = '20px',
  viewBox = '0 0 40 40',
  transform = '',
}) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5.5H1" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 2L1 5.5L4.5 9" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SVG;
