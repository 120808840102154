import React, {useState} from 'react';
import './UpdatePaymentMethod.scss';
import TitleAndNav from './TitleAndNav';
import Button from '../../../Button/Button';
import {updateMerchantContact} from '../../../../services/merchant';
import DrawerValidation, {validateEmail, validatePhone} from "./DrawerValidation";
import ReactGA from 'react-ga';

const UpdateReturnsContact = (props) => {
  const {id: merchantId, prod_api_secret: token} = props.userSession.activeBrand;
  const [returnsEmail, setReturnsEmail] = useState(props.returnsEmail);
  const [returnsPhone, setReturnsPhone] = useState(props.returnsPhone);
  const [returnsLink, setReturnsLink] = useState(props.returnsLink);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [isPhoneInvalid, setPhoneInvalid] = useState(false);

  const handleSubmitButton = async (e) => {
    if (validateEmail(returnsEmail) && validatePhone(returnsPhone)) {

      // Google analytics, track merchant returns contact change - phone and email are PII and cannot be captured
      ReactGA.event({ category: 'Admin - Preferences', action: 'Returns Contact change', label: `emailIsSet:${!!returnsEmail} phoneIsSet:${!!returnsPhone} link:${returnsLink}` });

      await updateMerchantContact({
        email: returnsEmail,
        phone: returnsPhone,
        link: returnsLink,
        slug: 'returns',
        merchantId,
        token
      });
      props.closeDrawer();
    } else {
      validateReturnsEmail();
      validateReturnsPhone();
    }
    props.refreshMerchantContact();
  };

  const closeEmailError = () => {
    setEmailInvalid(false);
  };

  const closePhoneError = () => {
    setPhoneInvalid(false);
  };

  const validateReturnsEmail = () => {
    setEmailInvalid(!validateEmail(returnsEmail))
  };

  const validateReturnsPhone = () => {
    setPhoneInvalid(!validatePhone(returnsPhone))
  };

  const handleEmailChange = (e) => {
    setReturnsEmail(e.target.value);
    closeEmailError();
  };

  const handlePhoneChange = (e) => {
    setReturnsPhone(e.target.value);
    closePhoneError();
  };

  return (
      <div>
        <TitleAndNav close={props.closeDrawer} title='Returns Contact'/>
        <div className='update-payment-method-upper-text'>
          <p>Provide contact information that your customers can use for return-related questions or
            concerns. This will be listed on your merchant profile in the Route mobile app.</p><br/>
          <p className='bold-paragraph'>Any fields below that are left blank will not appear on your
            profile in the Route mobile app.</p><br/>
        </div>
        <div className='update-payment-method-lower-text'>
          <div className='update-billing-contact-input-header'>
            Email
          </div>
          <input className='input-ss' value={returnsEmail} name="email"
                 onChange={handleEmailChange}
                 onBlur={validateReturnsEmail}
          />
          {isEmailInvalid ? <DrawerValidation isEmail={isEmailInvalid} closeEmailError={closeEmailError}/> : ''}
          <div className='update-billing-contact-input-header'>
            Phone
          </div>
          <input className='input-ss' value={returnsPhone} name="phone"
                 onChange={handlePhoneChange}
                 onBlur={validateReturnsPhone}
          />
          {isPhoneInvalid ? <DrawerValidation isPhone={isPhoneInvalid} closePhoneError={closePhoneError}/> : ''}
          <div className='update-billing-contact-input-header'>
            Link
          </div>
          <input className='input-ss' value={returnsLink} name="link"
                 onChange={(e) => setReturnsLink(e.target.value)}
          />
        </div>
        <Button className='update-company-details-save-button' type='submit'
                text="Save"
                onClick={handleSubmitButton}
        />
        {}
      </div>
  );
};
export default UpdateReturnsContact;
