import {replace, push} from 'connected-react-router';
import {Dispatch} from 'redux';

import {requestPasswordReset as apiRequestPasswordReset} from '../../../api';

export const PASSWORD_RESET_REQUEST_STARTED = 'PASSWORD_RESET_REQUEST_STARTED' as const;
export interface PasswordResetRequestStartedAction {
  type: typeof PASSWORD_RESET_REQUEST_STARTED;
};

export function passwordResetRequestStarted(): PasswordResetRequestStartedAction {
  return {
    type: PASSWORD_RESET_REQUEST_STARTED,
  };
}

export const PASSWORD_RESET_REQUEST_SUCCEEDED = 'PASSWORD_RESET_REQUEST_SUCCEEDED' as const;
export interface PasswordResetRequestSucceededAction {
  type: typeof PASSWORD_RESET_REQUEST_SUCCEEDED;
  email: string;
};

export function passwordResetRequestSucceeded(email: string): PasswordResetRequestSucceededAction {
  return {
    type: PASSWORD_RESET_REQUEST_SUCCEEDED,
    email,
  };
}

export const PASSWORD_RESET_REQUEST_FAILED = 'PASSWORD_RESET_REQUEST_FAILED' as const;
export interface PasswordResetRequestFailedAction {
  type: typeof PASSWORD_RESET_REQUEST_FAILED;
  reason: PasswordResetRequestFailureReason;
};

export type PasswordResetRequestFailureReason = 'Unknown'|'NoAccount';
export function passwordResetRequestFailed(reason: PasswordResetRequestFailureReason = 'Unknown'): PasswordResetRequestFailedAction {
  return {
    type: PASSWORD_RESET_REQUEST_FAILED,
    reason,
  };
}

export type PasswordResetRequestActions =
  PasswordResetRequestStartedAction |
  PasswordResetRequestSucceededAction | 
  PasswordResetRequestFailedAction;

export type ResetData = {
  email: string;
}

export const requestPasswordReset = (data: ResetData) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(passwordResetRequestStarted());
      const loginSucceeded = await apiRequestPasswordReset(data);

      if (loginSucceeded) {
        dispatch(passwordResetRequestSucceeded(data.email));
        dispatch(replace('/onboarding/sign-in/password-reset-confirmation'));
      } else {
        dispatch(passwordResetRequestFailed('NoAccount'));
      }
    } catch(err) {
      dispatch(passwordResetRequestFailed());
    }
  }
}

export const goBack = () => {
  return (dispatch: Dispatch) => {
    dispatch(replace('/onboarding/sign-in'));
  }
}

export const forgotEmail = () => {
  return (dispatch: Dispatch) => {
    dispatch(push('/onboarding/forgot-email'));
  }
}

export const HIDE_PASSWORD_RESET_REQUEST_INVALID_EMAIL_ERROR = 'HIDE_PASSWORD_RESET_REQUEST_INVALID_EMAIL_ERROR';
export interface HidePasswordResetRequestInvalidEmailErrorAction {
  type: typeof HIDE_PASSWORD_RESET_REQUEST_INVALID_EMAIL_ERROR;
}

export function hidePasswordResetRequestInvalidEmailErrorActionCreator() {
  return {
    type: HIDE_PASSWORD_RESET_REQUEST_INVALID_EMAIL_ERROR,
  };
}
