import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { searchBrands } from '../../../../services/api';
import DashIcon from '../../../../assets/icons/dashboard-icon';
import CartIcon from '../../../../assets/icons/cart-icon';
import FolderIcon from '../../../../assets/icons/folder-icon';
import EngageIcon from '../../../../assets/icons/engage-icon';
import GraphIcon from '../../../../assets/icons/graph-icon';
import AdminIcon from '../../../../assets/icons/gear-icon';
import TrackingIcon from '../../../../assets/icons/trackings-icon';
import SignOutIcon from '../../../../assets/icons/sign-out-icon';
import RouteLogo from '../../../../assets/images/route-brand-logo.svg';

import './NavBar.scss';
import NavItem from './NavItem/NavItem';
import { shouldHideRevenueData } from '../../../../services/merchant';
import Can from '../../components/Authentication/Can/Can';
import { AuthConsumer } from '../../hooks/authContext';
import NavItemUpgradeToPro from './NavItemUpgradeToPro';
import { permissions } from '../../components/Authentication/merchant_permissions';

const NavBar = (props) => {
  const location = useLocation();
  const pages = {
    '/': 'dash',
    orders: 'orders',
    issues: 'issues',
    admin: 'admin',
    engage: 'engage',
    insights: 'insights',
  };

  const determineSelected = () => {
    const { pathname } = location;
    const page = pathname !== '/' ? pathname.split('/')[1] : '/';
    return pages[page];
  };

  useEffect(() => {
    setSelected(determineSelected());
    setMainQuery(props.activeBrand.store_name);
  }, [location.pathname]);

  const [selected, setSelected] = useState(determineSelected());
  const [query, setQuery] = useState('');
  const [mainQuery, setMainQuery] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [matchingResults, setMatching] = useState([]);
  const [timer, setTimer] = useState();
  const { user, userSession, accessibleStores, updateActiveBrand, handleSnackbar } = props;

  const searchStores = async (entry) => {
    setQuery(entry);
    if (entry === '') {
      setMatching([]);
      setInputFocused(false);
    } else {
      user.role === 'admin'
        ? timeoutOnKeyDown(entry.toLowerCase())
        : setMatching(accessibleStores.filter((store) => store.store_name.toLowerCase().includes(entry.toLowerCase())));
    }
  };

  const queryBrand = async (entry) => {
    const results = await searchBrands(entry, user.token);
    setMatching(results);
  };

  const timeoutOnKeyDown = (entry) => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        queryBrand(entry);
      }, 1000),
    );
  };
  const handleSelectBrand = (brand) => {
    ReactGA.event({ category: 'Sidebar Nav', action: 'Store switch executed' });
    handleSnackbar(`Viewing as ${brand.store_name}`);
    updateActiveBrand(brand);
    setMatching([]);
    setQuery('');
  };

  const clearSearchInput = () => {
    setMatching([]);
    setQuery('');
    setInputFocused(false);
    props.setSearchOpen(false);
  };

  const displayedBrands = matchingResults.map((brand) => (
    <span key={brand.id} className="search-item-container" onClick={() => handleSelectBrand(brand)}>
      {brand.store_name}
    </span>
  ));

  const truncator = (str) => {
    if (str && typeof str === 'string') {
      return str.length > 18 ? `${str.substring(0, 15)}...` : str;
    }
    return str;
  };

  return (
    <AuthConsumer>
      {({ user, merchantRole, tier, ...rest }) => (
        <div id="sidebar-menu-container" onClick={props.doOnClick}>
          <div id="sidebar-menu-nav-container">
            <img src={RouteLogo} className="sidebar-brand" alt="Route's Logo" />
            <div className="sidebar-menu">
              <Can
                role={merchantRole}
                perform={permissions.dashboard.view}
                yes={() => (
                  <NavItem
                    name="dash"
                    Icon={DashIcon}
                    alt="dash-icon"
                    href="/"
                    selected={selected}
                    onClick={() => setSelected('dash')}
                    className="bigger"
                  >
                    Dashboard
                  </NavItem>
                )}
              />
              <NavItem
                name="orders"
                Icon={CartIcon}
                alt="cart-icon"
                href="/orders"
                selected={selected}
                onClick={() => setSelected('orders')}
              >
                Orders
              </NavItem>
              <NavItem
                name="issues"
                Icon={FolderIcon}
                alt="folder-icon"
                href="/issues"
                selected={selected}
                onClick={() => setSelected('issues')}
              >
                Issues
              </NavItem>
              <Can
                role={merchantRole}
                perform={permissions.engage.view}
                yes={() => (
                  <NavItem
                    name="engage"
                    Icon={EngageIcon}
                    alt="engage-icon"
                    href="/engage"
                    selected={selected}
                    onClick={() => setSelected('engage')}
                    isPro
                  >
                    Engage
                  </NavItem>
                )}
              />
              <Can
                role={merchantRole}
                perform={permissions.insights.view}
                yes={() => (
                  <NavItem
                    name="insights"
                    Icon={GraphIcon}
                    alt="graph-icon"
                    href="/insights"
                    selected={selected}
                    onClick={() => setSelected('insights')}
                    isPro
                  >
                    Insights
                  </NavItem>
                )}
              />
              <Can
                role={merchantRole}
                perform={permissions.admin.view}
                yes={() => (
                  <NavItem
                    name="admin"
                    Icon={AdminIcon}
                    alt="admin-icon"
                    href="/admin"
                    selected={selected}
                    onClick={() => setSelected('admin')}
                    className={`${shouldHideRevenueData(props) ? 'hide' : ''}`}
                  >
                    Admin
                  </NavItem>
                )}
              />

              <Can
                role={merchantRole}
                perform={'nav_bar:route-pro-widget'}
                services={tier}
                yes={() => <NavItemUpgradeToPro handleModal={props.handleModal} userSession={props.userSession} />}
              />

              <NavItem
                name="signout"
                Icon={SignOutIcon}
                alt="signout-icon"
                href="/"
                selected={selected}
                onClick={() => {
                  setSelected('signout');
                  ReactGA.event({ category: 'Sidebar Nav', action: '"Sign Out" clicked' });
                  props.signOut();
                }}
                className="medium"
              >
                <span>Sign Out</span>
              </NavItem>
              {/* <span id="line-break--bottom" /> */}
            </div>
          </div>

          <div className="merchant-display">
            {accessibleStores.length > 1 || user.role === 'admin' ? (
              <div
                className={!props.searchOpen ? 'pointer' : null}
                onClick={() => (!props.searchOpen ? props.setSearchOpen(!props.searchOpen) : null)}
              >
                {/*
                  <div className="display-sign-in-options">
                  <div
                    id="sign-in-as-container"
                    onClick={() => (!props.searchOpen ? null : props.setSearchOpen(!props.searchOpen))}
                  >
                    <div id="sign-in-as">
                      Sign{props.searchOpen ? '' : 'ed'} in as..
                    </div>
                    <div>
                      <ChevronIcon
                        height="20px"
                        width="20px"
                        circleFill="#66C1CB"
                      />
                    </div>
                  </div>
                </div>
                  */}
                <span id="line-break--bottom" />
                {
                  <div className="search-bar-results-container">
                    {accessibleStores.length > 1 || user.role === 'admin' ? (
                      <div className="search-bar-display-container">
                        <input
                          value={
                            props.searchOpen ? query : props.activeBrand && truncator(props.activeBrand.store_name)
                          }
                          autoComplete="off"
                          id="header-search-bar"
                          className={`search-bar ${inputFocused ? 'active' : ''}`}
                          placeholder="Switch Stores"
                          onChange={(e) => searchStores(e.target.value)}
                          onFocus={() => setInputFocused(true)}
                          onBlur={() => matchingResults.length === 0 && clearSearchInput()}
                          onClick={() => ReactGA.event({ category: 'Sidebar Nav', action: 'Store switcher click' })}
                        />

                        {inputFocused ? <div className="close-placeholder" onClick={clearSearchInput}></div> : ''}
                      </div>
                    ) : (
                      <Fragment />
                    )}
                    {matchingResults.length ? (
                      <div className="header-search-results">{displayedBrands}</div>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                }
              </div>
            ) : (
              <span className="merchant-name-display">
                {props.activeBrand && truncator(props.activeBrand.store_name)}
              </span>
            )}
          </div>
        </div>
      )}
    </AuthConsumer>
  );
};

export default NavBar;
