import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1048px;
  display: block;
  padding: 24px;
  background: #F7F7F7;
  border-radius: 24px;
  margin-top: 16px;
`;

export const Description = styled.div`
  margin-bottom: 16px;
`;

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 60px 10px 60px;
  display: block;
  box-sizing: border-box;
  /* border-top: 1px solid #EEEEEE; */
`;

export const Divider = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
  margin-left: 58px;
`;

export const Widgets = styled.div`
  display: flex;
  justify-content: space-between;
  @media(max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Widget = styled.div`
  max-width: calc(512px - 48px);
  width: 100%;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 24px;
  margin: 0 12px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 1200px) {
    max-width: calc(100% - 48px);
    margin-bottom: 24px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const WidgetContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;
export const WidgetTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.hr`
  border-top: 1px solid #EEEEEE;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export default {
  Container,
  Description,
  Grid,
  Widget,
  Widgets,
  WidgetContainer,
  WidgetHeader,
  Line,
};
