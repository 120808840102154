import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './NavItem.scss';
import RouteProIcon from '../../../../../assets/images/route-pro-badge-sm.svg';
import IconNew from '../../../components/IconNew';

const NavItem = ({ Icon, name, alt, href, selected, onClick, children, className, isNew = false, isPro = false }) => {
  return (
    <div className={`nav-item ${className}`} onClick={onClick}>
      <Link className="link" to={href}>
        <div className={`icon-container ${selected === name ? 'active-nav' : ''}`}>
          <Icon
            className="icon"
            fill={selected === name ? '#FFF' : '#666666'}
            stroke={selected === name ? '#5DC8DB' : '#FFF'}
            alt={alt}
            selected={selected}
          />
          <span className="text">{children}</span>
          {isNew && (
            <span className="label">
              <IconNew>NEW</IconNew>
            </span>
          )}
          {isPro && (
            <span className="label">
              <img src={RouteProIcon} alt="" />
            </span>
          )}
        </div>
      </Link>
    </div>
  );
};

NavItem.defaultProps = {
  className: '',
};

NavItem.propTypes = {
  Icon: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default NavItem;
