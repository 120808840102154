import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Colors from '../../../../constants/colors';

export const Container = styled.button<any>`
  position: relative;
  display: block;
  padding: 0;
  overflow: hidden;
  border-width: 0;
  outline: none;
  border-radius: 20px;
  background-color: ${Colors.primaryBackground};
  color: ${Colors.primaryText};
  transition: background-color .5s;

  ${(props) => props.disabled && css`
    background-color: ${Colors.gray};
    cursor: not-allowed;
  `};

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    padding-top: 0;
    border-radius: 100%;
    /* background-color: ${shade(0.75, Colors.secondaryButtonBackground)}; */
    background-color: rgba(236, 240, 241, .3);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #FFF;
  }

  &:active:before {
    width: 120%;
    padding-top: 120%;
    transition: width .2s ease-out, padding-top .2s ease-out;
  }

  &:hover {
    background-color: ${Colors.secondaryBackground};
    color: ${Colors.secondaryText};
  }
`;

export const Text = styled.span<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  font-family: 'Titillium Web', sans-serif;
  line-height: 14px;
  text-align: center;
  width: 66px;
  height: 20px;

  ${(props) => props.disabled && css`
    cursor: not-allowed;
    background-color: ${Colors.gray};
  `};
`;
