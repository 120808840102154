import React from 'react';
import { Line } from 'react-chartjs-2';
import '../Dashboard.scss';

const LineGraph = (props) => {
  const lineChartOptions = (labelPrefix) => ({
    maintainAspectRatio: false,
    legend: { display: false },
    global: {
      layout: {
        padding: '25px',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            fontColor: '#9799A8',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#9799A8',
            drawBorder: false,
            lineWidth: 0.5,
          },
          ticks: {
            fontColor: '#9799A8',
            callback(label, index) {
              if (
                (index === 0 && label <= 5)
                || (index === 1 && label <= 4.5)
                || (index === 2 && label <= 4)
                || (index === 3 && label <= 3.5)
                || (index === 4 && label <= 3)
                || (index === 5 && (label === 2.5 || label === 1.5 || label === 0.5))
                || (index === 6 && label > 0 && label <= 2)
                || (index === 7 && label > 0 && label <= 1.5)
                || (index === 8 && label > 0 && label <= 1)
                || (index === 9 && label > 0 && label <= 0.5)
              ) {
                label = label.toFixed(2);
              }
              if (label >= 1000) {
                label = label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '', '');
              }
              return `${labelPrefix || ''}${label}     `;
            },
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: '#445072',
    },
  });

  return (
    <div>
      <Line data={{
        labels: props.labels ? props.labels : [],
        datasets: [
          {
            fill: false,
            lineTension: 0.2,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#66C1CB',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#66C1CB',
            pointBackgroundColor: '#66C1CB',
            pointBorderWidth: 0,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#66C1CB',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 0,
            pointRadius: 5,
            data: props.lineData ? props.lineData : [],
          },
        ],
      }} color={'#66C1CB'} options={lineChartOptions(props.labelPrefix)} />
    </div>
  );
};

export default LineGraph;
