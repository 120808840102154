import styled from 'styled-components';
import { Breakpoints } from '../../utils/responsive-breakpoints';

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const LogoWrapper = styled.div`
    margin-right: 18px;
    position: relative;

    @media (max-width: ${Breakpoints.xsMax}px) {
        margin-right: 8px;
    }
`;

export const Logo = styled.div`
    background-image: url(${(props) => props.src || ''});
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 68px;
    width: 68px;

    ${(props) => (props.boxShadow ? 'box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.22);' : '')}
    ${(props) => (props.centerAlign ? 'transform: translate(-50%, -50%);' : '')}

    ${(props) => !props.large && `
        @media (max-width: ${Breakpoints.xsMax}px) {
            height: 40px;
            width: 40px;
        }
    `}
`;

export const RoutePlus = styled.img`
    bottom: 0;
    position: absolute;
    right: 0;
`;

export const Name = styled.div`
    font-family: 'Titillium Web', sans-serif;
    font-size: 34px;
    font-weight: bold;
    line-height: 34px;
    color: #333;

    @media (max-width: ${Breakpoints.xsMax}px) {
        font-size: 17px;
        line-height: 24px;
    }
`;

export const MerchantURL = styled.div`
    color: #999;
    font-size: 17px;
    line-height: 24px;
    font-family: 'Titillium Web', sans-serif;
    &:hover {
        cursor: pointer;
    }
`;