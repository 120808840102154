import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import LineGraph from './LineGraph';
import {
  CardLabel,
  FortyPixelsVerticalSpace,
  GraphContainer,
  GraphLoading,
} from '../Dashboard.styles';
import '../Dashboard.scss';

const MultiLineGraph = (props) => {
  const [dataset, setDataset] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(true);

  useEffect(() => {
    setForceUpdate(false);
    const temporary = [];

    if (props.lineData) {
      const graphDataSize = Object.keys(props.lineData).length;
      const { lineData } = props;
      const zipcodes = Object.keys(lineData);

      for (let y = 0; y < zipcodes.length; y++) {
        const zipcode = zipcodes[y];
        const color = props.colors[zipcode];
        temporary.push({
          label: zipcode,
          fill: false,
          lineTension: 0.2,
          backgroundColor: color,
          borderColor: color,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: color,
          pointBackgroundColor: color,
          pointBorderWidth: 0,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 0,
          pointRadius: 5,
          data: lineData[zipcode] && lineData[zipcode].length > 0 ? lineData[zipcode] : {},
        });
      }
    }
    setTimeout(() => {
      setForceUpdate(true);
      setDataset(temporary);
    });
  }, [props]);
  const lineChartOptions = (labelPrefix) => ({
    maintainAspectRatio: false,
    legend: { display: false },
    global: {
      layout: {
        padding: '25px',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
          ticks: {
            fontColor: '#9799A8',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#9799A8',
            drawBorder: false,
            lineWidth: 0.5,
          },
          ticks: {
            fontColor: '#9799A8',
            callback(label, index) {
              if (
                (index === 0 && label <= 5)
                || (index === 1 && label <= 4.5)
                || (index === 2 && label <= 4)
                || (index === 3 && label <= 3.5)
                || (index === 4 && label <= 3)
                || (index === 5 && (label === 2.5 || label === 1.5 || label === 0.5))
                || (index === 6 && label > 0 && label <= 2)
                || (index === 7 && label > 0 && label <= 1.5)
                || (index === 8 && label > 0 && label <= 1)
                || (index === 9 && label > 0 && label <= 0.5)
              ) {
                label = label.toFixed(2);
              }
              if (label >= 1000) {
                label = label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '', '');
              }
              return `${labelPrefix || ''}${label}     `;
            },
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: '#445072',
    },
  });

  return (
    <div style={{
      height: '650px',
      marginTop: '-40px',
    }}>
      {forceUpdate && (
        <Line
          data={{
            labels: props.labels ? props.labels : [],
            datasets: dataset,
          }}
          color={'#66C1CB'}
          options={lineChartOptions(props.labelPrefix)}
        />
      )}
    </div>

  );
};

export default MultiLineGraph;
