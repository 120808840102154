import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import Button from "../../Button/Button";

import {
   requestPasswordChange,
   requestForgotEmail
} from "../../../services/api";

import "../Login.scss";
import ReactGA from 'react-ga';

const ResetEmail = props => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [descriptiveText, setDescriptiveText] = useState("Submit your email address below and we will send you a link to reset your password.");
  const [subtitleText, setSubtitleText] = useState("");
  const [step, setStep] = useState(0);

  useEffect(() => {
     if (step == 1) {
      setDescriptiveText(`Sorry, we couldn't find an account with that email address. Our support team would love to help you out!`);
      setSubtitleText('What email address should we use to contact you?');
      setButtonText('Contact Support Now');
     }
  }, [step]);

  const validateForm = () => {
     return email.length > 0;
  };

  const isValidEmail = () => {
   return email && email.length > 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateSupportForm = () => {
     return (isValidEmail() && name.length > 0 && storeName.length > 0) || buttonDisabled === true;
  };

  const submitPasswordChangeRequest = async e => {
    e.preventDefault();

    try {
      setEmail('')
      setButtonText('Sending..')
      await requestPasswordChange(email.toLowerCase());
      setButtonText('Sent!');
    } catch (e) {
      setButtonText('Submit')
      setDescriptiveText(
        "An error occurred while trying to send a password reset email. Please check that your email is formatted correctly."
      );
      setStep(1);
    }
  };

  const submitSupportRequest = async e => {
   e.preventDefault();

   try {
     setButtonText('Sending..');
     const requestResp = await requestForgotEmail(name, email.toLowerCase(), storeName);

     if (requestResp && requestResp.status == 200) {
         setButtonDisabled(true);
         setDescriptiveText(`We've notified our support team of your request.\n You should receive a follow up email from them shortly.`);
         setSubtitleText('Have a nice day!');
         setButtonText('Sent!');

         return;
     }

     throw "Not be able to send the request";
   } catch (e) {
     setButtonText('Submit')
     setDescriptiveText(
       "An error occurred while trying to send a password reset email. Please check that your email is formatted correctly."
     );
   }
  }

  const renderRequestSupportForm = () => {
   return (
      <form className="login-form" onSubmit={submitSupportRequest}>
        <div className="form-descriptive-text">{descriptiveText}</div>
        <div className="form-descriptive-text form-subtitle-text">{subtitleText}</div>
         <label className="form-label">Email</label>
         <input
            className="form-input"
            required={true}
            onChange={e => setEmail(e.target.value)}
         />
         <label className="form-label">Your Name</label>
         <input
            className="form-input"
            required={true}
            onChange={e => setName(e.target.value)}
         />
         <label className="form-label">Store Name</label>
         <input
            className="form-input"
            required={true}
            onChange={e => setStoreName(e.target.value)}
         />
         <Button
            className="login-button"
            type="submit"
            text={buttonText}
            onClick={() => ReactGA.event({ category: 'Sign In', action: '"Contact Support Now" click' })}
            disabled={!validateSupportForm() || buttonDisabled}
            loadingText="Submitting..."
         />
      </form>
   );
};

  const renderForm = () => {
    return (
      <form className="login-form" onSubmit={submitPasswordChangeRequest}>
        <div className="form-descriptive-text">
         {descriptiveText}
        </div>
        <label className="form-label">Email</label>
        <input
            disabled={buttonText === 'Sending..' || buttonText === 'Sent!'}
            className="form-input"
            placeholder="Email"
            required={true}
            onChange={e => setEmail(e.target.value)}
        />
        <Button
          className="login-button"
          type="submit"
          disabled={!validateForm()}
          text={buttonText}
          loadingText="Submitting..."
        />
      </form>
    );
  };

  return (
    <div>
      <Header />
      <div className="login form">
        <div className="form-wrapper">
          <div className="form-container">
            <h1 className="form-header">Password Reset</h1>
            <div className="l-form">{step == 0 ? renderForm() : renderRequestSupportForm()}</div>
            <div className="l-other-actions">
              <Link to="/login">Return to Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmail;
