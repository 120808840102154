import React from 'react';
import { Container } from './Loading.styles';

import { ILoadingProps } from './interface';

const Loading: React.FC<ILoadingProps> = ({ isLoading }: ILoadingProps) => (
  <Container >&nbsp;</Container>
);

export default Loading;
