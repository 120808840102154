import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 60px;
  border: none;
  background-color: #eeeeee;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  background: linear-gradient(-10deg, #2bffff 0%, #28b8e3 47.33%, #114057 94.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
`;
