import React, { useEffect, useState, FC } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import NavBar from './_shared/NavBar/NavBar';
import Dashboard from './pages/dashboard/Dashboard';
import Insights from './pages/Insights/Insights';
import Orders from './Orders/Orders';
import Claims from './Claims/Claims';
import Engage from './pages/Engage/Engage';
import Trackings from './Trackings/Trackings';
import Hotjar from './components/Hotjar';
import Admin from './Admin/Admin';
import NotFound from '../_shared/ErrorPages/NotFound';
import { getBilling } from '../../services/billing';
import { BrowserUserSessionData, isNonEmptyObject } from '../../stores/SessionManager';
import { RouteApi } from '../Onboarding-2/api/types';
import { PortalCongratsModal } from '../Onboarding-2/pages/congrats/portal-modal';
import Pendo from './components/Pendo';
import './Portal.scss';
import ProtectedRoute from './components/ProtectedRoute/index';
import { check } from './components/Authentication/Can';
import GetRouteProThanksModal from './components/GetRouteProThanksModal';
import { ROUTE_PRICING_URL } from '../../constants';

type SnackbarData =
  | {
      variant?: 'success' | 'error' | 'warning' | 'info';
      message: string;
    }
  | string;

export type StateProps = {
  onboardingDialogVersion: 'none' | 'routePlusEnabled' | 'routePlusDisabled';
};
export type DispatchProps = {
  closeOnboardingDialog: () => void;
  closeOnboardingDialogAndGoToAdminPage: () => void;
};
export type OwnProps = {
  userSession: BrowserUserSessionData;
  signOut: () => void;
  updateActiveBrand: (brand: RouteApi.Merchant) => void;
  handleSnackbar: (data: SnackbarData) => void;
};
export type Props = RouteComponentProps & StateProps & DispatchProps & OwnProps;

const Portal: FC<Props> = (props) => {
  const [billing, setBilling] = useState<Partial<RouteApi.BillingRecord> | undefined>({});
  const [searchOpen, setSearchOpen] = useState(false);
  const [modal, setModal] = useState({ show: false, name: '' });
  const { user, brands, activeBrand, loggedIn, tier, merchantRole } = props.userSession as any;

  const {
    userSession,
    signOut,
    onboardingDialogVersion,
    closeOnboardingDialog,
    closeOnboardingDialogAndGoToAdminPage,
  } = props;

  useEffect(() => {
    getBillingDetails().catch((err) => console.error(err));
  }, []);

  const getBillingDetails = async () => {
    if (
      !props ||
      !props.userSession ||
      !props.userSession.activeBrand ||
      !isNonEmptyObject(props.userSession.activeBrand) ||
      !props.userSession.activeBrand.prod_api_secret
    ) {
      return;
    }
    const billingResp = await getBilling(props.userSession.activeBrand.prod_api_secret);
    setBilling(billingResp && billingResp[0]);
  };

  const verify = () => {
    props.history.push({
      pathname: '/admin/preferences',
      state: { drawer: 'verify-payment', isDrawerOpen: true },
    });
  };

  const closeSearchBar = () => {
    // if (searchOpen) {
    //   setSearchOpen(false);
    // }
  };

  const closeDrawer = () => {
    props.history.push({
      state: { isDrawerOpen: false },
    });
  };

  const handleModal = (show: boolean, name: string) => {
    setModal({ show, name });
  };

  const renderModal = () => {
    if (modal.show)
      switch (modal.name) {
        case 'modal_already_requested_csm_interaction':
          return (
            <GetRouteProThanksModal
              messageSuccess="<b>Looks like you’re already on our growing contact list of merchants interested in Route Pro.</b><br/><br/>Someone from Route will reach out to you shortly; keep an eye on your inbox!"
              tryOutButton="View Pricing Page"
              onTryOutPress={() => {
                window.open(ROUTE_PRICING_URL, '_blank');
                setModal({ ...modal, show: false });
              }}
              closeButton="Close"
              onClosePress={() => {
                setModal({ ...modal, show: false });
              }}
            />
          );
          break;

        default:
          break;
      }
  };

  return loggedIn ? (
    <div className="portal-container-head" onClick={() => closeSearchBar()}>
      <div className="flex">
        <NavBar
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          activeBrand={activeBrand}
          signOut={signOut}
          accessibleStores={brands}
          user={user}
          userSession={userSession}
          updateActiveBrand={props.updateActiveBrand}
          handleSnackbar={props.handleSnackbar}
          doOnClick={closeDrawer}
          handleModal={handleModal}
        />
        <div className="portal-container-large">
          {billing && billing.id && !billing.verified ? (
            <div className="notification-bar-verify-payment">
              <span>
                It looks like your payment needs verification. Please <a onClick={verify}>click here</a> to verify your
                payment method.
              </span>
            </div>
          ) : (
            ''
          )}
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              isAuthenticated={loggedIn}
              isAllowed={check({
                role: merchantRole,
                action: 'view_dashboard',
              })}
              restrictedPath="/orders"
              render={() => <Dashboard {...props} userSession={userSession} />}
            />
            <Route exact path="/orders" render={() => <Orders {...props} userSession={userSession} />} />
            <Route path="/issues" render={() => <Claims {...props} userSession={userSession} />} />
            <Route path="/engage" render={() => <Engage {...props} userSession={userSession} />} />
            <Route path="/insights" render={() => <Insights {...props} userSession={userSession} />} />
            <ProtectedRoute
              path="/admin"
              isAuthenticated={loggedIn}
              isAllowed={check({
                role: merchantRole,
                action: 'view_admin',
              })}
              restrictedPath="/"
              render={() => (
                <Admin
                  {...props}
                  setSnackbarMessage={props.handleSnackbar}
                  billing={billing}
                  getBillingDetails={getBillingDetails}
                  user={user}
                />
              )}
            />
            <Route path="*" component={NotFound} />
          </Switch>
          {modal.show ? renderModal() : null}
        </div>
      </div>
      <PortalCongratsModal
        open={onboardingDialogVersion !== 'none'}
        routePlusEnabled={onboardingDialogVersion === 'routePlusEnabled'}
        onClose={closeOnboardingDialog}
        onExplore={closeOnboardingDialogAndGoToAdminPage}
      />
      <Pendo
        visitorId={user.id}
        accountId={activeBrand.id}
        creationDate={activeBrand.created_on}
        merchantName={activeBrand.store_name}
        merchantDomain={activeBrand.store_domain}
        platform={activeBrand.platform_id}
        fullName={user.name}
        planTier={tier}
      />
      <Hotjar render={process.env?.REACT_APP_STAGE === 'prod'} />
    </div>
  ) : null;
};

export default withRouter(Portal);
