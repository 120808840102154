import React from 'react';
import propTypes from 'prop-types';
import { Grid, FormControl, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '0 !important',
    marginBottom: '20px',

    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
  },
}));

const FormItem = ({
  xs, md, spacing, children,
}) => {
  const classes = useStyles();

  return (
    <Grid container item xs={xs} md={md} spacing={spacing} className={classes.root}>
      <FormControl fullWidth={true}>
        {children}
      </FormControl>
    </Grid>
  );
};

FormItem.defaultProps = {
  xs: 12,
  md: 12,
};

FormItem.propTypes = {
  xs: propTypes.number,
  md: propTypes.number,
  spacing: propTypes.number,
  children: propTypes.element.isRequired,
};

export default FormItem;
