import React, { useState, useEffect } from 'react';

import ReactMapGL, { Popup, Marker } from 'react-map-gl';

import {
  ContainerMap,
  ContentMapInfo,
  ContainerZoom,
  ContentZoom,
  ButtonZoomOut,
  ButtonZoomIn,
  NavigationPercentage,
  Cluster,
  Card,
  CardTitle,
  CardSubTitle,
  CardSubTitleHighlight,
} from './TopZipCodesMap.styles';

import './TopZipCodesMap.scss';

import { ITopZipCodesMapProps } from './interface';

import { DELIVERY_STATUS } from '../../../../utils/delivery-status';
import { MAPBOX_API_KEY } from '../../../../../../constants';
import {
  AddressToGeolocation,
  getAddByZip,
} from '../../../../../../route-order-tracking-react-components/src/utils/geolocation';
import Tooltip from '../../../../components/Tooltip';
import IconInfo from '../../../../components/IconInfo';
import Typography from '../../../../components/Typography';

const TopZipCodesMap: React.FC<ITopZipCodesMapProps> = ({
  mapData,
  filteredData,
  isLoading,
  ...props
}: ITopZipCodesMapProps) => {
  const [state, setState] = useState<any>({
    viewport: {
      latitude: 37.785164,
      longitude: -100,
      zoom: 3,
      minZoom: 1,
      maxZoom: 20,
      bearing: 0,
      pitch: 0,
    },
    hoverInfo: null,
  });

  const [formatedData, setFormatedData] = useState<any>();
  const [deliveryStatus, setDeliveryStatus] = useState<any>(DELIVERY_STATUS);

  useEffect(() => {
    async function onMapDataChanged() {
      const mappedCheckpoints = mapData.map(async (item: any, index: number) => {
        const feature = await getAddByZip(item.ZIP);
        const geolocation = feature?.geometry?.coordinates ? feature?.geometry?.coordinates : -1;
        const place = feature?.place_name ? feature?.place_name : -1;
        const element = {
          ...item,
          ZIP: item.ZIP,
          CITY: place,
          LONGITUDE: geolocation[0],
          LATITUDE: geolocation[1],
          TOTAL: item.TOTAL.toFixed(2),
          PERCENTAGE: item.METRICS,
          COLOR: item.COLOR,
          RADIUS: item.METRICS < 25 ? item.METRICS : 25,
        };
        return element;
      });
      Promise.all(mappedCheckpoints).then((res) => {
        setFormatedData(res);
      });
    }
    if (!isLoading) {
      onMapDataChanged();
    }
  }, [mapData]);

  const onViewportChange = (viewport: any) => setState({ viewport });

  const handleClusterEnter = (event: MouseEvent, item: any) => {
    setState({
      ...state,
      hoverInfo: {
        item,
      },
    });
  };

  const handleClusterLeave = (event: MouseEvent) => {
    setState({
      ...state,
      hoverInfo: null,
    });
  };

  const handleZoomOut = () => {
    let zoom = state.viewport.zoom - 1;
    if (state.viewport.zoom <= 1) zoom = 1;

    setState({
      ...state,
      viewport: {
        ...state.viewport,
        zoom,
      },
    });
  };

  const handleZoomIn = () => {
    let zoom = state.viewport.zoom + 1;
    if (state.viewport.zoom >= 20) zoom = 20;

    setState({
      ...state,
      viewport: {
        ...state.viewport,
        zoom,
      },
    });
  };

  const renderPopup = () => {
    const { hoverInfo } = state;
    return (
      hoverInfo && (
        <Popup longitude={hoverInfo.item.LONGITUDE} latitude={hoverInfo.item.LATITUDE} closeButton={false}>
          <Card>
            <div style={{ marginBottom: '16px' }}>
              <CardTitle>{'City'}</CardTitle>
              <CardSubTitle>{hoverInfo.item.CITY || 'CITY'}</CardSubTitle>
              <CardSubTitle>{hoverInfo.item.ZIP || 'ZIP'}</CardSubTitle>
            </div>
            <div>
              <CardTitle>{'Metric'}</CardTitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Cluster
                  style={{
                    cursor: 'pointer',
                    width: '12px',
                    height: '12px',
                    background: deliveryStatus[hoverInfo.item.DELIVERY_STATUS]?.fill || 'rgba(90, 200, 219, 0.2)',
                    border: `1px solid ${deliveryStatus[hoverInfo.item.DELIVERY_STATUS]?.stroke || '#5DC8DB'}`,
                    borderRadius: hoverInfo.item.RADIUS * 2,
                    marginRight: '8px',
                  }}
                />
                <CardSubTitle
                  color={deliveryStatus[hoverInfo.item.DELIVERY_STATUS]?.stroke || '#5DC8DB'}
                >{`${hoverInfo.item.TOTAL} (${hoverInfo.item.PERCENTAGE}%)`}</CardSubTitle>
              </div>
            </div>
          </Card>
        </Popup>
      )
    );
  };

  return (
    <>
      <ContainerMap>
        <ReactMapGL
          {...state.viewport}
          width="100%"
          height="600px"
          onViewportChange={onViewportChange}
          mapboxApiAccessToken={MAPBOX_API_KEY}
          mapStyle="mapbox://styles/routewapp/ck0sfcnuh1cqp1cpybctk21k2"
          scrollZoom={true}
          interactive={true}
          // onHover={onHover}
        >
          {formatedData &&
            formatedData.map((item: any, index: any) => {
              if (item?.LONGITUDE && item?.LATITUDE) {
                return (
                  <Marker key={`marker-${index}`} longitude={item.LONGITUDE} latitude={item.LATITUDE}>
                    <Cluster
                      onMouseEnter={(event: MouseEvent) => handleClusterEnter(event, item)}
                      onMouseLeave={handleClusterLeave}
                      style={{
                        cursor: 'pointer',
                        display: filteredData.dailyAmounts[item.ZIP] ? 'block' : 'none',
                        position: 'absolute',
                        top: -item.RADIUS,
                        left: -item.RADIUS,
                        width: item.RADIUS * 2,
                        height: item.RADIUS * 2,
                        background: item.COLOR_RGBA || 'rgba(90, 200, 219, 0.2)',
                        border: `1px solid ${item.COLOR || '#5DC8DB'}`,
                        borderRadius: item.RADIUS * 2,
                      }}
                    />
                  </Marker>
                );
              }
            })}
          {renderPopup()}
        </ReactMapGL>
        <ContentMapInfo>
          <Tooltip
            wrapper={<IconInfo />}
            overlay={
              <>
                <Typography variant="p">
                  <a href="https://www.mapbox.com/about/maps/" target="_blank">
                    © Mapbox
                  </a>
                  <br />
                  <a href="http://www.openstreetmap.org/about/" target="_blank">
                    © OpenStreetMap
                  </a>
                  <br />
                  <a
                    className="mapbox-improve-map"
                    href="https://apps.mapbox.com/feedback/?owner=routewapp&amp;id=ck0sfcnuh1cqp1cpybctk21k2&amp;access_token=pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg"
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    Improve this map
                  </a>
                </Typography>
              </>
            }
          ></Tooltip>
        </ContentMapInfo>
      </ContainerMap>

      <ContainerZoom>
        <ContentZoom>
          <ButtonZoomOut onClick={handleZoomOut}>-</ButtonZoomOut>
          <NavigationPercentage>{`${Math.round(state.viewport.zoom * 100)}%`}</NavigationPercentage>
          <ButtonZoomIn onClick={handleZoomIn}>+</ButtonZoomIn>
        </ContentZoom>
      </ContainerZoom>
    </>
  );
};

export default React.memo(TopZipCodesMap);
