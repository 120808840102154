import React from "react";
const SVG = ({
               style = {},
               fill = '',
               width = '47px',
               className = '',
               height = '23px',
               viewBox = '0 0 47 23',
               transform = '',
             }) =>
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill='none'
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="0.5" width="47" height="22" rx="11" fill="#66C1CB"/>
    <circle cx="36" cy="11.5" r="8" fill="white"/>
    <path d="M11.608 14.554C10.145 14.554 9.804 13.652 9.804 11.771C9.804 9.901 10.156 8.911 11.608 8.911C13.06 8.911 13.401 9.901 13.401 11.771C13.401 13.685 13.071 14.554 11.608 14.554ZM11.608 15.621C13.962 15.621 14.666 14.334 14.666 11.771C14.666 9.241 13.962 7.844 11.608 7.844C9.254 7.844 8.539 9.252 8.539 11.771C8.539 14.312 9.243 15.621 11.608 15.621ZM16.0878 15.5H17.3088V9.032H17.4628L19.8058 15.5H21.8958V7.965H20.6748V14.422H20.5098L18.2218 7.965H16.0878V15.5Z" fill="white"/>
  </svg>
export default SVG;
