import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import './LoadingIndicator.scss';

const LoadingIndicator = (props) => (
      <LoadingOverlay {...props}
      className='custom-loadig-overlay'/>
);

export default LoadingIndicator;
