import React, { useState } from 'react';
import '../../_shared/_shared.scss';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { SwipeableDrawer } from '@material-ui/core';

import UpdatePaymentMethod from './UpdatePaymentMethod';
import VerifyPayment from './VerifyPayment';
import ConnectCard from './ConnectCard';
import UpdateDetails from './UpdateCompanyDetails';
import ConnectBank from './ConnectBank';
import './PreferencesDrawer.scss';
import SetUpPaymentMethod from './SetUpPaymentMethod';
import UpdateCoverageType from './UpdateCoverageType';
import UpdateRoutePlus from './UpdateRoutePlus';
import FreeTrackingEmail from './FreeTrackingEmail';
import ShopifyImplementation from './ShopifyImplementation';
import ThankYouPageTrackingLink from './ThankYouPageTrackingLink'
import UpdateBillingContact from './UpdateBillingContact';
import UpdateClaimsContact from './UpdateClaimsContact';
import UpdateCompanyContact from './UpdateCompanyContact';
import UpdateSupportContact from './UpdateSupportContact';
import UpdateReturnsContact from './UpdateReturnsContact';
import { STRIPE_PUBLIC_API_KEY } from '../../../../constants';
import ThankYouPageSavedSettings from "./ThankYouPageSavedSettings";
import BrandedOrderCard from '../Drawer/BrandedOrderCard/index';


const handleCloseBankModal = () => {};

const PreferencesDrawer = (props) => {
  const [understandChecked, setUnderstandChecked] = useState(false);
  const [keepDrawerOpen, setKeepDrawerOpen] = useState(false);
  const [trackingLinkOn, setTrackingLinkOn] = useState(false);
  const [activeSessionData, setActiveSessionData] = useState(false);


  const openBankAccountDrawer = () => {
    props.setDrawerName('connect-bank');
  };

  const thankYouPageSavedSettings = () => {
    props.setDrawerName('thank-you-saved');
  };

  const openCreditCardDrawer = () => {
    props.setDrawerName('connect-card');
  };
  const openShopifyImplementationDrawer = () => {
    props.setDrawerName('shopify-implementation');
  };

  const goBack = () => {
    if (props.location.pathname.includes('onboarding')) {
      props.setDrawerName('set-up-payment');
    } else {
      props.setDrawerName('update-payment');
    }
  };

  const goBackShopifyImplementation = () => {
    props.setDrawerName('update-route-plus');
  };

  const closeDrawer = (forceClose) => {
    // TODO: verify what's being passed in here - looks like it's a whole event object
    if (!keepDrawerOpen) {
      props.setOpen(false);
    } else if (forceClose === true) {
      props.setOpen(false);
      setKeepDrawerOpen(false);
    }
  };

  const getDrawer = (drawer) => {


    switch (drawer) {
      case 'update-billing-contact' :
        return <UpdateBillingContact
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'update-claims-contact' :
        return <UpdateClaimsContact
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'update-company-contact' :
        return <UpdateCompanyContact
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'update-support-contact' :
        return <UpdateSupportContact
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'update-returns-contact' :
        return <UpdateReturnsContact
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'connect-bank':
        return <ConnectBank
          getBillingDetails={props.getBillingDetails}
          goBack={goBack}
          closeDrawer={closeDrawer}
          {...props}
          onBillingSuccess={props.onBillingSuccess}
          onRequestClose={handleCloseBankModal}
        />;
      case 'connect-card':
        return <ConnectCard
          getBillingDetails={props.getBillingDetails}
          onBillingSuccess={props.onBillingSuccess}
          closeDrawer={closeDrawer}
          goBack={goBack}
          {...props}
        />;
      case 'update-payment':
        return <UpdatePaymentMethod
          understandChecked={understandChecked}
          setUnderstandChecked={setUnderstandChecked}
          getBillingDetails={props.getBillingDetails}
          openCreditCardDrawer={openCreditCardDrawer}
          openBankAccountDrawer={openBankAccountDrawer}
          onBillingSuccess={props.onBillingSuccess}
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'set-up-payment':
        return <SetUpPaymentMethod
          understandChecked={understandChecked}
          setUnderstandChecked={setUnderstandChecked}
          getBillingDetails={props.getBillingDetails}
          openCreditCardDrawer={openCreditCardDrawer}
          openBankAccountDrawer={openBankAccountDrawer}
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'verify-payment':
        return <VerifyPayment
          getBillingDetails={props.getBillingDetails}
          closeDrawer={closeDrawer}
          {...props}
        />;
      case 'branded-order-card':
        return <BrandedOrderCard
          closeDrawer={closeDrawer}
          setKeepDrawerOpen={setKeepDrawerOpen}
          {...props}
        />
      case 'update-details':
        return <UpdateDetails closeDrawer={closeDrawer} onUpdateDetails={props.onUpdateDetails} setKeepDrawerOpen={setKeepDrawerOpen}
                              {...props} />;
      case 'update-route-plus':
        return <UpdateRoutePlus closeDrawer={closeDrawer} onUpdateDetails={props.onUpdateDetails} openShopifyImplementationDrawer={openShopifyImplementationDrawer}
                                {...props} />;
      case 'update-coverage-type':
        return <UpdateCoverageType closeDrawer={closeDrawer} onUpdateDetails={props.onUpdateDetails}
                                   {...props} />;
      case 'update-tracking-email':
        return <FreeTrackingEmail closeDrawer={closeDrawer} onUpdateDetails={props.onUpdateDetails}
                                  {...props} />;
      case 'thank-you-page-tracking-link':
        return <ThankYouPageTrackingLink
          closeDrawer={closeDrawer}
          trackingLinkOn = {trackingLinkOn}
          setTrackingLinkOn = {setTrackingLinkOn}
          activeSessionData = {activeSessionData}
          setActiveSessionData = {setActiveSessionData}
          thankYouPageSavedSettings={thankYouPageSavedSettings}
          {...props} />;
      case 'thank-you-saved':
        return <ThankYouPageSavedSettings closeDrawer={closeDrawer} trackingLinkOn = {trackingLinkOn} activeSessionData = {activeSessionData} {...props} />;
      case 'shopify-implementation':
        return <ShopifyImplementation
          closeDrawer={closeDrawer}
          goBack={goBackShopifyImplementation}
          {...props}
        />;
      default:
    }
  };

  return (
    <SwipeableDrawer
      className='sidedrawer'
      style={{ width: '900px' }}
      open={props.isOpen || false}
      onOpen={() => {}}
      onClose={closeDrawer}
      anchor='right'
    >
      <StripeProvider apiKey={STRIPE_PUBLIC_API_KEY}>
        <Elements>
          <div className={`preferences-drawer-content-container ${(props.drawerName === 'update-details' || props.drawerName === 'thank-you-saved' || props.drawerName === 'thank-you-page-tracking-link') || props.drawerName === 'branded-order-card' ? 'extended-drawer' : ''}`} >
            {getDrawer(props.drawerName)}
          </div>
        </Elements>
      </StripeProvider>
    </SwipeableDrawer>
  );
};

export default PreferencesDrawer;
