import React from 'react';
import { Typography } from '@material-ui/core';
import './TermsAndConditionsLink.scss';

const TermsAndConditionsLink = () => {
   return (
      <Typography variant="p" component="p" className="terms">
         By proceeding, you are agreeing to our <a href="https://route.com/terms-and-conditions/" target="_blank" title="Click here to see the Terms and Conditions page">Terms and Conditions</a>.
      </Typography>
   );
};

export default TermsAndConditionsLink;