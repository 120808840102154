import { captureException } from "@sentry/browser";
import { RouteApi } from "../../api/types";
import { ThunkDispatch, State } from "../../store";
import { updateMerchant } from "../../api";
import { exportStateToBrowser } from "../sign-in/util";

export const UPDATE_ACTIVE_MERCHANT_SUCCEEDED = 'UPDATE_ACTIVE_MERCHANT_SUCCEEDED' as const;
export interface UpdateActiveMerchantSucceededAction {
  type: typeof UPDATE_ACTIVE_MERCHANT_SUCCEEDED;
  data: RouteApi.Merchant;
}

export function updateActiveMerchantSucceededActionCreator(data: RouteApi.Merchant): UpdateActiveMerchantSucceededAction {
  return {
    type: UPDATE_ACTIVE_MERCHANT_SUCCEEDED,
    data,
  };
}

export const UPDATE_ACTIVE_MERCHANT_FAILED = 'UPDATE_ACTIVE_MERCHANT_FAILED' as const;
export interface UpdateActiveMerchantFailedAction {
  type: typeof UPDATE_ACTIVE_MERCHANT_FAILED;
}

export function updateActiveMerchantFailedActionCreator(): UpdateActiveMerchantFailedAction {
  return {
    type: UPDATE_ACTIVE_MERCHANT_FAILED,
  };
}

export function updateActiveMerchant(merchant: Partial<RouteApi.Merchant> & {id: string; prod_api_secret: string}) {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      let userData = getState().app.userData;
      if (!userData.loggedIn || userData.activeBrand.id !== merchant.id) {
        return dispatch(updateActiveMerchantFailedActionCreator());
      }

      const newMerchant = await updateMerchant({
        id: merchant.id,
        token: merchant.prod_api_secret,
        data: merchant,
      });

      userData = getState().app.userData;
      if (!userData.loggedIn || newMerchant.id !== userData.activeBrand.id) {
        return dispatch(updateActiveMerchantFailedActionCreator());
      }

      const merchantUpdateResult = dispatch(updateActiveMerchantSucceededActionCreator(newMerchant));
      dispatch(exportStateToBrowser());
      return merchantUpdateResult;
    } catch(err) {
      captureException(err);
      return dispatch(updateActiveMerchantFailedActionCreator());
    }
  }
}
