import React, {FC} from 'react';
import {RouteCongrats} from './route';
import {ShopifySelf} from "./route-plus-shopify-self";

export type OwnProps = {};

export type StateProps = {
  routePlusEnabled: boolean;
  routePlusShopifySelf: boolean;
};

export type DispatchProps = {
  onSubmit: () => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

export const Congrats: FC<Props> = (props) => {
  return (
    props.routePlusShopifySelf ? 
      <ShopifySelf goToMerchantPortal={props.onSubmit}/> :
      <RouteCongrats routePlusEnabled={props.routePlusEnabled} goToMerchantPortal={props.onSubmit}/>
  );
};
