import {push, replace} from 'connected-react-router';
import {Dispatch} from 'redux';
import { ThunkDispatch, State } from '../../store';
import { routePlusEnabled, routePlusSelfImplementationEnabled } from '../../model/merchant';

export const goToPortal = () => {
  return (dispatch: Dispatch) => {
    dispatch(push('/'));
  }
}

export const SHOW_ONBOARDING_MODAL = 'SHOW_ONBOARDING_MODAL' as const;
export interface ShowOnboardingModalAction {
  type: typeof SHOW_ONBOARDING_MODAL;
}
export function showOnboardingModalActionCreator(): ShowOnboardingModalAction {
  return {
    type: SHOW_ONBOARDING_MODAL,
  };
}

export const HIDE_ONBOARDING_MODAL = 'HIDE_ONBOARDING_MODAL' as const;
export interface HideOnboardingModalAction {
  type: typeof HIDE_ONBOARDING_MODAL;
}
export function hideOnboardingModalActionCreator(): HideOnboardingModalAction {
  return {
    type: HIDE_ONBOARDING_MODAL,
  };
}

export const finishOnboarding = () => {
  return (dispatch: ThunkDispatch, getState: () => State) => {
    const {userData} = getState().app;

    if (!userData.loggedIn) {
      return dispatch(replace('/'));
    }

    // We only show the congrats screen for route plus shopify self implementation
    if (routePlusEnabled(userData.activeBrand) && routePlusSelfImplementationEnabled(userData.activeBrand)) {
      return dispatch(push('/onboarding/congrats'));
    }

    dispatch(showOnboardingModalActionCreator());
    return dispatch(replace('/'));
  };
}
