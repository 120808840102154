import React, { Component } from 'react';
import './Form.scss';

class Form extends Component {

    handleSubmit = (e) => {
      this.props.onSubmit(e);
    };

    render() {
      return (
        <form className={`routeapp-form ${this.props.styleName}`} method={this.props.method} onSubmit={this.handleSubmit} autoComplete="off">
          {this.props.children}
        </form>
      );
    }
}

Form.defaultProps = {
  styleName: "",
  method: "GET",
};

export default Form;