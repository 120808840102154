import React, { FC } from 'react';
import { Grid, Box, Link, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { Button, Container, ContainerContent, Text, TextVariant } from '../../components'
import { Logo } from '../../media/logo';
import { OnboardingStepper, StepEnum } from '../../components/stepper';
import styleMap, { colors } from '../../constants/styles';
import {
  ROUTE_TERM_OF_SERVICE_URL,
  ROUTE_PRIVACY_POLICY_URL
} from '../../../../constants/index';

export type OwnProps = {};

export type StateProps = {
  startOnboarding: () => void;
};

export type Props = OwnProps & StateProps;

//#region Styles
const spacing = 0;
const themePxPerSpace = 8;
function getMargin(margin: number): string {
  return `${margin - (spacing * themePxPerSpace)}px`;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '390px',
  },
  title: {
    marginTop: getMargin(75),
    textAlign: 'center',
  },
  logo: {
    marginTop: getMargin(55),
  },
  textOne: {
    marginTop: getMargin(55),
  },
  textTwo: {
    marginTop: getMargin(20),
  },
  button: {
    marginTop: '12px',
    marginBottom: '43px',
    width: '240px',
  },
  stepper: {
    marginTop: '10px',
  },
  stepperWrapper: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  termsAndConditions: {
    height: 12,
    marginTop: '26px',
    marginBottom: '15px',
  },
  termsAndConditionsText: {
    textAlign: 'center',
    color: styleMap.textBodyColor,
  },
  link: {
    color: styleMap.linkColor,
    textDecoration: 'underline',
    '&:hover': {
      color: styleMap.linkHoverColor,
    }
  },
  boxContainer: {
    padding: '0 48px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 28px',
    },
  }
}));
//#endregion

export const Welcome: FC<Props> = (props) => {
  const classes = useStyles();

  const viewTermsOfSercice = () => {
    window.open(ROUTE_TERM_OF_SERVICE_URL, '_blank');
  }

  const viewPrivacyPolicy = () => {
    window.open(ROUTE_PRIVACY_POLICY_URL, '_blank');
  }

  return (
    <Container>
      <ContainerContent width={390} >
        <Box className={classes.boxContainer} >
          <Grid container spacing={0} direction={'column'} alignItems={'center'} justify="flex-start">
            <Grid className={classes.title} item xs={12}>
              <Text variant={TextVariant.Title}>
                <span role="img" aria-label="clap">👏</span> Welcome to
              </Text>
            </Grid>
            <Grid className={classes.logo} item xs={12}>
              <Logo />
            </Grid>
            <Grid className={classes.textOne} item xs={12}>
              <Text variant={TextVariant.Body3} color={colors.darkGray}>
                Become one of Route’s trusted partners and join the most trusted network of premium brands by reserving your Brand Profile now.
              </Text>
            </Grid>
            <Grid className={classes.stepper} item xs={12}>
              <div onClick={props.startOnboarding} className={classes.stepperWrapper}>
                <OnboardingStepper stepperWidth={210} overrideConnector step={StepEnum.DomainVerification} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button id="start-onboarding" className={classes.button} onClick={props.startOnboarding}>Let's Get Started!</Button>
            </Grid>
            <Grid className={classes.termsAndConditions} item xs={12}>
              <Text variant={TextVariant.Terms} color={colors.darkGray}>
                {'By proceeding you are agreeing to our '}<br />
                <Link data-testid="terms-policy" onClick={viewTermsOfSercice} className={classes.link}>Terms of Service</Link>
                &nbsp;and <Link data-testid="privacy-policy" onClick={viewPrivacyPolicy} className={classes.link}>Privacy Policy.</Link>
              </Text>
            </Grid>
          </Grid>
        </Box>
      </ContainerContent>
    </Container>
  );
}

