import React, { Component, useState, useEffect } from 'react';
import './SetUpPasswordForm.scss';
import Form from '../../_shared/Form/Form';
import SubmitButton from '../../_shared/SubmitButton/SubmitButton';
import { updateMerchant, updateCollaborator } from '../../../../services/merchant';
import FormItem from '../../_shared/Form/FormItem/FormItem';
import FormInput from '../../_shared/Form/FormInput/FormInput';
import FormRow from '../../_shared/Form/FormRow/FormRow';
import { loginUser, resetPassword } from '../../../../services/api';
class SetUpPasswordForm extends Component {
	state = {
        email: "",
        fullName: "",
        password: "",
        confirmPassword: "",
        guid: "",
        loading: false
    };

    componentWillReceiveProps(props) {
        if (props.query && (props.query.email && props.query.guid)) {
            this.setState({
                email: props.query.email,
                guid: props.query.guid
            });
        } else {
            this.props.onInvalidAccess();
        }
    }

	handleChange = e => {
		const { value, id } = e.target;
		this.setState({
			[id]: value
		});
    };

	handleSubmit = async e => {
        const {
            confirmPassword,
            email,
            fullName,
            password,
            guid,
        } = this.state;

        e.preventDefault();

        if (password !== confirmPassword) {
			alert("Passwords don't match");
			return;
        }

        this.setState({
            loading: true
        });

        try {
            await resetPassword(password, guid);
            let resp = await loginUser(email, password);

            this.props.setUserSession({loggedIn: true, user: resp.user, brands: resp.brands, activeBrand: resp.brands[0]});

            updateMerchant(resp.brands[0]);
            updateCollaborator(resp.user);

            sessionStorage.setItem('uToken', resp.user.token);
            sessionStorage.setItem('uId', resp.user.id);
            sessionStorage.setItem('bId', resp.brands[0].id);

            this.props.onRequestCompleted();

            this.setState({
                loading: false
            });
        } catch (e) {
            alert("There was an error creating your account. Please contact support at support@route.com");
        }
    };

	render() {
		const {
			fullName, email, password, confirmPassword, loading
        } = this.state;

        return (
            <Form
                styleName="set-up-password-form"
                method="POST"
                onSubmit={this.handleSubmit}>
                <fieldset>
                    <legend className="legend">Email Address</legend>
                    <span className="email-label">{email}</span>
                    <FormRow spacing={2}>
                        <FormItem md={6}>
                            <label>Full Name</label>
                            <FormInput
                                type="text"
                                name="fullName"
                                id="fullName"
                                placeholder=""
                                value={fullName}
                                onChange={this.handleChange}
                                required
                            ></FormInput>
                        </FormItem>
                    </FormRow>
                    <FormRow spacing={2}>
                        <FormItem md={6}>
                            <label>Password</label>
                            <FormInput
                                type="password"
                                name="password"
                                id="password"
                                value={password}
                                placeholder=""
                                onChange={this.handleChange}
                                required
                            ></FormInput>
                        </FormItem>
                    </FormRow>
                    <FormRow spacing={2}>
                        <FormItem md={6}>
                            <label>Password Confirm</label>
                            <FormInput
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder=""
                                onChange={this.handleChange}
                                value={confirmPassword}
                                required
                            ></FormInput>
                        </FormItem>
                    </FormRow>
                    <SubmitButton
                        text="Next"
                        styleName="light"
                        isLoading={loading}
                    ></SubmitButton>
                </fieldset>
            </Form>
        );
    };
}

export default SetUpPasswordForm;
