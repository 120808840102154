import React, {FC, useState} from 'react';
import {Grid, Box, Link} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {Button, Container, ContainerContent} from '../../components'
import {Logo} from '../../media/logo';
import routePlusAsset from '../../media/route-plus-self.png';
import styleMap from '../../constants/styles';

export type OwnProps = {};

export type StateProps = {
  goToMerchantPortal: () => void;
};

export type Props = OwnProps & StateProps;

const useStyles = makeStyles({
  title: {
    marginTop: '53px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '33px',
    textAlign: 'center',
    width: '203px',
  },
  logo: {
    marginTop: '54px',
  },
  routeAsset: {
    marginTop: '50px',
    height: '182px',
  },
  textOne: {
    marginTop: '45px',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    width: '240px',
    fontWeight: 600,
  },
  textTwo: {
    fontWeight: 'normal',
  },
  button: {
    marginTop: '35px',
    width: '240px',
    marginBottom: '35px',
  },
  bottom: {
    width: '240px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    marginBottom: '55px',
  },
  link: {
    color: styleMap.linkColor,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: styleMap.linkHoverColor,
    }
  }
});

export const ShopifySelf: FC<Props> = (props) => {
  const classes = useStyles();
  const handleViewInstructions = () => {
    window.open('https://help.route.com/hc/en-us/articles/360046934434-VIDEO-How-to-self-install-Route-package-protection-on-your-store', '_blank');
  }

  return (
    <Container>
      <ContainerContent width={390}>
        <Box height={855}>
          <Grid container spacing={0} direction={'column'} alignItems={'center'} justify="flex-start">
            <Grid className={classes.logo} item xs={12}>
              <Logo width={59.12} height={43.48}/>
            </Grid>
            <Grid className={classes.title} item xs={12} >
              <span>Great job! Now please implement the Route+ Asset </span>
            </Grid>
            <Grid item xs={12}>
              <img alt="Route Logo" className={classes.routeAsset} src={routePlusAsset} />
            </Grid>
            <Grid className={classes.textOne} item xs={12}>Implementing the Route+ asset on your Shopify cart page is straightforward and simple. <span className={classes.textTwo}>
                Click the link below for implementation instructions.</span>
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.button} onClick={handleViewInstructions}>View Instructions</Button>
            </Grid>
            <Grid item xs={12} className={classes.bottom}>
              Once you’ve implemented Route+ on your site, you can manage your account and settings on the  <Link onClick={props.goToMerchantPortal} className={classes.link}>Route Portal.</Link>
            </Grid>
          </Grid>
        </Box>
      </ContainerContent>
    </Container>
  );
}









