import React, { Component } from "react";
import dragAndDropIcon from './DragAndDropIcon.svg';
import './DragAndDrop.scss';

class DragAndDrop extends Component {

	state = {
	  drag: false,
	};
    
    dropRef = React.createRef();
    browserFileRef = React.createRef();
    
	handleDrag = e => {
	  e.preventDefault();
	  e.stopPropagation();
	};
    
	handleDragIn = e => {
	  e.preventDefault();
	  e.stopPropagation();
	  this.dragCounter++;
	  if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
	    this.setState({ drag: true });
	  }
	};
    
	handleDragOut = e => {
	  e.preventDefault();
	  e.stopPropagation();
	  this.dragCounter--;
	  if (this.dragCounter === 0) {
	    this.setState({ drag: false });
	  }
	};
    
	handleDrop = e => {
	  e.preventDefault();
	  e.stopPropagation();
	  this.setState({ drag: false });
	  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
	    if (this.props.currentStep >= 0) {
	      this.props.handleDrop(e.dataTransfer.files, this.props.currentStep);
	    } else {
	      this.props.handleDrop(e.dataTransfer.files);
	    }
			
	    e.dataTransfer.clearData();
	    this.dragCounter = 0;
	  }
	};

    handleFileBrowser = e => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ drag: false });

      if (this.props.currentStep >= 0) {
        this.props.handleDrop(e.target.files, this.props.currentStep);
      } else {
        this.props.handleDrop(e.target.files);
      }
    }

    openFileBrowser = e => {
      let browserFile = this.browserFileRef.current;
      browserFile.click();
    }
    
    componentDidMount() {
      let div = this.dropRef.current;
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }
    
    componentWillUnmount() {
      let div = this.dropRef.current;
      div.removeEventListener("dragenter", this.handleDragIn);
      div.removeEventListener("dragleave", this.handleDragOut);
      div.removeEventListener("dragover", this.handleDrag);
      div.removeEventListener("drop", this.handleDrop);
    }
    
    render() {
      return (
        <div
          className="draganddrop"
          ref={this.dropRef}
        >
          <div className="draggable">
            <div className="container">
              <img src={dragAndDropIcon} alt="Drag and Drop icon" />
              <h3 className="text">Drop files here or <span className="spotlight" onClick={this.openFileBrowser}>browser</span></h3>
              <input type="file" id="file-browser" className="file-browser" accept="image/*" onChange={this.handleFileBrowser} ref={this.browserFileRef}/>
            </div>
          </div>

          <div className="draganddrop-file">
            { this.props.children }
          </div>
        </div>
      );
    }
}
export default DragAndDrop;
