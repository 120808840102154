import styled, { keyframes } from 'styled-components';

export const loadingKeyframes = keyframes`
  0%   { opacity: 1; }
  50%  { opacity: 0.65; }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #E5E5E5;
  animation: ${loadingKeyframes} 2s ease-in-out 0s infinite;
`;
