import React from 'react';
import Parser from 'html-react-parser';
import './IntroText.scss';

const IntroText = ({ text }) => {
  if (text === '') {
    return '';
  }

  return (
    <h2 className="intro-text">
      {Parser(text)}
    </h2>
  );
};

IntroText.defaultProps = {
  text: '',
};

export default IntroText;
