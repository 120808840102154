import {Stripe, StripeCardNumberElement, StripeIbanElement} from '@stripe/stripe-js';
import {StripeFormSetterData} from './method/stripe-helper';
import {PlaidMetadata} from '../../api/types';

export const formFieldNames = {
  stripe: 'stripe',
  collectionMethod: 'collectionMethod',
  billingEmail: 'billingEmail',

  bankAccountName: 'bankAccountName',
  bankAccountNumber: 'bankAccountNumber',
  bankAccountRoutingNumber: 'bankAccountRoutingNumber',
  bankAccountType: 'bankAccountType',

  plaidMetadata: 'plaidMetadata',

  ccCardholder: 'ccCardholder',
  ccAddressOne: 'ccAddressOne',
  ccAddressTwo: 'ccAddressTwo',
  ccCity: 'ccCity',
  ccState: 'ccState',
  ccZip: 'ccZip',
  ccCountry: 'ccCountry',
  ccNumber: 'ccNumber',
  ccExpiration: 'ccExpiration',
  ccCCV: 'ccCCV',
} as const;

export const testIds = {
  ...formFieldNames,
  collectionMethodSection: 'collectionMethodSection',
  bankAccountCollectionMethodSection: 'bankAccountCollectionMethodSection',
  creditCardCollectionMethodSection: 'creditCardCollectionMethodSection',
}

export type FormData = {
  [formFieldNames.stripe]?: StripeFormSetterData;
  [formFieldNames.collectionMethod]: 'bank' | 'cc';
  [formFieldNames.billingEmail]: string;

  [formFieldNames.bankAccountName]?: string;
  [formFieldNames.bankAccountNumber]?: string;
  [formFieldNames.bankAccountRoutingNumber]?: string;
  [formFieldNames.bankAccountType]?: 'individual' | 'company';

  [formFieldNames.plaidMetadata]?: PlaidMetadata;

  [formFieldNames.ccCardholder]?: string;
  [formFieldNames.ccAddressOne]?: string;
  [formFieldNames.ccAddressTwo]?: string;
  [formFieldNames.ccCity]?: string;
  [formFieldNames.ccState]?: string;
  [formFieldNames.ccZip]?: string;
  [formFieldNames.ccCountry]?: string;
  [formFieldNames.ccNumber]?: string;
  [formFieldNames.ccExpiration]?: string;
  [formFieldNames.ccCCV]?: string;
};
