import React from 'react';

const SVG = ({
  style = {},
  fill = 'white',
  stroke = '#333333',
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
  className = '',
  transform = '',
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill={fill}
    />
    <path d="M14.5 5.5L5.5 14.5" stroke={stroke} strokeLinecap="round" />
    <path d="M5.5 5.5L14.5 14.5" stroke={stroke} strokeLinecap="round" />
  </svg>
);

export default SVG;
