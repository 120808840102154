import React from 'react';

const SVG = ({
                 style = {},
                 fill = '#F06350',
                 width = '16',
                 height = '15',
                 className = '',
                 viewBox = '0 0 16 15',
                 transform = '',
             }) => <svg
                    style={style}
                    width={width}
                    height={height}
                    viewBox={viewBox}
                    className={className}
                    transform={transform}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
        <path fill="white" d="M5.97557 1.03412L0.279521 10.8976C-0.515758 12.2765 0.480923 14.0013 2.07148 14.0013H13.4584C15.049 14.0013 16.0457 12.2765 15.2504 10.8976L9.55949 1.03412C8.76421 -0.344707 6.77085 -0.344707 5.97557 1.03412Z" />
        <path fill={fill} d="M7.76938 9.61816C8.20317 9.61816 8.55433 9.96933 8.55433 10.4031C8.55433 10.8369 8.20317 11.1881 7.76938 11.1881C7.33559 11.1881 6.98443 10.8369 6.98443 10.4031C6.97927 9.96933 7.33559 9.61816 7.76938 9.61816Z" />
        <path fill={fill} d="M7.76938 4.2168C8.20317 4.2168 8.55433 4.56796 8.55433 5.00175V7.51152C8.55433 7.94531 8.20317 8.29647 7.76938 8.29647C7.33559 8.29647 6.98443 7.94531 6.98443 7.51152V5.00175C6.97927 4.56796 7.33559 4.2168 7.76938 4.2168Z" />
    </svg>

;

export default SVG;
