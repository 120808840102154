import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { History } from 'history';

import { getRootReducer } from '../reducers';
import { initialState } from './index';
import Storage from "./../../Brand/store/storage"

export const configureStore = (history: History) => {
  const rootReducer = getRootReducer(history);
  const middlewares = [thunk, routerMiddleware(history)]
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

  const brand = Storage.load("brand")
  initialState.brand = brand || initialState.brand;

  const store = createStore(
    rootReducer,
    {
      app: initialState,
      router: history,
    },
    enhancer,
  );

  store.subscribe(() => (
    Storage.save("brand", store.getState().app.brand)
  ));

  return store;
}
