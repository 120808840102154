import React, { useState } from 'react';
import ReactGA from 'react-ga';
import './UpdatePaymentMethod.scss';
import TitleAndNav from './TitleAndNav';
import Button from '../../../Button/Button';
import { updateMerchantContact } from '../../../../services/merchant';
import DrawerValidation, {validateEmail} from "./DrawerValidation";

const UpdateBillingContact = (props) => {
  const { id: merchantId, prod_api_secret: token } = props.userSession.activeBrand;
  const [billingEmail, setBillingEmail] = useState(props.billingEmail);
  const [isEmailInvalid, setEmailInvalid] = useState(false);

  const handleSubmitButton = async (e) => {
    if (validateEmail(billingEmail)) {
      setEmailInvalid(false);
      // Google analytics, track merchant billing contact change
      ReactGA.event({ category: 'Admin - Preferences', action: 'Billing Contact change' });
      await updateMerchantContact({ email: billingEmail, phone: '', link: '', slug: 'billing_contact', merchantId, token });
       props.closeDrawer();
    } else {
      setEmailInvalid(true);
    }
    props.refreshMerchantContact();
  };

  const handleEmailChange = (e) => {
    setBillingEmail(e.target.value);
    setEmailInvalid(false);
  }

  const closeEmailError = () => {
    setEmailInvalid(false);
  };

  const validateBillingEmail = () => {
    setEmailInvalid(!validateEmail(billingEmail))
  };


  return (
    <div>
      <TitleAndNav close={props.closeDrawer} title='Billing Contact' />
      <div className='update-payment-method-upper-text'>
        <p>
          Provide a primary contact that Route can use to send you notices for upcoming Route+
          premium collections.
        </p>
        <br />
        <p>
          Route+ premiums are paid by your customers and collected by Route on a routine basis.
        </p>
        <br />
        <p className='bold-paragraph'>
          If you leave this field empty, you will still be billed automatically but will not receive
          billing notifications.
        </p>
      </div>
      <div className='update-payment-method-lower-text'>
        <div className='update-billing-contact-input-header'>
          Email
        </div>
        <input className='input-ss'
          id="email"
          name="billingEmail"
          value={(billingEmail === null || billingEmail === undefined) ? props.billingEmail : billingEmail}
          onChange={handleEmailChange}
               onBlur={validateBillingEmail}
        />
          {isEmailInvalid ? <DrawerValidation isEmail = {isEmailInvalid} closeEmailError={closeEmailError} /> : ''}
      </div>

      <Button className='update-company-details-save-button' type='submit'
        text="Save"
        onClick={handleSubmitButton}
      />
    </div>
  );
};

export default UpdateBillingContact;
