import React from 'react'
import { Theme, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  HeaderContainer,
  Container,
  ContainerContent,
} from '../../components'
import { defaultPaperBorderRadius } from '../../constants/theme';
import DetailsText from '../setup-account/details-text';
import OptionGroup from './components/option-group';
import HelpCenter from './components/help-center';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '0 auto',
    width: '100%',
    maxWidth: 800,
    padding: '0 40px',
    borderRadius: '0px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      margin: '65px auto',
      padding: '0 90px',
      borderRadius: defaultPaperBorderRadius,
    },
  }
}))

const VerifyDomain = (props: any) => {
  const classes = useStyles(props)

  return (
    <HeaderContainer>
      <Container>
        <ContainerContent className={classes.content}>
          <DetailsText
            head="3: Verify Domain"
            description="Choose which way you would like to verify your domain."
          />
          <Divider />

          <OptionGroup />

          <HelpCenter />
        </ContainerContent>
      </Container>
    </HeaderContainer>
  )
}

export default VerifyDomain
