import React, {FC} from 'react';
import {Grid, Box, Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {Button, Container, ContainerContent} from '../../components'
import styleMap from "../../constants/styles";

export type OwnProps = {};

export type StateProps = {};

export type DispatchProps = {
  onBack: () => void;
  tryAgain: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

//#region Styles
const spacing = 0;
const themePxPerSpace = 8;
function getMargin(margin: number): string {
    return `${margin - (spacing * themePxPerSpace)}px`;
}

const useStyles = makeStyles({
  title: {
    marginTop: getMargin(75),
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '33px',
    textAlign: 'center',
  },
  logo: {
    marginTop: getMargin(55),
  },
  textOne: {
    marginBottom: getMargin(35),
    fontSize: '13px',
    lineHeight: '20px',
  },
  textTwo: {
    marginBottom: getMargin(25),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#2C373C',
  },
  button: {
    width: '240px',
  },
  buttonDivider: {
    marginTop: getMargin(35),
    marginBottom: getMargin(25),
    width: '210px',
  },
  boldText : {
    fontWeight: 600,
    marginTop: getMargin(15),
    marginBottom: getMargin(15),
  },
  footer : {
    marginBottom: getMargin(55),
  },
  link: {
    color: styleMap.linkColor,
    textDecoration: 'underline',
    '&:hover': {
      color: styleMap.linkHoverColor,
    }
  }
});
//#endregion

export const AccountCreationError: FC<Props> = (props) => {
    const classes = useStyles();

  return (
    <Container>
      <ContainerContent width={390}>
        <Box mx={7}>
          <Grid container spacing={0} direction={'column'} alignItems={'center'} justify="flex-start">
            <Grid className={classes.title} item xs={12} >
              <span>Looks like there was a problem creating your user account.</span>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.buttonDivider}/>
            </Grid>
            <Grid className={classes.textOne} item xs={9}>
              <span className={classes.boldText}>Please try again,</span> or, you may sign in and continue the process using an existing account.
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.button} onClick={props.tryAgain}>Try Again</Button>
            </Grid>
            <Grid className={classes.boldText} item xs={12}>
              <span>OR</span>
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.button} onClick={props.onBack}>Continue Using Existing Account</Button>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.buttonDivider}/>
            </Grid>
            <Grid item xs={9} className={classes.footer}>
              <span className={classes.textTwo}>If difficulties continue, please contact our Customer Success team at <span className={classes.boldText}>csm@route.com.</span></span>
            </Grid>
          </Grid>
        </Box>
      </ContainerContent>
    </Container>
  );
};
