import React from 'react';

const SVG = ({
  style = {},
  fill = '#313222',
  width = '20px',
  className = '',
  height = '20px',
  viewBox = '0 0 20 20',
  transform = '',
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H9C10.6569 0 12 1.34315 12 3V5C12 5.55228 11.5523 6 11 6C10.4477 6 10 5.55228 10 5V3C10 2.44772 9.55228 2 9 2H3C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H9C9.55229 18 10 17.5523 10 17V15C10 14.4477 10.4477 14 11 14C11.5523 14 12 14.4477 12 15V17C12 18.6569 10.6569 20 9 20H3C1.34315 20 0 18.6569 0 17V3ZM9 10C9 9.44771 9.44771 9 10 9H16.5858L14.2929 6.70711C13.9024 6.31658 13.9024 5.68342 14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289L19.7061 9.29193C19.7086 9.29443 19.7111 9.29693 19.7136 9.29945C19.8063 9.3938 19.8764 9.50195 19.9241 9.61722C19.973 9.73512 20 9.86441 20 10C20 10.2728 19.8907 10.5201 19.7136 10.7005C19.7111 10.7031 19.7086 10.7056 19.7061 10.7081L15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071C13.9024 14.3166 13.9024 13.6834 14.2929 13.2929L16.5858 11H10C9.44771 11 9 10.5523 9 10Z"
    />
  </svg>
);

export default SVG;
