import React, { useState, useEffect, useRef } from 'react';
import { Portal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { defaultPaperBorderRadius } from '../constants/theme';
import { colors } from '../constants/styles';
import { Button } from './button';
import { WarningIcon } from '../media/warning';

const smallX = (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.46094 2.64404L2.62579 7.49536" stroke="#333333" strokeLinecap="round"/>
    <path d="M7.46094 7.49536L2.62579 2.64404" stroke="#333333" strokeLinecap="round"/>
  </svg>
);


export type OwnProps = {};
export type StateProps = {
  errorMessage: string|React.ReactFragment|null;
};
export type DispatchProps = {
  onClose: () => void;
};
export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles({
  root: {
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'fixed',
    overflow: 'hidden',
    height: 'min-content',
    width: '463px',
    transform: 'translateY(0)',
    maxWidth: '100vw',
    borderTopLeftRadius: defaultPaperBorderRadius,
    borderTopRightRadius: defaultPaperBorderRadius,
    backgroundColor: colors.error,
    
    display: 'flex',
    flexDirection: 'column',

    transition: '1s',
  },
  open: {
    transform: 'translateY(-100%)',
    transition: '1s',
  },
  topDrawer: {
    height: '26px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '10px 10px 0 10px',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '45px',
    marginRight: '60px',
    marginBottom: '35px',
  },
  verticalSeparator: {
    marginLeft: '16px',
    height: '100%',
    width: '2px',
    minWidth: '2px',
    backgroundColor: 'white',
    opacity: 0.25,
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '25px',
  },
  message: {
    flexGrow: 0,
    fontFamily: 'Titillium Web, sans-serif',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: 'white',
    '& strong': {
      fontWeight: 'bold',
    },
    '& div~div': {
      marginTop: '5px',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '18px',
  }
}, {name: 'Banner'});

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function Banner(props: Props) {
  const classes = useStyles();

  const [bufferTimeoutId, setBufferTimeoutId] = useState<number|undefined>();
  const [shownErrorMessage, setShownErrorMessage] = useState<string|React.ReactFragment|null>();
  const previousErrorMessage = usePrevious(props.errorMessage);

  // This component has a transition for showing and hiding.
  // If we use `props.errorMessage` directly the error message will
  // disappear immediately from this banner once the error is cleared in
  // the state store.
  // We need to "buffer" the error message so it is still shown during
  // the "disappear" animation.
  useEffect(() => {
    // Condition 1: No message before, message now
    //   Action: Set the shown message
    // Condition 2: No Message now, message before
    //   Action: Set the timeout so the shown message is cleared after the transition
    // Condition 3: Message now, message before, SAME
    //   Action: Do nothing
    // Condition 4: Message now, message before, DIFFERENT
    //   Action: Clear any existing timeout that will clear the message
    //           and set the new message

    if (!previousErrorMessage && props.errorMessage) {
      setShownErrorMessage(props.errorMessage)
    } else if (!props.errorMessage && previousErrorMessage) {
      const timeoutId = setTimeout(() => {
        setShownErrorMessage(undefined);
        setBufferTimeoutId(undefined);
      }, 1000) as any as number;
      setBufferTimeoutId(timeoutId);
    } else if (props.errorMessage === previousErrorMessage) {
    } else if (props.errorMessage !== previousErrorMessage) {
      if (bufferTimeoutId) {
        clearTimeout(bufferTimeoutId);
        setBufferTimeoutId(undefined);
      }
      setShownErrorMessage(props.errorMessage);
    }
  }, [props.errorMessage]);

  const smallCloseButton = (
    <Button
      width={16}
      height={16}
      bgColor="white"
      hoverBgColor="rgba(255, 255, 255, 0.6)"
      onClick={props.onClose}
    >
      {smallX}
    </Button>
  );

  const mainCloseButton = (
    <Button 
      width={76}
      height={19}
      textColor="black"
      bgColor="white"
      hoverBgColor="rgba(255, 255, 255, 0.6)"
      onClick={props.onClose}
    >
      OK
    </Button>
  );

  return (
    <Portal>
      <div className={clsx(classes.root, {[classes.open]: !!props.errorMessage})}>
        <div className={classes.topDrawer}>
          {smallCloseButton}
        </div>
        <div className={classes.mainContent}>
          <div>
            <WarningIcon/>
          </div>
          <div className={classes.verticalSeparator}/>
          <div className={classes.messageContent}>
            <div className={classes.message}>{shownErrorMessage}</div>
            <div className={classes.buttons}>
              {mainCloseButton}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
