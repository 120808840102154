
import React from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './Calendar.scss';

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
        moment(weekStart)
            .add(i, 'days')
            .toDate()
    );
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date)
        .startOf('isoWeek')
        .toDate(),
    to: moment(date)
        .endOf('isoWeek')
        .toDate(),
  };
}

const MONTHS = ['JAN','FEB','MAR','APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'Th', 'F', 'Sa'];

export default class Calendar extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.selectedDays[0] != this.state.selectedDays[0]) {
      this.props.handleWeekRange(this.state.selectedDays);
    }
  }

  state = {
    hoverRange: undefined,
    selectedDays: getWeekDays(getWeekRange(moment().format()).from),
  };

  handleDayChange = (date) => {
    this.setState({
      selectedDays: getWeekDays(getWeekRange(date).from),
    });
  };

  handleDayEnter = date => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = date => {
    this.setState({
      hoverRange: undefined,
    });
  };


  render() {
    const {hoverRange, selectedDays} = this.state;
    const daysAreSelected = selectedDays.length > 0;
    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    return (
      <div className="outer-calender-div">
        <div className="SelectedWeekExample">
          <DayPicker
              selectedDays={selectedDays}
              showOutsideDays
              modifiers={modifiers}
              onDayClick={this.handleDayChange}
              onDayMouseEnter={this.handleDayEnter}
              onDayMouseLeave={this.handleDayLeave}
              firstDayOfWeek={1}
              months={MONTHS}
              weekdaysShort={WEEKDAYS_SHORT}
          />
        </div>
      </div>
    );
  }
}

