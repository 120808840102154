/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AllClaims from './AllClaims/AllClaims';
import IssueRules from './IssueRules';
import ClaimsInsights from './ClaimsInsights/ClaimsInsights';
import NotFound from '../../_shared/ErrorPages/NotFound';
import { getMerchantServiceTier } from '../../../services/merchant';

import './Claims.scss';
import Subnav from '../_shared/Subnav/Subnav';
import { check } from '../components/Authentication/Can/Can';

const Claims = (props) => {
  const { loggedIn } = props.userSession;
  const [claimPages, setClaimPages] = useState([
    { pathname: 'all', pageName: 'All Issues' },
    { pathname: 'insights', pageName: 'Insights' },
  ]);
  const [isRoutePro, setIsRoutePro] = useState(true);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={() => (isRoutePro ? (
          <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/issues/all',
              state: { from: props.location },
            }}
          ></Redirect>
      ))
      }
    />
  );
  const getServiceTier = async () => {
    const claimsInsightsResp = await getMerchantServiceTier(
      props.userSession.activeBrand.prod_api_secret,
      props.userSession.activeBrand.id,
    );

    if (claimsInsightsResp && claimsInsightsResp.protect_programmatic_rules.available_and_active) {
      setClaimPages([
        { pathname: 'all', pageName: 'All Issues' },
        { pathname: 'insights', pageName: 'Insights' },
        { pathname: 'issue-rules', pageName: 'Issue Rules' },
      ]);
    } else {
      setIsRoutePro(false);
    }
  };
  useEffect(() => {
    getServiceTier();
  }, []);

  return loggedIn ? (
    <div className="claims-container-large">
      <Subnav {...props} header="Issues" pages={claimPages} />
      <Switch>
        <Redirect exact from="/issues" to="/issues/all" />
        <Route exact path="/issues/all" render={() => <AllClaims {...props} />} />
        <Route exact path="/issues/insights" render={() => <ClaimsInsights {...props} />} />
        <PrivateRoute exact path="/issues/issue-rules" component={IssueRules} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  ) : null;
};

export default Claims;
