/* eslint-disable default-case */
/* eslint-disable react/prop-types */
import React, { useState, Fragment } from 'react';
import ReactGA from 'react-ga';
import { createStyles, makeStyles, Theme, Popover } from '@material-ui/core';
import TitleAndNav from '../TitleAndNav';

import Button from '../../../../Button/Button';

// todo: # New refactored component
// import Button from '../../../components/Button';
import { mediaUpload } from '../../../../../services/media';
import BrandedOrderPreview from './BrandedOrderPreview';
import {
  Container,
  FormContainer,
  FormGroup,
  Input,
  Label,
  Descriptive,
  Description,
  Form,
  LineBreak,
  Header,
  Subtitle,
  Wrapper,
  PreviewContainer,
  ButtonHidden,
  ButtonDisabled,
  FooterBar,
} from './styles';
import '../UpdateCompanyDetails.scss';
import ImageReq from '../ImageReq';
import { Success } from './Success';
import ImageDropzone from '../../../components/ImageDropzone';
import { updateMerchant } from '../../../../Onboarding-2/api';
import { NotSaved } from './NotSaved';
import { pendoTrack } from '../../../../../services/pendo';
import Can, { check } from '../../../components/Authentication/Can/Can';
import { getActiveMerchantServiceTier } from '../../../../../services/merchant_service_tier';
import GetRouteProWidget from '../../../components/GetRouteProWidget/GetRouteProWidget';
import GetRouteProPopover from '../../../components/GetRouteProPopover/GetRouteProPopover';
import GetRouteProPopoverDown from '../../../components/GetRouteProPopoverDown/GetRouteProPopoverDown';

import permissions from '../../../components/Authentication/permissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
    paper: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
  }),
);

export interface BrandedOrderCardProps {
  /* tag?: string;
  className?: string; */
}

const BrandedOrderCard: React.FC = (props: any) => {
  const classes = useStyles();
  const [heroObj, setHeroObj] = useState({
    hero:
      props.userSession && props.userSession.activeBrand.branded_order_featured_image
        ? props.userSession.activeBrand.branded_order_featured_image
        : '',
    edited: false,
  });
  const [headerImageLink, setHeaderImageLink] = useState({
    link:
      props.userSession && props.userSession.activeBrand.branded_order_image_link
        ? props.userSession.activeBrand.branded_order_image_link
        : '',
    edited: false,
  });
  const [heroError, setHeroError] = useState(false);
  const [toolTipHeroOpen, setToolTipHeroOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveStateActive, setSaveStateActive] = useState({
    active: false,
    location: '',
  });
  const [isEdited, setIsEdited] = useState(false);
  const [merchant, setMerchant] = useState({});
  const [notSavedStateActive, setNotSavedStateActive] = useState(false);
  const [hideRouteProWidget, setHideRouteProWidget] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleIsEdited = () => {
    setIsEdited(true);
  };

  const onGATracking = () => {
    const changes = [];
    if (props.userSession.activeBrand.branded_order_featured_image !== heroObj.hero) {
      changes.push('hero');
    }

    if (changes.length) {
      ReactGA.event({ category: 'Admin - Preferences', action: 'Branded Order Card change', label: changes.join('|') });
    }
  };

  const saveMerchant = async () => {
    setIsLoading(true);
    setIsEdited(false);
    if (
      !props ||
      !props.userSession ||
      !props.userSession.activeBrand ||
      !props.userSession.activeBrand.prod_api_secret
    ) {
      return;
    }

    onGATracking();

    if (heroObj.edited && heroObj.hero) {
      try {
        const response: any = await mediaUpload(
          {
            branded_order_featured_image: heroObj.edited && heroObj.hero,
          },
          props.userSession.activeBrand.id,
          props.userSession.activeBrand.prod_api_secret,
        );
        setMerchant({
          ...merchant,
          branded_order_featured_image: response.branded_order_featured_image,
        });

        pendoTrack('Order Card Image Upload', {
          message: 'Order Card Image was uploaded successfully',
          merchantId: props.userSession.activeBrand.id,
        });
      } catch (e) {
        console.log(e);
        setHeroError(true);
        return;
      }
    }

    if (headerImageLink.edited && headerImageLink.link) {
      setMerchant({
        ...merchant,
        branded_order_image_link: headerImageLink.link,
      });
    }

    setSaveStateActive({ active: true, location: 'saving' });
    setIsLoading(false);
  };

  const onSaveMerchant = async () => {
    const { activeBrand } = props.userSession;

    const response = await updateMerchant({
      id: activeBrand.id,
      data: merchant,
      token: activeBrand.prod_api_secret,
    });

    if (response) {
      props.updateActiveBrand(response);
    }
  };

  const onDropBrandedOrderImage = (acceptedFiles: any) => {
    const reader = new FileReader();

    if (reader.readyState === 0 && acceptedFiles.length === 0) {
      setHeroError(true);
      return;
    }

    reader.onload = () => {
      const binaryStr = reader.result;
      setHeroObj({ hero: binaryStr, edited: true });
    };

    acceptedFiles.forEach((file: any) => reader.readAsDataURL(file));
    handleIsEdited();
  };

  const onUpdateImage = async (image: any) => {
    setHeroObj({
      ...heroObj,
      hero: image,
    });
  };

  const coverImageContent = !heroError ? (
    <Fragment>
      <ImageDropzone
        onDrop={onDropBrandedOrderImage}
        onUpdateImage={onUpdateImage}
        image={heroObj.hero}
      ></ImageDropzone>

      {toolTipHeroOpen === false ? (
        <Fragment />
      ) : (
        <ImageReq close={() => setToolTipHeroOpen(false)} errorState={false} top={'26px'} />
      )}
    </Fragment>
  ) : (
    <ImageReq close={() => setHeroError(false)} errorState={true} top={'26px'} />
  );

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Can
        role={getActiveMerchantServiceTier()}
        services={getActiveMerchantServiceTier()}
        perform="merchant_brand_elements"
        yes={() => <></>}
        no={() => (
          <>
            {!hideRouteProWidget && (
              <GetRouteProWidget
                userSession={props.userSession}
                title="Promo Content"
                subtitle="Engage with customers beyond the checkout"
                description="Show branded content and promos every single time a customer tracks their order for full engagement throughout the post-purchase experience. <b><span>Heads up! Activate Route Pro to take full advantage of this feature.</span></b>"
                titleSuccess="Thanks for checking out Route Pro!"
                messageSuccess="Keep your eyes on your inbox! A member from our team will be emailing you shortly to get you set up."
                tryProButton="Try Route Pro for Free"
                tryOutButton="Explore This Feature"
                closeButton="Close"
                onTryOutPress={() => {
                  setHideRouteProWidget(true);
                }}
                onClosePress={() => {
                  setHideRouteProWidget(true);
                }}
              />
            )}
          </>
        )}
      />
      <Wrapper>
        <Header>
          <TitleAndNav
            title="Promo Content"
            close={() => {
              if (isEdited) {
                setNotSavedStateActive(true);
                return;
              }
              props.closeDrawer();
            }}
            isEdited={isEdited}
            location={saveStateActive.location}
            updateSaveState={setSaveStateActive}
            forceClose={() => {}}
          />
        </Header>
        {notSavedStateActive && isEdited && (
          <NotSaved
            closeDrawer={props.closeDrawer}
            setKeepDrawerOpen={(keepDrawerOpen: any) => {
              props.setKeepDrawerOpen(keepDrawerOpen);
              setNotSavedStateActive(false);
            }}
            open={isEdited}
          />
        )}
        {saveStateActive.active ? (
          <Success
            open={saveStateActive.active}
            closeDrawer={() => {
              onSaveMerchant();
              props.closeDrawer();
            }}
            setKeepDrawerOpen={props.setKeepDrawerOpen}
            location={saveStateActive.location}
            onPrimaryAction={saveStateActive.location === 'saving' ? props.closeDrawer : setSaveStateActive}
            onSecondaryAction={saveStateActive.location === 'saving' ? setSaveStateActive : props.closeDrawer}
          />
        ) : null}
        <Container>
          <PreviewContainer>
            <BrandedOrderPreview
              brandedOrderFeaturedImage={heroObj.hero}
              storeName={props?.userSession?.activeBrand?.store_name || ''}
              storeLogo={props?.userSession?.activeBrand?.store_logo || ''}
              storeHero={props?.userSession?.activeBrand?.store_hero || ''}
            />
          </PreviewContainer>
          <FormContainer>
            <Subtitle>Tell your brand story.</Subtitle>
            <Description>
              Here is where you can show your brand story, something that can delight and inform your customers.
            </Description>
            <LineBreak />
            <Form>
              <FormGroup>
                <Label>Branded Order Card Image</Label>
                <Descriptive>
                  Recommended w: 828px x h: 828px
                  <span onClick={() => setToolTipHeroOpen(!toolTipHeroOpen)} className="image-req-tool-tip">
                    {' '}
                    IMAGE REQs
                  </span>
                </Descriptive>
                {coverImageContent}
              </FormGroup>
              <FormGroup>
                <div className="routing-row">
                  <div className="field">
                    <Label htmlFor="label">
                      Promo Image Link <span>(Optional)</span>
                    </Label>
                    <Input
                      placeholder="companyname.com/link"
                      name="headerImageLink"
                      value={headerImageLink.link}
                      onChange={(e) => {
                        setHeaderImageLink({
                          link: e.target.value,
                          edited: true,
                        });
                        handleIsEdited();
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Logo</Label>
                <Descriptive>
                  You may upload or change your logo on your <span>PROFILE BUILDER</span>.
                </Descriptive>
              </FormGroup>
            </Form>
          </FormContainer>
        </Container>

        <Can
          role={getActiveMerchantServiceTier()}
          services={getActiveMerchantServiceTier()}
          perform="merchant_brand_elements"
          yes={() => (
            <div className="update-company-details-save-container">
              <div className="update-company-details-save-button">
                <Button
                  type="submit"
                  onClick={saveMerchant}
                  isLoading={isLoading}
                  loadingText={'Saving'}
                  text="Save"
                  disabled={!isEdited}
                  hoverState={true}
                />
              </div>
            </div>
          )}
          no={() => (
            <>
              <FooterBar>
                <ButtonHidden className="test" onClick={openPopover}>
                  <ButtonDisabled>Save</ButtonDisabled>
                </ButtonHidden>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={closePopover}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  className={classes.popover}
                  classes={{
                    paper: classes.paper,
                  }}
                >
                  <GetRouteProPopoverDown onClose={closePopover} userSession={props.userSession} />
                </Popover>
              </FooterBar>
            </>
          )}
        />
      </Wrapper>
    </>
  );
};

export default BrandedOrderCard;
