import React, { FC } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormRenderProps } from 'react-final-form';

import {
  HeaderContainer,
  ProgressContainerContent,
  StepEnum,
} from '../../components';
import InternalForm from "./internal-form"
import { FormData } from "./../../store"
import { State } from '../../../Onboarding-2/store';
import { useDispatch, useSelector } from 'react-redux';
import { AccountCreationError } from './account-creation-error';
import { dismissError } from "./../../actions/setup-account"

export type OwnProps = {};

export type StateProps = {
  initialLockedEmail?: string;
};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    height: 136,
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 90,
    paddingRight: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  }
}), { name: "SetupAccountForm" });

export const SetupAccountForm: FC<Props> = (props) => {
  const errorReason = useSelector((state: State) => state.app.brand.page.errorReason)
  const formState = useSelector((state: State) => state.app.brand.form)
  const dispatch = useDispatch()
  const classes = useStyles();

  return (
    <>
    {errorReason === "User Exists" ?
      <AccountCreationError tryAgain={() => dispatch(dismissError())} /> :
      <HeaderContainer>
        <ProgressContainerContent width={800} progressStep={StepEnum.SetupAccount}>
          <div className={classes.innerContent}>
            <Form
              onSubmit={props.onSubmit}
              initialValues={{
                email: props.initialLockedEmail || formState?.email || '',
                fullName: formState?.fullName || '',
                password: formState?.password || '',
                confirmPassword: formState?.confirmPassword || '',
                domain: formState?.domain || '',
                storeName: formState?.storeName || '',
                country: formState?.country || '',
                coverImage: formState?.coverImage || '',
                logoImage: formState?.logoImage || '',
              }}
            >
              {(formProps: FormRenderProps<FormData>) => {
                return <InternalForm {...props} {...formProps} />;
              }}
            </Form>
          </div>
        </ProgressContainerContent>
      </HeaderContainer>
    }
    </>
  );
}
