import {RouteApi} from '../components/Onboarding-2/api/types';
import {store} from '../index';
import {loadStateFromBrowser} from '../components/Onboarding-2/actions/sign-in/util';
import {ThunkDispatch} from '../components/Onboarding-2/store';

export function isNonEmptyObject<T extends NonNullable<any>>(item: T | {}): item is T {
  //@ts-ignore
  return !(Object.keys(item).length === 0 && item.constructor === Object)
}

// Typed this way because when logging out, empty objects/arrays are set
// in session storage instead of undefined
export type BrowserUserSessionData = {
  loggedIn: boolean;
  user: RouteApi.User.UserResponse | {};
  brands: RouteApi.Merchant.MerchantResponse[];
  activeBrand: RouteApi.Merchant.MerchantResponse | {};
  tier: any;//RouteApi.MerchantService.MerchantServiceResponse | {};
  merchantRole?: any;
};

//@ts-ignore - TODO: Fix warning that session storage could be null, and JSON.parse only accepts strings
export const getUserSession = () => JSON.parse(sessionStorage.getItem('userSession')) as BrowserUserSessionData|null;
export const setUserSessionInStorage = (userSession: BrowserUserSessionData, emitToStateStore = true) => {
  sessionStorage.setItem('userSession', JSON.stringify(userSession));
  if (isNonEmptyObject(userSession.user)) {
    sessionStorage.setItem('uToken', userSession.user.token as string);
    sessionStorage.setItem('uId', userSession.user.id);
  } else {
    sessionStorage.removeItem('uToken');
    sessionStorage.removeItem('uId');
  }


  if (isNonEmptyObject(userSession.activeBrand)) {
    sessionStorage.setItem('bId', userSession.activeBrand.id);
  } else {
    sessionStorage.removeItem('bId');
  }

  if (!emitToStateStore) {
    return;
  }

  (store.dispatch as ThunkDispatch)(loadStateFromBrowser())
}
export const getActiveBrand = () => {
  const userSession = getUserSession();

  return userSession && userSession.activeBrand ? userSession.activeBrand : false;
};
