import styled from 'styled-components';
import Colors from '../../../../constants/colors';

import UnderlineIcon from '../../../../assets/images/underline-icon.svg';
import ArrowIcon from '../../../../assets/images/arrow-icon.svg';

export const Container = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  max-width: 500px;
  height: 40px;
  border-radius: 12px;
  background: #eeeeee;
  background-image: url(${UnderlineIcon});
  background-repeat: no-repeat;
  background-position: 0;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #666666;
`;

export const Dropdown = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #666666;

  width: 200px;
  height: 40px;
  margin: 0;
  padding-left: 15px;

  background: none;
  border: none;
`;

export const DropdownHeader = styled.div<any>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(${ArrowIcon});
    background-repeat: no-repeat;
    background-position: ${(props) => (props.isDropdownOpened ? 'calc(100% - 178px) 50%' : 'calc(100% - 25px) 50%')};
    transform: ${(props) => (props.isDropdownOpened ? 'rotate(180deg)' : 'none')};
  }
`;

export const DropdownList = styled.ul<any>`
  position: absolute;
  z-index: 999;
  top: 40px;
  left: 0px;
  width: 100%;
  background: #f7f7f7;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.32));
  border-radius: 12px;
`;

export const DropdownListItem = styled.li<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;

  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
  padding-left: 15px;
  border-top: 1px solid #eeeeee;

  &:hover {
    background: #eeeeee;
  }
  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const SearchInput = styled.input<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #000000;

  position: absolute;
  right: 0px;
  width: 300px;
  height: 40px;

  padding-left: 15px;
  padding-right: 40px;

  background: #f7f7f7;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 12px;

  &::placeholder {
    color: #666666;
  }
`;

export const SearchButton = styled.button<any>`
  position: absolute;
  right: 0px;

  background: none;
  border: none;

  width: 40px;
  height: 40px;
`;
