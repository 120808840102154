import React from 'react';
import LineGraph from './LineGraph';
import {
  CardLabel,
  FortyPixelsVerticalSpace,
  GraphContainer,
  GraphLoading,
} from '../Dashboard.styles';
import IconClose from '../../../components/IconClose';

const LineGraphDisplayWidget = (props) => (
  <>
    <CardLabel>{props.graphTitle}</CardLabel>
    <GraphContainer>
      {props.graphData.isLoading ? (
        <GraphLoading>&nbsp;</GraphLoading>
      ) : (
          <>
            <LineGraph
              labelPrefix={(props.graphTitle && (props.graphTitle.includes('Savings') || props.graphTitle.includes('Revenue')))
                ? '$' : ''}
              lineData={props.graphData && props.graphData.dailyAmounts}
              // CURRENTLY DEFAULTING TO USD currency={props.userSession.activeBrand.currency}
              labels={props.graphData && props.graphData.labels}
            />
          </>
        )}

      {props.onClose ? (
        <div style={{
          position: 'absolute',
          right: '15px',
          top: '10px',
          cursor: 'pointer',
        }} onClick={() => props.onClose()}>
          <IconClose />
        </div>
      ) : null}
    </GraphContainer>
  </>
);

export default LineGraphDisplayWidget;
