import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {DispatchProps, StateProps, PasswordResetRequest, FormData} from './request';
import {requestPasswordReset, goBack, forgotEmail, hidePasswordResetRequestInvalidEmailErrorActionCreator} from '../../../actions/sign-in/password-reset-request'
import { State } from '../../../store';

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (data) => {
      dispatch(requestPasswordReset(data as FormData));
      return undefined;
    },
    onBack: () => dispatch(goBack()),
    onForgotEmail: () => dispatch(forgotEmail()),
    onHideInvalidEmail: () => {dispatch(hidePasswordResetRequestInvalidEmailErrorActionCreator())},
  };
}

export const mapStateToProps = (state: State): StateProps => {
  return {
    showInvalidEmail: state.app.signIn.showInvalidPasswordResetRequestError,
  };
}

export const ConnectedPasswordResetRequest = connect(mapStateToProps, mapDispatchToProps)(PasswordResetRequest);
