import React, { Component } from 'react';

class GenericError extends Component {
  render() {
    return (
      <div>
                An Error occurred
      </div>
    );
  }
}

export default GenericError;
