import styled, {css} from 'styled-components';

export const Container = styled.div<any>`
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.72);
  top: 0;
  height: 100%;
  ${(props) => props.gutterHeight && css`
    height: calc(100% - 45px);
    top: 45px;
  `}
`;

export const Modal = styled.div`
  background: rgba(255,255,255);
  height: 44px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  padding: 0 25px;
`;

export const LoaderSpinner = styled.div`
  #lottie-web-react {
    width: 20px;
  }
  margin-left: 10px;
`;

export default {
  Container,
  Modal,
};
