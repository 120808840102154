import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { motion } from 'framer-motion';

import { addBreadcrumb, Severity } from '@sentry/browser';
import Card from '../../components/Card';
import Typography from '../Typography';

import { createCsmInteraction } from '../../../../services/merchant_interaction';

import WidgetBgLight from '../../../../assets/images/route-pro-widget-bg.png';
import WidgetBgDark from '../../../../assets/images/route-pro-widget-bg-lg-dark.png';

import CloseIconHover from '../../../../assets/images/close-icon-hover.svg';
import RouteProMdIcon from '../../../../assets/images/route-pro-badge-md.svg';
import RouteProLgIcon from '../../../../assets/images/route-pro-badge-lg.svg';

import {
  Header1,
  Button1,
  Button2,
  Title2,
  SubTitle2,
  ButtonPrimary,
  CloseButton,
} from './GetRouteProLargeWidget.styles';

import { IProps } from './interface';
import dictionary from '../../../../constants/dictionary';
import { ROUTE_PRICING_URL } from '../../../../constants';

const container = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const GetRouteProLargeWidget = (props: IProps) => {
  const [state, setState] = useState({ page: 1 });

  const onGetRouteProPress = async () => {
    if (props?.userSession) {
      try {
        const { user, activeBrand: merchant } = props?.userSession;
        const payload = {
          interaction_type: 'get_route_pro_trial',
        };
        await createCsmInteraction(payload, merchant.id, user.token);
        setState({ page: 2 });
      } catch (err) {
        addBreadcrumb({
          message: dictionary.MESSAGE_ERROR_DEATTACH_USER_AND_MERCHANT_TRIAL_REQUEST,
          category: 'Get Route Pro 30 days trial',
          level: Severity.Error,
          data: {
            error: err,
          },
        });
      }
    }
  };

  const onClosePress = async () => {
    setState({ page: 1 });
  };

  return (
    <>
      {state.page === 1 && (
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          transition={{ ease: 'easeOut', duration: 1, delay: 0.25 }}
        >
          <Card image={WidgetBgLight} style={{ minWidth: 880, padding: 40 }}>
            <motion.div
              variants={item}
              initial="hidden"
              animate="visible"
              transition={{ ease: 'easeOut', duration: 1, delay: 1.25 }}
            >
              <Box mb={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={RouteProMdIcon} alt="route pro" style={{ marginRight: 15 }} />
                <Header1>Customer Engagement Insights</Header1>
              </Box>

              <Grid item lg={6} md={6} sm={12}>
                <Box mb={4}>
                  <Typography variant="h3" component="h1" color="gray">
                    Optimize your brand’s
                    <br /> post-purchase engagement with real-time insights
                  </Typography>
                </Box>
                <Box mb={8}>
                  <Typography variant="p" component="p" color="textPrimary">
                    Keep an eye on how your customers are tracking packages, engaging with promos, and using the app
                    versus email, so you know which tactics to tweak for the best customer experience possible.
                  </Typography>
                </Box>
                <Button1 onClick={onGetRouteProPress}>Try Route Pro for Free</Button1>
                <ButtonPrimary
                  onClick={() => {
                    window.open(ROUTE_PRICING_URL, '_blank');
                  }}
                  style={{ marginLeft: 15 }}
                >
                  Learn More
                </ButtonPrimary>
              </Grid>
            </motion.div>
          </Card>
        </motion.div>
      )}

      {state.page === 2 && (
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          transition={{ ease: 'easeOut', duration: 1, delay: 0.25 }}
        >
          <Card
            image={WidgetBgDark}
            style={{
              minWidth: 880,
              paddingTop: 90,
              paddingBottom: 90,
              paddingLeft: 40,
              paddingRight: 40,
            }}
          >
            <motion.div
              variants={item}
              initial="hidden"
              animate="visible"
              transition={{ ease: 'easeOut', duration: 1, delay: 1.25 }}
            >
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item lg={12} md={12} sm={12}>
                  <Box mb={8}>
                    <img src={RouteProLgIcon} alt="route pro" style={{ marginRight: 15 }} />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                  <Box mb={4}>
                    <Title2>Thanks for checking out Route Pro!</Title2>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} style={{ maxWidth: 620 }}>
                  <Box mb={8}>
                    <SubTitle2>
                      Keep your eyes on your inbox! A member from our team will be emailing you shortly to get you set
                      up.
                    </SubTitle2>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                  <Button2 onClick={onClosePress}>Close</Button2>
                </Grid>
              </Grid>
            </motion.div>
            <CloseButton
              srcSet={CloseIconHover}
              onClick={() => {
                onClosePress();
              }}
            />
          </Card>
        </motion.div>
      )}
    </>
  );
};

export default GetRouteProLargeWidget;
