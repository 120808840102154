import {Action} from 'redux';
import {AccountCreationState, initialState} from '../../store'
import {
  REDIRECTING_DONE,
  RedirectingDoneAction,
} from '../../actions/redirect/redirect';
import {
  ACCOUNT_CREATION_FAILED,
  AccountCreationFailedAction,
  HIDE_DOMAIN_EXISTS_ERROR,
  HideDomainExistsErrorAction,
} from '../../actions/account-creation/account-creation';

type ActionableAction = RedirectingDoneAction | AccountCreationFailedAction | HideDomainExistsErrorAction;

function isActionableAction(action: Action<any>): action is ActionableAction {
  return action.type === REDIRECTING_DONE || action.type === ACCOUNT_CREATION_FAILED || action.type === HIDE_DOMAIN_EXISTS_ERROR;
} 

export const accountCreationReducer = (state: AccountCreationState = initialState['accountCreation'], action: ActionableAction | Action<any>): AccountCreationState => {
  if (!isActionableAction(action)) {
    return state;
  }
  
  switch(action.type) {
    case REDIRECTING_DONE: return {
      ...state,
      ...action.onboardingData,
    }
    case ACCOUNT_CREATION_FAILED: return {
      ...state,
      page: {
        ...state.page,
        showDomainExistsError: action.errorReason === 'MerchantExists',
      },
    }
    case HIDE_DOMAIN_EXISTS_ERROR: return {
      ...state,
      page: {
        ...state.page,
        showDomainExistsError: false,
      }
    }
  }
}
