import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Label = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-size: 15px;
  line-height: 17px;
  color: #333333;
  font-weight: bold;
  display: inline-flex;
`;
