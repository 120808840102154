import React from 'react';
import colors from '../../constants/colors';

const SVG = ({
  style = {},
  circleFill = colors.primaryColor,
  chevronFill = '#fff',
  width = '24px',
  className = '',
  height = '24px',
  viewBox = '0 0 24 24',
  transform = 'rotate(90)',
  hoverState = false,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    hoverstate={hoverState}
  >
    <rect className={`${hoverState ? 'hover-chevron' : ''}`} width="24" height="24" rx="12" fill={circleFill} />
    <path fill={chevronFill} d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
  </svg>
);

export default SVG;
