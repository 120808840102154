import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {State} from '../../store';

import {continueToPremiums} from '../../actions/thank-you-page';
import {FormData, StateProps, ThankYouPage, DispatchProps} from './thank-you-page';

export const mapStateToProps = (state: any): StateProps => {
  const tpState = state.app.userData?.activeBrand;
  return {
    isShopify: tpState !== undefined ? tpState.platform_id === 'shopify' : false,
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (values: FormData) => {
      dispatch(continueToPremiums(values));
      return undefined;
    },
  };
}

export const ConnectedThankYouPage = connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
