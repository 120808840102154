import React from 'react';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Button } from '@material-ui/core';

import NavArrowIcon from '../../../../assets/icons/nav-arrow-icon';
import { portalScroll } from '../../utils/portalScroll';
import colors from '../../../../constants/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      fontFamily: 'Titillium Web, sans-serif',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontSize: '10px',
      color: colors.primaryColor,
      textTransform: 'capitalize',
    },
    labelPrev: {
      marginLeft: '5px',
    },
    labelNext: {
      marginRight: '5px',
    },
  }),
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = (props: TablePaginationActionsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    portalScroll(0, 0);
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    portalScroll(0, 0);
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Button className={classes.button} onClick={handleBackButtonClick} disabled={page === 0}>
        <NavArrowIcon fill={page === 0 ? '#CCCCCC' : colors.primaryColor} />
        <span className={classes.labelPrev}>Prev</span>
      </Button>
      {page + 1}
      <Button
        className={classes.button}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <span className={classes.labelNext}> Next </span>
        <NavArrowIcon
          fill={page >= Math.ceil(count / rowsPerPage) - 1 ? '#CCCCCC' : colors.primaryColor}
          transform="rotate(180)"
        />
      </Button>
    </div>
  );
};

export default TablePaginationActions;
