import React, { useEffect, useReducer, useState } from 'react';
import { ILoadingModalProps } from './interface.d';
import {
  Container,
  Modal,
  LoaderSpinner,
} from './LoadingModal.styles';
import loadingSpinnerData from './loading-spinner.json';
import Typography from '../Typography';

type LottieType = typeof import('lottie-web-react').default;
let Lottie: LottieType | undefined;
const LoadingModal: React.FC<ILoadingModalProps> = ({ isLoading }) => {
  const [message, setMessage] = useState('Loading...');
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [step, setStep] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const steps: any = {
    0: 'Loading...',
    5: 'Still working...',
    10: 'Sorry, this may take over a minute to load',
    20: 'Still gathering data',
    30: 'Still working',
    45: 'Yes, still loading',
    60: 'Gathering data',
    75: 'You may need to try again in a few minutes',
  };

  useEffect(() => {
    if (steps[step]) {
      setMessage(steps[step]);
    }
  }, [step]);

  useEffect(() => {
    import('lottie-web-react').then((module) => {
      Lottie = module.default;
      forceUpdate();
    }).catch();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => {
        const s = seconds + 1;
        setStep(s);
        return s;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Modal>
        <Typography variant="p" color="primary">{message}</Typography>
        {Lottie && (
          <LoaderSpinner>
            <Lottie
              options={{
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: loadingSpinnerData,
              }}
              playingState={'play'}
            />
          </LoaderSpinner>
        )}
      </Modal>
    </Container>
  );
};

export default LoadingModal;
