import {AxiosError} from 'axios';
import {push} from 'connected-react-router';
import {Dispatch} from 'redux';
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  setUser as setSentryUser,
  Severity,
} from '@sentry/browser';
import ReactGA from 'react-ga';

import {loginUser as apiLoginUser, getMerchantsForUser, linkMerchantToUser} from '../../api';
import {State, ThunkDispatch, AppState} from '../../store';
import {getActiveMerchant, exportStateToBrowser, noopAction} from './util';
import {redirectAfterExplicitSignIn} from '../redirect/redirect';
import {RouteApi} from '../../api/types';
import {setGlobalSentryUserData} from '../../../../helpers/sentry';
import { getMerchantServiceTier } from '../../../../services/merchant_service_tier';
import { getMerchantRole } from '../../../../services/user';

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED' as const;
export interface UserLoginStartedAction {
  type: typeof USER_LOGIN_STARTED,
};

export function userLoginStarted(): UserLoginStartedAction {
  return {
    type: USER_LOGIN_STARTED,
  };
}

export type UserLoggedInData = {
  user: RouteApi.User;
  brands: RouteApi.Merchant[];
  activeBrand: RouteApi.Merchant;
  tier: RouteApi.MerchantService;
  merchantRole?: any;
};

export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED' as const;
export interface UserLoginSucceededAction extends UserLoggedInData {
  type: typeof USER_LOGIN_SUCCEEDED;
};

export function userLoginSucceeded(data: UserLoggedInData): UserLoginSucceededAction {
  return {
    type: USER_LOGIN_SUCCEEDED,
    ...data,
  };
}

export type ExistingUserSessionData = UserLoggedInData & {
  loggedIn: boolean;
}

export const USER_DATA_LOADED = 'USER_DATA_LOADED' as const;
export interface UserDataLoadedAction {
  type: typeof USER_DATA_LOADED;
  data: AppState['userData'];
}

export function userDataLoaded(data: AppState['userData']): UserDataLoadedAction {
  return {
    type: USER_DATA_LOADED,
    data,
  }
}

export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED' as const;
export interface UserLoginFailedAction {
  type: typeof USER_LOGIN_FAILED;
  reason: 'unknown' | 'credentials',
};

export function userLoginFailed(reason?: 'unknown' | 'credentials'): UserLoginFailedAction {
  return {
    type: USER_LOGIN_FAILED,
    reason: reason ?? 'unknown',
  };
}

export const HIDE_CREDENTIALS_ERROR = 'HIDE_CREDENTIALS_ERROR' as const;
export interface HideCredentialsErrorAction {
  type: typeof HIDE_CREDENTIALS_ERROR;
}

export function hideCredentialsError(): HideCredentialsErrorAction {
  return {
    type: HIDE_CREDENTIALS_ERROR,
  };
}

export type UserActions =
  UserLoginStartedAction |
  UserLoginSucceededAction |
  UserLoginFailedAction |
  HideCredentialsErrorAction;

export type LoginData = {
  username: string;
  password: string;
  userInitiatedLogIn?: boolean;
}

// This function is responsible for:
// 1. Logging the user in
// 2. Getting the brands/merchants associated with a user
// 3. Determining the correct brand/merchant to set as "active"
// 4. Firing an action with all user data (user + merchants) that will
//    ultimately end up storing that data in the state store
// 5. Making sure that the state we have is reflected in the rest
//    of the app (non-redux)
// 6. If this was a user initiated login, start the redirect process
//
export const loginUser = (data: LoginData) => {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Starting user login',
        data: {
          userInitiatedLogIn: data.userInitiatedLogIn === true,
        },
        category: 'login',
        level: Severity.Info,
      });

      dispatch(userLoginStarted());

      let user: RouteApi.User;
      try {
        user = await apiLoginUser(data);
      } catch(err) {
        if (err.response) {
          const axiosError = err as AxiosError;
          if (axiosError.response!.status === 404 ||
              axiosError.response!.status === 401) {
            addBreadcrumb({
              message: 'User login failed due to credentials',
              category: 'login',
              level: Severity.Info,
            });
            return dispatch(userLoginFailed('credentials'));
          }
        }

        throw err;
      }

      const {merchant: onboardingMerchant} = getState().app.accountCreation;

      let merchants = await getMerchantsForUser({
        userId: user.id,
        userToken: user.token
      });

      if (onboardingMerchant && !merchants.some((merchant) => merchant.prod_api_secret === onboardingMerchant.prod_api_secret)) {
        // We are coming from onboarding and have a merchant defined, but this user isn't linked to it
        const linkSuccessful = await linkMerchantToUser({
          userId: user.id,
          merchantToken: onboardingMerchant.prod_api_secret,
        });

        if (!linkSuccessful) {
          // This could mean the request failed because the user and merchant are already linked,
          // or it could mean we encountered a random error. These are both error conditions because
          // we already checked to see if this merchant is in the list of merchants associated with
          // the user and we didn't find this new merchant. If this failed because there is already a link
          // then we have a larger problem.
          throw new Error('Unable to successfully link user and merchant');
        }

        merchants = await getMerchantsForUser({
          userId: user.id,
          userToken: user.token,
        });
      }

      if (!merchants.length) {
        addBreadcrumb({
          message: 'After log in - No merchants found for this user',
          category: 'login',
          level: Severity.Warning,
        });
        throw new Error('Expected at least one Merchant linked to user');
      }

      const activeBrand = onboardingMerchant ?? getActiveMerchant(merchants);
      const serviceTier = await getMerchantServiceTier(activeBrand.id, activeBrand.prod_api_secret);
      const { merchant_role: merchantRole } = await getMerchantRole(user.id, user.token, activeBrand.id);

      // Validate our assumption: the merchant stored from the account creation process
      // should be in the list of merchants returned for this user
      if (!merchants.some((merchant) => merchant.id === activeBrand.id)) {
        captureMessage('Active user merchant not found in list of user merchants', Severity.Error);
      }
      const userData: UserLoggedInData = {
        user,
        brands: merchants,
        activeBrand,
        tier: serviceTier,
        merchantRole,
      };

      setGlobalSentryUserData({reduxSessionData: userData});

      dispatch(userLoginSucceeded(userData));
      dispatch(exportStateToBrowser());
      addBreadcrumb({
        message: 'Successful Sign In',
        category: 'login',
        level: Severity.Info,
      });

      if (data.userInitiatedLogIn) {
        ReactGA.set({ userId: user.id }); // TODO this is not taking
        ReactGA.event({
          category: 'Sign In',
          action: 'Successful Sign In',
        });

        return dispatch(redirectAfterExplicitSignIn());
      }

      return dispatch(noopAction);
    } catch(err) {
      if (data.userInitiatedLogIn) {
        ReactGA.event({
          category: 'Sign In',
          action: 'Failed Sign In',
        });
      }

      captureException(err);
      return dispatch(userLoginFailed());
    }
  }
}

export const sendToForgotPassword = () => {
  return (dispatch: Dispatch) => {
    dispatch(push('/onboarding/sign-in/request-password-reset'));
  }
}

export const sendToForgotEmail = () => {
  return (dispatch: Dispatch) => {
    dispatch(push('/onboarding/forgot-email'));
  }
}
