import { addBreadcrumb, Severity } from '@sentry/browser';
import { Merchant } from '../models/merchant';
import openApi from './open-api';
import { getHeadersWithToken } from './utils';
import dictionary from '../constants/dictionary';

export const mediaUpload = async (
  merchant: Merchant = {},
  merchantId: string,
  token: string,
) => {
  try {
    const body: Merchant = {
      ...merchant,
    };
    const { data } = await openApi.post(`/merchants/${merchantId}/media`, body, getHeadersWithToken(token));
    return data[0];
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_MEDIA_UPLOAD,
      category: 'media',
      data: {
        merchantId,
        error: err,
        ...merchant,
      },
      level: Severity.Error,
    });
    return false;
  }
};

export default {
  mediaUpload,
};
