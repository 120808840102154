import styled from 'styled-components';
import colors from '../../../../constants/colors';

const handleColorType = (props: any) => {
  if (props.reverseColor) {
    if (props.disabled) {
      return '#DDDDDD';
    }
    return colors.primaryColor;
  }
  return '#FFFFFF';
};

const handleBackgroundColorType = (props: any) => {
  if (props.reverseColor) {
    if (props.disabled) {
      return '#F7F7F7';
    }
    return 'FFFFFF';
  }
  if (props.disabled) {
    return '#DDDDDD';
  }
  return colors.primaryColor;
};

export const Wrapper = styled.div<any>`
  font-family: Titillium Web;
  display: 'block';
  width: 100%;
  height: '1px';
  background-color: '#F7F7F7';
  margin-left: 50px;
`;

export const SubsetRulesContainer = styled.div<any>`
  background: #ffffff;
  box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  width: 552px;
  margin: 10px;
  padding: 15px 30px;

  h1 {
    margin-left: 20px;
    margin-bottom: -15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #333333;
  }
`;

export const RulesContentContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HeaderContainer = styled.div<any>`
  margin-left: 15px;
  color: #333333;
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  div {
    font-size: 11px;
    line-height: 17px;
    width: 466px;
    margin: 10px 0;
  }
`;

export const Button = styled.button<any>`
cursor: ${(props) => (props.disabled === true ? 'default' : 'pointer')};
margin: 0 20px 15px 0;
background: ${(props) => (props.reverseColor ? '#FFFFFF' : colors.primaryColor)};
background: ${(props) => handleBackgroundColorType(props)};
border-radius: 20px;
text-decoration: none;
color: ${(props) => (props.reverseColor ? colors.primaryColor : 'white')};
color: ${(props) => handleColorType(props)};
border: 1.5px solid ${(props) => (props.disabled ? '#DDDDDD' : colors.primaryColor)};
width: 100px;
padding: 5px;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 18px;
}
`;

export const RadioContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
    margin-left: 10px;
  }
`;
