import React from 'react';
import { Container } from './MetricList.styles';

import { IMetricListProps } from './interface';

const MetricList: React.FC<IMetricListProps> = ({ children }: IMetricListProps) => (
  <Container>
    {children}
  </Container>
);

export default MetricList;
