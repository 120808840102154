import _ from "lodash"

const Storage = {
  save: (item = "", state: any): void => {
    try {
      if (typeof state === "boolean" || typeof state === "string") {
        sessionStorage.setItem(item, state as string)
        return
      }

      const serializedState = JSON.stringify({ ...state })
      sessionStorage.setItem(item, serializedState)
    } catch (err) {
      console.error(err)
    }
  },
  load: (item = ""): any => {
    try {
      const storageItem = sessionStorage.getItem(item)
      if (storageItem === null) return null

      const obj = JSON.parse(storageItem)
      if (_.isEmpty(obj)) return null

      return obj
    } catch (err) {
      return null
    }
  }
}

export default Storage
