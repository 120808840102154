import React, { FC } from 'react';
import * as _ from 'lodash';
import MultiLineGraph from './MultiLineGraph';

const ComparativeGraph: FC<any> = (props) => {

  return (
    <div className="App">
      <MultiLineGraph
        labelPrefix={'$'}
        lineData={props.allGraphData && props.allGraphData.dailyAmounts}
        labels={props.allGraphData && props.allGraphData.labels}
        colors={props.allGraphData && props.allGraphData.colors}
      />
    </div>
  );
};

export default ComparativeGraph;
