import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {StateProps, DispatchProps, PasswordResetConfirmation} from './confirmation';
import {goBack} from '../../../actions/sign-in/password-reset-request'
import {State} from '../../../store';

export const mapStateToProps = (state: State): StateProps => {
  return {
    email: state.app.signIn.passwordResetEmail
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onBack: () => dispatch(goBack()),
  };
}

export const ConnectedPasswordResetConfirmation = connect(mapStateToProps, mapDispatchToProps)(PasswordResetConfirmation);
