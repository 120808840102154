import React, {
  useState,
  createRef,
  useEffect,
  Fragment,
} from 'react';
import Dropzone from 'react-dropzone';
import {
  Container,
  Icon,
  Image,
  ImageContainer,
  Instructions,
  Instruction,
  InstructionsContainer,
  Wrapper,
  Note,
  Zone
} from './styles';
import DoubleImageIcon from '../../../../assets/icons/double-image';
import PenIcon from '../../../../assets/icons/pen';

interface IDropzoneProps {
  onDrop?: any;
  multiple?: boolean;
  maxSize?: number;
  accept?: string;
  image?: string;
  onUpdateImage?: any;
}

const ImageDropzone: React.FC<IDropzoneProps> = (props: any) => {
  const [toolTipHeroOpen, setToolTipHeroOpen] = useState(false);
  const [image, setImage] = useState('');
  const [prevImage, setPrevImage] = useState('');
  const [isEditting, setIsEditting] = useState(false);
  const dropzoneRef: any = createRef();

  useEffect(() => {
    setImage(props.image);
    if (!isEditting) {
      setPrevImage(props.image);
    }

    if (props && props.onUpdateImage) {
      props.onUpdateImage(props.image);
    }
  }, [props.image]);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const hasPopulatedImage = image && image.length > 0;

  const activeEditMode = () => {
    setIsEditting(true);
  };

  const cancelEditMode = () => {
    if (isEditting) {
      setImage(prevImage);

      if (props && props.onUpdateImage) {
        props.onUpdateImage(prevImage);
      }
    }

    setIsEditting(false);
  };

  return (
    <Wrapper>
      <Dropzone
        onDrop={props.onDrop}
        multiple={false}
        maxSize={5242880}
        accept=".jpeg,.png,.jpg"
      >
        {( dropzone: any ) => (
          <Container>
            <div>
              <input {...dropzone.getInputProps()} />
              <Zone
                type="button"
                onClick={openDialog}
              >
                <Instructions overlay={isEditting} hide={hasPopulatedImage && !isEditting}>
                  <InstructionsContainer {...dropzone.getRootProps()}>
                    <DoubleImageIcon />
                    <Instruction primary>
                      Drag & Drop Image
                    </Instruction>
                    <Instruction>
                      or
                    </Instruction>
                    <Instruction secondary>
                      Select Image
                    </Instruction>
                  </InstructionsContainer>
                  { isEditting && (
                    <Instruction danger onClick={cancelEditMode}>
                      Cancel
                    </Instruction>
                  ) }
                </Instructions>
                { hasPopulatedImage && (
                  <ImageContainer>
                    <Image src={image}/>
                    { !isEditting && (
                      <Icon onClick={activeEditMode}>
                        <PenIcon/>
                      </Icon>
                    ) }
                  </ImageContainer>
                ) }
              </Zone>
            </div>
          </Container>
        )}
      </Dropzone>
      <Note>NOTE: If you do not upload an image, no image will be shown.</Note>
    </Wrapper>
  );
};

export default ImageDropzone;