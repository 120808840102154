import styled from 'styled-components';

export const Label = styled.span<any>`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  color: #FFFFFF;
  font-weight: bold;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.active ? '#333' : '#999')};
  border-radius: 50%;
  width: 16px;
  height: 16px;
`;
