import React, {useEffect, useReducer, FC} from 'react';
import { Portal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { colors } from '../constants/styles';
import { defaultPaperBorderRadius } from '../constants/theme';
import loadingSpinnerData from '../media/loading-spinner.json';

type LottieType = typeof import('lottie-web-react').default;
let Lottie: LottieType|undefined;

export type OwnProps = {};
export type StateProps = {
  active: boolean;
};
export type DispatchProps = {};
export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    width: '118px',
    height: '44px',

    // Center horizontally
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',

    transform: 'translateY(0)',
    transition: '1s',

    backgroundColor: colors.blue,
    borderRadius: defaultPaperBorderRadius,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    // -100% has the bottom edge at the bottom of the viewport
    // and we want it to be 35px from the bottom
    transform: 'translateY(calc(-100% - 35px))',
  },
  loopContainer: {
    width: '20px',
    height: '20px',
  },
  textContainer: {
    marginRight: '5px',
  },
  text: {
    fontFamily: 'Titillium Web, sans-serif',
    color: 'white',
    fontSize: '12px',
    lineHeight: '18px',
  }
}, {name: 'LoadingToast'});

export const LoadingToast: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    import('lottie-web-react').then((module) => {
      Lottie = module.default;
      // We need to force a re-render because we aren't changing any react state
      // that would cause a re-redner. Without this we won't see the loading
      // animation even after the module is loaded
      forceUpdate();
    }).catch();
  }, []);

  const loadingSpinner = Lottie ?
    <Lottie
      options={{
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingSpinnerData,
      }}
      playingState={'play'}
    /> : null;

  return (
    <Portal>
      <div className={clsx(classes.root, {[classes.active]: props.active})}>
        <div className={classes.textContainer}>
          <div className={classes.text}>Saving...</div>
        </div>
        {
          loadingSpinner &&
          <div className={classes.loopContainer}>
            {loadingSpinner}
          </div>
        }
      </div>
    </Portal>
  );
};
