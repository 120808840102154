import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import colors from '../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
    fontWeight: 'bold',
    borderRadius: 20,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    fontWeight: 'bold',
    borderRadius: 20,
  },
  info: {
    backgroundColor: colors.primaryColor,
    fontWeight: 'bold',
    borderRadius: 20,
  },
  warning: {
    backgroundColor: amber[700],
    fontWeight: 'bold',
    borderRadius: 20,
  },
  close: {
    padding: theme.spacing(0.5),
    borderRadius: 20,
  },
  snackbar: {
    backgroundColor: colors.primaryColor,
    fontWeight: 'bold',
    borderRadius: 20,
  },
}));

export default function SnackBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    if (props.message && props.message !== message) {
      setMessage(props.message);
      setOpen(true);
    }
  }, [message, props]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        className={(props.variant && classes[props.variant]) || classes.snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={4500}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: (props.variant && classes[props.variant]) || classes.snackbar,
          },
        }}
        message={<span id="message-id">{props.message}</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}
