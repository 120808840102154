import React, { useState, useEffect } from 'react';
import './Billing.scss';
import ReactGA from 'react-ga';
import Checkbox from '@material-ui/core/Checkbox';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Redirect } from 'react-router-dom';
import {
  Box, Paper,
} from '@material-ui/core';
import PageTitle from '../_shared/PageTitle/PageTitle';
import BillingVideoModal from './BillingVideoModal/BillingVideoModal';
import PlayVideoButton from '../../../assets/images/play-video-button.svg';
import bulletSquare from '../../../assets/images/bullet-square-icon.jpg';

import {
  STRIPE_PUBLIC_API_KEY,
} from '../../../constants';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import PageHeader from '../_shared/PageHeader/PageHeader';
import Paragraph from '../_shared/Paragraph/Paragraph';
import Subtitle from '../_shared/Subtitle/Subtitle';
import PreferencesDrawer from '../../Portal/Preferences/Drawer/PreferencesDrawer';
import CTAButton from '../_shared/CTAButton/CTAButton';
import { getMerchant } from '../../../services/merchant';
import { getBilling } from '../../../services/billing';


const Billing = (props) => {
  const [billing, setBilling] = useState({});
  const [skipClicked, setSkipClicked] = useState(false);
  const [billingVideoModalOpen, setBillingVideoModelOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawer, setDrawer] = useState('set-up-payment');

  const getBillingDetails = async () => {
    const { prod_api_secret } = getMerchant();
    if (!prod_api_secret) {
      return;
    }
    const billingResp = await getBilling(prod_api_secret);
    setBilling(billingResp && billingResp[0]);
  };

  useEffect(() => {
    getBillingDetails().catch((err) => console.error(err));
  }, []);

  const handleOpenBillingVideoModal = () => {
    setBillingVideoModelOpen(true);
  };

  const handleCloseBillingVideoModal = () => {
    setBillingVideoModelOpen(false);
  };

  const onBillingSuccess = () => {
    // Google analytics, track added billing method
    ReactGA.event({ category: 'Onboarding', action: 'Payment method set' });

    props.history.push('/onboarding/personalize-your-in-app-brand');
  };

  const toggleDrawer = () => {
    setDrawerOpen(true);
  };

  return (
    <div className="set-up-billing">
      <OnboardingContainer>
        <Box width="100%">
            <PageHeader>
              <PageTitle title="Set a Payment Method"></PageTitle>
            </PageHeader>
            <Paper className="column">
              <Paragraph text='Next up, we need to set a payment method to easily collect insurance premiums paid by you or your customers at checkout.'></Paragraph>

              <div className="set-up-billing-play-video-wrapper">
                <Subtitle title="How does collection of premiums work?"></Subtitle>
                <img src={PlayVideoButton} alt="Play Video Button icon" className="play-video" onClick={handleOpenBillingVideoModal}></img>
              </div>

              <Paragraph text='In order to proceed, please review the following points:'></Paragraph>

                <ul className="nested-bullet-list">
                    <li>
                      <img srcSet={bulletSquare} className="bullet" />
                      <span>Route Plus is completely, 100% free for your business (<i>unless you've chosen to pay for Route Plus package protection on behalf of your customers</i>)</span>
                    </li>
                    <li>
                      <img srcSet={bulletSquare} className="bullet" />
                      <span>When Route Plus package protection is purchased, you are collecting the insurance premium <i>on behalf of Route</i></span>
                    </li>
                    <li>
                      <img srcSet={bulletSquare} className="bullet" />
                      <span>You must connect a payment method in order to forward those premiums on to Route in a timely manner in order to be legally compliant</span>
                    </li>
                </ul>

              <div className="button-container">
                <CTAButton text="I Understand" href="#" onClick={toggleDrawer}></CTAButton>
                <CTAButton text="Skip (you can set this later)" href="/onboarding/personalize-your-in-app-brand" styleName="basic underline"></CTAButton>
              </div>
            </Paper>
          </Box>
      </OnboardingContainer>

      {billingVideoModalOpen ? (
        <BillingVideoModal
          onRequestClose={handleCloseBillingVideoModal}
          open={billingVideoModalOpen}
        />
      ) : (
        ''
      )}
      <PreferencesDrawer {...props} isOpen={drawerOpen} setOpen={setDrawerOpen} drawerName={drawer} setDrawerName={setDrawer} getBillingDetails={getBillingDetails} onBillingSuccess={onBillingSuccess} setSnackbarMessage={() => {}} />
    </div>
  );
};

export default Billing;
