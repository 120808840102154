/* eslint-disable default-case */
/* eslint-disable react/prop-types */
import React, { useState, createRef, useCallback, useEffect, Fragment } from 'react';
import ReactGA from 'react-ga';
import Dropzone from 'react-dropzone';
import TitleAndNav from './TitleAndNav';
import Button from '../../../Button/Button';
import { uploadStoreImages } from '../../../../services/api';
import Trash from '../../../../assets/icons/trash.svg';
import MobileDevice from './MobileDevice';
import { upsertMerchant } from '../../../../services/merchant';
import 'react-image-crop/dist/ReactCrop.css';
import './UpdateCompanyDetails.scss';
import ImageReq from './ImageReq';
import SaveState from './SaveState';
import { getBrand, updateBioText } from '../../../../services/merchant_brand';
import DrawerValidation from './DrawerValidation';
import CropAndResizeImage from './CropAndresizeImage';
import DimensionErrorBox from './DimensionErrorBox';

const UpdateCompanyDetails = (props) => {
  const [logoObj, setLogoObj] = useState({
    logo: props.userSession && props.userSession.activeBrand.store_logo ? props.userSession.activeBrand.store_logo : '',
    cropped: null,
    edited: false,
  });
  const [heroObj, setHeroObj] = useState({
    hero: props.userSession && props.userSession.activeBrand.store_hero ? props.userSession.activeBrand.store_hero : '',
    cropped: null,
    edited: false,
  });

  const [storeName, setStoreName] = useState('');
  const [storeDomain, setStoreDomain] = useState('');
  const [heroError, setHeroError] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [merchantDomainError, setMerchantDomainError] = useState(false);
  const [toolTipLogoOpen, setToolTipLogoOpen] = useState(false);
  const [toolTipHeroOpen, setToolTipHeroOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [cropState, setCropState] = useState(false);
  // const [dimensionError, setDimensionError] = useState(false);
  const [merchantBrand, setMerchantBrand] = useState({
    bio: '',
  });
  const [saveStateActive, setSaveStateActive] = useState({
    active: false,
    location: '',
  });
  const [isEdited, setIsEdited] = useState(false);
  const MAX_BIO_CHARACTERS = 300;

  useEffect(() => {
    const fetchData = async () => {
      if (props.userSession.activeBrand) {
        const { activeBrand: merchant } = props.userSession;
        setStoreName(merchant.store_name);
        setStoreDomain(merchant.store_domain);
        setMerchantBrand(await getBrand(merchant.id, merchant.prod_api_secret));
      }
    };
    fetchData();
  }, []);

  const handleIsEdited = () => {
    setIsEdited(true);
    props.setKeepDrawerOpen(true);
  };

  const handleUpdateMerchant = (key, value) => {
    switch (key) {
      case 'storeName':
        setStoreName(value);
        if (value !== props.userSession.activeBrand.store_name) {
          handleIsEdited();
        }
        break;
      case 'storeDomain':
        setStoreDomain(value);
        if (value !== props.userSession.activeBrand.store_domain) {
          handleIsEdited();
        }
        break;
      case 'hero':
        // this method is only used to delete an image
        setHeroObj({ hero: '', edited: true });
        if (props.userSession.activeBrand.store_hero) {
          handleIsEdited();
        }
        break;
      case 'logo':
        // this method is only used to delete an image
        setLogoObj({ logo: '', edited: true });
        if (props.userSession.activeBrand.store_logo) {
          handleIsEdited();
        }
        break;
    }
  };

  const handleBioMerchant = (bio) => {
    if (bio.length <= MAX_BIO_CHARACTERS) {
      setIsEdited(true);
      setMerchantBrand({
        ...merchantBrand,
        bio,
      });
    }
  };

  const textCounter = (value, maxLimit = 300) => (!value ? maxLimit : maxLimit - value.length);

  const saveMerchant = async () => {
    setIsLoading(true);
    let resp;

    if (props && props.userSession && props.userSession.activeBrand) {
      const changes = [];
      if (props.userSession.activeBrand.store_name !== storeName) {
        changes.push('storeDomain');
      }
      if (props.userSession.activeBrand.store_domain !== storeDomain) {
        changes.push('storeName');
      }
      if (props.userSession.activeBrand.store_hero !== heroObj.hero) {
        changes.push('hero');
      }
      if (props.userSession.activeBrand.store_logo !== logoObj.logo) {
        changes.push('logo');
      }

      if (changes.length) {
        ReactGA.event({ category: 'Admin - Preferences', action: 'Merchant Profile change', label: changes.join('|') });
      }
    }

    if (
      !props ||
      !props.userSession ||
      !props.userSession.activeBrand ||
      !props.userSession.activeBrand.prod_api_secret
    ) {
      return;
    }

    if (storeDomain) {
      try {
        resp = await upsertMerchant(
          'store_domain',
          storeDomain,
          props.userSession.activeBrand.id,
          props.userSession.activeBrand.prod_api_secret,
        );
        props.updateActiveBrand(resp.data);
      } catch (err) {
        setMerchantDomainError(true);
        setIsLoading(false);
        props.setKeepDrawerOpen(true);
        return;
      }
    }

    try {
      await uploadStoreImages(
        props.userSession.activeBrand.prod_api_secret,
        logoObj.edited && logoObj.logo,
        heroObj.edited && heroObj.hero,
        props.userSession.activeBrand.id,
      );
    } catch (e) {
      setHeroError(true);
    }
    props.setKeepDrawerOpen(false);

    if (storeName) {
      resp = await upsertMerchant(
        'store_name',
        storeName,
        props.userSession.activeBrand.id,
        props.userSession.activeBrand.prod_api_secret,
      );
      props.updateActiveBrand(resp.data);
    }

    if (merchantBrand) {
      await updateBioText(
        merchantBrand.bio,
        props.userSession.activeBrand.id,
        props.userSession.activeBrand.prod_api_secret,
      );
    }

    if (!logoObj.logo && props.userSession && props.userSession.activeBrand.store_logo) {
      resp = await upsertMerchant(
        'store_logo',
        '',
        props.userSession.activeBrand.id,
        props.userSession.activeBrand.prod_api_secret,
      );
      props.updateActiveBrand(resp.data);
    }

    if (!heroObj.hero && props.userSession && props.userSession.activeBrand.store_hero) {
      resp = await upsertMerchant(
        'store_hero',
        '',
        props.userSession.activeBrand.id,
        props.userSession.activeBrand.prod_api_secret,
      );
      props.updateActiveBrand(resp.data);
    }

    if (props.onUpdateDetails) {
      props.onUpdateDetails();
    } else {
      setSaveStateActive({ active: true, location: 'saving' });
    }
  };

  const onDropHero = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    if (reader.readyState === 0 && acceptedFiles.length === 0) {
      setHeroError(true);
      return;
    }
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      // const image = new Image();
      // image.addEventListener('load', () => {
      //   if (image.width >= 1080 && image.height >= 400) {
      //     acceptedFiles.push(image);
      //     setDimensionError(false);
      //     setHeroObj({ hero: binaryStr, edited: true });
      //     setCropState(true);
      //   } else {
      //     setDimensionError(true);
      //   }
      // });
      // image.src = URL.createObjectURL(acceptedFiles[0]);

      setHeroObj({ hero: binaryStr, edited: true });
    };

    acceptedFiles.forEach((file) => {
      reader.readAsDataURL(file);
    });
  }, []);

  const onDropLogo = useCallback((acceptedFiles) => {
    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      setLogoObj({ logo: binaryStr, edited: true });
    };

    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  const getDropzone = (saveStateFunction) => (
    <Dropzone multiple={false} maxSize={10485760} accept=".jpeg,.png,.jpg" onDrop={saveStateFunction}>
      {({ getRootProps, getInputProps, acceptedFiles }) => (
        <div>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <button className="update-company-details-button" type="button" onClick={openDialog}>
              <p className="update-company-details-instructions">
                <span>Add Image</span> or drop files here
              </p>
            </button>
          </div>
        </div>
      )}
    </Dropzone>
  );

  const deleteImg = (type) => (
    <div className={`delete-img-bar-${type}`} onClick={() => handleUpdateMerchant(type)}>
      <img src={Trash} alt="trash-can-icon" />
      <span>Delete pic</span>
    </div>
  );

  const coverImageContent = !heroError ? (
    <Fragment>
      {getDropzone(onDropHero)}
      <span className="update-company-details-recommended-size">
        Recommended 1080 x 400
        <span onClick={() => setToolTipHeroOpen(!toolTipHeroOpen)} className="image-req-tool-tip">
          {' '}
          (IMAGE REQs)
          {toolTipHeroOpen === false ? (
            <Fragment />
          ) : (
            <ImageReq close={() => setToolTipHeroOpen(false)} errorState={false} top={'26px'} />
          )}
        </span>
      </span>

      {/* {dimensionError == true ? (
        <DimensionErrorBox setDimensionError={setDimensionError} setCropState={setCropState} />
      ) : ( */}
      <div>
        {heroObj.hero ? (
          // new img placeholder
          // <div className="blank-image-placeholder-hero">
          //   <div className="blank-image-placeholder-hero-inside-box"></div>
          //   <img src={heroObj.hero} height="140px" width="300px" />
          // </div>

          // tmp img placeholder
          <div>
            <img src={heroObj.hero} className="store-hero" />
            {deleteImg('hero')}
          </div>
        ) : (
          <div className="blank-image-placeholder-hero">
            <div className="blank-image-placeholder-hero-inside-box"></div>
            <span>
              COVER IMAGE <br />
              PREVIEW
            </span>
          </div>
        )}
        {/* TODO: <div className="cover-image-preview-link" onClick={onReCropImage}>Re-Crop Image</div> */}
      </div>
      {/* )} */}
    </Fragment>
  ) : (
    <div style={{ position: 'relative' }}>
      <ImageReq close={() => setHeroError(false)} errorState={true} />
    </div>
  );

  const logoImageContent = !logoError ? (
    <Fragment>
      {getDropzone(onDropLogo)}

      <span className="update-company-details-recommended-size">
        Recommended 512 x 512
        <span onClick={() => setToolTipLogoOpen(!toolTipLogoOpen)} className="image-req-tool-tip">
          {' '}
          (IMAGE REQs)
        </span>
        {toolTipLogoOpen === false ? (
          <Fragment />
        ) : (
          <ImageReq close={() => setToolTipLogoOpen(false)} errorState={false} />
        )}
        <div className="merchant-logo-container">
          {logoObj.logo ? (
            <div>
              <img src={logoObj.logo} className="store-logo" />
              {deleteImg('logo')}
            </div>
          ) : (
            <div className="blank-image-placeholder-logo">
              <div className="blank-image-placeholder-logo-line-right"> </div>
              <div className="blank-image-placeholder-logo-line-left"></div>
              <span>Logo</span>
            </div>
          )}
        </div>
      </span>
    </Fragment>
  ) : (
    <ImageReq close={() => setLogoError()} errorState={true} />
  );

  return (
    <div className="update-company-details-parent-container">
      <div className="update-company-details-header">
        <TitleAndNav
          title="In-App Merchant Profile"
          close={props.closeDrawer}
          isEdited={isEdited}
          location={saveStateActive.location}
          updateSaveState={setSaveStateActive}
          forceClose={saveStateActive.active}
        />
      </div>
      {saveStateActive.active ? (
        <div className="update-company-details-save-state-container">
          <SaveState
            closeDrawer={props.closeDrawer}
            setKeepDrawerOpen={props.setKeepDrawerOpen}
            location={saveStateActive.location}
            onPrimaryAction={saveStateActive.location === 'saving' ? props.closeDrawer : setSaveStateActive}
            onSecondaryAction={saveStateActive.location === 'saving' ? setSaveStateActive : props.closeDrawer}
          />
        </div>
      ) : null}
      {/* {!cropState ? ( */}
      <div className="update-company-details">
        <div className="update-company-details-mobile-preview">
          <MobileDevice website={storeDomain} companyName={storeName} coverImage={heroObj.hero} logo={logoObj.logo} />
        </div>
        <div className="update-company-details-container">
          <header>General Info</header>
          <div className="routing-row">
            <div className="field">
              <div className="update-company-details-section-title-small">Store Name</div>
              <input
                className="routing-input"
                id="storeName"
                placeholder="Your store name"
                name="storeName"
                value={storeName}
                onChange={(e) => handleUpdateMerchant('storeName', e.target.value)}
              />
            </div>
          </div>
          <div className="routing-row">
            <div className="field">
              <div className="update-company-details-section-title-small">Website</div>
              <input
                className="routing-input"
                id="website"
                placeholder="Your store domain"
                name="website"
                value={storeDomain}
                onChange={(e) => handleUpdateMerchant('storeDomain', e.target.value)}
              />

              {merchantDomainError ? (
                <DrawerValidation
                  isDomainAlreadyInUse={merchantDomainError}
                  closeDomainInUseError={() => {
                    setMerchantDomainError(false);
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="routing-row">
            <div className="field">
              <div className="update-company-details-section-title-small">Bio</div>
              <span className="routing-message">
                {MAX_BIO_CHARACTERS} Max characters ({textCounter(merchantBrand.bio, MAX_BIO_CHARACTERS)} remaining)
              </span>
              <textarea
                className="routing-text-area"
                value={merchantBrand.bio}
                onChange={(e) => handleBioMerchant(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div id="line-break"></div>
          <div></div>
          <div className="update-company-details-section-title">Cover Image</div>
          {coverImageContent}
          <div className="update-company-details-section-title">Logo</div>
          {logoImageContent}
        </div>
      </div>
      {/* ) : (
        <CropAndResizeImage
          image={heroObj}
          setCropState={setCropState}
          cropState={cropState}
          setCompletedCrop={setHeroObj}
        />
      )} */}
      {/* {!cropState ? ( */}
      <div className="update-company-details-save-container">
        <div className="update-company-details-save-button">
          <Button type="submit" onClick={saveMerchant} isLoading={isLoading} loadingText={'Saving'} text="Save" />
        </div>
      </div>
      {/* ) : (
        ''
      )} */}
    </div>
  );
};

export default UpdateCompanyDetails;
