import React, { FC, useState, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import * as _ from 'lodash';
import Card from '../../../components/Card';
import CardLabel from '../../../components/CardLabel';
import SmallNavBar from '../../../components/SmallNavBar/SmallNavBar';
import MetricList from '../../../components/MetricList';
import MetricItem from '../../../components/MetricItem';
import ComparativeGraph from '../Graphs/ComparativeGraph';
import TopZipCodesMap from '../Graphs/TopZipCodesMap';
import ErrorBox from '../../../components/ErrorBox';
import ErrorBoxIcon from '../../../../../assets/images/error-box-icon.svg';
import { ErrorBoxOuterContainer } from './ZipCodesByOrdersCard.style';

const ZipCodesByOrdersCard: FC<any> = ({ shipmentCountsByZipAndDay }) => {
  const [selected, setSelected] = useState('Map');
  const [zipList, setZipList] = useState<any[]>(
    Array(10).fill({
      ZIP: 'Zip Code',
      TOTAL: 0,
      COLOR: '',
      COLOR_RGBA: '',
    }),
  );
  const [revenueErrorBox, setRevenueErrorBox] = useState(false);

  const colorset: any = [
    '#5DC8DB',
    '#14869F',
    '#521DC3',
    '#8121CC',
    '#B52082',
    '#ED1C80',
    '#14E2E2',
    '#0CCECE',
    '#EEE61E',
    '#728E9D',
  ];

  const colorsetRGBA: any = [
    'rgba(93, 200, 219, 0.2)',
    'rgba(20, 134, 159, 0.2)',
    'rgba(82, 29, 195, 0.2)',
    'rgba(129, 33, 204, 0.2)',
    'rgba(181, 32, 130, 0.2)',
    'rgba(237, 28, 128, 0.2)',
    'rgba(20, 226 ,226 ,0.2)',
    'rgba(12, 206, 206, 0.2)',
    'rgba(238, 230, 30, 0.2)',
    'rgba(114, 142, 157, 0.2)',
  ];

  const [graphData, setGraphData] = useState({
    dailyAmounts: {},
    colors: {},
  });

  const [initialGraphData, setInitialGraphData] = useState({
    dailyAmounts: {},
    colors: {},
  });

  const [pickedUp, setPickedUp] = useState<any>({});

  useEffect(() => {
    if (shipmentCountsByZipAndDay?.data) {
      const { data, isLoading } = shipmentCountsByZipAndDay;
      if (!isLoading && data) {
        const result: { ZIP: string; TOTAL: number; METRICS: string; COLOR: string; COLOR_RGBA: string }[] = [];
        const aggregateTotalShipment = aggregateTotalShipments(data);

        Object.keys(data).forEach((item, index) => {
          let totalShipments = 0;
          if (item !== 'labels' && item !== '') {
            data[item].map((i: any) => {
              totalShipments += i;
            });
            result.push({
              ZIP: item,
              TOTAL: totalShipments,
              METRICS: ((totalShipments / aggregateTotalShipment) * 100).toFixed(2),
              COLOR: colorset[index],
              COLOR_RGBA: colorsetRGBA[index],
            });
          }
        });
        if (result?.length > 0) {
          result.sort((a, b) => b.TOTAL - a.TOTAL);
          setZipList(result);
          setRevenueErrorBox(false);
        } else {
          setRevenueErrorBox(true);
        }
      }
      setInitialGraph();
    }
  }, [shipmentCountsByZipAndDay]);

  useEffect(() => {
    const allZipList: any = zipList;
    const allZipChecked: any = {};
    allZipList.forEach((item: any, index: any) => {
      allZipChecked[item.ZIP] = false;
    });
    setPickedUp({ ...allZipChecked });
  }, [zipList]);

  useEffect(() => {
    const newGraphData: any = graphData;

    if (newGraphData) {
      const zipcodes = Object.keys(newGraphData.dailyAmounts);
      for (let x = 0; x < zipcodes.length; x++) {
        if (!pickedUp[zipcodes[x]]) {
          delete newGraphData.dailyAmounts[zipcodes[x]];
          newGraphData.colors[zipcodes[x]] = '#CCCCCC';
        }
      }

      setGraphData({
        ...newGraphData,
      });
    }
  }, [pickedUp]);

  const aggregateTotalShipments = (data: any) => {
    let total = 0;
    const zipcodes = Object.keys(data);

    for (let x = 0; x < zipcodes.length; x++) {
      const zipcode = zipcodes[x];

      if (zipcode === 'labels') {
        continue;
      }
      for (let y = 0; y < data[zipcode].length; y++) {
        total += data[zipcode][y];
      }
    }

    return total;
  };

  const onZipChange = (i: any, index: any) => {
    const zipcode = i.ZIP;
    const newPickedUp = pickedUp;
    newPickedUp[zipcode] = !pickedUp[zipcode];
    setGraph(i.ZIP, i.COLOR);
    setPickedUp({
      ...newPickedUp,
    });
  };

  const setInitialGraph = () => {
    const { data } = shipmentCountsByZipAndDay;
    const allDailyAmounts: any = {};
    const allColors: any = initialGraphData.colors;
    const labels: any[] = [];

    Object.keys(data).forEach((item, index) => {
      if (item !== 'labels') {
        allDailyAmounts[item] = data[item];
      }
      if (item === 'labels') {
        labels.push(data[item]);
      }
      allColors[item] = '#CCCCCC';
    });

    const newInitialGraphData = {
      ...initialGraphData,
      dailyAmounts: allDailyAmounts,
      labels: labels[0],
      colors: allColors,
    };

    setInitialGraphData(newInitialGraphData);
  };

  const setGraph = (zip: any, color: string) => {
    const { data } = shipmentCountsByZipAndDay;
    const selectedDailyAmounts: any = graphData.dailyAmounts;
    const selectedColors: any = initialGraphData.colors;

    const labels: any[] = [];
    if (pickedUp[zip] === true) {
      selectedDailyAmounts[zip] = data[zip];
      selectedColors[zip] = color;
    }

    Object.keys(data).forEach((item, index) => {
      if (item === 'labels') {
        labels.push(data[item]);
      }
    });

    const newGraphData = {
      ...graphData,
      dailyAmounts: selectedDailyAmounts,
      labels: labels[0],
      colors: selectedColors,
    };

    setGraphData(newGraphData);
  };

  useEffect(() => {
    if (zipList && !shipmentCountsByZipAndDay.isLoading) {
      zipList.map((i, index) => {
        // starts with all zips checked
        onZipChange(i, index);
      });
    }
  }, [zipList]);

  const getCountByZip = () =>
    zipList.map((i, index) => (
      <MetricItem
        key={index}
        text={`${index + 1} - ${i.ZIP} (${i.METRICS || '00'}%)`}
        value={`$${
          (i.TOTAL || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || '0'
        }`}
        color={pickedUp[i.ZIP] ? i.COLOR : '#CCCCCC'}
        hasCheck
        isChecked={!!pickedUp[i.ZIP]}
        handleCheck={() => onZipChange(i, index)}
        isLoading={shipmentCountsByZipAndDay.isLoading}
      />
    ));

  return (
    <Card isLoading={shipmentCountsByZipAndDay.isLoading}>
      {revenueErrorBox ? (
        <ErrorBoxOuterContainer>
          <ErrorBox
            bgColor="#333333"
            label="Beep, boop, we need data..."
            description="Looks like we don’t have any data to show yet. Please check back in couple of hours to view these metrics. "
            icon={ErrorBoxIcon}
            close={() => setRevenueErrorBox(false)}
            marginTop="27%"
          />
        </ErrorBoxOuterContainer>
      ) : (
        ''
      )}
      <Grid container spacing={0}>
        <Grid item lg={3} md={3} xs={12}>
          <Box mb={4}>
            <CardLabel>Top Zips by Revenue</CardLabel>
            <Box mt={4} style={{ opacity: revenueErrorBox ? '0.24' : '' }}>
              <MetricList>{getCountByZip()}</MetricList>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={9} md={9} xs={12} style={{ opacity: revenueErrorBox ? '0.24' : '' }}>
          <Box
            mb={4}
            mt={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SmallNavBar items={['Map', 'Comparative Graph']} selected={selected} setSelected={setSelected} />
          </Box>
          <Box mt={4} height="100%">
            {selected === 'Map' && zipList && (
              <TopZipCodesMap
                mapData={zipList}
                filteredData={graphData}
                isLoading={shipmentCountsByZipAndDay.isLoading}
              />
            )}
            {selected === 'Comparative Graph' && (
              <ComparativeGraph isGraph={true} allGraphData={initialGraphData} colors={colorset} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ZipCodesByOrdersCard;
