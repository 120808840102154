import styled, {css} from 'styled-components';
import Colors from '../../../../../../constants/colors';

export const Wrapper: any = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99001;
  user-select: none;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: 900;
    background: ${Colors.secondaryBackground};
    opacity: 0.9;
    position: fixed;
  }

  ${(props: any) => props.disabled && css`
    display: none;
  `}
`;

export const Container:any = styled.div`
  display: flex;
  position: relative;
  width: 573px;
  justify-content: center;
  align-items: center;
  z-index: 99002;
  margin: 38px auto 0 auto;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  width: 330px;
  svg {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
`;

export const Title = styled.h6`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${Colors.secondaryText};
  text-transform: uppercase;
`;

export const ArrowIcon = styled.div`
  cursor: pointer;
  margin: 0 15px;
  transition: .5s all;

  &:hover {
    circle {
      transition: .5s all;
      fill: ${Colors.secondaryColor}
    }
  }
`;

export const CloseIconContainer = styled.div``;

export const Middle = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div``;

export const Thumbnails = styled.div`
  display: flex;
  margin: 30px 0;
  width: 370px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Thumbnail = styled.img<any>`
  height: 60px;
  width: 60px;
  cursor: pointer;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 3px solid transparent;
  transition: all .5s;
  ${(props: any) => props.bordered && css`
    border-color: ${Colors.primaryColor};
  `}

  &:hover {
    border-color: ${Colors.primaryColor};
  }
`;
