import React, {FC} from 'react';
import {Grid, Box} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {Button, Container, ContainerContent} from '../../components';
import {Logo} from '../../media/logo';
import {LineDivider} from '../../media/line-divider';
import customerRoute from '../../media/customer-track-order.png';
import merchantRoute from '../../media/merchant-track-order.png';
import customerRoutePlus from '../../media/customer-route-plus.png';
import merchantRoutePlus from '../../media/merchant-route-plus.png';

import styleMap from '../../constants/styles';

export type OwnProps = {
  routePlusEnabled: boolean;
};

export type StateProps = {
  goToMerchantPortal: () => void;
};

export type Props = OwnProps & StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    marginTop: '54px',
  },
  title: {
    marginTop: '57px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '33px',
    textAlign: 'center',
  },
  trackingOrderCustomer: {
    marginTop: '37px',
  },
  trackingOrderMerchant: {
    marginTop: '65px',
  },
  lineDivider: {
    marginTop: '65px',
  },
  description: {
    marginTop: '56px',
  },
  descriptionText: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  button: {
    marginTop: '25px',
    marginBottom: '75px',
    width: '202px',
  },
  leftImage: {
    width: '296px',
    marginLeft: '30px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
    marginTop: (props: {routePlusEnabled: boolean}) => props.routePlusEnabled ? '30px' : '0px',
  },
  rightImage: {
    width: '301px',
    marginRight: '30px',
    imageRendering: '-webkit-optimize-contrast',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
    }
  },
  link: {
    color: styleMap.linkColor,
    textDecoration: 'underline',
    '&:hover': {
      color: styleMap.linkHoverColor,
    }
  }
}), {name: 'RouteCongrats'});

export const RouteCongrats: FC<Props> = (props) => {
  const {routePlusEnabled} = props;
  const classes = useStyles({routePlusEnabled});

  return (
    <Container>
      <ContainerContent width={800}>
        <Box>
          <Grid container alignItems={'center'} justify="flex-start">
            <Grid className={classes.logo} item xs={12}>
              <Logo width={59.12} height={43.48}/>
            </Grid>
            <Grid className={classes.title} item xs={12} >
              <span>Congratulations! You’re all done<span role="img" aria-label="clap">👏</span></span>
            </Grid>
            <Grid className={classes.trackingOrderCustomer} item xs={12} sm={6}>
                <img data-testid="customer-img" className={classes.leftImage} alt="Track Order - Customers" src={routePlusEnabled ? customerRoutePlus : customerRoute}/>
            </Grid>
            <Grid className={classes.trackingOrderMerchant} item xs={12} sm={6}>
                <img data-testid="merchant-img" className={classes.rightImage} alt="Track Order - Merchant" src={routePlusEnabled ? merchantRoutePlus : merchantRoute}/>
            </Grid>
            <Grid className={classes.lineDivider} item xs={12}>
                <LineDivider/>
            </Grid>
            <Grid className={classes.description} item xs={12}>
              <div data-testid="visible-text" className={classes.descriptionText}>
                {
                  routePlusEnabled ?
                  'You may adjust your settings, view order info, view claims info, and view metrics in the Route Portal.' :
                  'You may adjust settings and view tracking info in the Route Portal.'
                }
              </div>
            </Grid>
            <Grid item xs={12}>
                <Button className={classes.button} onClick={props.goToMerchantPortal}>Go to the Route Portal</Button>
            </Grid>
          </Grid>
        </Box>
      </ContainerContent>
    </Container>
  );
}
