import React from 'react';
import Congratulation from '../../../../assets/icons/congratulation.svg';
import Button from "../../../Button/Button";
import TitleAndNav from './TitleAndNav';



const ThankYouPageSavedSettings = (props) => {
  const closeThankYouPage = () => {
    if (props.activeSessionData) {
      const resp = Object.assign(props.activeSessionData);
      props.updateActiveBrand(resp);
    }
    props.closeDrawer();

  }

  return(
    <div
      className="saved-setting"
    >
      <div className="update-company-details-header">
        <TitleAndNav
          title="Thank You Page Tracking Link"
          close={props.closeDrawer}
        />

      <img
        src={Congratulation}
        alt="Congratulation"
        className="saved-setting-logo"
      />
      <div
        className="saved-setting-description"
      >Your customers will {props.trackingLinkOn ? 'now' : 'not'} see the<br />
        Tracking Link on the Thank You page.
      </div>
      <div>
        <Button
          hoverState ='true'
          text="Close"
          onClick={closeThankYouPage}
          className="saved-setting-button"
        />
      </div>
    </div>
    </div>
  )
};
export default ThankYouPageSavedSettings;
