import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../route-order-tracking-react-components/src/utils/responsive-breakpoints'
import { colors } from './../../constants/styles'

export const Container = styled.div`
  background: transparent;
  border: 1px dashed #CCCCCC;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 5px;
  width: 250px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: ${Breakpoints.xsMax}px) {
    width: 240px;
    height: 150px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  z-index: 999;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 250px;
  height: 80px;
  border-radius: 12px;

  @media (min-width: ${Breakpoints.xsMax}px) {
    width: 240px;
    height: 150px;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
`;

export const InstructionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

export const Instructions = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;

  ${(props: any) => props.overlay && css`
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      z-index: 11;
      background: #F7F7F7;
      opacity: 0.85;
      border-radius: 7.22321px;
      width: 240px;
      height: 240px;
      z-index: -1;
      background: #F7F7F7;
      border: 1px dashed #CCCCCC;
      box-sizing: border-box;
      border-radius: 24px;
      left: -1px;
      top: -1px;
    }
  `}

  ${(props: any) => props.hide && css`
    svg {
      display: none;
    }

    div {
      color: transparent !important;
    }
  `}
`;

export const Instruction: any = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-family: Titillium Web;
  color: #333333;

  ${(props: any) => props.primary && css`
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #333333;
    text-decoration: none;
    margin: 5px 0;
  `}

  ${(props: any) => props.secondary && css`
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: #333333;
    background-color: #F7F7F7;
    padding: 2px 5px;
    margin: 5px 0;
    border-radius: 16px;
  `}

  ${(props: any) => props.danger && css`
  text-align: center;
  text-decoration-line: underline;
  color: #F06350;
  position: absolute;
    top: 165px;
  `}
`;

export const Note = styled.p`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
`;

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex: 1;
`;

export const Zone = styled.button`
  background: transparent;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  cursor: auto;
`;

export const Title = styled.h3`
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-align: left;
  margin-top: 8px;

  @media (min-width: ${Breakpoints.xsMax}px) {
    font-size: 12px;
  }
`;

export const Recommendation = styled.span`
  font-size: 10px;
  line-height: 12px;
  font-weight: normal;
  text-align: left;
`;

export const Link = styled.button`
  text-decoration: underline;
  border: unset;
  background: transparent;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
`;

export const TextArea = styled.div`
  display: grid;
  width: 100%;
`;

export const ShowDragDropIcon = styled.div`
  display: none;
  
  @media (min-width: ${Breakpoints.xsMax}px) {
    display: block;
  }
`;

export const ContentPopoverImages = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 10px;
`;

export const ContentPopoverh3 = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.white};
`;

export const ContentPopoverSpan = styled.span`
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${colors.gray};
`;

export const ContentPopoverText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  & ${ContentPopoverh3} {
    margin-top: 10px;
  }
  & ${ContentPopoverSpan} {
    margin-bottom: 10px;
  }
`;
