export function formatClaimStatus(status: string): string {
  const formattedStatus: Record<string, string> = {
    'in-progress': 'In Review',
    needs_resolution: 'In Review',
    new: 'Open',
    _not_covered: 'Status - Not Covered',
    not_covered: 'Not covered',
    not_serviced: 'Not serviced',
    _open: 'Status - Open',
    paid: 'Paid',
    'pending-reorder': 'Pending Reorder',
    _pending_review: 'Status - Pending Review',
    resolved__order_arrived: 'Resolved - Order Arrived',
    resolved__order_pick_up: 'Resolved - Order Pick Up',
    resolved__claim_abandoned: 'Resolved - Claim Abandoned',
    resolved__retailer_resolved: 'Resolved - Retailer Resolved',
    resolved__unfulfilled: 'Resolved - Unfulfilled',
    'status_-_not_covered': 'Not serviced',
    needs_assessment: 'In Review',
    awaiting_response: 'Awaiting Response',
    resolved__approved: 'Resolved - Approved',
    reordered: 'Resolved - Replacement',
    refunded: 'Resolved - Refund',
    resolved: 'Resolved - Approved',
    resolved__not_approved: 'Resolved - Not Approved',
    'referred-to-merchant': 'Resolved - Not Approved',
  };

  return formattedStatus[status] ? formattedStatus[status] : status;
}

export function formatClaimString(status: string|undefined): string|undefined {
  if (!status) {
    return;
  }
  let str = status.toLowerCase().replace('_', ' ');
  str = str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

  return str;
}

export function formatClaimType(str: string|undefined): string|undefined {
  if (!str) {
    return;
  }

  str.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

  return str;
}

export function isClaimResolved(status: string): boolean {
  return status !== 'in-progress' && status !== 'pending_reorder';
}
