import {replace} from 'connected-react-router';
import {Dispatch} from 'redux';

import {
  resetPassword as apiResetPassword,
  resendPasswordResetLink as apiResendPasswordResetLink,
} from '../../../api';
import { State } from '../../../store';

export const PASSWORD_RESET_STARTED = 'PASSWORD_RESET_STARTED' as const;
export interface PasswordResetStartedAction {
  type: typeof PASSWORD_RESET_STARTED;
};

export function passwordResetStarted(): PasswordResetStartedAction {
  return {
    type: PASSWORD_RESET_STARTED,
  };
}

export const PASSWORD_RESET_SUCCEEDED = 'PASSWORD_RESET_SUCCEEDED' as const;
export interface PasswordResetSucceededAction {
  type: typeof PASSWORD_RESET_SUCCEEDED;
};

export function passwordResetSucceeded(): PasswordResetSucceededAction {
  return {
    type: PASSWORD_RESET_SUCCEEDED,
  };
}

export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED' as const;
export interface PasswordResetFailedAction {
  type: typeof PASSWORD_RESET_FAILED;
};

export function passwordResetFailed(): PasswordResetFailedAction {
  return {
    type: PASSWORD_RESET_FAILED,
  };
}

export type PasswordResetActions =
  PasswordResetStartedAction |
  PasswordResetSucceededAction | 
  PasswordResetFailedAction;

export type ResetData = {
  guid?: string;
  password: string;
}

export const resetPassword = (data: ResetData) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    try {
      dispatch(passwordResetStarted());

      if (!data.guid) {
        const possibleGuid = getState().app.accountCreation.passwordGuid;
        if (!possibleGuid) {
          return dispatch(passwordResetFailed());
        }
        data.guid = possibleGuid;
      }
      await apiResetPassword(data as {password: string; guid: string});

      dispatch(passwordResetSucceeded());
      dispatch(replace('/onboarding/sign-in/password-reset-success'));
    } catch(err) {
      dispatch(passwordResetFailed());
    }
  }
}

export const goBackToSignIn = () => {
  return (dispatch: Dispatch) => {
    dispatch(replace('/onboarding/sign-in'));
  }
}

// ************************************************************* //

export const RESEND_PASSWORD_RESET_STARTED = 'RESEND_PASSWORD_RESET_STARTED' as const;
export interface ResendPasswordResetStartedAction {
  type: typeof RESEND_PASSWORD_RESET_STARTED;
};

export function resendPasswordResetStarted(): ResendPasswordResetStartedAction {
  return {
    type: RESEND_PASSWORD_RESET_STARTED,
  };
}

export const RESEND_PASSWORD_RESET_SUCCEEDED = 'RESEND_PASSWORD_RESET_SUCCEEDED' as const;
export interface ResendPasswordResetSucceededAction {
  type: typeof RESEND_PASSWORD_RESET_SUCCEEDED;
};

export function resendPasswordResetSucceeded(): ResendPasswordResetSucceededAction {
  return {
    type: RESEND_PASSWORD_RESET_SUCCEEDED,
  };
}

export const RESEND_PASSWORD_RESET_FAILED = 'RESEND_PASSWORD_RESET_FAILED' as const;
export interface ResendPasswordResetFailedAction {
  type: typeof RESEND_PASSWORD_RESET_FAILED;
};

export function resendPasswordResetFailed(): ResendPasswordResetFailedAction {
  return {
    type: RESEND_PASSWORD_RESET_FAILED,
  };
}

export const resendPasswordResetLink = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(resendPasswordResetStarted());
      const loginSucceeded = await apiResendPasswordResetLink();

      // TODO: make sure the email used for resetting makes its way to the state store
      // via an action dispatched in this function
      if (loginSucceeded) {
        dispatch(resendPasswordResetSucceeded());
        dispatch(replace('/onboarding/sign-in/password-reset-confirmation'));
      } else {
        dispatch(resendPasswordResetFailed());
      }
    } catch(err) {
      dispatch(resendPasswordResetFailed());
    }
  }
}
