import React, {FC} from 'react';
import {Grid, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {Button, Container, ContainerContent} from '../../components'

export type OwnProps = {};
export type StateProps = {};
export type DispatchProps = {
    onGoToSignIn: () => void;
}
export type Props = OwnProps & StateProps & DispatchProps;

//#region Styles
const spacing = 0;
const themePxPerSpace = 8;
function getMargin(margin: number): string {
    return `${margin - (spacing * themePxPerSpace)}px`;
}

const useStyles = makeStyles({
    title: {
        marginTop: getMargin(75),
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '33px',
        textAlign: 'left',
    },

    textOne: {
        marginTop: getMargin(45),
        fontSize: '13px',
        lineHeight: '20px',
        textAlign: 'left',
        color: '#5C676C',
    },
    textTwo: {
        marginTop: getMargin(10),
        marginRight: getMargin(134),
        fontSize: '16px',
        lineHeight: '23px',
        fontWeight: 600,
    },
    button: {
        marginTop: getMargin(42),
        marginBottom: getMargin(55),
        width: '140px',
    },

});
//#endregion

export const ForgotEmail: FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <Container>
            <ContainerContent width={390}>
                <Box mx={9}>
                    <Grid container spacing={0} direction={'column'} alignItems={'center'} justify="flex-start">
                        <Grid className={classes.title} item xs={12} >
                            <span>Forgot Sign-in Email?</span>
                        </Grid>
                        <Grid className={classes.textOne} item xs={12}>To recover your email, please contact our client success team at:</Grid>
                        <Grid className={classes.textTwo} item xs={12}>csm@route.com</Grid>
                        <Grid item xs={12}>
                            <Button id="" className={classes.button} onClick={props.onGoToSignIn}>Back to Sign In</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ContainerContent>
        </Container>
    );
};
