import { addBreadcrumb, Severity } from '@sentry/browser';
import React, { useEffect, useState } from 'react';

import RouteProSmIcon from '../../../../assets/images/route-pro-badge-sm.svg';
import RouteProMdIcon from '../../../../assets/images/route-pro-badge-md.svg';
import dictionary from '../../../../constants/dictionary';
import { getRequestCsmInteraction } from '../../../../services/merchant_interaction';

import { Button, Label } from './NavItemUpgradeToPro.styles';
import { ROUTE_PRICING_URL } from '../../../../constants';

const NavItemUpgradeToPro = (props) => {
  const { user, activeBrand: merchant } = props?.userSession;
  const [isLoading, setIsLoading] = useState(false);
  const [hasRequestedCsmInteraction, setHasRequestedCsmInteraction] = useState(false);

  const onButtonPress = async () => {
    if (hasRequestedCsmInteraction) {
      props.handleModal(true, 'modal_already_requested_csm_interaction');
    } else {
      window.open(ROUTE_PRICING_URL, '_blank');
    }
  };

  useEffect(() => {
    const checkRequestCsmInteraction = async () => {
      try {
        setIsLoading(true);
        const res = await getRequestCsmInteraction('get_route_pro_trial', merchant.id, user.token);
        if (res) {
          setHasRequestedCsmInteraction(true);
        }
        setIsLoading(false);
      } catch (err) {
        addBreadcrumb({
          message: dictionary.MESSAGE_ERROR_DEATTACH_USER_AND_MERCHANT_TRIAL_REQUEST,
          category: 'Get Route Pro trial',
          level: Severity.Error,
          data: {
            error: err,
          },
        });
      }
    };

    checkRequestCsmInteraction();
  }, []);

  return (
    <Button onClick={onButtonPress}>
      {hasRequestedCsmInteraction ? (
        <>
          <Label>
            Route will be in contact
            <br />
            shortly about Route Pro.
            <br />
            Keep an eye on your inbox!
          </Label>
          <img
            src={RouteProSmIcon}
            alt="route pro"
            style={{ marginLeft: 15, filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08))' }}
          />
        </>
      ) : (
        <>
          <Label>
            Check out <br />
            Route Pro
          </Label>
          <img
            src={RouteProMdIcon}
            alt="route pro"
            style={{ marginLeft: 15, filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08))' }}
          />
        </>
      )}
    </Button>
  );
};
export default NavItemUpgradeToPro;
