import React, { useEffect, useState } from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import {
  Container, ContentChildren, Label, Number,
} from './Doughnut.styles';

import { IDoughnutProps } from './interface';

const Doughnut: React.FC<IDoughnutProps> = ({
  children, total = 0, metrics, isLoading, text, height = 200, width = 200,
}: IDoughnutProps) => (
    <Container height={height} width={width}>
      <ContentChildren height={height} width={width}>
        <Label>{text}</Label>
        <Number>{(total).toLocaleString()}</Number>
      </ContentChildren>
      {metrics.ReadyToShip > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.ReadyToShip}
            maxValue={total}
            styles={buildStyles({
              rotation: 0,
              pathColor: '#FFBB23',
              trailColor: '#CCCCCC',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}
      {metrics.Delivered > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.Delivered}
            maxValue={total}
            styles={buildStyles({
              rotation: metrics.ReadyToShip / total,
              pathColor: '#5DC8DB',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}

      {metrics.InTransit > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.InTransit}
            maxValue={total}
            styles={buildStyles({
              rotation: (metrics.ReadyToShip + metrics.Delivered) / total,
              pathColor: '#14869F',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}
      {metrics.AvailableForPickup > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.AvailableForPickup}
            maxValue={total}
            styles={buildStyles({
              rotation: (metrics.ReadyToShip + metrics.Delivered + metrics.InTransit) / total,
              pathColor: '#728E9D',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}
      {metrics.OutForDelivery > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.OutForDelivery}
            maxValue={total}
            styles={buildStyles({
              rotation: (metrics.ReadyToShip + metrics.Delivered + metrics.InTransit + metrics.AvailableForPickup) / total,
              pathColor: '#983677',
              trailColor: 'transparent',
              strokeLinecap: 'round',

            })}
          />
        </ContentChildren>
      )}
      {metrics.AttemptFail > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.AttemptFail}
            maxValue={total}
            styles={buildStyles({
              rotation: (metrics.ReadyToShip + metrics.Delivered + metrics.InTransit + metrics.AvailableForPickup + metrics.OutForDelivery) / total,
              pathColor: '#CA116A',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}

      {metrics.ProtectedOrders > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.ProtectedOrders}
            maxValue={total}
            styles={buildStyles({
              rotation: (metrics.Orders) / total,
              pathColor: '#5DC8DB',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}
      {metrics.Orders > 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={metrics.Orders - metrics.ProtectedOrders}
            maxValue={total}
            styles={buildStyles({
              rotation: (metrics.Orders + metrics.ProtectedOrders)  / total,
              pathColor: '#B52082',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}
      {total == 0 && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={total}
            maxValue={total}
            styles={buildStyles({
              rotation: 0,
              pathColor: '#E5E5E5',
              trailColor: 'transparent',
              strokeLinecap: 'round',

            })}
          />
        </ContentChildren>
      )}
      {isLoading && (
        <ContentChildren height={height} width={width}>
          <CircularProgressbar
            value={1}
            maxValue={1}
            styles={buildStyles({
              pathColor: '#E5E5E5',
              trailColor: 'transparent',
              strokeLinecap: 'round',
            })}
          />
        </ContentChildren>
      )}
    </Container>
  );

export default Doughnut;
