import React from "react";
import { AppState, initialState } from "../store";
import { AccountCreationFailedAction, AccountCreationSucceededAction, ACCOUNT_CREATION_FAILED, ACCOUNT_CREATION_SUCCEEDED } from "../actions/account-creation/account-creation";
import { RoutePlusConfigurationSubmitFailedAction, RoutePlusConfigurationSubmitSucceededAction, ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED, ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED } from "../actions/configure-route-plus";
import { PremiumsTransferFailedAction, PremiumsTransferSucceededAction, PREMIUMS_TRANSFER_FAILED, PREMIUMS_TRANSFER_SUCCEEDED } from "../actions/premiums-transfer";
import { ClearUserErrorAction, CLEAR_USER_ERROR, SHOW_USER_ERROR, ShowUserErrorAction } from "../actions/user-error";
import { SKIP_PREMIUMS_FAILED, SKIP_PREMIUMS_SUCCEEDED, SkipPremiumsFailedAction, SkipPremiumsSucceededAction } from "../actions/premiums-transfer/complete-later";
import { PASSWORD_RESET_REQUEST_SUCCEEDED, PASSWORD_RESET_REQUEST_FAILED, PasswordResetRequestSucceededAction, PasswordResetRequestFailedAction } from "../actions/sign-in/password-reset-request";
import { PasswordResetSucceededAction, PasswordResetFailedAction, PASSWORD_RESET_FAILED, PASSWORD_RESET_SUCCEEDED, RESEND_PASSWORD_RESET_FAILED, ResendPasswordResetFailedAction, ResendPasswordResetSucceededAction, RESEND_PASSWORD_RESET_SUCCEEDED } from "../actions/sign-in/password-reset";
import { VERIFY_DOMAIN_FAILED, VERIFY_DOMAIN_SUCCEEDED, VerifyDomainFailedAction, VerifyDomainSucceededAction } from "../../Brand/actions/verify-domain"
import { SETUP_ACCOUNT_FAILED, SETUP_ACCOUNT_SUCCEEDED, SetupAccountFailedAction, SetupAccountSucceededAction } from "../../Brand/actions/setup-account"

type ActionableActions =
  AccountCreationFailedAction | AccountCreationSucceededAction |
  RoutePlusConfigurationSubmitFailedAction | RoutePlusConfigurationSubmitSucceededAction |
  PremiumsTransferFailedAction | PremiumsTransferSucceededAction |
  SkipPremiumsFailedAction | SkipPremiumsSucceededAction |
  PasswordResetRequestFailedAction | PasswordResetRequestSucceededAction |
  PasswordResetSucceededAction | PasswordResetFailedAction |
  ResendPasswordResetFailedAction | ResendPasswordResetSucceededAction |
  ClearUserErrorAction | ShowUserErrorAction |
  VerifyDomainFailedAction | VerifyDomainSucceededAction |
  SetupAccountFailedAction | SetupAccountSucceededAction;

export const errorFragment = (
  <div>
    <div>It looks like the server encountered an error processing this request. We're quite sorry for the inconvenience. <strong>Please try again.</strong></div>
    <div>If the problem persists, you may need to wait for several minutes or contact <strong>csm@route.com</strong>.</div>
  </div>
);

export function createPaymentFailedFragment(message?: string): React.ReactFragment {
  return (
    <div>
      <div>An error occured while processing your payment information:</div>
      <div>{message || 'An unknown error occurred'}</div>
    </div>
  );
}

export function userErrorReducer(state: AppState['userError'] = initialState.userError, action: ActionableActions): AppState['userError'] {
  switch (action.type) {
    case SHOW_USER_ERROR:
    case ACCOUNT_CREATION_FAILED:
    case ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED:
    case SKIP_PREMIUMS_FAILED:
    case PASSWORD_RESET_REQUEST_FAILED:
    case PASSWORD_RESET_FAILED:
    case RESEND_PASSWORD_RESET_FAILED:
    case PREMIUMS_TRANSFER_FAILED:
    case VERIFY_DOMAIN_FAILED:
    case SETUP_ACCOUNT_FAILED: {
      if ((action.type === ACCOUNT_CREATION_FAILED || action.type === SETUP_ACCOUNT_FAILED) &&
        (action.errorReason === 'User Exists' ||
          action.errorReason === 'MerchantExists')) {
        return state;
      }
      if (action.type === PASSWORD_RESET_REQUEST_FAILED &&
        action.reason === 'NoAccount') {
        return state;
      }
      if (action.type === PREMIUMS_TRANSFER_FAILED && action.isHumanReadableError) {
        return createPaymentFailedFragment(action.reason)
      }

      return errorFragment;
    }
    case CLEAR_USER_ERROR:
    case ACCOUNT_CREATION_SUCCEEDED:
    case ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED:
    case SKIP_PREMIUMS_SUCCEEDED:
    case PASSWORD_RESET_REQUEST_SUCCEEDED:
    case PASSWORD_RESET_SUCCEEDED:
    case RESEND_PASSWORD_RESET_SUCCEEDED:
    case PREMIUMS_TRANSFER_SUCCEEDED:
    case VERIFY_DOMAIN_SUCCEEDED:
    case SETUP_ACCOUNT_SUCCEEDED: {
      return null;
    }
    default: {
      return state;
    }
  }
}
