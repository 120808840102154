import React, { Component } from "react";
import Modal from "react-modal";
import PlaidLink from "react-plaid-link";
import { injectStripe } from "react-stripe-elements";

import {
  PLAID_ENV,
  PLAID_PUBLIC_KEY,
} from "../../../../constants";
import "./AddBankAccountModal.scss";

const customStyles = {
  content: {
    width: "650px",
    height: "500px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
  },
};

class AddBankAccountModal extends Component {
  constructor(props) {
    super(props);

    // create a ref to plaidLink to execute a click manually.
    //  this.dashboardActions = this.props.dashboardActions;
    this.plaidLinkRef = React.createRef();

    this.state = {
      openBankModal: true,
      width: this.props.width || "683px",
      height: this.props.height || "534px",
      accountName: "",
      routingNumber: "",
      accountNumber: "",
      accountHolder: "individual",
      // billingSuccess: false
    };
  }

  handleAddACHBankClick = e => {
    e.preventDefault();

    this.handleCloseBankModal();
  };

  handleAddPlaidBankClick = e => {
    e.preventDefault();
    this.plaidLinkRef.current.handleOnClick();
    this.handleCloseBankModal();
  };

  handleCloseBankModal = () => {
    this.setState({ openBankModal: false });
    this.props.onRequestClose();
  };

  handleOnSuccessPlaid = () => {
    // createStripeBankAccountViaPlaid(
    //   ({ source_token: public_token_value, metadata }, res) => {
    //     if (res.status === 200) {
    //       this.props.onBillingSuccess();
    //     // this.setState({ billingSuccess: true });
    //     } else {
    //       // this.setState({ billingSuccess: false });
    //       this.showBillingFailureAlert();
    //     }
    //   }
    // );
  };

  handleOnExitPlaid = () => {
    /* handle the case when your user exits Link*/
  };

  handleAddBankRouting = async e => {
    e.preventDefault();

    const { token, error } = await this.props.stripe.createToken(
      "bank_account",
      {
        country: "US",
        currency: "USD",
        routing_number: this.state.routingNumber,
        account_number: this.state.accountNumber,
        account_holder_name: this.state.accountName,
        account_holder_type: this.state.accountHolder,
      }
    );
    if (error) {
      console.error("Create Bank Error: ", error);
      this.setState({ alertBillingFailure: true,
        // billingSuccess: false
      });
      return;
    }

    // let res = await createStripeBankAccount(token);
    // if (res) {
    //   this.props.onBillingSuccess();
    //   // this.setState({ billingSuccess: true });
    // } else {
    //   // this.setState({ billingSuccess: false });
    // }
  };

  handleInputChange = event => {
    const target = event.target;
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Modal
        isOpen={this.state.openBankModal}
        onRequestClose={this.handleCloseBankModal}
        // style={customModalSize(this.state.height, this.state.width)}
        style={customStyles}
      >
        <div className="bank-options">
          <form className="bank-routing-form">
            <div className="bank-routing-form-container">
              <div className="title">Bank Routing Information</div>
              <div className="routing-row">
                <div className="field">
                  <label htmlFor="account-name">
                    <span>Account Name</span>
                  </label>
                  <input
                    className="routing-input"
                    id="account-name"
                    placeholder="Jane Doe"
                    name="accountName"
                    value={this.state.accountName}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="routing-row">
                <div className="field">
                  <label htmlFor="routing-number">
                    <span>Routing Number</span>
                  </label>
                  <input
                    className="routing-input"
                    id="routing-number"
                    placeholder="021000021"
                    name="routingNumber"
                    maxLength="9"
                    value={this.state.routingNumber}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="routing-row">
                <div className="field">
                  <label htmlFor="account-number">
                    <span>Account Number</span>
                  </label>
                  <input
                    className="routing-input"
                    id="account-number"
                    placeholder="Savings/Checking Number"
                    maxLength="12"
                    name="accountNumber"
                    value={this.state.accountNumber}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="routing-row">
                <div className="field">
                  <label htmlFor="account-holder">
                    <span>Account Holder</span>
                  </label>
                  <select
                    className="routing-input"
                    id="account-holder"
                    name="accountHolder"
                    defaultValue={this.state.accountHolder}
                    onChange={this.handleAccountHolderSelect}
                  >
                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                  </select>
                </div>
              </div>
              <button
                className="bank-options-connect-routing"
                onClick={this.handleAddBankRouting}
              >
                Save
              </button>
            </div>
          </form>
          <div className="bank-options-routing-disclaimer">
            Using the bank routing method will take up to 1-2 days.
          </div>
          <div className="bank-options-divider">
            <div className="bank-options-divider-left" />
            <div className="bank-options-divider-label">Or</div>
            <div className="bank-options-divider-right" />
          </div>
          <button
            className="bank-options-connect-instantly"
            onClick={this.handleAddPlaidBankClick}
          >
            Connect Bank Instantly
          </button>
        </div>
        {/* Start non-visible content */}
        <PlaidLink
          selectAccount={true}
          env={PLAID_ENV}
          clientName="Route"
          publicKey={PLAID_PUBLIC_KEY}
          product={["auth"]}
          style={{ display: "none" }}
          ref={this.plaidLinkRef}
          onExit={this.handleOnExitPlaid}
          onSuccess={this.handleOnSuccessPlaid}
        />
        {/* End non-visible content */}
      </Modal>
    );
  }
}

export default injectStripe(AddBankAccountModal);
