/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { Component } from './Typography.styles';

const defaultVariantMapping: any = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
  h8: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  inherit: 'p',
};

type TypographyProps = {
  align?: string;
  classes?: object;
  className?: string;
  color?: string;
  component?: string;
  display?: string;
  gutterBottom?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  variant: any;
  children?: React.ReactNode;
  uppercase?: boolean;
  semibold?: boolean;
};

const Typography = React.forwardRef((props: TypographyProps, ref) => {
  const {
    align,
    classes,
    className,
    color,
    component,
    display,
    gutterBottom,
    noWrap,
    paragraph,
    variant,
    children,
    uppercase,
  } = props;

  const Element = component || (paragraph ? 'p' : defaultVariantMapping[variant]) || 'span';

  return (
    <Component as={Element} {...props} ref={ref}>
      {children}
    </Component>
  );
});

Typography.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'error',
    'inherit',
    'initial',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'darkGray',
    'gray',
    'gray3',
    'white',
  ]),
  component: PropTypes.string,
  display: PropTypes.oneOf(['block', 'initial', 'inline']),
  gutterBottom: PropTypes.bool,
  noWrap: PropTypes.bool,
  paragraph: PropTypes.bool,
  uppercase: PropTypes.bool,
  semibold: PropTypes.bool,
  variant: PropTypes.oneOfType([
    PropTypes.oneOf([
      'body1',
      'body2',
      'button',
      'caption',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'inherit',
      'overline',
      'subtitle1',
      'subtitle2',
    ]),
    PropTypes.string,
  ]),
};

export default Typography;
