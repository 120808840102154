import React, { useState, useEffect, useReducer } from 'react';
import { Toggle } from './Switch.styles';
import { ISwitchProps } from './interface.d';

const Switch: React.FC<ISwitchProps> = (props: ISwitchProps) => {
  const [checked, setChecked] = useState(props.checked);
  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    setChecked(props.checked);
    forceUpdate();
  }, [props.checked]);

  return (
    <React.Fragment>
      <Toggle>
        <div
          className={`toggle ${checked ? 'toggle-on' : ''} ${props.disabled ? 'toggle-disabled' : ''}`}
          id="switch"
          onClick={() => {
            if (!props.disabled) {
              const toggled = !checked;
              setChecked(toggled);
              if (props.onChange) {
                props.onChange(toggled);
              }
            }
          }}
        >
          <div className="glow-comp"></div>
          <div className="toggle-button"></div>
        </div>
      </Toggle>
    </React.Fragment>
  );
};

Switch.defaultProps = {
  disabled: false,
};

export default Switch;
