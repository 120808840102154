import React, { useState, useEffect } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';

import { getMerchantStats } from '../../../../services/merchant_stats.ts';
import { formatSecToMin } from '../../utils/formatDates';
import { formatNumToCoin } from '../../utils/formatCoin';
import { colors } from '../../constants/styles';
import PageTitle from '../../_shared/PageTitle/PageTitle';
import DateRangePicker from '../../../_shared/DateRangePicker/DateRangePicker';
import Card from '../../components/Card';
import CardHeaderOutside from '../../components/CardHeaderOutside';
import CardLabel from '../../components/CardLabel';
import Tooltip from '../../components/Tooltip';
import IconInfo from '../../components/IconInfo';
import HintText from '../../components/HintText';
import MetricList from '../../components/MetricList';
import MetricItem from '../../components/MetricItem';
import Typography from '../../components/Typography';
import LineGraphDisplayWidget from '../dashboard/Graphs/LineGraphDisplayWidget';
import Can from '../../components/Authentication/Can/Can';
import { AuthConsumer } from '../../hooks/authContext';

import GetRouteProLargeWidget from '../../components/GetRouteProLargeWidget';

const useStyles = makeStyles({
  grid: {
    marginLeft: -15,
    marginRight: -15,
  },
});

const Insights = (props) => {
  const classes = useStyles();
  const [state, setState] = useState();
  const setupStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const setupEndDate = moment().add(1, 'days').format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(setupStartDate);
  const [endDate, setEndDate] = useState(setupEndDate);
  //
  const [graphDataOne, setGraphDataOne] = useState({ isLoading: true });
  const [graphDataTwo, setGraphDataTwo] = useState({ isLoading: true });
  const [graphDataThree, setGraphDataThree] = useState({ isLoading: true });
  const [graphDataFour, setGraphDataFour] = useState({ isLoading: true });
  //
  const [graphTitleOne, setGraphTitleOne] = useState('');
  const [graphTitleTwo, setGraphTitleTwo] = useState('');
  const [graphTitleThree, setGraphTitleThree] = useState('');
  const [graphTitleFour, setGraphTitleFour] = useState('');
  // block 1
  const [totalOrdersTrackedInApp, setTotalOrdersTrackedInApp] = useState({ isLoading: true }); // total_orders_and_orders_tracked_in_app
  const [avgSessionsPerOrder, setAvgSessionsPerOrder] = useState({ isLoading: true }); // avg_sessions_per_order
  const [avgDurationPerOrder, setAvgDurationPerOrder] = useState({ isLoading: true }); // avg_session_duration_in_sec_per_order
  const [totalSales, setTotalSales] = useState({ isLoading: true }); // total_sales
  const [avgSalesValue, setAvgSalesValue] = useState({ isLoading: true }); // average_sales_value
  const [totalClicksToMerchantSites, setTotalClicksToMerchantSites] = useState({ isLoading: true }); // num_of_click_to_web
  const [totalSalesValue, setTotalSalesValue] = useState({ isLoading: true }); // total_sales_value
  // block 2
  const [totalViewAppPromoContent, setTotalViewAppPromoContent] = useState({ isLoading: true }); // click_rate_for_merchant_promotions
  const [totalClickAppPromoContent, setTotalClickAppPromoContent] = useState({ isLoading: true }); // click_rate_for_merchant_promotions
  const [totalClickToPurchaseAppPromoContent, setTotalClickToPurchaseAppPromoContent] = useState({ isLoading: true }); // num_of_taps_on_branded_content_resulted_in_order
  const [totalAttributedSalesAppPromoContent, setTotalAttributedSalesAppPromoContent] = useState({ isLoading: true }); // tot_rev_att_to_user_click_on_branded_content_to_purchase_item
  const [totalAvgClickToPurchaseAppPromoContent, setTotalAvgClickToPurchaseAppPromoContent] = useState({
    isLoading: true,
  }); // avg_num_of_taps_on_branded_content_resulted_in_order
  const [totalAvgPurchaseValueAppPromoContent, setTotalAvgPurchaseValueAppPromoContent] = useState({ isLoading: true }); // avg_rev_att_to_user_click_on_branded_content_to_purchase_item
  // block 3
  const [totalViewAppProfilePage, setTotalViewAppProfilePage] = useState({ isLoading: true }); // click_rate_merchant_web_from_merchant_profile_route_app
  const [totalClickAppProfilePage, setTotalClickAppProfilePage] = useState({ isLoading: true }); // click_rate_merchant_web_from_merchant_profile_route_app
  const [totalClickToPurchaseAppProfilePage, setTotalClickToPurchaseAppProfilePage] = useState({ isLoading: true }); // num_of_taps_on_app_profile_resulted_in_order
  const [totalAttributedSalesAppProfilePage, setTotalAttributedSalesAppProfilePage] = useState({ isLoading: true }); // tot_rev_att_to_user_tap_on_app_profile_to_purchase_item
  const [totalAvgClickToPurchaseAppProfilePage, setTotalAvgClickToPurchaseAppProfilePage] = useState({
    isLoading: true,
  }); // avg_num_of_taps_on_app_profile_resulted_in_order
  const [totalAvgPurchaseValueAppProfilePage, setTotalAvgPurchaseValueAppProfilePage] = useState({ isLoading: true }); // avg_rev_att_to_user_tap_on_app_profile_to_purchase_item
  // block 4
  const [totalViewWebTrackingPromoContent, setTotalViewWebTrackingPromoContent] = useState({ isLoading: true }); // click_rate_for_merchant_web_tracking_promo_content
  const [totalClickWebTrackingPromoContent, setTotalClickWebTrackingPromoContent] = useState({ isLoading: true }); // click_rate_for_merchant_web_tracking_promo_content

  useEffect(() => {
    setGraphDataOne({ lineData: [], labels: [], isLoading: true });
    setGraphDataTwo({ lineData: [], labels: [], isLoading: true });
    setGraphDataThree({ lineData: [], labels: [], isLoading: true });
    setGraphDataFour({ lineData: [], labels: [], isLoading: true });
    setGraphTitleOne('');
    setGraphTitleTwo('');
    setGraphTitleThree('');
    setGraphTitleFour('');

    setTotalOrdersTrackedInApp({ isLoading: true });
    setAvgSessionsPerOrder({ isLoading: true });
    setAvgDurationPerOrder({ isLoading: true });
    setTotalSales({ isLoading: true });
    setAvgSalesValue({ isLoading: true });
    setTotalClicksToMerchantSites({ isLoading: true });
    setTotalSalesValue({ isLoading: true });

    setTotalViewAppPromoContent({ isLoading: true });
    setTotalClickAppPromoContent({ isLoading: true });
    setTotalClickToPurchaseAppPromoContent({ isLoading: true });
    setTotalAttributedSalesAppPromoContent({ isLoading: true });
    setTotalAvgClickToPurchaseAppPromoContent({ isLoading: true });
    setTotalAvgPurchaseValueAppPromoContent({ isLoading: true });

    setTotalViewAppProfilePage({ isLoading: true });
    setTotalClickAppProfilePage({ isLoading: true });
    setTotalClickToPurchaseAppProfilePage({ isLoading: true });
    setTotalAttributedSalesAppProfilePage({ isLoading: true });
    setTotalAvgClickToPurchaseAppProfilePage({ isLoading: true });
    setTotalAvgPurchaseValueAppProfilePage({ isLoading: true });

    setTotalViewWebTrackingPromoContent({ isLoading: true });
    setTotalClickWebTrackingPromoContent({ isLoading: true });

    let unmounted = false;
    const source = axios.CancelToken.source();

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_and_orders_tracked_in_app',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalOrdersTrackedInApp({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_sessions_per_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setAvgSessionsPerOrder({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_session_duration_in_sec_per_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setAvgDurationPerOrder({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalSales({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'average_sales_value',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setAvgSalesValue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_click_to_web',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalClicksToMerchantSites({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales_value',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalSalesValue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_for_merchant_promotions',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalViewAppPromoContent({ ...res.data, isLoading: false });
          setTotalClickAppPromoContent({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_taps_on_branded_content_resulted_in_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalClickToPurchaseAppPromoContent({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'tot_rev_att_to_user_click_on_branded_content_to_purchase_item',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalAttributedSalesAppPromoContent({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_num_of_taps_on_branded_content_resulted_in_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalAvgClickToPurchaseAppPromoContent({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_rev_att_to_user_click_on_branded_content_to_purchase_item',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalAvgPurchaseValueAppPromoContent({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_merchant_web_from_merchant_profile_route_app',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalViewAppProfilePage({ ...res.data, isLoading: false });
          setTotalClickAppProfilePage({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_taps_on_app_profile_resulted_in_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalClickToPurchaseAppProfilePage({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'tot_rev_att_to_user_tap_on_app_profile_to_purchase_item',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalAttributedSalesAppProfilePage({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_num_of_taps_on_app_profile_resulted_in_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalAvgClickToPurchaseAppProfilePage({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_rev_att_to_user_tap_on_app_profile_to_purchase_item',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalAvgPurchaseValueAppProfilePage({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_for_merchant_web_tracking_promo_content',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalViewWebTrackingPromoContent({ ...res.data, isLoading: false });
          setTotalClickWebTrackingPromoContent({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    // return cleanup function, this is called when the component unmounts, here we will cancel pending requests
    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [startDate, endDate]);

  const onSelectDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleTotalOrdersClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Total Orders');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_and_orders_tracked_in_app_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].orders);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalOrdersTrackedInAppClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Total Orders Tracked In App');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_and_orders_tracked_in_app_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].tracked_orders);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleAvgSessionsPerOrderClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Average sessions per order');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_sessions_per_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].date);
            graphData.dailyAmounts.push(result[i].average);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleAvgDurationPerOrderClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Average time on app per user');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_session_duration_in_sec_per_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].date);
            graphData.dailyAmounts.push(result[i].average);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalSalesClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Total Sales');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].count);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleAvgSalesValueClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Average Order Value');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'average_sales_value_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalClicksToMerchantSitesClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Total Clicks (to Merchant’s Site/s)');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_click_to_web_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].count);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalSalesValueClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne('Total Sales Value');
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales_value_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalViewAppPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleTwo('Views');
    setGraphDataTwo({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_for_merchant_promotions_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].views);
          }

          setGraphDataTwo(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalClickAppPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleTwo('Clicks');
    setGraphDataTwo({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_for_merchant_promotions_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].taps);
          }

          setGraphDataTwo(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalClickToPurchaseAppPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleTwo('Click to Purchase');
    setGraphDataTwo({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_taps_on_branded_content_resulted_in_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].count);
          }

          setGraphDataTwo(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalAttributedSalesAppPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleTwo('Total Attributed Sales');
    setGraphDataTwo({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'tot_rev_att_to_user_click_on_branded_content_to_purchase_item_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataTwo(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalAvgClickToPurchaseAppPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleTwo('Average Clicks to Purchase (Per Customer)');
    setGraphDataTwo({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_num_of_taps_on_branded_content_resulted_in_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].average);
          }

          setGraphDataTwo(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalAvgPurchaseValueAppPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleTwo('Average Purchase Value');
    setGraphDataTwo({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_rev_att_to_user_click_on_branded_content_to_purchase_item_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataTwo(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalViewAppProfilePageClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleThree('Views');
    setGraphDataThree({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_merchant_web_from_merchant_profile_route_app_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].views);
          }

          setGraphDataThree(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalClickAppProfilePageClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleThree('Clicks');
    setGraphDataThree({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_merchant_web_from_merchant_profile_route_app_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].clicks);
          }

          setGraphDataThree(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalClickToPurchaseAppProfilePageClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleThree('Clicks to Purchase');
    setGraphDataThree({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_taps_on_app_profile_resulted_in_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].count);
          }

          setGraphDataThree(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalAttributedSalesAppProfilePageClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleThree('Total Attributed Sales');
    setGraphDataThree({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'tot_rev_att_to_user_tap_on_app_profile_to_purchase_item_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataThree(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalAvgClickToPurchaseAppProfilePageClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleThree('Average Clicks to Purchase (Per Customer)');
    setGraphDataThree({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_num_of_taps_on_app_profile_resulted_in_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].average);
          }

          setGraphDataThree(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalAvgPurchaseValueAppProfilePageClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleThree('Average Purchase Value');
    setGraphDataThree({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_rev_att_to_user_tap_on_app_profile_to_purchase_item_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataThree(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalViewWebTrackingPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleFour('Views');
    setGraphDataFour({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_for_merchant_web_tracking_promo_content_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].views);
          }

          setGraphDataFour(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Clicks');
    };
  };

  const handleTotalClickWebTrackingPromoContentClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleFour('Clicks');
    setGraphDataFour({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'click_rate_for_merchant_web_tracking_promo_content_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].clicks);
          }

          setGraphDataFour(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const onClearDateRange = () => {
    onSelectDate(setupStartDate, setupEndDate);
  };

  return (
    <AuthConsumer>
      {({ tier }) => (
        <div>
          <div className="portal-content-container dashboard">
            <PageTitle title="Insights" />
            <Can
              role={tier}
              permissions={tier}
              perform="post_purchase_insights_page"
              yes={() => (
                <>
                  <Grid container spacing={0} padding={0}>
                    <Grid item lg={12} md={12} sm={12} spacing={0} padding={0} className={classes.grid}>
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#EEEEEE',
                          marginTop: '22px',
                          marginBottom: '22px',
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} padding={0}>
                    <Box padding={0}>
                      <DateRangePicker
                        title="Date Range:"
                        placeholder="Last 30 Days"
                        startDate={props.userSession.activeBrand.created_on || moment().subtract(1, 'week')}
                        onSelectDate={onSelectDate}
                        onClean={onClearDateRange}
                      ></DateRangePicker>
                    </Box>
                  </Grid>

                  <Grid container spacing={0} padding={0}>
                    <Grid item lg={12} md={12} sm={12} spacing={0} padding={0} className={classes.grid}>
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#EEEEEE',
                          marginTop: '20px',
                          marginBottom: '8px',
                        }}
                      ></div>
                      <HintText>General App Usage</HintText>
                    </Grid>
                    <Grid container lg={12} md={12} sm={12} className={classes.grid}>
                      <Grid item lg={3} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>Orders Tracked In App</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">Engagement with the App per order. </Typography>
                                  <br />
                                  <br />
                                  <Typography variant="p">
                                    Number of orders customers have placed that are using the App.
                                  </Typography>
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card isLoading={totalOrdersTrackedInApp.isLoading}>
                            <MetricList>
                              <MetricItem
                                text="Total Orders"
                                value={(totalOrdersTrackedInApp.orders || 0).toLocaleString()}
                                isLoading={totalOrdersTrackedInApp.isLoading}
                                color={colors.darkBlue}
                                hasGraph
                                handleGraphPress={graphTitleOne !== 'Total Orders' && handleTotalOrdersClicked}
                                isActive={graphTitleOne === 'Total Orders'}
                              />
                              <MetricItem
                                text="Total Orders Tracked In App"
                                value={
                                  `${(totalOrdersTrackedInApp.tracked_orders || 0).toLocaleString()} (${
                                    totalOrdersTrackedInApp.percentage
                                  }%)` || '00,000 (00%)'
                                }
                                isLoading={totalOrdersTrackedInApp.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleOne !== 'Total Orders Tracked In App' &&
                                  handleTotalOrdersTrackedInAppClicked
                                }
                                isActive={graphTitleOne === 'Total Orders Tracked In App'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>Average Session Length Per User</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Average number of times and duration a user opens the Route App to check on their
                                    order status.
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card isLoading={avgSessionsPerOrder.isLoading || avgDurationPerOrder.isLoading}>
                            <MetricList>
                              <MetricItem
                                text="Average sessions per order"
                                value={(avgSessionsPerOrder.average || 0).toFixed(2) || '0.0'}
                                isLoading={avgSessionsPerOrder.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleOne !== 'Average sessions per order' && handleAvgSessionsPerOrderClicked
                                }
                                isActive={graphTitleOne === 'Average sessions per order'}
                              />
                              <MetricItem
                                text="Average time on app per user"
                                value={formatSecToMin(avgDurationPerOrder.average || 0) || '00:00'}
                                isLoading={avgDurationPerOrder.isLoading}
                                color={colors.darkBlue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleOne !== 'Average time on app per user' && handleAvgDurationPerOrderClicked
                                }
                                isActive={graphTitleOne === 'Average time on app per user'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>Total Route App Attributed Sales</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Total app value. Total sales and average order value tracked through the app.
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card isLoading={totalSales.isLoading || avgSalesValue.isLoading}>
                            <MetricList>
                              <MetricItem
                                text="Total Sales"
                                value={totalSales.count || '0'}
                                isLoading={totalSales.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={graphTitleOne !== 'Total Sales' && handleTotalSalesClicked}
                                isActive={graphTitleOne === 'Total Sales'}
                              />
                              <MetricItem
                                text="Average Order Value"
                                value={`$${
                                  (avgSalesValue.amount || 0).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }) || '$0.00'
                                }`}
                                isLoading={avgSalesValue.isLoading}
                                color={colors.green}
                                hasGraph
                                handleGraphPress={graphTitleOne !== 'Average Order Value' && handleAvgSalesValueClicked}
                                isActive={graphTitleOne === 'Average Order Value'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>Total App Value</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Click-through rate to your website from a Route link and associated sales within 24
                                    hours.
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card isLoading={totalClicksToMerchantSites.isLoading || totalSales.isLoading}>
                            <MetricList>
                              <MetricItem
                                text="Total Clicks (to Merchant’s Site/s)"
                                value={(totalClicksToMerchantSites.count || 0).toLocaleString() || '00,000'}
                                isLoading={totalClicksToMerchantSites.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleOne !== 'Total Clicks (to Merchant’s Site/s)' &&
                                  handleTotalClicksToMerchantSitesClicked
                                }
                                isActive={graphTitleOne === 'Total Clicks (to Merchant’s Site/s)'}
                              />
                              <MetricItem
                                text="Total Sales Value"
                                value={`$${
                                  (totalSalesValue.amount || 0).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }) || '$0.00'
                                }`}
                                isLoading={totalSalesValue.isLoading}
                                color={colors.green}
                                hasGraph
                                handleGraphPress={graphTitleOne !== 'Total Sales Value' && handleTotalSalesValueClicked}
                                isActive={graphTitleOne === 'Total Sales Value'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>

                    {graphTitleOne && (
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} className={classes.grid}>
                          <LineGraphDisplayWidget
                            graphTitle={graphTitleOne}
                            graphData={graphDataOne}
                            onClose={() => {
                              setGraphTitleOne('');
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} className={classes.grid}>
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#EEEEEE',
                          marginTop: '20px',
                          marginBottom: '8px',
                        }}
                      ></div>
                      <HintText>App Promo Content Insights</HintText>
                    </Grid>

                    <Grid container lg={12} md={12} sm={12} className={classes.grid}>
                      <Grid item lg={4} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>App Promo Content: Click-Through</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Total Views and Clicks of your promotional order content through the app.{' '}
                                  </Typography>
                                  <br />
                                  <br />
                                  <Typography variant="p">
                                    You can view and make changes to what is shown on the Promo Content tab.
                                  </Typography>
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card isLoading={totalViewAppPromoContent.isLoading || totalClickAppPromoContent.isLoading}>
                            <MetricList>
                              <MetricItem
                                text="Views"
                                value={(totalViewAppPromoContent.views || 0).toLocaleString() || '00,000'}
                                isLoading={totalViewAppPromoContent.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={graphTitleTwo !== 'Views' && handleTotalViewAppPromoContentClicked}
                                isActive={graphTitleTwo === 'Views'}
                              />
                              <MetricItem
                                text="Clicks"
                                value={
                                  `${(totalClickAppPromoContent.taps || 0).toLocaleString()} (${
                                    totalClickAppPromoContent.percentage
                                  }%)` || '00,000 (00%)'
                                }
                                isLoading={totalClickAppPromoContent.isLoading}
                                color={colors.darkBlue}
                                hasGraph
                                handleGraphPress={graphTitleTwo !== 'Clicks' && handleTotalClickAppPromoContentClicked}
                                isActive={graphTitleTwo === 'Clicks'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>App Promo Content: Total Value</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Total revenue attributed to a user clicking on promotional content to purchase an
                                    item within 24 hours.
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card
                            isLoading={
                              totalClickToPurchaseAppPromoContent.isLoading ||
                              totalAttributedSalesAppPromoContent.isLoading
                            }
                          >
                            <MetricList>
                              <MetricItem
                                text="Click to Purchase"
                                value={(totalClickToPurchaseAppPromoContent.count || 0).toLocaleString() || '00,000'}
                                isLoading={totalClickToPurchaseAppPromoContent.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleTwo !== 'Click to Purchase' &&
                                  handleTotalClickToPurchaseAppPromoContentClicked
                                }
                                isActive={graphTitleTwo === 'Click to Purchase'}
                              />
                              <MetricItem
                                text="Total Attributed Sales"
                                value={`$${
                                  (totalAttributedSalesAppPromoContent.amount || 0).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }) || '$0.00'
                                }`}
                                isLoading={totalAttributedSalesAppPromoContent.isLoading}
                                color={colors.green}
                                hasGraph
                                handleGraphPress={
                                  graphTitleTwo !== 'Total Attributed Sales' &&
                                  handleTotalAttributedSalesAppPromoContentClicked
                                }
                                isActive={graphTitleTwo === 'Total Attributed Sales'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>App Promo Content: Value Per Customer</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Average clicks and purchase value a customer has through promotional content before
                                    making a purchase.
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card
                            isLoading={
                              totalAvgClickToPurchaseAppPromoContent.isLoading ||
                              totalAvgPurchaseValueAppPromoContent.isLoading
                            }
                          >
                            <MetricList>
                              <MetricItem
                                text="Average Clicks to Purchase (Per Customer)"
                                value={totalAvgClickToPurchaseAppPromoContent.average || '.0'}
                                isLoading={totalAvgClickToPurchaseAppPromoContent.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleTwo !== 'Average Clicks to Purchase (Per Customer)' &&
                                  handleTotalAvgClickToPurchaseAppPromoContentClicked
                                }
                                isActive={graphTitleTwo === 'Average Clicks to Purchase (Per Customer)'}
                              />
                              <MetricItem
                                text="Average Purchase Value"
                                value={formatNumToCoin(totalAvgPurchaseValueAppPromoContent.amount || 0) || '$0.00'}
                                isLoading={totalAvgPurchaseValueAppPromoContent.isLoading}
                                color={colors.green}
                                hasGraph
                                handleGraphPress={
                                  graphTitleTwo !== 'Average Purchase Value' &&
                                  handleTotalAvgPurchaseValueAppPromoContentClicked
                                }
                                isActive={graphTitleTwo === 'Average Purchase Value'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>

                    {graphTitleTwo && (
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} className={classes.grid}>
                          <LineGraphDisplayWidget
                            graphTitle={graphTitleTwo}
                            graphData={graphDataTwo}
                            onClose={() => {
                              setGraphTitleTwo('');
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} className={classes.grid}>
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#EEEEEE',
                          marginTop: '20px',
                          marginBottom: '8px',
                        }}
                      ></div>
                      <HintText>App Profile Page Insights</HintText>
                    </Grid>

                    <Grid container lg={12} md={12} sm={12} className={classes.grid}>
                      <Grid item lg={4} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>App Profile Page: Click-Through</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Click-through rate to your website from the Merchant Profile in the Route app.{' '}
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card isLoading={totalViewAppProfilePage.isLoading || totalClickAppProfilePage.isLoading}>
                            <MetricList>
                              <MetricItem
                                text="Views"
                                value={(totalViewAppProfilePage.views || 0).toLocaleString() || '00,000'}
                                isLoading={totalViewAppProfilePage.isLoading}
                                color={colors.darkBlue}
                                hasGraph
                                handleGraphPress={graphTitleThree !== 'Views' && handleTotalViewAppProfilePageClicked}
                                isActive={graphTitleThree === 'Views'}
                              />
                              <MetricItem
                                text="Clicks"
                                value={
                                  `${(totalClickAppProfilePage.clicks || 0).toLocaleString()} (${(
                                    totalClickAppProfilePage.clickThroughRate || 0
                                  ).toFixed(2)}%)` || '0,000 (00%)'
                                }
                                isLoading={totalClickAppProfilePage.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={graphTitleThree !== 'Clicks' && handleTotalClickAppProfilePageClicked}
                                isActive={graphTitleThree === 'Clicks'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={12} sm={12}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>App Profile Page: Total Value</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Total revenue attributed to a user clicking on the Merchant Profile to purchase an
                                    item within 24 hours.{' '}
                                  </Typography>
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card
                            isLoading={
                              totalClickToPurchaseAppProfilePage.isLoading ||
                              totalAttributedSalesAppProfilePage.isLoading
                            }
                          >
                            <MetricList>
                              <MetricItem
                                text="Clicks to Purchase"
                                value={(totalClickToPurchaseAppProfilePage.count || 0).toLocaleString() || '00,000'}
                                isLoading={totalClickToPurchaseAppProfilePage.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleThree !== 'Clicks to Purchase' &&
                                  handleTotalClickToPurchaseAppProfilePageClicked
                                }
                                isActive={graphTitleThree === 'Clicks to Purchase'}
                              />
                              <MetricItem
                                text="Total Attributed Sales"
                                value={formatNumToCoin(totalAttributedSalesAppProfilePage.amount) || '$0,000.00'}
                                isLoading={totalAttributedSalesAppProfilePage.isLoading}
                                color={colors.green}
                                hasGraph
                                handleGraphPress={
                                  graphTitleThree !== 'Total Attributed Sales' &&
                                  handleTotalAttributedSalesAppProfilePageClicked
                                }
                                isActive={graphTitleThree === 'Total Attributed Sales'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={12} sm={12} className={classes.grid}>
                        <Box mb={4}>
                          <CardHeaderOutside>
                            <CardLabel>App Profile Page: Value Per Customer</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Average clicks and revenue attributed to a user clicking on the Merchant Profile to
                                    purchase an item within 24 hours.{' '}
                                  </Typography>{' '}
                                  <br />
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                          <Card
                            isLoading={
                              totalAvgClickToPurchaseAppProfilePage.isLoading ||
                              totalAvgPurchaseValueAppProfilePage.isLoading
                            }
                          >
                            <MetricList>
                              <MetricItem
                                text="Average Clicks to Purchase (Per Customer)"
                                value={totalAvgClickToPurchaseAppProfilePage.average || '00.0'}
                                isLoading={totalAvgClickToPurchaseAppProfilePage.isLoading}
                                color={colors.blue}
                                hasGraph
                                handleGraphPress={
                                  graphTitleThree !== 'Average Clicks to Purchase (Per Customer)' &&
                                  handleTotalAvgClickToPurchaseAppProfilePageClicked
                                }
                                isActive={graphTitleThree === 'Average Clicks to Purchase (Per Customer)'}
                              />
                              <MetricItem
                                text="Average Purchase Value"
                                value={formatNumToCoin(totalAvgPurchaseValueAppProfilePage.amount) || '$00.00'}
                                isLoading={totalAvgPurchaseValueAppProfilePage.isLoading}
                                color={colors.green}
                                hasGraph
                                handleGraphPress={
                                  graphTitleThree !== 'Average Purchase Value' &&
                                  handleTotalAvgPurchaseValueAppProfilePageClicked
                                }
                                isActive={graphTitleThree === 'Average Purchase Value'}
                              />
                            </MetricList>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>

                    {graphTitleThree && (
                      <Grid container>
                        <Grid item lg={12} md={12} sm={12} className={classes.grid}>
                          <LineGraphDisplayWidget
                            graphTitle={graphTitleThree}
                            graphData={graphDataThree}
                            onClose={() => {
                              setGraphTitleThree('');
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={4} md={12} sm={12}>
                      <div
                        style={{
                          display: 'block',
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#EEEEEE',
                          marginTop: '20px',
                          marginBottom: '8px',
                        }}
                      ></div>
                      <HintText>Web Tracking Page Insights</HintText>
                      <Box mb={4}>
                        <CardHeaderOutside>
                          <CardLabel>Web Tracking Promo Content: Click-Through</CardLabel>
                          <Tooltip
                            wrapper={<IconInfo />}
                            overlay={
                              <>
                                <Typography variant="p">
                                  Total Views and Clicks of your promotional order content via the web tracking feature.
                                </Typography>
                                <br />
                                <br />
                                <Typography variant="p">
                                  You can view and make changes to what is shown on the Promo Content tab.
                                </Typography>
                              </>
                            }
                          ></Tooltip>
                        </CardHeaderOutside>
                        <Card
                          isLoading={
                            totalViewWebTrackingPromoContent.isLoading || totalClickWebTrackingPromoContent.isLoading
                          }
                        >
                          <MetricList>
                            <MetricItem
                              text="Views"
                              value={(totalViewWebTrackingPromoContent.views || 0).toLocaleString() || '00,000'}
                              isLoading={totalViewWebTrackingPromoContent.isLoading}
                              color={colors.darkBlue}
                              hasGraph
                              handleGraphPress={
                                graphTitleFour !== 'Views' && handleTotalViewWebTrackingPromoContentClicked
                              }
                              isActive={graphTitleFour === 'Views'}
                            />
                            <MetricItem
                              text="Clicks"
                              value={
                                `${(totalClickWebTrackingPromoContent.clicks || 0).toLocaleString()} (${(
                                  totalClickWebTrackingPromoContent.percentage || 0
                                ).toFixed(2)}%)` || '0,000 (00%)'
                              }
                              isLoading={totalClickWebTrackingPromoContent.isLoading}
                              color={colors.blue}
                              hasGraph
                              handleGraphPress={
                                graphTitleFour !== 'Clicks' && handleTotalClickWebTrackingPromoContentClicked
                              }
                              isActive={graphTitleFour === 'Clicks'}
                            />
                          </MetricList>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>

                  {graphTitleFour && (
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} className={classes.grid}>
                        <LineGraphDisplayWidget
                          graphTitle={graphTitleFour}
                          graphData={graphDataFour}
                          onClose={() => {
                            setGraphTitleFour('');
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container direction="row" justify={'flex-end'} alignItems={'center'}>
                    <Box mb={4}>
                      <Typography variant="h8" component="h4" color="gray">
                        *Data could be delayed up to 24 hours.
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
              no={() => (
                <>
                  <Grid container spacing={0} padding={0}>
                    <Grid item lg={12} md={12} sm={12} spacing={0} padding={0} className={classes.grid}>
                      <GetRouteProLargeWidget {...props} />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </div>
        </div>
      )}
    </AuthConsumer>
  );
};

export default Insights;
