import axios from 'axios';

export const AddressToGeolocation = async (address) => {
  try {
    return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg`).then((resp) => resp.data.features[0].geometry.coordinates);
  } catch (error) {
    console.error('Error to get geolocation', error);
    return [-1, -1];
  }
};

export const getAddByZip = async (zip) => {
  try {
    return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${zip}.json?access_token=pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg`).then((resp) => resp.data.features[0]);
  } catch (error) {
    console.error('Error to get geolocation', error);
    return [];
  }
};
