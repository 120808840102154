import React, {FC} from 'react';
import {Container as MuiContainer, ContainerProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    maxWidth: 'unset !important',
    overflowX: 'hidden',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}, {name: 'RouteContainer'});

export const Container: FC<ContainerProps> = (props) => {
  const classes = useStyles();
  const {children, ...otherProps} = props;

  return (
    <MuiContainer className={classes.root} {...otherProps}>
      {children}
    </MuiContainer>
  );
}
