import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { Paper, Theme, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StepEnum, OnboardingStepper } from './stepper';
import { defaultPaperBorderRadius } from '../constants/theme';

type StylesProps = {
  width?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  contentScrollContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  // Used to center horizontally. We can't use auto margins on the scroll
  // container to center horizontally because we need to set the right margin
  // to a negative value when the scrollbar is visible.
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    borderRadius: defaultPaperBorderRadius,
    textAlign: 'center',
    display: 'flex',
    flexShrink: 0,
    marginTop: 65,
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    maxWidth: (props: StylesProps = {}) => props.width,
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      marginTop: 0,
    },
  },
  spacer: {
    width: '100%',
    height: '65px',
    minHeight: '65px',
    [theme.breakpoints.down('xs')]: {
      height: '0px',
      minHeight: '0px',
    }
  }
}), { name: 'ProgressContainerContent' });

export type ProgressContainerContentProps = {
  children: React.ReactNode;
  progressStep: StepEnum;
  width?: number;
  [prop: string]: any;
}

export const ProgressContainerContent: FC<ProgressContainerContentProps> = (props) => {
  const classes = useStyles(props);
  const { children, progressStep, ...otherProps } = props;

  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const contentScrollContainerRef = useRef<HTMLDivElement | null>(null);

  function handleResize() {
    if (!contentScrollContainerRef.current) {
      return;
    }

    setScrollbarWidth(contentScrollContainerRef.current.offsetWidth - contentScrollContainerRef.current.clientWidth);
  }

  useLayoutEffect(() => {
    // Run the resize once after the first render to set the initial state
    handleResize();

    // Hook up the resize handler whenever the window is resized
    window.addEventListener('resize', handleResize);

    // Listen for subtree nodes being added or removed so we resize then.
    // This is necessary for when the content container children size is
    // dynamic (like on the configure route plus page - the content resizes
    // based on route plus being enabled/disabled)
    const observer = new MutationObserver((mutationRecords) => {
      if (!mutationRecords.some((record) => record.type === 'childList')) {
        return;
      }
      handleResize();
    });
    observer.observe(contentScrollContainerRef.current!, {
      childList: true,
      subtree: true,
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentScrollContainer} ref={contentScrollContainerRef}>
        <div className={classes.contentContainer} style={{ marginRight: `-${scrollbarWidth}px` }}>
          <Paper className={classes.content} {...otherProps}>
            {children}
          </Paper>
        </div>
        <div className={classes.spacer} />
      </div>
    </div>
  );
}
