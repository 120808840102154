import React, { FC, useState, useEffect } from 'react';
import emoji from 'react-easy-emoji';
import { Box, Popover } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '../../../../components/Typography';

import IconArrowLeft from '../../../../../../assets/icons/arrow-left';
import CloseIconHover from '../../../../../../assets/images/close-icon-hover.svg';
import Button from '../../../../components/Button';
import { LoadingToast } from '../../../../components/LoadingToast';
import {
  getNotificationsSettings,
  updateNotificationsSettings,
} from '../../../../../../services/notification_settings';

import { Descriptive } from './ReadyToShip.styles';
import ShippingNotification from '../ShippingNotification/ShippingNotification';
import MobilePreviewDraw from '../MobilePreview/Notification-ReadyToShip-Push-Preview.png';
import DesktopPreviewDraw from '../DesktopPreview/Notification-ReadyToShip-Email-Preview.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
    paper: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
  }),
);

type ReadyToShipProps = {
  active: boolean;
  history: {
    push(url: string): void;
  };
  preview: {
    desktop: boolean;
    mobile: boolean;
  };
  notificationSettings: any;
  userSession: any;
  location: any;
};

const ReadyToShip: FC<ReadyToShipProps> = (props: ReadyToShipProps) => {
  const classes = useStyles();
  const [active, setActive] = useState(props.active || false);
  const [notificationSettings, setNotificationSettings] = useState(
    props.notificationSettings || {
      settings: {
        shipping_ready: {
          active: false,
          delay_hours: 0,
        },
      },
    },
  );
  const [preview, setPreview] = useState(
    props.preview || {
      desktop: false,
      mobile: true,
      configure: false,
    },
  );
  const [notification, setNotification] = useState({
    active: false,
    error: false,
    message: '',
  });
  const [hideRouteProWidget, setHideRouteProWidget] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.notificationSettings) {
      const { notificationSettings: settings } = props.location.state;
      setNotificationSettings(settings);
    }
  }, [props.location]);

  useEffect(() => {
    if (notification.active) {
      setTimeout(() => {
        setNotification({
          ...notification,
          active: false,
          error: false,
        });
      }, 2500);
    }
  }, [notification]);

  const fetchNotifications = async () => {
    if (props?.userSession) {
      const merchant = props?.userSession.activeBrand;
      const response = await getNotificationsSettings('shipping_ready', merchant.id, merchant.prod_api_secret);
      setNotificationSettings(response);
      setActive(response.settings.shipping_ready.active);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const sendNotificationSettings = async (checked: boolean) => {
    if (props?.userSession) {
      const merchant = props?.userSession.activeBrand;
      await updateNotificationsSettings('shipping_ready', { active: checked }, merchant.id, merchant.prod_api_secret);
      setNotificationSettings({
        settings: {
          shipping_ready: {
            active: checked,
          },
        },
      });
    }
  };

  const sendToastNotification = (message: string): void => {
    setNotification({
      active: true,
      error: false,
      message,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <ShippingNotification
        title={'Ready To Ship'}
        description={
          'This notification lets your customers know that their package has a tracking number and is waiting to be picked up by the carrier.'
        }
        settings={[
          {
            field: 'shipping_ready',
            subfield: 'active',
            label: 'Sending',
            required: false,
            conditionMatch: true,
            onSwitch: (field: any, subfield: any, checked: any) => {
              const toastMessage = checked
                ? 'Ready To Ship Notifications have been turned ON 👏'
                : 'Ready To Ship Notifications have been turned OFF 👍';
              sendNotificationSettings(checked);
              sendToastNotification(toastMessage);
            },
          },
        ]}
        notificationSettings={notificationSettings}
        setNotificationSettings={setNotificationSettings}
        configure={{
          heading: 'Trigger',
          label: 'Tracking Number Generated',
          buttonLabel: 'Details',
          conditionMatch: true,
          render: () => (
            <>
              <div
                style={{
                  height: '100%',
                  padding: '40px 48px',
                  marginTop: '-20px',
                  borderRadius: 20,
                  width: 'calc(100% - 96px)',
                }}
              >
                <Typography variant="h4" component="h4">
                  Tracking Number Generated
                </Typography>
                <Descriptive>
                  This notification is triggered when a tracking number is generated for the shipment.
                </Descriptive>
              </div>
            </>
          ),
        }}
        userSession={props.userSession}
        history={props.history}
        preview={preview}
        previews={{
          mobile: MobilePreviewDraw,
          desktop: DesktopPreviewDraw,
        }}
        isLoading={loading}
      ></ShippingNotification>
      <LoadingToast active={notification.active} message={emoji(notification.message)} />
    </>
  );
};

export default ReadyToShip;
