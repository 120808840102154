import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  featuredImage: {
    maxWidth: '100%',
    textAlign: 'center',
    margin: '0 auto',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

const FeaturedImage = (props) => {
  const classes = useStyles();

  return (
    <img
      {...props}
      className={classes.featuredImage}
      alt="Featured Img"
    />
  );
};

export default FeaturedImage;
