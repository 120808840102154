import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Box } from '@material-ui/core/';
import '../../_shared/_shared.scss';
import './Dashboard.scss';
import moment from 'moment';
import { GrayBackground, MaxWidthContainer, HintText } from './Dashboard.styles';
import PageTitle from '../../_shared/PageTitle/PageTitle';
import DateRangePicker from '../../../_shared/DateRangePicker/DateRangePicker';
import ZipCodesByOrdersCard from '../dashboard/ZipCodesByOrdersCard/ZipCodesByOrdersCard';
import LineGraphDisplayWidget from './Graphs/LineGraphDisplayWidget';
// import NotFound from '../NotFound/NotFound'; TODO not found page is broken
import Card from '../../components/Card';
import { getMerchantStats } from '../../../../services/merchant_stats.ts';
import { formatSecToMin } from '../../utils/formatDates';
import { formatNumToCoin } from '../../utils/formatCoin';
import { colors } from '../../constants/styles';
import { graphTitles } from '../../constants';
import CardLabel from '../../components/CardLabel';
import Doughnut from '../../components/Doughnut';
import MetricList from '../../components/MetricList';
import MetricItem from '../../components/MetricItem';
import MetricBox from '../../components/MetricBox';
import CardHeader from '../../components/CardHeader';
import Tooltip from '../../components/Tooltip';
import IconInfo from '../../components/IconInfo';
import CardHeaderOutside from '../../components/CardHeaderOutside/CardHeaderOutside';
import OrderStatusMap from '../../components/OrderStatusMap';
import Typography from '../../components/Typography';
import ORDER_STATUS_DATA from './test_map_data.json'; // just for tests
import { DatepickerContainer, ErrorBoxOuterContainer, MetricBoxChart, MetricBoxColumn } from './Dashboard.styles';
import Button from '../../../Button/Button';
import Can from '../../components/Authentication/Can/Can';
import { AuthConsumer } from '../../hooks/authContext';
import ErrorBox from '../../components/ErrorBox';
import ErrorBoxIcon from '../../../../assets/images/error-box-icon.svg';
import Support from '../../../Portal/components/Support'
import GetRouteProLargeWidget from '../../components/GetRouteProLargeWidget';

const Dashboard = (props) => {
  // const [notFound, setNotFound] = useState(false); TODO not found page is broken
  const setupStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const setupEndDate = moment().add(1, 'days').format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(setupStartDate);
  const [endDate, setEndDate] = useState(setupEndDate);
  const [fixedStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [fixedEndDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
  const [totalOrders, setTotalOrders] = useState({ isLoading: true });
  const [totalRevenue, setTotalRevenue] = useState({ isLoading: true });
  const [protectedOrders, setProtectedOrders] = useState({ isLoading: true });
  const [protectedRevenue, setProtectedRevenue] = useState({ isLoading: true });
  const [claimsReordered, setClaimsReordered] = useState({ isLoading: true });
  const [reorderRevenue, setReorderRevenue] = useState({ isLoading: true });
  const [claimsRefunded, setClaimsRefunded] = useState({ isLoading: true });
  const [refundSavings, setRefundSavings] = useState({ isLoading: true });
  const [graphData, setGraphData] = useState({ isLoading: true });
  const [graphTitle, setGraphTitle] = useState('');
  const [showInfoOne, setShowInfoOne] = useState(false);
  const [showInfoTwo, setShowInfoTwo] = useState(false);
  const [showInfoThree, setShowInfoThree] = useState(false);
  const [showInfoFour, setShowInfoFour] = useState(false);
  const [shipmentCountsByZip, setShipmentCountsByZip] = useState({ isLoading: true });
  const [shipmentCountsByZipAndDay, setShipmentCountsByZipAndDay] = useState({ isLoading: true });

  // not filtered
  const [totalOrdersSnapshot, setTotalOrdersSnapshot] = useState({ isLoading: true });
  const [totalOrdersReadyToShip, setTotalOrdersReadyToShip] = useState({ isLoading: true });
  const [totalOrdersInTransit, setTotalOrdersInTransit] = useState({ isLoading: true });
  const [totalOrdersOutForDelivery, setTotalOrdersOutForDelivery] = useState({ isLoading: true });
  const [totalOrdersAvailableForPickup, setTotalOrdersAvailableForPickup] = useState({
    isLoading: true,
  });
  const [totalOrdersDelivered, setTotalOrdersDelivered] = useState({ isLoading: true });
  const [totalOrdersAttemptFail, setTotalOrdersAttemptFail] = useState({ isLoading: true });

  // filtered by zip (top 100 locations)
  const [filteredShipmentCountsByZip, setFilteredShipmentCountsByZip] = useState({
    isLoading: true,
  });
  const [filteredOrdersReadyToShip, setFilteredOrdersReadyToShip] = useState({ isLoading: true });
  const [filteredOrdersInTransit, setFilteredOrdersInTransit] = useState({ isLoading: true });
  const [filteredOrdersOutForDelivery, setFilteredOrdersOutForDelivery] = useState({
    isLoading: true,
  });
  const [filteredOrdersAvailableForPickup, setFilteredOrdersAvailableForPickup] = useState({
    isLoading: true,
  });
  const [filteredOrdersDelivered, setFilteredOrdersDelivered] = useState({ isLoading: true });
  const [filteredOrdersAttemptFail, setFilteredOrdersAttemptFail] = useState({ isLoading: true });

  const [showOrdersReadyToShip, setShowOrdersReadyToShip] = useState(true);
  const [showOrdersInTransit, setShowOrdersInTransit] = useState(true);
  const [showOrdersOutForDelivery, setShowOrdersOutForDelivery] = useState(true);
  const [showOrdersAvailableForPickup, setShowOrdersAvailableForPickup] = useState(true);
  const [showOrdersDelivered, setShowOrdersDelivered] = useState(true);
  const [showOrdersAttemptFail, setShowOrdersAttemptFail] = useState(true);
  const [graphTitleOne, setGraphTitleOne] = useState('');
  const [graphDataOne, setGraphDataOne] = useState({ isLoading: true });
  const [totalOrdersTrackedInApp, setTotalOrdersTrackedInApp] = useState({ isLoading: true }); // total_orders_and_orders_tracked_in_app
  const [avgSessionsPerOrder, setAvgSessionsPerOrder] = useState({ isLoading: true }); // avg_sessions_per_order
  const [avgDurationPerOrder, setAvgDurationPerOrder] = useState({ isLoading: true }); // avg_session_duration_in_sec_per_order
  const [totalSales, setTotalSales] = useState({ isLoading: true }); // total_sales
  const [avgSalesValue, setAvgSalesValue] = useState({ isLoading: true }); // average_sales_value
  const [totalClicksToMerchantSites, setTotalClicksToMerchantSites] = useState({ isLoading: true }); // num_of_click_to_web
  const [totalSalesValue, setTotalSalesValue] = useState({ isLoading: true }); // total_sales_value
  const [shippingErrorBox, setShippingErrorBox] = useState(false);

  const graphTitlesOne = {
    TOTAL_ORERS_BY_DAY: 'Total Orders by Day',
    TOTAL_ORDERS_TRACKED_IN_APP_BY_DAY: 'Total Orders Tracked In App by Day',
    AVERAGE_SESSIONS_PER_ORDER_BY_DAY: 'Average sessions per order by Day',
    AVERAGE_TIME_ON_APP_PER_USER_BY_DAY: 'Average time on app per user by Day',
    TOTAL_SALES_BY_DAY: 'Total Sales by Day',
    AVERAGE_ORDER_VALUE_BY_DAY: 'Average Order Value by Day',
    TOTAL_CLICKS_TO_MERCHANTS_SITE_BY_DAY: 'Total Clicks (to Merchant’s Site/s) by Day',
    TOTAL_SALES_VALUE_BY_DAY: 'Total Sales Value by Day',
  };

  const mountPercentage = (num, total) => Math.round((num * 100) / total);

  const mountMetric = (num, total) => `${num.toLocaleString()} (${num ? mountPercentage(num, total) : 0}%)`;

  const filterObjByZip = (obj) => {
    const filtered = obj.filter((item) => item?.LONGITUDE && item?.LATITUDE);
    return filtered;
  };

  const hasLongLat = (item) => !!(item?.LONGITUDE && item?.LATITUDE);

  useEffect(() => {
    setShipmentCountsByZip({ isLoading: true });

    setTotalOrdersSnapshot({ isLoading: true });
    setTotalOrdersReadyToShip({ isLoading: true });
    setTotalOrdersInTransit({ isLoading: true });
    setTotalOrdersOutForDelivery({ isLoading: true });
    setTotalOrdersAvailableForPickup({ isLoading: true });
    setTotalOrdersDelivered({ isLoading: true });
    setTotalOrdersAttemptFail({ isLoading: true });

    setFilteredOrdersReadyToShip({ isLoading: true });
    setFilteredOrdersInTransit({ isLoading: true });
    setFilteredOrdersOutForDelivery({ isLoading: true });
    setFilteredOrdersAvailableForPickup({ isLoading: true });
    setFilteredOrdersDelivered({ isLoading: true });
    setFilteredOrdersAttemptFail({ isLoading: true });

    let unmounted = false;
    const source = axios.CancelToken.source();

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      fixedStartDate,
      fixedEndDate,
      'shipment_counts_by_zip',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setShipmentCountsByZip({ data: [...res.data], isLoading: false });
          // setShipmentCountsByZip({ data: ORDER_STATUS_DATA, isLoading: false }); // just for test
        }
      },
      (err) => {
        setShipmentCountsByZip({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    // return cleanup function, this is called when the component unmounts, here we will cancel pending requests
    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, []);

  useEffect(() => {
    setGraphData({ lineData: [], labels: [], isLoading: true });
    setTotalOrders({ isLoading: true });
    setTotalRevenue({ isLoading: true });
    setProtectedOrders({ isLoading: true });
    setProtectedRevenue({ isLoading: true });
    setClaimsReordered({ isLoading: true });
    setReorderRevenue({ isLoading: true });
    setClaimsRefunded({ isLoading: true });
    setRefundSavings({ isLoading: true });

    setShipmentCountsByZipAndDay({ isLoading: true });

    setGraphTitleOne('');
    setGraphDataOne({ lineData: [], labels: [], isLoading: true });
    setTotalOrdersTrackedInApp({ isLoading: true });
    setAvgSessionsPerOrder({ isLoading: true });
    setAvgDurationPerOrder({ isLoading: true });
    setTotalSales({ isLoading: true });
    setAvgSalesValue({ isLoading: true });
    setTotalClicksToMerchantSites({ isLoading: true });
    setTotalSalesValue({ isLoading: true });

    let unmounted = false;
    const source = axios.CancelToken.source();

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalOrders({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setTotalOrders({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_revenue_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalRevenue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setTotalRevenue({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'protected_orders_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setProtectedOrders({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setProtectedOrders({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'protected_revenue_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setProtectedRevenue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setProtectedRevenue({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'claims_reordered_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setClaimsReordered({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setClaimsReordered({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'reorder_revenue_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setReorderRevenue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setReorderRevenue({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'claims_refunded_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setClaimsRefunded({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setClaimsRefunded({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );
    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'refund_savings_by_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setRefundSavings({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setRefundSavings({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'top_shipment_revenue_by_zip_and_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setShipmentCountsByZipAndDay({ data: { ...res.data }, isLoading: false });
        }
      },
      (err) => {
        setShipmentCountsByZipAndDay({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_and_orders_tracked_in_app',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalOrdersTrackedInApp({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setTotalOrdersTrackedInApp({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_sessions_per_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setAvgSessionsPerOrder({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setAvgSessionsPerOrder({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_session_duration_in_sec_per_order',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setAvgDurationPerOrder({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setAvgDurationPerOrder({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalSales({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setTotalSales({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'average_sales_value',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setAvgSalesValue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setAvgSalesValue({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_click_to_web',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalClicksToMerchantSites({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setTotalClicksToMerchantSites({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales_value',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          setTotalSalesValue({ ...res.data, isLoading: false });
        }
      },
      (err) => {
        setTotalSalesValue({ isLoading: false });
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    // return cleanup function, this is called when the component unmounts, here we will cancel pending requests
    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [startDate, endDate]);

  useEffect(() => {
    const { data, isLoading } = shipmentCountsByZip;
    let count = 0;
    let countTotalOrdersReadyToShip = 0;
    let countTotalOrdersInTransit = 0;
    let countTotalOrdersOutForDelivery = 0;
    let countTotalOrdersAvailableForPickup = 0;
    let countTotalOrdersDelivered = 0;
    let countTotalOrdersAttemptFail = 0;

    let countFiltered = 0;
    let countFilteredOrdersReadyToShip = 0;
    let countFilteredOrdersInTransit = 0;
    let countFilteredOrdersOutForDelivery = 0;
    let countFilteredOrdersAvailableForPickup = 0;
    let countFilteredOrdersDelivered = 0;
    let countFilteredOrdersAttemptFail = 0;

    if (!isLoading && data) {
      setShippingErrorBox(data.length <= 0);
      for (let i = 0; i < data.length; i++) {
        switch (data[i]?.DELIVERY_STATUS) {
          case 'InfoReceived':
          case 'Pending':
            countTotalOrdersReadyToShip += data[i]?.COUNT;
            count += data[i]?.COUNT;
            if (hasLongLat(data[i])) {
              countFilteredOrdersReadyToShip += data[i]?.COUNT;
              countFiltered += data[i]?.COUNT;
            }
            break;
          case 'InTransit':
            countTotalOrdersInTransit += data[i]?.COUNT;
            count += data[i]?.COUNT;
            if (hasLongLat(data[i])) {
              countFilteredOrdersInTransit += data[i]?.COUNT;
              countFiltered += data[i]?.COUNT;
            }
            break;
          case 'OutForDelivery':
            countTotalOrdersOutForDelivery += data[i]?.COUNT;
            count += data[i]?.COUNT;
            if (hasLongLat(data[i])) {
              countFilteredOrdersOutForDelivery += data[i]?.COUNT;
              countFiltered += data[i]?.COUNT;
            }
            break;
          case 'AvailableForPickup':
            countTotalOrdersAvailableForPickup += data[i]?.COUNT;
            count += data[i]?.COUNT;
            if (hasLongLat(data[i])) {
              countFilteredOrdersAvailableForPickup += data[i]?.COUNT;
              countFiltered += data[i]?.COUNT;
            }
            break;
          case 'Delivered':
            countTotalOrdersDelivered += data[i]?.COUNT;
            count += data[i]?.COUNT;
            if (hasLongLat(data[i])) {
              countFilteredOrdersDelivered += data[i]?.COUNT;
              countFiltered += data[i]?.COUNT;
            }
            break;
          case 'AttemptFail':
            countTotalOrdersAttemptFail += data[i]?.COUNT;
            count += data[i]?.COUNT;
            if (hasLongLat(data[i])) {
              countFilteredOrdersAttemptFail += data[i]?.COUNT;
              countFiltered += data[i]?.COUNT;
            }
            break;
          default:
            break;
        }
      }

      setTotalOrdersSnapshot({
        data: {
          total: count,
          metrics: {
            ReadyToShip: countTotalOrdersReadyToShip,
            InTransit: countTotalOrdersInTransit,
            OutForDelivery: countTotalOrdersOutForDelivery,
            AvailableForPickup: countTotalOrdersAvailableForPickup,
            Delivered: countTotalOrdersDelivered,
            AttemptFail: countTotalOrdersAttemptFail,
          },
        },
        isLoading: false,
      });

      setTotalOrdersReadyToShip({
        data: mountMetric(countTotalOrdersReadyToShip, count),
        isLoading: false,
      });
      setTotalOrdersInTransit({ data: mountMetric(countTotalOrdersInTransit, count), isLoading: false });
      setTotalOrdersOutForDelivery({
        data: mountMetric(countTotalOrdersOutForDelivery, count),
        isLoading: false,
      });
      setTotalOrdersAvailableForPickup({
        data: mountMetric(countTotalOrdersAvailableForPickup, count),
        isLoading: false,
      });
      setTotalOrdersDelivered({ data: mountMetric(countTotalOrdersDelivered, count), isLoading: false });
      setTotalOrdersAttemptFail({
        data: mountMetric(countTotalOrdersAttemptFail, count),
        isLoading: false,
      });

      //
      setFilteredShipmentCountsByZip({
        data: filterObjByZip(shipmentCountsByZip.data),
        isLoading: false,
      });
      setFilteredOrdersReadyToShip({
        data: mountMetric(countFilteredOrdersReadyToShip, countFiltered),
        isLoading: false,
      });
      setFilteredOrdersInTransit({
        data: mountMetric(countFilteredOrdersInTransit, countFiltered),
        isLoading: false,
      });
      setFilteredOrdersOutForDelivery({
        data: mountMetric(countFilteredOrdersOutForDelivery, countFiltered),
        isLoading: false,
      });
      setFilteredOrdersAvailableForPickup({
        data: mountMetric(countFilteredOrdersAvailableForPickup, countFiltered),
        isLoading: false,
      });
      setFilteredOrdersDelivered({
        data: mountMetric(countFilteredOrdersDelivered, countFiltered),
        isLoading: false,
      });
      setFilteredOrdersAttemptFail({
        data: mountMetric(countFilteredOrdersAttemptFail, countFiltered),
        isLoading: false,
      });
    }
  }, [shipmentCountsByZip]);

  const syncGraphWithSelectedData = () => {
    switch (graphTitle) {
      case graphTitles.TOTAL_ORERS_BY_DAY:
        setGraphData(JSON.parse(JSON.stringify(totalOrders.graphData || { isLoading: true })));
        break;
      case graphTitles.PROTECTED_ORDERS_BY_DAY:
        setGraphData(JSON.parse(JSON.stringify(protectedOrders.graphData || { isLoading: true })));
        break;
      case graphTitles.CLAIMS_REORDERED_BY_DAY:
        setGraphData(JSON.parse(JSON.stringify(claimsReordered.graphData || { isLoading: true })));
        break;
      case graphTitles.REORDER_REVENUE_BY_DAY:
        setGraphData(JSON.parse(JSON.stringify(reorderRevenue.graphData || { isLoading: true })));
        break;
      case graphTitles.CLAIMS_REFUNDED_BY_DAY:
        setGraphData(JSON.parse(JSON.stringify(claimsRefunded.graphData || { isLoading: true })));
        break;
      case graphTitles.REFUNDED_SAVING_BY_DAY:
        setGraphData(JSON.parse(JSON.stringify(refundSavings.graphData || { isLoading: true })));
        break;
      default:
    }
  };

  // make the line graph listen and update based on changes to the data
  useEffect(() => {
    syncGraphWithSelectedData();
  }, [
    totalOrders,
    totalRevenue,
    protectedOrders,
    protectedRevenue,
    claimsReordered,
    reorderRevenue,
    claimsRefunded,
    refundSavings,
    graphTitle,
  ]);

  const onSelectDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleTotalOrdersClicked = () => {
    if (graphTitle === graphTitles.TOTAL_ORERS_BY_DAY) {
      setGraphTitle('');
    } else {
      setGraphTitle(graphTitles.TOTAL_ORERS_BY_DAY);
    }
  };
  const handleProtectedOrdersClicked = () => {
    if (graphTitle === graphTitles.PROTECTED_ORDERS_BY_DAY) {
      setGraphTitle('');
    } else {
      setGraphTitle(graphTitles.PROTECTED_ORDERS_BY_DAY);
    }
  };
  const handleClaimsReorderedClicked = () => {
    setGraphTitle(graphTitles.CLAIMS_REORDERED_BY_DAY);
  };
  const handleReorderRevenueClicked = () => {
    setGraphTitle(graphTitles.REORDER_REVENUE_BY_DAY);
  };
  const handleClaimsRefundedClicked = () => {
    setGraphTitle(graphTitles.CLAIMS_REFUNDED_BY_DAY);
  };
  const handleRefundSavingsClicked = () => {
    setGraphTitle(graphTitles.REFUNDED_SAVING_BY_DAY);
  };

  const handleTotalOrdersInAppClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.TOTAL_ORERS_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_and_orders_tracked_in_app_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].orders);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalOrdersTrackedInAppClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.TOTAL_ORDERS_TRACKED_IN_APP_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_orders_and_orders_tracked_in_app_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].tracked_orders);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleAvgSessionsPerOrderClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.AVERAGE_SESSIONS_PER_ORDER_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_sessions_per_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].date);
            graphData.dailyAmounts.push(result[i].average);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleAvgDurationPerOrderClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.AVERAGE_TIME_ON_APP_PER_USER_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'avg_session_duration_in_sec_per_order_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].date);
            graphData.dailyAmounts.push(result[i].average);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalSalesClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.TOTAL_SALES_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].count);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleAvgSalesValueClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.AVERAGE_ORDER_VALUE_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'average_sales_value_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalClicksToMerchantSitesClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.TOTAL_CLICKS_TO_MERCHANTS_SITE_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'num_of_click_to_web_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].count);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const handleTotalSalesValueClicked = () => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    setGraphTitleOne(graphTitlesOne.TOTAL_SALES_VALUE_BY_DAY);
    setGraphDataOne({ isLoading: true });

    getMerchantStats(
      props.userSession && props.userSession.activeBrand.id,
      props.userSession && props.userSession.activeBrand.prod_api_secret,
      startDate,
      endDate,
      'total_sales_value_per_day',
      source.token,
    ).then(
      (res) => {
        if (!unmounted) {
          const result = { ...res.data };
          const keys = Object.keys(result);

          const graphData = { labels: [], dailyAmounts: [] };
          for (let i = 0; i < keys.length; i++) {
            graphData.labels.push(result[i].day);
            graphData.dailyAmounts.push(result[i].amount);
          }

          setGraphDataOne(graphData || { isLoading: true });
        }
      },
      (err) => {
        if (!axios.isCancel(err)) {
          console.log(err.message);
        }
      },
    );

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  };

  const onClearDateRange = () => {
    onSelectDate(setupStartDate, setupEndDate);
  };

  const onFeedbackClick = () => {
    window.open('https://routeapp.typeform.com/to/TusKPA', '_blank');
  };

  const onSupportClick = () => {
    window.open('https://help.route.com/hc/en-us/requests/new?ticket_form_id=360001446393', '_blank');
  };

  return (
    <AuthConsumer>
      {({ tier, merchantRole }) => (
        <div>
          <div className="portal-content-container dashboard">
            <MaxWidthContainer>
              <PageTitle title="Dashboard" />
              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    display: 'block',
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#EEEEEE',
                    marginTop: '25px',
                    marginBottom: '8px',
                  }}
                ></div>
                {shippingErrorBox ? (
                  <ErrorBoxOuterContainer>
                    <ErrorBox
                      bgColor="#333333"
                      label="Beep, boop, we need data..."
                      description="Looks like we don’t have any data to show yet. Please check back in a couple of hours to view these metrics. "
                      icon={ErrorBoxIcon}
                      close={() => setShippingErrorBox(false)}
                      marginTop="27%"
                    />
                  </ErrorBoxOuterContainer>
                ) : (
                  ''
                )}
                <Support />
                <div>
                  <HintText>
                    Shipping Status Insights (shows data from the past 7 days)
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 1,
                        marginLeft: 8,
                      }}
                    >
                      <Typography variant="h8" component="h4" color="darkGray">
                        *This is updated daily, and does not include shipping data for the current day
                      </Typography>
                    </div>
                  </HintText>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={12} sm={12}>
                      <Box mb={4}>
                        <Card isLoading={totalOrdersSnapshot.isLoading}>
                          <CardHeader>
                            <CardHeaderOutside>
                              <CardLabel>Shipping Statuses</CardLabel>
                              <Tooltip
                                wrapper={<IconInfo />}
                                overlay={
                                  <>
                                    <Typography variant="p">
                                      This is updated daily, and does not include shipping data for the current day.
                                    </Typography>
                                  </>
                                }
                              />
                            </CardHeaderOutside>
                          </CardHeader>
                          <div style={{ opacity: shippingErrorBox ? '0.24' : null }}>
                            <div
                              style={{
                                marginBottom: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Doughnut
                                text="Total Number"
                                total={totalOrdersSnapshot.data?.total || '-'}
                                metrics={
                                  totalOrdersSnapshot.data?.metrics || {
                                    ReadyToShip: 0,
                                    InTransit: 0,
                                    OutForDelivery: 0,
                                    AvailableForPickup: 0,
                                    Delivered: 0,
                                    AttemptFail: 0,
                                  }
                                }
                                isLoading={totalOrdersSnapshot.isLoading}
                              />
                            </div>
                            <MetricList>
                              <MetricItem
                                text="Ready To Ship"
                                isLoading={totalOrdersReadyToShip.isLoading}
                                value={totalOrdersReadyToShip.data || '00,000 (00%)'}
                                color="#FFBB23"
                              />
                              <MetricItem
                                text="In Transit"
                                isLoading={totalOrdersInTransit.isLoading}
                                value={totalOrdersInTransit.data || '00,000 (00%)'}
                                color="#14869F"
                              />
                              <MetricItem
                                text="Out For Delivery"
                                isLoading={totalOrdersOutForDelivery.isLoading}
                                value={totalOrdersOutForDelivery.data || '00,000 (00%)'}
                                color="#983677"
                              />
                              <MetricItem
                                text="Available For Pickup"
                                isLoading={totalOrdersAvailableForPickup.isLoading}
                                value={totalOrdersAvailableForPickup.data || '00,000 (00%)'}
                                color="#728E9D"
                              />
                              <MetricItem
                                text="Delivered"
                                isLoading={totalOrdersDelivered.isLoading}
                                value={totalOrdersDelivered.data || '00,000 (00%)'}
                                color="#5DC8DB"
                              />
                              <MetricItem
                                text="Delivery Attempted"
                                isLoading={totalOrdersAttemptFail.isLoading}
                                value={totalOrdersAttemptFail.data || '00,000 (00%)'}
                                color="#CA116A"
                              />
                            </MetricList>
                          </div>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item lg={8} md={12} sm={12} mb={2}>
                      <Box mb={4}>
                        <Card
                          isLoading={
                            shipmentCountsByZip.isLoading ||
                            totalOrdersReadyToShip.isLoading ||
                            totalOrdersInTransit.isLoading ||
                            totalOrdersOutForDelivery.isLoading ||
                            totalOrdersAttemptFail.isLoading ||
                            totalOrdersDelivered.isLoading ||
                            totalOrdersAvailableForPickup.isLoading
                          }
                        >
                          <CardHeader>
                            <CardLabel>Shipping Statuses By Zip Code (Top 100 Locations)</CardLabel>
                          </CardHeader>
                          <div style={{ opacity: shippingErrorBox ? '0.24' : null }}>
                            <OrderStatusMap
                              shipmentCountsByZip={filteredShipmentCountsByZip.data || []}
                              showOrdersReadyToShip={showOrdersReadyToShip}
                              showOrdersInTransit={showOrdersInTransit}
                              showOrdersOutForDelivery={showOrdersOutForDelivery}
                              showOrdersAvailableForPickup={showOrdersAvailableForPickup}
                              showOrdersDelivered={showOrdersDelivered}
                              showOrdersAttemptFail={showOrdersAttemptFail}
                            />
                            <div
                              style={{
                                display: 'block',
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#F7F7F7',
                                marginTop: '30px',
                                marginBottom: '30px',
                              }}
                            ></div>
                            <Grid container spacing={3}>
                              <Grid item lg={4} md={4} sm={12}>
                                <MetricItem
                                  text="Ready To Ship"
                                  isLoading={filteredOrdersReadyToShip.isLoading}
                                  value={filteredOrdersReadyToShip.data || '00,000 (00%)'}
                                  color="#FFBB23"
                                  hasCheck
                                  isChecked={showOrdersReadyToShip}
                                  handleCheck={() => setShowOrdersReadyToShip(!showOrdersReadyToShip)}
                                />
                                <MetricItem
                                  text="In Transit"
                                  isLoading={filteredOrdersInTransit.isLoading}
                                  value={filteredOrdersInTransit.data || '00,000 (00%)'}
                                  color="#14869F"
                                  hasCheck
                                  isChecked={showOrdersInTransit}
                                  handleCheck={() => setShowOrdersInTransit(!showOrdersInTransit)}
                                />
                              </Grid>
                              <Grid item lg={4} md={4} sm={12}>
                                <MetricItem
                                  text="Out For Delivery"
                                  isLoading={filteredOrdersOutForDelivery.isLoading}
                                  value={filteredOrdersOutForDelivery.data || '00,000 (00%)'}
                                  color="#983677"
                                  hasCheck
                                  isChecked={showOrdersOutForDelivery}
                                  handleCheck={() => setShowOrdersOutForDelivery(!showOrdersOutForDelivery)}
                                />
                                <MetricItem
                                  text="Available For Pickup"
                                  isLoading={filteredOrdersAvailableForPickup.isLoading}
                                  value={filteredOrdersAvailableForPickup.data || '00,000 (00%)'}
                                  color="#728E9D"
                                  hasCheck
                                  isChecked={showOrdersAvailableForPickup}
                                  handleCheck={() => setShowOrdersAvailableForPickup(!showOrdersAvailableForPickup)}
                                />
                              </Grid>
                              <Grid item lg={4} md={4} sm={12}>
                                <MetricItem
                                  text="Delivered"
                                  isLoading={filteredOrdersDelivered.isLoading}
                                  value={filteredOrdersDelivered.data || '00,000 (00%)'}
                                  color="#5DC8DB"
                                  hasCheck
                                  isChecked={showOrdersDelivered}
                                  handleCheck={() => setShowOrdersDelivered(!showOrdersDelivered)}
                                />
                                <MetricItem
                                  text="Delivery Attempted"
                                  isLoading={filteredOrdersAttemptFail.isLoading}
                                  value={filteredOrdersAttemptFail.data || '00,000 (00%)'}
                                  color="#CA116A"
                                  hasCheck
                                  isChecked={showOrdersAttemptFail}
                                  handleCheck={() => setShowOrdersAttemptFail(!showOrdersAttemptFail)}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EEEEEE',
                      marginTop: '25px',
                      marginBottom: '25px',
                    }}
                  ></div>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12}>
                      <Box mb={4}>
                        <DatepickerContainer>
                          <DateRangePicker
                            title="Date Range:"
                            placeholder="Last 30 Days"
                            startDate={props.userSession.activeBrand.created_on || moment().subtract(1, 'week')}
                            onSelectDate={onSelectDate}
                            onClean={onClearDateRange}
                          ></DateRangePicker>
                        </DatepickerContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xs={12} mb={3} spacing={4}>
                    <Box mb={4} mt={-1}>
                      <Card isLoading={totalOrders.isLoading || protectedOrders.isLoading}>
                        <CardHeader>
                          <CardHeaderOutside>
                            <CardLabel>Orders</CardLabel>
                            <Tooltip
                              wrapper={<IconInfo />}
                              overlay={
                                <>
                                  <Typography variant="p">
                                    Total Orders - a count of orders made during the specified time frame.
                                  </Typography>
                                  <br />
                                  <br />
                                  <Typography variant="p">
                                    Protected Orders - a count of orders made during the specified time frame where
                                    Route was purchased.
                                  </Typography>
                                </>
                              }
                            ></Tooltip>
                          </CardHeaderOutside>
                        </CardHeader>
                        <Grid
                          container
                          spacing={3}
                          style={{
                            maxWidth: '1024px',
                            justifyContent: 'center',
                            margin: '-5px auto 20px auto',
                            alignItems: 'center',
                          }}
                        >
                          <MetricBoxChart>
                            <Doughnut
                              text={'Total Orders'}
                              total={totalOrders.total || 0}
                              metrics={{
                                Orders: totalOrders.total || 0,
                                ProtectedOrders: protectedOrders.total || 0,
                              }}
                              isLoading={protectedOrders.isLoading || totalOrders.isLoading}
                              height={173}
                              width={173}
                            />
                          </MetricBoxChart>
                          <MetricBoxColumn>
                            <MetricBox
                              text="Protected Orders"
                              value={(protectedOrders.total || 0).toLocaleString()}
                              total={totalOrders.total || 0}
                              isLoading={protectedOrders.isLoading}
                              color="#5DC8DB"
                              hasGraph
                              handleGraphPress={handleProtectedOrdersClicked}
                              isActive={graphTitle === graphTitles.PROTECTED_ORDERS_BY_DAY}
                              hasPercentage
                            />
                          </MetricBoxColumn>
                          <MetricBoxColumn>
                            <MetricBox
                              text="Total Orders"
                              color="#B52082"
                              value={(totalOrders.total || 0).toLocaleString()}
                              total={totalOrders.total || 0}
                              isLoading={totalOrders.isLoading}
                              hasGraph
                              handleGraphPress={handleTotalOrdersClicked}
                              isActive={graphTitle === graphTitles.TOTAL_ORERS_BY_DAY}
                            />
                          </MetricBoxColumn>
                        </Grid>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>

                {graphTitle && (
                  <Grid item lg={12} md={12} xs={12} mb={3} spacing={4}>
                    <Box mb={4}>
                      <LineGraphDisplayWidget
                        graphTitle={graphTitle}
                        graphData={graphData}
                        onClose={() => {
                          setGraphTitle('');
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12}>
                    <Box mb={4}>
                      <ZipCodesByOrdersCard shipmentCountsByZipAndDay={shipmentCountsByZipAndDay} />
                    </Box>
                  </Grid>
                </Grid>

                {/* PPI */}
                <Grid container direction="row" justify={'flex-end'} alignItems={'center'}>
                  <Box mb={2} mt={4}>
                    <Typography variant="h8" component="h4" color="gray">
                      *Data could be delayed up to 24 hours.
                    </Typography>
                  </Box>
                </Grid>

                <Can
                  role={merchantRole}
                  services={tier}
                  perform="shipping_insights"
                  yes={() => (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          marginTop: 18,
                        }}
                      >
                        <Grid item lg={3} md={6} sm={12}>
                          <Box mb={4}>
                            <CardHeaderOutside>
                              <CardLabel>Orders Tracked In App</CardLabel>
                              <Tooltip
                                wrapper={<IconInfo />}
                                overlay={
                                  <>
                                    <Typography variant="p">Engagement with the App per order.</Typography>
                                    <br />
                                    <br />
                                    <Typography variant="p">
                                      umber of orders customers have placed that are using the App.
                                    </Typography>
                                  </>
                                }
                              ></Tooltip>
                            </CardHeaderOutside>
                            <Card
                              isLoading={totalOrdersTrackedInApp.isLoading || totalOrdersTrackedInApp.tracked_orders}
                            >
                              <MetricList>
                                <MetricItem
                                  text="Total Orders"
                                  value={(totalOrdersTrackedInApp.orders || 0).toLocaleString()}
                                  isLoading={totalOrdersTrackedInApp.isLoading}
                                  color={colors.darkBlue}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.TOTAL_ORERS_BY_DAY && handleTotalOrdersInAppClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.TOTAL_ORERS_BY_DAY}
                                />
                                <MetricItem
                                  text="Total Orders Tracked In App"
                                  value={
                                    `${(totalOrdersTrackedInApp.tracked_orders || 0).toLocaleString()} (${
                                      totalOrdersTrackedInApp.percentage
                                    }%)` || '00,000 (00%)'
                                  }
                                  isLoading={totalOrdersTrackedInApp.isLoading}
                                  color={colors.blue}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.TOTAL_ORDERS_TRACKED_IN_APP_BY_DAY &&
                                    handleTotalOrdersTrackedInAppClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.TOTAL_ORDERS_TRACKED_IN_APP_BY_DAY}
                                />
                              </MetricList>
                            </Card>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12}>
                          <Box mb={4}>
                            <CardHeaderOutside>
                              <CardLabel>Average Session Length Per User</CardLabel>
                              <Tooltip
                                wrapper={<IconInfo />}
                                overlay={
                                  <>
                                    <Typography variant="p">
                                      Average number of times and duration a user opens the Route App to check on their
                                      order status.
                                    </Typography>
                                    <br />
                                  </>
                                }
                              ></Tooltip>
                            </CardHeaderOutside>
                            <Card isLoading={avgSessionsPerOrder.isLoading || avgDurationPerOrder.isLoading}>
                              <MetricList>
                                <MetricItem
                                  text="Average sessions per order"
                                  value={(avgSessionsPerOrder.average || 0).toFixed(2) || '0.0'}
                                  isLoading={avgSessionsPerOrder.isLoading}
                                  color={colors.blue}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.AVERAGE_SESSIONS_PER_ORDER_BY_DAY &&
                                    handleAvgSessionsPerOrderClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.AVERAGE_SESSIONS_PER_ORDER_BY_DAY}
                                />
                                <MetricItem
                                  text="Average time on app per user"
                                  value={formatSecToMin(avgDurationPerOrder.average || 0) || '00:00'}
                                  isLoading={avgDurationPerOrder.isLoading}
                                  color={colors.darkBlue}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.AVERAGE_TIME_ON_APP_PER_USER_BY_DAY &&
                                    handleAvgDurationPerOrderClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.AVERAGE_TIME_ON_APP_PER_USER_BY_DAY}
                                />
                              </MetricList>
                            </Card>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12}>
                          <Box mb={4}>
                            <CardHeaderOutside>
                              <CardLabel>Total Route App Attributed Sales</CardLabel>
                              <Tooltip
                                wrapper={<IconInfo />}
                                overlay={
                                  <>
                                    <Typography variant="p">
                                      Total app value. Total sales and average order value tracked through the app.
                                    </Typography>
                                    <br />
                                  </>
                                }
                              ></Tooltip>
                            </CardHeaderOutside>
                            <Card isLoading={totalSales.isLoading || avgSalesValue.isLoading}>
                              <MetricList>
                                <MetricItem
                                  text="Total Sales"
                                  value={totalSales.count || '0'}
                                  isLoading={totalSales.isLoading}
                                  color={colors.blue}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.TOTAL_SALES_BY_DAY && handleTotalSalesClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.TOTAL_SALES_BY_DAY}
                                />
                                <MetricItem
                                  text="Average Order Value"
                                  value={formatNumToCoin(avgSalesValue.amount || 0) || '$0.00'}
                                  isLoading={avgSalesValue.isLoading}
                                  color={colors.green}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.AVERAGE_ORDER_VALUE_BY_DAY &&
                                    handleAvgSalesValueClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.AVERAGE_ORDER_VALUE_BY_DAY}
                                />
                              </MetricList>
                            </Card>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={6} sm={12}>
                          <Box mb={4}>
                            <CardHeaderOutside>
                              <CardLabel>Total App Value</CardLabel>
                              <Tooltip
                                wrapper={<IconInfo />}
                                overlay={
                                  <>
                                    <Typography variant="p">
                                      Click-through rate to your website from a Route link and associated sales within
                                      24 hours.
                                    </Typography>
                                    <br />
                                  </>
                                }
                              ></Tooltip>
                            </CardHeaderOutside>
                            <Card isLoading={totalClicksToMerchantSites.isLoading || totalSalesValue.isLoading}>
                              <MetricList>
                                <MetricItem
                                  text="Total Clicks (to Merchant’s Site/s)"
                                  value={(totalClicksToMerchantSites.count || 0).toLocaleString() || '00,000'}
                                  isLoading={totalClicksToMerchantSites.isLoading}
                                  color={colors.blue}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.TOTAL_CLICKS_TO_MERCHANTS_SITE_BY_DAY &&
                                    handleTotalClicksToMerchantSitesClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.TOTAL_CLICKS_TO_MERCHANTS_SITE_BY_DAY}
                                />
                                <MetricItem
                                  text="Total Sales Value"
                                  value={formatNumToCoin(totalSalesValue.amount || 0) || '$0.00'}
                                  isLoading={totalSalesValue.isLoading}
                                  color={colors.green}
                                  hasGraph
                                  handleGraphPress={
                                    graphTitleOne !== graphTitlesOne.TOTAL_SALES_VALUE_BY_DAY &&
                                    handleTotalSalesValueClicked
                                  }
                                  isActive={graphTitleOne === graphTitlesOne.TOTAL_SALES_VALUE_BY_DAY}
                                />
                              </MetricList>
                            </Card>
                          </Box>
                        </Grid>
                      </Grid>

                      {graphTitleOne && (
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12}>
                            <Box mb={4}>
                              <LineGraphDisplayWidget
                                graphTitle={graphTitleOne}
                                graphData={graphDataOne}
                                onClose={() => {
                                  setGraphTitleOne('');
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container direction="row" justify={'flex-end'} alignItems={'center'}>
                        <Button
                          text="View More Customer Engagement Insights"
                          hoverState={true}
                          href={'/insights'}
                          style={{ borderRadius: 40, paddingLeft: 32, paddingRight: 32, marginBottom: 40 }}
                        />
                      </Grid>
                    </>
                  )}
                  no={() => (
                    <>
                      <GetRouteProLargeWidget {...props} />
                    </>
                  )}
                />
              </div>
            </MaxWidthContainer>
          </div>
          {showInfoOne || showInfoTwo || showInfoThree || showInfoFour ? (
            <GrayBackground
              onClick={() => {
                setShowInfoOne(false);
                setShowInfoTwo(false);
                setShowInfoThree(false);
                setShowInfoFour(false);
              }}
            ></GrayBackground>
          ) : null}
        </div>
      )}
    </AuthConsumer>
  );
};

export default Dashboard;
