import {push} from 'connected-react-router';
import {Dispatch} from 'redux';

import {State, ThunkDispatch} from '../../store';
import {upsertMerchantContact} from '../../api';
import {OnboardingStatus} from '../../api/types';
import {updateActiveMerchant, UPDATE_ACTIVE_MERCHANT_SUCCEEDED} from '../user-data/merchants';
import {captureException, addBreadcrumb, Severity} from '@sentry/browser';
import {finishOnboarding} from '../congrats/congrats';

export const ROUTE_PLUS_CONFIGURATION_SUBMIT_STARTED = 'ROUTE_PLUS_CONFIGURATION_SUBMIT_STARTED' as const;
export interface RoutePlusConfigurationSubmitStartedAction {
  type: typeof ROUTE_PLUS_CONFIGURATION_SUBMIT_STARTED;
}

export function routePlusConfigurationSubmitStarted(): RoutePlusConfigurationSubmitStartedAction {
  return {
    type: ROUTE_PLUS_CONFIGURATION_SUBMIT_STARTED,
  };
}

export const ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED = 'ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED' as const;
export interface RoutePlusConfigurationSubmitSucceededAction {
  type: typeof ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED;
}

export function routePlusConfigurationSubmitSucceeded(): RoutePlusConfigurationSubmitSucceededAction {
  return {
    type: ROUTE_PLUS_CONFIGURATION_SUBMIT_SUCCEEDED,
  };
}

export const ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED = 'ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED' as const;
export interface RoutePlusConfigurationSubmitFailedAction {
  type: typeof ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED;
  reason?: string;
}

export function routePlusConfigurationSubmitFailed(reason?: string): RoutePlusConfigurationSubmitFailedAction {
  return {
    type: ROUTE_PLUS_CONFIGURATION_SUBMIT_FAILED,
    reason,
  };
}

export type ConfigurationData = {
  routePlusEnabled: boolean;
  expertImplementationEnabled: boolean;
  claimsContactEmail?: string;
  returnsContactEmail?: string;
  returnsProcessLink?: string;
}

export const continueToThankYouPage = (data: ConfigurationData) => {
  data = {
    ...data,
  };

  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Starting "Configure Route Plus" action',
        level: Severity.Info,
      });
      dispatch(routePlusConfigurationSubmitStarted());
      const {userData} = getState().app;

      if (!userData.loggedIn) {
        throw new Error('Expected user to be logged in');
      }
      
      if (!data.routePlusEnabled) {
        const finalAction = await dispatch(updateActiveMerchant({
          id: userData.activeBrand.id,
          prod_api_secret: userData.activeBrand.prod_api_secret,
          has_route_plus: false,
          onboarding_status: OnboardingStatus.Complete,
          onboarding_complete: true,
          status: 'Active',
        }));

        if (finalAction.type !== UPDATE_ACTIVE_MERCHANT_SUCCEEDED) {
          throw new Error('Merchant Update Failed');
        }

        addBreadcrumb({
          message: '"Configure Route Plus" successful',
          data: {
            routePlusEnabled: false,
          },
          level: Severity.Info,
        });

        dispatch(routePlusConfigurationSubmitSucceeded());
        return dispatch(finishOnboarding());
      }

      if (!data.claimsContactEmail || !data.returnsContactEmail) {
        throw new Error('Required information for Route Plus not set');
      }

      const install_method = userData.activeBrand.platform_id !== 'shopify' ?
        undefined : data.expertImplementationEnabled ?
          'expert' :
          'self';
      const updateActionResult = await dispatch(updateActiveMerchant({
        id: userData.activeBrand.id,
        prod_api_secret: userData.activeBrand.prod_api_secret,
        has_route_plus: true,
        // The next page will be transfer of premiums which is an optional page, and the last one
        // before the congrats screen.
        onboarding_status: OnboardingStatus.Skipped,
        onboarding_complete: true,
        status: 'Active',
        install_method,
      }));

      if (updateActionResult.type !== UPDATE_ACTIVE_MERCHANT_SUCCEEDED) {
        throw new Error('Merchant Update Failed');
      }

      const baseBody = {
        merchantId: updateActionResult.data.id,
        merchantToken: updateActionResult.data.prod_api_secret,
        data: {
          merchant_id: updateActionResult.data.id,
        },
      };
      const merchantClaimsContactPromise = upsertMerchantContact({
        ...baseBody,
        data: {
          ...baseBody.data,
          slug: 'claim_contact',
          email: data.claimsContactEmail,
        },
      });
      const merchantReturnsContactPromise = upsertMerchantContact({
        ...baseBody,
        data: {
          ...baseBody.data,
          slug: 'returns',
          email: data.returnsContactEmail,
          link: data.returnsProcessLink,
        },
      });

      try {
        await Promise.all([merchantClaimsContactPromise, merchantReturnsContactPromise]);
      } catch {
        throw new Error('Update merchant contact info fetches failed');
      }

      addBreadcrumb({
        message: '"Configure Route Plus" successful',
        data: {
          routePlusEnabled: true,
        },
        level: Severity.Info,
      });
      dispatch(routePlusConfigurationSubmitSucceeded());
      return dispatch(push('/onboarding/thank-you-page'))
    } catch(err) {
      captureException(err);
      const message: string|undefined = err && typeof err.message === 'string' ? err.message : undefined;
      dispatch(routePlusConfigurationSubmitFailed(message));
    }
  }
}
