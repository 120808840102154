import React, { useState } from 'react';
import './UpdatePaymentMethod.scss';
import TitleAndNav from './TitleAndNav';
import Button from '../../../Button/Button';
import { updateMerchantContact } from '../../../../services/merchant';
import DrawerValidation, {validateEmail, validatePhone} from "./DrawerValidation";
import ReactGA from 'react-ga';

const UpdateCompanyContact = (props) => {
  const { id: merchantId, prod_api_secret: token } = props.userSession.activeBrand;
  const [companyEmail, setCompanyEmail] = useState(props.companyEmail);
  const [companyPhone, setCompanyPhone] = useState(props.companyPhone);
  const [companyLink, setCompanyLink] = useState(props.companyLink);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [isPhoneInvalid, setPhoneInvalid] = useState(false);

  const handleSubmitButton = async (e) => {
    if (validateEmail(companyEmail) && validatePhone(companyPhone)) {

      // Google analytics, track merchant billing contact change - phone and email are PII and cannot be captured
      ReactGA.event({ category: 'Admin - Preferences', action: 'Company Contact change', label: `emailIsSet:${!!companyEmail} phoneIsSet:${!!companyPhone} link:${companyLink}` });

      await updateMerchantContact({
        email: companyEmail,
        phone: companyPhone,
        link: companyLink,
        slug: 'company',
        merchantId,
        token
      });
      props.closeDrawer();
    } else {
      validateBillingEmail();
      validateBillingPhone();
    }
    props.refreshMerchantContact();
  };

  const closeEmailError = () => {
    setEmailInvalid(false);
  };

  const closePhoneError = () => {
    setPhoneInvalid(false);
  };

  const validateBillingEmail = () => {
    setEmailInvalid(!validateEmail(companyEmail))
  };

  const validateBillingPhone = () => {
    setPhoneInvalid(!validatePhone(companyPhone))
  };

  const handleEmailChange =(e) => {
    setCompanyEmail(e.target.value);
    closeEmailError();
  };

  const handlePhoneChange =(e) => {
    setCompanyPhone(e.target.value);
    closePhoneError();
  };

  return (
    <div>
      <TitleAndNav close={props.closeDrawer} title='Company Contact' />
      <div className='update-payment-method-upper-text'>
        <p>Provide contact information that your customers can use for general questions or
          concerns. This will be listed on your merchant profile in the Route mobile app.</p><br />
        <p className='bold-paragraph'>Any fields below that are left blank will not appear on your
          profile in the Route mobile app.</p>
      </div>
      <div className='update-payment-method-lower-text'>
        <div className='update-billing-contact-input-header'>Email</div>
        <input className='input-ss' value={companyEmail} name="contactEmail"
           onChange={handleEmailChange}
           onBlur={validateBillingEmail}
        />
        {isEmailInvalid ? <DrawerValidation isEmail = {isEmailInvalid} closeEmailError = {closeEmailError} /> : ''}
        <div className='update-billing-contact-input-header'>Phone</div>
        <input className='input-ss' value={companyPhone} name="phone"
            onChange={handlePhoneChange}
            onBlur={validateBillingPhone}
        />
        {isPhoneInvalid ? <DrawerValidation isPhone = {isPhoneInvalid} closePhoneError = {closePhoneError}/> : ''}
        <div className='update-billing-contact-input-header'>Link</div>
        <input className='input-ss' value={companyLink} name="link"
          onChange={(e) => setCompanyLink(e.target.value)}
        />
      </div>
      <Button className='update-company-details-save-button' type='submit'
        text="Save"
        onClick={handleSubmitButton}
      />
    </div>
  );
};

export default UpdateCompanyContact;
