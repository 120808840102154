import React, { Component } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from 'react-stripe-elements';
import './ConnectCard.scss';
import Button from '../../../Button/Button';
import { createStripeCardAccount } from '../../../../services/billing';
import TitleAndNav from './TitleAndNav';
import { getMerchant } from '../../../../services/merchant';
import { isOnboardingFlow } from '../../../../utils/url';

const createOptions = () => {
  return {
    style: {
      base: {
        height: '100%',
        width: '100%',
        fontWeight: '320',
        color: '#313942',
        padding: '5px',
        fontSize: '12px',
        lineHeight: '19px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class ConnectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      addressLine1: '',
      addressLine2: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
      addressCountry: '',
      openCardModal: true,
      disableAddCardButton: false,
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleSubmit = async ev => {
    this.setState({ disableAddCardButton: true });
    ev.preventDefault();

    const { token } = await this.props.stripe.createToken({
      name: this.state.name,
      address_line1: this.state.addressLine1,
      address_line2: this.state.addressLine2,
      address_city: this.state.addressCity,
      address_state: this.state.addressState,
      address_zip: this.state.addressZip,
      address_country: this.state.addressCountry,
    }).then(this.props.handleResult);

    let prodApiSecret = ''

    if (isOnboardingFlow()) {
      const merchant = getMerchant() || "";
      prodApiSecret = merchant.prod_api_secret;
    } else {
      prodApiSecret = this.props.userSession.activeBrand.prod_api_secret; 
    }
    let res = await createStripeCardAccount(token, prodApiSecret);

    if (res) {
      this.props.setSnackbarMessage({ message: 'Payment Method Updated Successfully', variant: 'success' });
      this.props.closeDrawer();
      this.props.getBillingDetails();

      if (this.props.onBillingSuccess) {
        this.props.onBillingSuccess();
      }
    } else {
      this.props.setSnackbarMessage({ message: 'An error occurred while trying to verify your payment card with Stripe. \nPlease make sure your information is entered correctly. \nPlease reach out to Route Customer Service with any questions, or if this error persists.', variant: 'error' });
    }
    this.setState({ disableAddCardButton: false });
  };


  state = {
    errorMessage: '',
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <TitleAndNav title='Connect Debit / Credit Card' goBack={this.props.goBack} close={this.props.closeDrawer} />
        <div className='connect-card-row-1'>
          <div className='connect-card-field'>
            <label id='label-name' htmlFor='name'>
                  Cardholder Name<span style={{ color: 'red' }}> *</span>
            </label>
            <input
              id='name'
              className='connect-card-billing-input'
              type='text'
              placeholder='Jane Doe'
              autoComplete='name'
              value={this.state.name}
              name='name'
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='connect-card-row-2'>
          <div className='connect-card-field'>
            <label htmlFor='address'>Address<span style={{ color: 'red' }}> *</span></label>
            <input
              id='address'
              className='connect-card-billing-input'
              type='text'
              placeholder='185 Berry St'
              required=''
              autoComplete='address-line1'
              value={this.state.addressLine1}
              name='addressLine1'
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='connect-card-row-3'>
          <div className='connect-card-field'>
            <label htmlFor='address2'>Address 2</label>
            <input
              id='address2'
              className='connect-card-billing-input'
              type='text'
              placeholder='apt 3, suite#'
              required=''
              autoComplete='address-line2'
              value={this.state.addressLine2}
              name='addressLine2'
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='connect-card-row-4' data-locale-reversible>
          <div className='connect-card-field connect-card-double-column connect-card-double-column-left'>
            <label htmlFor='city'>City<span style={{ color: 'red' }}> *</span></label>
            <input
              id='city'
              className='connect-card-billing-input'
              type='text'
              placeholder='San Francisco'
              required=''
              autoComplete='city'
              value={this.state.addressCity}
              name='addressCity'
              onChange={this.handleInputChange}
            />
          </div>
          <div className='connect-card-field connect-card-double-column'>
            <label htmlFor='state'>State<span style={{ color: 'red' }}> *</span></label>
            <input
              id='state'
              className='connect-card-billing-input'
              type='text'
              placeholder='CA'
              required=''
              autoComplete='state'
              value={this.state.addressState}
              name='addressState'
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='connect-card-row-5' data-locale-reversible>
          <div className='connect-card-field connect-card-double-column connect-card-double-column-left'>
            <label htmlFor='zip'>ZIP<span style={{ color: 'red' }}> *</span></label>
            <input
              id='zip'
              className='connect-card-billing-input'
              type='text'
              placeholder='94107'
              required=''
              autoComplete='postal-code'
              value={this.state.addressZip}
              name='addressZip'
              onChange={this.handleInputChange}
            />
          </div>
          <div className='connect-card-field  connect-card-double-column'>
            <label htmlFor='country'>Country<span style={{ color: 'red' }}> *</span></label>
            <input
              id='country'
              className='connect-card-billing-input'
              type='text'
              placeholder='US'
              required=''
              autoComplete='country'
              value={this.state.addressCountry}
              name='addressCountry'
              onChange={this.handleInputChange}
            />
          </div>
        </div>
        <div className='connect-card-row-6'>
          <div className='split-form'>
            <div className='connect-card-field'>
              <label>
                <span className='connect-card-stripe-label'>Card number<span style={{ color: 'red' }}> *</span></span>
                <CardNumberElement
                  {...createOptions()}
                  placeholder='#### #### #### 1234'
                  onChange={this.handleChange}
                />
              </label>
            </div>
          </div>
          <div className='connect-card-row-7'>
            <div className='connect-card-field connect-card-double-column connect-card-double-column-left'>
              <label>
                <span className='connect-card-stripe-label'>Expiration<span style={{ color: 'red' }}> *</span></span>
                <CardExpiryElement
                  {...createOptions()}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <div className='connect-card-field connect-card-double-column'>
              <div className='split-form'>
                <label>
                  <span className='connect-card-stripe-label'>CVC<span style={{ color: 'red' }}> *</span></span>
                  <CardCVCElement {...createOptions()} onChange={this.handleChange} />
                </label>
              </div>
            </div>
          </div>


          <div className='error' role='alert'>
            {this.state.errorMessage}
          </div>
        </div>
        <div className='connect-card-submit-CC-container' >
        <Button className='connect-card-submit-CC-button' type='submit'
          onClick={this.handleSubmit}
          disabled={this.state.disableAddCardButton}
          text='Save Payment Method' />
          </div>
      </form>
    );
  }
}
export default injectStripe(ConnectCard);
