import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { addBreadcrumb, Severity } from '@sentry/browser';
import ReactGA from 'react-ga';
import './Preferences.scss';
import '../_shared/_shared.scss';
import CloseIcon from '../../../assets/icons/close-rounded';
import Subnav from '../_shared/Subnav/Subnav';
import { getMerchantContacts, getMerchantOptStatus, getMerchantPreference } from '../../../services/merchant';
import PreferencesDrawer from './Drawer/PreferencesDrawer';
import { testIds } from './Preferences.constant';
import { permissions } from '../components/Authentication/merchant_permissions';
import { check } from '../components/Authentication/Can/Can';

const Preferences = (props) => {
  const [open, setOpen] = useState(
    (props.location && props.location.state && props.location.state.isDrawerOpen) ||
      (props.billing && !props.billing.verified) ||
      false,
  );
  const [drawer, setDrawer] = useState(
    (props.location && props.location.state && props.location.state.drawer) ||
      props.drawer ||
      'update-payment' ||
      'set-up-payment',
  );
  const [billingEmail, setBillingEmail] = useState('');
  const [claimEmail, setClaimEmail] = useState('');
  const [merchantContacts, setMerchantContacts] = useState([]);
  const [forceDisabled, setForceDisabled] = useState(false);

  const refreshMerchantContact = async () => {
    try {
      let contacts = [];
      if (props.userSession.activeBrand.prod_api_secret && props.userSession.activeBrand.id) {
        contacts = await getMerchantContacts(
          props.userSession.activeBrand.prod_api_secret,
          props.userSession.activeBrand.id,
        );
      }
      if (contacts) {
        setMerchantContacts(contacts);
      }
    } catch (error) {
      addBreadcrumb({
        message: `"getMerchantContacts" failure: "${error}"`,
        category: 'GET Merchant Contacts',
        level: Severity.Error,
      });
      throw error;
    }
  };

  useEffect(() => {
    refreshMerchantContact();
    setForceDisabled(!check({ role: props?.userSession.merchantRole, action: permissions.admin.edit }));
  }, []);

  useEffect(() => {
    setDrawer(props.location && props.location.state && props.location.state.drawer);
    setOpen(props.location && props.location.state && props.location.state.isDrawerOpen);
  }, [props.location]);

  useEffect(() => {
    const contactBilling = merchantContacts && merchantContacts.find((x) => x.slug && x.slug === 'billing_contact');
    setBillingEmail((contactBilling && contactBilling.email) || '');

    const contactClaim = merchantContacts && merchantContacts.find((x) => x.slug && x.slug === 'claim_contact');
    setClaimEmail((contactClaim && contactClaim.email) || '');
  }, [merchantContacts]);

  const openUpdateBillingContact = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Billing Contact "Edit" click / Drawer view' });
    setDrawer('update-billing-contact');
    setOpen(true);
  };

  const openUpdateClaimsContact = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Claims Contact "Edit" click / Drawer view' });
    setDrawer('update-claims-contact');
    setOpen(true);
  };

  const openUpdatePaymentMethod = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Payment Method "Edit" click / Drawer view' });
    setDrawer('update-payment');
    setOpen(true);
  };

  const openUpdateRoutePlus = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Route Plus "Edit" click / Drawer view' });
    setDrawer('update-route-plus');
    setOpen(true);
  };

  const openUpdateCoverageType = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Coverage Type "Edit" click / Drawer view' });
    setDrawer('update-coverage-type');
    setOpen(true);
  };

  const openUpdateFreeTrackingEmail = () => {
    ReactGA.event({ category: 'Admin - Preferences', action: 'Free Tracking Email "Edit" click / Drawer view' });
    setDrawer('update-tracking-email');
    setOpen(true);
  };

  const openThankYouPage = () => {
    setDrawer('thank-you-page-tracking-link');
    setOpen(true);
  };

  const subnavRoutes = [
    { pathname: 'preferences', pageName: 'Preferences' },
    { pathname: 'billing', pageName: 'Billing' },
  ];

  return (
    <div className="admin-preferences-main-container">
      <Subnav {...props} header="Admin" pages={subnavRoutes} />
      <div>
        <Grid container spacing={2} style={{ marginLeft: '60px' }}>
          <Grid item xs={6}>
            <div className="admin-preferences-below-container">
              <div className="admin-preferences-card-above-card-title first">Installation Details</div>
              <Card className="admin-preferences-card" elevation={0}>
                <div className="div-table">
                  <div className="div-table-row">
                    <div className="div-table-cell">Platform</div>
                    <div className="div-table-right-cell" style={{ textTransform: 'capitalize' }}>
                      {props.userSession.activeBrand.platform_id || '-'}
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Merchant ID</div>
                    <div className="div-table-right-cell">{props.userSession.activeBrand.id || '-'}</div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Public Token</div>
                    <div className="div-table-right-cell">{props.userSession.activeBrand.public_api_key || '-'}</div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Secret Token</div>
                    <div className="div-table-right-cell">{props.userSession.activeBrand.prod_api_secret || '-'}</div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Test-Secret Token</div>
                    <div className="div-table-right-cell">{props.userSession.activeBrand.stage_api_secret || '-'}</div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="admin-preferences-below-container">
              <div className="admin-preferences-card-above-card-title first">Route Configuration</div>
              <Card className="admin-preferences-card" elevation={0}>
                <div className="div-table">
                  <div className="div-table-row">
                    <div className="div-table-cell">Route+ Package Protection</div>
                    <div className="div-table-right-cell">
                      {!props.userSession.activeBrand.has_route_plus ? (
                        'DISABLED'
                      ) : (
                        <span className="toggle-on">ENABLED</span>
                      )}
                    </div>
                    <div className="edit-cell-div">
                      {forceDisabled ? (
                        <span className="edit-links-small-edits-grayed">Edit</span>
                      ) : (
                        <span className="edit-links-small-edits" onClick={openUpdateRoutePlus}>
                          Edit
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Protection Toggle Default (ON / OFF)</div>
                    <div className="div-table-right-cell">
                      {!props.userSession.activeBrand.has_route_plus ? (
                        '-'
                      ) : getMerchantOptStatus(props.userSession.activeBrand.merchant_preferences) == 'OFF' ? (
                        <span style={{ color: '#249D0E' }}>OFF</span>
                      ) : (
                        <span className="toggle-on">
                          {getMerchantOptStatus(props.userSession.activeBrand.merchant_preferences)}
                        </span>
                      )}
                    </div>
                    <div className="edit-cell-div">
                      {!props.userSession.activeBrand.has_route_plus || forceDisabled ? (
                        <span className="edit-links-small-edits-grayed">Edit</span>
                      ) : (
                        <span className="edit-links-small-edits" onClick={openUpdateCoverageType}>
                          Edit
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Free Tracking Email</div>
                    <div className="div-table-right-cell">
                      {getMerchantPreference(props.userSession.activeBrand, 'optional_tracking_email') !== true ? (
                        'OFF'
                      ) : (
                        <span className="toggle-on">ON</span>
                      )}
                    </div>
                    <div className="edit-cell-div">
                      <span
                        className={forceDisabled ? 'edit-links-small-edits-grayed' : 'edit-links-small-edits'}
                        onClick={!forceDisabled && openUpdateFreeTrackingEmail}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                  {props.userSession.activeBrand.platform_id !== 'bold-cashier' ? (
                    <div className="div-table-row" data-testid={testIds.THANK_YOU_PAGE_TRACKING_LINK_CONTAINER}>
                      <div className="div-table-cell">Thank You Page Tracking Link</div>
                      <div className="div-table-right-cell">
                        {getMerchantPreference(props.userSession.activeBrand, 'asset_live') !== true ? (
                          'OFF'
                        ) : (
                          <span className="toggle-on">ON</span>
                        )}
                      </div>
                      <div className="div-table-right-cell">
                        <span
                          className={forceDisabled ? 'edit-links-small-edits-grayed' : 'edit-links-small-edits'}
                          onClick={!forceDisabled && openThankYouPage}
                        >
                          Edit
                        </span>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className="admin-preferences-below-container">
              <div className="admin-preferences-card-above-card-title first">
                <span>Payment Method</span>
                <span
                  className={forceDisabled ? 'edit-links-grayed' : 'edit-links'}
                  onClick={!forceDisabled && openUpdatePaymentMethod}
                >
                  Edit
                </span>
              </div>
              <Card className="admin-preferences-card" elevation={0}>
                <div className="div-table">
                  <div className="div-table-row">
                    <div className="div-table-cell">Account Name</div>
                    <div className="div-table-right-cell">
                      {!props.billing ? (
                        <CloseIcon width={10} height={10} viewBox="0 0 10 10" fill="#DC5960" />
                      ) : (
                        props.billing.account_name || '-'
                      )}
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Bank Name / Card Name</div>
                    <div className="div-table-right-cell">
                      {!props.billing ? (
                        <CloseIcon width={10} height={10} viewBox="0 0 10 10" fill="#DC5960" />
                      ) : (
                        props.billing.bank_name || '-'
                      )}
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell">Account Ending</div>
                    <div className="div-table-right-cell">
                      {!props.billing ? (
                        <CloseIcon width={10} height={10} viewBox="0 0 10 10" fill="#DC5960" />
                      ) : props.billing.account_last_four ? (
                        `...${props.billing.account_last_four}`
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="admin-preferences-below-container">
              <div className="admin-preferences-card-above-card-title first">Merchant Contact</div>
              <Card elevation={0}>
                <div className="div-table">
                  <div className="div-table-row">
                    <div className="div-table-cell">Billing Contact</div>
                    <div className="div-table-right-cell">
                      <span
                        className={forceDisabled ? 'edit-links-small-edits-grayed' : 'edit-links-small-edits'}
                        onClick={!forceDisabled && openUpdateBillingContact}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell-content">
                      <span className="div-table-cell-border">Email</span>
                    </div>
                    <div className="div-table-right-cell">{billingEmail || '-'}</div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell" style={{ paddingTop: '20px' }}>
                      Claims Contact
                    </div>
                    <div className="div-table-right-cell">
                      <span
                        className={forceDisabled ? 'edit-links-small-edits-grayed' : 'edit-links-small-edits'}
                        onClick={!forceDisabled && openUpdateClaimsContact}
                      >
                        Edit
                      </span>
                    </div>
                  </div>
                  <div className="div-table-row">
                    <div className="div-table-cell-content">
                      <span className="div-table-cell-border">Email</span>
                    </div>
                    <div className="div-table-right-cell">{claimEmail || '-'}</div>
                  </div>
                </div>
              </Card>
            </div>
          </Grid>
        </Grid>
        <PreferencesDrawer
          {...props}
          isOpen={open}
          setOpen={setOpen}
          drawerName={drawer}
          setDrawerName={setDrawer}
          refreshMerchantContact={refreshMerchantContact}
          billingEmail={billingEmail}
          claimEmail={claimEmail}
          getBillingDetails={props.getBillingDetails}
          user={props.user}
        />
      </div>
    </div>
  );
};

export default Preferences;
