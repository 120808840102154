import React, { useState, useEffect } from 'react';

import ReactMapGL, { Popup, Marker } from 'react-map-gl';
import StaticMap from 'react-map-gl';

import {
  ContainerMap,
  ContentMapInfo,
  ContainerZoom,
  ContentZoom,
  ButtonZoomOut,
  ButtonZoomIn,
  NavigationPercentage,
  Cluster,
  Card,
  CardTitle,
  CardSubTitle,
  CardSubTitleHighlight,
} from './OrderStatusMap.styles';

import './OrderStatusMap.scss';

import { IOrderStatusMapProps } from './interface';

import { DELIVERY_STATUS } from '../../utils/delivery-status';
import { MAPBOX_API_KEY } from '../../../../constants';
import Tooltip from '../Tooltip';
import IconInfo from '../IconInfo';
import Typography from '../Typography';

const OrderStatusMap: React.FC<IOrderStatusMapProps> = ({
  shipmentCountsByZip,
  showOrdersReadyToShip,
  showOrdersDelivered,
  showOrdersInTransit,
  showOrdersAvailableForPickup,
  showOrdersOutForDelivery,
  showOrdersAttemptFail,
  ...props
}: IOrderStatusMapProps) => {
  const [state, setState] = useState<any>({
    viewport: {
      latitude: 37.785164,
      longitude: -100,
      zoom: 3,
      minZoom: 1,
      maxZoom: 20,
      bearing: 0,
      pitch: 0,
    },
    hoverInfo: null,
  });

  const [deliveryStatus, setDeliveryStatus] = useState<any>(DELIVERY_STATUS);

  useEffect(() => {
    setDeliveryStatus({
      ...deliveryStatus,
      InfoReceived: { ...deliveryStatus.InfoReceived, visible: showOrdersReadyToShip },
      Pending: { ...deliveryStatus.Pending, visible: showOrdersReadyToShip },
    });
  }, [showOrdersReadyToShip]);

  useEffect(() => {
    setDeliveryStatus({ ...deliveryStatus, Delivered: { ...deliveryStatus.Delivered, visible: showOrdersDelivered } });
  }, [showOrdersDelivered]);

  useEffect(() => {
    setDeliveryStatus({ ...deliveryStatus, InTransit: { ...deliveryStatus.InTransit, visible: showOrdersInTransit } });
  }, [showOrdersInTransit]);

  useEffect(() => {
    setDeliveryStatus({
      ...deliveryStatus,
      AvailableForPickup: { ...deliveryStatus.AvailableForPickup, visible: showOrdersAvailableForPickup },
    });
  }, [showOrdersAvailableForPickup]);

  useEffect(() => {
    setDeliveryStatus({
      ...deliveryStatus,
      OutForDelivery: { ...deliveryStatus.OutForDelivery, visible: showOrdersOutForDelivery },
    });
  }, [showOrdersOutForDelivery]);

  useEffect(() => {
    setDeliveryStatus({
      ...deliveryStatus,
      AttemptFail: { ...deliveryStatus.AttemptFail, visible: showOrdersAttemptFail },
    });
  }, [showOrdersAttemptFail]);

  const onViewportChange = (viewport: any) => setState({ viewport });

  const handleClusterEnter = (event: MouseEvent, item: any) => {
    setState({
      ...state,
      hoverInfo: {
        item,
      },
    });
  };

  const handleClusterLeave = (event: MouseEvent) => {
    setState({
      ...state,
      hoverInfo: null,
    });
  };

  const handleZoomOut = () => {
    let zoom = state.viewport.zoom - 1;
    if (state.viewport.zoom <= 1) zoom = 1;

    setState({
      ...state,
      viewport: {
        ...state.viewport,
        zoom,
      },
    });
  };

  const handleZoomIn = () => {
    let zoom = state.viewport.zoom + 1;
    if (state.viewport.zoom >= 20) zoom = 20;

    setState({
      ...state,
      viewport: {
        ...state.viewport,
        zoom,
      },
    });
  };

  const aggregateZipcodes = (shipmentCounts: any) => {
    let total = 0;

    for (let x = 0; x < shipmentCounts.length; x++) {
      total += shipmentCounts[x].COUNT;
    }
    return total;
  };

  const calculateZipcodeMetrics = (count: any, shipmentCounts: any) => {
    const metric = (count / aggregateZipcodes(shipmentCounts)) * 100;
    return !isNaN(metric) ? metric.toFixed(2) : '00';
  };

  const renderPopup = () => {
    const { hoverInfo } = state;
    return (
      hoverInfo && (
        <Popup longitude={hoverInfo.item.LONGITUDE} latitude={hoverInfo.item.LATITUDE} closeButton={false}>
          <Card>
            <div style={{ marginBottom: '16px' }}>
              <CardTitle>{'City'}</CardTitle>
              <CardSubTitle>{hoverInfo.item.CITY}</CardSubTitle>
              <CardSubTitle>{hoverInfo.item.ZIP}</CardSubTitle>
            </div>
            <div>
              <CardTitle>{'Metric'}</CardTitle>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Cluster
                  style={{
                    cursor: 'pointer',
                    width: '12px',
                    height: '12px',
                    background: deliveryStatus[hoverInfo.item.DELIVERY_STATUS].fill,
                    border: `1px solid ${deliveryStatus[hoverInfo.item.DELIVERY_STATUS].stroke}`,
                    borderRadius: '12px',
                    marginRight: '8px',
                  }}
                />
                <CardSubTitle color={deliveryStatus[hoverInfo.item.DELIVERY_STATUS].stroke}>
                  {`${hoverInfo.item.COUNT} (${calculateZipcodeMetrics(hoverInfo.item.COUNT, shipmentCountsByZip)}%)`}
                </CardSubTitle>
              </div>
            </div>
          </Card>
        </Popup>
      )
    );
  };

  return (
    <>
      <ContainerMap>
        <ReactMapGL
          {...state.viewport}
          reuseMaps
          width="100%"
          height="450px"
          onViewportChange={onViewportChange}
          mapboxApiAccessToken={MAPBOX_API_KEY}
          mapStyle="mapbox://styles/routewapp/ck0sfcnuh1cqp1cpybctk21k2"
          scrollZoom={true}
          interactive={true}
          // onHover={onHover}
          attributionControl={false}
        >
          {shipmentCountsByZip &&
            shipmentCountsByZip.map((item: any, index: any) => {
              if (item?.LONGITUDE && item?.LATITUDE && deliveryStatus[item?.DELIVERY_STATUS]) {
                return (
                  <Marker key={`marker-${index}`} longitude={item.LONGITUDE} latitude={item.LATITUDE}>
                    <Cluster
                      onMouseEnter={(event: MouseEvent) => handleClusterEnter(event, item)}
                      onMouseLeave={handleClusterLeave}
                      style={{
                        cursor: 'pointer',
                        display: deliveryStatus[item.DELIVERY_STATUS]?.visible ? 'block' : 'none',
                        position: 'absolute',
                        top: -(item.COUNT < 20 ? item.COUNT : 20),
                        left: -(item.COUNT < 20 ? item.COUNT : 20),
                        width: (item.COUNT < 20 ? item.COUNT : 20) * 2,
                        minWidth: '4px',
                        maxWidth: '60px',
                        height: (item.COUNT < 20 ? item.COUNT : 20) * 2,
                        minHeight: '4px',
                        maxHeight: '60px',
                        background: deliveryStatus[item.DELIVERY_STATUS]?.fill,
                        border: `1px solid ${deliveryStatus[item.DELIVERY_STATUS]?.stroke}`,
                        borderRadius: (item.COUNT < 20 ? item.COUNT : 20) * 2,
                      }}
                    />
                  </Marker>
                );
              }
            })}
          {renderPopup()}
        </ReactMapGL>
        <ContentMapInfo>
          <Tooltip
            wrapper={<IconInfo />}
            overlay={
              <>
                <Typography variant="p">
                  <a href="https://www.mapbox.com/about/maps/" target="_blank">
                    © Mapbox
                  </a>
                  <br />
                  <a href="http://www.openstreetmap.org/about/" target="_blank">
                    © OpenStreetMap
                  </a>
                  <br />
                  <a
                    className="mapbox-improve-map"
                    href="https://apps.mapbox.com/feedback/?owner=routewapp&amp;id=ck0sfcnuh1cqp1cpybctk21k2&amp;access_token=pk.eyJ1Ijoicm91dGV3YXBwIiwiYSI6ImNqeDF5bjVxcTBlejc0M25zcnI2ZGY4Nm4ifQ.tVxEFyO5UTwSMTUgxJo_hg"
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    Improve this map
                  </a>
                </Typography>
              </>
            }
          ></Tooltip>
        </ContentMapInfo>
      </ContainerMap>

      <ContainerZoom>
        <ContentZoom>
          <ButtonZoomOut onClick={handleZoomOut}>-</ButtonZoomOut>
          <NavigationPercentage>{`${Math.round(state.viewport.zoom * 100)}%`}</NavigationPercentage>
          <ButtonZoomIn onClick={handleZoomIn}>+</ButtonZoomIn>
        </ContentZoom>
      </ContainerZoom>
    </>
  );
};

export default React.memo(OrderStatusMap);
