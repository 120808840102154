import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Header from "../../Header/Header";
import Button from "../../Button/Button";
import { 
  resetPassword,
  loginUser,
  getMerchant,
} from "../../../services/api";
import {
  linkMerchant,
  updateMerchant,
  updateCollaborator,
} from "../../../services/merchant";

import "../Login.scss";

const PasswordReset = props => {
  const [passwords, setPassword] = useState({
    password: "",
      confirmPassword: ""
   });
   const [merchant, setMerchant] = useState({
      email: "",
      name: "",
      storeName: "",
      token: "",
   });
   const [buttonText, setButtonText] = useState("Reset Password");
   const [descriptiveText, setDescriptiveText] = useState("");
   const [resetPasswordGUID, setGuid] = useState("");

   useEffect(() => {
      const { guid: passwordGUID, token, email } = queryString.parse(
         // eslint-disable-next-line react/prop-types
         props.location.search
      );

      setGuid(passwordGUID);

      if (token && email) {
        const formattedEmail = email.replace(' ', '+');

        setMerchant({
          ...merchant,
          email: formattedEmail,
          token,
        });
      }
   }, []);

   const validateForm = () =>
      passwords.password.length &&
      passwords.confirmPassword.length &&
      passwords.password === passwords.confirmPassword;

   const handleSubmit = async e => {
      e.preventDefault();

      try {
         setPassword({
            password: "",
            confirmPassword: ""
         });
         setButtonText("Resetting..");
         const resetPasswordResp = await resetPassword(passwords.password, resetPasswordGUID);

         if (resetPasswordResp && merchant.token && merchant.email) {
            const loginResp = await loginUser(merchant.email, passwords.password);

            if (loginResp && loginResp.user.id) {
              const linkMerchantResp = await linkMerchant(loginResp.user.id, merchant.token);

              if (linkMerchantResp && linkMerchantResp.merchant_id) {
                const activeBrand = await getMerchant(merchant.token);
                
                props.setUserSession({
                  loggedIn: true,
                  user: loginResp.user,
                  brands: loginResp.brands,
                  activeBrand,
                });
                updateMerchant(activeBrand);
                updateCollaborator(loginResp.user);
                props.history.push('/onboarding/route-package-protection');
              }
            }
         }
         setButtonText("Password Reset!");
      } catch (e) {
         setButtonText("Submit");
         setDescriptiveText(
          "An error occurred while trying to reset your password. Please contact support@route.com"
         );
      }
   };

   const renderForm = () =>
      !descriptiveText ? (
         <form className="login-form" onSubmit={handleSubmit}>
            <label className="form-label">New Password</label>
            <input
               className="form-input"
               id="password"
               type="password"
               placeholder=""
               value={passwords.password}
               onChange={e =>
                  setPassword({ ...passwords, password: e.target.value })
               }
            />
            <label className="form-label">New Password Confirm</label>
            <input
               className="form-input"
               id="password"
               type="password"
               placeholder=""
               value={passwords.confirmPassword}
               onChange={e =>
                  setPassword({
                     ...passwords,
                     confirmPassword: e.target.value
                  })
               }
            />
            <Button
               className="login-button"
               type="submit"
               disabled={!validateForm()}
               text={buttonText}
            />
         </form>
      ) : (
            <form className="login-form">
               <div className="form-descriptive-text">{descriptiveText}</div>
            </form>
         );

   return (
      <div>
         <Header />
         <div className="login form">
            <div className="form-wrapper">
               <div className="form-container">
                  <h1 className="form-header">Password Reset</h1>
                  <div className="l-form">{renderForm()}</div>
                  <div className="l-other-actions">
                     <Link to="/login">Back to Login</Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default PasswordReset;
