import React from 'react';

const SVG = ({
  style = {},
  fill = '#FFFFFF',
  width = '24px',
  className = '',
  height = '24px',
  viewBox = '0 0 24 24',
  transform = 'translate(0,6)',
  doOnClick,
}) => <svg
  width={width}
  cursor={'pointer'}
  style={style}
  height={height}
  viewBox={viewBox}
  transform={transform}
  backgroundFill='#666666'
  fill={fill}
  onClick={doOnClick}
>
    <circle cx="12" cy="12" r="9" fill='#666666' />
    <path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
  </svg>;

export default SVG;
