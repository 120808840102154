import React from 'react';

const SVG = ({
  style = {},
  fill = '#66C1CB',
  width = '6',
  height = '12',
  viewBox = '0 0 6 12',
  className = '',
  transform = 'rotate(90)',
}) => <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M5.23087 11.6017L5.7318 11.0146C5.79869 10.9364 5.83203 10.8462 5.83203 10.7444C5.83203 10.6427 5.79869 10.5527 5.7318 10.4744L1.79388 5.85944L5.73169 1.24464C5.79858 1.16637 5.83193 1.0763 5.83193 0.974544C5.83193 0.87275 5.79858 0.782679 5.73169 0.704452L5.23073 0.117463C5.16397 0.0390722 5.08711 -3.81581e-08 5.00025 -4.26077e-08C4.91345 -4.70536e-08 4.83659 0.0391956 4.76984 0.117463L0.100409 5.58934C0.0336211 5.66757 0.000244403 5.75768 0.000244399 5.85944C0.000244395 5.96119 0.0336211 6.05114 0.100409 6.12932L4.76984 11.6017C4.83663 11.6799 4.91349 11.7187 5.00025 11.7187C5.08711 11.7187 5.16397 11.6799 5.23087 11.6017Z" />
  </svg>;

export default SVG;
