import React, { FC } from 'react';
import Thumbsup from '../../../../assets/images/thumbsup.svg';
import { Button } from '../../../../components/Brand/components/button';
import { pendoShowGuideById } from '../../../../services/pendo';

import { Feedback } from './Support.styles';

export type SupportProps = {};

const onFeedbackClick = () => {
  pendoShowGuideById('rJH24j3PkcxpllFU7Oul4i_wA20');
};

const onSupportClick = () => {
  window.open('https://help.route.com/hc/en-us/requests/new?ticket_form_id=360001446393', '_blank');
};

export const Support: FC<SupportProps> = (props) => {
  return (
    <Feedback>
      <Button
        onClick={() => {
          onFeedbackClick();
        }}
        style={{
          borderRadius: 40,
          paddingLeft: 16,
          paddingRight: 16,
          marginRight: 8,
          fontFamily: 'Titillium Web, sans-serif',
        }}
        fontSize="12px"
        lineHeight="18px"
      >
        <img src={Thumbsup} />
        &nbsp; Please Give Us Feedback
      </Button>

      <Button
        onClick={() => {
          onSupportClick();
        }}
        style={{ borderRadius: 40, paddingLeft: 16, paddingRight: 16, fontFamily: 'Titillium Web, sans-serif' }}
        fontSize="12px"
        lineHeight="18px"
      >
        Support
      </Button>
    </Feedback>
  );
};

export default Support;
