import React, { useState, useEffect } from 'react';
import DeliveryLineItem from '../DeliveryLineItem';
import { Container, ViewAll } from './style';

const DeliveryHistory = (props) => {
  const [visibleTotal, setVisibleTotal] = useState(2);
  const [hideItems, setHideItems] = useState(true);
  const [toggleLabel, setToggleLabel] = useState(true);

  useEffect(() => {
    if (props.checkpoints && props.checkpoints.length > 0) {
      const label = hideItems ? `View All (${props.checkpoints.length})` : 'View Less';
      setToggleLabel(label);
    }
  }, [hideItems]);

  if (!props.checkpoints || !props.checkpoints.length) return null;

  const reversedCheckpoints = props.checkpoints.slice().reverse();

  return (
    <Container>
      {reversedCheckpoints.map((item, index) => {
        return (
          <DeliveryLineItem
            hidden={index >= visibleTotal && hideItems}
            info={item}
            key={index}
            active={index !== 0 || item.status === 'Delivered'}
            trackingLine={index < reversedCheckpoints.length - 1 && (!hideItems || index < visibleTotal - 1)}
            dashed={index === 0 && item.status !== 'Delivered'}
          ></DeliveryLineItem>
        );
      })}
      {props.checkpoints.length > visibleTotal && (
        <ViewAll onClick={() => setHideItems(!hideItems)}>{toggleLabel}</ViewAll>
      )}
    </Container>
  );
};

export default DeliveryHistory;
