import React, { useState, useEffect } from 'react';
import PageTitle from '../_shared/PageTitle/PageTitle';
import './InstallationInstructions.scss';
import CTAButton from '../_shared/CTAButton/CTAButton';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import List from '../_shared/List/List';
import ListItem from '../_shared/ListItem/ListItem';
import Subtitle from '../_shared/Subtitle/Subtitle';

import bulletSquare from '../../../assets/images/bullet-square-icon.jpg';
import {
  setExpertInstallOption,
  setManualInstallOption,
  clearInstallOption,
  getPlatform,
} from '../../../services/api';
import {
  Box, Paper,
} from '@material-ui/core';
import { getMerchant, setOnboardingComplete, hasDealSizeLimitAmount } from '../../../services/merchant';
import PageHeader from '../_shared/PageHeader/PageHeader';
import Paragraph from '../_shared/Paragraph/Paragraph';
import FeaturedImage from '../_shared/FeaturedImage/FeaturedImage';
import routePackageProtection from '../../../assets/images/route-package-protection.svg';

const InstallationInstructions = (props) => {
  const [merchant, setMerchat] = useState();

  useEffect(() => {
    // clearInstallOption();
    setMerchat(getMerchant());
  }, []);

  useEffect(() => {
      if (merchant) {
         if (!hasDealSizeLimitAmount(merchant) && merchant.platform_id == 'shopify') {
            handleManualInstall();
         }
      }
  }, [merchant]);

  const goToBilling = (installOption) => {
    if (merchant) {
      merchant.merchantId = merchant.merchantId || merchant.id;
      setOnboardingComplete(
		  merchant.merchantId,
		  installOption,
		  false,
      );
	 }

    props.history.push('/onboarding/billing');
  };

  const handleExpertInstall = () => {
    setExpertInstallOption();
    goToBilling('expert');
  };

  const handleManualInstall = (e) => {
    setManualInstallOption();
    goToBilling('self');
  };
  
  return (
	<OnboardingContainer className="installation-instructions">
		<Box display="flex">
			<Box width={721} mr="60px">
				<PageHeader>
					<PageTitle title="Route Plus Package Protection"></PageTitle>
				</PageHeader>
				<Paper className="column">
					<div>
						
                  {hasDealSizeLimitAmount(merchant) && (
                     <React.Fragment>
                        <Paragraph
                        text={
                           'As a Shopify store, you have two options to place Route Plus Package Protection on your site:'
                        }
                        ></Paragraph>

                        <section className="expert-step">
                           <Subtitle title="Expert Implementation"></Subtitle>

                           <ul className="nested-bullet-list">
                              <li>
                                 <img
                                    srcSet={bulletSquare}
                                    className="bullet"
                                 />
                                 <span>
                                    Our team will be in contact
                                    within 3 business days to
                                    request any necessary
                                    <br /> permissions and finalize
                                    the implementation
                                 </span>
                              </li>
                              <li>
                                 <img
                                    srcSet={bulletSquare}
                                    className="bullet"
                                 />
                                 <span>
                                    No additional cost to you
                                 </span>
                              </li>
                           </ul>
                        </section>
                     </React.Fragment>
                  )}
						<section className="self-step">
							<Subtitle title="Self Implementation"></Subtitle>

							<ul className="nested-bullet-list">
								<li>
									<img
										srcSet={bulletSquare}
										className="bullet"
									/>
									<span>
										Instructions for self
										implementation can be found
										&nbsp;<a href="https://help.route.com/hc/en-us/articles/360038579473-How-to-self-install-Route-on-your-store" target="_blank" rel="noopener norefferer">here</a>.
									</span>
								</li>
							</ul>
						</section>
					</div>
					<div className="button-container">
						{hasDealSizeLimitAmount(merchant) && (
                     <CTAButton
                        text="Expert Install"
                        href="#"
                        onClick={handleExpertInstall}
                     ></CTAButton>
						)}
						<CTAButton
							text="I'd like to do it myself"
							href="#"
							styleName="basic underline"
							onClick={handleManualInstall}
						></CTAButton>
					</div>
				</Paper>
			</Box>
			<Box display="flex" mt="140px" alignItems="flex-start">
				<FeaturedImage
					src={routePackageProtection}
					alt="Route Plus Package Protection sample"
				></FeaturedImage>
			</Box>
		</Box>
	</OnboardingContainer>
  );
};

export default InstallationInstructions;
