import React from "react";
const SVG = ({
                 style = {},
                 fill = 'rgba(0, 0, 0, 0.2)',
                 width = '24px',
                 className = '',
                 height = '24px',
                 viewBox = '0 0 24 24',
                 transform = 'translate(0,6)',
                 doOnClick = () => { },
             }) =>
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        transform={transform}
        fill={fill}
        onClick={doOnClick}
    >
        <circle cx="12" cy="12" r="9" fill='rgba(0, 0, 0, 0.2)' />
        <path fill="white" transform="translate(-8,-8)" d="M22.89 21.84L21.051 20L22.8907 18.16C23.0364 18.015 23.0364 17.7793 22.8907 17.6343L22.3645 17.1087C22.2195 16.9638 21.9839 16.9638 21.8389 17.1087L20 18.9488L18.1603 17.1087C18.0154 16.9638 17.7797 16.9638 17.6348 17.1087L17.1093 17.6351C16.9636 17.7801 16.9636 18.0157 17.1093 18.1607L18.949 20.0007L17.1093 21.84C16.9636 21.985 16.9636 22.2207 17.1093 22.3657L17.6348 22.8913C17.7797 23.0362 18.0154 23.0362 18.1603 22.8913L20 21.0512L21.8389 22.8913C21.9839 23.0362 22.2195 23.0362 22.3645 22.8913L22.89 22.3657C23.0364 22.2199 23.0364 21.985 22.89 21.84Z" />
    </svg>;

export default SVG;
