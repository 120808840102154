import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';
import { prettifyCurrency } from '../../../../utils/currency';
import { getOrderLineItems, getWebOrderTracking } from '../../../../services/order';
import '../../_shared/_shared.scss';
import Shipment from '../../../../route-order-tracking-react-components/src/components/Shipment';
import {
  ParseShipmentStatuses,
  LoadingStatuses,
} from '../../../../route-order-tracking-react-components/src/utils/shipment';
import { getActiveBrand } from '../../../../stores/SessionManager';
import ChevronIcon from '../../../../assets/icons/circle-with-chevron';
import dictionary from '../../../../constants/dictionary';
import { testIds } from './OrderForm.constants';
import colors from '../../../../constants/colors';

const OrderForm = (props) => {
  const [trackings, setTrackings] = useState([]);
  const [lineItems, setLineItems] = useState();
  const [open, setOpen] = useState(false);
  const [emailAddress, setEmail] = useState(encodeURIComponent(props.data.email_address));
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [shouldReload, setShouldReload] = useState(true);
  const [shippingStatus, setShippingStatus] = useState('');
  const [isFetchingData, setIsFetchingData] = useState(false);
  const MAX_AFTERSHIP_RETRY = 6;

  useEffect(() => {
    if (shipments.length > 0 && shouldReload && !isFetchingData) {
      if (LoadingStatuses.includes(shippingStatus)) {
        setTimeout(() => {
          queryForNewData(1);
        }, 5000);
      } else {
        setShouldReload(false);
      }
    }
  }, [shipments]);

  const getOrderItems = async (orderId) => {
    try {
      const activeBrand = getActiveBrand();

      if (activeBrand) {
        const { prod_api_secret: token } = activeBrand;

        const orderLineItemsResp = await getOrderLineItems(orderId, token);

        if (orderLineItemsResp) {
          setLineItems(orderLineItemsResp);
        }
      }
    } catch (e) {
      console.log('Error getting Order Line Items', e);
    }
  };

  async function queryForNewData(num) {
    setIsFetchingData(true);
    try {
      const { id } = props.data;
      const response = await getWebOrderTracking(id);

      if (response && response.id) {
        const data = response;
        const tempStatus = ParseShipmentStatuses(data.shipments);
        setTrackings([]);
        setTrackings(response);

        if (LoadingStatuses.includes(tempStatus)) {
          if (num === MAX_AFTERSHIP_RETRY) {
            setShowErrorMessage(true);
          }
          setTimeout(() => {
            queryForNewData(num + 1);
          }, 5000);
        } else {
          setShowErrorMessage(false);
        }
      } else {
        setTimeout(() => {
          queryForNewData(num + 1);
        }, 5000);
      }
    } catch (err) {
      setIsFetchingData(false);
      console.log(err);
    }
  }

  useEffect(() => {
    (async () => {
      if (props && Object.keys(props).length > 0) {
        const { id } = props.data;
        const trackingResp = await getWebOrderTracking(id);
        await getOrderItems(id);

        if (trackingResp) {
          if (trackingResp.shipments) {
            setTrackings(trackingResp);
          }
        }

        setShippingStatus(ParseShipmentStatuses(trackingResp.shipments || []));
        setShipments(trackingResp.shipments || []);
      }
      return false;
    })();
  }, []);

  const getOrderNumber = () => {
    const platform = props.userSession.activeBrand.platform_id;
    if (platform === 'shopify') {
      return props.data.source_order_number;
    }
    if (platform === 'magento' || platform === 'woocommerce') {
      return props.data.source_order_id;
    }
    return props.data.source_order_number || props.data.source_order_id;
  };

  const handleViewTrackingClicked = () => {
    // Google Analytics - Track view tracking
    if (!open && document.location.pathname.includes('orders')) {
      ReactGA.event({ category: 'Orders', action: '"View Tracking" click' });
    } else if (!open && document.location.pathname.includes('admin/billing')) {
      ReactGA.event({ category: 'Admin - Billing', action: '"View Tracking" click' });
    }

    setOpen(!open);
  };

  const hasTrackingAvailabe = (tracking) => {
    if (tracking && tracking.shipments) {
      return tracking.shipments.some(
        (shipment) => shipment.trackingNumber !== null && shipment.status !== dictionary.TRACKING_STATUS.NOT_AVAILABLE,
      );
    }
    return false;
  };

  const getShipmentInfo = (trackingNumber, status) => {
    let message = trackingNumber;

    if (status === dictionary.TRACKING_STATUS.NOT_AVAILABLE && trackingNumber) {
      message = dictionary.TRACKING_STATUS.WAITING_TRACKING_NUMBER;
    }

    if (!trackingNumber) {
      message = dictionary.TRACKING_STATUS.NOT_TRACKING_NUMBER;
    }
    return message;
  };

  const fileClaim = () => {
    // Google Analytics - Track file a claim clicked
    if (!open && document.location.pathname.includes('orders')) {
      ReactGA.event({ category: 'Orders', action: '"File a Claim" click' });
    } else if (!open && document.location.pathname.includes('admin/billing')) {
      ReactGA.event({ category: 'Admin - Billing', action: '"File a Claim" click' });
    }
  };

  const displayLineItems = (itemLineItems) => (
    <div>
      {itemLineItems &&
        itemLineItems.map((item) => (
          <div key={Math.random()} className="container-box">
            <p>{item.name}</p>
            <p>
              {item.quantity} {item.quantity > 1 ? 'items' : 'item'}
            </p>
            <p>{prettifyCurrency(item.price, props.data.currency)}</p>
          </div>
        ))}
    </div>
  );

  return (
    <div className="portal-order-form">
      <div className="form-display-container">
        {props.data.status === 'test' ? (
          <div className="form-data-label--emphasized">*Staging Order*</div>
        ) : (
          <a
            data-testid={testIds.FILE_A_CLAIM}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://claims.route.com/?routeOrderNumber=${props.data.order_number || ''}&email=${
              emailAddress || ''
            }&source=portal_web`}
          >
            <div className="button-claim-container">
              <span className="claim-file">
                <p>Report an Issue</p>on behalf of your customer
              </span>
              <ChevronIcon
                height="22px"
                width="22px"
                circleFill={colors.primaryColor}
                transform="rotate(0)"
                className="chevron-claim"
                hoverState="true"
              />
            </div>
          </a>
        )}
        {props.userSession.activeBrand.reimbursable === true ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://claims.route.com/?routeOrderNumber=${props.data.order_number || ''}&email=${
              emailAddress || ''
            }&source=portal_web&reimbursement=true`}
          >
            <div className="button-claim-container">
              <span className="claim-file">
                <p> Request reimbursement</p>if you have covered the cost of
                <br />a reorder/replacement
              </span>
              <ChevronIcon
                height="22px"
                width="22px"
                circleFill={colors.primaryColor}
                transform="rotate(0)"
                className="chevron-reimbursement"
                hoverState={true}
              />
            </div>
          </a>
        ) : (
          ''
        )}
        <div className="form-data-inner-container">
          <div className="form-data-container">
            <span className="form-data-label">Order Date</span>
            <span data-testid={testIds.ORDER_DATE}>
              {props.data.source_created_on || props.data.created_on
                ? moment(props.data.source_created_on || props.data.created_on)
                  .utc()
                  .format('MM/DD/YYYY')
                : ''}
            </span>
          </div>
          <div className="form-data-container">
            <span className="form-data-label">Order Number</span>
            <span>{getOrderNumber()}</span>
          </div>
          <div className="form-data-container">
            <span className="form-data-label">Route Order Number</span>
            <span data-testid={testIds.ROUTE_ORDER_ID}>{props.data.order_number}</span>
          </div>
          <div className="form-data-container">
            <span className="form-data-label">
              Tracking Number(s)
              {hasTrackingAvailabe(trackings) ? (
                <span className="featured" onClick={handleViewTrackingClicked}>
                  {open ? 'Close' : 'View'}
                </span>
              ) : (
                ''
              )}
            </span>
            {trackings.shipments && trackings.shipments.length ? (
              trackings.shipments.map((value, key) => (
                <span key={key}>{getShipmentInfo(value.trackingNumber, value.status)}</span>
              ))
            ) : (
              <span>Waiting for Shipping Info</span>
            )}
          </div>

          <div className="form-data-container">
            <span className="form-data-label">Protected Value</span>
            <span>{prettifyCurrency(props.data.amount_covered, props.data.currency)}</span>
          </div>
          <div className="form-data-container">
            <span className="form-data-label">Shipping Protection Cost</span>
            <span>{prettifyCurrency(props.data.paid_to_insure, props.data.currency)}</span>
          </div>
          <div className="form-data-container">
            <span className="form-data-label">Currency</span>
            <span>{props.data.currency}</span>
          </div>
          <div className="form-data-container">
            <span className="form-data-label">Customer</span>
            <span>{`${props.data.first_name} ${props.data.last_name}`}</span>
          </div>
          {lineItems && lineItems.length > 0 ? (
            <div className="form-data-container">
              <span className="form-data-label">Order Items</span>
              <span>{displayLineItems(lineItems)}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {open && (
        <Fragment>
          <div className="form-web-order-tracking">
            {trackings &&
              trackings.shipments &&
              trackings.shipments.map((value, key) => {
                return (
                  <Shipment key={key} item={value} data={trackings} showErrorMessage={showErrorMessage}></Shipment>
                );
              })}
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default OrderForm;
