import React from 'react';
import { Route } from 'react-router';
import { Box } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { defaultTheme } from './constants/theme';
import { ConnectedWelcome } from './pages/welcome/welcome-container';
import { ConnectedSetupAccount } from './pages/setup-account/setup-account-container';
import VerifyDomain from './pages/verify-domain';
import ThankYou from "./pages/thank-you"

import svg from './media/background.svg';
import { ConnectedErrorBanner } from './pages/misc/error-banner-container';
import { ConnectedLoadingToast } from './pages/misc/loading-toast';
import './index.scss';


const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${svg})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    height: '100%',
    width: '100%',
    fontFamily: 'Titillium Web, sans-serif'
  },
  container: {
    overflowX: 'hidden',
    width: '390px',
    paddingLeft: '0',
    paddingRight: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
})

export const Brand = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={defaultTheme}>
      <ConnectedLoadingToast />
      <Box className={`${classes.root} onboarding-2`} m={0}>
        <Route exact path="/brand/welcome" component={ConnectedWelcome} />
        <Route exact path="/brand/setup-account" component={ConnectedSetupAccount} />
        <Route exact path="/brand/verify-domain" component={VerifyDomain} />
        <Route exact path="/brand/thank-you" component={ThankYou} />
      </Box>
      <ConnectedErrorBanner />
    </ThemeProvider>
  );
}
