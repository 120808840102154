import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import Modal from "react-modal";
import "./AddPaymentCardModal.scss";
import StripeForm from "./StripeForm";

import {
  STRIPE_PUBLIC_API_KEY} from "../../../../constants";

const customModalSize = (height, width) => {
  return {
    content: {
      width,
      height,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "hidden",
    },
  };
};

class AddPaymentCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openBillingModal: false,
      openVerifyPaymentModal: false,
      deposit1: "",
      deposit2: "",
      width: this.props.width || "663px",
      height: this.props.height || "530px",
      openCardModal: true,
    };
  }

  handleCloseAddCardModal = () => {
    this.props.onRequestClose();
    this.setState({ openCardModal: false });
  };

  render() {
    return (
      <Modal
        isOpen={this.state.openCardModal}
        onRequestClose={this.handleCloseAddCardModal}
        style={customModalSize(this.state.height, this.state.width)}
      >
        <StripeProvider apiKey={STRIPE_PUBLIC_API_KEY}>
          <Elements>
            <StripeForm />
          </Elements>
        </StripeProvider>
      </Modal>
    );
  }
}

export default AddPaymentCardModal;