import { captureException, addBreadcrumb, Severity, captureMessage } from '@sentry/browser';
import { push } from 'connected-react-router';
import { FormData } from '../../store';
import { CreateUserErrorReason } from '../../api'
import { State, ThunkDispatch } from '../../../Onboarding-2/store';
import { createNecessaryUserAndMerchant } from './helpers';
import { RouteApi } from '../../api/types';

export const SETUP_ACCOUNT_STARTED = 'SETUP_ACCOUNT_STARTED' as const;
export interface SetupAccountStartedAction {
  type: typeof SETUP_ACCOUNT_STARTED;
}

export function setupAccountStarted(): SetupAccountStartedAction {
  return {
    type: SETUP_ACCOUNT_STARTED,
  };
}

export const SETUP_ACCOUNT_SUCCEEDED = 'SETUP_ACCOUNT_SUCCEEDED' as const;
export interface SetupAccountSucceededAction {
  type: typeof SETUP_ACCOUNT_SUCCEEDED;
}

export function setupAccountSucceeded(): SetupAccountSucceededAction {
  return {
    type: SETUP_ACCOUNT_SUCCEEDED,
  };
}

export const SETUP_ACCOUNT_FAILED = 'SETUP_ACCOUNT_FAILED' as const;
export interface SetupAccountFailedAction {
  type: typeof SETUP_ACCOUNT_FAILED;
  errorReason?: CreateUserErrorReason;
  errorMessage?: string;
}

export function setupAccountFailed(data: { message?: string; reason?: CreateUserErrorReason } = {}): SetupAccountFailedAction {
  return {
    type: SETUP_ACCOUNT_FAILED,
    errorMessage: data.message,
    errorReason: data.reason,
  };
}

export const DISMISS_ERROR = 'DISMISS_ERROR' as const;
export interface DismissErrorAction {
  type: typeof DISMISS_ERROR;
}

export function dismissError() {
  return {
    type: DISMISS_ERROR,
  };
}

export const SAVE_SETUP_ACCOUNT_FORM = 'SAVE_SETUP_ACCOUNT_FORM' as const;
export interface SaveSetupAccountFormAction {
  type: typeof SAVE_SETUP_ACCOUNT_FORM;
  data: FormData;
}

export function saveSetupAccountForm(data: FormData): SaveSetupAccountFormAction {
  return {
    type: SAVE_SETUP_ACCOUNT_FORM,
    data,
  };
}

export const SAVE_MERCHANT_DATA = 'SAVE_MERCHANT_DATA' as const;
export interface SaveMerchantDataAction {
  type: typeof SAVE_MERCHANT_DATA;
  merchant: RouteApi.Merchant.MerchantResponse;
}

export function saveMerchantData(merchant: RouteApi.Merchant.MerchantResponse): SaveMerchantDataAction {
  return {
    type: SAVE_MERCHANT_DATA,
    merchant,
  };
}

export type AppActionTypes =
  SetupAccountStartedAction |
  SetupAccountSucceededAction |
  SetupAccountFailedAction |
  SaveSetupAccountFormAction |
  SaveMerchantDataAction;


export const createAccount = (data: FormData) => {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Starting setup account process',
        category: 'Setup Account',
        level: Severity.Info,
      });
      dispatch(setupAccountStarted());
      dispatch(saveSetupAccountForm(data))

      const response = await createNecessaryUserAndMerchant(data);
      if (response.successful === false) {
        switch (response.reason) {
          case 'MerchantExists': {
            addBreadcrumb({
              message: 'Merchant already exists',
              category: 'Setup Account',
              level: Severity.Info,
            });
            return dispatch(setupAccountFailed({
              reason: 'MerchantExists',
            }));
          }
          case 'User Exists': {
            addBreadcrumb({
              message: 'User already exists',
              category: 'Setup Account',
              level: Severity.Info,
            });
            return dispatch(setupAccountFailed({
              reason: 'User Exists',
            }));
          }
          default: {
            captureMessage(`Setup account failed: "${response.message}"`);
            return dispatch(setupAccountFailed({
              message: response.message,
              reason: response.reason,
            }));
          }
        }
      }

      addBreadcrumb({
        message: 'Merchant setup account successful',
        category: 'Setup account',
        level: Severity.Info,
      });

      dispatch(saveMerchantData(response.merchant))

      dispatch(setupAccountSucceeded());
      return dispatch(push('/brand/verify-domain'));
    } catch (err) {
      captureException(err);
      return dispatch(setupAccountFailed({
        message: err?.message,
      }));
    }
  }
}
