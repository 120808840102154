import {Action} from 'redux';
import {SignInState, initialState} from "../../store";
import {
  PasswordResetRequestActions,
  PASSWORD_RESET_REQUEST_STARTED,
  PASSWORD_RESET_REQUEST_SUCCEEDED,
  PASSWORD_RESET_REQUEST_FAILED,
  HidePasswordResetRequestInvalidEmailErrorAction,
  HIDE_PASSWORD_RESET_REQUEST_INVALID_EMAIL_ERROR,
} from '../../actions/sign-in/password-reset-request';
import {
  USER_LOGIN_STARTED,
  USER_LOGIN_SUCCEEDED,
  USER_LOGIN_FAILED,
  UserActions,
  HIDE_CREDENTIALS_ERROR
} from '../../actions/sign-in';

type Actions = PasswordResetRequestActions | UserActions | HidePasswordResetRequestInvalidEmailErrorAction;
export function signInStateReducer(state: SignInState = initialState.signIn, action: Actions): SignInState {
  switch(action.type) {
    case PASSWORD_RESET_REQUEST_STARTED: return {
      ...state,
      passwordResetEmail: '',
      showInvalidPasswordResetRequestError: false,
    }
    case PASSWORD_RESET_REQUEST_SUCCEEDED: return {
      ...state,
      passwordResetEmail: action.email,
      showInvalidPasswordResetRequestError: false,
    }
    case PASSWORD_RESET_REQUEST_FAILED: return {
      ...state,
      passwordResetEmail: '',
      showInvalidPasswordResetRequestError: action.reason === 'NoAccount',
    }
    case USER_LOGIN_STARTED: return {
      ...state,
      showIncorrectCredentialsError: false,
    }
    case USER_LOGIN_FAILED: return {
      ...state,
      showIncorrectCredentialsError: action.reason === 'credentials',
    }
    case USER_LOGIN_SUCCEEDED: return {
      ...state,
      showIncorrectCredentialsError: false,
    }
    case HIDE_CREDENTIALS_ERROR: return {
      ...state,
      showIncorrectCredentialsError: false,
    }
    case HIDE_PASSWORD_RESET_REQUEST_INVALID_EMAIL_ERROR: return {
      ...state,
      showInvalidPasswordResetRequestError: false,
    }
    default: {
      return state;
    }
  }
}
