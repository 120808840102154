import React from 'react';
import colors from '../../constants/colors';

const SVG = ({
  style = {},
  fill = 'none',
  stroke = colors.primaryColor,
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
  className = '',
  transform = '',
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="3.50024" transform="matrix(1 0 0.010928 0.99994 9.04395 9)" stroke={stroke} />
    <path d="M11.5 11.5001L14.4999 14.5001" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SVG;
