import React from 'react';
import { Label } from './IconNew.styles';

import { IIconNewProps } from './interface';

const IconNew: React.FC<IIconNewProps> = ({ children }: IIconNewProps) => (
  <>
    <Label>
      {children}
    </Label>
  </>
);

export default IconNew;
