import React, { useState, useEffect } from 'react';
import './UpdateRouteEmail.scss';
import { Box, Paper } from '@material-ui/core';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import PageHeader from '../_shared/PageHeader/PageHeader';
import PageTitle from '../_shared/PageTitle/PageTitle';
import Paragraph from '../_shared/Paragraph/Paragraph';
import bulletSquare from '../../../assets/images/bullet-square-icon.jpg';
import CTAButton from '../_shared/CTAButton/CTAButton';
import FeaturedImage from '../_shared/FeaturedImage/FeaturedImage';
import routePlusSampleEmail from '../../../assets/images/route-plus-sample-email.svg';
import routeOnlySampleEmail from '../../../assets/images/route-only-sample-email.svg';
import { isRoutePlusInstallation } from '../../../services/api';
import { getMerchant, updateMerchantRequest } from '../../../services/merchant';

const UpdateRouteEmail = (props) => {
  const [title, setTitle] = useState('Almost Finished!');
  const [mainText, setMainText] = useState("To the right you'll see an example of the email your customers will receive when they purchase Route Plus package protection at checkout. The email includes links to:");
  const [secondText, setSecondText] = useState("You have the option of providing this additional tracking capability to all of your customers, whether they've purchased Route Plus package protection or not.");
  const [skipButtonText, setSkipButtonText] = useState('Only send on Route Plus orders');
  const [routeEmail, setRouteEmail] = useState(routePlusSampleEmail);

  useEffect(() => {
    if (!isRoutePlusInstallation()) {
      setRouteEmail(routeOnlySampleEmail);
      setTitle('Order Tracking for Your Customers');
      setMainText("To the right you'll see an example of an optional email we can send to your customers when they make an online purchase from your site. The email includes links to:");
      setSecondText('You have the option of providing this additional tracking capability to all of your customers, with no additional cost to you or your customers.');
      setSkipButtonText('I have my own tracking solution');
    }
  }, []);

  const nextStep = () => {
    props.history.push('/onboarding/successfully');
  };

  const handleSubmitButton = async (e) => {
    const merchant = getMerchant();

    if (!merchant) {
      return;
    }

    const merchantPreferences = Object.assign(merchant.merchant_preferences || {}, {
      optional_tracking_email: true,
    });

    await updateMerchantRequest('merchant_preferences', JSON.stringify(merchantPreferences));

    nextStep();

    e.preventDefault();
  };

  const handleSkipButton = async (e) => {
    const merchant = getMerchant();

    if (!merchant) {
      return;
    }

    const merchantPreferences = Object.assign(merchant.merchant_preferences || {}, {
      optional_tracking_email: false,
    });

    await updateMerchantRequest('merchant_preferences', JSON.stringify(merchantPreferences));

    nextStep();

    e.preventDefault();
  };

  return (
      <div className="update-route-email">
        <OnboardingContainer>
         <Box display="flex">
            <Box width={721} mr="20px" mb="70px">
               <PageHeader>
                  <PageTitle title={title}></PageTitle>
               </PageHeader>
               <Paper className="column">
                  <Paragraph text={mainText}></Paragraph>
                  <ul className="nested-bullet-list">
                     <li>
                        <img srcSet={bulletSquare} className="bullet" />
                        <span>{'A branded web page where your customers can track each order from your store'}</span>
                     </li>
                     <li>
                        <img srcSet={bulletSquare} className="bullet" />
                        <span>{'Download the Route mobile app where customers can see all orders over time'}</span>
                     </li>
                     { isRoutePlusInstallation() && (
                        <li>
                           <img srcSet={bulletSquare} className="bullet" />
                           <span>{'File a claim, should their shipment from you get lost, stolen, or damaged in transit'}</span>
                        </li>
                     )}
                  </ul>
                  <Paragraph text={secondText}></Paragraph>
                  <div className="button-container">
                     <CTAButton text="Send on All Future Orders" href="#" onClick={handleSubmitButton}></CTAButton>
                     <CTAButton text={skipButtonText} href="#" styleName="basic underline" onClick={handleSkipButton}></CTAButton>
                  </div>
               </Paper>
            </Box>
            <Box display="flex" mt="25px" alignItems="flex-start">
               <FeaturedImage src={routeEmail} alt="People on Route"></FeaturedImage>
            </Box>
         </Box>
        </OnboardingContainer>
      </div>
  );
};

export default UpdateRouteEmail;
