import axios from 'axios';
import querystring from 'query-string';
import ReactGA from 'react-ga';
import { ROUTE_API, ROUTE_SHOPIFY_URL } from '../constants';
import { setPlatform } from './api';
import { getCurrentUserId } from './user';

export const getMerchantToken = () => {
  const merchant = JSON.parse(localStorage.getItem('merchant'));
  return merchant.prod_api_secret;
};

export const getHeaderToken = () => localStorage.getItem('token');

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getHeadersWithToken = () => ({
  headers: {
    'Content-Type': 'application/json',
    Token: getHeaderToken(),
  },
});

export const createUser = async (name, password, email, platform_id, phone) => {
  const body = {
    name,
    platform_id,
    password,
    phone,
    primary_email: email,
  };

  let response = {
    status: '',
    message: null,
    data: null,
  };

  try {
    const resp = await axios.post(`${ROUTE_API}/users`, body, getHeaders());

    // Google analytics
    ReactGA.event({ category: 'Onboarding', action: 'User account created' });

    const { data } = resp;

    const collaborator = {
      collaboratorId: data.id,
      email: data.primary_email,
      fullName: data.name,
      firstName: data.name.split(' ')[0],
      role: data.role,
      phone: data.phone,
      token: data.token,
    };

    localStorage.setItem('collaborator', JSON.stringify(collaborator));
    localStorage.setItem('token', collaborator.token);

    response = {
      status: 'success',
      message: 'Account Created successfuly',
      data: collaborator,
    };
  } catch (err) {
    response = {
      status: 'error',
      message: '',
      statusCode: err.response.status,
      data: null,
    };

    if (err.response.data.includes('User already exists')) {
      response.message = 'User is already associated with this shop.';
    } else {
      response.message = 'There was an error creating a user account.';
    }

    console.error('Error trying to create user : ', err.response);
  }

  return response;
};


export const linkMerchant = async (userId, token) => {
  const url = `${ROUTE_API}/users/${userId}/link_merchant`;

  const options = {
    headers: {
      Token: token || localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
  };

  try {
    // Google analytics
    ReactGA.event({ category: 'Onboarding', action: 'Merchant account linked' });

    const res = await axios.post(url, {}, options);

    return res.data;
  } catch (err) {
    console.error('Error linking merchant', {
      payload: {
        userId,
        token
      },
      error: err
    });
  }
};

export const updateMerchant = (merchant) => localStorage.setItem('merchant', JSON.stringify(merchant));

export const updateCollaborator = (collaborator) => localStorage.setItem('collaborator', JSON.stringify(collaborator));

export const getMerchant = () => JSON.parse(localStorage.getItem('merchant'));

export const getCollaborator = () => JSON.parse(localStorage.getItem('collaborator'));

export const uploadStoreMedia = async (key, media) => {
  const merchant = getMerchant();
  const collaborator = getCollaborator();
  merchant.merchantId = merchant.merchantId || merchant.id;
  const url = `${ROUTE_API}/merchants/${merchant.merchantId}/media`;

  const body = {};
  body[key] = media;

  const options = {
    headers: {
      token: collaborator.token,
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios
      .post(url, body, options)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          updateMerchant(res.data[0]);
        }
      })
      .catch((err) => {
        alert('incompatible file, expected file with extension [jpg, jpeg, png]');
      });
  } catch (err) {
    console.error('Error during uploading image: ', err);
  }
};

export const uploadStoreLogo = async (media) => { 
  await uploadStoreMedia('store_logo', media);
};

export const uploadStoreHero = async (media) => {
  await uploadStoreMedia('store_hero', media);
};

export const upsertMerchant = async (key, value, merchantId, token) => {
  const userId = getCurrentUserId();
  const url = `${ROUTE_API}/merchants/${merchantId}?user_id=${userId}`;

  const body = {};
  body[key] = value;

  const options = {
    headers: {
      token,
      'Content-Type': 'application/json',
    },
  };

  try {
    return axios.post(url, body, options);
  } catch (err) {
    console.error('Error during  update merchant: ', err);
  }
};

export const updateMerchantContact = async (params) => {
  const { email, phone, link, slug, merchantId, token } = params;

  const url = `${ROUTE_API}/merchants/${merchantId}/merchant_contact`;
  const slugs = ['billing_contact', 'claim_contact', 'support', 'company', 'returns'];
  if (!slugs.includes(slug)) {
    throw new Error(`Invalid slug value: ${slug}`);
  }

  const body = {
    merchant_id: merchantId,
    email,
    phone,
    link,
    slug,
  };

  const options = {
    headers: {
      token,
      'Content-Type': 'application/json',
    },
  };

  try {
    return axios
      .post(url, body, options);
  } catch (err) {
    console.error('Error during updating merchant contact for merchant: ', merchantId, ', Error: ', err);
  }
};

const getRequestOptions = (token) => ({
  headers: {
    'Content-Type': 'application/json',
    token: token || getMerchantToken(),
  },
});

export const getMerchantContacts = async (token, merchantId) => {
  const url = `${ROUTE_API}/merchants/${merchantId}/merchant_contact`;
  try {
    const contactResp = await axios.get(url, getRequestOptions(token));
    if (contactResp.data) {
      return contactResp.data;
    }
  } catch (err) {
    console.error('Error getting merchant contacts for merchant : ', merchantId, ', Error: ', err);
  }
};

export const getMerchantAsset = async (domain) => {
  const url = `${ROUTE_API}/asset-settings/${domain}`;
  try {
    const contactResp = await axios.get(url);
    if (contactResp.data) {
      return contactResp.data;
    }
  } catch (err) {
    console.error('Error getting thank you page asset for merchant-domain : ', domain, ', Error: ', err);
  }
};

export const updateMerchantRequest = async (key, value) => {
  const merchant = getMerchant();
  if (!merchant) {
    return;
  }

  merchant.merchantId = merchant.merchantId || merchant.id;

  const collaborator = getCollaborator();
  const userId = getCurrentUserId();
  const url = `${ROUTE_API}/merchants/${merchant.merchantId}?user_id=${userId}`;

  const body = {};
  body[key] = value;

  const options = {
    headers: {
      token: collaborator.token,
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios
      .post(url, body, options)
      .then((res) => {
        if (res.data) {
          updateMerchant(res.data);
        }
      });
  } catch (err) {
    console.error('Error during  update merchant: ', err);
  }
};

export const setOnboardingComplete = (merchantId, installMethod, onboardingComplete = false) => {
  let collaborator = localStorage.getItem('collaborator');
  collaborator = JSON.parse(collaborator);
  const authToken = collaborator.token;

  const updateBody = {
    onboarding_complete: onboardingComplete,
    install_method: installMethod,
    status: 'Active',
  };

  const options = {
    headers: {
      token: authToken,
      'Content-Type': 'application/json',
    },
  };

  axios
    .post(`${ROUTE_API}/merchants/${merchantId}`, updateBody, options)
    .then((res) => {
      axios
        .get(`${ROUTE_API}/users/${collaborator.collaboratorId}/merchants`, options)
        .then((resp) => {
          let [merchant] = resp.data;
          merchant = {
            ...merchant,
            merchantId: merchant.id,
            allMerchants: resp.data.length > 1 ? resp.data : [],
          };

          if (merchant.allMerchants.length > 1) {
            localStorage.setItem('multipleStores', true);
            merchant.viewAs = merchant.store_name;
          }

          localStorage.setItem('merchant', JSON.stringify(merchant));
        })
        .catch((err) => {
          console.error('Error getting merchants: ', { err });
        });
    })
    .catch((err) => {
      console.error('New System Error updating merchant: ', err);
    });
};

export const getMerchantPreference = (merchant, key) => {
  if (!merchant || !merchant.merchant_preferences) {
    return false;
  }

  return merchant.merchant_preferences[key];
};
export const shouldHideRevenueData = (userSession) => {
  try {
    if (userSession && userSession.user && userSession.user.role === 'admin') {
      return false;
    }
    const merchant = userSession && userSession.activeBrand;
    const revenueData = getMerchantPreference(merchant, 'hide_revenue_data');
    return !!revenueData;
  } catch (error) {
    console.log('Error getting basic level access', error);
  }
  return false;
};

export const shouldShowOrderBrandedContent = (userSession) => {
  try {
    if (process.env.REACT_APP_STAGE !== 'prod') {
      return true;
    }
    const merchant = userSession && userSession.activeBrand;
    const match = getMerchantPreference(merchant, 'show_order_branded_content');
    return !!match;
  } catch (error) {
    console.log('Error getting basic level access', error);
  }
  return false;
};

export const getMerchantOptStatus = (merchant_preferences) => {
  let status = '-';

  if (merchant_preferences && Object.keys(merchant_preferences).length > 0) {
    const isFullCoverage = !!merchant_preferences.merchant_supplied_insurance;
    const isMixedCoverage = !!merchant_preferences.opt_out && isFullCoverage;

    if (isMixedCoverage) {
      status = '(Mixed Coverage)';
    } else {
      status = isFullCoverage ? 'HIDDEN (Full Coverage)' : (!!merchant_preferences.opt_in ? 'OFF' : 'ON');
    }
  }
  return status;
};

export const shopifyUpdateScripts = async (shopDomain) => {
  const url = `${ROUTE_SHOPIFY_URL}/update-scripts`;

  const body = {
    shop: shopDomain,
  };

  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    return axios
      .post(url, querystring.stringify(body), options);
  } catch (err) {
    console.error('Error update shopify route plus merchant:', err);
  }
};

export const requestFullCoverage = async (merchantId, coverageType, token, merchantEmail) => {
  const url = `${ROUTE_API}/merchants/${merchantId}/request_full_coverage`;

  const body = {
    coverage_type: coverageType,
    merchant_email: merchantEmail || '',
  };

  const options = {
    headers: {
      Token: token,
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(url, body, options);

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getCalculateDealSize = (merchant) => {
  const dealSizeOrderCount = (merchant && merchant.deal_size_order_count) || 0;

  return isNaN(dealSizeOrderCount) || dealSizeOrderCount == null ? 0 : dealSizeOrderCount;
};

export const hasDealSizeLimitAmount = (merchant) => {
  return getCalculateDealSize(merchant) >= 500;
};

export const getMerchantServiceTier = async (token, merchantId) => {
  const url = `${ROUTE_API}/merchants/${merchantId}/service-tier`;
  try {
    const serviceTierResp = await axios.get(url, getRequestOptions(token));
    if (serviceTierResp.data) {
      return serviceTierResp.data;
    }
  } catch (err) {
    console.error('Error getting service tier for merchant : ', merchantId, ', Error: ', err);
  }
};
