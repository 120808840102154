import styled, { css } from 'styled-components';
import Colors from '../../../../../../constants/colors';

export const Container = styled.div`
  padding: 24px 72px;
  max-width: 100%;
`;

export const Link = styled.button`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  border: none;
  color: ${Colors.primaryColor};
  display: flex;
  margin-top: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 1s;
  background: none;

  svg {
    margin-right: 3px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const Header = styled.header`
  max-width: 536px;
  width: 100%;

  .title {
    margin-top: 8px;
    margin-bottom: 12px;
    cursor: auto;
  }

  .subtitle {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

export const Widget = styled.div`
  background: #ffffff;
  border-radius: 24px;
  padding: 24px 32px;
  margin-top: 9px;
`;

export const Columns = styled.div`
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Column = styled.div<any>`
  /* Background-2 */
  border-radius: 16px;
  margin-right: 24px;
  max-width: 100%;
  width: ${(props) => props.maxWidth}px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Line = styled.hr`
  background: ${Colors.black};
  height: 1px;
  opacity: 0.12;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Preview = styled.div`
  background: #fbfbfb;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 535px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PreviewHeader = styled.div`
  display: flex;
  width: calc(100% - 32px);
  padding: 16px 16px 16px 16px;
  justify-content: space-between;
  margin-top: -9px;
`;

export const PreviewTitle = styled.h3`
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
`;

export const PreviewCloseButton = styled.img<any>`
  cursor: pointer;
`;

export const Notification = styled.div`
  background: ${Colors.primaryColor};
  border-radius: 16px;
  position: absolute;
  padding: 9px 22px;
  color: ${Colors.white};
  left: calc(50% - 120px);
  animation-name: toast;
  animation-duration: 1.5s;
  transition: all 0.5s;
  bottom: 0;

  @keyframes toast {
    from {
      bottom: 0px;
      opacity: 0;
    }
    to {
      bottom: 80px;
      opacity: 1;
    }
  }
`;

export const Blank = styled.div<any>`
  display: block;
  height: ${(props) => props.height}px;
`;

export const ButtonHidden = styled.button<any>`
  border: none;
  background: none;
  padding: 0;
`;


export const Box = styled.div<any>`

`;

export const InnerContainer = styled.div<any>`
  display: flex;
  justify-content: space-between;
  background: #fbfbfb;
  border-radius: 16px;
  padding: 12px 16px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: #eeeeee;
    `};
`;
