import React, { useEffect } from 'react';
import { Box, Grid, ClickAwayListener } from '@material-ui/core';
import { addBreadcrumb, Severity } from '@sentry/browser';
import { motion } from 'framer-motion';

import Card from '../Card';

import dictionary from '../../../../constants/dictionary';
import { createCsmInteraction } from '../../../../services/merchant_interaction';

import {
  Container,
  Title,
  SubTitle,
  Description,
  Button,
  ButtonLinear,
  TitleSuccess,
  SubTitleSuccess,
  Button2,
  ButtonClose,
  CloseButton,
} from './GetRouteProThanksModal.styles';

import CloseIconHover from '../../../../assets/images/close-icon-hover.svg';
import RouteProLgIcon from '../../../../assets/images/route-pro-badge-lg.svg';
import RouteProWidgetBgMdDark from '../../../../assets/images/route-pro-widget-bg-md-dark.png';

import { IProps } from './interface';

const container = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const GetRouteProThanksModal: React.FC<IProps> = ({
  children,
  titleSuccess,
  messageSuccess,
  tryOutButton,
  closeButton,
  onTryOutPress,
  onClosePress,
}: IProps) => {
  return (
    <>
      <Container>
        <ClickAwayListener onClickAway={onClosePress}>
          <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
            transition={{ ease: 'easeOut', duration: 1, delay: 0.25 }}
          >
            <Card
              image={RouteProWidgetBgMdDark}
              style={{
                backgroundColor: '#eee',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: 450,
                padding: 60,
              }}
            >
              <Grid container direction="column" alignItems="center" justify="center">
                <Grid item lg={10}>
                  <Box mb={8} mt={4}>
                    <img src={RouteProLgIcon} alt="route pro" />
                  </Box>
                </Grid>
                {titleSuccess && (
                  <Grid item lg={10}>
                    <Box mb={4}>
                      <TitleSuccess>{titleSuccess}</TitleSuccess>
                    </Box>
                  </Grid>
                )}
                {messageSuccess && (
                  <Grid item lg={10}>
                    <Box mb={8}>
                      <SubTitleSuccess dangerouslySetInnerHTML={{ __html: messageSuccess }} />
                    </Box>
                  </Grid>
                )}
                {tryOutButton && (
                  <Grid item lg={10}>
                    <Box mb={2}>
                      <Button2 onClick={onTryOutPress}>{tryOutButton}</Button2>
                    </Box>
                  </Grid>
                )}
                {closeButton && (
                  <Grid item lg={10}>
                    <Box mb={2}>
                      <ButtonClose onClick={onClosePress}>{closeButton}</ButtonClose>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <CloseButton
                srcSet={CloseIconHover}
                onClick={() => {
                  onClosePress();
                }}
              />
            </Card>
          </motion.div>
        </ClickAwayListener>
      </Container>
    </>
  );
};

export default GetRouteProThanksModal;
