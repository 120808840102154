import React, { useState } from "react"
import { RadioGroup, makeStyles, Theme, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux"

import { RouteRadioButton, Button, TextArea } from "../../../components";
import { State } from "./../../../../Onboarding-2/store"
import { sendEmailInstructions as sendEmailInstructionsAction } from "../../../actions/verify-domain"
import { EmailType } from "../../../api/merchants";

const useStyles = makeStyles((theme: Theme) => ({
  radioGroupOption: {
    marginTop: 15,
  },
  radioGroupEmail: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    paddingLeft: 40,
  },
  emailButton: {
    flex: '0 0 100%',
    marginRight: '30%'
  },
  childGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
    paddingLeft: 40
  },
  radioEmail: {
    flex: '0 0 50%',
    marginBottom: 16
  },
  divider: {
    margin: "25px 0",
    [theme.breakpoints.up("sm")]: {
      margin: "43px 0",
    }
  },
  textAreaOption: {
    marginBottom: 16
  }
}))

const getEmailOptions = (domain: string) => {
  const emails = [
    `hostmaster@${domain}`,
    `postmaster@${domain}`,
    `webmaster@${domain}`,
  ]
  return emails
}

const OptionGroup = (props: any) => {
  const classes = useStyles(props);
  const merchantForm = useSelector((state: State) => state.app.brand.form)
  const merchant = useSelector((state: State) => state.app.brand.merchant) // get hash content from back-end || verification_secret_key
  const loading = useSelector((state: State) => state.app.loading)
  const [option, setOption] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const dispatch = useDispatch()

  const domain = merchant?.store_domain as string;
  const emails = getEmailOptions(domain)

  const onChangeVerifyType = (event: { target: HTMLInputElement }) => {
    if (event.target.value === option) return;

    setEmail('')
    setOption(event.target.value)
  }

  const onChangeEmails = (event: { target: HTMLInputElement }) => {
    if (option !== "verify-email") return

    setEmail(event.target.value)
  }

  const sendInstructions = (type: EmailType) => {
    let mailto = email;
    if (type !== "email") {
      mailto = merchantForm?.email as string
    }

    dispatch(sendEmailInstructionsAction(mailto, type))
  }

  return (
    <RadioGroup className={classes.radioGroupOption} aria-label="verify-type" name="verify" value={option} onChange={onChangeVerifyType}>

      {/* <RouteRadioButton
        label="Verify by Email (Fastest)"
        value="verify-email"
        description="Choose an address below."
      />

      <RadioGroup className={classes.radioGroupEmail} aria-label="emails" name="emails" value={email} onChange={onChangeEmails}>
        {emails.map(value => (
          <RouteRadioButton
            key={value}
            className={classes.radioEmail}
            label={value}
            value={value}
          />
        ))}

        <Button
          onClick={() => sendInstructions("email")}
          disabled={option !== "verify-email" || email === ""}
          maxWidth={200}
          className={classes.emailButton}
          loading={option === "verify-email" && loading}
        >Send Email Verification</Button>
      </RadioGroup>

      <Divider className={classes.divider} /> */}

      <RouteRadioButton
        label="Verify by File Upload"
        value="verify-file-upload"
        description={`This file should be publicly accessible at 
        https://${domain}/route-brand-verification.txt`}
      />

      <div className={classes.childGroup}>
        <TextArea
          label="File Name"
          value="route-brand-verification.txt"
          className={classes.textAreaOption}
        />
        <TextArea
          label="File Content"
          value={merchant?.verification_secret_key || ""}
          className={classes.textAreaOption}
        />

        <Button
          onClick={() => sendInstructions("file-upload")}
          disabled={option !== "verify-file-upload"}
          maxWidth={200}
          loading={option === "verify-file-upload" && loading}
        >Email Instructions</Button>
      </div>

      <Divider className={classes.divider} />

      <RouteRadioButton
        label="Verify by TXT Record"
        value="verify-txt-record"
        description="You can create a TXT record on the domain if it’s not a
        subdomain. The record should have the following value:"
      />

      <div className={classes.childGroup}>
        <TextArea
          label="File Content"
          value={`route-brand-verification=${merchant?.verification_secret_key}`}
          height={45}
          className={classes.textAreaOption}
          maxWidth={420}
        />

        <Button
          onClick={() => sendInstructions("text-record")}
          disabled={option !== "verify-txt-record"}
          maxWidth={200}
          loading={option === "verify-txt-record" && loading}
        >Email Instructions</Button>
      </div>

    </RadioGroup>
  )
}

export default OptionGroup;
