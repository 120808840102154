import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Flex, { FlexItem } from 'styled-flex-component';
import { Container, Marker, MarkerIcon, ShipmentType, Metadata } from './style';
import { StatusLabel } from '../../utils/shipment';

const DeliveryLineItem = (props) => {
  return (
    <Flex {...props}>
      <FlexItem>
        <Marker {...props}>
          <MarkerIcon {...props}></MarkerIcon>
        </Marker>
      </FlexItem>
      <FlexItem>
        <ShipmentType {...props}>{props.info.details || StatusLabel(props.info.status)}</ShipmentType>
        <Metadata {...props}>
          {props.info.name}{' '}
          {props.info.date && (
            <React.Fragment>
              -{' '}
              <Moment tz="UTC" format="MMM D, hh:mm a" parse="YYYY-MM-DDTHH:mm:ss.SSSZ">
                '{props.info.date}'
              </Moment>
            </React.Fragment>
          )}{' '}
        </Metadata>
      </FlexItem>
    </Flex>
  );
};

export default DeliveryLineItem;
