import React from 'react';
import { hotjar } from 'react-hotjar';
import { HOTJAR_ID, HOTJAR_VERSION } from '../../../../constants';

type HotjarProps = {
  render: boolean;
};

const Hotjar: React.FC<HotjarProps> = (props: HotjarProps) => {
  if (props.render) {
    hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);
  }

  return (
    <></>
  );
};

export default Hotjar;
