import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import '../_shared/_shared.scss';
import './Orders.scss';
import axios from 'axios';
import { Box, Grid } from '@material-ui/core';
import TableAndFormContainer from '../_shared/TableAndFormContainer/TableAndFormContainer';
import { getOrders } from '../../../services/api';
import Typography from '../components/Typography';

const Orders = (props) => {
  const [cursor, updateCursor] = useState('');
  const [limit, updateLimit] = useState(10);
  const [searchValue, updateSearchValue] = useState('');
  const [searchKey, updateSearchKey] = useState('');
  const [selected, updateSelected] = useState({});
  const [orders, updateOrders] = useState({ results: [], count: 0 });
  const [isLoading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0); // counter is used to trigger state refresh
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [isSearching, setIsSearching] = useState(false);

  const checkNextPage = async ({ prevCursor, nextCursor, currentResults }) => {
    const source = axios.CancelToken.source();
    const params = { limit, cursor: nextCursor, start_date: formatDate(startDate) };
    const result = await getOrders(source.token, props.userSession.activeBrand.prod_api_secret, params);

    updateCursor(result.data.cursor || '');
    updateOrders({ results: [...currentResults, ...result.data.results] });
  };

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();
    const params = { limit, cursor, start_date: formatDate(startDate) };

    const prevCursor = cursor;
    let nextCursor = '';
    let unmounted = false;

    if (searchKey) {
      params.search_key = searchKey;
    }
    if (searchValue) {
      params.search_value = searchValue;
    }

    if (searchKey && searchValue) {
      setIsSearching(true);
    }

    getOrders(source.token, props.userSession.activeBrand.prod_api_secret, params).then(
      (res) => {
        if (!unmounted) {
          nextCursor = res.data.cursor || '';
          if (searchKey && searchValue) {
            updateCursor(nextCursor || '');
            updateOrders({ results: [...res.data.results] });
          } else {
            checkNextPage({
              prevCursor: prevCursor || '',
              nextCursor: nextCursor || '',
              currentResults: [...orders.results, ...res.data.results],
            });
          }
          setIsSearching(false);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
        if (!axios.isCancel(err) && !unmounted) {
          updateCursor('');
          updateOrders({});
          setLoading(false);
        }
      },
    );

    // return cleanup function, this is called when the component unmounts, or renders, here we will cancel pending requests
    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [counter]);

  useEffect(() => {
    updateCursor('');
    setCounter(counter + 1);
  }, [limit]);

  const formatDate = (date) => {
    if (!date) {
      return moment().utc().format('YYYY-MM-DD');
    }
    return moment(date).utc().format('YYYY-MM-DD');
  };

  const searchOrders = () => {
    if (searchKey && searchValue) {
      ReactGA.event({ category: 'Orders', action: 'Search Executed', label: searchKey || 'No Search Key' });
    }
    updateCursor('');
    setCounter(counter + 1);
  };

  const fetchMoreOrders = () => {
    setCounter(counter + 1);
  };

  const orderSelected = (order) => {
    ReactGA.event({ category: 'Orders', action: 'Row Click / Drawer View', label: String((order && order.id) || '') });
    updateSelected(order);
  };

  return (
    <div className="orders-container">
      <TableAndFormContainer
        pageHeader="Order"
        headerList={[
          'Order Date',
          'Store Order Number',
          'Route Order ID',
          'Customer Name',
          'Order Subtotal',
          'Protection Cost',
        ]}
        dataProps={[
          'source_created_on',
          ['source_order_number', 'source_order_id'],
          'order_number',
          ['first_name', 'last_name'],
          'subtotal',
          'paid_to_insure_usd',
        ]}
        isLoading={isLoading}
        setLoading={setLoading}
        data={orders.results}
        count={String(orders.results?.length)}
        cursor={cursor}
        setStartDate={setStartDate}
        limit={limit}
        updateSearchKey={updateSearchKey}
        updateSearchValue={updateSearchValue}
        updateLimit={updateLimit}
        updateOrders={updateOrders}
        updateCursor={updateCursor}
        search={searchOrders}
        onSelect={orderSelected}
        selected={selected}
        searchPlaceholder="Select Category"
        searchDropdownItems={{
          route_order_number: 'Route Order Number',
          source_order_number: 'Store Order Number',
          tracking_number: 'Tracking Number',
          customer_email: 'Customer Email',
          customer_name: 'Customer Name',
        }}
        checkNextPage={checkNextPage}
        searchAsDropdown={true}
        fetchMoreOrders={fetchMoreOrders}
        userSession={props.userSession || null}
        isSearching={isSearching}
      />
      <Grid container direction="row" justify={'flex-end'} alignItems={'center'}>
        <Box mb={4} pr="80px">
          <Typography variant="h8" component="h4" color="gray">
            *Data could be delayed up to 24 hours.
          </Typography>
        </Box>
      </Grid>
    </div>
  );
};

export default Orders;
