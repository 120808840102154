
import {push} from 'connected-react-router';
import {addBreadcrumb, Severity} from '@sentry/browser';
import {Action} from 'redux';
import {FormData} from '../../pages/account-creation/account-creation-form'
import {
  resetPassword,
  createUserAndLinkToMerchant,
  createUser,
  requestPasswordReset,
  CreateUserResponse,
  createMerchant,
  merchantExists,
} from '../../api'
import {ThunkDispatch, AccountCreationState} from '../../store';


async function createForAccountThatAlreadyExists(data: {email: string; password: string; passwordGuid: string}): Promise<CreateUserResponse|undefined> {
  try {
    addBreadcrumb({
      message: '"createForAccountThatAlreadyExists" - starting password reset',
      category: 'Account Creation',
      level: Severity.Info,
    });
    const resetSuccessful = await resetPassword({
      password: data.password,
      guid: data.passwordGuid,
    });
  
    if (!resetSuccessful) {
      addBreadcrumb({
        message: '"createForAccountThatAlreadyExists" - expired password reset guid',
        category: 'Account Creation',
        level: Severity.Info,
      });
      await requestPasswordReset({
        email: data.email,
        source: 'onboarding',
      });
  
      return {
        successful: false,
        reason: 'PasswordResetGuidExpired',
      };
    }
  } catch (err) {
    addBreadcrumb({
      message: `"createForAccountThatAlreadyExists" failure: "${err.message}"`,
      category: 'Account Creation',
      level: Severity.Error,
    });
    throw err;
  }  
}

async function createForMerchantThatExists(formData: FormData, accountCreationData: AccountCreationState): Promise<CreateUserResponse> {
  try {
    // Shopify - create user using existing merchant (will be automatically linked on user creation)
    const merchant = accountCreationData.merchant;
    if (!merchant || !merchant.platform_id || !merchant.prod_api_secret) {
      throw new Error('Merchant needs to be set in "AccountCreation" state in order to create account');
    }

    addBreadcrumb({
      message: '"createForMerchantThatExists" - starting user creation with linking',
      category: 'Account Creation',
      level: Severity.Info,
    });
  
    return await createUserAndLinkToMerchant({
      email: formData.email,
      password: formData.password,
      phone: formData.phoneNumber,
      name: formData.fullName,
      platformId: merchant.platform_id,
      merchantToken: merchant.prod_api_secret,
    });
  } catch (err) {
    addBreadcrumb({
      message: `"createForMerchantThatAlreadyExists" failure: "${err.message}"`,
      category: 'Account Creation',
      level: Severity.Error,
    });
    throw err;
  }
}

async function createForMerchantThatDoesNotExist(formData: FormData, accountCreationData: AccountCreationState): Promise<CreateUserResponse> {
  try {
    const {merchantCreationData} = accountCreationData;
    const domain = formData.domain || merchantCreationData?.domain;

    if (!domain) {
      throw new Error('Expected domain to be populated for account creation');
    }

    const platformId = merchantCreationData?.platformId || 'api';

    addBreadcrumb({
      message: '"createForMerchantThatDoesNotExist - checking for domain existence',
      category: 'Account Creation',
      level: Severity.Info,
    });

    // We are checking to see if the  merchant already exists before we try to create the user account
    // because if we create the user account and then find out that the merchant domain is already used
    // then the user will run into an issue when re-submitting because then the user account already exists
    const domainUsed = await merchantExists(domain);
    if (domainUsed) {
      return {
        successful: false,
        reason: 'MerchantExists',
      };
    }

    addBreadcrumb({
      message: '"createForMerchantThatDoesNotExist" - starting user creation',
      category: 'Account Creation',
      level: Severity.Info,
    });

    const userResponse = await createUser({
      email: formData.email,
      password: formData.password,
      phone: formData.phoneNumber,
      name: formData.fullName,
      platformId,
    });

    if (!userResponse.successful) {
      return userResponse;
    }

    addBreadcrumb({
      message: '"createForMerchantThatDoesNotExist" - starting merchant creation',
      category: 'Account Creation',
      level: Severity.Info,
    });

    // Prefer form data and then url data
    const merchantData = {
      platform_id: platformId,
      store_domain: domain,
      store_name: formData.storeName || merchantCreationData?.name,
      country: formData.country || merchantCreationData?.country,
      currency: formData.currency || merchantCreationData?.currency,
    };

    // The user and merchant should be linked after this because this is a user with "merchant" as its role
    const result = await createMerchant({
      userToken: userResponse.user.token,
      userId: userResponse.user.id,
      data: merchantData,
    });
    if (!result.successful) {
      return result;
    }

    return userResponse;
  } catch (err) {
    addBreadcrumb({
      message: `"createForMerchantThatDoesNotExist" failure: "${err.message}"`,
      category: 'Account Creation',
      level: Severity.Error,
    });
    throw err;
  }
}

export async function createNecessaryUserAndMerchant({formData, accountCreationData}: {
  formData: FormData,
  accountCreationData: AccountCreationState,
}): Promise<CreateUserResponse|undefined> {
  if (accountCreationData.passwordGuid) {
    return await createForAccountThatAlreadyExists({
      email: formData.email,
      password: formData.password,
      passwordGuid: accountCreationData.passwordGuid,
    });
  } else {
    // We need to create a user unconditionally. If we already have a merchant we will use that
    // when creating the user to automatically link. Otherwise we have to create the merchant
    // after we create the user.
    return accountCreationData.merchant ?
      await createForMerchantThatExists(formData, accountCreationData) :
      await createForMerchantThatDoesNotExist(formData, accountCreationData);
  }
}
