import React from 'react';
import propTypes from 'prop-types';
import './SubmitButton.scss';

const SubmitButton = ({
  styleName, text, disabled, isLoading,
}) => (
    <button className={`btn ${styleName}`} type="submit" disabled={disabled || isLoading}>{text}</button>
);

SubmitButton.propTypes = {
  text: propTypes.string.isRequired,
};

export default SubmitButton;
