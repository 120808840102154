import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { Grid, Box, Paper } from '@material-ui/core';
import PageTitle from '../_shared/PageTitle/PageTitle';
import Paragraph from '../_shared/Paragraph/Paragraph';
import PageHeader from '../_shared/PageHeader/PageHeader';
import peopleOnRoute from '../../../assets/images/people-on-route.svg';
import { clearAllStorage } from '../../../services/api';
import FeaturedImage from '../_shared/FeaturedImage/FeaturedImage';
import OnboardingContainer from '../_shared/OnboardingContainer/OnboardingContainer';
import './SetUpPassword.scss';
import SetUpPasswordForm from './SetUpPasswordForm/SetUpPasswordForm';
import TermsAndConditionsLink from '../../_shared/TermsAndConditionsLink/TermsAndConditionsLink';

const SetUpPassword = (props) => {
  const [query, setQuery] = useState('');
  const [paragraphes, setParagraphes] = useState([]);

  useEffect(() => {
    clearAllStorage();

    setParagraphes([
      'You\'ve successfully installed Route on your store, but there\'s just a bit more to do. Let\'s start by setting a password for your Route account.',
      'This will be used to sign in to the Route "Brand Portal" - a unique set of tools to help you customize how Route is implemented on your site, as well as track orders, shipments, and any resulting insurance claims.',
    ]);

    const query = queryString.parse(props.location.search);

    if (query) {
      setQuery(query);
    }
  }, []);

  const onRequestCompleted = () => {
    props.history.push('/onboarding/route-package-protection');
  };

  const onInvalidAccess = () => {
    props.history.push('/onboarding');
  };

  return (
    <div>
      <OnboardingContainer className="account-creation">
        <Box display="flex">
          <Box width={721} mr="60px" mb="70px">
            <PageHeader>
              <PageTitle title="Installation Complete!" alignment="no-margin left"></PageTitle>
              <PageTitle title="But there's more..."></PageTitle>
            </PageHeader>
            <Paper className="column column--account-creation">
              <Box mr="18px">
                {paragraphes.map((text, i) => <Paragraph key={i} text={text}></Paragraph>)}
              </Box>
              <SetUpPasswordForm onRequestCompleted={onRequestCompleted} onInvalidAccess={onInvalidAccess} query={query} setUserSession={props.setUserSession} userSession={props.userSession}></SetUpPasswordForm>
              <TermsAndConditionsLink />
            </Paper>
          </Box>
          <Box display="flex" mt="130px" alignItems="flex-start">
            <FeaturedImage src={peopleOnRoute} alt="People on Route"></FeaturedImage>
          </Box>
        </Box>
      </OnboardingContainer>
    </div>
  );
};

export default SetUpPassword;
