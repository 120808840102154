import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { captureMessage, Severity } from '@sentry/browser';
import { getMerchantPreference, getMerchantAsset, upsertMerchant } from '../../../../services/merchant';
import './UpdatePaymentMethod.scss';
import TitleAndNav from './TitleAndNav';
import ThankYouPageTop from '../../../../assets/icons/thank-you-page-top-half.svg';
import ThankYouPageBottom from '../../../../assets/icons/thank-you-page-bottom.svg';
import ToggleSwitch from '../../../../assets/icons/toggle-switch.js';
import ToggleSwitchOff from '../../../../assets/icons/toggle-switch-off.js';
import ThankYouTrackingOn from '../../../../assets/icons/thank-you-tracking-on.svg';
import ThankYouTrackingOff from '../../../../assets/icons/thank-you-tracking-off.svg';
import OrderUpdate from '../../../../assets/images/order-update.png';
import Phone from '../../../../assets/images/app-tracking.gif';
import Button from '../../../Button/Button';
import './ThankYouPage.scss';

const ThankYouPageTrackingLink = (props) => {
  const [dynamicCSS, setDynamicCSS] = useState('_blank');
  const [assetVersion] = useState(getMerchantPreference(props.userSession.activeBrand, 'asset_version'));
  const [dynamicContent, setDynamicContent] = useState('');

  useEffect(() => {
    (async function () {
      try {
        const asset = await getMerchantAsset(props.userSession.activeBrand.store_domain);
        setDynamicContent(asset.asset_settings.asset_content.raw_html);
        setDynamicCSS(asset.asset_settings.asset_content.css_url);
      } catch (err) {
        captureMessage(
          `Error getting merchant asset for ${
            props.userSession && props.userSession.activeBrand && props.userSession.activeBrand.store_domain
          }`,
          Severity.Error,
        );
      }
    })();
    const assetLive = getMerchantPreference(props.userSession.activeBrand, 'asset_live');

    props.setTrackingLinkOn(assetLive);
  }, []);

  const toggleSwitch = () => {
    props.setTrackingLinkOn(!props.trackingLinkOn);
  };

  const handleSubmitButton = async () => {
    const { merchant_preferences, id, prod_api_secret, has_route_plus } = props.userSession.activeBrand;

    const merchantPreferences = Object.assign(merchant_preferences || {}, {
      asset_live: props.trackingLinkOn,
    });

    const resp = await upsertMerchant('merchant_preferences', JSON.stringify(merchantPreferences), id, prod_api_secret);
    props.setActiveSessionData(resp.data);
    props.thankYouPageSavedSettings();
  };

  return (
    <div className="update-company-details-parent-container">
      <div className="update-company-details-header">
        <TitleAndNav title="Thank You Page Tracking Link" close={props.closeDrawer} />
      </div>
      <div className="update-company-details">
        <div className="update-company-details-mobile-preview" style={{ borderRight: '0.5px solid #dedede' }}>
          <img src={ThankYouPageTop} alt="Thank You Page Map" className="thank-you-page-top" />
          {props.trackingLinkOn ? (
            <div className="thank-you-page-dynamic-block">
              <div
                className="thank-you-page-dynamic-block-scaled-container"
                // conditional styling needed to support the static (v1) widget
                style={assetVersion === 'static' ? { margin: '-8% -10% -7% -22%' } : {}}
              >
                <h2 className="thank-you-page-dynamic-block-header">Order Updates</h2>
                <link rel="stylesheet" type="text/css" href={dynamicCSS}></link>
                {ReactHtmlParser(dynamicContent)}
              </div>
            </div>
          ) : (
            <div className="thank-you-page-dynamic-block-blank">
              <img src={ThankYouTrackingOff} alt="Tracking Mobile View" className="phone-orders-web-icon" />
            </div>
          )}
          <img src={ThankYouPageBottom} alt="Customer Information" className="thank-you-page-bottom" />
        </div>
        <div className="update-company-details-container">
          <span className="download-page-text-upper">
            After your customers make an online purchase from your site, you have the option to provide them with a link
            to Route’s premium visual order tracking on your site’s “Thank You” page.
            <br />
            <br /> Route’s premium visual tracking is FREE for all of your customers.
          </span>
          <div id="line-break--bottom" style={{ marginTop: '40px' }}></div>
          <div className="update-company-details-section-title" style={{ marginTop: '8px' }}>
            <div className="thank-you-table-cell-row">
              <div className="thank-you-table-cell">Tracking Link</div>
              <div className="toggle-button" onClick={toggleSwitch}>
                {props.trackingLinkOn ? <ToggleSwitch /> : <ToggleSwitchOff />}
              </div>
            </div>
          </div>
          <div id="line-break--bottom"></div>
          <div className="download-page-text">
            <div>
              The Tracking Link will send customers to the Route App download page on{' '}
              <a href="https://apps.apple.com/us/app/route-package-order-tracker/id1449803898" target="_blank">
                Apple
              </a>{' '}
              or{' '}
              <a href="https://play.google.com/store/apps/details?id=com.route.app" target="_blank">
                Google Play.
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="update-company-details-save-container">
        <div className="update-company-details-save-button">
          <Button hoverState="true" className="thank-you-page-save-button" text="Save" onClick={handleSubmitButton} />
        </div>
      </div>
    </div>
  );
};
export default ThankYouPageTrackingLink;
