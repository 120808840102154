export const Carriers = {
    '4px': '4PX',
    'apc': 'APC',
    'amazon': 'Amazon',
    'australia-post': 'Australia Post',
    'bluedart': 'Bluedart',
    'canada-post': 'Canada Post',
    'china-post': 'China Post',
    'brazil-correios': 'Correios',
    'dhl': 'DHL Express',
    'dhl-global-mail': 'DHL eCommerce',
    'dhl-global-mail-asia': 'DHL eCommerce Asia',
    'dpd': 'DPD',
    'interlink-express': 'DPD Local',
    'dpd-uk': 'DPD UK',
    'delhivery': 'Delhivery',
    'fedex': 'FedEx',
    'fedex-uk': 'FedEx UK',
    'gls': 'GLS',
    'globegistics': 'Globegistics',
    'japan-post': 'Japan Post',
    'la-poste-colissimo': 'La Poste',
    'new-zealand-post': 'New Zealand Post',
    'newgistics': 'Newgistics',
    'postnl': 'PostNL',
    'postnord': 'PostNord',
    'purolator': 'Purolator',
    'sf-express-webhook': 'SF Express',
    'sagawa': 'Sagawa(EN)',
    'singapore-post': 'Singapore Post',
    'tnt': 'TNT',
    'ups': 'UPS',
    'usps': 'USPS',
    'whistl': 'Whistl',
    'taqbin-jp': 'Yamato',
}