import React, {
  useState, useEffect, useRef, Fragment,
} from 'react';
import moment from 'moment';
import './DateRangePicker.scss';
import './rsuite-default.css';
import { DateRangePicker } from 'rsuite';

const {
  allowedMaxDays,
  afterToday,
  combine,
} = DateRangePicker;

const DateRangePickers = ({
  title, startDate, onSelectDate, onClean, isClaim, placeholder,
}) => {
  const [dates, setDates] = useState();
  const [open, setOpen] = useState(false);

  const node = useRef();

  const dateRange = (from, to, interval) => {
    const ret = [];

    const toDate = moment(to, 'DD-MM-YYYY');
    let fromDate = moment(from, 'DD-MM-YYYY');
    while (toDate < fromDate) {
      ret.push(fromDate.format('YYYY-MM-DD'));
      fromDate = moment(fromDate).subtract(interval, 'months');
    }
    return ret;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <div className={`daterange-picker ${open && 'daterange-picker-enter-active'}`} ref={node}>
        <label className="daterange-title">{title}</label>
        <DateRangePicker
          placeholder={placeholder}
          defaultValue={[
            new Date(moment().subtract(1, 'months')),
            new Date(),
          ]}
          format="MMM D, YYYY"
          onChange={(dates) => {
            const initialDate = moment(dates[0]).format('YYYY-MM-DD');
            let endDate = moment(dates[1]).format('YYYY-MM-DD');

            if (initialDate === endDate) {
              endDate = moment(dates[1]).add(1, 'days').format('YYYY-MM-DD');
            }
            onSelectDate(initialDate, endDate);
          }}
          disabledDate={!isClaim ? combine(allowedMaxDays(31), afterToday()) : afterToday()}
          onClean={onClean}
        >
        </DateRangePicker>
      </div>
    </Fragment>
  );
};

export default DateRangePickers;
