import styled, { keyframes } from 'styled-components';

export const MaxWidthContainer = styled.div`
  max-width: 1120px;
`;

export const GraphContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 0px;
  height: 340px;
  position: relative;
  box-sizing: border-box;
  background-color: #FFF;
  border-radius: 24px;
  padding: 30px;
  canvas {
  padding-top: 0px !important;
    height: 280px !important;
  }
`;

export const DatepickerContainer = styled.div`
    background: #F7F7F7;
    border-radius: 18px;
    padding: 17px;
`;

export const loadingKeyframes = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.65; }
`;

export const LoadingDiv = styled.div`
  width:310px;
  height:30px;
  background-color: #E5E5E5;
  animation: ${loadingKeyframes} 2s ease-in-out 0s infinite;
`;

export const CardLabel = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #333333;
  display: inline-flex;
`;

export const GraphLoading = styled(LoadingDiv)`
  width:100%;
  height:100%;
`;

export const FortyPixelsVerticalSpace = styled.div`
  width:100%;
  height:40px;
`;

export const GrayBackground = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 0;
`;

export const HintText = styled.span`
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  display: flex;
  margin-bottom: 10px;
`;

export const ErrorBoxOuterContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const MetricBoxChart = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  max-width: 280px;
  margin-left: -43px;
  margin-right: 20px;

  @media screen and (max-width: 1200px) {
    margin: 10px auto;
    max-width: 100%;
  }
`;

export const MetricBoxColumn = styled.div`
  max-width: 340px;
  width: 100%;
  margin: 0 24px 0 0;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 1200px) {
    margin: 10px auto;
    max-width: 100%;
  }
`;
