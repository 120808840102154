import styled from 'styled-components';

export const Feedback = styled.div`
  padding: 16px 16px 0px 16px;
  position: fixed;
  height: 55px;
  right: 60px;
  bottom: 0px;
  background: rgba(230, 230, 230, 0.5);
  backdrop-filter: blur(30px);
  border-radius: 16px 16px 0px 0px;
  z-index: 99999;
`;
