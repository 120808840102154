import styled, { css } from 'styled-components';
import { Breakpoints } from '../../../../route-order-tracking-react-components/src/utils/responsive-breakpoints'
import { colors } from './../../constants/styles'

export const Container = styled.div`
  position: absolute;
  background: #333;
  border-radius: 15px;
  color: white;
  z-index: 999;
  width: 211px;
  height: 73px;
  padding: 5px 20px;
  bottom: 0;

  @media (min-width: ${Breakpoints.xsMax}px) {
    width: 200px;
    height: 140px;
    flex-direction: column;
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: all .5s;
  
  svg {
    height: 7px;
    width: 7px;
    margin-left: -1px;
  }

  &:hover {
    background: #65c1cb;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: none;

  @media (min-width: ${Breakpoints.xsMax}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
`;

export const InfoContentHeader = styled.h1`
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;

  @media (min-width: ${Breakpoints.xsMax}px) {
    text-align: center;
  }
`;

export const InfoContentDescription = styled.span`
  text-align: left;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  @media (min-width: ${Breakpoints.xsMax}px) {
    text-align: center;
    font-size: 11px;
  }
`;

export const ListItems = styled.div`
  font-size: 10px;
  font-weight: normal;

  @media (min-width: ${Breakpoints.xsMax}px) {
    font-weight: 600;
    font-size: 11px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;

  span {
    margin-left: 5px;
  }

  @media (min-width: ${Breakpoints.xsMax}px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  position: absolute;
  background: #66c1cb;
  bottom: 10px;
  right: 15px;
  color: white;
  width: 62px;
  height: 20px;
  border-radius: 20px;
  font-size: 13px;
  text-align: center;
  line-height: 16px;
  text-transform: none;
  cursor: pointer;
  border: 0;

  &:hover {
    font-weight: bold;
  }

  @media (min-width: ${Breakpoints.xsMax}px) {
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 10px;
  }
`;