import React from 'react';

const SVG = ({
  style = {},
  fill = '',
  width = '20px',
  className = '',
  height = '20px',
  viewBox = '0 0 20 20',
  transform = '',
  stroke = 'white',
  selected,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52941 18H16.4706C17.366 18 18 17.366 18 16.4706V16.0606L12.0001 10.0607L8.03039 14.0303C7.73749 14.3232 7.26262 14.3232 6.96973 14.0303L5.00006 12.0607L2 15.0607V16.4706C2 17.366 2.63398 18 3.52941 18ZM12.5304 8.46967L18 13.9393V3.52941C18 2.63398 17.366 2 16.4706 2H3.52941C2.63398 2 2 2.63398 2 3.52941V12.9394L4.46973 10.4697C4.76262 10.1768 5.23749 10.1768 5.53039 10.4697L7.50006 12.4393L11.4697 8.46967C11.7626 8.17678 12.2375 8.17678 12.5304 8.46967ZM3.52941 20H16.4706C18.4706 20 20 18.4706 20 16.4706V3.52941C20 1.52941 18.4706 0 16.4706 0H3.52941C1.52941 0 0 1.52941 0 3.52941V16.4706C0 18.4706 1.52941 20 3.52941 20ZM16 5.5C16 6.32843 15.3284 7 14.5 7C13.6716 7 13 6.32843 13 5.5C13 4.67157 13.6716 4 14.5 4C15.3284 4 16 4.67157 16 5.5Z"
    />
    {/* {selected === 'engage' ? (
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52941 18H16.4706C17.366 18 18 17.366 18 16.4706V16.0606L12.0001 10.0607L8.03039 14.0303C7.73749 14.3232 7.26262 14.3232 6.96973 14.0303L5.00006 12.0607L2 15.0607V16.4706C2 17.366 2.63398 18 3.52941 18ZM12.5304 8.46967L18 13.9393V3.52941C18 2.63398 17.366 2 16.4706 2H3.52941C2.63398 2 2 2.63398 2 3.52941V12.9394L4.46973 10.4697C4.76262 10.1768 5.23749 10.1768 5.53039 10.4697L7.50006 12.4393L11.4697 8.46967C11.7626 8.17678 12.2375 8.17678 12.5304 8.46967ZM3.52941 20H16.4706C18.4706 20 20 18.4706 20 16.4706V3.52941C20 1.52941 18.4706 0 16.4706 0H3.52941C1.52941 0 0 1.52941 0 3.52941V16.4706C0 18.4706 1.52941 20 3.52941 20ZM16 5.5C16 6.32843 15.3284 7 14.5 7C13.6716 7 13 6.32843 13 5.5C13 4.67157 13.6716 4 14.5 4C15.3284 4 16 4.67157 16 5.5Z"
      />
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.52941 18H16.4706C17.366 18 18 17.366 18 16.4706V16.0606L12.0001 10.0607L8.03039 14.0303C7.73749 14.3232 7.26262 14.3232 6.96973 14.0303L5.00006 12.0607L2 15.0607V16.4706C2 17.366 2.63398 18 3.52941 18ZM12.5304 8.46967L18 13.9393V3.52941C18 2.63398 17.366 2 16.4706 2H3.52941C2.63398 2 2 2.63398 2 3.52941V12.9394L4.46973 10.4697C4.76262 10.1768 5.23749 10.1768 5.53039 10.4697L7.50006 12.4393L11.4697 8.46967C11.7626 8.17678 12.2375 8.17678 12.5304 8.46967ZM3.52941 20H16.4706C18.4706 20 20 18.4706 20 16.4706V3.52941C20 1.52941 18.4706 0 16.4706 0H3.52941C1.52941 0 0 1.52941 0 3.52941V16.4706C0 18.4706 1.52941 20 3.52941 20ZM16 5.5C16 6.32843 15.3284 7 14.5 7C13.6716 7 13 6.32843 13 5.5C13 4.67157 13.6716 4 14.5 4C15.3284 4 16 4.67157 16 5.5Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="20"
            y1="20"
            x2="-0.00765012"
            y2="2.28136"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2BFFFF" />
            <stop offset="0.592551" stopColor="#28B8E3" />
            <stop offset="1" stopColor="#255FC0" />
          </linearGradient>
        </defs>
      </>
    )} */}
  </svg>
);

export default SVG;
