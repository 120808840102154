import React from 'react';

const SVG = ({
  style = {},
  fill = '#313222',
  width = '21px',
  className = '',
  height = '20px',
  viewBox = '0 0 21 20',
  transform = '',
}) => (
  // <svg
  //   width={width}
  //   style={style}
  //   height={height}
  //   viewBox={viewBox}
  //   className={className}
  //   transform={transform}
  //   fill='none'
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  // >
  //   <path d="M2.22222 18.2245C2.22222 18.8554 2.72222 19.1423 3.33333 19.1423H16.6667C17.2778 19.1423 17.7778 18.8554 17.7778 18.2245L20 8.24354C20 7.9269 19.7511 7.66992 19.4444 7.66992H0.555556C0.248889 7.66992 0 7.9269 0 8.24354L2.22222 18.2245Z" fill={fill}/>
  //   <path d="M7.88932 0.786621H3.33377C2.72266 0.786621 2.22266 1.4176 2.22266 2.04858V4.80194C2.22266 5.11857 2.47155 5.37555 2.77821 5.37555H17.2227C17.5293 5.37555 17.7782 5.11857 17.7782 4.80194V4.22832C17.7782 3.59505 17.2815 3.08109 16.6671 3.08109H8.88932V2.04858C8.88932 1.4176 8.50043 0.786621 7.88932 0.786621Z" fill={fill}/>
  // </svg>;

  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    transform={transform}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 0C13.2348 0 12.9804 0.105357 12.7929 0.292893L11.0858 2H5C3.34772 2 2 3.34772 2 5V6.74382C1.23415 7.45289 0.84521 8.57003 1.01168 9.65206L2.51209 19.1559C2.58883 19.642 3.00777 20 3.49986 20H18.4999C18.9998 20 19.4229 19.6309 19.4907 19.1356L20.7899 9.64298C20.9379 8.6624 20.6303 7.65401 20 6.94748V3C20 1.34772 18.6523 0 17 0H13.5ZM18 6.00168V3C18 2.45228 17.5477 2 17 2H13.9142L12.2071 3.70711C12.0196 3.89464 11.7652 4 11.5 4H5C4.45228 4 4 4.45228 4 5V6L17.9001 6C17.9336 6 17.9669 6.00056 18 6.00168ZM2.98842 9.34794L2.98815 9.34622C2.86285 8.52579 3.43533 8 3.90005 8L17.9001 8C18.3652 8 18.9381 8.52653 18.8118 9.34794L18.8094 9.3644L17.6275 18H4.35437L2.98842 9.34794ZM10.4 11.3C10.0762 11.3 9.80005 11.5762 9.80005 11.9C9.80005 12.2239 10.0762 12.5 10.4 12.5H10.5V11.3H10.4ZM10.5 10.3H10.4C9.52391 10.3 8.80005 11.0239 8.80005 11.9C8.80005 12.7762 9.5239 13.5 10.4 13.5H10.5V14.7H10.4C10.194 14.7 10.0392 14.6075 9.91607 14.4227C9.7629 14.1929 9.45246 14.1308 9.2227 14.284C8.99293 14.4372 8.93085 14.7476 9.08402 14.9774C9.36085 15.3926 9.8061 15.7 10.4 15.7H10.5V16C10.5 16.2761 10.7239 16.5 11 16.5C11.2761 16.5 11.5 16.2761 11.5 16V15.7H11.6C12.4762 15.7 13.2 14.9762 13.2 14.1C13.2 13.2239 12.4762 12.5 11.6 12.5H11.5V11.3H11.6C11.8061 11.3 11.9608 11.3926 12.084 11.5774C12.2372 11.8072 12.5476 11.8693 12.7774 11.7161C13.0072 11.5629 13.0693 11.2525 12.9161 11.0227C12.6392 10.6075 12.194 10.3 11.6 10.3H11.5V10C11.5 9.72386 11.2761 9.5 11 9.5C10.7239 9.5 10.5 9.72386 10.5 10V10.3ZM11.5 13.5V14.7H11.6C11.9239 14.7 12.2 14.4239 12.2 14.1C12.2 13.7762 11.9239 13.5 11.6 13.5H11.5Z"
    />
  </svg>
);

export default SVG;
