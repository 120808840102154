import React, {FC, useRef} from 'react';
import {Grid, Link, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Form, FormRenderProps} from 'react-final-form';

import {HeaderContainer, ContainerContent, Button, RouteFormTextInput, ErrorPopover} from '../../components';
import {createRequiredValidator} from '../../validation/required';
import styleMap from '../../constants/styles';

export type OwnProps = {};

export type StateProps = {
  showCredentialsError: boolean;
};

export type DispatchProps = {
  onSubmit: (data: Partial<FormData>) => void;
  onHideCredentialsError: () => void;
  onForgotPassword: () => void;
  onForgotEmail: () => void;
}

export type FormData = {
  email: string;
  password: string;
};

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  },
  title: {
    textAlign: 'left',
    marginTop: '75px',
  },
  subtitle: {
    textAlign: 'left',
    marginTop: '15px',
  },
  loginSubtitle: {
    textAlign: 'left',
    textTransform: 'uppercase',
    marginTop: '45px',
  },
  linkContainer: {
    marginTop: '15px',
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    color: styleMap.linkColor,
    '&:hover': {
      color: styleMap.linkHoverColor,
    },
    // This is cryptic, but it evaluates to "<link classname> ~ <link classname>"
    // So the margin is only applied to the second link to give space between them
    '$link ~ &': {
      marginLeft: '25px',
    },
  },
  spacer: {
    marginBottom: '55px',
  }
}), {name: 'SignInForm'});

const emailRequiredValidator = createRequiredValidator('This field is required. Please enter your email.');
const passwordRequiredValidator = createRequiredValidator('This field is required. Please enter your password.');

export const SignInForm: FC<Props> = (props) => {
  const classes = useStyles();
  const onSubmit = props.onSubmit ? props.onSubmit : (data: any) => console.log(data);

  const onForgotEmail = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onForgotEmail();
  }
  const onForgotPassword = (event: React.MouseEvent) => {
    event.preventDefault();
    props.onForgotPassword();
  }

  return (
    <HeaderContainer>
      <ContainerContent width={390}>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>Sign in to Continue</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>Please sign in to continue setting up your account.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.loginSubtitle}>Enter Login Info</Typography>
            </Grid>
            <Grid item xs={12}>
              <Form onSubmit={onSubmit}>
                {(formProps: FormRenderProps<FormData>) => {
                  return <InternalForm {...props} {...formProps}/>;
                }}
              </Form>
            </Grid>
            <Grid item xs={12} className={classes.linkContainer}>
              <Typography variant="caption" className={classes.link}>
                <Link href="" onClick={onForgotEmail} className={classes.link}>Forgot Email?</Link>
              </Typography>
              <Typography variant="caption" className={classes.link}>
                <Link href="" onClick={onForgotPassword} className={classes.link}>Forgot Password?</Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.spacer}/>
            </Grid>
          </Grid>
        </div>
      </ContainerContent>
    </HeaderContainer>
  );
};


const useFormStyles = makeStyles({
  email: {
    marginTop: '25px',
  },
  password: {
    marginTop: '25px'
  },
  button: {
    marginTop: '45px',
  },
}, {name: 'SignInForm-internal'});

const InternalForm: FC<Props & FormRenderProps<FormData>> = (props) => {
  const classes = useFormStyles();
  const {invalid: formInvalid, showCredentialsError, onHideCredentialsError} = props;
  const confirmPasswordRef = useRef(null);
  const incorrectCredentialsError = <span>
    This does not appear to be a <span style={{fontWeight: 'bold'}}>email / password combination. </span>
    If you have forgotten your email address or password, please click the link below.
  </span>;

  return (
    <Grid container>
      <form onSubmit={props.handleSubmit}>
        <Grid item xs={12} className={classes.email}>
          <RouteFormTextInput
            required
            overrideError={showCredentialsError}
            id="email"
            name="email"
            label="Email Address"
            fieldProps={{
              name: 'email',
              validate: emailRequiredValidator,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.password} ref={confirmPasswordRef}>
          <RouteFormTextInput
            required
            overrideError={showCredentialsError}
            type="password"
            id="password"
            name="password"
            label="Password"
            fieldProps={{
              name: 'password',
              validate: passwordRequiredValidator,
            }}
          />
        </Grid>
        <ErrorPopover
          open={showCredentialsError}
          onClose={onHideCredentialsError}
          content={incorrectCredentialsError}
          anchorEl={confirmPasswordRef.current}
        />
        <Grid item xs={12} className={classes.button}>
          <Button width={240} type="submit" disabled={formInvalid}>Submit</Button>
        </Grid>
      </form>
    </Grid>
  );
}
