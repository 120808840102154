import React from 'react';
import {Bold} from '../components';
import {ValidatorWithoutOtherValues} from './index';

const errorFragment = <div>This does not appear to be a valid email. <Bold>Please check formatting and try again.</Bold></div>;
const emailValidate = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const emailPatternValidator: ValidatorWithoutOtherValues<string> = (value: any) => {
  // We only want to check that the value is valid if we have a value at all.
  // We will interpret an undefined/null value and the empty string as "no value"
  if (value === undefined || value === null || value === '') {
    return undefined;
  }

  if (typeof value !== 'string') {
    return errorFragment;
  }

  return value.match(emailValidate) ? undefined : errorFragment;
}
