import {Dispatch} from 'react';
import {connect} from 'react-redux';

import {State} from '../../store';
import {FormData, AccountCreationForm, StateProps, DispatchProps} from './account-creation-form';
import {createAccount, hideDomainExistsErrorActionCreator} from '../../actions/account-creation/account-creation';

export const mapStateToProps = (state: State): StateProps => {
  const acState = state.app.accountCreation;
  const lockEmail = !acState.merchant || acState.merchant.platform_id !== 'shopify';

  // The three ways that we DO have enough information - merchant token, password reset guid, or query params
  const showExtendedFields = !acState.merchant && !acState.passwordGuid && !acState.merchantCreationData;

  return {
    // We could just use the existence/absence of the email, but for now we want to ensure
    // that a locked field is unique to non-shopify merchants
    initialLockedEmail: lockEmail && acState.email ? acState.email : undefined,
    showDomainExistsError: acState.page.showDomainExistsError,
    showExtendedFields,
  };
}

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onSubmit: (values: FormData) => {
      dispatch(createAccount(values));
      return undefined;
    },
    onMerchantExistsErrorDismissed: () => {
      dispatch(hideDomainExistsErrorActionCreator());
      return undefined;
    },
  };
}

export const ConnectedAccountCreation = connect(mapStateToProps, mapDispatchToProps)(AccountCreationForm);
