import { connect } from 'react-redux';
import { LoadingToast, StateProps } from '../../components/loading-toast';
import { State } from '../../../Onboarding-2/store';

export const mapStateToProps = (state: State): StateProps => {
  return {
    active: state.app.loading,
  };
}

export const ConnectedLoadingToast = connect(mapStateToProps)(LoadingToast);
