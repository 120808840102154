import axios from 'axios';
import { addBreadcrumb, Severity } from '@sentry/browser';

import { ROUTE_API } from '../../../../constants';
import { headersWithToken } from "../index"

export type ConfirmEmailRequest = {
  email: string;
}

export type MerchantResponse = {
  successful: boolean;
  reason?: string;
  message?: string;
  data?: any;
}

export type EmailType = "email" | "file-upload" | "text-record";

export type SendBrandEmailRequest = {
  email: string;
  emailType: EmailType;
  storeName?: string;
  domain: string;
}

// '/v1/merchants/send_brand_email'
export const sendBrandEmail = async (token: string, request: SendBrandEmailRequest): Promise<MerchantResponse> => {
  const headers = headersWithToken(token)

  const endpoint = `${ROUTE_API}/merchants/send_brand_email?email=${request.email}&domain=${request.domain}&emailType=${request.emailType}`
  try {
    const response = await axios.post(endpoint, {}, { headers })
    return {
      successful: response.status === 200
    }
  } catch (err) {
    addBreadcrumb({
      message: `Error encountered while trying to confirm email: "${err.message}"`,
      data: { err },
      level: Severity.Error,
    });

    return {
      successful: false,
      reason: "Failed to confirm email",
      message: err.message
    }
  }
}
