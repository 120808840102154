import React from 'react';
import { Link } from 'react-router-dom';
import './CTAButton.scss';

const CTAButton = (props) => (
    props.external ? 
      <a href={props.href} className={`btn ${props.styleName}`} {...props}>{props.text}</a>
      :
      <Link to={props.href} className={`btn ${props.styleName}`} {...props}>
         <span>{props.text}</span>
      </Link>
);

CTAButton.defaultProps = {
  styleName: '',
};

export default CTAButton;
