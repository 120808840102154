import React, { Fragment, useState, useRef, useEffect, useLayoutEffect } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ReactGA from 'react-ga';

import SearchIcon from '../../../../assets/icons/search-icon';
import CloseIcon from '../../../../assets/icons/close-icon';

import {
  Container,
  Dropdown,
  DropdownHeader,
  DropdownList,
  DropdownListItem,
  SearchInput,
  SearchButton,
} from './SearchBar.styles';

const SearchBar = (props) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const searchInput = useRef();
  const options = props.searchDropdownItems;

  const handleClickAway = () => {
    setIsDropdownOpened(false);
  };

  const onCleanPress = () => {
    setIsSearching(false);
    setOptionSelected('');
    searchInput.current.value = '';
    props.updateSearchValue('');
    props.updateSearchKey('');
    props.search(false);
  };

  const onSelectChange = (key) => {
    const label = options[key];
    setIsDropdownOpened(false);
    setOptionSelected(label);
    props.updateSearchKey(key); // Set Search Key

    // Google Analytics - Track dropdown search
    if (document.location.pathname.includes('orders')) {
      ReactGA.event({ category: 'Orders', action: 'Search field click', label });
    } else if (document.location.pathname.includes('admin/billing')) {
      ReactGA.event({ category: 'Admin - Billing', action: 'Search field click', label });
    } else if (document.location.pathname.includes('claims')) {
      ReactGA.event({ category: 'Claims - All Claims', action: 'Search field click', label });
    }
  };

  const onInputChange = (e) => {
    const { value } = e.target;

    if (value !== null) {
      props.updateSearchValue(value); // Set Search Value
    }
  };

  const onInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsSearching(true);
      props.search();
    }
  };

  return (
    <>
      {options && (
        <Container>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Dropdown>
              <DropdownHeader
                // className={`dropdown-header ${isDropdownOpened ? 'dropdown-header-opened' : ''}`}
                isDropdownOpened={isDropdownOpened}
                onClick={() => {
                  setIsDropdownOpened(!isDropdownOpened);
                }}
              >
                {optionSelected || props.placeholder}
              </DropdownHeader>

              {isDropdownOpened && (
                <DropdownList>
                  {Object.keys(options).map((item, index) => {
                    const key = item;
                    const label = options[key];
                    if (label === null) {
                      return false;
                    }
                    return (
                      <DropdownListItem
                        key={key}
                        className="dropdown-list-item"
                        onClick={() => {
                          onSelectChange(key);
                        }}
                      >
                        {label}
                      </DropdownListItem>
                    );
                  })}
                </DropdownList>
              )}
            </Dropdown>
          </ClickAwayListener>

          <SearchInput
            ref={searchInput}
            type="text"
            placeholder={`Search ${optionSelected}`}
            onChange={onInputChange}
            onKeyPress={onInputKeyPress}
          />

          {!isSearching ? (
            <SearchButton
              onClick={() => {
                setIsSearching(true);
                props.search();
              }}
            >
              <SearchIcon />
            </SearchButton>
          ) : (
            <SearchButton
              onClick={() => {
                onCleanPress();
              }}
            >
              <CloseIcon />
            </SearchButton>
          )}
        </Container>
      )}
    </>
  );
};

export default SearchBar;
