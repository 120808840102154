import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {DispatchProps, PasswordResetExpired} from './expired';
import {resendPasswordResetLink} from '../../../actions/sign-in/password-reset';

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  return {
    onResendLink: () => dispatch(resendPasswordResetLink()),
  };
}

export const ConnectedPasswordResetExpired = connect(null, mapDispatchToProps)(PasswordResetExpired);
