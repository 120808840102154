import React, { FC } from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { defaultButtonBorderRadius } from '../constants/theme';

type StylesProps = {
  width?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    maxWidth: (props: StylesProps = {}) => props.width,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    }
  }
}), { name: 'ContainerContent' });

export type ContainerContentProps = {
  children: React.ReactNode;
  width?: number;
  [prop: string]: any;
}

export const ContainerContent: FC<ContainerContentProps> = (props) => {
  const classes = useStyles(props);
  const { children, ...otherProps } = props;

  return (
    <Paper className={classes.root} {...otherProps}>
      {children}
    </Paper>
  );
}
