import React, { Component } from 'react';
import './UpdatePaymentMethod.scss';
import AddPaymentCardModal from '../../../_shared/AddBillingMethod/AddCard/AddPaymentCardModal';
import AddBankAccountModal from '../../../_shared/AddBillingMethod/AddBank/AddBankAccountModal';
import { Elements, StripeProvider } from 'react-stripe-elements';

import {
  STRIPE_PUBLIC_API_KEY,
} from '../../../../constants';
import BillingVideoModal from '../BillingVideoModal/BillingVideoModal';
import TitleAndNav from './TitleAndNav';
import { isOnboardingFlow } from '../../../../utils/url';
class SetUpPaymentMethod extends Component {
  constructor() {
    super();

    this.state = {
      title: 'Payment Method',
      billingVideoModalOpen: false,
      paymentCardModalOpen: false,
      bankModalOpen: false,
      billingSuccess: false,
      skipClicked: false,
    };
  }

  componentDidMount() {
    if (isOnboardingFlow()) {
      this.setState({
        title: 'Set Payment Method'
      });
    }
  }


  handleOpenBankModal = () => {
   this.setState({ bankModalOpen: true });
  };
  handleCloseBankModal = () => this.setState({ bankModalOpen: false });
  handleOpenCardModal = () => {
   this.setState({ paymentCardModalOpen: true });
  };
  handleCloseCardModal = () => this.setState({ paymentCardModalOpen: false });
  handleOpenBillingVideoModal = () =>
    this.setState({ billingVideoModalOpen: true });
  handleCloseBillingVideoModal = () =>
    this.setState({ billingVideoModalOpen: false });

  handleBillingSuccess = () => {
    this.setState({ billingSuccess: true });
  }

  render() {
    return (
      <div className='update-payment-method'>
        <TitleAndNav close={this.props.closeDrawer} title={this.state.title} />
        <div className='update-payment-method-upper-text'>
          <p>
            Connect your bank account in order to remit insurance premiums
            you have collected from your customers, on behalf of Route.
          </p>
        </div>
        <div className='update-payment-method-container'>
          <div className={'update-payment-method-connect-account'}
            onClick={this.props.openBankAccountDrawer}
          >
            <span className='update-payment-method-icon-title-wrapper'>
            Connect a bank account
            </span>
          </div>
          <div className={'update-payment-method-connect-card'}
            onClick={this.props.openCreditCardDrawer}
          >
            <span className='update-payment-method-icon-title-wrapper'>
              Connect debit / credit card
            </span>
          </div>
        </div>
        <div className='update-payment-method-lower-text'>
          <p>
            <b>Please note</b> that Route has integrated with Stripe, an online
            payment solution application, to provide a <i>secure environment</i> in which
            you can connect your payment method.<br></br>
          </p>
          <p className='route-text'>
            <i>Route does not store any sensitive billing information.</i>
          </p>
        </div>

        {this.state.billingVideoModalOpen ? (
          <BillingVideoModal
            open={this.state.billingVideoModalOpen}
            onRequestClose={this.handleCloseBillingVideoModal}
          />
        ) : (
          ''
        )}
        {this.state.paymentCardModalOpen ? (
          <AddPaymentCardModal
            onBillingSuccess={this.handleBillingSuccess}
            onRequestClose={this.handleCloseCardModal}
          />
        ) : (
          ''
        )}
        {this.state.bankModalOpen ? (
          <StripeProvider apiKey={STRIPE_PUBLIC_API_KEY}>
            <Elements>
              <AddBankAccountModal
                onBillingSuccess={this.handleBillingSuccess}
                onRequestClose={this.handleCloseBankModal}
              />
            </Elements>
          </StripeProvider>
        ) : (
          ''
        )}

      </div>
    );
  }
}

export default SetUpPaymentMethod;
