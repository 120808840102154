import { captureException, addBreadcrumb, Severity, captureMessage } from '@sentry/browser';
import { push } from 'connected-react-router';
import { State, ThunkDispatch } from '../../../Onboarding-2/store';
import { sendBrandEmail, SendBrandEmailRequest, EmailType } from "../../api/merchants";
import { RouteApi } from '../../api/types';

export const VERIFY_DOMAIN_STARTED = 'VERIFY_DOMAIN_STARTED' as const;
export interface VerifyDomainStartedAction {
  type: typeof VERIFY_DOMAIN_STARTED;
}

export function verifyDomainStarted(): VerifyDomainStartedAction {
  return {
    type: VERIFY_DOMAIN_STARTED,
  };
}

export const VERIFY_DOMAIN_SUCCEEDED = 'VERIFY_DOMAIN_SUCCEEDED' as const;
export interface VerifyDomainSucceededAction {
  type: typeof VERIFY_DOMAIN_SUCCEEDED;
}

export function verifyDomainSucceeded(): VerifyDomainSucceededAction {
  return {
    type: VERIFY_DOMAIN_SUCCEEDED,
  };
}

export const VERIFY_DOMAIN_FAILED = 'VERIFY_DOMAIN_FAILED' as const;
export interface VerifyDomainFailedAction {
  type: typeof VERIFY_DOMAIN_FAILED;
  errorReason?: any;
  errorMessage?: string;
}

export function verifyDomainFailed(data: { message?: string; reason?: any } = {}): VerifyDomainFailedAction {
  return {
    type: VERIFY_DOMAIN_FAILED,
    errorMessage: data.message,
    errorReason: data.reason,
  };
}

export type VerifyDomainMethod = {
  email: string;
  method: EmailType;
}

export const SET_VERIFY_DOMAIN_METHOD = 'SET_VERIFY_DOMAIN_METHOD' as const;
export interface SetVerifyDomainMethodAction {
  type: typeof SET_VERIFY_DOMAIN_METHOD;
  data: VerifyDomainMethod
}

export function setVerifyDomainMethod(data: VerifyDomainMethod): SetVerifyDomainMethodAction {
  return {
    type: SET_VERIFY_DOMAIN_METHOD,
    data,
  };
}

export type AppActionTypes =
  VerifyDomainStartedAction |
  VerifyDomainSucceededAction |
  VerifyDomainFailedAction |
  SetVerifyDomainMethodAction;

export const sendEmailInstructions = (email: string, emailType: EmailType) => {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    try {
      addBreadcrumb({
        message: 'Starting verify domain process',
        category: 'Verify Domain',
        level: Severity.Info,
      });
      dispatch(verifyDomainStarted());

      const merchant = getState().app.brand.merchant;
      if (!merchant) {
        return dispatch(verifyDomainFailed({
          message: "Merchant is not defined",
        }));
      }

      const { store_domain, prod_api_secret: token }: RouteApi.Merchant = merchant
      const request: SendBrandEmailRequest = {
        email,
        emailType,
        domain: store_domain as string
      }

      const response = await sendBrandEmail(token, request)
      if (!response.successful) {
        return dispatch(verifyDomainFailed({
          message: response.message,
          reason: response.reason,
        }))
      }

      dispatch(setVerifyDomainMethod({ email, method: emailType }))

      addBreadcrumb({
        message: 'Verify domain successful',
        category: 'Verify Domain',
        level: Severity.Info,
      });
      dispatch(verifyDomainSucceeded());

      return dispatch(push('/brand/thank-you'));
    } catch (err) {
      captureException(err);
      return dispatch(verifyDomainFailed({
        message: err?.message,
      }));
    }
  }
}
