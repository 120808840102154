import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 28px);
  max-width: 646px;
  height: 544px;
  overflow-y: auto;
  text-align: center;


  &::-webkit-scrollbar {
    width: 6px;
    background: none;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    outline: 1px solid #ebebeb;
    border-radius: 50px;
  }
`;

export const Draw = styled.img`
  max-width: 500px;
`;

export default {
  Container,
  Draw,
};
