import React, { FC, useState, useEffect } from 'react';
import { Popover } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '../../../../components/Typography';

import IconArrowLeft from '../../../../../../assets/icons/arrow-left';
import CloseIconHover from '../../../../../../assets/images/close-icon-hover.svg';
import Button from '../../../../components/Button';
import Switch from '../../../../components/Switch';
import MobilePreview from '../MobilePreview';
import DesktopPreview from '../DesktopPreview';
import { getActiveMerchantServiceTier } from '../../../../../../services/merchant_service_tier';
import Can from '../../../../components/Authentication/Can/Can';
import GetRouteProWidget from '../../../../components/GetRouteProWidget/GetRouteProWidget';
import GetRouteProPopover from '../../../../components/GetRouteProPopover/GetRouteProPopover';
import IconWarning from '../../../../../../assets/icons/icon-warning';
import IconInfoCircle from '../../../../../../assets/icons/icon-info-circle';

import {
  Blank,
  Container,
  Column,
  Columns,
  Header,
  Link,
  Widget,
  Line,
  Preview,
  PreviewTitle,
  PreviewHeader,
  PreviewCloseButton,
  ButtonHidden,
  Box,
  InnerContainer,
} from './ShippingNotification.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
    paper: {
      background: 'none',
      boxShadow: 'none',
      border: 'none',
      overflowX: 'initial',
      overflowY: 'initial',
    },
  }),
);

type ReadyToShipProps = {
  active: boolean;
  history: {
    push(url: string): void;
  };
  preview: {
    desktop: boolean;
    mobile: boolean;
    configure: boolean;
  };
  notificationSettings: any;
  userSession: any;
  location: any;
};

const ShippingNotification: FC<any> = (props: any) => {
  const classes = useStyles();
  const [active, setActive] = useState(props.active || false);
  const [preview, setPreview] = useState(
    props.preview || {
      desktop: false,
      mobile: true, // TODO: change it back to true
      configure: false,
    },
  );
  const [hideRouteProWidget, setHideRouteProWidget] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setPreview(props.preview);
  }, [props.preview]);

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Can
        role={getActiveMerchantServiceTier()}
        services={getActiveMerchantServiceTier()}
        perform="pre_shipment_notifications"
        yes={() => <></>}
        no={() => (
          <>
            {!hideRouteProWidget && (
              <GetRouteProWidget
                userSession={props.userSession}
                title="Shipping Notifications"
                subtitle="Create post-purchase messaging that builds customer loyalty."
                description="Improve the post-purchase experience with in-app and email notifications that engage and inform customers every step of the way. <b><span>Heads up! Activate Route Pro to save your settings and take full advantage of this feature.</span></b>"
                titleSuccess="Thanks for checking out Route Pro!"
                messageSuccess="Keep your eyes on your inbox! A member from our team will be emailing you shortly to get you set up."
                tryProButton="Try Route Pro for Free"
                tryOutButton="Explore This Feature"
                closeButton="Close"
                onTryOutPress={() => {
                  setHideRouteProWidget(true);
                }}
                onClosePress={() => {
                  setHideRouteProWidget(true);
                }}
              />
            )}
          </>
        )}
      />
      <Container>
        <Header>
          <Link onClick={() => props.history.push('/engage/shipping-notification/')}>
            <IconArrowLeft /> Back to Shipping Notifications
          </Link>
          <Typography variant="h1" component="h2" className="title">
            {props.title}
          </Typography>
          <Typography variant="p" component="p" className="subtitle">
            {props.description}
          </Typography>
        </Header>
        <Widget>
          <Typography variant="h4" component="h3">
            Settings
          </Typography>
          <Blank height={33}></Blank>
          <Columns>
            <Column maxWidth={370}>
              <Line />
              {props?.settings.map((setting: any, key: any) => (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 17,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        marginRight: 4,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <Typography variant="h7" component="h4" color="darkGray">
                          {setting.label}:{' '}
                        </Typography>

                        {setting?.popover && (
                          <div
                            style={{ marginTop: 2, marginLeft: 4, cursor: 'pointer' }}
                            onClick={setting?.popover?.open}
                          >
                            <IconInfoCircle selected={setting?.popover.active} />
                          </div>
                        )}
                      </div>
                      <>{setting?.popover?.render()}</>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                      {props?.notificationSettings?.settings[setting.field][setting.subfield] ? (
                        <Typography variant="p" component="h4" color="primary" align="right">
                          ON
                        </Typography>
                      ) : (
                        <Typography variant="p" component="h4" color="gray" align="right">
                          OFF
                        </Typography>
                      )}
                    </div>
                    <Can
                      role={getActiveMerchantServiceTier()}
                      services={getActiveMerchantServiceTier()}
                      perform="pre_shipment_notifications"
                      yes={() => (
                        <>
                          {setting?.conditionMatch ||
                          props?.notificationSettings?.settings[setting.field][setting.subfield] ? (
                            <Switch
                              checked={props?.notificationSettings?.settings[setting.field][setting.subfield]}
                              onChange={(checked: boolean) => {
                                setActive(checked);
                                setting?.onSwitch(setting.field, setting.subfield, checked);
                              }}
                            ></Switch>
                          ) : (
                            <>
                              <ButtonHidden onClick={setting.requiredPopover?.open}>
                                <Switch disabled checked={false} />
                              </ButtonHidden>
                              {setting.requiredPopover?.render()}
                            </>
                          )}
                        </>
                      )}
                      no={() => (
                        <>
                          <ButtonHidden onClick={openPopover}>
                            <Switch disabled checked={false} />
                          </ButtonHidden>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={closePopover}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            className={classes.popover}
                            classes={{
                              paper: classes.paper,
                            }}
                          >
                            <GetRouteProPopover onClose={closePopover} userSession={props.userSession} />
                          </Popover>
                        </>
                      )}
                    />
                  </div>
                </div>
              ))}
              <Line />
              <div
                style={{
                  marginTop: 15,
                  marginBottom: 8,
                }}
              >
                <Typography variant="h6" component="h4" color="darkGray">
                  Notification Types
                </Typography>
              </div>
              <Box>
                <InnerContainer disabled={preview.mobile}>
                  <Typography variant="h6" component="h4" semibold>
                    App (Push Notification)
                  </Typography>
                  <Button
                    disabled={preview.mobile}
                    text="Preview"
                    onClick={() => {
                      setPreview({
                        configure: false,
                        desktop: false,
                        mobile: true,
                      });
                    }}
                  ></Button>
                </InnerContainer>
                <InnerContainer disabled={preview.desktop}>
                  <Typography variant="h6" component="h4" semibold>
                    Email
                  </Typography>
                  <Button
                    disabled={preview.desktop}
                    text="Preview"
                    onClick={() => {
                      setPreview({
                        mobile: false,
                        desktop: true,
                        configure: false,
                      });
                    }}
                  ></Button>
                </InnerContainer>
              </Box>
              <div
                style={{
                  marginTop: 35,
                  marginBottom: 8,
                }}
              >
                <Typography variant="h6" component="h4" color="darkGray">
                  {props.configure.heading}
                </Typography>
              </div>

              <Box>
                <InnerContainer disabled={preview.configure}>
                  <Typography variant="h6" component="h4" semibold>
                    {props.configure.label}
                  </Typography>
                  <div style={{ display: 'flex' }}>
                    <Can
                      role={getActiveMerchantServiceTier()}
                      services={getActiveMerchantServiceTier()}
                      perform="pre_shipment_notifications"
                      yes={() => (
                        <Button
                          text={props.configure.buttonLabel}
                          disabled={preview.configure}
                          onClick={() => {
                            setPreview({
                              mobile: false,
                              desktop: false,
                              configure: true,
                            });
                          }}
                        ></Button>
                      )}
                      no={() => (
                        <>
                          <Button text={props.configure.buttonLabel} onClick={openPopover}></Button>
                        </>
                      )}
                    />
                    {!props.configure?.conditionMatch && !props.isLoading && (
                      <>
                        <div style={{ marginLeft: 8 }}>
                          <div onClick={props.configure?.popover.open} style={{ cursor: 'pointer' }}>
                            <IconWarning />
                          </div>
                          {props.configure?.popover.render()}
                        </div>
                      </>
                    )}
                  </div>
                </InnerContainer>
                <Blank height={10}></Blank>
              </Box>
            </Column>
            <Column maxWidth={646}>
              <Blank height={18} />
              <Preview>
                {(preview.mobile !== false || preview.desktop !== false) && (
                  <PreviewHeader>
                    <PreviewTitle>{preview.desktop ? 'Email Preview' : 'App (Push Notification) Preview'}</PreviewTitle>
                    <PreviewCloseButton
                      srcSet={CloseIconHover}
                      onClick={() => {
                        setPreview({
                          mobile: false,
                          desktop: false,
                          configure: false,
                        });
                      }}
                    />
                  </PreviewHeader>
                )}
                {preview.configure && props.configure.render()}
                {preview.mobile && <MobilePreview draw={props.previews.mobile} />}
                {preview.desktop && <DesktopPreview draw={props.previews.desktop} />}
                {preview.desktop === false && preview.mobile === false && preview.configure === false && (
                  <Typography variant="h7" component="h4" color="gray3">
                    PREVIEW AREA
                  </Typography>
                )}
              </Preview>
            </Column>
          </Columns>
        </Widget>
      </Container>
    </>
  );
};

export default ShippingNotification;
