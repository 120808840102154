import React from "react";
import ImageIcon from "../../../../assets/icons/image";
import CheckMark from "../../../../assets/icons/checkmark";
import CloseIcon from "../../../../assets/icons/close";
import ExclamationMark from "../../../../assets/icons/exclamationMark";
import ErrorStatus from "../../../../assets/icons/error-status";

import "./ImageReq.scss";

const ImageReq = props => {
  const { errorState, close } = props;
  return (
    <div className="image-notification-container" style={{top:props.top}}>
      <div className="image-notification-close-icon-container" onClick={close}>
        <CloseIcon fill="#2c373c"/>
      </div>
      <div className="image-notification-icon-arrow">
        <div className="image-notification-icon-arrow-outer">
          <div className="image-notification-icon-arrow-inner"></div>
        </div>
      </div>
      <div className="image-notification-icon-container">
        {errorState === true ? <ExclamationMark /> : <CheckMark />}
        <ImageIcon stroke={errorState === true ? "#F1373C" : "white"} />
      </div>
      {errorState === true ? (
        <div className="image-notification-content">
          <div className="image-notification-header">Image could not load</div>
          <div className="image-notification-desc">
            Please check image requirements:
          </div>
          <ul>
            <li>
              <div>{errorState === true ? <ErrorStatus /> : <CheckMark />}</div>
              JPG, PNG, GIF file types
            </li>
            <li>
              <div>{errorState === true ? <ErrorStatus /> : <CheckMark />}</div>
              5MB size limit
            </li>
          </ul>
        </div>
      ) : (
        <div>
          <div>
            <div className="image-notification-sub-header">
              File Formats Accepted:
            <div>JPG, PNG, GIF</div>
            </div>
            <div className="image-notification-sub-header">
              File Size Limit:<div>5MB</div>
            </div>
          </div>
        </div>
      )}
      <div onClick={close} className="image-notification-close-button">
        close
      </div>
    </div>
  );
};
export default ImageReq;
