import {Dispatch} from 'react';
import {connect} from 'react-redux';
import {DispatchProps, ForgotEmail} from './forgot-email';
import {goBackToSignIn} from '../../actions/sign-in/password-reset'

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
    return {
        onGoToSignIn: () => dispatch(goBackToSignIn()),
    };
}

export const ConnectedForgotEmail = connect(null, mapDispatchToProps)(ForgotEmail);
