import {captureException} from '@sentry/browser';

import {RouteApi} from '../api/types';
import {Immutable} from '../../../types';

export function qualifiesForExpertRouteImplementation(merchant: Immutable<RouteApi.Merchant>): boolean {
  try {
    if (!merchant) {
      throw new Error('Invariant violated: merchant not defined');
    }

    if (merchant.platform_id !== 'shopify' || !merchant.deal_size_order_count) {
      return false;
    }

    const numericOrderCount = typeof merchant.deal_size_order_count === 'string' ?
      Number.parseInt(merchant.deal_size_order_count, 10) :
      merchant.deal_size_order_count;

    if (isNaN(numericOrderCount)) {
      throw new Error(`Failed to parse "deal_size_order_count": "${merchant.deal_size_order_count}"`);
    }
      
    return numericOrderCount >= 500;
  } catch(err) {
    const newError = new Error('Failed to determine if merchant qualifies for expert implementation');
    //@ts-ignore
    newError.innerError = err;
    captureException(newError);
    return false;
  }
}

export function routePlusEnabled(merchant: Immutable<RouteApi.Merchant>): boolean {
  return merchant.has_route_plus === true;
}

export function routePlusSelfImplementationEnabled(merchant: Immutable<RouteApi.Merchant>): boolean {
  return merchant.install_method === 'self';
}
