import {replace} from 'connected-react-router';
import queryString from 'query-string';
import {addBreadcrumb, captureMessage, Severity, captureException} from '@sentry/browser';

import {State, ThunkDispatch, AppState} from '../../store';
import {OnboardingStatus, RouteApi} from '../../api/types';
import {getMerchantFromMerchantToken, updateMerchant} from '../../api';
import {BrowserUserSessionData} from '../../../../stores/SessionManager';
import {updateOnboardingStatusForMerchant} from '../onboarding/status';
import {updateActiveMerchant} from '../user-data/merchants';

export const REDIRECTING_DONE = 'REDIRECTING_DONE' as const;
export interface RedirectingDoneAction {
  type: typeof REDIRECTING_DONE;
  onboardingData?: {
    merchant?: RouteApi.Merchant.MerchantResponse;
    email?: string;
    passwordGuid?: string;
    merchantCreationData?: AppState['accountCreation']['merchantCreationData'];
  }
};

export function redirectingDone(data?: RedirectingDoneAction['onboardingData']): RedirectingDoneAction {
  return {
    type: REDIRECTING_DONE,
    onboardingData: data,
  };
}

export type RedirectAction = RedirectingDoneAction;

export const PRE_LOAD_DATA = 'PRE_LOAD_DATA' as const;
export interface PreloadDataAction {
  type: typeof PRE_LOAD_DATA;
  setUserSessionFn: (data: BrowserUserSessionData) => void;
}

// The purpose of this action is to allow us to reflect Redux User State (user, merchants, active merchant)
// in the Non-Redux parts of the app (that get user state from props, ultimately stored in a `useState` hook
// declared in `App.js`)
export function preloadData(options: {setUserSessionFn: PreloadDataAction['setUserSessionFn']}): PreloadDataAction {
  return {
    type: PRE_LOAD_DATA,
    setUserSessionFn: options.setUserSessionFn,
  };
}

function addNoRedirectBreadcrumb() {
  addBreadcrumb({
    message: 'Did not detect redirectable URL',
    category: 'redirect',
    level: Severity.Info,
  });
}

/**********************
  Here is the current onboarding links we can expect. If we encounter any other
  URLs we will not try to redirect and let them go where they want.

  New users from shopify/bold:
    - /onboarding/account-creation
      - token (Only Shopify)
      - platform
      - currency
      - country
      - merchantDomain
      - merchantName
      - email
    - Action:
      - Shopify: Save merchant
        - Merchant will be used later to choose the merchant we are onboarding
      - Bold: Save data to create merchant later
      - Take to /onboarding/welcome
        - When creating an account, actually try to create the account

  New users from woo/magento:
    - /onboarding/set-password
      - guid (password reset guid)
      - email
    - Action:
      - Save email + guid for use in account creation page
        - The email will be used to lock the email field
      - Take to /onboarding/welcome
        - When creating an account, reset the password instead of creating an account

  Existing users from shopify/bold (onboarding is complete):
    - /login
    - Action:
      - Take to normal portal sign in
      - Wait for them to log in to see if they need to onboard for some reason
        - Just look at the most recently installed merchant associated with user

  Existing users from woo/magento:
    - /login
      - redirect=onboarding
    - Action:
      - Take to onboarding sign in
      - Once they log in check to see if they need to onboard
        - Just look at the most recently installed merchant associated with user
***********************/


export function redirectAfterInitialLoad() {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    const state = getState();
    const path = state.router.location.pathname;
    const queryParams = queryString.parse(state.router.location.search);

    addBreadcrumb({
      message: 'Starting Redirect After Initial Load',
      category: 'redirect',
      data: {
        path: path,
        search: state.router.location.search,
      },
      level: Severity.Info,
    });

    const accountCreationUrl = '/onboarding/account-creation';
    const woomagOnboardingUrl = '/onboarding/set-password';
    const allLoginUrl = '/login';
    const resetPasswordUrl = '/reset-password';

    const redirectableURLs = [
      accountCreationUrl,
      woomagOnboardingUrl,
      allLoginUrl,
      resetPasswordUrl,
    ]

    // The first step. If we don't have an onboarding link we continue without doing anything
    if (!redirectableURLs.some((url) => path.includes(url))) {
      addNoRedirectBreadcrumb();
      return dispatch(redirectingDone());
    }

    // This could be a login URL from any platform, or from a direct link not associated to onboarding
    if (path.includes(allLoginUrl)) {
      // This is probably a shopify redirect and onboarding is complete, or a regular link. Let it continue
      if (!queryParams.redirect) {
        addNoRedirectBreadcrumb();
        return dispatch(redirectingDone());
      }

      // This shouldn't happen because currently there aren't any values other than "onboarding"
      // so let's note that this has happened in Sentry and continue along
      if (queryParams.redirect !== 'onboarding') {
        captureMessage(`Encountered /login?redirect=${queryParams.redirect}`, Severity.Warning);
      }

      addBreadcrumb({
        message: 'Redirecting to /onboarding/sign-in',
        category: 'redirect',
        level: Severity.Info,
      });
      dispatch(replace('/onboarding/sign-in'));
      return dispatch(redirectingDone());
    }

    if (path.includes(woomagOnboardingUrl)) {
      // Something wrong happened because these should always be present for this URL
      // We can't onboard the user because we don't know how to reset their password
      if (!queryParams.guid || !queryParams.email) {
        captureMessage(
          'Encountered /set-password URL without password reset GUID or email',
          Severity.Fatal,
        );

        dispatch(replace('/onboarding/account-creation/error'));
        return dispatch(redirectingDone());
      }

      addBreadcrumb({
        message: 'Redirecting to /onboarding/welcome',
        category: 'redirect',
        level: Severity.Info,
      });
      dispatch(replace('/onboarding/welcome'));

      // This information will be stored in Redux for use later during the account creation process
      return dispatch(redirectingDone({
        email: (queryParams.email as string).replace(' ', '+'),
        passwordGuid: queryParams.guid as string,
      }));
    }

    if (path.includes(resetPasswordUrl)) {
      if (!queryParams.guid) {
        captureMessage(
          'Encountered password reset URL without password reset GUID',
          Severity.Fatal,
        );

        dispatch(replace('/onboarding/account-creation/error'));
        return dispatch(redirectingDone());
      }

      switch (queryParams.source) {
        case 'onboarding': {
          dispatch(replace('/onboarding/sign-in/reset-password'));
          return dispatch(redirectingDone({
            passwordGuid: queryParams.guid as string,
          }));
        }
        default: {
          addNoRedirectBreadcrumb();
          return dispatch(redirectingDone());
        }
      }
    }

    // From here on out we can assume we are working with a shopify, bold, or api onboarding link
    // Shopify will come into the process with a merchant created already while Bold
    // will not have a merchant created (and thus no merchant token). The API link doesn't
    // have _any_ information coming into the process.
    //
    // We will store the merchant for later use if there is a merchant token,
    // Otherwise we will look at query params for data we will use to later create a merchant
    // If we don't see the domain in the query params we don't set the data at all because
    // we will show extra fields to get this information on the account creation screen
    if (!queryParams.token) {
      dispatch(replace('/onboarding/welcome'));
      return dispatch(redirectingDone({
        email: queryParams.email as string|undefined,
        merchantCreationData: queryParams.merchantDomain ? {
          platformId: queryParams.platform as string|undefined,
          currency: queryParams.currency as string|undefined,
          country: queryParams.country as string|undefined,
          domain: queryParams.merchantDomain as string|undefined,
          name: queryParams.merchantName as string|undefined,
        } : undefined,
      }));
    }

    let merchant = await getMerchantFromMerchantToken(queryParams.token as string);
    if (!merchant) {
      addBreadcrumb({
        message: 'Merchant fetch failed',
        category: 'redirect',
        level: Severity.Warning,
      });

      // We can't do anything without the status
      dispatch(replace('/onboarding/account-creation/error'));
      return dispatch(redirectingDone());
    }

    if (!merchant.onboarding_status) {
      // At this point we have encountered a merchant token in the URL which means that they
      // are trying to onboard. For some reason this merchant doesn't have their onboarding
      // status set to a value so we should interpret this as an "Install" status and commit that
      // value to the server.
      merchant.onboarding_status = OnboardingStatus.Install;
      try {
        merchant = await updateMerchant({
          id: merchant.id,
          token: merchant.prod_api_secret,
          data: {
            onboarding_status: OnboardingStatus.Install
          }
        });
      } catch(error) {
        // This error isn't critical - we can log and ignore it
        captureException(error);
      }
    }

    switch(merchant.onboarding_status) {
      case OnboardingStatus.Install: {
        addBreadcrumb({
          message: 'Redirecting to /onboarding/welcome',
          data: {
            status: OnboardingStatus.Install,
          },
          category: 'redirect',
          level: Severity.Info,
        });

        // Send to account creation. Set enough info so the
        // creation process knows what it needs to do
        dispatch(replace('/onboarding/welcome'));
        return dispatch(redirectingDone({
          merchant,
        }));
      }
      case OnboardingStatus.AccountCreated: {
        // TODO: If someone is already logged in should we set status to skipped?
        //       I don't think so because they could have just refreshed the page???
        if (getState().app.userData.loggedIn) {
          addBreadcrumb({
            message: 'Redirecting to /onboarding/configure-route-plus',
            data: {
              status: OnboardingStatus.AccountCreated,
            },
            category: 'redirect',
            level: Severity.Info,
          });

          dispatch(replace('/onboarding/configure-route-plus'));
        } else {
          addBreadcrumb({
            message: 'Redirecting to /onboarding/sign-in',
            data: {
              status: OnboardingStatus.AccountCreated,
            },
            category: 'redirect',
            level: Severity.Info,
          });

          dispatch(replace('/onboarding/sign-in'));
        }

        return dispatch(redirectingDone({
          merchant,
        }));
      }
      case OnboardingStatus.Skipped:
      case OnboardingStatus.Complete:
      case OnboardingStatus.Uninstall: {
        addBreadcrumb({
          message: 'Redirecting to root',
          data: {
            status: merchant.onboarding_status,
          },
          category: 'redirect',
          level: Severity.Info,
        });

        dispatch(replace('/'));
        return dispatch(redirectingDone({
          merchant,
        }));
      }
      default: {
        captureMessage(`Encountered unknown onboarding status: "${merchant.onboarding_status}"`, Severity.Critical);

        dispatch(replace('/'));
        return dispatch(redirectingDone({
          merchant,
        }));
      }
    }
  }
}

// This function is responsible for redirecting after an explicit sign in
//
// (Explicit in this case means that the user themselves has logged in via
//  one of our sign in pages and it does not mean our application silently signing
//  in the user after creating an account)
//
// It expects:
// 1. The user to be logged in (and in the state store)
// 2. The correct "activeBrand" to be already correctly set in the state store
//    - "correct" meaning that if we are going to onboard, we want to onboard this merchant
//
export function redirectAfterExplicitSignIn() {
  return async (dispatch: ThunkDispatch, getState: () => State) => {
    addBreadcrumb({
      message: 'Starting redirect after explicit login',
      category: 'redirect',
      level: Severity.Info,
    });

    const state = getState();
    const {from}: {from: string} = {
      from: '/',
      ...state.router.location.state,
    };

    const doNotRedirect = () => {
      return dispatch(replace(from));
    }

    if (!state.app.userData.loggedIn) {
      captureMessage('After Explicit Login - Redirect: User Not Logged In', Severity.Critical);
      return doNotRedirect();
    }

    const merchant = state.app.userData.activeBrand;

    switch(merchant.onboarding_status) {
      case OnboardingStatus.Install: {
        // This should error because an account has to be created already if they just signed in.
        // We can assume that something went wrong when the merchant/user was created somewhere else
        // and the onboarding status was not updated. Take them to the configure page
        captureMessage('Merchant with onboarding status "Install" encountered after login', Severity.Critical);

        await dispatch(updateOnboardingStatusForMerchant({
          merchantId: merchant.id,
          merchantToken: merchant.prod_api_secret,
          onboardingStatus: OnboardingStatus.AccountCreated,
        }));
        return dispatch(replace('/onboarding/configure-route-plus'));
      }
      case OnboardingStatus.AccountCreated: {
        // If someone has explicitly logged in when the have the "Account Created"
        // status we need to set it to "Skipped" so they can exit the onboarding flow
        // If after this they exit and log in again
        await dispatch(updateActiveMerchant({
          id: merchant.id,
          prod_api_secret: merchant.prod_api_secret,
          onboarding_status: OnboardingStatus.Skipped,
          onboarding_complete: true,
          status: 'Active',
        }));
        return dispatch(replace('/onboarding/configure-route-plus'));
      }
      case OnboardingStatus.Skipped:
      case OnboardingStatus.Complete:
      case OnboardingStatus.Uninstall:
      // Older merchants might not have this value set. That is OK - just don't redirect
      case null:
      case undefined: {
        return doNotRedirect();
      }
      default: {
        captureMessage(`After Explicit Login - Redirect: Encountered unknown install status: "${merchant.onboarding_status}"`, Severity.Critical);
        return doNotRedirect();
      }
    }
  }
}
