import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

type StyleProps = {
  marginTop?: number;
}

type Props = {
  head: string;
  description?: string;
  marginTop?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: (props: StyleProps) => props.marginTop || 20,
    marginBottom: 8,
    maxWidth: 420,
    textAlign: 'left',
    [theme.breakpoints.up("sm")]: {
      marginTop: 96,
      marginBottom: 24,
    },
  },
  heading: {
    fontSize: 32,
    lineHeight: '33px',
    fontWeight: 'bold',
  },
  body: {
    marginTop: 16,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '23px',
  },
}));

const DetailsText: FC<Props> = ({ head, description, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <h1 className={classes.heading}>{head}</h1>
      {description && <div className={classes.body}>
        {description}
      </div>}
    </div>
  );
}

export default DetailsText;
