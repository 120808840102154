import React, {FC} from 'react';
import {Grid, Typography, Divider} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';

import {HeaderContainer, ContainerContent, Button} from '../../../components';

export type OwnProps = {};

export type StateProps = {};

export type DispatchProps = {
  onResendLink: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 75,
    paddingRight: 75,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 45,
      paddingRight: 45,
    }
  },
  titleContainer: {
    marginTop: '75px',
  },
  titleText: {
    fontSize: '28px',
    fontWeight: 'bold',
    lineHeight: '32px',
  },
  divider: {
    width: '100%',
    marginTop: '30px',
  },
  contentTextContainer: {
    marginTop: '24px',
  },
  buttonContainer: {
    marginLeft: '-16px',
    marginTop: '35px',
    marginBottom: '55px',
  }
}), {name: 'PasswordResetExpired'});

export const PasswordResetExpired: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <HeaderContainer>
      <ContainerContent width={360}>
        <div className={classes.root}>
          <Grid container>
            <Grid className={classes.titleContainer} item xs={12}>
              <Typography variant="h1" className={classes.titleText}>Sorry, this Password Reset Link has expired.</Typography>
            </Grid>
            <Grid className={classes.divider} item xs={12}>
              <Divider/>
            </Grid>
            <Grid className={classes.contentTextContainer} item xs={12}>
              <Typography variant="subtitle1">To have another Password Reset Link emailed to you, please click the button below.</Typography>
            </Grid>
            <Grid className={classes.buttonContainer} item xs={12}>
              <Button width={242} onClick={props.onResendLink}>Email a new Password Reset Link</Button>
            </Grid>
          </Grid>
        </div>
      </ContainerContent>
    </HeaderContainer>
  );
};
