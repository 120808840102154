import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './BillingVideoModal.scss';
import CloseIcon from '@material-ui/icons/Close';

import {
  BILLING_VIDEO_CDN,
} from '../../../../constants';

class BillingVideoModal extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.onRequestClose && this.props.onRequestClose();
  }

  renderVideo() {
    return (
      <div className="video-container">
        <video width="100%" height="100%" controls className="video">
          <source src={BILLING_VIDEO_CDN} type="video/mp4" />
        </video>
        <CloseIcon className='icon' onClick={this.handleClose} />
      </div>

    );
  }

  render() {
    const { open } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {this.renderVideo()}
        </Fade>
      </Modal>
    );
  }
}

export default BillingVideoModal;
