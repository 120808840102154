import React, { useEffect, useState } from 'react';
import './PasswordIssueModal.scss';
import Button from '../../../../Button/Button';
import alertWithCircle from '../../../../../assets/images/alert-with-circle.svg';
import newAccountUser from '../../../../../assets/images/new-account-icon.svg';
import closeIcon from '../../../../../assets/images/close-icon.svg';
import { requestPasswordResetLink } from '../../../../../services/api';

const PasswordIssueModal = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState();
  const [publicToken, setPublicToken] = useState();
  const [merchantId, setMerchantId] = useState();
  const [step, setStep] = useState(0);

  const disableScroll = (condition) => {
    document.body.style.overflow = (condition ? 'hidden' : 'unset');
  };

  useEffect(() => {
    setOpenModal(props.openModal);
  }, [props.openModal]);

  useEffect(() => {
    if (props.publicToken) {
      setPublicToken(props.publicToken);
    }
  }, [props.publicToken]);

  useEffect(() => {
    if (openModal) {
      disableScroll(openModal);
    }
  }, [openModal]);

  useEffect(() => {
    if (props.email) {
      setEmail(props.email);
    }
  }, [props.email]);

  useEffect(() => {
    if (props.merchantId) {
      setMerchantId(props.merchantId);
    }
  }, [props.merchantId]);

  const closeModal = () => {
    setOpenModal(false);
    disableScroll(false);

    if (props && props.onResetInput) {
      props.onResetInput();
    }
  };

  const resetPasswordLink = async () => {
    try {
      await requestPasswordResetLink(email, publicToken);
      setStep(1);
    } catch (err) {
      alert('Something went wrong while requesting the password reset!');
    }
  };

  const enterPasswordModal = () => {
    closeModal();
  };

  const isInitialScreen = () => {
    return !!(step === 0);
  };

  const renderInitialScreen = () => {
    return (
      <div className="password-issue-modal-wrapper">
        <div className="password-issue-modal-header">
          <img srcSet={closeIcon} className="close" onClick={() => closeModal()} /> 
          <h3 className="password-issue-modal-title">
            <img srcSet={alertWithCircle} /> Password Issue
          </h3>
        </div>
        <div className="password-issue-modal-content">
          <p className="description">An account already exists for: <br/>
          <b>{email}</b></p>
          <p className="description">The password you entered does not match the existing account.</p>
          <Button text="Enter Correct Password" styleName="light" onClick={enterPasswordModal} />
          <Button text="Reset Password" styleName="light" onClick={resetPasswordLink} />
        </div>
        <div className="password-issue-modal-footer">
          <img srcSet={newAccountUser} className="new-account-user" />
          <p>To create a new account for this store, enter a different email address <span className="highlight" onClick={enterPasswordModal}>HERE</span>.</p>
        </div>        
      </div>
    );
  };

  const renderSuccessScreen = () => {
    return (
      <div className="password-issue-modal-wrapper password-issue-modal-success-screen">
        <div className="password-issue-modal-header">
          <h3 className="password-issue-modal-title">
            Thank you!
          </h3>
        </div>
        <div className="password-issue-modal-content">
          <p className="description">An email has been sent to: <br/>
          <b>{email}</b></p>
          <p className="description">Please click the link in the email to reset your password.</p>
          <Button text="Close" styleName="light" onClick={() => closeModal()} /><br/>
        </div>
      </div>
    );
  };

  

  return (
    openModal ? (
      <div className="password-issue-modal">
        {isInitialScreen() ? renderInitialScreen() : renderSuccessScreen()}
      </div>
    ) : ''
  );
};

export default PasswordIssueModal;
