const permissions = {
  ROUTE: {
    static: ['nav_bar:route-pro-widget'],
  },
  LEGACY: {
    static: ['nav_bar:route-pro-widget'],
  },
  ROUTE_PRO: {
    static: [
      /* New Merchant Service Tiers */
      'carrier_subrogation',
      'in_app_merchant_branding_card',
      'post_purchase_insights_page',
      'branded_email_comms',
      'customized_notification_settings',
      'pre_shipment_notifications',
      'global_insurance_policy_included',
      'shipping_insights',
      'basic_tracking_notifications',
      'merchant_brand_elements',
      'thank_you_page_asset',
      'order_list_and_claim_insights',
      'protect_programmatic_rules',
      'consumer_paid_protection',
    ],
  },
};

export default permissions;
