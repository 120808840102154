import React from 'react';

export const RightArrow = (props: { className?: string }) => (
  <div style={{ position: 'static', width: 0 }}>
    <svg className={props.className} width="45" height="15" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.5H10" stroke="#5DC8DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 1L10.5064 5.50638L6 10" stroke="#5DC8DB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);
