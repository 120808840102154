import React, {FC, useState} from 'react';
import {Button, StepEnum, ProgressContainerContent, HeaderContainer, RouteRadio} from '../../components'
import {Grid, Divider, Theme, Typography, RadioGroup} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {colors} from '../../constants/styles';
import {RightArrow} from '../../media/right-arrow';
import blackCircularCheckbox from '../../../../assets/images/black-circular-checkbox.svg';
import modalInstructionImage from '../../../../assets/images/onboarding-modal-instruction.svg';
import blackUnchecked from '../../../../assets/images/black-unchecked.svg';
import TrackOrder from '../../../../assets/images/tracking-orders.svg'
import CloseIcon from '../../../../assets/icons/close'
import { Form, FormRenderProps, Field, FieldRenderProps } from 'react-final-form';

export type OwnProps = {};

export type StateProps = {
  isShopify ?: boolean;
};

export type DispatchProps = {
  onSubmit: (data: FormData) => void;
}

export type FormData = {
  checkTrace: boolean;
};

export type Props = OwnProps & StateProps & DispatchProps;

//#region Styles
const spacing = 0;
const themePxPerSpace = 8;
function getMargin(margin: number): string {
  return `${margin - (spacing * themePxPerSpace)}px`;
}

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    height: 136,
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    marginTop: '65px',
    paddingLeft: 90,
    paddingRight: 90,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  innerContentItem: {
    marginTop: '63px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerContentSmallText: {
    fontSize: '10px !important',
    fontWeight: 600,
    lineHeight: '14px',
  },
  formContentsContainer: {
    marginTop: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  arrow: {
    marginRight: '14px',
  },
  radioGroup: {
    display: 'contents',
    width: '100%',
  },
  radioLabel: {
    color: 'black',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  },
  firstHorizontalGridItem: {
    '.onboarding-2 &': {
      paddingLeft: '26px !important',
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '75px',
  },
  spanText: {
    verticalAlign: 'middle',
    marginTop: '10px',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  },
  expertImplementationExplanationText: {
    marginLeft: '26px',
    marginTop: '-26px',
    maxWidth: '200px',
    fontSize: '12px !important',
    lineHeight: '18px',
    position: 'relative',
    color: '#666666'
  },
  instructionModal: {
    background: '#FFFFFF',
    borderRadius: '16px'
  },
  modalContainer: {
    alignItems: 'left',
    background: '#FFFFFF',
    borderRadius: '16Px',
    boxShadow: '0px 14px 18px rgba(0, 0, 0, 0.15)',
    left: '-63.95%',
    display: 'flex',
    flexDirection: 'column',
    height: '268px',
    marginBottom: '10px',
    position: 'absolute',
    top: '55px',
    width: '452px',
    zIndex: 19000,
    padding: '35px'
  },
  modalContent: {
    zIndex:1000,
    position: 'absolute',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#333333',
    width: '191px'
  },
  instructionImage: {
    position:'absolute',
    marginTop: '95px'
  },
  toolTip: {
    position: 'absolute',
    width: '40px',
    height: '32px',
    left: 'calc(50% - 40px/2 - 0.84px)',
    top: '-14.84px',
    background: '#FFFFFF',
    borderRadius: '4px',
  }
}), {});

export const testIds = {
  tpShopifyMerchantSection: 'tpShopifyMerchantSection',
  tpInternalFormSection: 'tpInternalFormSection',
  tpProgressContainerContent: 'tpProgressContainerContent',
  tpRadioBox: 'tpRadioBox'
};

export const ThankYouPage: FC<Props> = (props) => {
  const classes = useStyles();
  const initialValues: FormData = {
    checkTrace: true
  };

  const parseFormElementValueToBoolean = (value: any) => value === 'true';

  return (
    <HeaderContainer>
      <ProgressContainerContent width={800} progressStep={StepEnum.CartThankYouPage} data-testid={testIds.tpProgressContainerContent}>
        <div className={classes.innerContent}>
          <Grid container style={{flexShrink: 0}}>
            <Grid item xs={12}>
              <Typography variant="h1">3: Cart Thank You Page</Typography>
            </Grid>
            <Grid className={classes.innerContentItem} item xs={12} sm={8}>
                <Typography>Route shows your customers a link to track their order. This link appears on the cart Thank You Page after checkout.</Typography>
            </Grid> 
          </Grid>
          <Divider style={{marginTop: '65px'}}/>
          <Form
            onSubmit = {props.onSubmit}
            initialValues = {
              initialValues
            }
          >
            {(formProps: FormRenderProps<FormData>) => {
              return <InternalForm {...props} {...formProps}/>;
            }}
          </Form>
        </div>
      </ProgressContainerContent>
    </HeaderContainer>  
  );
}

function InternalForm(props: Props & FormRenderProps<FormData>) {
  
  const { isShopify } = props;

  const [toolTipHeroOpen, setToolTipHeroOpen] = useState(false);
  const { handleSubmit } = props;
  const classes = useStyles();

  const instructionToggle = () => {
    setToolTipHeroOpen(!toolTipHeroOpen);
  };

  const parseFormElementValueToBoolean = (value: any) => value === 'true';

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className={classes.formContentsContainer}>
          <Grid container>
            <Grid container item xs={12} data-testid={testIds.tpInternalFormSection}>
              <RightArrow className={classes.arrow}/>
              <Typography variant="subtitle2">A. Settings</Typography>
            </Grid>
            <RadioGroup className={classes.radioGroup}>
              <Grid container item xs={6} sm={6} className={classes.firstHorizontalGridItem}>
              <Field
                name="checkTrace"
                type="checkbox"
                format={parseFormElementValueToBoolean}
                render={(renderProps: any) => {
                  return (
                    <FormControlLabel
                      control={<Checkbox
                        checked={renderProps.input.checked}
                        value={renderProps.input.value}
                        onChange={renderProps.input.onChange}
                        icon={
                          <img
                            alt="checkbox icon image"
                            srcSet={blackUnchecked}
                            width={20}
                          />
                        }
                        checkedIcon={
                          <img
                            alt="checkbox icon image"
                            srcSet={blackCircularCheckbox}
                            width={20}
                          />
                        }
                        style={{height:"fit-content"}}
                        color="primary"
                      />}
                      label= {<span className={classes.spanText}>THANK YOU PAGE TRACKING LINK</span>}
                      /> 
                  );
                }}
              />
              { 
                isShopify ?
                (
                  <div className={classes.expertImplementationExplanationText} data-testid={testIds.tpShopifyMerchantSection}>
                    Please turn off the default tracking link for Shopify stores.
                    <a style={{color: colors.blue}} onClick={instructionToggle}> Instructions</a>
                    {toolTipHeroOpen === true ? (    
                      <div className={classes.modalContainer}>
                        <div className="image-notification-close-icon-container" onClick={instructionToggle}>
                          <CloseIcon fill="#2c373c"/>
                        </div>
                        <img src={modalInstructionImage} className={classes.instructionImage}/>
                        <span className={classes.modalContent}>Go to your Shopify store settings › Location › Location</span>
                        <div className={classes.toolTip}></div>
                      </div>
                      ) : 
                      ('')
                    }
                  </div>
                ) : null
              }
              </Grid>  
              <Grid container item xs={6} sm={6} > 
                <img src={TrackOrder} alt="Track Orders" style={{marginTop: '-25px'}}/>
              </Grid>
              <Divider style={{marginTop: '175px'}}/>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button onClick={handleSubmit} width={240} >{'Save & Continue to STEP 4'}</Button>
              </Grid>
            </RadioGroup>
          </Grid>
        </div>
      </form>
    </React.Fragment>
  )
}