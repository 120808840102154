import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    width: '162px',
    height: '218px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    position: 'relative',
  },
  circle: {
    width: '95px',
    height: '95px',
    left: '12px',
    top: '88px',
    zIndex: '20',
    border: '5px solid #E5E5E5',
    position: 'absolute',
    borderRadius: '100px',
  },
  circleOuterBackground: {
    width: '80px',
    height: '80px',
    position: 'absolute',
    top: '80px',
    left: '5px',
    // zIndex: '5',
    borderRadius: '100px',
    border: '20px solid #FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',

  }, circleInnerBackground: {
    width: '80px',
    height: '80px',
    position: 'absolute',
    top: '80px',
    left: '5px',
    zIndex: '5',
    borderRadius: '100px',
    border: '20px solid #FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1) inset',

  },
  loading: {
    width: '109px !important',
    height: '109px !important',
    position: 'absolute',
    top: '86px',
    left: '11px',
    zIndex: '21',
    color: props => props.color,
  }, percentText: {
    position: 'absolute',
    width: '30px',
    height: '24px',
    left: '50px',
    top: '128px',

    font: 'inherit',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#666666',
  }, titleText: {
    display: 'inline-block',

    marginTop: '20px',
    marginLeft: '20px',

    font: 'inherit',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666666',

  }, subtitleText: {
    display: 'inline-block',
    marginTop: '10px',
    zIndex: '50',
    marginLeft: '20px',
    font: 'inherit',

    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '14px',
    color: props => props.color,
  },
  subtitleTextClaims: {
    display: 'inline-block',
    top: '86px',
    left: '11px',
    zIndex: '50',
    marginLeft: '5px',
    top: '44px',
    left: '20px',
    font: 'inherit',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666666',
  }, helpTextClaims: {
    margin: '10px 20px 10px 4px',
    font: 'inherit',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666666',
  },
  hidden: {
    display: 'none',
  },

}));

export default function InsightWidget(props) {

  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      {
        props.showInfo ? (<Fragment><div className={classes.titleText}>{props.title}</div> <div>{props.helpText.map(x => <p className={classes.helpTextClaims}>{x}</p>)}</div></Fragment>) : (<Fragment><div className={classes.titleText}>{props.title}</div>
          <br />
          <div className={classes.subtitleText}>{props.isLoading ? '-' : props.count}</div>
          <div className={classes.subtitleTextClaims}>issues</div>
          <CircularProgress variant={"static"} value={props.rate} className={classNames(classes.loading, props.isLoading ? classes.hidden : '')} />
          {/* <div className=></div> */}
          <div className={props.isLoading ? `dash-loading-state ${classes.circle}` : `${classes.circle}`}></div>
          <div className={classNames(classes.circleOuterBackground, props.isLoading ? classes.hidden : '')}></div>
          <div className={classNames(classes.circleInnerBackground, props.isLoading ? classes.hidden : '')}></div>
          <div className={classNames(classes.percentText, props.isLoading ? classes.hidden : '')}>{props.rate}%</div>
        </Fragment>)
      }
    </div>
  );
}