import {RouteApi} from '../../api/types';
import {getUserSession, setUserSessionInStorage, BrowserUserSessionData} from '../../../../stores/SessionManager';
import {State, ThunkDispatch, AppState} from '../../store';
import {userDataLoaded} from './index';
import { captureMessage, Severity } from '@sentry/browser';

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function getActiveMerchant(merchants: RouteApi.Merchant.MerchantResponse[]): RouteApi.Merchant.MerchantResponse {
  const mostRecentlyUpdatedMerchant = merchants.reduce((reducedMerchant, currentMerchant) => {
    if (!reducedMerchant) {
      return currentMerchant;
    }

    if (!reducedMerchant.updated_on) {
      return currentMerchant;
    }
    if (!currentMerchant.updated_on) {
      return reducedMerchant;
    }

    const reducedDate = new Date(reducedMerchant.updated_on);
    const currentDate = new Date(currentMerchant.updated_on);

    if (!isValidDate(reducedDate)) {
      return currentMerchant;
    }
    if (!isValidDate(currentDate)) {
      return reducedMerchant;
    }

    return reducedDate > currentDate ?
      reducedMerchant :
      currentMerchant;
  });

  return mostRecentlyUpdatedMerchant;
}


function isPlainObject(item: any): item is ({}) {
  return Object.keys(item).length === 0;
}

export const noopAction = {
  type: 'NOOP' as const,
};

// This function transforms the user session data as it might be stored in
// the browser session storage into a form that conforms to the redux store.
// Most notably it will convert any placeholders (empty objects) into undefined
//
// It will then dispatch an action so the data can be loaded into the state store
export const loadStateFromBrowser = () => {
  return (dispatch: ThunkDispatch) => {
    const sessionData = getUserSession();
    if (!sessionData ||
         typeof sessionData !== 'object' ||
         typeof sessionData.loggedIn !== 'boolean') {
      return dispatch(noopAction);
    }

    let stateStoreData: AppState['userData'];
    if (!sessionData.loggedIn) {
      stateStoreData = {
        loggedIn: false,
      };
    } else {
      if (isPlainObject(sessionData.user) || isPlainObject(sessionData.activeBrand)) {
        captureMessage(`loadStateFromBrowser: User logged in but user or active merchant is plain object`, Severity.Error);
        return dispatch(noopAction);
      }

      stateStoreData = {
        loggedIn: true,
        user: sessionData.user,
        brands: sessionData.brands,
        activeBrand: sessionData.activeBrand,
        tier: sessionData.tier as any,
        merchantRole: sessionData.merchantRole,
      };
    }

    return dispatch(userDataLoaded(stateStoreData));
  };
}

// This function transforms the state store user data into a form
// that the rest of the non-redux app is expecting. To do this
// it will add placeholder values if a user is not logged in.
//
// It's next job is to commit this data to session storage.
// It is also responsible for propagating this data to the
// rest of the non-redux app via the "App.js useState"
// react hook that stores user session data
//
export const exportStateToBrowser = () => {
  return (dispatch: ThunkDispatch, getState: () => State) => {
    const {userData, setUserSessionOutsideStoreFunction} = getState().app;

    const data: BrowserUserSessionData = {
      loggedIn: userData.loggedIn,
      user: userData.loggedIn && userData.user ? userData.user : {},
      brands: userData.loggedIn && userData.brands ? userData.brands : [],
      activeBrand: userData.loggedIn && userData.activeBrand ? userData.activeBrand : {},
      tier: userData.loggedIn && userData.tier ? userData.tier : {},
      merchantRole: userData.loggedIn && userData.merchantRole ? userData.merchantRole : {},
    };

    setUserSessionInStorage(data, false);
    // This is the function that reflects changes in the non-redux parts of the app
    setUserSessionOutsideStoreFunction(data);

    return dispatch(noopAction);
  };
};
