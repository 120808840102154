import axios from 'axios';
import { addBreadcrumb, Severity } from '@sentry/browser';
import { ROUTE_API } from '../constants';
import dictionary from '../constants/dictionary';

const getOptions = (token) => ({
  headers: {
    token,
    'Content-Type': 'application/json',
  },
});

export const getOrderLineItems = async (orderId, token) => {
  const endpoint = `${ROUTE_API}/orders/${orderId}/items`;

  try {
    const { data: lineItems } = await axios.get(endpoint, getOptions(token));
    if (lineItems && lineItems.length > 0) {
      return lineItems.filter((lineItem) => lineItem.status !== 'cancelled');
    }
  } catch (err) {
    console.log('Error getting order line items: ', err.response);
  }
  return [];
};

export const getWebOrderTracking = async (orderId) => {
  const endpoint = `${ROUTE_API}/web/orders/${orderId}`;

  try {
    const ordersResp = await axios.get(endpoint);
    if (ordersResp.data) {
      return ordersResp.data;
    }
  } catch (err) {
    addBreadcrumb({
      message: dictionary.MESSAGE_ERROR_RETRIEVE_ORDER_WEB_TRACKING,
      category: 'Web Order Tracking',
      level: Severity.Info,
    });
  }
  return [];
};
